import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Typography } from "@material-ui/core";

import Close from "components/Icons/Close";

import { i18nVerboseDateFormat } from "utils/i18nDate";

import { useSearchResultErrorStyles } from "./searchResultErrorStyles";

export function SearchResultErrors(props) {
  const classes = useSearchResultErrorStyles();

  const { requestErrors, isOpen, close } = props;

  if (!requestErrors.length || !isOpen) return null;

  return (
    <div className={classes.errorWrapper}>
      <Close className={classes.closeIcon} onClick={close} />

      <Typography>
        <FormattedMessage
          id="search.no_result"
          defaultMessage="No result for "
        />
      </Typography>

      {requestErrors.map((date, i) => (
        <Typography className={classes.requestError} key={`date-error-${i}`}>
          {i18nVerboseDateFormat(date)}
        </Typography>
      ))}
    </div>
  );
}
