import { settings } from "config/app";
import cookie from "lib/cookie.js";
import { snackMessage } from "containers/SnackBar/actions.js";
import { userManager } from "containers/SSOSignInForm/ssoUserManager";
import { RESET_LOGIN } from "containers/SignInForm/actions";
import { jumpToNextPage } from "store/actions";

export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const REQUESTING = "@@login/REQUESTING";

export const loginRequestError = (error) => ({
  type: LOGIN_REQUEST_ERROR,
  error,
});

const requesting = () => ({
  type: REQUESTING,
});

export const ssoLoginRequest =
  (navigate, setIsAuthenticated, loadUserProfile, location) =>
  (dispatch, getState, getIntl) => {
    dispatch(requesting());
    userManager
      .signinRedirectCallback()
      .then((user) => {
        // in case of token conflict (impersonate, etc)
        cookie.erase(settings.cookieKeys.impersonateCookie);
        cookie.erase(settings.cookieKeys.authCookie);
        cookie.erase("adm");

        cookie.set(settings.cookieKeys.authCookie, user.access_token, 2);
        cookie.set(settings.cookieKeys.isLoggedWithOpenid, true);

        setIsAuthenticated(true);

        loadUserProfile()
          .then((userProfile) => {
            dispatch(
              snackMessage(
                "success",
                getIntl().formatMessage(
                  {
                    id: "signin.success",
                  },
                  { name: userProfile.firstName },
                ),
              ),
            );
            dispatch({ type: RESET_LOGIN });
            jumpToNextPage(navigate, location);
          })
          .catch((error) => {
            // inconsistent user or restricted territory
            dispatch(
              snackMessage(
                "error",
                _.get(error, "infos.detail.message", "unable to login"),
                error,
              ),
            );
            navigate("/sso-login");
            dispatch(loginRequestError(error));
          });
      })
      .catch((error) => dispatch(loginRequestError(error)));
  };
