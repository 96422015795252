import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export function DiscountTicketIcon(props) {
  const { className, fill } = props;

  return (
    <SvgIcon className={className} viewBox="0 0 24 22">
      <path
        d="M19.5 3.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-17a2 2 0 0 1-2-2v-2.042a2.978 2.978 0 0 0 .832-.27l-.074.036c.064-.03.126-.06.186-.094l-.112.059a3 3 0 0 0-.186-5.462l.112.049a2.981 2.981 0 0 0-.192-.08l.08.03A2.978 2.978 0 0 0 .5 7.543V5.5a2 2 0 0 1 2-2h17zm-4.005 1H2.5a1 1 0 0 0-.993.883L1.5 5.5v1.291a4.001 4.001 0 0 1 0 7.418V15.5a1 1 0 0 0 .883.993l.117.007h12.995v-1.357h1V16.5H19.5a1 1 0 0 0 .993-.883l.007-.117v-10a1 1 0 0 0-.883-.993L19.5 4.5h-3.005v1.357h-1V4.5zm1 6.929v1.857h-1v-1.857h1zm0-3.715v1.857h-1V7.714h1z"
        fill={fill || "white"}
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
