import React from "react";
import { connect } from "react-redux";
import { SearchResultErrors } from "components/SearchResultErrors/SearchResultErrors.jsx";

/**
 * Search errors container
 */
export class SearchResultErrorsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: true };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.requestErrors.length) this.setState({ isOpen: true });
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { requestErrors } = this.props;
    const { isOpen } = this.state;

    return (
      <SearchResultErrors
        requestErrors={requestErrors}
        isOpen={isOpen}
        close={this.close}
      />
    );
  }
}

const _mapState = (state) => ({
  requestErrors: state.search.requestErrors,
});

export default connect(_mapState, null)(SearchResultErrorsContainer);
