import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

function WalkMarker(props) {
  const { classes, color, style } = props;

  return (
    <SvgIcon
      style={style}
      classes={classes}
      viewBox="0 0 22 22"
      focusable="false"
      role="none"
      aria-hidden="true"
    >
      <g
        id="VX/Icons/bus/walk/dropoff"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <circle
          id="Oval-Copy-5"
          stroke={color}
          strokeWidth="2"
          fill="#FFFFFF"
          cx="11"
          cy="11"
          r="10"
        ></circle>
        <g
          id="Group-Copy-2"
          transform="translate(6.000000, 3.000000)"
          fill={color}
        >
          <path
            d="M2.64000024,10.5600009 L3.93600035,11.8560011 L2.81600025,14.8800013 C2.68800024,15.2160014 2.36800021,15.4240014 2.03200018,15.4240014 C1.93600017,15.4240014 1.84000016,15.4080014 1.74400016,15.3760014 C1.31200012,15.2160014 1.0880001,14.7360013 1.24800011,14.3040013 L2.64000024,10.5600009 Z"
            id="Path"
          ></path>
          <path
            d="M0.22400002,7.24800065 L0.992000089,5.77600052 C1.20000011,5.39200048 1.50400013,5.07200045 1.87200017,4.84800043 C3.64800033,3.79200034 3.87200035,3.80800034 4.09600037,3.82400034 L5.29600047,3.90400035 C5.66400051,3.92000035 5.92000053,4.01600036 7.15200064,5.82400052 C7.20000064,5.88800053 7.26400065,5.93600053 7.36000066,5.95200053 L8.83200079,6.17600055 C9.23200083,6.24000056 9.50400085,6.60800059 9.44000084,7.00800063 C9.37600084,7.40800066 9.00800081,7.68000069 8.60800077,7.61600068 L7.13600064,7.39200066 C6.6560006,7.31200065 6.22400056,7.05600063 5.95200053,6.6560006 C5.88800053,6.56000059 5.84000052,6.48000058 5.77600052,6.40000057 L4.83200043,9.39200084 L6.14400055,10.704001 C6.33600057,10.896001 6.49600058,11.152001 6.59200059,11.408001 L7.60000068,14.3520013 C7.76000069,14.7840013 7.52000067,15.2640014 7.08800063,15.4080014 C6.99200063,15.4400014 6.91200062,15.4560014 6.81600061,15.4560014 C6.46400058,15.4560014 6.14400055,15.2320014 6.01600054,14.8960013 L5.00800045,11.9520011 C4.99200045,11.9200011 4.97600044,11.9040011 4.96000044,11.8720011 L2.62400023,9.52000085 C2.2880002,9.18400082 2.16000019,8.72000078 2.2560002,8.27200074 L2.76800025,6.00000054 C2.72000024,6.03200054 2.67200024,6.06400054 2.60800023,6.09600055 C2.46400022,6.17600055 2.35200021,6.30400056 2.2720002,6.44800058 L1.50400013,7.92000071 C1.37600012,8.16000073 1.1200001,8.30400074 0.864000077,8.30400074 C0.752000067,8.30400074 0.640000057,8.27200074 0.528000047,8.22400074 C0.176000016,8.03200072 0.0480000043,7.60000068 0.22400002,7.24800065 Z"
            id="Path"
          ></path>
          <circle
            id="Oval-WalkMarker-circle"
            transform="translate(4.942359, 1.848197) rotate(-83.788255) translate(-4.942359, -1.848197) "
            cx="4.94235884"
            cy="1.84819697"
            r="1.61595332"
          ></circle>
        </g>
      </g>
    </SvgIcon>
  );
}

export default WalkMarker;
