import React, { useCallback, useContext } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import SignUpForm from "components/SignUpForm/SignUpForm.jsx";

import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";

import { formatPhoneNumber } from "utils/phoneNumber";
import { onFieldChange, signupRequest } from "./actions.js";
import { updateCustomFields } from "utils/updateCustomField.js";

async function generateValidationCode(firstname, lastname, phoneNumber, email) {
  const str = `padam${firstname}${lastname}${phoneNumber}${email}`;
  const utf8 = new TextEncoder().encode(str);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((bytes) => bytes.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

/**
 * Sign up container
 */
function SignUp(props) {
  const navigate = useNavigate();
  const { loadUserProfile, setIsAuthenticated } = useContext(UserContext);
  const { productParameters } = useContext(ProductContext);

  const phoneValidationProcessAtBooking =
    productParameters?.user.phone_validation_process === "AT_BOOKING";

  const submit = useCallback(
    async (captchaAnswer) => {
      let payload = {
        email: props.email,
        phone_number: formatPhoneNumber(props.phoneNumber),
        first_name: props.firstname,
        last_name: props.lastname,
        password: props.password,
        opt_in_market: Boolean(props.optInMarket),
        password_repeat: props.passwordRepeat,
        has_terms_accepted: Boolean(props.hasTermsAccepted),
        validation_code: await generateValidationCode(
          props.firstname,
          props.lastname,
          props.phoneNumber,
          props.email,
        ),
        captcha: captchaAnswer,
      };
      updateCustomFields(props, payload);

      props.signupRequest(
        navigate,
        loadUserProfile,
        setIsAuthenticated,
        phoneValidationProcessAtBooking,
        payload,
      );
    },
    [navigate, props, loadUserProfile, setIsAuthenticated],
  );

  const fieldChange = useCallback(
    (name, e) => {
      let value = null;
      if (e?.target?.value !== undefined) {
        value = e.target.value;
      } else if (e?.target?.checked) {
        value = e.target.checked;
      } else {
        value = e;
      }
      props.onFieldChange(name, value);
    },
    [props.onFieldChange],
  );

  const onCheckChange = useCallback(
    (name, bool) => {
      props.onFieldChange(name, bool);
    },
    [props.onFieldChange],
  );

  return (
    <SignUpForm
      intl={props.intl}
      lastname={props.lastname}
      firstname={props.firstname}
      phoneNumber={props.phoneNumber}
      onCheckChange={onCheckChange}
      email={props.email}
      hasTermsAccepted={props.hasTermsAccepted}
      optInMarket={props.optInMarket}
      password={props.password}
      passwordRepeat={props.passwordRepeat}
      passportId={props.passportId}
      error={props.error}
      submit={submit}
      fieldChange={fieldChange}
      regional={productParameters}
    />
  );
}

const _mapState = (state) => ({
  ...state.signup,
});

const _mapDispatch = (dispatch) => ({
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  signupRequest: (
    navigate,
    loadUserProfile,
    setIsAuthenticated,
    phoneValidationProcessAtBooking,
    payload,
  ) => {
    dispatch(
      signupRequest(
        navigate,
        loadUserProfile,
        setIsAuthenticated,
        phoneValidationProcessAtBooking,
        payload,
      ),
    );
  },
});

export default connect(_mapState, _mapDispatch)(injectIntl(SignUp));
