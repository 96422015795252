import React, { useContext, useState } from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { useForm } from "react-hook-form";

import { Clock3, Loader2Icon } from "lucide-react";

import api from "api";
import { PostWaitingListOutput, WaitingList } from "api/types/waitingList";

import { UserContext } from "contexts/UserContext";

import { Typography } from "components/ui/typography";
import { Button } from "components/ui/button";
import { Form } from "components/ui/form";
import { Frame } from "atoms/Frame";
import { Message } from "atoms/Message";

import { TimeWindowLabel } from "../TimeWindowLabel";
import { Link } from "react-router-dom";

interface JoinFormProps {
  searchRequestId: string;
}

export function JoinForm({ searchRequestId }: JoinFormProps) {
  const { userProfile } = useContext(UserContext);
  const [joinedWaitingList, setJoinedWaitingList] = useState<WaitingList>();

  const customerId = userProfile.customerId;

  const form = useForm();

  function onSubmit() {
    return api
      .joinWaitingList({ customerId, search_request_id: searchRequestId })
      .then((waitingList: PostWaitingListOutput) => {
        setJoinedWaitingList(waitingList);
      })
      .catch((error) => {
        form.setError("root.serverError", {
          type: error.infos.extra?.reason,
          message: error.infos.message,
        });
      });
  }

  if (joinedWaitingList) {
    return (
      <div className="flex flex-col items-center gap-6">
        <Message variant="success">
          <FormattedMessage id="waiting_list.tooltip.confirmation" />
        </Message>
        <Button variant="outline" asChild>
          <Link to="/reservation">
            <FormattedMessage id="ACTION_SEE_REQUEST" />
          </Link>
        </Button>
      </div>
    );
  }

  if (form.formState.errors.root?.serverError) {
    return (
      <Message variant="error">
        {form.formState.errors.root.serverError.type ===
        "ALREADY_SIMILAR_SEARCH_IN_WAITING_LIST" ? (
          <FormattedMessage id="TEXT_WAITING_LIST_SIMILAR_PERIOD_REQUEST" />
        ) : (
          form.formState.errors.root.serverError.message
        )}
      </Message>
    );
  }

  return (
    <>
      <Frame>
        <Typography variant="p" className="text-muted-foreground">
          <FormattedMessage id="TEXT_WAITING_LIST_TIME_WINDOW" />
        </Typography>
        <Typography variant="ul" className="ml-0 text-muted-foreground">
          <li className="flex gap-2">
            <Clock3 />
            <TimeWindowLabel />
          </li>
        </Typography>
      </Frame>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col items-center gap-6"
        >
          <Button
            type="submit"
            disabled={form.formState.isSubmitting}
            className="gap-2"
          >
            {form.formState.isSubmitting && (
              <Loader2Icon className="animate-spin" />
            )}
            <FormattedMessage id="ACTION_WAITING_LIST_SUBSCRIBE" />
          </Button>
        </form>
      </Form>
    </>
  );
}
