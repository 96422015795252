import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useForgetPasswordStyles = makeStyles((theme: CustomTheme) => ({
  actionButtons: {
    display: "grid",
    columnGap: "1.5rem",
    rowGap: "1rem",

    margin: "3rem 0 0 0",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  emailInput: {
    margin: "1rem 0 0 0",
    width: "100%",
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.caption.fontSize,
  },
  forgetPasswordContainer: {
    margin: "16px",
    padding: "2rem 1.5rem",

    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  forgetPasswordTitle: {
    margin: "0 1rem 2rem 1rem",

    fontWeight: 500,
    textAlign: "center",
  },
  sentEmailMessage: {
    margin: "1rem 0 0 0",
  },
}));
