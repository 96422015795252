import { makeStyles } from "@material-ui/core";

export const useUniversalLinkStyles = makeStyles(() => ({
  titleContainer: {
    display: "flex",
  },
  closeBtn: {
    marginLeft: "right",
  },
  title: {
    margin: "0 auto",
  },
  textAlert: {
    color: "#0869AF",
  },
  containerUniversalLinks: {
    display: "flex",
    flexDirection: "column",
    margin: "1rem 0",
  },
  universalLink: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid transparent",
    borderRadius: "10px",
    padding: "0.5rem",
    marginBottom: "1.5rem",
    boxShadow: "0 0.5rem 1rem rgba(0, 0, 0, 0.30)",
    "&:hover": {
      borderColor: "rgba(8, 105, 175, 0.30)",
      boxShadow: "0 0.5rem 1rem rgba(8, 105, 175, 0.30)",
    },
  },
  containerText: {
    display: "flex",
    justifyContent: "center",
  },
  iconLeft: {
    marginRight: ".5rem",
  },
  iconRight: {
    marginLeft: "2rem",
  },
  link: {
    textAlign: "center",
  },
}));
