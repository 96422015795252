import React from "react";
import { useIntl } from "react-intl";

import { Grid } from "@material-ui/core";

import About from "components/About/About";

export default function AboutView() {
  const intl = useIntl();

  return (
    <Grid container justifyContent="center">
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "menu.about" })}
      </h1>

      <About />
    </Grid>
  );
}
