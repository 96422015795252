import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useScrollButtonToTopStyles = makeStyles((theme: CustomTheme) => ({
  floatinButtonContainer: {
    display: "flex",
    justifyContent: "end",
    position: "fixed",
    right: "32px",
    bottom: "64px",
  },
  scrollButton: {
    border: "2px solid rgba(255, 255, 255, 0.5)",

    background: theme.palette.primary.main,
  },
}));
