import React from "react";
import CreditManager from "containers/CreditManager/CreditManager";
import { AccordionDetails } from "@material-ui/core";

export function Credit() {
  return (
    <AccordionDetails>
      <CreditManager />
    </AccordionDetails>
  );
}
