import {
  INITIATE_GEOCODES_REQUEST,
  RECEIVE_GEOCODES,
  GEOCODES_REQUEST_ERROR,
  INITIATE_NODES_REQUEST,
  RECEIVE_NODES,
  NODES_REQUEST_ERROR,
  INITIATE_ADDRESSES_REQUEST,
  RECEIVE_ADDRESSES,
  ADDRESSES_REQUEST_ERROR,
  INITIATE_USERS_REQUEST,
  RECEIVE_USERS,
  USERS_REQUEST_ERROR,
  ARROW_UP,
  ARROW_DOWN,
  RESET_AUTOCOMPLETE,
  IS_REQUESTING,
  REQUESTS_RECEIVED,
  contextToData,
} from "./actions.js";
import _ from "lodash";

const initialState = {
  loading: false,
  geocodes: [],
  isRequestingGeocodes: false,
  nodes: [],
  isRequestingNodes: false,
  addresses: [],
  isRequestingAddresses: false,
  users: [],
  isRequestingUsers: false,
  // we use an index for the list. -1 means no element is selected
  highlightedIndex: -1,
  // We add cache
  geocodeCache: [],
};

// forced positive modulo
const modulo = (n, p) => ((n % p) + p) % p;

// size of stored cache = list of key+geocodes objects
const size_geocodeCache = 10;

export const autocomplete = (state = initialState, action) => {
  //useful to handle geocode cache
  let newgeocodeCache = state.geocodeCache;
  const maxIndex = (context) => {
    // to clean
    if (context === "search")
      return (
        (state.geocodes ? 1 : 0) + state.nodes.length + state.addresses.length
      );
    // basically we want to count all listitems for each data type
    return _.reduce(
      contextToData[context],
      (sum, dataType) => sum + _.get(state, dataType, []).length,
      0,
    );
  };
  switch (action.type) {
    // GEOCODES (we have max 1)
    case INITIATE_GEOCODES_REQUEST:
      return {
        ...state,
        isRequestingGeocodes: true,
      };
    case RECEIVE_GEOCODES:
      // remove action.key from cache if it already is in there
      _.remove(newgeocodeCache, { key: action.key });
      if (newgeocodeCache.length > size_geocodeCache) {
        // if cache is already full, remove first item before adding a new one
        newgeocodeCache.shift();
      }
      newgeocodeCache.push({ geocodes: action.geocodes, key: action.key });
      return {
        ...state,
        geocodes: action.geocodes,
        isRequestingGeocodes: false,
        geocodeCache: newgeocodeCache,
      };
    case GEOCODES_REQUEST_ERROR:
      return {
        ...state,
        geocodes: [],
        isRequestingGeocodes: false,
      };
    // NODES
    case INITIATE_NODES_REQUEST:
      return {
        ...state,
        isRequestingNodes: true,
      };
    case RECEIVE_NODES:
      return {
        ...state,
        nodes: action.nodes,
        isRequestingNodes: false,
      };
    case NODES_REQUEST_ERROR:
      return {
        ...state,
        nodes: [],
        isRequestingNodes: false,
      };
    // ADDRESSES
    case INITIATE_ADDRESSES_REQUEST:
      return {
        ...state,
        isRequestingAddresses: true,
      };
    case RECEIVE_ADDRESSES:
      return {
        ...state,
        addresses: action.addresses,
        isRequestingAddresses: false,
      };
    case ADDRESSES_REQUEST_ERROR:
      return {
        ...state,
        addresses: [],
        isRequestingAddresses: false,
      };
    // USERS
    case INITIATE_USERS_REQUEST:
      return {
        ...state,
        isRequestingUsers: true,
      };
    case RECEIVE_USERS:
      return {
        ...state,
        // New apiAxios endpoint sends user id in user attribute, previous one was sending it on id attribute.
        users: action.users.map((user) => {
          user.id = user.user;
          return user;
        }),
        isRequestingUsers: false,
      };
    case USERS_REQUEST_ERROR:
      return {
        ...state,
        users: [],
        isRequestingUsers: false,
      };
    case ARROW_UP:
      return {
        ...state,
        highlightedIndex: modulo(
          state.highlightedIndex - 1,
          maxIndex(action.context),
        ),
      };
    case ARROW_DOWN:
      // enter keyboard navigation
      if (state.highlightedIndex === -1) {
        return {
          ...state,
          highlightedIndex: 0,
        };
      }
      return {
        ...state,
        highlightedIndex: modulo(
          state.highlightedIndex + 1,
          maxIndex(action.context),
        ),
      };
    case RESET_AUTOCOMPLETE:
      return initialState;
    case IS_REQUESTING:
      return {
        ...state,
        loading: true,
      };
    case REQUESTS_RECEIVED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
