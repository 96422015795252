import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useSalesConditionsStyles = makeStyles((theme: CustomTheme) => ({
  gtcsLink: {
    margin: "0 0 0 4px",

    color: theme.palette.info.main,
    textDecoration: "underline",

    "& svg": {
      transform: "translateY(3px)",
    },
  },
  infoIcon: {
    margin: "0 0.5rem 0 0",

    transform: "translateY(6px)",

    "& path": {
      fill: theme.palette.radio.main,
    },
  },
  salesConditionsContainer: {
    margin: "1rem 1.5rem 0 1.5rem",

    color: theme.palette.radio.main,
    fontSize: "1rem",
    lineHeight: 1.75,
  },
}));
