import { makeStyles } from "@material-ui/core";

export const useSearchResultRideStyles = makeStyles(() => ({
  collapseHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  collapseHeaderText: {
    width: "calc(100% - 4rem)",
    textAlign: "center",
  },
  collapseIcon: {
    width: "4rem",
    textAlign: "center",
  },
  noRideSelected: {
    width: "100%",
    border: "0.125rem solid lightgrey",
  },
  proposalsList: {
    display: "flex",
    flexDirection: "column",
    gap: "1rem",

    margin: 0,
    padding: "0 16px",
  },
  someRideSelected: {
    width: "100%",
    border: "0.125rem solid #131589",
  },
}));
