import * as React from "react";
import * as ReactDOM from "react-dom";

import { Provider } from "react-redux";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";

import DayjsUtils from "@date-io/dayjs";
import "normalize.css";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

// Polyfill to fallback fetch API for Cypress (stubbing HTTP requests):
import "whatwg-fetch";

import { DayJsProvider } from "./provider/DayJsProvider";
import { I18nProvider } from "./provider/I18nProvider";
import { ProductContextProvider } from "./contexts/ProductContext";
import { UserContextProvider } from "./contexts/UserContext";
import { TerritoryContextProvider } from "./contexts/TerritoryContext";
import Router from "./routing/router";
import store from "./store/store.js";
import "./dayjsConfig";

import "./styles/global.css";
import "./styles/fonts.css";

if (process.env.NODE_ENV === "production" && process.env.SENTRY_PUBLIC_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_PUBLIC_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
    release:
      process.env.SENTRY_RELEASE ||
      process.env.DRONE_COMMIT ||
      process.env.COMMIT_HASH,
  });
  Sentry.configureScope((scope) => {
    scope.setTag("server_name", DEFAULT_TERRITORY || PRODUCT);
  });
}

// Workaround: declare global variable SIGNUP_AFTER_SEARCH if
// it's not already declared (in index.html) to avoid crash.
window["SIGNUP_AFTER_SEARCH"] =
  typeof SIGNUP_AFTER_SEARCH === "undefined" ? false : SIGNUP_AFTER_SEARCH;

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DayjsUtils}>
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: don't know how to solve Redux store typing issue
      // but it doesn't matter because it will disappear eventually
      <Provider store={store}>
        <ProductContextProvider>
          <UserContextProvider>
            <TerritoryContextProvider>
              <I18nProvider>
                <DayJsProvider>
                  <Router />
                </DayJsProvider>
              </I18nProvider>
            </TerritoryContextProvider>
          </UserContextProvider>
        </ProductContextProvider>
      </Provider>
    }
  </MuiPickersUtilsProvider>,
  document.getElementById("app-container"),
);
