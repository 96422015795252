import { makeStyles } from "@material-ui/core";

export const useDeleteEmergencyContactDialogStyles = makeStyles((theme) => ({
  actionButton: {
    minWidth: "initial",
    width: "calc(50% - 1rem)",
    margin: "1rem 0",

    "&:not(:first-child)": {
      margin: 0,
    },

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 2rem)",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    padding: "2rem 1rem 0.5rem 1rem",

    [theme.breakpoints.up("sm")]: {
      padding: "2rem 1.5rem 0.5rem 1.5rem",
    },
  },
  emergencyContactDialogContainer: {
    overflowY: "scroll",

    "& .MuiPaper-root": {
      maxWidth: "45rem",
    },

    "& .MuiDialog-paper": {
      width: "80%",
      minWidth: "300px",
      margin: "1rem",
    },

    "& .MuiDialogContent-root": {
      padding: "0.5rem 1rem",
      overflowY: "visible",

      [theme.breakpoints.up("sm")]: {
        padding: "0.5rem 1.5rem",
      },
    },
  },
  emergencyContactDialogContent: {
    margin: "2rem auto 0 auto",
  },
  emergencyContactDialogTitle: {
    "& h2": {
      textAlign: "center",
      fontSize: "1.35rem",
      fontWeight: 700,
    },

    [theme.breakpoints.up("sm")]: {
      "& h2": {
        fontSize: "1.5rem",
      },
    },
  },
}));
