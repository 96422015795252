import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useModifyRecurringDialogStyles = makeStyles(
  (theme: CustomTheme) => ({
    actionButton: {
      width: "calc(50% - 1rem)",

      [theme.breakpoints.up("sm")]: {
        width: "calc(50% - 2rem)",
      },
    },
    actionsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",

      padding: "2rem",
    },
    dialogTitle: {
      padding: "2rem",

      "& h2": {
        textAlign: "center",
        fontSize: "1.35rem",
        fontWeight: 700,
      },

      [theme.breakpoints.up("sm")]: {
        padding: "2rem 4rem",

        "& h2": {
          fontSize: "1.5rem",
        },
      },
    },
    infoBox: {
      margin: "10rem 0 0 0",
    },
  }),
);
