import React from "react";

import { SvgIcon } from "@material-ui/core";

type PassengersIconProps = {
  className?: string;
  fill?: string;
};

export function PassengersIcon({
  className,
  fill = "#6C6C6C",
}: PassengersIconProps) {
  return (
    <SvgIcon className={className} viewBox="0 0 35 35">
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12 18C15.5899 18 18.5 15.0899 18.5 11.5C18.5 7.91015 15.5899 5 12 5C8.41015 5 5.5 7.91015 5.5 11.5C5.5 15.0899 8.41015 18 12 18Z"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 3.24361C18.894 2.99172 19.8317 2.93434 20.7497 3.07533C21.6678 3.21632 22.545 3.55241 23.3222 4.06096C24.0995 4.56952 24.7587 5.23873 25.2555 6.02351C25.7524 6.8083 26.0753 7.69044 26.2024 8.61052C26.3296 9.5306 26.2582 10.4673 25.9929 11.3574C25.7276 12.2475 25.2747 13.0705 24.6646 13.7709C24.0544 14.4712 23.3013 15.0327 22.4559 15.4175C21.6106 15.8022 20.6925 16.0014 19.7637 16.0015"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.9297 16C21.6948 15.9999 23.4341 16.4246 25.0006 17.238C26.5671 18.0515 28.3011 19.8478 28.9301 20.6739C29.5592 21.5 30.9296 24 31.4296 26.5"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.5 28.5C23 26 21.6296 23.5 21.0006 22.6739C20.3715 21.8478 18.6375 20.0515 17.071 19.238C15.5045 18.4246 13.7652 17.9999 12.0001 18C10.235 18.0001 8.49576 18.4248 6.92931 19.2384C5.36287 20.052 4.01517 21.2306 3 22.6746C2.5 23.4497 1.5 25.5 1 28.5"
          stroke={fill}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
