import * as React from "react";
import { Chip, Typography } from "@material-ui/core";

import { FixedLinesLineType } from "components/organisms/FixedLinesResultElement/FixedLinesTypes";

import { useFixedLinesLineStyles } from "./fixedLinesLineStyles";
import { FormattedMessage } from "react-intl";

type FixedLinesLineProps = {
  line: FixedLinesLineType;
  showDestination?: boolean;
  mode?: string;
};

export function FixedLinesLine({
  line,
  showDestination,
  mode,
}: FixedLinesLineProps) {
  const classes = useFixedLinesLineStyles();

  const lineStyle = {
    backgroundColor: "#" + line?.color,
    color: "#" + line?.text_color,
    marginRight: "10px",
    borderRadius: "3px",
    minWidth: "3rem",
  };
  return (
    <div>
      {line?.code && <Chip style={lineStyle} label={line.code} />}
      <Typography className={classes.subtitle}>
        {mode && <FormattedMessage id={`research.line_type.${mode}`} />}
      </Typography>
      {line?.code && showDestination && "→ " + line.destination}
    </div>
  );
}
