import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { settings } from "config/app";
import {
  API_ERROR_CODES,
  API_VERSION,
  APP_VERSION,
  CONTENT_TYPE,
  CONTENT_TYPES,
  HTTP_METHOD,
  HTTP_STATUS,
  SIGNIN_PATH,
  TIMEOUT,
  UNAUTHORIZED,
  USER_GROUP,
} from "./constants";
import { apiResources } from "./resources";
import { ApiType, FiltersType } from "./types/api";
import cookie from "lib/cookie";
import { http } from "./httpAxios";
import { getLang } from "../i18n";

const instance: AxiosInstance = axios.create({
  baseURL: settings.api.baseURL.api,
  timeout: TIMEOUT,
  headers: {
    [CONTENT_TYPE]: CONTENT_TYPES.JSON,
  },
});

instance.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response?.data;
  },
  (error: AxiosError<any>) => {
    if (
      error?.response?.status === HTTP_STATUS.UNAUTHORIZED &&
      !error?.response?.config?.url?.includes(SIGNIN_PATH) &&
      error?.response?.data?.detail?.code === API_ERROR_CODES.USER_SUSPENDED
    ) {
      // Error code 179 means that the user we are trying to impersonate is suspended
      return Promise.reject({
        reason: API_ERROR_CODES.USER_SUSPENDED,
        message: error?.response?.data?.detail?.message,
      });
    }
    if (
      error?.response?.status === HTTP_STATUS.UNAUTHORIZED &&
      !error?.response?.config?.url?.includes(SIGNIN_PATH)
    ) {
      // 401 means auth token has changed, we clear the cookie, unless we are authenticating
      cookie.erase(settings.cookieKeys.authCookie);
      return Promise.reject({ reason: UNAUTHORIZED });
    }

    if (typeof error?.response?.data === "object") {
      return Promise.reject(error?.response?.data);
    }

    return Promise.reject(error);
  },
);

const api: ApiType = {
  UNAUTHORIZED: async () => UNAUTHORIZED,
};

interface territoryQueryType extends FiltersType {
  app_version: string;
  user_group: string;
  language?: string;
}
const territoryQueries: territoryQueryType = {
  app_version: APP_VERSION,
  user_group: USER_GROUP,
};

/**
 * Check if payload has error
 * --------------------------------
 * Compare payload with requiredParameters
 *
 * @param payload
 * @param information
 * @return {Promise<string | null>}
 */
const comparePayloadAndRequiredParameters = (payload, information) => {
  if (
    ![HTTP_METHOD.POST, HTTP_METHOD.PUT, HTTP_METHOD.PATCH].includes(
      information.method,
    )
  ) {
    return null;
  }
  if (!information.requiredParameters) {
    return null;
  }
  const missingParameters: string[] = [];
  const typeErrorParameters: string[] = [];
  for (const [key, type] of Object.entries(information.requiredParameters)) {
    if (!payload.hasOwnProperty(key)) {
      missingParameters.push(key);
    }
    if (type === "array" && !Array.isArray(payload[key])) {
      typeErrorParameters.push(`${key} should be an array`);
    }
    if (typeof payload[key] !== type) {
      typeErrorParameters.push(
        `${key} should be a ${type} / value: "${
          payload[key]
        }" is a ${typeof payload[key]}`,
      );
    }
  }
  let msg: string | null = null;
  if (missingParameters.length > 0) {
    msg = `Missing parameters: ${missingParameters.join(", ")}`;
  }
  if (typeErrorParameters.length > 0) {
    if (!msg) {
      msg = "";
    }
    if (msg) {
      msg += "\n";
    }
    msg += `Type error: ${typeErrorParameters.join(", ")}`;
  }
  if (!msg) {
    return null;
  }

  return Promise.reject(msg);
};

/**
 * Parse parameters and remove properties null/undefined/empty
 * @param queryParameters
 * @return {void}
 */
export const removeNotDefinedParameters = (queryParameters) => {
  if (!queryParameters) {
    return;
  }
  for (const [key, value] of Object.entries(queryParameters)) {
    if (value === null || value === undefined || value === "") {
      delete queryParameters[key];
    }
  }
};

for (const [name, information] of Object.entries(apiResources)) {
  let baseUrl: string | undefined = undefined;

  if (information.apiVersion === API_VERSION.NEW) {
    baseUrl = settings.api.baseURL.newApi;
  }
  if (information.adminzone) {
    baseUrl = settings.api.baseURL.adminzone;
  }

  information.endpoint;

  // @ts-ignore
  api[name] = ({ payload, urlParameters, queryParameters }) => {
    const payloadErrors = comparePayloadAndRequiredParameters(
      payload,
      information,
    );
    if (payloadErrors) {
      return payloadErrors;
    }
    removeNotDefinedParameters(queryParameters);

    territoryQueries.language = getLang();

    const queryParametersToSend: FiltersType = {
      ...queryParameters,
      ...territoryQueries,
    };

    return http.request({
      axiosInstance: instance,
      method: information.method,
      url: information.endpoint,
      baseUrl: baseUrl,
      apiVersion: information.apiVersion,
      impersonate: information?.impersonate,
      payload: payload,
      urlParameters: urlParameters,
      queryParameters: queryParametersToSend,
      authenticationRequired: information.authenticationRequired ?? true,
    });
  };
}

export default api;
