import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useReservationListItemStyles = makeStyles(
  (theme: CustomTheme) => ({
    actionsContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",

      width: "100%",
      margin: "0.5rem 0 0 0",

      [theme.breakpoints.up("sm")]: {
        width: "unset",
        margin: 0,
      },
    },
    deleteButton: {
      margin: "0 0 0 1.5rem",
      padding: 0,

      opacity: 0.75,

      transition: "all 300ms",

      "&:hover": {
        opacity: 1,
      },
    },
    deleteIcon: {
      width: "3rem",
      height: "3rem",
      padding: "0.75rem",
      borderRadius: "50%",

      color: "#d0021b",

      backgroundColor: "#fae0e0",
    },
    reservationContainer: {
      margin: "0.75rem 0",
      padding: "1rem 1rem 0.75rem 1rem",
      borderRadius: "1rem",

      "& .MuiButtonBase-root": {
        width: "max-content",
      },

      [theme.breakpoints.up("sm")]: {
        width: "auto",
      },
    },
    reservationDate: {
      margin: "0 1rem 0 0",

      fontWeight: 500,
    },
    reservationHeader: {
      display: "flex",
      flexWrap: "wrap",
      alignItems: "center",

      margin: "0 0 0.75rem 0",
    },
    timedRideContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",

      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",

        margin: "0 0 0 2rem",
      },
    },
  }),
);
