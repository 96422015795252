import React from "react";
import ReactDOM from "react-dom";
import { useIntl } from "react-intl-phraseapp";

import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";

import CloseIcon from "../Icons/Close";

export const Modal = ({ isShowing, hide, closable = true, children }) => {
  const intl = useIntl();

  if (!isShowing) return null;

  return ReactDOM.createPortal(
    <Dialog
      open={isShowing}
      onClose={closable ? hide : undefined}
      maxWidth="md"
    >
      {closable && (
        <IconButton
          aria-label={intl.formatMessage({ id: "misc.close_dialog" })}
          onClick={hide}
          style={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 1400,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      )}
      {children}
    </Dialog>,
    document.body,
  );
};
