import api from "api";

type IsPhoneNumberValidated =
  | {
      validated: false;
      phoneCode: string;
    }
  | { validated: true };

/*
 * Hack to know if the phone number is validated by calling
 * validate-booking with an invalid payment method.
 * Ask Kiefer for more information.
 */
export function isPhoneNumberValidated(): IsPhoneNumberValidated {
  return api
    .validateBooking({
      payment: {
        method: "EMPTY",
      },
    })
    .catch(({ infos, status }) => {
      return status === 409
        ? { validated: false, phoneCode: infos.phone_code }
        : { validated: true };
    });
}
