import * as React from "react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { CounterButtons } from "molecules/CounterButtons/CounterButtons";

import { useDiscountsDialogStyles } from "./discountsDialogStyles";
import { loadDiscountedPrices } from "containers/Payment/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  PERCENTAGE,
  SUBTRACTION,
} from "containers/Payment/PaymentDiscounts/PaymentDiscountsConstants";
import type { StateInterface } from "../types";

export const DiscountsDialog = (props) => {
  const { maxDiscountQuantity, onClose } = props;

  const dispatch = useDispatch();
  const classes = useDiscountsDialogStyles();
  const selectedDiscounts = useSelector(
    (state: StateInterface) => state?.payment?.selectedDiscounts,
  );
  const applicableDiscounts = useSelector(
    (state: StateInterface) => state?.payment?.applicableDiscounts,
  );
  const isApplicableDiscountsLoading = useSelector(
    (state: StateInterface) => state?.payment?.isApplicableDiscountsLoading,
  );
  const selectedProposals = useSelector(
    (state: StateInterface) => state?.search?.selectedProposals,
  );
  const responses = useSelector(
    (state: StateInterface) => state?.search?.responses,
  );
  const promoCode = useSelector(
    (state: StateInterface) => state?.payment?.promoCode,
  );
  const isPromoCodeValid = useSelector(
    (state: StateInterface) => state?.payment?.isPromoCodeValid,
  );
  const [currentlySelectedDiscounts, setCurrentlySelectedDiscounts] =
    useState<any>(null);

  useEffect(() => {
    setCurrentlySelectedDiscounts(selectedDiscounts);
  }, [selectedDiscounts]);

  const discountSchemes = applicableDiscounts?.filter(
    (discount) => discount.discount_type === PERCENTAGE,
  );
  const flatFares = applicableDiscounts?.filter(
    (discount) => discount.discount_type === SUBTRACTION,
  );

  const privateDiscountsIds = applicableDiscounts
    ?.filter((applicableDiscount) => !applicableDiscount.is_public)
    .map((applicableDiscount) => applicableDiscount.id);

  const updateSelectedDiscounts = (discountId, newValue) => {
    const data = (previouslySelectedDiscounts) => {
      const newSelectedDiscounts = [...previouslySelectedDiscounts];
      const selectedDiscount = newSelectedDiscounts?.find(
        (selectedDiscount) => selectedDiscount.discountId === discountId,
      );

      if (selectedDiscount === undefined) {
        newSelectedDiscounts.push({ discountId, quantity: newValue });
      } else {
        selectedDiscount.quantity = newValue;
      }

      return newSelectedDiscounts;
    };
    setCurrentlySelectedDiscounts(data);
    // const selectedDiscount = selectedDiscounts?.find((discount) => discount.discountId === discountId)
    // dispatch(setSelectedDiscounts([...selectedDiscounts, {discountId, quantity: newValue}]))
  };

  const getMaxDiscountQuantity = (discountId) => {
    // Calculate the maximum selectable quantity for a given discount
    // considering :
    // - the total maximum selectable quantity
    // - the currently selected quantity of all discounts
    // - the currently selected quantity for the given discount
    // - that only one private discount from each type can be selected

    const totalSelectedDiscountQuantity = currentlySelectedDiscounts?.reduce(
      (totalQuantity, selectedDiscount) =>
        totalQuantity + selectedDiscount.quantity,
      0,
    );
    const givenDiscount = currentlySelectedDiscounts?.find(
      (selectedDiscount) => selectedDiscount.discountId === discountId,
    );

    const maxQuantity =
      maxDiscountQuantity -
      totalSelectedDiscountQuantity +
      (givenDiscount?.quantity || 0);

    if (privateDiscountsIds?.includes(discountId)) {
      return maxQuantity > 0 ? 1 : 0;
    }

    return maxQuantity;
  };

  const handleSubmit = () => {
    dispatch(
      loadDiscountedPrices({
        selectedProposals: selectedProposals,
        responses: responses,
        promoCode: promoCode,
        isPromoCodeValid: isPromoCodeValid,
        newSelectedDiscounts: currentlySelectedDiscounts,
      }),
    );
    onClose();
  };

  return (
    <Dialog
      className={classes.dialogContainer}
      open
      onClose={onClose}
      aria-labelledby="discountDialogName"
    >
      <Alert className={classes.alertMessage} severity="info">
        <FormattedMessage
          id="ticketing.supporting_document_information"
          defaultMessage="A supporting document will be required when entering the vehicle"
        />
      </Alert>

      {isApplicableDiscountsLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress />
        </div>
      )}

      {flatFares?.length ? (
        <>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Typography variant="h2">
              <FormattedMessage
                id="ticketing.flat_fares"
                defaultMessage="Flat fares"
              />
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {flatFares.map((applicableDiscount, i) => {
              const selectedDiscount = selectedDiscounts?.find(
                (selectedDiscount) =>
                  selectedDiscount.discountId === applicableDiscount.id,
              );

              return (
                <CounterButtons
                  key={i}
                  label={applicableDiscount.name}
                  defaultValue={selectedDiscount?.quantity}
                  minValue={0}
                  maxValue={getMaxDiscountQuantity(applicableDiscount.id)}
                  onChange={(newValue) =>
                    updateSelectedDiscounts(applicableDiscount.id, newValue)
                  }
                />
              );
            })}
          </DialogContent>
        </>
      ) : null}

      {discountSchemes?.length ? (
        <>
          <DialogTitle className={classes.dialogTitle} disableTypography>
            <Typography variant="h2">
              <FormattedMessage
                id="ticketing.reductions_or_subscriptions"
                defaultMessage="Reductions or subscriptions"
              />
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            {discountSchemes.map((applicableDiscount, i) => {
              const selectedDiscount = selectedDiscounts?.find(
                (selectedDiscount) =>
                  selectedDiscount.discountId === applicableDiscount.id,
              );

              return (
                <CounterButtons
                  key={i}
                  label={applicableDiscount.name}
                  defaultValue={selectedDiscount?.quantity || 0}
                  minValue={0}
                  maxValue={getMaxDiscountQuantity(applicableDiscount.id)}
                  onChange={(newValue) =>
                    updateSelectedDiscounts(applicableDiscount.id, newValue)
                  }
                />
              );
            })}
          </DialogContent>
        </>
      ) : null}

      <DialogActions className={classes.actionsContainer}>
        <Button
          onClick={onClose}
          variant="outlined"
          color="primary"
          className={classes.actionButton}
        >
          <FormattedMessage id="misc.cancel" />
        </Button>

        <Button
          onClick={handleSubmit}
          type="submit"
          variant="contained"
          color="primary"
          className={classes.actionButton}
        >
          <FormattedMessage id="misc.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};
