import * as React from "react";

import { useSeparatorStyles } from "./separatorStyles";

export function Separator() {
  const classes = useSeparatorStyles();

  return (
    <div className={classes.separatorContainer}>
      <div className={classes.separator} />
    </div>
  );
}
