import React from "react";
import { withTheme } from "@material-ui/core/styles";

const Clock = ({ className, width = 24, height = 24, theme, color }) => (
  <svg
    className={className}
    style={{ fill: color ? color : theme.palette.primary.main }}
    id="clock"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    focusable="false"
    role="none"
    aria-hidden="true"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#717587">
        <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.25c-4.832 0-8.75 3.918-8.75 8.75s3.918 8.75 8.75 8.75 8.75-3.918 8.75-8.75S16.832 3.25 12 3.25zm0 2.083c.46 0 .833.373.833.834v5.832L17 12c.46 0 .833.373.833.833 0 .46-.373.834-.833.834h-5c-.46 0-.833-.373-.833-.834V6.167c0-.46.373-.834.833-.834z" />
      </g>
    </g>
  </svg>
);

export default withTheme(Clock);
