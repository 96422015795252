import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const Globe = (props) => {
  return (
    <SvgIcon {...props} aria-label="external link" x="0px" y="0px">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 3.26562C6.72842 3.26562 3.26562 6.72842 3.26562 11C3.26562 15.2716 6.72842 18.7344 11 18.7344C15.2716 18.7344 18.7344 15.2716 18.7344 11C18.7344 6.72842 15.2716 3.26562 11 3.26562ZM2.23438 11C2.23438 6.15888 6.15888 2.23438 11 2.23438C15.8411 2.23438 19.7656 6.15888 19.7656 11C19.7656 15.8411 15.8411 19.7656 11 19.7656C6.15888 19.7656 2.23438 15.8411 2.23438 11Z"
        fill="#1D1A3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.70418 8.25C2.70418 7.96523 2.93503 7.73438 3.2198 7.73438H18.7801C19.0648 7.73438 19.2957 7.96523 19.2957 8.25C19.2957 8.53477 19.0648 8.76562 18.7801 8.76562H3.2198C2.93503 8.76562 2.70418 8.53477 2.70418 8.25Z"
        fill="#1D1A3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.7045 13.75C2.7045 13.4652 2.93536 13.2344 3.22013 13.2344H18.7803C19.0651 13.2344 19.2959 13.4652 19.2959 13.75C19.2959 14.0348 19.0651 14.2656 18.7803 14.2656H3.22013C2.93536 14.2656 2.7045 14.0348 2.7045 13.75Z"
        fill="#1D1A3F"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.04332 5.52592C8.45475 6.90059 8.07812 8.83561 8.07812 11C8.07812 13.1644 8.45475 15.0994 9.04332 16.4741C9.33801 17.1623 9.67567 17.6863 10.0243 18.0308C10.3708 18.3731 10.6999 18.5129 11 18.5129C11.3001 18.5129 11.6292 18.3731 11.9757 18.0308C12.3243 17.6863 12.662 17.1623 12.9567 16.4741C13.5453 15.0994 13.9219 13.1644 13.9219 11C13.9219 8.83561 13.5453 6.90059 12.9567 5.52592C12.662 4.83766 12.3243 4.31365 11.9757 3.96921C11.6292 3.62694 11.3001 3.48709 11 3.48709C10.6999 3.48709 10.3708 3.62694 10.0243 3.96921C9.67567 4.31365 9.33801 4.83766 9.04332 5.52592ZM9.2996 3.23557C9.77564 2.7653 10.3508 2.45584 11 2.45584C11.6492 2.45584 12.2244 2.7653 12.7004 3.23557C13.1742 3.70366 13.5773 4.3554 13.9047 5.12002C14.5602 6.65111 14.9531 8.73035 14.9531 11C14.9531 13.2697 14.5602 15.3489 13.9047 16.88C13.5773 17.6446 13.1742 18.2963 12.7004 18.7644C12.2244 19.2347 11.6492 19.5442 11 19.5442C10.3508 19.5442 9.77564 19.2347 9.2996 18.7644C8.82576 18.2963 8.4227 17.6446 8.09532 16.88C7.43976 15.3489 7.04688 13.2697 7.04688 11C7.04688 8.73035 7.43976 6.65111 8.09532 5.12002C8.4227 4.3554 8.82576 3.70366 9.2996 3.23557Z"
        fill="#1D1A3F"
      />
    </SvgIcon>
  );
};
