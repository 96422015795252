import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useTimedRideStepStyles = makeStyles((theme: CustomTheme) => ({
  durationBadge: {
    display: "inline-block",
    position: "relative",
    left: "-3.5rem",

    margin: "0 0 1.25rem 0",
  },
  nodeName: {
    fontWeight: 500,
  },
  nodeNameAndAddress: {
    color: "#6C6C6C",
    fontSize: "1.2rem",
    fontStyle: "normal",
  },
  stepContainer: {
    position: "relative",
    display: "flex",
  },
  timeOrDuration: {
    color: "#6C6C6C",
    fontWeight: 500,
    margin: "0.75rem 0 0.375rem 0",
    padding: "0.5rem 1rem 0.5rem 1rem",
    borderRadius: "1rem",
    width: "fit-content",
    background: "#f4f4f4",
  },
  dataContainer: {
    marginRight: "0.5rem",
  },
  estimatedTime: {
    color: "#151515",
    marginLeft: "1rem",
    marginBottom: "0.75rem",
    fontSize: "1rem",
  },
  referencePickupTime: {
    marginBottom: "0.75rem",
    color: theme.palette.success.main,
    marginLeft: "1rem",
    display: "flex",
  },
  dropOffPickUpLabel: {
    color: "#6C6C6C",
    fontSize: "1rem",
    fontStyle: "normal",
  },
  labelConfirmed: {
    color: theme.palette.success.main,
  },
  inlineLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre-wrap",

    "& > svg": {
      margin: "0 0.5rem",
    },
  },
  column: {
    display: "flex",
    flexDirection: "column",
  },
  lineDescription: {
    position: "relative",
    left: "-3.5rem",
  },
}));
