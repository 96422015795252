import api from "api";
import { snackMessage } from "containers/SnackBar/actions.js";
import * as Sentry from "@sentry/react";
import { PaymentMethodEnum } from "types/payment";

export const REQUEST_PROMOCODE = "payment @@ REQUEST_PROMOCODE";
export const REQUEST_PROMOCODE_ERROR = "payment @@ REQUEST_PROMOCODE_ERROR";
export const CONFIRM_PROMOCODE = "payment @@ CONFIRM_PROMOCODE";

export const SET_TOTAL_PRICE = "payment @@ SET_TOTAL_PRICE";

export const REQUEST_SAVED_PAYMENT_METHODS =
  "payment @@ REQUEST_SAVED_PAYMENT_METHODS";
export const REQUEST_SAVED_PAYMENT_METHODS_ERROR =
  "payment @@ REQUEST_SAVED_PAYMENT_METHODS_ERROR";
export const RECEIVE_SAVED_CARDS = "payment @@ RECEIVE_SAVED_CARDS";
export const RECEIVE_SAVED_IBANS = "payment @@ RECEIVE_SAVED_IBANS";
export const SELECT_CARD = "payment @@ SELECT_CARD";
export const SELECT_IBAN = "payment @@ SELECT_IBAN";
export const SHOW_CARD_FORM = "payment @@ SHOW_CARD_FORM";
export const HIDE_CARD_FORM = "payment @@ HIDE_CARD_FORM";
export const SHOW_IBAN_FORM = "payment @@ SHOW_IBAN_FORM";
export const HIDE_IBAN_FORM = "payment @@ HIDE_IBAN_FORM";
export const SET_SELECTED_DISCOUNTS = "payment @@ SET_SELECTED_DISCOUNTS";
export const SET_DISCOUNT_PRICE_LOADING =
  "payment @@ SET_DISCOUNT_PRICE_LOADING";
export const SET_DISCOUNTED_PRICE = "payment @@ SET_DISCOUNT_PRICE";
export const SET_DISCOUNTED_PRICE_MULTI_DATE =
  "payment @@ SET_DISCOUNT_PRICE_MULTI_DATE";
export const RESET_PROMO_CODE = "payment @@ RESET_PROMOCODE";
export const SET_APPLICABLE_DISCOUNTS_LOADING =
  "payment @@ SET_APPLICABLE_DISCOUNTS_LOADING";
export const SET_APPLICABLE_DISCOUNTS = "payment @@ SET_APPLICABLE_DISCOUNTS";
export const SET_SELECTED_TRIP_PROPOSAL_ID =
  "payment @@ SET_SELECTED_TRIP_PROPOSAL_ID";
export const RESET_TICKETING = "payment @@ RESET_TICKETING";

export const REQUEST_STRIPE_PAYMENT = "stripe @@ REQUEST_STRIPE_PAYMENT";
export const REQUEST_STRIPE_PAYMENT_ERROR =
  "stripe @@ REQUEST_STRIPE_PAYMENT_ERROR";
export const RECEIVE_STRIPE_TOKEN = "stripe @@ RECEIVE_STRIPE_TOKEN";
export const SET_PAYPAL_PAYMENT = "paypal @@ SET_PAYPAL_PAYMENT";
export const REQUEST_PAYPAL_PAYMENT_ERROR =
  "paypal @@ REQUEST_PAYPAL_PAYMENT_ERROR";

const PROMO_CODE_NOT_AUTHORIZED = 650;
const PROMO_CODE_ERROR_INVALID = 691;
const PROMO_CODE_ERROR_CANNOT_USE_MORE_THAN_ONCE = 692;
const PROMO_CODE_ERROR_EXPIRED = 693;
const PROMO_CODE_ERROR_LIMIT_USAGE = 694;
const PROMO_CODE_ERROR_MIN_AMOUNT_IS_BELOW = 695;
const PROMO_CODE_ERROR_NOT_VALID_FOR_NUMBER_OF_RIDERS = 696;
const PROMO_CODE_ERROR_NOT_VALID_FOR_RIDE_LEAVING_FROM_THIS_PICKUP_AREA = 697;
const PROMO_CODE_ERROR_NOT_VALID_FOR_THIS_DESTINATION = 698;
const PROMO_CODE_ERROR_ALREADY_USED = 699;

export const showForm = (method) => (dispatch) => {
  let type = SHOW_CARD_FORM;
  if (method === PaymentMethodEnum.STRIPE_SEPA) {
    type = SHOW_IBAN_FORM;
  }
  dispatch({
    type,
  });
};

export const hideForm = (method) => (dispatch) => {
  let type = HIDE_CARD_FORM;
  if (method === PaymentMethodEnum.STRIPE_SEPA) {
    type = HIDE_IBAN_FORM;
  }
  dispatch({
    type,
  });
};

export const setTotalPrice = (newPrice) => (dispatch) => {
  dispatch({
    type: SET_TOTAL_PRICE,
    newPrice,
  });
};

export const selectPaymentMethod = (method, index) => (dispatch) => {
  if (method === PaymentMethodEnum.STRIPE_CARD) {
    dispatch({
      type: SELECT_CARD,
      index,
    });
  }
  if (method === PaymentMethodEnum.STRIPE_SEPA) {
    dispatch({
      type: SELECT_IBAN,
      index,
    });
  }
};

const requestCheckPromoCode = (
  dispatch,
  { bookingInfoId, selectedProposal, promoCode, selectedTerritoryKey },
) => {
  return api.checkPromoCode(
    {
      reservation_history_id: bookingInfoId,
      customer_proposition_id: selectedProposal,
      promo_code: promoCode,
    },
    { territory: selectedTerritoryKey },
  );
};

export const checkPromoCode =
  ({ promoCode, selectedTerritoryKey }) =>
  (dispatch, getState, getIntl) => {
    if (!promoCode) return;

    const intl = getIntl();
    const state = getState();

    const searchRequests = state?.search?.responses;
    if (_.isEmpty(searchRequests))
      return dispatch(
        snackMessage("info", intl.formatMessage({ id: "ride.no_trips" })),
      );

    const selectedProposals = state?.search?.selectedProposals;
    const requests = [];
    for (const [searchDate, booking] of Object.entries(searchRequests)) {
      const bookingInfoId = booking.reservation_info?.id;
      const selectedProposal = selectedProposals[searchDate];
      if (bookingInfoId && selectedProposal) {
        requests.push(
          new Promise((resolve) => {
            requestCheckPromoCode(dispatch, {
              bookingInfoId,
              selectedProposal,
              promoCode,
              selectedTerritoryKey,
            })
              .then((response) => {
                resolve({ searchDate, response });
              })
              .catch((error) => {
                resolve({ searchDate, error });
              });
          }),
        );
      }
    }

    dispatch({
      type: REQUEST_PROMOCODE,
      promoCode,
    });
    let remainingUsageCount = null;
    Promise.all(requests).then((responses) => {
      const isPromoCodeValid = {};
      let errorMessage = "";
      const errorCodesInvalid = [
        PROMO_CODE_ERROR_INVALID,
        PROMO_CODE_NOT_AUTHORIZED,
      ];
      const expiredCodes = [
        PROMO_CODE_ERROR_EXPIRED,
        PROMO_CODE_ERROR_LIMIT_USAGE,
      ];
      const errorCodesBooking = [
        PROMO_CODE_ERROR_CANNOT_USE_MORE_THAN_ONCE,
        PROMO_CODE_ERROR_MIN_AMOUNT_IS_BELOW,
        PROMO_CODE_ERROR_NOT_VALID_FOR_NUMBER_OF_RIDERS,
        // PROMO_CODE_ERROR_NOT_VALID_FOR_RIDE_LEAVING_FROM_THIS_PICKUP_AREA,
        // PROMO_CODE_ERROR_NOT_VALID_FOR_THIS_DESTINATION,
        // PROMO_CODE_ERROR_ALREADY_USED,
      ];

      responses.forEach((apiResponse) => {
        const { searchDate, response, error } = apiResponse;
        if (remainingUsageCount === null) {
          remainingUsageCount = response?.remaining_usage_count;
        }

        if (error) {
          let message = "";
          if (expiredCodes.includes(error?.infos?.detail?.code)) {
            message = intl.formatMessage({ id: "error.promocode_expired" });
          }
          if (errorCodesBooking.includes(error?.infos?.detail?.code)) {
            message = intl.formatMessage({
              id: "error.promocode_invalid_on_booking",
            });
          }
          if (errorCodesInvalid.includes(error?.infos?.detail?.code)) {
            message = intl.formatMessage({ id: "error.promocode_invalid" });
          }

          errorMessage = message;

          isPromoCodeValid[searchDate] = false;
          return;
        }

        if (!response) {
          isPromoCodeValid[searchDate] = false;
          return;
        }

        if (remainingUsageCount <= 0) {
          isPromoCodeValid[searchDate] = false;
          return;
        }
        remainingUsageCount--;

        isPromoCodeValid[searchDate] = true;
      });

      const isError = Object.values(isPromoCodeValid).every((v) => v === false);

      if (isError) {
        dispatch({
          type: REQUEST_PROMOCODE_ERROR,
          errorMessage: isError ? [errorMessage] : "",
        });
        return;
      }

      dispatch({
        type: CONFIRM_PROMOCODE,
        isPromoCodeValid: isPromoCodeValid,
      });

      const promoCodeState = Object.values(isPromoCodeValid);
      const id =
        remainingUsageCount && remainingUsageCount > 1
          ? "success.promocode_applied_multidate"
          : "success.promocode_applied_monodate";
      dispatch(
        snackMessage(
          "success",
          intl.formatMessage(
            {
              id: id,
            },
            {
              remainingUsageCount: promoCodeState.filter(
                (state) => state === true,
              ).length,
            },
          ),
        ),
      );
    });
  };

export const loadDiscountedPrices =
  ({
    selectedProposals = [],
    responses = {},
    promoCode = "",
    isPromoCodeValid = {},
    newSelectedDiscounts = null,
  }) =>
  (dispatch, getState) => {
    const state = getState();

    const isDiscountMultiDate = Object.keys(selectedProposals).length > 1;
    const requests = [];
    const selectedDiscountsState = state?.payment?.selectedDiscounts;
    for (const [datetime, proposal] of Object.entries(selectedProposals)) {
      const id = selectedProposals[datetime];
      const ride = responses[datetime];
      const booking = ride?.reservation_info?.proposed_datetimes.find(
        (proposed_datetime) => proposed_datetime.id === id,
      );

      const payload = {
        customer_proposition_id: proposal,
      };

      let selectedDiscounts = null;
      if (!selectedDiscountsState?.length) {
        selectedDiscounts = booking?.discount_info?.map((discountInfo) => ({
          discountId: discountInfo?.discount_profile_id,
          quantity: discountInfo?.passengers_number,
        }));
      } else {
        selectedDiscounts = selectedDiscountsState;
      }
      if (newSelectedDiscounts) {
        selectedDiscounts = newSelectedDiscounts;
      }

      const hasDiscounts =
        selectedDiscounts?.reduce((_, discount) => !!discount?.quantity, 0) > 0;

      if (hasDiscounts) {
        dispatch({
          type: SET_SELECTED_DISCOUNTS,
          selectedDiscounts: selectedDiscounts.filter((selectedDiscount) =>
            Boolean(selectedDiscount.quantity),
          ),
        });
        payload.discount_info = selectedDiscounts.map((selectedDiscount) => ({
          discount_profile_id: selectedDiscount.discountId,
          passengers_number: selectedDiscount.quantity,
        }));
      }
      if (
        promoCode &&
        Object.keys(isPromoCodeValid || []).includes(datetime) &&
        isPromoCodeValid[datetime]
      ) {
        payload.promo_code = promoCode;
      }

      requests.push(
        new Promise((resolve, reject) => {
          api
            .getCustomerPropositionPrice(payload)
            .then((response) => {
              resolve({ datetime, response });
            })
            .catch((error) => {
              reject(error);
            });
        }),
      );
    }

    dispatch({
      type: SET_DISCOUNT_PRICE_LOADING,
      isDiscountPriceLoading: true,
    });

    Promise.all(requests)
      .then((responses) => {
        if (!isDiscountMultiDate) {
          dispatch({
            type: SET_DISCOUNTED_PRICE,
            discountedPrices: responses[0].response,
          });
        } else {
          const discountedPricesMultiDate = {};
          for (const value of Object.values(responses)) {
            discountedPricesMultiDate[value.datetime] = value.response;
          }

          dispatch({
            type: SET_DISCOUNTED_PRICE_MULTI_DATE,
            discountedPricesMultiDate: { ...discountedPricesMultiDate },
          });
        }
        const newTotalPrice = Object.values(responses).reduce(
          (acc, value) => acc + value.response.price,
          0,
        );

        dispatch({
          type: SET_TOTAL_PRICE,
          totalPrice: newTotalPrice,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        dispatch({
          type: SET_DISCOUNT_PRICE_LOADING,
          isDiscountPriceLoading: false,
        });
      });
  };

export const unsetPromoCode = () => (dispatch) => {
  dispatch({
    type: REQUEST_PROMOCODE,
    promoCode: "",
  });
  dispatch({
    type: CONFIRM_PROMOCODE,
    isPromoCodeValid: {},
  });
};

export const resetPromoCode = () => (dispatch) => {
  dispatch({
    type: RESET_PROMO_CODE,
  });
};

export const loadApplicableDiscounts =
  ({ selectedTripProposalId }) =>
  (dispatch) => {
    dispatch({
      type: SET_APPLICABLE_DISCOUNTS_LOADING,
      isApplicableDiscountsLoading: true,
    });
    dispatch({
      type: SET_SELECTED_TRIP_PROPOSAL_ID,
      selectedTripProposalId: selectedTripProposalId,
    });
    api
      .getApplicableDiscountProfilesForCustomerProposition({
        customer_proposition_id: selectedTripProposalId,
      })
      .then((response) => {
        dispatch({
          type: SET_APPLICABLE_DISCOUNTS,
          applicableDiscounts: response,
        });
      })
      .catch((error) => {
        Sentry.captureException(error);
      })
      .finally(() => {
        dispatch({
          type: SET_APPLICABLE_DISCOUNTS_LOADING,
          isApplicableDiscountsLoading: false,
        });
      });
  };

export const setSelectedDiscounts = (selectedDiscounts) => (dispatch) => {
  dispatch({
    type: SET_SELECTED_DISCOUNTS,
    selectedDiscounts: selectedDiscounts,
  });
};

export const resetTicketing = () => (dispatch, getState) => {
  const state = getState();
  const applicableDiscounts = state?.payment?.applicableDiscounts;

  dispatch({
    type: RESET_TICKETING,
  });
  if (applicableDiscounts?.length) {
    const selectedDiscounts = [];
    for (const applicableDiscount of applicableDiscounts) {
      if (!applicableDiscount.is_public) {
        selectedDiscounts.push({
          discountId: applicableDiscount.id,
          quantity: 1,
        });
      }
    }

    dispatch({
      type: SET_SELECTED_DISCOUNTS,
      selectedDiscounts: selectedDiscounts,
    });
  }
};

export const resetError = () => (dispatch) => {
  dispatch({
    type: REQUEST_PROMOCODE_ERROR,
    errorMessage: "",
  });
};

export const sendPaypalOrder =
  ({ paypalData }) =>
  (dispatch) => {
    paypalData = {
      id: paypalData.orderID,
      ...paypalData,
    };
    api
      .capturePaypalPayment(paypalData)
      .then(() => {
        dispatch({
          type: SET_PAYPAL_PAYMENT,
          paypalPaymentCaptured: true,
        });
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_PAYPAL_PAYMENT_ERROR,
          error: error?.infos?.detail?.message,
        });
      });
  };
