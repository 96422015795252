export const TEXT_CONTENTS_DE = {
  // BLOCK 1:
  "block1-h2": "Barrierefreiheit",

  "block1-p1": "Diese Seite stellt keine Hilfsfunktion dar.",
  "block1-p2":
    'Sie soll die Politik der Zugänglichkeit der Website "{websiteURL}" vorstellen und das allgemeine Zugänglichkeitsniveau definieren, das auf der Website in Übereinstimmung mit den geltenden Vorschriften und Referenzen festgestellt wurde.',

  "block1-h3": "Was ist digitale Barrierefreiheit?",

  "block1-p3":
    "Die digitale Barrierefreiheit ist eine Reihe von Regeln und bewährten Verfahren, die insbesondere funktionale, grafische, technische und redaktionelle Aspekte abdecken.",
  "block1-p4":
    "Durch die Einhaltung dieser Regeln und bewährten Verfahren wird sichergestellt, dass digitale Medien (Websites, mobile Anwendungen, PDF-Dokumente usw.) für Menschen mit Behinderungen zugänglich sind.",
  "block1-p5": "Eine barrierefreie Website ermöglicht z. B.:",

  "block1-li1":
    "Ihre Anzeige über das Betriebssystem und/oder den Browser individuell anzupassen (Vergrößerung oder Verkleinerung der Schrift, Änderung der Typografie, Änderung der Farben, Anhalten von Animationen usw.).",
  "block1-li2":
    "Mithilfe von unterstützenden Technologien wie einer Sprachausgabe oder einer Blindenschrift zu navigieren.",
  "block1-li3":
    "Ohne Maus, nur mit der Tastatur, mit Schaltflächen oder über einen Touchscreen zu navigieren.",
  "block1-li4":
    "Videos und Audioinhalte mithilfe von Untertiteln und/oder Transkriptionen anzusehen.",
  "block1-li5": "Etc.",

  // BLOCK 2:
  "block2-h2": "Erklärung zur Barrierefreiheit",

  "block2-p1":
    "{brand} verpflichtet sich, seine Webseiten (Internet, Intranet und Extranet), mobilen Anwendungen, Softwarepakete und digitale Infrastruktur gemäß Artikel 47 des Gesetzes Nr. 2005-102 vom 11. Februar 2005 zugänglich zu machen.",
  "block2-p2":
    'Diese Erklärung zur Barrierefreiheit gilt für die Website "{websiteURL}".',

  "block2-h3": "Status der Konformität",

  "block2-p3":
    'Die Website "{websiteURL}" entspricht teilweise der WCAG 2.1 (Stufe AA) und zu {a11yOverallRate} der RGAA Version 4.1, aufgrund der unten aufgeführten Nichtkonformitäten und Abweichungen.',

  // BLOCK 3:
  "block3-h2": "Testergebnisse",

  "block3-p1a":
    "Im {auditDate} wurde von der Firma Ideance ein Prüfverfahren zur Einhaltung der RGAA Version 4.1 durchgeführt, gefolgt von einem selbst durchgeführten Prüfverfahren im {autoAuditDate}, nachdem die von ",
  "block3-p1b":
    " vorgenommenen Korrekturen integriert wurden. Dieses von Padam Mobility durchgeführte Prüfverfahren ergab in der Stichprobe:",

  "block3-li1":
    "Die allgemeine Konformitätsrate liegt bei {a11yOverallRate}. Diese Quote ergibt sich, wenn die Anzahl der konformen Kriterien durch die Anzahl der anwendbaren Kriterien dividiert wird.",
  "block3-li2":
    "Die durchschnittliche Erfüllungsquote beträgt {a11yAverageRate}. Diese Quote ergibt sich aus dem Durchschnitt der Erfüllungsquoten der einzelnen Webseiten.",

  "block3-h3": "Unzugängliche Inhalte",

  "block3-h4-1": "Non-Konformitäten",

  "block3-p2": "Liste der nicht konformen Kriterien:",

  "block3-criterion-1-1":
    "1.1 Hat jedes informationstragende Bild eine Textalternative?",
  "block3-criterion-1-2": "",
  "block3-criterion-1-3": "",
  "block3-criterion-3-2":
    "3.2 Ist auf jeder Webseite der Kontrast zwischen der Farbe des Textes und der Farbe seines Hintergrunds ausreichend hoch (außer in Sonderfällen)?",
  "block3-criterion-6-1": "",
  "block3-criterion-7-1":
    "7.1 Ist jedes Skript, falls erforderlich, mit assistierenden Technologien kompatibel?",
  "block3-criterion-7-3":
    "7.3 Ist jedes Skript mit der Tastatur und mit jedem Zeigegerät steuerbar (außer in Sonderfällen)?",
  "block3-criterion-7-5":
    "7.5 Werden Statusmeldungen auf jeder Webseite von assistierenden Technologien korrekt wiedergegeben?",
  "block3-criterion-8-2":
    "8.2 Ist für jede Webseite der erzeugte Quellcode gemäß dem angegebenen Dokumenttyp gültig (außer in Sonderfällen)?",
  "block3-criterion-8-6":
    "8.6 Ist bei jeder Webseite mit einem Seitentitel dieser Titel relevant?",
  "block3-criterion-8-7":
    "8.7 Wird auf jeder Webseite jeder Sprachwechsel im Quellcode angegeben (außer in Sonderfällen)?",
  "block3-criterion-8-9":
    "8.9 Auf jeder Webseite dürfen Tags nicht nur zu Präsentationszwecken verwendet werden. Wird diese Regel eingehalten?",
  "block3-criterion-9-2": "",
  "block3-criterion-9-3":
    "9.3 Ist jede Liste auf jeder Webseite korrekt strukturiert?",
  "block3-criterion-10-7":
    "10.7 Ist auf jeder Webseite für jedes Element, das einen Fokus erhält, die Fokussierung sichtbar?",
  "block3-criterion-10-8":
    "10.8 Sind versteckte Inhalte auf jeder Webseite dazu ausgelegt, von assistierenden Technologien ausgeblendet zu werden?",
  "block3-criterion-10-11":
    "10.11 Können auf jeder Webseite Inhalte dargestellt werden, ohne dass vertikales Scrollen bei einem Fenster mit einer Höhe von 256px oder horizontales Scrollen bei einem Fenster mit einer Breite von 320px erforderlich ist (Sonderfälle ausgenommen)?",
  "block3-criterion-10-12":
    "10.12 Können auf jeder Webseite die Eigenschaften des Textabstands vom Benutzer ohne Verlust von Inhalt oder Funktionalität neu festgelegt werden (außer in Sonderfällen)?",
  "block3-criterion-11-1": "",
  "block3-criterion-11-3": "",
  "block3-criterion-11-5": "",
  "block3-criterion-11-9":
    "11.9 Ist in jedem Formular die Bezeichnung jeder Schaltfläche relevant (abgesehen von Sonderfällen)?",
  "block3-criterion-11-10":
    "11.10 Wird in jedem Formular die Eingabekontrolle sinnvoll eingesetzt (außer in Sonderfällen)?",
  "block3-criterion-11-13":
    "11.13 Kann der Zweck eines Eingabefeldes abgeleitet werden, um ein automatisches Ausfüllen der Felder mit den Daten des Nutzers zu erleichtern?",
  "block3-criterion-12-6": "",
  "block3-criterion-12-7":
    "12.7 Ist auf jeder Webseite ein Link zur Umgehung oder zum Schnellzugriff auf den Bereich mit dem Hauptinhalt vorhanden (Sonderfälle ausgenommen)?",

  "block3-h4-2": "Ausnahmeregelungen bei unverhältnismäßiger Belastung",

  "block3-h5-1": "Hauptnavigationsmenü",

  "block3-p-3a":
    "Das aufklappbare Hauptnavigationsmenü, das im angeschlossenen Teil der Website vorhanden ist, folgt teilweise dem ",
  "block3-p-3b": '"ARIA-Designmotiv "Menu or Menu bar"',
  "block3-p-4":
    "Dies ist nicht optimal, da dieses Designmuster nicht für diese Art von Navigationsmenü vorgesehen ist.",
  "block3-p-5":
    "Außerdem weist die HTML-Strukturierung dieses Menüs einige leichte Unzulänglichkeiten auf.",
  "block3-p-6":
    "Diese Abweichung ist jedoch möglich, da das Menü sowohl mit der Tastatur als auch mit einem Screenreader gut bedienbar ist.",

  "block3-h5-2": "Ariadnefaden",

  "block3-p-7":
    "Ab einer bestimmten Bildschirmbreite (800px und weniger) wurde aus funktionalen und grafischen Gründen entschieden, „Breadcrumbs“ nicht mehr anzuzeigen.",
  "block3-p-8": "Dadurch könnte Kriterium 10.11 ungültig werden.",
  "block3-p-9":
    "Diese Abweichung ist jedoch denkbar, da „Breadcrumbs“ eine Funktion darstellen, die für die ordnungsgemäße Nutzung der Website nicht wesentlich ist.",
  "block3-p-10":
    "Außerdem gibt es andere, zugängliche Alternativen, die es ermöglichen, den aktuellen Navigationspunkt der Website zu ermitteln und dann zu den zuvor besuchten Seiten zurückzukehren.",

  "block3-h5-3": "„Uhrzeit“-Funktionalität",

  "block3-p-11":
    'Die Zeitfunktion, mit der Sie z. B. die Zeitfelder auf der Seite "Fahrten suchen" ausfüllen können, ist nicht zugänglich, insbesondere nicht mit der Tastatur oder einem Bildschirmlesegerät.',
  "block3-p-12":
    "Diese Ausnahmeregelung ist jedoch denkbar, da es möglich ist, diese Zeitfelder von Hand einzugeben, ohne diese Funktion nutzen zu müssen.",
  "block3-p-13": "Dies stellt eine zugängliche Alternative dar.",

  "block3-h4-3":
    "Inhalte, die nicht der Pflicht zur Barrierefreiheit unterliegen",

  "block3-p-14":
    "Die auf den Detailseiten der Buchungen vorhandenen interaktiven Google-Maps-Karten.",

  // BLOCK 4:
  "block4-h2": "Erstellung dieser Erklärung zur Barrierefreiheit",

  "block4-p-1": "Diese Erklärung wurde am {autoAuditDate} erstellt.",

  "block4-h3-1": "Für die Erstellung der Website verwendete Technologien",

  "block4-h3-2": "Testumgebung",

  "block4-p-2":
    "Die Tests wurden mit den folgenden Kombinationen aus Webbrowser und Screenreader durchgeführt:",

  "block4-h3-3": "Tools zur Bewertung der Barrierefreiheit",

  "block4-li-12": "Tools für Firefox-Entwickler",
  "block4-li-13": "Web Developer (Firefox-Erweiterung)",

  "block4-h3-4": "Die auf Konformität überprüften Seiten der Website",

  "block4-li-14": "Anmelden",
  "block4-li-15": "Registrieren",
  "block4-li-16": "Suche nach Fahrten",
  "block4-li-17": "Vorschläge für Fahrten",
  "block4-li-18": "Buchung",
  "block4-li-19": "Kontoeinstellungen",

  // BLOCK 5:
  "block5-h2": "Feedback und Kontakt",

  "block5-p-1":
    "Wenn Sie auf Inhalte oder Dienste dieser Website nicht zugreifen können, wenden Sie sich bitte an uns, damit wir Sie auf eine zugängliche Alternative verweisen oder Ihnen die Inhalte in anderer Form zur Verfügung stellen können.",
  "block5-p-2": "Schreiben Sie uns per Post an die folgende Adresse:",

  "block5-h3": "Rechtsmittel",

  "block5-p-3":
    "Die unten beschriebene Vorgehensweise ist in folgendem Fall anzuwenden.",
  "block5-p-4":
    "Sie haben dem Verantwortlichen der Website einen Zugänglichkeitsfehler gemeldet, der Sie daran hindert, auf einen Inhalt oder einen der Dienste zuzugreifen, und haben keine zufriedenstellende Antwort erhalten.",

  "block5-li-1": "Schreiben Sie eine Nachricht an den Rechtsverteidiger",
  "block5-li-2":
    "Kontaktieren Sie einen Vertreter des Rechtsverteidigers in Ihrer Region",
  "block5-li-3":
    "Senden Sie einen Brief per Post (kostenlos, keine Briefmarke erforderlich) an folgende Adresse:",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Appendix",
};
