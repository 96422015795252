import React, { FC, ReactNode } from "react";
import SvgIcon, { SvgIconClassKey } from "@material-ui/core/SvgIcon";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { TooltipWrapper } from "components/molecules/TooltipWrapper/TooltipWrapper";

type BusMarkerProps = {
  id: string;
  ariaLabel: string;
  color: string;
  tooltip: string | ReactNode;
  titleAccess?: string;
  classes: Partial<ClassNameMap<SvgIconClassKey>>;
};

const BusMarker: FC<BusMarkerProps> = ({
  id,
  ariaLabel,
  classes,
  color,
  tooltip,
  titleAccess,
}) => {
  return (
    <TooltipWrapper
      showTooltip={tooltip !== "" && tooltip !== undefined}
      tooltip={<>{tooltip}</>}
    >
      <SvgIcon
        classes={classes}
        viewBox="0 0 50 70"
        titleAccess={titleAccess}
        focusable="false"
        role="none"
        aria-label={ariaLabel}
      >
        <defs>
          <filter
            x="-31.7%"
            y="-28.3%"
            width="163.3%"
            height="163.3%"
            filterUnits="objectBoundingBox"
            id={`${id}-filter`}
          >
            <feOffset
              dx="0"
              dy="1"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feGaussianBlur
              stdDeviation="3"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            ></feGaussianBlur>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.202824519 0"
              type="matrix"
              in="shadowBlurOuter1"
            ></feColorMatrix>
          </filter>
        </defs>
        <g
          id={`${id}-dropoff`}
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id={`${id}-icon`} transform="translate(10.000000, 6.000000)">
            <g id={`${id}-pin`} transform="translate(7.000000, 30.699797)">
              <path
                d="M11.500779,-0.605660001 L11.5009623,10.5525137 C12.9459812,11.2064601 14.1755579,12.252736 15.0535663,13.5556174 C15.9667629,14.9107149 16.5,16.5431694 16.5,18.3002025 C16.5,20.6474127 15.5486051,22.7724127 14.0104076,24.3106102 C12.4722102,25.8488076 10.3472102,26.8002025 8,26.8002025 C5.65278981,26.8002025 3.52778981,25.8488076 1.98959236,24.3106102 C0.451394907,22.7724127 -0.5,20.6474127 -0.5,18.3002025 C-0.5,16.5429643 0.0333615132,14.9103336 0.946753604,13.5551427 C1.82494049,12.2521854 3.05475395,11.2059046 4.50002422,10.5520673 L4.50002422,10.5520673 L4.49984555,-0.605527708 C5.62107071,-0.368038375 6.7934125,-0.242149095 8,-0.242149095 C9.20681153,-0.242149095 10.3793646,-0.368085128 11.500779,-0.605660001 L11.500779,-0.605660001 Z"
                id="Combined-Shape"
                stroke="#FFFFFF"
                fill={color}
              ></path>
              <circle
                id={`${id}-oval-bus-marker-circle`}
                fill="#FFFFFF"
                cx="8"
                cy="18.3002025"
                r="4"
              ></circle>
            </g>
            <g>
              <use
                fill="black"
                fillOpacity="1"
                filter={`url(#${id}-filter)`}
                xlinkHref="#path-1"
              ></use>
              <circle
                cx="15"
                cy="15"
                r="15"
                fill={color}
                fillRule="evenodd"
              ></circle>
            </g>
            <g
              id={`${id}-picto-bus`}
              transform="translate(4.000000, 4.000000)"
              fill="#FFFFFF"
            >
              <path d="M14.5217391,3 C16.4427296,3 18,4.55727044 18,6.47826087 L18,14.1304348 C18,14.9151798 17.7401212,15.6392269 17.3017312,16.2212086 L17.3,18.3043478 C17.3,18.6885459 16.9885459,19 16.6043478,19 L15.1956522,19 C14.8114541,19 14.5,18.6885459 14.5,18.3043478 L14.5,17.608 L7.5,17.608 L7.5,18.3043478 C7.5,18.6885459 7.18854591,19 6.80434783,19 L5.39565217,19 C5.01145409,19 4.7,18.6885459 4.7,18.3043478 L4.69979169,16.2232288 C4.26048068,15.6409029 4,14.9160881 4,14.1304348 L4,6.47826087 C4,4.55727044 5.55727044,3 7.47826087,3 L14.5217391,3 Z M7.15,13.4347826 C6.57010101,13.4347826 6.1,13.9019637 6.1,14.4782609 C6.1,15.054558 6.57010101,15.5217391 7.15,15.5217391 C7.72989899,15.5217391 8.2,15.054558 8.2,14.4782609 C8.2,13.9019637 7.72989899,13.4347826 7.15,13.4347826 Z M14.85,13.4347826 C14.270101,13.4347826 13.8,13.9019637 13.8,14.4782609 C13.8,15.054558 14.270101,15.5217391 14.85,15.5217391 C15.429899,15.5217391 15.9,15.054558 15.9,14.4782609 C15.9,13.9019637 15.429899,13.4347826 14.85,13.4347826 Z M16.6,6.47826087 L5.4,6.47826087 L5.4,10.6521739 L16.6,10.6521739 L16.6,6.47826087 Z"></path>
            </g>
          </g>
        </g>
      </SvgIcon>
    </TooltipWrapper>
  );
};

export default BusMarker;
