import { makeStyles } from "@material-ui/core";
import { CustomTheme } from "src/themes/themeTypes";

export const useFixedLinesRideDetailsStyles = makeStyles(
  (theme: CustomTheme) => ({
    rideDetailsContainer: {
      padding: "1rem",
    },
    rideStepList: {
      padding: 0,

      listStyle: "none",

      "& li": {
        margin: "0 0 0 2rem",
        padding: "0 1rem 2rem 2rem",
        borderLeft: "0.125rem dashed #717587",
      },

      "& li:last-child": {
        padding: "0 1rem 0 2rem",
        borderColor: "transparent",
      },
    },
    stepIcon: {
      position: "absolute",
      left: "-1rem",

      background: "white",
      width: "1.375rem",
      height: "1.375rem",
      padding: "0.125rem",
      border: "0.125rem solid transparent",
    },
    externalLinkDescription: {
      marginTop: "0.5rem",
      color: theme.palette.text.secondary,
      fontSize: "1rem",
    },
    newTabIcon: {
      marginRight: "0.5rem",
    },
  }),
);
