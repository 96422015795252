import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

type SelectGeneratorProps = {
  id: string;
  label: string | JSX.Element;
  labelID: string;
  value: string;
  disabled?: boolean;
  required: boolean | undefined;
  options: { key: string; display: string; order: number }[] | undefined;
  handleChange: (
    event: React.ChangeEvent<{ value: unknown; checked: unknown }>,
  ) => void;
  dataTestId: string;
};

export const SelectGenerator = (props: SelectGeneratorProps) => {
  const {
    id,
    label,
    labelID,
    value,
    disabled = false,
    required,
    options,
    handleChange,
    dataTestId,
  } = props;

  return (
    <FormControl fullWidth>
      <InputLabel id={labelID}>{label}</InputLabel>
      <Select
        data-testid={dataTestId}
        labelId={labelID}
        id={id}
        value={value}
        label={label}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        fullWidth={true}
      >
        <MenuItem value={""}>--------</MenuItem>
        {options
          ?.sort((a, b) => a.order - b.order)
          .map(({ key, display }) => (
            <MenuItem key={key} value={key}>
              {display}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
