import React from "react";
import { useIntl } from "react-intl";
import { useTheme } from "@material-ui/core";

import Breadcrumb from "components/Breadcrumb/Breadcrumb.jsx";
import ActionHome from "components/Icons/Help";

export default function BreadcrumbContainer(props) {
  const { currentLoc } = props;
  const theme = useTheme();

  const intl = useIntl();
  const fillColor = theme.palette.textColor;

  let pathArray = currentLoc.slice(1).split("/");
  let data = [
    {
      icon: <ActionHome className="mr-4 h-6 w-6" fill={fillColor} />,
      path: "/",
      title: intl.formatMessage({ id: "breadcrumb.home" }),
    },
  ];

  for (var i = 0; i < pathArray.length; i++) {
    switch (pathArray[i]) {
      case "result":
        data.push({
          path: "/search/result",
          title: intl.formatMessage({ id: "breadcrumb.search" }),
        });
        break;
      case "confirmation":
        data.push({
          path: "/search/result/confirmation",
          title: intl.formatMessage({ id: "breadcrumb.bookingValidation" }),
        });
        break;
      case "payment":
        data.push({
          path: "/search/result/confirmation/payment",
          title: intl.formatMessage({ id: "payment.payment" }),
        });
        break;
      case "reservation":
        data.push({
          path: "/reservation",
          title: intl.formatMessage({ id: "breadcrumb.reservation" }),
        });
        break;
      case "impersonate":
        data.push({
          path: "/impersonate",
          title: intl.formatMessage({ id: "breadcrumb.callcenter" }),
        });
        break;
      case "profile":
        data.push({
          path: "/profile",
          title: intl.formatMessage({ id: "menu.account" }),
        });
        break;
      case "about":
        data.push({
          path: "/about",
          title: intl.formatMessage({ id: "menu.about" }),
        });
        break;
      case "accessibility":
        data.push({
          path: "/accessibility",
          title: intl.formatMessage({
            id: "breadcrumb.a11y",
            defaultMessage: "Accessibility",
          }),
        });
        break;
      default:
        break;
    }
    if (i >= 1 && pathArray[i - 1] === "reservation") {
      data.push({
        path: "/reservation/" + pathArray[1],
        title: intl.formatMessage({ id: "breadcrumb.myResa" }),
      });
    }
  }

  return <Breadcrumb data={data} />;
}
