import { useCallback, useMemo } from "react";

import { captchaAudioBaseUrl, captchaUrl } from "./constants";

export interface Captcha {
  id: string;
  img: string;
}

interface CernCaptchApiClient {
  getCaptcha: () => Promise<Captcha>;
  getCaptchaAudioUrl: (captchaId: string) => string;
}

export function useCernCaptchApiClient(): CernCaptchApiClient {
  const getCaptcha = useCallback(async (): Promise<Captcha> => {
    return await (
      await fetch(captchaUrl, {
        headers: new Headers({ "content-type": "application/json" }),
      })
    ).json();
  }, []);

  const getCaptchaAudioUrl = useCallback((captchaId: string): string => {
    return `${captchaAudioBaseUrl}/${captchaId}`;
  }, []);

  const cernCaptchaApiClient = useMemo<CernCaptchApiClient>(
    () => ({
      getCaptcha,
      getCaptchaAudioUrl,
    }),
    [getCaptcha, getCaptchaAudioUrl],
  );

  return cernCaptchaApiClient;
}
