import { createTheme as createMuiTheme } from "@material-ui/core/styles";
import "@material-ui/core/styles/createPalette";

import { getAccessibleTextColor } from "../styles/textColor";
import { CustomThemeOptions } from "./themeTypes";

// Font familiess come from fonts.css:
const FONT_TITLE = "Roboto, helvetica, arial, sans-serif";
// const FONT_TEXT = "Nunito, Roboto, helvetica, arial, san-serif ";
const FONT_TEXT = FONT_TITLE;

export const createTheme = (colors) =>
  createMuiTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1536,
      },
    },

    palette: {
      primary: { main: colors.PRIMARY },
      secondary: { main: "#1EA86A" },

      textColor: colors.textColor || getAccessibleTextColor(colors.PRIMARY),

      neutral: { main: "#717587" },
      radio: { main: "#404148" },

      pickup: { main: "#1EA86A" },
      dropoff: { main: "#FF1D58" },

      error: { main: "#D0021B" },
      warning: { main: "#CC4E04" },
      success: { main: "#128754" },
      info: { main: "#0869AF" },
    },

    typography: {
      h1: {
        fontFamily: FONT_TITLE,
        fontSize: "1.8rem",
      },

      h2: {
        fontFamily: FONT_TITLE,
        fontSize: "1.5rem",
      },

      h3: {
        fontFamily: FONT_TEXT,
        fontSize: "1.35rem",
      },

      h4: {
        fontFamily: FONT_TEXT,
        fontSize: "1.25rem",
      },

      h5: {
        fontFamily: FONT_TEXT,
        fontSize: "1.15rem",
      },

      h6: {
        fontFamily: FONT_TEXT,
        fontSize: "1.1rem",
      },

      body1: {
        fontFamily: FONT_TEXT,
        fontSize: "1.15rem",
      },

      body2: {
        fontFamily: FONT_TEXT,
        fontSize: "1.05rem",
      },

      button: {
        fontFamily: FONT_TITLE,
        fontSize: "1.15rem",
      },

      caption: {
        fontFamily: FONT_TEXT,
        fontSize: "1rem",
      },
    },

    shape: {
      borderRadius: 20,
    },

    overrides: {
      MuiAvatar: {
        colorDefault: {
          color: colors.textColor || getAccessibleTextColor(colors.PRIMARY),
          backgroundColor: colors.PRIMARY,
        },
      },

      MuiButton: {
        root: {
          minWidth: "160px",
          textTransform: "none",
        },

        containedPrimary: {
          color: colors.textColor || getAccessibleTextColor(colors.PRIMARY),
        },
      },

      MuiCard: {
        root: {
          "&.MuiPaper-elevation1": {
            boxShadow: "0 0 4px #aaa",
          },
        },
      },

      MuiFormLabel: {
        root: {
          fontSize: "1rem",
        },
      },

      MuiInput: {
        root: {
          color: "black",
        },

        underline: {
          "&::after": {
            borderBottom: "2px solid #131589",
          },

          "&::hover:not($focused):before": {
            borderBottom: "2px solid #131589",
          },
        },
      },

      MuiOutlinedInput: {
        root: {
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#131589",
          },
        },
      },

      MuiPaper: {
        root: {
          "&.MuiPaper-elevation1": {
            boxShadow: "0 0 4px #aaa",
          },
        },
      },

      MuiTab: {
        root: {
          margin: "0 0.5rem 0 0",
          border: "0 solid lightgrey",
          borderWidth: "2px 2px 1px 2px",
          borderRadius: "1rem 1rem 0 0",

          textTransform: "none",
        },
      },

      PrivateTabIndicator: {
        root: {
          height: "4px",
        },
      },
    },

    props: {
      MuiCircularProgress: {
        size: 24,
        thickness: 8,
      },
    },
  } as CustomThemeOptions);
