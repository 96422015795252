import React from "react";

import { Typography } from "@material-ui/core";

export function A11yDeclarationBlock2(props) {
  const { a11yOverallRate, brand, textContents, websiteURL } = props;

  return (
    <>
      <Typography component="h2">
        {textContents["block2-h2"].replace("{brand}", brand)}
      </Typography>

      <Typography component="p">
        {textContents["block2-p1"].replace("{brand}", brand)}
      </Typography>

      <Typography component="p">
        {textContents["block2-p2"].replace("{websiteURL}", websiteURL)}
      </Typography>

      <Typography component="h3">{textContents["block2-h3"]}</Typography>

      <Typography component="p">
        {textContents["block2-p3"]
          .replace("{a11yOverallRate}", a11yOverallRate)
          .replace("{websiteURL}", websiteURL)}
      </Typography>
    </>
  );
}
