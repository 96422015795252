import React, { FC, ReactNode } from "react";
import SvgIcon, { SvgIconClassKey } from "@material-ui/core/SvgIcon";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { TooltipWrapper } from "components/molecules/TooltipWrapper/TooltipWrapper";

type BusMarkerProps = {
  ariaLabel: string;
  color: string;
  tooltip: string | ReactNode;
  titleAccess?: string;
  classes: Partial<ClassNameMap<SvgIconClassKey>>;
};

const BusStopMarker: FC<BusMarkerProps> = ({
  ariaLabel,
  classes,
  color,
  tooltip,
  titleAccess,
}) => {
  return (
    <TooltipWrapper
      showTooltip={tooltip !== "" && tooltip !== undefined}
      tooltip={<>{tooltip}</>}
    >
      <SvgIcon
        classes={classes}
        viewBox="0 0 40 57"
        titleAccess={titleAccess}
        focusable="false"
        role="none"
        aria-label={ariaLabel}
      >
        <circle cx="20" cy="18" r="13" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0173 34H21.9869C22.0044 43.4952 22.0175 47.8486 22 48L20.0204 51L18 48C17.9769 47.8041 17.9942 43.4507 18.0173 34Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35 18C35 26.2843 28.2843 33 20 33C11.7157 33 5 26.2843 5 18C5 9.71573 11.7157 3 20 3C28.2843 3 35 9.71573 35 18ZM27 13.4783C27 11.5573 25.4427 10 23.5217 10H16.4783C14.5573 10 13 11.5573 13 13.4783V21.1304C13 21.9161 13.2605 22.6409 13.6998 23.2232L13.7 25.3043C13.7 25.6885 14.0115 26 14.3957 26H15.8043C16.1885 26 16.5 25.6885 16.5 25.3043V24.608H23.5V25.3043C23.5 25.6885 23.8115 26 24.1957 26H25.6043C25.9885 26 26.3 25.6885 26.3 25.3043L26.3017 23.2212C26.7401 22.6392 27 21.9152 27 21.1304V13.4783ZM15.1 21.4783C15.1 20.902 15.5701 20.4348 16.15 20.4348C16.7299 20.4348 17.2 20.902 17.2 21.4783C17.2 22.0546 16.7299 22.5217 16.15 22.5217C15.5701 22.5217 15.1 22.0546 15.1 21.4783ZM22.8 21.4783C22.8 20.902 23.2701 20.4348 23.85 20.4348C24.4299 20.4348 24.9 20.902 24.9 21.4783C24.9 22.0546 24.4299 22.5217 23.85 22.5217C23.2701 22.5217 22.8 22.0546 22.8 21.4783ZM14.4 13.4783H25.6V17.6522H14.4V13.4783Z"
          fill={color}
        />
      </SvgIcon>
    </TooltipWrapper>
  );
};

export default BusStopMarker;
