import React, { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { sessionStarted } from "containers/ImpersonateForm/actions";
import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";
import { TerritoryContext } from "contexts/TerritoryContext";
import store from "store/store.js";
import cookie from "lib/cookie.js";
import { settings } from "config/app";

export function RequireAuthOrSAS({ children }) {
  const authToken = cookie.get(settings.cookieKeys.authCookie);
  const impersonateToken = cookie.get(settings.cookieKeys.impersonateCookie);

  const state = store.getState();

  const { productParameters } = useContext(ProductContext);
  const { isAuthenticated, userProfile, setIsAuthenticated, loadUserProfile } =
    useContext(UserContext);
  const { selectedTerritoryKey } = useContext(TerritoryContext);

  const navigate = useNavigate();
  const location = useLocation();

  const phoneValidationProcessAtBooking =
    productParameters?.user.phone_validation_process === "AT_BOOKING";

  useEffect(() => {
    if (authToken || impersonateToken) {
      // We fetch the profile to ensure that the user is logged in
      loadUserProfile()
        .then(() => setIsAuthenticated(true))
        .catch((error) => {
          setIsAuthenticated(false);
          throw error;
        });
    } else {
      // No cookie -> unauthenticated
      setIsAuthenticated(false);
    }
  }, [selectedTerritoryKey]);

  if (isAuthenticated) {
    // User is authenticated

    if (SIGNUP_AFTER_SEARCH || phoneValidationProcessAtBooking) {
      // Signup after search mode: phone number verification dialog is handled by the Authenticated layout
      // Phone validation process at booking: phone number verification dialog is opened right before payment
      return children;
    }

    // No signup after search mode

    const isPhoneValidated = userProfile.isPhoneValidated;
    const isOnVerificationPage = location.pathname === "/register/verification";
    const isImpersonating = state?.impersonateForm?.sessionStarted || false;

    if (!(isPhoneValidated || isOnVerificationPage || isImpersonating)) {
      navigate("/register/verification");
    }

    // Otherwise, everything is good: the children can be displayed
    return children;
  }

  if (isAuthenticated === undefined) {
    // Waiting for authentication state
    return null;
  }

  // Cases below as unauthenticated
  if (!SIGNUP_AFTER_SEARCH) {
    // No signup after search and unauthenticated -> redirect to login
    const redirectToAfterLogin = location.pathname + location.search;
    return (
      <Navigate to="/login" replace state={{ path: redirectToAfterLogin }} />
    );
  }

  // below is to tell redux that Impersonate Session was started
  if (impersonateToken && !state.impersonateForm?.sessionStarted) {
    store.dispatch(sessionStarted());
  }

  return children;
}
