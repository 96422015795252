// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-cssmodule-rideConfirm__breadcrumb-EbKCP{margin:1%}.main-cssmodule-rideConfirm__container-F7hgr{flex:1 0 auto;display:flex;flex-direction:column;margin:1%}@media (max-width: 888px){.main-cssmodule-rideConfirm__container-F7hgr{padding:0 4%}}.main-cssmodule-rideConfirm__wrapper-kRRKk{display:flex;flex:1 0 auto;justify-content:space-around;margin-bottom:10px}@media (max-width: 888px){.main-cssmodule-rideConfirm__wrapper-kRRKk{flex-direction:column}}.main-cssmodule-rideConfirm__details-ydZhu{flex:0 1 30rem;margin:1%}@media (max-width: 888px){.main-cssmodule-rideConfirm__details-ydZhu{flex:0 0 auto}}.main-cssmodule-rideConfirm__map-cgMY7{flex:1 1 60rem;min-height:20rem;max-height:40rem;margin:1%;position:relative}@media (max-width: 800px){.main-cssmodule-rideConfirm__map-cgMY7{flex:0 0 50rem}}.main-cssmodule-rideConfirm__validation-QzT6O{flex:0 1 30rem;margin:1%;height:fit-content}@media (max-width: 888px){.main-cssmodule-rideConfirm__validation-QzT6O{flex:0 0 auto}}
`, "",{"version":3,"sources":["webpack://./src/views/SearchResultConfirmation/main.cssmodule.scss"],"names":[],"mappings":"AACE,8CACE,SAAU,CACX,6CAEC,aAAc,CACd,YAAa,CACb,qBAAsB,CACtB,SAAU,CACV,0BALF,6CAMI,YAAa,CAEhB,CACD,2CACE,YAAa,CACb,aAAc,CACd,4BAA6B,CAC7B,kBAAmB,CAEnB,0BANF,2CAOI,qBAAsB,CAEzB,CACD,2CACE,cAAe,CACf,SAAU,CAEV,0BAJF,2CAKI,aAAc,CAEjB,CACD,uCACE,cAAe,CAEf,gBAAiB,CACjB,gBAAiB,CACjB,SAAU,CACV,iBAAkB,CAElB,0BARF,uCASI,cAAe,CAElB,CACD,8CACE,cAAe,CACf,SAAU,CACV,kBAAmB,CACnB,0BAJF,8CAKI,aAAc,CAEjB","sourcesContent":[".rideConfirm {\n  &__breadcrumb {\n    margin: 1%;\n  }\n  &__container {\n    flex: 1 0 auto;\n    display: flex;\n    flex-direction: column;\n    margin: 1%;\n    @media (max-width: 888px) {\n      padding: 0 4%;\n    }\n  }\n  &__wrapper {\n    display: flex;\n    flex: 1 0 auto;\n    justify-content: space-around;\n    margin-bottom: 10px;\n\n    @media (max-width: 888px) {\n      flex-direction: column;\n    }\n  }\n  &__details {\n    flex: 0 1 30rem;\n    margin: 1%;\n    //height: 100%;\n    @media (max-width: 888px) {\n      flex: 0 0 auto;\n    }\n  }\n  &__map {\n    flex: 1 1 60rem;\n    //height: 100%;\n    min-height: 20rem;\n    max-height: 40rem;\n    margin: 1%;\n    position: relative;\n\n    @media (max-width: 800px) {\n      flex: 0 0 50rem;\n    }\n  }\n  &__validation {\n    flex: 0 1 30rem;\n    margin: 1%;\n    height: fit-content;\n    @media (max-width: 888px) {\n      flex: 0 0 auto;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rideConfirm__breadcrumb": `main-cssmodule-rideConfirm__breadcrumb-EbKCP`,
	"rideConfirm__container": `main-cssmodule-rideConfirm__container-F7hgr`,
	"rideConfirm__wrapper": `main-cssmodule-rideConfirm__wrapper-kRRKk`,
	"rideConfirm__details": `main-cssmodule-rideConfirm__details-ydZhu`,
	"rideConfirm__map": `main-cssmodule-rideConfirm__map-cgMY7`,
	"rideConfirm__validation": `main-cssmodule-rideConfirm__validation-QzT6O`
};
export default ___CSS_LOADER_EXPORT___;
