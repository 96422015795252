import React from "react";

import { SvgIcon } from "@material-ui/core";

export function CommentedIcon() {
  return (
    <SvgIcon viewBox="0 0 22 22">
      <path
        d="M18.5327 16.2618C20.0691 14.8553 21 13.0146 21 11C21 6.58172 16.5228 3 11 3C5.47715 3 1 6.58172 1 11C1 15.4183 5.47715 19 11 19C12.5726 19 14.0605 18.7096 15.3843 18.1921L18.1644 19.5822C18.5216 19.7608 18.9337 19.4692 18.8841 19.073L18.5327 16.2618Z"
        fill="#717587"
      />
    </SvgIcon>
  );
}
