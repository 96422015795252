import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Paper, Slider, Typography } from "@material-ui/core";

import { SalesConditions } from "components/SalesConditions/SalesConditions";
import Credit from "components/Icons/Credit";
import { Price } from "atoms/Price/Price";
import StripeContainer from "containers/Stripe/StripeContainer";

import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import { getCreditBalance, getCreditMatrix, purchaseCredit } from "./actions";

import { useCreditManagerStyles } from "./creditManagerStyles";
import { find, get } from "lodash";
import { PaymentMethodEnum } from "types/payment";

type CreditManagerProps = {
  balance: number;
  getCreditBalance: () => void;
  getCreditMatrix: (selectedTerritoryKey?: string) => void;
  isRequesting: boolean;
  matrix: number[][];
  purchaseCredit: (
    creditAmount: number,
    realAmount: number,
    payment,
    selectedTerritoryKey?: string,
  ) => void;
};

function CreditManager(props: CreditManagerProps) {
  const {
    balance,
    getCreditBalance,
    getCreditMatrix,
    isRequesting,
    matrix,
    purchaseCredit,
  } = props;

  const { productParameters } = useContext(ProductContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const [creditAmount, setCreditAmount] = useState<number>(0);

  const intl = useIntl();
  const classes = useCreditManagerStyles();

  const brand = productParameters?.brand;
  const gtcsURL = productParameters?.contact?.gtcs_url;

  useEffect(() => {
    getCreditMatrix(selectedTerritory?.territory_key);
    getCreditBalance();
  }, [getCreditBalance, getCreditMatrix, selectedTerritory?.territory_key]);

  useEffect(() => {
    if (matrix.length > 0) {
      setCreditAmount(matrix[0][0]);
    }
  }, [matrix]);

  if (matrix.length === 0 || balance === null) {
    return null;
  }

  const minCreditsQuantity = matrix[0][0];
  const maxCreditsQuantity = matrix[matrix.length - 1][0];

  const sliderMarks = matrix.map((pair) => ({
    value: pair[0],
  }));

  const realAmount = get(
    find(matrix, (pair: number[]) => pair[0] === creditAmount),
    1,
  );

  return (
    <div className={classes.creditManagerContainer}>
      <Paper className={classes.container}>
        <Credit className={classes.creditIcon} />

        <Typography data-testid="credit-balance">
          <FormattedMessage id="credit.balance" values={{ balance }} />
        </Typography>
      </Paper>

      <Paper className={classes.container}>
        <Typography
          id="buyWithDiscount"
          className={classes.buyCreditHeading}
          variant="h3"
        >
          <FormattedMessage
            id="credit.buyWithDiscount"
            defaultMessage="Buy {creditAmount} credits and pay {price}"
            values={{
              creditAmount: intl.formatNumber(creditAmount),
              price: <Price value={realAmount} />,
            }}
          />
        </Typography>

        <div className={classes.sliderContainer}>
          <Typography className={classes.sliderExtremum}>
            {intl.formatNumber(minCreditsQuantity)}
          </Typography>

          <Slider
            defaultValue={minCreditsQuantity}
            step={null}
            marks={sliderMarks}
            onChange={(_, value: number) => setCreditAmount(value)}
            min={minCreditsQuantity}
            max={maxCreditsQuantity}
            scale={(value) => value}
            valueLabelDisplay="auto"
            data-testid="credit-slider"
            aria-labelledby="buyWithDiscount"
          />

          <Typography className={classes.sliderExtremum}>
            {intl.formatNumber(maxCreditsQuantity)}
          </Typography>
        </div>
      </Paper>

      <StripeContainer
        validate={(data) =>
          purchaseCredit(
            creditAmount,
            realAmount,
            {
              method: "STRIPE",
              data,
            },
            selectedTerritory?.territory_key,
          )
        }
        method={PaymentMethodEnum.STRIPE_CARD}
        totalPrice={realAmount}
        isRequesting={isRequesting}
      />

      <SalesConditions brand={brand} gtcsURL={gtcsURL} />
    </div>
  );
}

const mapStateToProps = (state) => {
  const credit = state.credit;

  return {
    matrix: credit.matrix,
    balance: credit.balance,
    isRequesting:
      credit.isRequestingMatrix ||
      credit.isRequestingBalance ||
      credit.isRequestingPurchase,
    isRequestingPurchase: credit.isRequestingPurchase,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCreditMatrix: (selectedTerritoryKey) =>
    dispatch(getCreditMatrix(selectedTerritoryKey)),
  getCreditBalance: () => dispatch(getCreditBalance()),
  purchaseCredit: (creditAmount, realAmount, payment, selectedTerritoryKey) =>
    dispatch(
      purchaseCredit(creditAmount, realAmount, payment, selectedTerritoryKey),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreditManager);
