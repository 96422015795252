import { ResourceType } from "api/resources/types/resourceType";

export const common: ResourceType = {
  getCurrentInAppNotifications: {
    endpoint: "/get-current-inapp-notifications",
    method: "GET",
    impersonate: true,
  },
  getParameters: {
    endpoint: "/get-parameters",
    method: "GET",
    authenticationRequired: false,
  },
};
