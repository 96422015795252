import React from "react";
import { ListItem, Radio } from "@material-ui/core";
import { SavedCard } from "components/Payment/SavedCard";
import { StripeCardType } from "types/payment";
import { GREY_COLOR_HEX } from "utils/constants";

type SavedCardListProps = {
  savedCards: StripeCardType[];
  selectedCardIndex: number;
  method: string;
  selectPaymentMethod: (method: string, index: number) => void;
  deletePaymentMethod: (method: string, id: number) => void;
  canBeSelected: boolean;
};

export function SavedCardList(props: SavedCardListProps) {
  const {
    savedCards = [],
    selectedCardIndex,
    method,
    selectPaymentMethod,
    deletePaymentMethod,
    canBeSelected = true,
  } = props;

  return (
    <>
      {savedCards.map((card, i) => (
        <ListItem
          data-testId="saved-cards-list"
          key={`credit-card-${card.id}`}
          id={`credit-card-${card.id}`}
          button
          className="flex-column flex w-full justify-between p-2 md:flex-row md:items-center md:justify-center md:space-x-0 md:space-y-2"
          selected={canBeSelected ? selectedCardIndex === i : undefined}
          onClick={() => {
            if (!canBeSelected) return;
            selectPaymentMethod(method, i);
          }}
        >
          {canBeSelected && (
            <Radio
              classes={{ root: "left" }}
              style={{ color: GREY_COLOR_HEX }}
              checked={selectedCardIndex === i}
              inputProps={{ "aria-labelledby": `credit-card-${card.id}` }}
            />
          )}
          <SavedCard
            data-testid="delete-saved-card"
            card={card}
            deleteCard={(id: number) => deletePaymentMethod(method, id)}
          />
        </ListItem>
      ))}
    </>
  );
}
