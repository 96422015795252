import api from "api";
import { getSavedPaymentMethods } from "containers/Stripe/actions";

import { clearIndicationsToDriver } from "containers/SearchForm/actions";

export const INITIATE_BOOKING_VALIDATION_REQUEST =
  "INITIATE_BOOKING_VALIDATION_REQUEST";
export const BOOKING_VALIDATION_END_REQUEST = "BOOKING_VALIDATION_END_REQUEST";
export const BOOKING_VALIDATION_ERROR = "BOOKING_VALIDATION_ERROR";
export const BOOKING_VALIDATION_SUCCESS = "BOOKING_VALIDATION_SUCCESS";
export const CLOSE_VALIDATION_RESULT = "CLOSE_VALIDATION_RESULT";

export const bookingValidationResponse =
  (day, response) => (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: BOOKING_VALIDATION_SUCCESS,
      day,
      response,
    });
    // check if end of multidate request
    const daysRequesting = {
      ...state.bookingValidation.daysRequesting,
      [day]: false,
    };
    if (Object.values(daysRequesting).every((b) => b === false)) {
      dispatch({
        type: BOOKING_VALIDATION_END_REQUEST,
      });
    }
  };

export const bookingValidationError = (day, error) => (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: BOOKING_VALIDATION_ERROR,
    day,
    error,
  });
  // check if end of multidate request
  const daysRequesting = {
    ...state.bookingValidation.daysRequesting,
    [day]: false,
  };
  if (Object.values(daysRequesting).every((b) => !b)) {
    dispatch({
      type: BOOKING_VALIDATION_END_REQUEST,
    });
  }
};

/**
 * This action is triggered at the beginning of multiDate for( in days ) request
 * it creates an array of isRequesting for each day
 * in order to detect the end of validateBookingRequest
 * @return
 */
const initiateBookingValidationRequest = (days) => {
  const daysRequesting = {};
  for (let i = 0; i < days.length; i++) daysRequesting[days[i]] = true;
  return {
    type: INITIATE_BOOKING_VALIDATION_REQUEST,
    daysRequesting,
  };
};

export const closeValidationResult = () => ({ type: CLOSE_VALIDATION_RESULT });

export const validateBooking =
  ({
    customerId,
    paymentData,
    paymentMode,
    selectedDiscounts = [],
    selectedTerritoryKey,
  }) =>
  (dispatch, getState) => {
    const state = getState();

    const originImpersonate = state?.impersonateForm?.sessionStarted || false;
    const responses = state?.search?.responses || {};
    const selectedProposals = state?.search?.selectedProposals || {};
    const passengersProfiles = state?.passengersProfiles || {};
    const personalItems = state?.search?.searchForm?.personalItems || {};

    const days = Object.keys(selectedProposals);

    dispatch(initiateBookingValidationRequest(days));
    days.map((day) => {
      const response = responses[day];
      const reservationId = response?.reservation_info?.id;
      const proposalId = selectedProposals[day];

      let payload = {
        reservation_history_id: reservationId,
        customer_proposition_id: proposalId,
        booked_from: originImpersonate
          ? "BOOKED_FROM_CALL_CENTER"
          : "BOOKED_FROM_WEBSITE",
        payment: {
          method: paymentMode,
          data: paymentData || {},
        },
        discount_info: selectedDiscounts.map((selectedDiscount) => ({
          discount_profile_id: selectedDiscount.discountId,
          passengers_number: selectedDiscount.quantity,
        })),
      };

      const profileAndItemRequests = [];
      Object.entries(passengersProfiles?.profiles).forEach(([_, profile]) => {
        for (let i = 0; i < profile?.count; i++) {
          let payloadPassenger = {
            customerId: state?.user?.user?.customer_id,
            searchrequestId: reservationId,
            passenger_profile: profile.id,
          };
          profileAndItemRequests.push(
            api.addPassengerToSearchRequest(payloadPassenger),
          );
        }
      });

      Object.values(personalItems?.personalItems || {}).forEach(
        (personalItem) => {
          for (let i = 0; i < personalItem?.count; i++) {
            let payloadPersonalItem = {
              customerId: state?.user?.user?.customer_id,
              searchrequestId: reservationId,
              personal_equipment: personalItem.id,
            };
            profileAndItemRequests.push(
              api.addPersonalItem(payloadPersonalItem),
            );
          }
        },
      );

      if (state?.payment?.isPromoCodeValid) {
        payload = {
          ...payload,
          promo_code: state?.payment?.promoCode,
        };
      }

      const indicationsToDriver =
        state?.search?.searchForm?.indicationsToDriver || "";

      Promise.all([...profileAndItemRequests])
        .then(() => {
          let bookingIdToPatchIndications;

          api
            .validateBooking(payload, { territory: selectedTerritoryKey })
            .then((json) => {
              dispatch(bookingValidationResponse(day, json.reservation));
              if (!_.isEmpty(paymentData)) dispatch(getSavedPaymentMethods());

              bookingIdToPatchIndications = json?.reservation?.id;
            })
            .catch((error) => {
              dispatch(bookingValidationError(day, error));
            })
            .finally(() => {
              api.patchIndicationsToDriver({
                comment: indicationsToDriver,
                bookingId: bookingIdToPatchIndications,
                customerId,
              });

              dispatch(clearIndicationsToDriver());
            });
        })
        .catch((error) => {
          dispatch(bookingValidationError(day, error));
        });
    });
  };
