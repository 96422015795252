import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const switchTerritoryDialogStyles = makeStyles((theme: CustomTheme) => ({
  availableTerritoriesContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",

    maxHeight: "20rem",
    margin: "1rem 0",
    padding: 0,
  },
  currentTerritoryMessage: {
    margin: "2rem 0 1.5rem 0",
    fontSize: "1.35rem",
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      fontSize: "1.2rem",
    },
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "80%",
      maxWidth: "40rem",
      minWidth: "300px",
      margin: "1rem",
      padding: "1.5rem",
    },
  },
  dialogTitle: {
    "& h2": {
      textAlign: "center",
      fontSize: "1.35rem",
      fontWeight: 700,
    },

    [theme.breakpoints.up("sm")]: {
      "& h2": {
        fontSize: "1.5rem",
      },
    },
  },
}));
