import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export const Info = ({ fill = "black", ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="100%"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6485 13.8168H10.2862C10.2911 13.3481 10.3326 12.9648 10.4107 12.6669C10.4937 12.3642 10.628 12.0883 10.8135 11.8393C10.9991 11.5902 11.2457 11.307 11.5533 10.9897C11.7779 10.7602 11.983 10.5453 12.1685 10.3451C12.3589 10.1401 12.5128 9.92033 12.6299 9.68595C12.7471 9.44669 12.8057 9.16105 12.8057 8.82902C12.8057 8.4921 12.7447 8.20158 12.6226 7.95744C12.5054 7.71329 12.3297 7.52531 12.0953 7.39347C11.8658 7.26163 11.5801 7.19572 11.2383 7.19572C10.9551 7.19572 10.6866 7.24699 10.4327 7.34953C10.1788 7.45206 9.97369 7.61076 9.81744 7.8256C9.66119 8.03556 9.58063 8.31144 9.57574 8.65324H8.22076C8.23053 8.10148 8.36725 7.62785 8.63092 7.23234C8.89948 6.83683 9.2608 6.5341 9.7149 6.32413C10.169 6.11417 10.6768 6.00919 11.2383 6.00919C11.8585 6.00919 12.3858 6.1215 12.8204 6.34611C13.2598 6.57072 13.5943 6.89298 13.8238 7.3129C14.0533 7.72794 14.168 8.22111 14.168 8.7924C14.168 9.23185 14.0777 9.63712 13.897 10.0082C13.7213 10.3744 13.4942 10.7187 13.2159 11.0409C12.9376 11.3632 12.6422 11.6708 12.3297 11.9638C12.0611 12.2128 11.8804 12.4936 11.7877 12.8061C11.6949 13.1186 11.6485 13.4555 11.6485 13.8168ZM10.2276 16.1386C10.2276 15.9189 10.296 15.7333 10.4327 15.5819C10.5694 15.4306 10.7672 15.3549 11.0259 15.3549C11.2896 15.3549 11.4898 15.4306 11.6265 15.5819C11.7632 15.7333 11.8316 15.9189 11.8316 16.1386C11.8316 16.3485 11.7632 16.5292 11.6265 16.6806C11.4898 16.8319 11.2896 16.9076 11.0259 16.9076C10.7672 16.9076 10.5694 16.8319 10.4327 16.6806C10.296 16.5292 10.2276 16.3485 10.2276 16.1386Z"
          fill={fill}
        />
        <circle cx="11" cy="11" r="10.5" stroke={fill} />
      </svg>
    </SvgIcon>
  );
};
