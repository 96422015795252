import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Typography, useTheme } from "@material-ui/core";

import { DiscountTicketIcon } from "components/Icons/DiscountTicket";

import { usePaymentDiscountsStyles } from "./paymentDiscountsStyles";
import { DiscountsDialog } from "./DiscountsDialog";
import { SelectedDiscountList } from "./SelectedDiscountList";
import { useSelector } from "react-redux";
import { TerritoryContext } from "contexts/TerritoryContext";

export function PaymentDiscounts(props) {
  const { totalPassengers } = props;

  const { selectedTerritory } = useContext(TerritoryContext);
  const paymentOptions = selectedTerritory?.payment;
  const isTicketingEnabled = paymentOptions?.ticketing?.enabled || false;

  const [discountsDialogOpen, setDiscountsDialogOpen] = useState(false);

  const theme = useTheme();
  const classes = usePaymentDiscountsStyles();

  const selectedDiscounts = useSelector(
    (state) => state?.payment?.selectedDiscounts,
  );
  const applicableDiscounts = useSelector(
    (state) => state?.payment?.applicableDiscounts,
  );

  const [discountsAreSelected, setDiscountsAreSelected] = useState(false);

  useEffect(() => {
    if (selectedDiscounts?.length > 0) {
      setDiscountsAreSelected(true);
    } else {
      setDiscountsAreSelected(false);
    }
  }, [selectedDiscounts]);

  return (
    <>
      <SelectedDiscountList totalPassengers={totalPassengers} />

      {isTicketingEnabled && applicableDiscounts?.length > 0 && (
        <div className={classes.changeDiscounts}>
          <DiscountTicketIcon
            fill={theme.palette.info.main}
            className={classes.discountTicketIcon}
          />
          <button
            className={classes.link}
            onClick={() => setDiscountsDialogOpen(true)}
          >
            <Typography id="discountDialogName">
              {discountsAreSelected ? (
                <FormattedMessage
                  id="ticketing.edit_reduction_or_subscription"
                  defaultMessage="Modify a discount/subscription"
                />
              ) : (
                <FormattedMessage
                  id="ticketing.add_reduction_or_subscription"
                  defaultMessage="Add reduction or subscription"
                />
              )}
            </Typography>
          </button>
        </div>
      )}

      {discountsDialogOpen && (
        <DiscountsDialog
          maxDiscountQuantity={totalPassengers}
          onClose={() => setDiscountsDialogOpen(false)}
        />
      )}
    </>
  );
}
