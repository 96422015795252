// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incompatibleBrowser-cssmodule-wrapper-vVcl8{display:flex;flex-direction:column;justify-content:center;flex:1 0 auto;align-items:center;position:relative;z-index:1;background:#fff;margin:10% auto 0;padding:30px;box-shadow:0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)}.incompatibleBrowser-cssmodule-text-TI93e{text-align:center;font-size:2.3rem}.incompatibleBrowser-cssmodule-forgotButton-YTaRN{display:flex;margin-top:100px;font-weight:500}
`, "",{"version":3,"sources":["webpack://./src/views/IncompatibleBrowser/incompatibleBrowser.cssmodule.scss"],"names":[],"mappings":"AAAA,6CACE,YAAa,CACb,qBAAsB,CACtB,sBAAuB,CACvB,aAAc,CACd,kBAAmB,CACnB,iBAAkB,CAClB,SAAU,CACV,eAAgB,CAChB,iBAAkB,CAClB,YAAa,CACb,gEAE+B,CAChC,0CAGC,iBAAkB,CAClB,gBAAiB,CAClB,kDAGC,YAAa,CACb,gBAAiB,CACjB,eAAgB","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1 0 auto;\n  align-items: center;\n  position: relative;\n  z-index: 1;\n  background: #fff;\n  margin: 10% auto 0;\n  padding: 30px;\n  box-shadow:\n    0 1px 3px rgba(0, 0, 0, 0.12),\n    0 1px 2px rgba(0, 0, 0, 0.24);\n}\n\n.text {\n  text-align: center;\n  font-size: 2.3rem;\n}\n\n.forgotButton {\n  display: flex;\n  margin-top: 100px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `incompatibleBrowser-cssmodule-wrapper-vVcl8`,
	"text": `incompatibleBrowser-cssmodule-text-TI93e`,
	"forgotButton": `incompatibleBrowser-cssmodule-forgotButton-YTaRN`
};
export default ___CSS_LOADER_EXPORT___;
