import React, { Fragment } from "react";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl-phraseapp";

import { NotificationRowContainer } from "components/molecules/NotificationRow/NotificationRowContainer";
import {
  INotificationPreference,
  INotificationsError,
} from "./NotificationPreferencesContainer";

import styles from "./notificationPreferences.cssmodule.scss";

const notificationRows = [
  {
    notificationGroupKey: "PICKUP_NOTIFICATIONS",
    phraseId: "REMINDER_INFO_BEFORE_PU",
  },
  { notificationGroupKey: "ALERT_NOTIFICATIONS", phraseId: "IMPORTANT_ALERTS" },
  {
    notificationGroupKey: "BOOKING_NOTIFICATIONS",
    phraseId: "MY_BOOKINGS_MANAGEMENT",
  },
];

export function NotificationPreferences({
  notificationPreferences,
  onChange,
  errors,
}: {
  notificationPreferences?: INotificationPreference[];
  errors: INotificationsError[];
  onChange: (
    notificationGroupKey: string,
    channelChanged: { [key: string]: boolean },
  ) => void;
}) {
  return (
    <Card className={styles.wrapper}>
      <CardHeader
        id={"header-bookings"}
        title={
          <>
            <Typography
              className={styles.title}
              component="h2"
              data-testid="notificationPreferences-heading"
            >
              <FormattedMessage id={"LABEL_BOOKING"} />
            </Typography>
            <Divider />
          </>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          {notificationPreferences?.map((notificationPreference, index) => {
            const rowType = notificationRows.find(
              (row) =>
                row.notificationGroupKey ===
                notificationPreference.notification_group_key,
            );
            if (!rowType) return null;
            return (
              <Fragment key={notificationPreference.notification_group_key}>
                <NotificationRowContainer
                  rowType={rowType?.phraseId}
                  channels={notificationPreference.channels}
                  onChange={(channelChanged) => {
                    onChange(
                      notificationPreference.notification_group_key,
                      channelChanged,
                    );
                  }}
                  error={errors.find(
                    (error) =>
                      error.notificationGroupKey ===
                      notificationPreference.notification_group_key,
                  )}
                />
                {index < notificationRows.length - 1 && (
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                )}
              </Fragment>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
}
