import * as React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Badge } from "atoms/Badge/Badge";
import { Price } from "atoms/Price/Price";

import { BikeIcon } from "icons/BikeIcon";
import { ClockIcon } from "icons/ClockIcon";
import { PassengerIcon } from "icons/PassengerIcon";
import { PassengersIcon } from "icons/PassengersIcon";
import { SuitcaseIcon } from "icons/SuitcaseIcon";
import { WalkerIcon } from "icons/WalkerIcon";
import { WheelchairIcon } from "icons/WheelchairIcon";

import { useInfosBadgesStyles } from "./infosBadgesStyles";

type Discount = {
  name: string;
  seats: number;
};

type InfosBadgesProps = {
  bikeSeats?: number;
  discounts?: Discount[];
  duration?: string;
  hasBeenCanceledByDriver?: boolean;
  initialPrice?: number;
  price?: number;
  standardSeats?: number;
  allSeats?: number;
  textLabel?: string;
  walkDuration?: string;
  wheelchairSeats?: number;
  personalItems?: number;
};

export function InfosBadges(props: InfosBadgesProps) {
  const {
    bikeSeats,
    discounts,
    duration,
    hasBeenCanceledByDriver,
    initialPrice,
    price,
    standardSeats,
    allSeats,
    textLabel,
    walkDuration,
    wheelchairSeats,
    personalItems,
  } = props;

  const intl = useIntl();
  const classes = useInfosBadgesStyles();

  return (
    <div className={classes.infosBadgesContainer}>
      {textLabel && <Badge label={textLabel} />}

      {!!standardSeats && standardSeats > 0 && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "search.nb_standards" })}
          iconElement={<PassengerIcon />}
          label={standardSeats}
        />
      )}

      {!!allSeats && allSeats > 0 && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "search.nb_standards" })}
          iconElement={<PassengersIcon />}
          label={allSeats}
        />
      )}

      {!!wheelchairSeats && wheelchairSeats > 0 && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "search.nb_wheelchairs" })}
          iconElement={<WheelchairIcon />}
          label={wheelchairSeats}
        />
      )}

      {duration && duration !== "" && (
        <Badge iconElement={<ClockIcon />} label={duration} />
      )}

      {!!bikeSeats && bikeSeats > 0 && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "search.nb_bikechairs" })}
          iconElement={<BikeIcon />}
          label={bikeSeats}
        />
      )}

      {!!personalItems && personalItems > 0 && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "search.nb_personal_items" })}
          iconElement={<SuitcaseIcon />}
          label={personalItems}
        />
      )}

      {!!price && price > 0 && (
        <Badge
          label={
            <>
              {initialPrice && initialPrice > 0 && initialPrice !== price && (
                <>
                  <span className={classes.initialPrice}>
                    <Price value={initialPrice} />
                  </span>{" "}
                </>
              )}

              <Price value={price} />
            </>
          }
        />
      )}

      {walkDuration && walkDuration !== "" && (
        <Badge
          iconAriaLabel={intl.formatMessage({ id: "ride.walking_time" })}
          iconElement={<WalkerIcon />}
          label={walkDuration}
        />
      )}

      {discounts &&
        discounts.map((discount) => {
          return (
            <Badge
              styleVariant={{ background: "#e8f1f8" }}
              label={`${discount.name} x ${discount.seats}`}
              key={discount.name}
            />
          );
        })}

      {!!hasBeenCanceledByDriver && (
        <Badge
          styleVariant={{ background: "#fae5e8" }}
          label={
            <FormattedMessage
              id="ride.canceled_by_driver"
              defaultMessage="Canceled by driver"
            />
          }
        />
      )}
    </div>
  );
}
