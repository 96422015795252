import axios from "axios";

const OpenStreetMap_API_URL = "https://routing.openstreetmap.de";
const OpenStreetMap_PATH = "/routed-foot/route/v1/walking";

type OpenStreetMapLegsStep = {
  geometry: {
    coordinates: number[][];
  };
};

type OpenStreetMapResponseWalking = {
  routes: [
    {
      legs: [
        {
          steps: OpenStreetMapLegsStep[];
        },
      ];
    },
  ];
};

const convertToLatLng = (steps: OpenStreetMapLegsStep[]): LatLng[] => {
  const paths: LatLng[] = [];
  for (const step of steps) {
    for (const coordinate of step.geometry.coordinates) {
      paths.push({ lat: coordinate[1], lng: coordinate[0] });
    }
  }
  return paths;
};

// Get walking path OpenStreetMap with synchronous function axios
export const getWalkingPath = async (
  startPosition: LatLng,
  endPosition: LatLng,
) => {
  const response = await axios.get(
    `${OpenStreetMap_API_URL}${OpenStreetMap_PATH}/${startPosition.lng},${startPosition.lat};${endPosition.lng},${endPosition.lat}?overview=false&alternatives=false&steps=true&geometries=geojson`,
  );
  return convertToLatLng(
    (response.data as OpenStreetMapResponseWalking).routes[0].legs[0].steps,
  );
};
