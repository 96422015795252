import api from "api";
import {
  RECEIVE_SAVED_CARDS,
  RECEIVE_SAVED_IBANS,
  RECEIVE_STRIPE_TOKEN,
  REQUEST_SAVED_PAYMENT_METHODS,
  REQUEST_SAVED_PAYMENT_METHODS_ERROR,
  REQUEST_STRIPE_PAYMENT,
  REQUEST_STRIPE_PAYMENT_ERROR,
} from "containers/Payment/actions";
import { payloadHandleStripePaymentIntentParameters } from "./StripeType";
import { StateInterface } from "containers/Payment/types";
import {
  PaymentMethodEnum,
  StripeCardType,
  StripeIbanType,
} from "types/payment";

export const getSavedPaymentMethods = () => (dispatch) => {
  dispatch({
    type: REQUEST_SAVED_PAYMENT_METHODS,
  });

  api
    .getStripePaymentMethods()
    .then((savedPaymentmethods) => {
      const cards: Array<StripeCardType> = [];
      const ibans: Array<StripeIbanType> = [];
      for (const saved of savedPaymentmethods) {
        if (saved.type === PaymentMethodEnum.STRIPE_CARD) {
          cards.push(saved);
        }
        if (saved.type === PaymentMethodEnum.STRIPE_SEPA) {
          ibans.push(saved);
        }
      }

      dispatch({
        type: RECEIVE_SAVED_CARDS,
        savedCards: cards,
      });
      dispatch({
        type: RECEIVE_SAVED_IBANS,
        savedIBANs: ibans,
      });
    })
    .catch(() => {
      dispatch({
        type: REQUEST_SAVED_PAYMENT_METHODS_ERROR,
      });
    });
};

export const deletePaymentMethod = (type, id) => (dispatch) => {
  api
    .deleteStripePaymentMethod({ id, type })
    .then(() => dispatch(getSavedPaymentMethods()));
};

type PayloadStripePaymentIntent = {
  amount: number;
  payment_method_id?: string;
  setup_future_usage?: boolean;
  type: string;
};

/* aim of this function is to ask Stripe for a payment_intent, and return the payment_intent_id for validate-booking */
export const handleStripePaymentIntent =
  ({
    stripe,
    elements,
    totalPrice,
    shouldSavePaymentMethod,
    method,
    callback,
    extraOptions,
  }: payloadHandleStripePaymentIntentParameters) =>
  (dispatch, getState) => {
    dispatch({
      type: REQUEST_STRIPE_PAYMENT,
    });
    // 0 : setup payload for "createPaymentIntent"
    const state: StateInterface = getState();
    const selectedCardIndex = state?.payment?.selectedCardIndex;
    const selectedIbanIndex = state?.payment?.selectedIBANIndex;

    let payload: PayloadStripePaymentIntent = {
      amount: Math.round(totalPrice * 100), // round to avoid approximation errors
      type: method, // sepa_debit or card
    };

    if (method === PaymentMethodEnum.STRIPE_CARD && selectedCardIndex != -1) {
      const cardId = state?.payment?.savedCards[selectedCardIndex]?.id;
      payload = {
        ...payload,
        payment_method_id: cardId,
      };
    } else if (
      method === PaymentMethodEnum.STRIPE_SEPA &&
      selectedIbanIndex != -1
    ) {
      const ibanId = state?.payment?.savedIBANs[selectedIbanIndex]?.id;
      payload = {
        ...payload,
        payment_method_id: ibanId,
      };
    } else if (shouldSavePaymentMethod) {
      payload = {
        ...payload,
        setup_future_usage: true,
      };
    }

    // 1 : instantiate token for paymentIntent
    api
      .createStripePaymentIntent(payload)
      .then((res) => {
        let paymentMethodData;
        const fullname = `${state?.user?.user?.last_name} ${state?.user?.user?.first_name}`;
        if (
          method === PaymentMethodEnum.STRIPE_CARD &&
          selectedCardIndex === -1
        ) {
          paymentMethodData = {
            payment_method: {
              card: elements.getElement("cardNumber"),
              billing_details: {
                name: fullname,
                email: state.user.user.email,
              },
            },
          };
        } else if (
          method === PaymentMethodEnum.STRIPE_SEPA &&
          selectedIbanIndex === -1
        ) {
          paymentMethodData = {
            payment_method: {
              sepa_debit: elements.getElement("iban"),
              billing_details: {
                name: extraOptions?.name ?? fullname,
                email: state.user.user.email,
              },
            },
          };
        }

        const action = {
          [PaymentMethodEnum.STRIPE_CARD]: stripe.confirmCardPayment,
          [PaymentMethodEnum.STRIPE_SEPA]: stripe.confirmSepaDebitPayment,
        };

        action[method](res.client_secret, paymentMethodData)
          .then((stripeResult) => {
            if (stripeResult?.error) {
              dispatch({
                type: REQUEST_STRIPE_PAYMENT_ERROR,
                error: stripeResult?.error.message,
              });
            } else {
              dispatch({
                type: RECEIVE_STRIPE_TOKEN,
              });
              callback(stripeResult?.paymentIntent.id);
            }
          })
          .catch((error) => {
            dispatch({
              type: REQUEST_STRIPE_PAYMENT_ERROR,
              error,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: REQUEST_STRIPE_PAYMENT_ERROR,
          error,
        });
      });
  };
