// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationPreferences-cssmodule-wrapper-AE7Nd{display:flex;flex-direction:column;width:100%;height:100%;margin:auto;padding:0 2rem}.notificationPreferences-cssmodule-title-Tdp5y{font-size:1.25rem !important;font-weight:700 !important;margin-bottom:1rem !important}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/NotificationPreferences/notificationPreferences.cssmodule.scss"],"names":[],"mappings":"AAAA,iDACE,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CACZ,WAAY,CACZ,cAAe,CAChB,+CAGC,4BAA6B,CAC7B,0BAA2B,CAC3B,6BAA8B","sourcesContent":[".wrapper {\n  display: flex;\n  flex-direction: column;\n\n  width: 100%;\n  height: 100%;\n  margin: auto;\n  padding: 0 2rem;\n}\n\n.title {\n  font-size: 1.25rem !important;\n  font-weight: 700 !important;\n  margin-bottom: 1rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `notificationPreferences-cssmodule-wrapper-AE7Nd`,
	"title": `notificationPreferences-cssmodule-title-Tdp5y`
};
export default ___CSS_LOADER_EXPORT___;
