import { ResourceType } from "api/resources/types/resourceType";

export const payment: ResourceType = {
  createStripePaymentIntent: {
    endpoint: "/payment/stripe/create-payment-intent",
    method: "POST",
    impersonate: true,
  },
  getStripePaymentMethods: {
    endpoint: "/payment/stripe/user/payment-methods",
    method: "GET",
    impersonate: true,
  },
  deleteStripePaymentMethod: {
    endpoint: "/payment/stripe/user/payment-method/{id}",
    method: "DELETE",
    impersonate: true,
  },
  getCreditPurchaseConditions: {
    endpoint: "/get-credit-purchase-conditions",
    method: "GET",
    impersonate: true,
  },
  getCreditBalance: {
    endpoint: "/payment/get-credit-balance",
    method: "GET",
    impersonate: true,
  },
  purchaseCredit: {
    endpoint: "/purchase-credit",
    impersonate: true,
    method: "POST",
  },
  // TODO Never used
  createStripeSetupIntent: {
    endpoint: "/payment/stripe/create-setup-intent",
    method: "POST",
    impersonate: true,
  },
  // TODO Never used
  confirmStripeSetupeIntent: {
    endpoint: "/payment/stripe/confirm-setup-intent",
    method: "POST",
    impersonate: true,
  },
};
