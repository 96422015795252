import React, { useContext } from "react";
import ReactPhoneInput from "react-phone-input-material-ui";
import "react-phone-input-material-ui/lib/style.css";

import { TextField } from "@material-ui/core";

import { ProductContext } from "contexts/ProductContext";

import {
  LANG_TO_COUNTRY,
  getCountryFromBrowserLanguage,
} from "utils/phoneNumber";

import { usePhoneFieldStyles } from "./phoneFieldStyles";

const PREFERED_COUNTRIES = ["ch", "de", "gb", "es", "fr", "it", "se"];

const getPhoneNumberDefaultCountry = (
  phoneNumberDefaultCountry,
  userLanguageCode,
) => {
  if (phoneNumberDefaultCountry) {
    return phoneNumberDefaultCountry.toLowerCase();
  }

  if (userLanguageCode) {
    return LANG_TO_COUNTRY[userLanguageCode.toLowerCase()];
  }

  return getCountryFromBrowserLanguage();
};

export function PhoneField(props) {
  const {
    autoComplete,
    error,
    helperText,
    id,
    label,
    onChange,
    required,
    value,
  } = props;

  const classes = usePhoneFieldStyles();
  const { productParameters } = useContext(ProductContext);

  const phoneNumberDefaultCountry = getPhoneNumberDefaultCountry(
    productParameters?.phonenumber_default_region,
    productParameters?.user?.user_language_code,
  );

  return (
    <ReactPhoneInput
      className={classes.phoneField}
      id="phone-input"
      inputClass={classes.field}
      dropdownClass={classes.countryList}
      component={TextField}
      label={required ? label + " *" : label}
      placeholder=""
      value={value}
      autoComplete={autoComplete}
      required={required}
      country={phoneNumberDefaultCountry}
      preferredCountries={PREFERED_COUNTRIES}
      enableAreaCodes
      inputProps={{ error, helperText, id }}
      InputLabelProps={{ htmlFor: id }}
      onChange={(newValue) => onChange(`+${newValue}`)} // passed function receives the phone value
    />
  );
}
