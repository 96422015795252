import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Card,
  CardActionArea,
  CardHeader,
  Collapse,
  Paper,
  Typography,
} from "@material-ui/core";

import { capitalize } from "utils/strings";

import FreePaymentProvider from "components/Payment/FreePaymentProvider";
import CustomPaymentProvider from "components/Payment/CustomPaymentProvider";
import Bus from "components/Icons/Bus";
import ExpandLess from "components/Icons/ExpandLess";
import ExpandMore from "components/Icons/ExpandMore";

import { validateBooking } from "containers/BookingValidation/actions";
import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";
import {
  getPaymentMethods,
  paymentMode,
  paymentProvidersAndMethods,
} from "containers/Payment/payments";

import type {
  StateInterface,
  StatePaymentMethodsContainerInterface,
} from "./types";
import { PaymentMethodEnum } from "types/payment";

type PaymentMethodsContainerProps = {
  paymentOptions: {
    enabled: boolean;
    payment_methods: string[];
  };
  totalPrice: number;
};

const PaymentMethodsContainer = (props: PaymentMethodsContainerProps) => {
  const { paymentOptions, totalPrice } = props;

  const dispatch = useDispatch();
  const isRequesting = useSelector(
    (state: StatePaymentMethodsContainerInterface) =>
      state.bookingValidation?.isRequesting,
  );
  const bookingsCounter = useSelector(
    (state: StatePaymentMethodsContainerInterface) =>
      Object.keys(state?.search?.selectedProposals).length,
  );

  const intl = useIntl();

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { selectedTerritory } = useContext(TerritoryContext);
  const { userProfile } = useContext(UserContext);

  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);
  const [currentMethod, setCurrentMethod] = useState<string | null>(null);

  const customerId = userProfile.customerId;

  const selectedDiscounts = useSelector(
    (state: StateInterface) => state?.payment?.selectedDiscounts,
  );
  const discountedPricesState = useSelector(
    (state: StateInterface) => state?.payment?.discountedPrices,
  );

  const discountedPricesMultiDateState = useSelector(
    (state: StateInterface) => state?.payment?.discountedPricesMultiDate,
  );

  const finalPriceMultiDate = discountedPricesMultiDateState
    ? Object.values(discountedPricesMultiDateState).reduce(
        (acc, curr) => acc + curr.price,
        0,
      )
    : undefined;

  const finalPrice =
    discountedPricesState?.price !== undefined
      ? discountedPricesState.price
      : totalPrice;

  const isMultiDateEnabled = selectedTerritory?.booking?.multi_date?.enabled;

  useEffect(() => {
    let paymentMethods: string[] = [];
    if (isMultiDateEnabled && bookingsCounter > 1) {
      paymentMethods = [PaymentMethodEnum.ON_BOARD];
    } else {
      paymentMethods = getPaymentMethods(paymentOptions);
    }

    setPaymentMethods(paymentMethods);
    setCurrentMethod(paymentMethods[0]);
  }, [paymentOptions, isMultiDateEnabled, bookingsCounter]);

  const renderFreePayment = () => {
    return (
      <Paper className="MuiEngagementPaper--01 paperContainer">
        <FreePaymentProvider
          validate={() =>
            dispatch(
              validateBooking({
                customerId: customerId,
                selectedTerritoryKey: selectedTerritory?.territory_key,
                paymentMode: paymentMode.ZERO,
                paymentData: {},
                selectedDiscounts: selectedDiscounts,
              }),
            )
          }
          isRequesting={isRequesting}
        />
      </Paper>
    );
  };

  const renderNoPaymentMethodsSelected = () => {
    return (
      <Paper className="MuiEngagementPaper--01 paperContainer column">
        <Typography
          component="p"
          style={{
            padding: "2rem 1rem",
            textAlign: "center",
          }}
        >
          <FormattedMessage
            id="payment.no-payment-method"
            defaultMessage="Error: No payment method available."
          />
        </Typography>
      </Paper>
    );
  };

  // In case a promoCode sets price to zero:
  if (finalPrice === 0 || finalPriceMultiDate === 0) {
    return renderFreePayment();
  }

  if (paymentMethods.length === 0) {
    return renderNoPaymentMethodsSelected();
  }

  const paymentLabel = (method) => {
    switch (method) {
      case PaymentMethodEnum.CREDIT:
        return intl.formatMessage({ id: "payment.credits" });
      case PaymentMethodEnum.STRIPE_CARD:
        return intl.formatMessage({ id: "LABEL_PAYMENT_MODE_CREDIT_CARD" });
      case PaymentMethodEnum.STRIPE_SEPA:
        return intl.formatMessage({ id: "payment.sepa" });
      case PaymentMethodEnum.ON_BOARD:
        return intl.formatMessage({ id: "payment.onboard" });
      case PaymentMethodEnum.PAYPAL:
        return intl.formatMessage({ id: "payment.paypal" });
      default:
        return capitalize(method);
    }
  };

  const paymentAriaLabel = (method) =>
    intl.formatMessage(
      { id: "payment.open_tab" },
      { method: paymentLabel(method) },
    );

  // Desktop view:
  return (
    <div className="column">
      {paymentMethods.map((method, index) => {
        const PaymentComponent =
          paymentProvidersAndMethods[method].component || CustomPaymentProvider;
        const Icon = paymentProvidersAndMethods[method].icon || Bus;
        return (
          <Card
            className="MuiEngagementPaper--01"
            style={{ margin: "0 0 1rem 0" }}
            key={`provider-${index}`}
            aria-label={paymentAriaLabel(method)}
          >
            <CardActionArea>
              <div
                aria-label={`open ${method} tab`}
                data-isopen={Boolean(method === currentMethod).toString()}
                onClick={() =>
                  setCurrentMethod(method === currentMethod ? "" : method)
                }
                className="row-only"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingRight: "1rem",
                }}
              >
                <CardHeader
                  avatar={<Icon style={{ width: "2rem", height: "2rem" }} />}
                  title={
                    <Typography style={{ fontWeight: 400 }} variant="h2">
                      {paymentLabel(method)}
                    </Typography>
                  }
                />
                {method === currentMethod ? <ExpandLess /> : <ExpandMore />}
              </div>
            </CardActionArea>

            <Collapse in={method === currentMethod}>
              <PaymentComponent
                method={method}
                buttonLabel={`pay with ${method}`}
                key={index}
                validate={(paymentData) => {
                  dispatch(
                    validateBooking({
                      customerId: customerId,
                      selectedTerritoryKey: selectedTerritory?.territory_key,
                      paymentMode:
                        paymentProvidersAndMethods[method].method || method,
                      paymentData: paymentData,
                      selectedDiscounts: selectedDiscounts,
                    }),
                  );
                }}
                totalPrice={finalPrice}
                paymentOptions={paymentOptions}
                isRequesting={isRequesting}
              />
            </Collapse>
          </Card>
        );
      })}
    </div>
  );
};

export default PaymentMethodsContainer;
