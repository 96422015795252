import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { connect } from "react-redux";

import { Icon, List, ListItem, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import PaperBox from "components/PaperBox/PaperBox";
import Clock from "components/Icons/Clock/Clock";
import DateRange from "components/Icons/DateRange";
import DropOff from "components/Icons/DropOff/DropOff";
import PickUp from "components/Icons/PickUp/PickUp";
import { InfosBadges } from "molecules/InfosBadges/InfosBadges";

import { TerritoryContext } from "contexts/TerritoryContext";

import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";

import { FORMAT_HOUR } from "utils/constants";

import { useSearchSidebarStyles } from "./searchSidebarStyles";

function SearchSidebar({ formData, profiles }) {
  const {
    departure,
    destination,
    time,
    timeRestrictionType,
    passengers,
    personalItems,
    selectedDays,
    recurrence,
  } = formData;
  const personalItemsCount = personalItems?.count || 0;

  const classes = useSearchSidebarStyles();
  const intl = useIntl();

  const { selectedTerritory } = useContext(TerritoryContext);

  const recurringBookings = selectedTerritory?.booking?.recurring_bookings;
  const recurringOffers =
    recurringBookings && recurringBookings.enabled
      ? recurringBookings.offers
      : [];

  let renderData = [];
  const nbPassengerStandard = Object.keys(profiles).reduce(
    (acc, profile) => acc + profiles[profile].count,
    0,
  );
  let nbPassengerWheelchair = 0;

  Object.keys(passengers).forEach((type) => {
    if (passengers[type] > 0) {
      if (type === "access") {
        nbPassengerWheelchair += passengers[type];
      }
    }
  });

  renderData = [
    ...renderData,
    {
      icon: <PickUp height={24} width={24} aria-hidden />,
      label: intl.formatMessage({ id: "search.pickup" }).toUpperCase(),
      data: departure.display,
    },
    {
      icon: <DropOff height={24} width={24} aria-hidden />,
      label: intl.formatMessage({ id: "search.dropoff" }).toLocaleUpperCase(),
      data: destination.display,
    },
    {
      icon: <Clock height={24} width={24} aria-hidden />,
      label: <FormattedMessage id={`search.${timeRestrictionType}_time`} />,
      data: dayjs(time).format(FORMAT_HOUR),
    },
  ];

  if (!recurrence || recurrence.id === 0) {
    const formattedDatesArray = selectedDays.map((datetime) =>
      capitalize(i18nVerboseDateFormat(datetime)),
    );

    renderData.push({
      icon: <DateRange aria-hidden />,
      label: (
        <FormattedMessage
          id="date.title"
          defaultMessage="{count, plural, =0 {Date} one {Date} other {Dates}}"
          values={{ count: formattedDatesArray.length }}
        />
      ),
      data: formattedDatesArray.join(" - "),
    });
  } else {
    renderData = [
      ...renderData,
      {
        icon: <Icon aria-hidden>date_range</Icon>,
        label: <FormattedMessage style={{}} id="search.recurrence.title" />,
        data: recurringOffers.find((o) => o.id === recurrence.id).name,
      },
      {
        icon: <Icon aria-hidden>date_range</Icon>,
        label: "",
        data: (
          <FormattedMessage
            id="search.multi_date"
            values={{
              startDate: i18nVerboseDateFormat(recurrence.start_datetime),
              endDate: i18nVerboseDateFormat(recurrence.end_datetime),
            }}
          />
        ),
      },
    ];
  }

  return (
    <PaperBox title={<FormattedMessage id="search.sidebar.title" />}>
      <List className={classes.searchSidebarList}>
        {renderData.map((row, index) => (
          <ListItem className={classes.listItem} key={`search-${index}`}>
            <div className={classes.listItemIcon}>{row.icon}</div>

            {row.label ? (
              <Typography className={classes.listItemLabel}>
                {row.label}
              </Typography>
            ) : null}

            <Typography>{row.data}</Typography>
          </ListItem>
        ))}
        <ListItem className={classes.listItem}>
          <InfosBadges
            standardSeats={nbPassengerStandard}
            wheelchairSeats={nbPassengerWheelchair}
            personalItems={personalItemsCount}
          />
        </ListItem>
      </List>
    </PaperBox>
  );
}

const mapState = (state) => {
  return {
    formData: state.search.searchForm,
    profiles: state.passengersProfiles.profiles,
  };
};

export default connect(mapState)(SearchSidebar);
