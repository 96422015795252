import * as React from "react";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";

import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Collapse,
  Link,
  Typography,
} from "@material-ui/core";

import { Badge } from "atoms/Badge/Badge";
import { PersonalItemsCounters } from "components/organisms/PersonalItemsCounters/PersonalItemsCounters";

import ExpandMore from "components/Icons/ExpandMore";
import ExpandLess from "components/Icons/ExpandLess";
import { OpenNewTabIcon } from "icons/OpenNewTabIcon";
import { SuitcaseIcon } from "icons/SuitcaseIcon";

import { ProductContext } from "contexts/ProductContext";

import { usePersonalItemsStyles } from "./PersonalItemsStyles";

export default function PersonalItems(props) {
  const { personalItems, onPersonalItemsChange } = props;

  const [isPersonalItemsOpen, setIsPersonalItemsOpen] = useState(false);
  const { productParameters } = useContext(ProductContext);
  const intl = useIntl();
  const classes = usePersonalItemsStyles();

  const changePersonalItems = () => {
    onPersonalItemsChange(personalItems.personalItems);
  };

  const onKeyDown = (e) => {
    e.stopPropagation();
    switch (e.keyCode) {
      case 38: // UP ARROW KEY
      case 40: // DOWN ARROW KEY
      case 13: // ENTER KEY
        togglePersonalItemsOpen();
        return;
      default:
        return;
    }
  };

  const togglePersonalItemsOpen = () => {
    setIsPersonalItemsOpen(!isPersonalItemsOpen);
  };

  const equipmentsDocumentationUrl =
    productParameters?.contact?.equipments_documentation_url || "";

  if (personalItems?.isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  const isPersonalItemsDisplayed =
    personalItems &&
    personalItems?.personalItems &&
    Object.keys(personalItems.personalItems).length > 0;

  return (
    <>
      {isPersonalItemsDisplayed && (
        <Card className={classes.card}>
          <div
            className={classes.cardHeaderContainer}
            tabIndex={0}
            role="region"
            aria-expanded={isPersonalItemsOpen}
            onClick={() => togglePersonalItemsOpen()}
            onKeyDown={(e) => onKeyDown(e)}
          >
            <CardHeader
              className={classes.cardHeader}
              id={"header-personal-items"}
              title={
                <Typography variant="caption" component="legend">
                  {intl.formatMessage({
                    id: "research.section.add_personal_items",
                  })}

                  <Badge
                    iconElement={<SuitcaseIcon />}
                    label={personalItems.count}
                  />
                </Typography>
              }
            />

            {isPersonalItemsOpen ? <ExpandLess /> : <ExpandMore />}
          </div>

          <Collapse in={isPersonalItemsOpen}>
            <CardContent
              className={classes.cardContent}
              aria-labelledby={"header-personal-items"}
              aria-expanded={isPersonalItemsOpen}
            >
              <PersonalItemsCounters
                personalItemsObj={personalItems}
                onChange={changePersonalItems}
              />

              {equipmentsDocumentationUrl.length > 0 && (
                <Link href={equipmentsDocumentationUrl} target="_blank">
                  <Typography className={classes.linkText}>
                    <span>
                      {intl.formatMessage({
                        id: "help.equipments_policy",
                      })}
                    </span>

                    <span className={classes.newTabIcon}>
                      <OpenNewTabIcon />
                    </span>
                  </Typography>
                </Link>
              )}
            </CardContent>
          </Collapse>
        </Card>
      )}
    </>
  );
}
