import React, { useContext } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Paper, Typography } from "@material-ui/core";

import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import { IndicationsToDriverInput } from "components/organisms/IndicationsToDriverInput/IndicationsToDriverInput";

import { useDriverDetailsStyles } from "./driverDetailsStyles";
import { PaymentMethodAPIEnum } from "types/payment";

export function DriverDetails(props) {
  const { reservation } = props;

  const { productParameters } = useContext(ProductContext);
  const classes = useDriverDetailsStyles();

  const driver = reservation?.driver;
  const callDriverEnabled = productParameters?.features?.call_driver?.enabled;
  const bus = reservation?.bus;
  const { selectedTerritory } = useContext(TerritoryContext);

  const isIndicationsToDriverEnabled =
    selectedTerritory?.booking?.comment?.enabled &&
    // ride.pickup_waiting_time > 0 &&
    reservation?.ride_status !== PaymentMethodAPIEnum.ONBOARD;

  const reservationId = reservation?.id;

  return (
    <Paper className={classes.driverDetailsContainer}>
      <div className={classes.driverDetailsBlock}>
        <div className={classes.driverDetail}>
          <Typography className={classes.driverDetailType}>
            <FormattedMessage id="ride.driver_name" />
          </Typography>

          <Typography className={classes.driverDetailContent}>
            {driver.first_name}
          </Typography>

          {callDriverEnabled && (
            <div className={classes.driverDetail}>
              <Typography className={classes.driverDetailType}>
                <FormattedMessage id="ride.driver_phone" />
              </Typography>

              <Typography className={classes.driverDetailContent}>
                {driver.phone_number}
              </Typography>
            </div>
          )}
        </div>

        <div className={classes.driverDetail}>
          <Typography className={classes.driverDetailType}>
            <FormattedMessage id="ride.bus_plate" />
          </Typography>

          <Typography className={classes.driverDetailContent}>
            {bus.plate}
          </Typography>
        </div>

        <div className={classes.driverDetail}>
          <Typography className={classes.driverDetailType}>
            <FormattedMessage id="ride.bus_model" />
          </Typography>

          <Typography className={classes.driverDetailContent}>
            {bus.model}
          </Typography>
        </div>
      </div>
      {isIndicationsToDriverEnabled && (
        <div className={classes.driverIndicationsBlock}>
          <IndicationsToDriverInput
            previousIndications={reservation.comment ? reservation.comment : ""}
            bookingId={reservationId}
          />
        </div>
      )}
    </Paper>
  );
}
