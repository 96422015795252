import { makeStyles } from "@material-ui/core";

export const usePaymentContainerStyles = makeStyles((theme) => ({
  contentWrapper: {
    padding: "1rem 0.5rem",

    [theme.breakpoints.up("sm")]: {
      padding: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      padding: "2rem",
    },
  },
  icon: {
    width: "32px",
    height: "32px",
  },
}));
