import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";

export default function FreePaymentProvider(props) {
  const { validate, isRequesting } = props;

  return (
    <div className="column" style={{ alignItems: "center", padding: "1rem" }}>
      <Typography variant="h2" style={{ padding: "2rem" }}>
        <FormattedMessage id="payment.free" />
      </Typography>

      <MuiButton
        color="primary"
        variant="contained"
        onClick={() => validate()}
        disabled={isRequesting}
      >
        {isRequesting ? (
          <CircularProgress />
        ) : (
          <FormattedMessage id="misc.validate" />
        )}
      </MuiButton>
    </div>
  );
}
