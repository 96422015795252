import { makeStyles } from "@material-ui/core";

export const useUnauthenticatedLayoutStyles = makeStyles((theme) => ({
  viewBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "100%",
    minHeight: "100vh",

    background: theme.palette.primary.main,
  },
  viewWrapper: {
    width: "100%",
    maxWidth: theme.breakpoints.values.xl,
    height: "100%",
    margin: "0 auto",
  },
}));
