import api from "api";
import { snackMessage } from "containers/SnackBar/actions.js";
import { getSavedPaymentMethods } from "containers/Stripe/actions";

export const INITIATE_CREDIT_MATRIX_REQUEST =
  "credit @@ INITIATE_CREDIT_MATRIX_REQUEST";
export const RECEIVE_CREDIT_MATRIX = "credit @@ RECEIVE_CREDIT_MATRIX";
export const CREDIT_MATRIX_REQUEST_ERROR =
  "credit @@ CREDIT_MATRIX_REQUEST_ERROR";

export const INITIATE_CREDIT_BALANCE_REQUEST =
  "credit @@ INITIATE_CREDIT_BALANCE_REQUEST";
export const RECEIVE_CREDIT_BALANCE = "credit @@ RECEIVE_CREDIT_BALANCE";
export const CREDIT_BALANCE_REQUEST_ERROR =
  "credit @@ CREDIT_BALANCE_REQUEST_ERROR";

export const INITIATE_CREDIT_PURCHASE_REQUEST =
  "credit @@ INITIATE_CREDIT_PURCHASE_REQUEST";
export const CREDIT_PURCHASE_REQUEST_SUCCESS =
  "credit @@ CREDIT_PURCHASE_REQUEST_SUCCESS";
export const CREDIT_PURCHASE_REQUEST_ERROR =
  "credit @@ CREDIT_PURCHASE_REQUEST_ERROR";

export const getCreditMatrix = (selectedTerritoryKey) => (dispatch) => {
  dispatch({ type: INITIATE_CREDIT_MATRIX_REQUEST });
  api
    .getCreditPurchaseConditions(undefined, { territory: selectedTerritoryKey })
    .then((json) =>
      dispatch({
        type: RECEIVE_CREDIT_MATRIX,
        matrix: json.credit_matrix,
      }),
    )
    .catch((error) =>
      dispatch({
        type: CREDIT_MATRIX_REQUEST_ERROR,
        error,
      }),
    );
};

export const getCreditBalance = () => (dispatch) => {
  dispatch({ type: INITIATE_CREDIT_BALANCE_REQUEST });
  api
    .getCreditBalance()
    .then((json) =>
      dispatch({
        type: RECEIVE_CREDIT_BALANCE,
        balance: json.credit_balance,
      }),
    )
    .catch((error) =>
      dispatch({
        type: CREDIT_BALANCE_REQUEST_ERROR,
        error,
      }),
    );
};

export const purchaseCredit =
  (creditAmount, realAmount, payment, selectedTerritoryKey) => (dispatch) => {
    dispatch({ type: INITIATE_CREDIT_PURCHASE_REQUEST });
    api
      .purchaseCredit(
        {
          credit_amount: creditAmount,
          credit_purchasing_price: realAmount,
          payment,
        },
        { territory: selectedTerritoryKey },
      )
      .then((json) => {
        dispatch({
          type: CREDIT_PURCHASE_REQUEST_SUCCESS,
        });
        dispatch(snackMessage("success", json.message));
        dispatch(getCreditBalance());
        // maybe shouldn't be here, but synchronicity is easier here rather than promises or callback
        dispatch(getSavedPaymentMethods());
      })
      .catch((error) =>
        dispatch({
          type: CREDIT_PURCHASE_REQUEST_ERROR,
          error,
        }),
      );
  };
