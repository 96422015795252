import React, { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import { Grid } from "@material-ui/core";

import { SalesConditions } from "components/SalesConditions/SalesConditions";
import { PaymentResume } from "containers/Payment/PaymentResume";

import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import { usePaymentContainerStyles } from "./paymentContainerStyles";
import PaymentMethodsContainer2 from "containers/Payment/PaymentMethodsContainer";

function PaymentContainer(props) {
  const { selectedProposals, totalPrice } = props;

  const navigate = useNavigate();
  const classes = usePaymentContainerStyles();

  const { productParameters } = useContext(ProductContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const brand = productParameters?.brand;
  const gtcsURL = productParameters?.contact?.gtcs_url;
  const paymentOptions = selectedTerritory?.payment;

  const hasDirectPaymentOptions = useMemo(
    () =>
      paymentOptions?.payment_methods.some((r) =>
        ["paypal", "creditcard", "payzone", "stripe", "ticketing"].includes(r),
      ),
    [paymentOptions],
  );

  // don't redirect if is automated test context
  if (_.isEmpty(selectedProposals) && !window.Cypress)
    navigate("/search/result");

  return (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      className={classes.contentWrapper}
    >
      <Grid item xs={12} sm={10} md={4} lg={4}>
        <PaymentResume />
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={6}>
        <PaymentMethodsContainer2
          totalPrice={totalPrice}
          paymentOptions={paymentOptions}
        />
        {hasDirectPaymentOptions && (
          <SalesConditions brand={brand} gtcsURL={gtcsURL} />
        )}
      </Grid>
    </Grid>
  );
}
const mapStateToProps = (state) => {
  return {
    selectedProposals: _.get(state, "search.selectedProposals"),
    responses: _.get(state, "search.responses"),
    totalPrice: _.get(state, "payment.totalPrice"),
  };
};

export default connect(mapStateToProps)(PaymentContainer);
