import React from "react";
import { useIntl } from "react-intl";

import {
  withTheme,
  IconButton,
  Slide,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@material-ui/core";

import Check from "components/Icons/Check";
import Cross from "components/Icons/Cross/Cross";
import Interrogation from "components/Icons/Interrogation";
import Warning from "components/Icons/Warning";

import { useSnackBarStyles } from "./snackBarStyles";

const SNACKBAR_TYPES = ["success", "warning", "error", "info"];

const icons = {
  success: Check,
  warning: Warning,
  error: Warning,
  info: Interrogation,
};

function SnackBar(props) {
  // The "hide" effect is managed in reducer with a timeout:
  const { close, message, open, severity, theme } = props;

  const classes = useSnackBarStyles();
  const intl = useIntl();

  if (!SNACKBAR_TYPES.includes(severity)) {
    return null;
  }

  const Icon = icons[severity];

  return (
    <Snackbar
      TransitionComponent={Slide}
      open={open}
      aria-live="assertive"
      aria-atomic="true"
    >
      <SnackbarContent
        data-testid="snackbar"
        className={classes.snackBarContent}
        style={{ backgroundColor: theme.palette[severity].dark }}
        message={
          <div className={classes.snackBarMessage}>
            <Icon className={classes.severityIcon} fill="white" />

            <Typography>{message}</Typography>
          </div>
        }
        action={
          <IconButton
            className={classes.closeIconButton}
            tabIndex={-1}
            aria-label={intl.formatMessage({ id: "misc.close_dialog" })}
            onClick={close}
          >
            <Cross
              className={classes.closeIcon}
              fill="white"
              focusable="false"
              aria-hiddden="true"
            />
          </IconButton>
        }
        role="none"
      />
    </Snackbar>
  );
}

export default withTheme(SnackBar);
