import { makeStyles } from "@material-ui/core";

export const useInfosBadgesStyles = makeStyles(() => ({
  infosBadgesContainer: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",

    textTransform: "none",
  },
  initialPrice: {
    textDecoration: "line-through",
  },
}));
