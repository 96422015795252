import React, { useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import BookingValidationResult from "components/BookingValidation/BookingValidationResult";

import {
  getPersonalItems,
  resetPassengersCount,
  searchReturnTrip,
  setBookingReturnTrip,
} from "containers/SearchForm/actions.js";
import {
  getFormattedPassengersProfile,
  setPassengerProfilesLoadingStatus,
} from "containers/PassengersProfiles/actions";
import { closeValidationResult } from "./actions.js";
import { TerritoryContext } from "contexts/TerritoryContext/index";

function BookingValidationResultContainer({
  closeValidationResult,
  dialogOpen,
  edition,
  errors,
  isRequesting,
  responses,
  departure,
  destination,
  time,
  isReturnTrip,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { selectedTerritory } = useContext(TerritoryContext);

  const territoryId = selectedTerritory?.territory_id;

  const wipeSearchForm = () => {
    dispatch(setPassengerProfilesLoadingStatus());
    dispatch(resetPassengersCount());
    dispatch(getPersonalItems(selectedTerritory));
    dispatch(getFormattedPassengersProfile({ territoryId }));
    dispatch(setBookingReturnTrip(false));
  };

  const backToSearch = () => {
    wipeSearchForm();
    closeValidationResult();
    navigate("/search");
  };

  const bookReturnTrip = () => {
    closeValidationResult();
    dispatch(searchReturnTrip(departure, destination, time));
    dispatch(setBookingReturnTrip(true));
    navigate("/search");
  };

  const redirectToRideId = () => {
    wipeSearchForm();
    closeValidationResult();

    const day = Object.keys(responses)[0];
    const bookingId = responses[day].id;

    navigate(`/reservation/${bookingId}`);
  };

  const redirectToRides = () => {
    closeValidationResult();
    navigate("/reservation");
  };

  return (
    <>
      {dialogOpen && (
        <BookingValidationResult
          backToSearch={backToSearch}
          bookReturnTrip={bookReturnTrip}
          closeValidationResult={closeValidationResult}
          edition={edition}
          errors={errors}
          isRequesting={isRequesting}
          redirectToRideId={redirectToRideId}
          redirectToRides={redirectToRides}
          responses={responses}
          time={time}
          isReturnTrip={isReturnTrip}
        />
      )}
    </>
  );
}

const _mapState = (state) => ({
  dialogOpen: state.bookingValidation.dialogOpen,
  errors: state.bookingValidation.requestErrors,
  isRequesting: state.bookingValidation.isRequesting,
  responses: state.bookingValidation.responses,
  departure: state.search.searchForm.departure,
  destination: state.search.searchForm.destination,
  time: state.search.searchForm.time,
  isReturnTrip: state.search.isReturnTrip,
});

const _mapDispatch = (dispatch) => ({
  closeValidationResult: () => {
    dispatch(closeValidationResult());
  },
});

export default connect(
  _mapState,
  _mapDispatch,
)(BookingValidationResultContainer);
