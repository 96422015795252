export enum PassengerProfileEnum {
  PASSENGER = "PASSENGER",
  ADULT = "ADULT",
  CHILD = "CHILD",
  BABY = "BABY",
}

export type PassengerProfileType =
  | PassengerProfileEnum.PASSENGER
  | PassengerProfileEnum.ADULT
  | PassengerProfileEnum.CHILD
  | PassengerProfileEnum.BABY
  | null;

export type Profile = {
  id: number;
  ageMin: number;
  ageMax: number;
  isEnabled: boolean;
  count: number;
};

export type PassengerProfilesType = {
  [PassengerProfileEnum.PASSENGER]: Profile;
  [PassengerProfileEnum.ADULT]: Profile;
  [PassengerProfileEnum.CHILD]: Profile;
  [PassengerProfileEnum.BABY]: Profile;
};

export enum GenericSeat {
  ACCESS = "access",
}

export type GenericSeats = GenericSeat.ACCESS;
