import * as React from "react";
import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";

import { CounterButtons } from "molecules/CounterButtons/CounterButtons";

import { usePersonalItemsCountersStyles } from "./PersonalItemsCountersStyles";

export type PersonalItemType = {
  id: number;
  name: string;
  count: number;
  externalIdentifier: string;
  maximum: number;
};

type PersonalItemsCountersProps = {
  onChange: () => void;
  personalItemsObj: { isLoading: boolean; personalItems: PersonalItemType[] };
};

export function PersonalItemsCounters(props: PersonalItemsCountersProps) {
  const { onChange, personalItemsObj } = props;
  const intl = useIntl();
  const classes = usePersonalItemsCountersStyles();

  const { isLoading, personalItems } = personalItemsObj;

  if (isLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }
  const getCounterButtonProps = (type: string) => {
    const label = personalItems[type]?.externalIdentifier
      ? intl.formatMessage({
          id: `LABEL_${personalItems[type]?.externalIdentifier}`,
          defaultMessage: type,
        })
      : intl.formatMessage({ id: type });
    const maxValue = personalItems[type]?.maximum || 0;
    return [label, maxValue] as [string, number];
  };

  return (
    <>
      {Object.entries(personalItems).map(
        ([type, personal_item]: [string, PersonalItemType]) => {
          const [label, maxValue] = getCounterButtonProps(type);
          if (!maxValue) {
            return null;
          }
          return (
            <CounterButtons
              key={personal_item.id}
              label={label}
              minValue={0}
              maxValue={maxValue}
              onChange={(value) => {
                personal_item.count = value;
                onChange();
              }}
              defaultValue={personal_item.count || 0}
              type={type}
            />
          );
        },
      )}
    </>
  );
}
