import { ResourceType } from "api/resources/types/resourceType";

export const auth: ResourceType = {
  signup: {
    endpoint: "/signup",
    method: "POST",
  },
  createUser: {
    endpoint: "/create-user",
    method: "POST",
    impersonate: true,
  },
  getCustomerToken: {
    endpoint: "/customers/{customerId}/token",
    method: "GET",
    adminzone: true,
  },
  resetUserPassword: {
    endpoint: "/reset-user-password",
    method: "POST",
  },
  resetUserPasswordEmail: {
    endpoint: "/reset-user-password-email",
    method: "POST",
  },
  getPhoneNumberValidationCode: {
    endpoint: "/get-phone-number-validation-code",
    method: "POST",
  },
  validatePhoneNumber: {
    endpoint: "/validate-phone-number",
    method: "POST",
    impersonate: true,
  },
  login: {
    endpoint: "/signin",
    method: "POST",
  },
  logout: {
    endpoint: "/signout",
    method: "DELETE",
  },
};
