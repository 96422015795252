import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePersonalDatatyles = makeStyles((theme: CustomTheme) => ({
  actionButton: {
    margin: "0.5rem",
  },
  actionButtonsContainer: {
    flexWrap: "wrap",
    justifyContent: "space-between",

    margin: "0.5rem",
  },
  deleteButton: {
    margin: "4px 0",
    borderColor: theme.palette.error.main,

    color: theme.palette.error.main,
  },
  deleteDialogHeading: {
    "& .MuiTypography-root": {
      fontWeight: 500,
      textAlign: "center",
      fontSize: theme.typography.h3.fontSize,
    },
  },
  deleteSection: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: {
    margin: "0.75rem 0",
  },
  personalDataContainer: {
    display: "flex",
    flexDirection: "column",
  },
  sectionHeading: {
    margin: "8px 0",

    fontWeight: 500,
  },
}));
