import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import AppBar from "components/AppBar/AppBar.jsx";
import {
  closeImpersonateSession,
  openImpersonateDialog,
} from "containers/ImpersonateForm/actions.js";
import { logout } from "store/actions.js";
import { getImpersonateSession } from "containers/ImpersonateForm/selector.js";
import { isAdmin } from "lib/user/userRoles.js";
import Notification from "components/Notification/Notification";

import { setPassengerProfilesLoadingStatus } from "containers/PassengersProfiles/actions";
import { resetPassengersCount } from "containers/SearchForm/actions";

/**
 * App bar container
 */
class AppBarContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMenu: false, menuTargetElement: null };
  }

  openMenu = (e) => {
    e.preventDefault();
    this.setState({
      showMenu: true,
      menuTargetElement: e.currentTarget,
    });
  };

  closeMenu = () => {
    this.setState({ showMenu: false });
  };

  canImpersonate = () => isAdmin(this.props.groups) || this.props.isReferee;

  /**
   * We're not using 'Link' in the appBar menu to be able
   * to click on the whole pane so we manually redirect
   *
   * @param  string to [description]
   * @return
   */
  redirect = (navigate, to) => {
    if (!to) return;

    this.closeMenu();

    navigate(to);
  };

  render() {
    return (
      <>
        <AppBar
          user={this.props.user}
          isAdmin={isAdmin(this.props.groups)}
          favorite={this.props.favorite}
          showMenu={this.state.showMenu}
          openMenu={this.openMenu}
          closeMenu={this.closeMenu}
          intl={this.props.intl}
          menuTargetElement={this.state.menuTargetElement}
          impersonateSession={this.props.impersonateSession}
          impersonateUser={this.props.impersonateUser}
          closeImpersonateSession={this.props.closeImpersonateSession}
          logout={this.props.logout}
          redirect={this.redirect}
          canImpersonate={this.canImpersonate}
          openImpersonateDialog={this.props.openImpersonateDialog}
          impersonateDialog={this.props.impersonateDialog}
          isRequestingImpersonate={this.props.isRequestingImpersonate}
        />

        {/* popup for optional info / alert / promo  */}
        <Notification />
      </>
    );
  }
}

const _mapState = (state) => {
  const impersonatableUsers = _.get(
    state,
    "user.user.custom_fields.can_be_impersonated",
  );
  return {
    user: state.user.user,
    impersonateSession: getImpersonateSession(state.impersonateForm),
    // when impersonating, the chip should display the user that is stored in state
    impersonateUser: {
      first_name: _.get(state, "user.user.first_name", ""),
      last_name: _.get(state, "user.user.last_name", ""),
    },
    favorite: state.user.favorite,
    groups: state.user.groups,
    impersonateDialog: state.impersonateForm.openDialog,
    isReferee: impersonatableUsers && impersonatableUsers.length > 0,
    isRequestingImpersonate: _.get(
      state,
      "impersonateForm.isRequestingImpersonate",
    ),
  };
};

const _mapDispatch = (dispatch) => ({
  closeImpersonateSession: (navigate, loadUserProfile) => {
    dispatch(setPassengerProfilesLoadingStatus());
    dispatch(resetPassengersCount());
    dispatch(closeImpersonateSession(navigate, loadUserProfile));
  },
  openImpersonateDialog: () => {
    dispatch(openImpersonateDialog());
  },
  logout: (navigate, setIsAuthenticated) => {
    dispatch(logout(navigate, setIsAuthenticated));
  },
});

export default connect(_mapState, _mapDispatch)(injectIntl(AppBarContainer));
