import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, IconButton, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import Visibility from "components/Icons/Visibility";
import VisibilityOff from "components/Icons/VisibilityOff";
import { UserPasswordDataForUpdate } from "contexts/UserContext/types";

import { usePasswordStyles } from "./passwordStyles";
import { AccountInfosFormErrors } from "types/accountInfos";

type PasswordProps = {
  formErrors?: AccountInfosFormErrors;
  onFieldChange: (field: string, value: string) => void;
  ongoingImpersonation: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  passwordData: UserPasswordDataForUpdate;
};

export function Password(props: PasswordProps) {
  const {
    formErrors,
    onFieldChange,
    ongoingImpersonation,
    onSubmit,
    passwordData,
  } = props;

  const [showPasswords, setShowPasswords] = useState(false);

  const intl = useIntl();
  const classes = usePasswordStyles();

  const newPasswordLabel = <FormattedMessage id="profile.edit_password" />;
  const repeatPasswordLabel = (
    <FormattedMessage id="profile.repeat_new_password" />
  );
  const newPasswordLabel2 = !ongoingImpersonation
    ? repeatPasswordLabel
    : newPasswordLabel;

  const isFieldEmpty = (field: string) => passwordData[field] === "";

  const isSubmitButtonDisabled = ongoingImpersonation
    ? isFieldEmpty("newPassword") || isFieldEmpty("newPasswordConfirm")
    : isFieldEmpty("currentPassword") ||
      isFieldEmpty("newPassword") ||
      isFieldEmpty("newPasswordConfirm");

  return (
    <form className={classes.passwordContainer} onSubmit={onSubmit}>
      <Typography variant="caption" component="p" align="center">
        {intl.formatMessage({ id: "help.fieldsRequired" })}
      </Typography>

      {ongoingImpersonation || (
        <TextField
          className={classes.currentPasswordContainer}
          id="profile-old-password"
          label={<FormattedMessage id="profile.old_password" />}
          type={showPasswords ? "text" : "password"}
          value={passwordData.currentPassword}
          helperText={formErrors?.current_password}
          autoComplete="current-password"
          required
          onChange={(e) => onFieldChange("currentPassword", e.target.value)}
          error={Boolean(formErrors?.current_password?.length)}
          InputProps={{
            endAdornment: (
              <IconButton
                id="visibility-password-1"
                type="button"
                aria-label={intl.formatMessage({
                  id: "aria.show_all_passwords",
                })}
                aria-pressed={showPasswords}
                onClick={() => setShowPasswords(!showPasswords)}
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
      )}

      <div className={classes.newPasswordContainer}>
        <Alert className={classes.infoBox} severity="info" aria-hidden={true}>
          <FormattedMessage id={"password.minChar"} />
        </Alert>

        <TextField
          id="profile-new-password-1"
          label={newPasswordLabel}
          type={showPasswords ? "text" : "password"}
          value={passwordData.newPassword}
          helperText={formErrors?.new_password}
          autoComplete="new-password"
          aria-label={intl.formatMessage({ id: "password.minChar" })}
          required
          onChange={(e) => onFieldChange("newPassword", e.target.value)}
          error={Boolean(formErrors?.new_password?.length)}
          InputProps={{
            endAdornment: (
              <IconButton
                id="visibility-password-2"
                type="button"
                aria-label={intl.formatMessage({
                  id: "aria.show_all_passwords",
                })}
                aria-pressed={showPasswords}
                onClick={() => setShowPasswords(!showPasswords)}
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />

        <TextField
          id="profile-new-password-2"
          label={newPasswordLabel2}
          type={showPasswords ? "text" : "password"}
          value={passwordData.newPasswordConfirm}
          autoComplete="new-password"
          helperText={formErrors?.new_password_confirmation}
          aria-label={intl.formatMessage({ id: "password.minChar" })}
          required
          onChange={(e) => onFieldChange("newPasswordConfirm", e.target.value)}
          error={Boolean(formErrors?.new_password_confirmation?.length)}
          InputProps={{
            endAdornment: (
              <IconButton
                id="visibility-password-3"
                type="button"
                aria-label={intl.formatMessage({
                  id: "aria.show_all_passwords",
                })}
                aria-pressed={showPasswords}
                onClick={() => setShowPasswords(!showPasswords)}
              >
                {showPasswords ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            ),
          }}
        />
      </div>

      <div className={classes.saveButtonContainer}>
        <Button
          color="primary"
          disabled={isSubmitButtonDisabled}
          type="submit"
          variant="contained"
          aria-label={intl.formatMessage({
            id: "aria.save_password",
          })}
        >
          <FormattedMessage id="misc.save" />
        </Button>
      </div>
    </form>
  );
}
