import React, { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import SignUpForm from "containers/SignUpForm/SignUpForm.jsx";

import { ProductContext } from "contexts/ProductContext";

export default function SignUpView() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { productParameters } = useContext(ProductContext);

  const canRegister = Boolean(
    productParameters?.features?.booking_website?.customer_can_register,
  );

  useEffect(() => {
    if (!canRegister) {
      navigate("/login");
    }
  }, [canRegister, navigate]);

  return (
    <div
      className="column container"
      style={{ height: "100%", justifyContent: "center", alignItems: "center" }}
    >
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "signup.title" })}
      </h1>

      <SignUpForm />
    </div>
  );
}
