import * as React from "react";
import { useState } from "react";
import { Button, Tooltip, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

export function GenerateDeeplinkBtn(props) {
  const { territoryKey, departure, destination, totalPassenger } = props;

  const [openTooltipGenerateDeeplink, setOpenTooltipGenerateDeeplink] =
    useState(false);
  const intl = useIntl();

  let debug;
  try {
    debug = ENABLE_DEEPLINK_DEBUG_BTN;
  } catch (e) {
    debug = false;
  }

  if (!debug || !territoryKey) {
    return null;
  }

  const handleTooltipGenerateDeeplinkClose = () => {
    setOpenTooltipGenerateDeeplink(false);
  };

  const handleTooltipGenerateDeeplinkOpen = () => {
    setOpenTooltipGenerateDeeplink(true);
  };

  /**
   * Generate Deeplink (Only available on dev)
   */
  const generateDeeplinkOnClipboard = () => {
    const DEPARTURE_ADDRESS = "departure-address";
    const DESTINATION_ADDRESS = "destination-address";
    const REQUESTED_SEAT = "requested-seats";

    let uri = `${window.location.origin}${window.location.pathname}?`;
    let keyNumber = 0;

    const deeplinkData = {
      territory: territoryKey,
      [DEPARTURE_ADDRESS]: departure,
      [DESTINATION_ADDRESS]: destination,
    };

    const requestedSeats = totalPassenger;
    if (requestedSeats > 1) {
      deeplinkData[REQUESTED_SEAT] = requestedSeats;
    }

    for (const [key, value] of Object.entries(deeplinkData)) {
      if (keyNumber > 0) {
        uri += "&";
      }
      uri += `${key}=${encodeURIComponent(value)}`;
      keyNumber++;
    }

    navigator.clipboard.writeText(uri);
    handleTooltipGenerateDeeplinkOpen();
  };

  return (
    <Tooltip
      PopperProps={{
        disablePortal: true,
      }}
      onClose={handleTooltipGenerateDeeplinkClose}
      open={openTooltipGenerateDeeplink}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title="Copied on clipboard !"
      arrow={true}
    >
      <Button
        variant="contained"
        color="primary"
        onClick={generateDeeplinkOnClipboard}
        style={{ minWidth: "15rem", marginLeft: "1rem" }}
        data-testid="deeplink-btn"
      >
        <Typography variant="body1">
          {intl.formatMessage({
            id: "search.generate_deeplink",
            defaultMessage: "Generate deeplink",
          })}
        </Typography>
      </Button>
    </Tooltip>
  );
}
