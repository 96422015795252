import {
  INITIATE_PASSENGERS_PROFILES_VALIDATION_REQUEST,
  RECEIVE_PASSENGERS_PROFILES,
  PASSENGERS_PROFILES_ERROR,
  RECEIVE_BOOKED_PROFILE_IDS,
  UPDATE_PASSENGERS_PROFILES,
  PASSENGER_PROFILES_LOADING,
} from "./actions";

const initialState = {
  isLoading: true,
  profiles: {},
  bookedProfilesIds: {},
};

export const passengersProfiles = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_PASSENGERS_PROFILES_VALIDATION_REQUEST:
      return {
        ...initialState,
      };
    case PASSENGER_PROFILES_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PASSENGERS_PROFILES_ERROR:
      return {
        ...state,
      };
    case RECEIVE_PASSENGERS_PROFILES:
      return {
        ...state,
        profiles: action.passengersProfiles,
        isLoading: false,
      };
    case UPDATE_PASSENGERS_PROFILES:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.profileType]: action.profile,
        },
      };
    case RECEIVE_BOOKED_PROFILE_IDS:
      return {
        ...state,
        bookedProfilesIds: action.bookedProfilesIds,
      };
    default:
      return state;
  }
};
