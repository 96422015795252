import dayjs from "dayjs";

import { getLang } from "../i18n";

/**
 * @param {Date} date
 * @returns {string} internationalized weekday / day / month date
 */
export function i18nVerboseDateFormat(date) {
  const lang = getLang();

  switch (lang) {
    case "de":
      return dayjs(date).format("dddd, D. MMMM");

    case "en":
      return dayjs(date).format("dddd, DD MMMM");

    case "ca":
    case "es":
    case "fr":
    case "fr-ca":
    case "it":
    case "da":
    default:
      return dayjs(date).format("dddd DD MMMM");
  }
}

/**
 * @param {string} date
 * @returns {string} internationalized weekday / day / month / year date
 */
export function i18nVerboseDateFormatWithYear(date) {
  const lang = getLang();

  switch (lang) {
    case "de":
      return dayjs(date).format("dddd, D. MMMM YYYY");

    case "en":
      return dayjs(date).format("dddd, LL");

    case "ca":
    case "es":
    case "fr":
    case "fr-ca":
    case "it":
    case "da":
    default:
      return dayjs(date).format("dddd LL");
  }
}
