import React, { FC, ReactElement } from "react";
import { ClickAwayListener, Tooltip } from "@material-ui/core";

type TooltipWrapperProps = {
  children: ReactElement<any, any>;
  tooltip?: string | ReactElement<any, any>;
  showTooltip?: boolean;
};

export const TooltipWrapper: FC<TooltipWrapperProps> = ({
  children,
  tooltip,
  showTooltip,
}) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };
  const handleTooltipOpen = () => {
    setOpen(true);
  };

  if (!!tooltip && showTooltip) {
    return (
      <>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            arrow
            title={tooltip}
          >
            <div onClick={handleTooltipOpen}>{children}</div>
          </Tooltip>
        </ClickAwayListener>
      </>
    );
  }
  return <>{children}</>;
};
