// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.termsDialog-cssmodule-terms-GfDDB{display:flex;flex-direction:column;justify-content:space-around;gap:1em}.termsDialog-cssmodule-terms__links-a2aVz{display:flex;justify-content:space-between;gap:2em}.termsDialog-cssmodule-terms__link-fCuyI{color:#0869af;text-decoration:underline}.termsDialog-cssmodule-terms__link-fCuyI:hover{text-decoration:none}
`, "",{"version":3,"sources":["webpack://./src/components/Terms/termsDialog.cssmodule.scss"],"names":[],"mappings":"AAAA,mCACE,YAAa,CACb,qBAAsB,CACtB,4BAA6B,CAC7B,OAAQ,CACR,0CACE,YAAa,CACb,6BAA8B,CAC9B,OAAQ,CACT,yCAEC,aAAc,CACd,yBAA0B,CAC3B,+CAEC,oBAAqB","sourcesContent":[".terms {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  gap: 1em;\n  &__links {\n    display: flex;\n    justify-content: space-between;\n    gap: 2em;\n  }\n  &__link {\n    color: #0869af;\n    text-decoration: underline;\n  }\n  &__link:hover {\n    text-decoration: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"terms": `termsDialog-cssmodule-terms-GfDDB`,
	"terms__links": `termsDialog-cssmodule-terms__links-a2aVz`,
	"terms__link": `termsDialog-cssmodule-terms__link-fCuyI`
};
export default ___CSS_LOADER_EXPORT___;
