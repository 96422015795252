import Authorization from "./authorization.js";
import cookie from "lib/cookie.js";
import { settings } from "config/app";
import { UNAUTHORIZED } from "./constants.ts";
import { removeNotDefinedParameters } from "apiAxios";

/**
 * Http wrapper used to request the API.
 * uses fetch under the hood
 */
export const http = {
  /**
   * @param  object args request arguments
   * @return
   */
  request: function (args) {
    let body = null;
    let {
      method,
      url,
      apiVersion = null,
      impersonate = null,
      payload = null,
      queries = null,
      headers = {},
      authenticationRequired = true,
    } = args;

    headers.Accept = apiVersion
      ? `application/json; version=${apiVersion}`
      : "application/json";

    removeNotDefinedParameters(payload);
    url = this.parseUrl(url, payload);

    if (queries) url = this.parseQueries(url, queries);

    if (authenticationRequired) {
      headers.Authorization = Authorization.getHeaders(impersonate);
    }

    if (["POST", "PUT", "PATCH"].includes(method)) {
      headers["Content-Type"] = "application/json";
      body = JSON.stringify(payload);
    }

    return fetch(url, {
      method: method,
      credentials: "omit",
      headers: new Headers(headers),
      body: body,
    })
      .then((response) => {
        // 204 <=> "NO CONTENT", applying .json() on it would create an error
        if (response.status === 204) {
          return Promise.resolve();
        }
        if (response.status === 401 && !url.includes("/signin")) {
          // 401 means auth token has changed, we clear the cookie, unless we are authenticating
          cookie.erase(settings.cookieKeys.authCookie);
          return Promise.reject({ reason: UNAUTHORIZED });
        }
        if (response.ok) return response.json().then((json) => json);
        return Promise.reject(response);
      })
      .catch((error) => {
        if ("json" in error) {
          return error.json().then((json) =>
            Promise.reject({
              infos: json,
              status: error.status,
            }),
          );
        }

        return Promise.reject({
          infos: error,
          status: error.status,
        });
      });
  },

  /**
   * URL parsing. Matching & replacing placeholders
   *
   * @param  string url
   * @param  object data
   * @return
   */
  parseUrl: function (url, data) {
    const placeholders = url.match(/({[^}]+})/g);
    let output = url;

    if (!placeholders) return url;

    for (const match of placeholders) {
      const key = match.replace("{", "").replace("}", "").trim();

      if (data[key]) {
        output = output.replace(match, data[key]);

        delete data[key];
      } else console.log(`http.parseURL - key '${key}' is required`);
    }

    return output;
  },
  /**
   * Parse queries object and create query string
   *
   * @param  string url
   * @param  object queries
   * @return
   */
  parseQueries: function (url, queries) {
    if (Object.keys(queries).length === 0) return url;

    const queriesUrl = [];

    for (const key of Object.keys(queries))
      queriesUrl.push(`${key}=${queries[key]}`);

    return `${url}?${queriesUrl.join("&")}`;
  },
};
