import React, { useState } from "react";

import { LabeledSwitch } from "./LabeledSwitch";

type LabeledSwitchContainerProps = {
  switchId: string;
  isChecked: boolean;
  onChange: (
    switchId: string,
    value: boolean,
    setChecked: (value: boolean) => void,
  ) => void;
};

export function LabeledSwitchContainer({
  switchId,
  isChecked,
  onChange,
}: LabeledSwitchContainerProps) {
  const [checked, setChecked] = useState(isChecked);

  return (
    <LabeledSwitch
      switchId={switchId}
      checked={checked}
      onChange={(_, valueChecked) => {
        setChecked(valueChecked);
        onChange(switchId, valueChecked, setChecked);
      }}
    />
  );
}
