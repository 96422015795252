import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useIndicationsToDriverDialogStyles = makeStyles(
  (theme: CustomTheme) => ({
    actionButton: {
      width: "calc(50% - 1rem)",
      margin: "0.25rem 0 !important",

      [theme.breakpoints.up("sm")]: {
        width: "calc(50% - 2rem)",
      },
    },
    actionsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between !important",

      padding: "1rem 1rem 1.5rem 1rem !important",

      [theme.breakpoints.up("sm")]: {
        padding: "1rem 1.5rem 1.5rem 1.5rem !important",
      },
    },
    charCounter: {
      padding: "0 0.5rem 0 0",

      fontSize: "1rem",
      fontWeight: 500,
      textAlign: "right",

      opacity: 0.5,
    },
    indicationsToDriverDialogContainer: {
      overflowY: "scroll",

      "& .MuiPaper-root": {
        maxWidth: "45rem",
      },

      "& .MuiDialog-paper": {
        width: "80%",
        minWidth: "300px",
        margin: "1rem",
      },

      "& .MuiDialogContent-root": {
        padding: "0.5rem 1rem",
        overflowY: "visible",

        [theme.breakpoints.up("sm")]: {
          padding: "0.5rem 1.5rem",
        },
      },
    },
    indicationsToDriverDialogTitle: {
      "& h2": {
        textAlign: "center",
        fontSize: "1.35rem",
        fontWeight: 700,
      },

      [theme.breakpoints.up("sm")]: {
        "& h2": {
          fontSize: "1.5rem",
        },
      },
    },
    indicationsToDriverDialogTextArea: {
      "& .MuiOutlinedInput-root": {
        background: "#f4f4f4",
      },
    },
    infobox: {
      margin: "1rem 0 0 0",
    },
  }),
);
