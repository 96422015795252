import React from "react";
import { useIntl } from "react-intl";

import { SearchFormContainer } from "containers/SearchForm/SearchForm.jsx";
import TermsDialogContainer from "containers/Terms/Terms.jsx";

import { useSearchViewStyles } from "./searchViewStyles";

export default function SearchView() {
  const intl = useIntl();
  const classes = useSearchViewStyles();

  return (
    <>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "search.title" })}
      </h1>

      <div className={classes.searchViewContainer}>
        <SearchFormContainer />
      </div>

      <TermsDialogContainer />
    </>
  );
}
