import React, { useCallback } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import ResetPasswordForm from "components/ResetPasswordForm/ResetPasswordForm.jsx";

import { onFieldChange, resetPasswordRequest } from "./actions.js";

/**
 * Sign in container
 */
function ResetPasswordContainer(props) {
  const {
    error,
    isSent,
    onFieldChange,
    password,
    passwordConfirmation,
    resetPassword,
    token,
  } = props;

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      resetPassword(navigate, password, passwordConfirmation, token);
    },
    [navigate, password, passwordConfirmation, resetPassword, token],
  );

  const handleFieldChange = useCallback(
    (name, e) => {
      onFieldChange(name, e.target.value);
    },
    [onFieldChange],
  );

  return (
    <ResetPasswordForm
      password={password}
      passwordConfirmation={passwordConfirmation}
      onFieldChange={handleFieldChange}
      isSent={isSent}
      onSubmit={handleSubmit}
      error={error}
    />
  );
}

const _mapState = (state) => ({
  password: state.forgetPassword.password,
  passwordConfirmation: state.forgetPassword.passwordConfirmation,
  isSent: state.forgetPassword.isSent,
  error: state.forgetPassword.error,
});

const _mapDispatch = (dispatch) => ({
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  resetPassword: (navigate, password, passwordConfirmation, token) => {
    dispatch(
      resetPasswordRequest(navigate, password, passwordConfirmation, token),
    );
  },
});

export default connect(_mapState, _mapDispatch)(ResetPasswordContainer);
