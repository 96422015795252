import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useReservationFormStyles = makeStyles((theme: CustomTheme) => ({
  formContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
  },
  loaderContainer: {
    padding: "1.5rem",

    textAlign: "center",
  },
  noRideCard: {
    border: "1px solid grey",
  },
  positionAddress: {
    width: "calc(100% - 1rem)",
    margin: "0.5rem",

    [theme.breakpoints.up("md")]: {
      width: "calc(100% - 2rem)",
    },
  },
  selectContainer: {
    width: "100%",
    maxWidth: "14rem",
    margin: "1rem auto",
  },
  submitButton: {
    margin: "2rem 0 0 0",
  },
}));
