import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { FormattedMessage } from "react-intl";

import { Button, Grid, Paper, TextField, Typography } from "@material-ui/core";

import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";

import { useForgetPasswordStyles } from "./forgetPasswordStyles";

export default function ForgetPasswordEmailForm(props) {
  const { email, error, isSent, message, onFieldChange, onSubmit } = props;

  const { showAuthModal } = useContext(AuthModalsContext);

  const navigate = useNavigate();
  const classes = useForgetPasswordStyles();

  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        className={classes.forgetPasswordContainer}
        component={Paper}
      >
        <Typography
          className={classes.forgetPasswordTitle}
          component="h2"
          variant="h2"
        >
          <FormattedMessage id="forgetPassword.resetPassword" />
        </Typography>

        {isSent ? (
          <Typography className={classes.sentEmailMessage}>
            {message}
          </Typography>
        ) : (
          <form onSubmit={onSubmit}>
            <Typography>
              <FormattedMessage id="forgetPassword.enterEmail" />
            </Typography>

            <TextField
              className={classes.emailInput}
              id="reset-password-email-form"
              required
              autoComplete="email"
              label={<FormattedMessage id="signin.email" />}
              value={email}
              type="email"
              onChange={(e) => onFieldChange("email", e)}
              helperText={error.email}
              error={error.email}
              data-testid="forget-password-email"
            />

            {error && (
              <Typography className={classes.errorMessage}>
                {error.detail.message}
              </Typography>
            )}

            <div className={classes.actionButtons}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  if (SIGNUP_AFTER_SEARCH) {
                    showAuthModal(AuthModal.SignIn);
                  } else {
                    navigate("/login");
                  }
                }}
                role="link"
              >
                <FormattedMessage id="signin.connexion" />
              </Button>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSent || !email}
                data-testid="forget-password-submit-btn"
              >
                <FormattedMessage id="misc.send" defaulMessage="Send" />
              </Button>
            </div>
          </form>
        )}
      </Grid>
    </Grid>
  );
}
