import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { useIntl } from "react-intl";

import { Button, Paper, TextField, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Captcha, useCernCaptchApiClient } from "./useCernCaptchaApiClient";

import { useCernCaptchaStyles } from "./cernCaptchaStyles";

interface CaptchaAnswer {
  id: string;
  answer: string;
}

interface CernCaptchaProps {
  error: string[] | undefined;
  onCaptchaAnswerChange: (captchaAnswer: CaptchaAnswer) => void;
}

const CAPTCHA_LIFETIME_SECONDS = 60;

export function CernCaptcha(props: CernCaptchaProps) {
  const { error, onCaptchaAnswerChange } = props;
  const { getCaptcha, getCaptchaAudioUrl } = useCernCaptchApiClient();
  const intl = useIntl();
  const [captcha, setCaptcha] = useState<Captcha | undefined>(undefined);
  const [showCaptchaAudio, setShowCaptchaAudio] = useState<boolean>(false);
  const [timerSeconds, setTimerSeconds] = useState(CAPTCHA_LIFETIME_SECONDS);
  const classes = useCernCaptchaStyles();

  const loadCaptcha = useCallback(() => {
    getCaptcha().then((captcha) => {
      setCaptcha(captcha);
      setTimerSeconds(CAPTCHA_LIFETIME_SECONDS);
    });
  }, [getCaptcha]);

  const handleReloadCaptcha = useCallback(() => loadCaptcha(), [loadCaptcha]);

  const handleToggleAudioCaptcha = useCallback(
    () => setShowCaptchaAudio((oldShowCaptchaAudio) => !oldShowCaptchaAudio),
    [],
  );

  const handleCaptchaAnswerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      captcha &&
      onCaptchaAnswerChange({ id: captcha.id, answer: e.target.value }),
    [captcha, onCaptchaAnswerChange],
  );

  const captchaAudioUrl = useMemo(
    () => captcha && showCaptchaAudio && getCaptchaAudioUrl(captcha.id),
    [captcha, getCaptchaAudioUrl, showCaptchaAudio],
  );

  useEffect(() => {
    // First captcha loading
    loadCaptcha();
  }, []);

  useEffect(() => {
    // Set up capcha timer
    const timer = setInterval(() => {
      // Decrement the timer by 1 second
      setTimerSeconds((prevTimerSeconds) => prevTimerSeconds - 1);
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (timerSeconds <= 0) {
      // Timer runs out: load new captcha and reset timer
      loadCaptcha();
    }
  }, [timerSeconds]);

  return (
    <Paper className={classes.cernCaptchaContainer}>
      <Typography className={classes.cernCaptchaTitle} component="h2">
        <FormattedMessage
          id="signup.securityCheck"
          defaultMessage="Security Check"
        />
      </Typography>

      <div className={classes.captchaContainer}>
        {captcha && (
          <img
            className={classes.captchaImage}
            alt={intl.formatMessage({
              id: "signup.securityCode",
              defaultMessage: "Security code",
            })}
            src={captcha.img}
          />
        )}

        {captchaAudioUrl && (
          <audio controls key={captchaAudioUrl}>
            <source src={captchaAudioUrl} />
          </audio>
        )}

        <div className={classes.captchaControls}>
          <Button
            className={classes.captchaControlButton}
            variant="outlined"
            size="small"
            onClick={handleReloadCaptcha}
          >
            <FormattedMessage
              id="signup.refreshCaptcha"
              defaultMessage="Refresh"
            />
          </Button>

          <Button
            className={classes.captchaControlButton}
            variant="outlined"
            size="small"
            onClick={handleToggleAudioCaptcha}
          >
            {captchaAudioUrl ? (
              <FormattedMessage
                id="signup.hideAudio"
                defaultMessage="Hide Audio"
              />
            ) : (
              <FormattedMessage
                id="signup.showAudio"
                defaultMessage="Audio version"
              />
            )}
          </Button>
        </div>
      </div>

      <TextField
        id="captcha-input"
        className={classes.captchaField}
        required
        label={
          <FormattedMessage
            id="signup.captcha"
            defaultMessage="Enter the above code"
          />
        }
        error={error && error.length > 0}
        helperText={error && error[0]}
        onChange={handleCaptchaAnswerChange}
      />

      <Alert className={classes.infoBox} severity="info">
        <FormattedMessage
          id="signup.captchaExplanation"
          defaultMessage="This code is valid for 60 seconds only. You can refresh the code to reset the timer."
        />
      </Alert>
    </Paper>
  );
}
