import { makeStyles } from "@material-ui/core";

export const useFavouritesStyles = makeStyles(() => ({
  autocompleteLabel: {
    color: "#5c5c5c",
  },
  favouritesContainer: {
    width: "100%",
  },
  fieldsContainer: {
    display: "grid",
    gridGap: "24px 32px",

    margin: "0.5rem 0 2rem 0",
  },
  saveButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
