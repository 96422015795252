import { SvgIcon } from "@material-ui/core";
import React from "react";

export function SuccessIcon(props) {
  return (
    <SvgIcon viewBox="0 0 48 48" {...props}>
      <path
        d="M23 1.917c11.644 0 21.083 9.439 21.083 21.083 0 11.644-9.439 21.083-21.083 21.083-11.644 0-21.083-9.439-21.083-21.083C1.917 11.356 11.356 1.917 23 1.917zm0 1.916C12.415 3.833 3.833 12.415 3.833 23S12.415 42.167 23 42.167 42.167 33.585 42.167 23 33.585 3.833 23 3.833zm12.6 10.784c.69.691.744 1.779.159 2.53l-.16.18-14.063 14.056c-.691.69-1.778.744-2.53.16l-.18-.16-7.02-7.015a1.917 1.917 0 0 1 2.53-2.871l.18.16 5.665 5.66 12.709-12.7a1.917 1.917 0 0 1 2.71 0z"
        fill="#1EA86A"
      />
    </SvgIcon>
  );
}
