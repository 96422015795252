import * as React from "react";
import { useContext } from "react";

import { Modal } from "components/Modal/Modal";

import SignInForm from "containers/SignInForm/SignInForm";
import SignUpForm from "containers/SignUpForm/SignUpForm";
import SignUpVerificationForm from "organisms/SignUpVerificationForm/SignUpVerificationForm";
import ForgetPasswordEmailForm from "containers/ForgetPassword/ForgetPasswordEmailForm";

import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";

export function AuthModals() {
  const { authModal, showAuthModal, phoneCode } = useContext(AuthModalsContext);

  return (
    <Modal
      isShowing={authModal !== null}
      hide={() => showAuthModal(null)}
      closable={authModal !== AuthModal.SignUpVerification} // only SignUpVerification modal cannot be closed
    >
      {authModal === AuthModal.SignIn && <SignInForm />}
      {authModal === AuthModal.SignUp && <SignUpForm />}
      {authModal === AuthModal.SignUpVerification && (
        <SignUpVerificationForm phoneCode={phoneCode} />
      )}
      {authModal === AuthModal.ForgotPassword && <ForgetPasswordEmailForm />}
    </Modal>
  );
}
