// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list-cssmodule-tabTitle-yoDtO{color:#000 !important;font-weight:600 !important}@media (max-width: 767px){.list-cssmodule-tabTitle-yoDtO{font-size:0.8rem !important}}.list-cssmodule-loader-SW6Jd{text-align:center;padding-top:20px;padding-bottom:20px}
`, "",{"version":3,"sources":["webpack://./src/components/Reservation/list.cssmodule.scss"],"names":[],"mappings":"AAAA,+BACE,qBAAsB,CACtB,0BAA2B,CAE3B,0BAJF,+BAKI,2BAA4B,CAE/B,CAED,6BACE,iBAAkB,CAClB,gBAAiB,CACjB,mBAAoB","sourcesContent":[".tabTitle {\n  color: #000 !important;\n  font-weight: 600 !important;\n\n  @media (max-width: 767px) {\n    font-size: 0.8rem !important;\n  }\n}\n\n.loader {\n  text-align: center;\n  padding-top: 20px;\n  padding-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabTitle": `list-cssmodule-tabTitle-yoDtO`,
	"loader": `list-cssmodule-loader-SW6Jd`
};
export default ___CSS_LOADER_EXPORT___;
