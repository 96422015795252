import * as React from "react";
import { useEffect, useCallback, useState } from "react";

import { MenuItem, TextField } from "@material-ui/core";

import { useDropDownStyles } from "./DropDownStyles";

export type DropDownIdentifier = {
  display: string;
  id: string;
};

type DropDownProps = {
  values: DropDownIdentifier[];
  defaultId?: string;
  onChange?: (id: string) => void;
  disabled?: boolean;
  label: string | JSX.Element;
};

export function DropDown(props: DropDownProps) {
  const { defaultId, disabled, label, onChange, values } = props;

  const [selectedId, setSelectedId] = useState(defaultId || "");

  const classes = useDropDownStyles();

  useEffect(() => {
    if (!values.some((item) => item.id === selectedId)) {
      setSelectedId("");
    }
  }, [values]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (onChange) {
        onChange(event.target.value);
      }

      setSelectedId(event.target.value);
    },
    [onChange],
  );

  return (
    <div className={classes.selectContent}>
      <TextField
        className={classes.selectInput}
        fullWidth
        autoComplete="off"
        select
        label={label}
        value={selectedId}
        onChange={handleChange}
        disabled={disabled}
      >
        {values.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.display}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
