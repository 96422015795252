import { SNACK_MESSAGE, CLOSE_SNACKBAR } from "./actions";

const initialState = {
  open: false,
  message: "",
  severity: null,
  debugMessage: "",
};

export const snackBar = (state = initialState, action) => {
  switch (action.type) {
    case SNACK_MESSAGE:
      return {
        ...state,
        open: true,
        message: action.message,
        severity: action.severity,
        debugMessage: action.debugMessage,
      };
    case CLOSE_SNACKBAR:
      // need to keep the rest for the "close" transition rendering
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
