import { makeStyles } from "@material-ui/core";

export const useSearchSidebarStyles = makeStyles(() => ({
  listItem: {
    display: "flex",
    alignItems: "flex-start",

    margin: "0.75rem 0.5rem",
    padding: 0,
  },
  listItemIcon: {
    width: "2.25rem",

    "& .material-icons": {
      color: "rgba(0, 0, 0, 0.5)",
    },
  },
  listItemLabel: {
    margin: "0 0.5rem 0 0",

    color: "#5C5C5C",
    fontWeight: 500,
  },
  searchSidebarList: {
    padding: 0,
  },
}));
