import { makeStyles } from "@material-ui/core";

export const useSignUpVerificationStyles = makeStyles((theme) => ({
  actionButton: {
    width: "calc(50% - 0.5rem)",

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 2rem)",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    margin: "2rem 0 0 0",
  },
  errorMessage: {
    margin: "1.5rem 0 0 0",
    color: theme.palette.error.main,
    textAlign: "center",
  },
  formContainer: {
    zIndex: 1000,
    maxWidth: "40rem",
    padding: "16px",

    [theme.breakpoints.up("sm")]: {
      padding: "32px",
    },
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    height: "5rem",
    margin: "0 0 1rem 0",
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  resendCodeButton: {
    width: "fit-content",
    margin: "1rem 0 1rem auto",
  },
  resendCodeError: {
    width: "fit-content",
    margin: "0 0 1rem auto",
  },
  signupVerificationForm: {
    display: "flex",
    flexDirection: "column",
  },
}));
