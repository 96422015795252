import React, { useContext } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import ImpersonateForm from "components/ImpersonateForm/ImpersonateForm.jsx";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

import {
  createImpersonateAndModifyUser,
  onChange,
  onUserSelected,
  startImpersonateSession,
} from "./actions.js";
import { resetPromoCode, resetTicketing } from "containers/Payment/actions";

function ImpersonateFormContainer(props) {
  const { selectedTerritory } = useContext(TerritoryContext);
  const { loadUserProfile } = useContext(UserContext);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const onSubmit = (e) => {
    e.preventDefault();

    props.startImpersonateSession(
      navigate,
      loadUserProfile,
      selectedTerritory.territory_key,
      props.selectedUser,
      null,
      () => {
        dispatch(resetPromoCode());
        dispatch(resetTicketing());
      },
    );
  };

  const onUserSelected = (user) => {
    props.onUserSelected(user);
    props.onChange(`${user.first_name} ${user.last_name}`);
  };

  const { onChange, value, onCreateNewUser, isRequesting, selectedUser } =
    props;

  return (
    <ImpersonateForm
      onSubmit={onSubmit}
      onUserSelected={onUserSelected}
      onChange={(e) => onChange(e.target.value)}
      onCreateNewUser={() => onCreateNewUser(navigate, loadUserProfile)}
      value={value}
      selectedUser={selectedUser}
      isRequesting={isRequesting}
    />
  );
}

const _mapState = (state) => ({
  selectedUser: state.impersonateForm.selectedUser,
  value: state.impersonateForm.value,
  isRequesting: state.impersonateForm.isRequestingImpersonate,
});

const _mapDispatch = (dispatch) => ({
  onChange: (value) => {
    dispatch(onChange(value));
  },
  onUserSelected: (user) => {
    dispatch(onUserSelected(user));
  },
  startImpersonateSession: (
    navigate,
    loadUserProfile,
    selectedTerritoryKey,
    user,
    isReferent,
    clearDiscounts,
  ) => {
    dispatch(
      startImpersonateSession(
        navigate,
        loadUserProfile,
        selectedTerritoryKey,
        user,
        isReferent,
        clearDiscounts,
      ),
    );
  },
  onCreateNewUser: (navigate, loadUserProfile) => {
    dispatch(createImpersonateAndModifyUser(navigate, loadUserProfile));
  },
});

export default connect(_mapState, _mapDispatch)(ImpersonateFormContainer);
