import {
  GenericSeat,
  GenericSeats,
  PassengerProfileEnum,
  PassengerProfilesType,
} from "types/passengerProfile";

type ExtraToGenericSeat = {
  alias: string;
  display: string;
  enabled: boolean;
  max_access_seats_in_bus: number;
  name: string;
  requires_standard_seat: boolean;
};

type ExtraToGenericSeats = {
  [GenericSeat.ACCESS]: ExtraToGenericSeat;
};

export function extrasToGenericSeats(extras: ExtraToGenericSeats) {
  const genericSeats: GenericSeats[] = [GenericSeat.ACCESS];

  const genericSeatsKey = Object.keys(extras).filter((key) => {
    const genericSeatKey = key as GenericSeats;
    if (!genericSeats.includes(genericSeatKey)) {
      return false;
    }

    if (!extras[genericSeatKey].enabled) {
      return false;
    }

    return extras[genericSeatKey].max_access_seats_in_bus > 0;
  });

  return genericSeatsKey as GenericSeats[];
}

export const getMainProfileKey = (profiles: PassengerProfilesType) => {
  if (profiles[PassengerProfileEnum.PASSENGER]) {
    return PassengerProfileEnum.PASSENGER;
  }
  if (profiles[PassengerProfileEnum.ADULT]) {
    return PassengerProfileEnum.ADULT;
  }
  return null;
};
