import React from "react";

import { Typography } from "@material-ui/core";

export default function FormSectionHeader({ title }) {
  return (
    <div className="fullWidth" style={{ padding: "0.4rem" }}>
      <Typography variant="caption" component="h2" style={{ color: "#5C5C5C" }}>
        {title}
      </Typography>
    </div>
  );
}
