import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { useIndicationsToDriverDialogStyles } from "./indicationsToDriverDialogStyles";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import api from "api";

import { UserContext } from "contexts/UserContext";

const MAX_INDICATIONS_TO_DRIVER_LENGTH = 100;

export function IndicationsToDriverDialog(props) {
  const {
    bookingId,
    indicationsToDriver,
    isSearchRequest,
    onIndicationsToDriverChange,
    setIndicationsToDriver,
    setIndicationsToDriverDialogOpen,
  } = props;

  const [userInput, setUserInput] = useState(indicationsToDriver);

  const { userProfile } = useContext(UserContext);

  const intl = useIntl();
  const classes = useIndicationsToDriverDialogStyles();

  const customerId = userProfile?.customerId;

  const updateIndicationsToDriver = (indicationsToDriver) => {
    if (isSearchRequest) {
      onIndicationsToDriverChange(indicationsToDriver);
    } else {
      api.patchIndicationsToDriver({
        comment: indicationsToDriver,
        bookingId,
        customerId,
      });
    }
  };

  return (
    <Dialog className={classes.indicationsToDriverDialogContainer} open>
      <DialogTitle
        className={classes.indicationsToDriverDialogTitle}
        disableTypography
      >
        <Typography variant="h2">
          <FormattedMessage id="comment.dialog_title" />
        </Typography>
      </DialogTitle>

      <DialogContent>
        <TextField
          className={classes.indicationsToDriverDialogTextArea}
          variant="outlined"
          fullWidth
          multiline
          minRows={2}
          maxRows={3}
          autoComplete="off"
          autoFocus
          placeholder={intl.formatMessage({ id: "comment.add_indication" })}
          value={userInput}
          inputProps={{ maxLength: MAX_INDICATIONS_TO_DRIVER_LENGTH }}
          onChange={(e) => setUserInput(e.target.value)}
        />

        <Typography className={classes.charCounter}>
          {userInput.length} / {MAX_INDICATIONS_TO_DRIVER_LENGTH}
        </Typography>

        <Alert className={classes.infobox} severity="info">
          <FormattedMessage id="comment.dialog_infobox" />
        </Alert>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={() => setIndicationsToDriverDialogOpen(false)}
        >
          <FormattedMessage id="misc.cancel" />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => {
            setIndicationsToDriver(userInput);
            updateIndicationsToDriver(userInput);
            setIndicationsToDriverDialogOpen(false);
          }}
        >
          <FormattedMessage id="misc.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
