import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const DeleteCross = (props) => (
  <SvgIcon {...props}>
    <path
      d="M11 2a9 9 0 1 1 0 18 9 9 0 0 1 0-18zM7.749 7.053a.5.5 0 0 0-.638.765L10.293 11 7.11 14.182l-.058.07a.5.5 0 0 0 .765.637L11 11.707l3.182 3.182.07.058a.5.5 0 0 0 .637-.765L11.707 11l3.182-3.182.058-.07a.5.5 0 0 0-.765-.637L11 10.293 7.818 7.11z"
      fill="#C9C6C6"
      fillRule="evenodd"
    />
  </SvgIcon>
);
