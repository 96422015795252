import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { map, get } from "lodash";

import { Button, DialogContent, Typography } from "@material-ui/core";

import DialogBox from "components/DialogBox/DialogBox";

import {
  closeImpersonateDialog,
  startImpersonateSession,
} from "containers/ImpersonateForm/actions.js";
import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

import styles from "./impersonateDialog.cssmodule.scss";

function ImpersonateDialog(props) {
  const {
    handleCloseDialog,
    me,
    startImpersonateSession,
    usersImpersonatable,
  } = props;

  const { selectedTerritory } = useContext(TerritoryContext);
  const { loadUserProfile } = useContext(UserContext);

  const navigate = useNavigate();

  const [userSelected, selectId] = useState(null);

  return (
    <DialogBox
      classes={{ paper: styles.dialog__container }}
      isClosable
      onClose={handleCloseDialog}
      title={
        <FormattedMessage
          id={"menu.impersonate"}
          defaultMessage={"Impersonate"}
        />
      }
    >
      <div className="column aligned">
        <div className={styles.currentTerritory__container}>
          <Typography>
            <FormattedMessage
              id="impersonate.me"
              defaultMessage="Your main identity"
            />
          </Typography>

          <div className={styles.currentTerritory__line} />

          <div className={styles.currentTerritory__wrapper}>
            <Typography variant="caption">
              {`${me.first_name} ${me.last_name}`}
            </Typography>
          </div>
        </div>
      </div>

      <Typography variant="h3" style={{ marginTop: "1rem" }}>
        <FormattedMessage
          id="impersonate.users_available"
          defaultMessage="Available users"
        />
      </Typography>

      <DialogContent
        dividers
        className={"padded " + styles.availableTerritories__wrapper}
      >
        {map(usersImpersonatable, (user, index) => (
          <Button
            tabIndex={userSelected && user.id === userSelected.id ? -1 : 0}
            variant={
              userSelected && user.id === userSelected.id
                ? "contained"
                : "outlined"
            }
            onClick={() => selectId(user)}
            key={index}
          >
            {`${user.first_name} ${user.last_name}`}
          </Button>
        ))}
      </DialogContent>

      <DialogContent className="column aligned">
        <Button
          variant="contained"
          aria-label="confirm impersonate user"
          onClick={() => {
            startImpersonateSession(
              navigate,
              loadUserProfile,
              selectedTerritory.territory_key,
              userSelected,
              true,
            );
          }}
          color="primary"
          disabled={!userSelected}
        >
          {<FormattedMessage id="misc.confirm" defaultMessage="Confirm" />}
        </Button>
      </DialogContent>
    </DialogBox>
  );
}

const mapStateToProps = (state) => ({
  usersImpersonatable: get(
    state,
    "user.user.custom_fields.can_be_impersonated",
    [],
  ),
  me: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  handleCloseDialog: () => dispatch(closeImpersonateDialog()),
  startImpersonateSession: (
    navigate,
    loadUserProfile,
    selectedTerritoryKey,
    user,
    isReferent,
  ) => {
    dispatch(closeImpersonateDialog());
    dispatch(
      startImpersonateSession(
        navigate,
        loadUserProfile,
        selectedTerritoryKey,
        user,
        isReferent,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonateDialog);
