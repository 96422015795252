import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ActionLogOut(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M11.399 1.25v1.5H5.9c-1.68 0-3.052 1.315-3.145 2.971L2.75 5.9v10.2c0 1.74 1.41 3.15 3.15 3.15h5.499v1.5H5.9c-2.497 0-4.534-1.968-4.645-4.437L1.25 16.1V5.9c0-2.568 2.082-4.65 4.65-4.65h5.499zm2.879 4.443l.093.085 4.363 4.551c.325.34.352.873.081 1.244l-.081.098-4.363 4.551c-.355.37-.93.37-1.286 0-.325-.34-.352-.872-.08-1.244l.08-.097 2.811-2.933H5.909l-.114-.007C5.347 11.882 5 11.483 5 11c0-.486.35-.887.803-.942l.106-.006 9.987-.001-2.81-2.932c-.326-.34-.353-.872-.082-1.244l.081-.097c.326-.34.837-.368 1.193-.085z"
        transform="translate(-926 -266) translate(926 266)"
      />
    </SvgIcon>
  );
}

export default ActionLogOut;
