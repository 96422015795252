import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { Link, useNavigate } from "react-router-dom";

import { Button, Typography } from "@material-ui/core";

import PaperBox from "components/PaperBox/PaperBox";

export default function SiteMapView() {
  const intl = useIntl();

  const navigate = useNavigate();

  return (
    <div
      className="column centered aligned fullHeight"
      style={{ minHeight: "inherit", backgroundColor: "lightGrey" }}
    >
      <PaperBox title={intl.formatMessage({ id: "sitemap.title" })}>
        <Button
          style={{ marginBottom: "2rem" }}
          role="link"
          onClick={() => navigate("/search")}
          variant="outlined"
        >
          {intl.formatMessage({ id: "misc.back_to_search" })}
        </Button>
        <Link to="/login" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="signin.title" />
          </Typography>
        </Link>
        <Link to="/forget-password-email" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="forgetPassword.title" />
          </Typography>
        </Link>
        <Link to="/register" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="signup.title" />
          </Typography>
        </Link>
        <Link to="/search" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="search.title" />
          </Typography>
        </Link>
        <Link to="/profile" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="profile.title" />
          </Typography>
        </Link>
        <Link to="/reservation" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="reservationList.title" />
          </Typography>
        </Link>
        <Link to="/impersonate" className="page-link padded">
          <Typography variant="h2">
            <FormattedMessage id="impersonate.title" />
          </Typography>
        </Link>
      </PaperBox>
    </div>
  );
}
