import { startsWith, some, lowerCase } from "lodash";

/**
 * User roles. Defines if a user is admin based
 * on his groups. (returned from the API)
 *
 * @param  array groups [current user groups membership]
 * @return boolean
 */
export const isAdmin = (groups) => {
  if (!Array.isArray(groups) || !groups.length) return false;

  return some(groups, (g_upper_or_lower) => {
    const g = lowerCase(g_upper_or_lower);
    return (
      startsWith(g, "admin") ||
      startsWith(g, "booking") ||
      startsWith(g, "operator") ||
      startsWith(g, "manager") ||
      startsWith(g, "observer")
    );
  });
};
