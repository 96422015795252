import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useDiscountCardStyles = makeStyles((theme: CustomTheme) => ({
  discountContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",

    width: "auto",
    padding: "8px 16px",
  },
  discountExternalIdentifier: {
    width: "calc(50% - 3.5rem)",
    padding: "0 0 0 1rem",

    color: theme.palette.neutral.main,
    textAlign: "left",
  },
  discountName: {
    width: "calc(50% - 3rem)",
    padding: "0 0 0 0.5rem",

    textAlign: "left",
  },
  discountTicketIcon: {
    width: "2.5rem",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export const useTooltipStyles = makeStyles(() => ({
  arrow: {
    color: "#d7eaf5",
  },
  tooltip: {
    padding: "0.75rem 1rem",
    borderRadius: "0.5rem",

    color: "black",

    backgroundColor: "#d7eaf5",
  },
}));
