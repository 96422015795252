import React, { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl-phraseapp";

import { Typography } from "@material-ui/core";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import { TimedRide } from "organisms/TimedRide/TimedRide";

import { i18nVerboseDateFormatWithYear } from "utils/i18nDate";
import { capitalize } from "utils/strings";
import { cn } from "utils/classes";
import { FORMAT_DATE } from "utils/constants";

import { useReservationListItemStyles } from "./reservationListItemStyles";
import { WaitingList } from "api/types/waitingList";
import dayjs from "dayjs";
import { UnvalidatedWaitingListStatus } from "../WaitingList/UnvalidatedWaitingListStatus";
import { Button } from "components/ui/button";
import { CancelWaitingListDialog } from "../WaitingList/CancelWaitingListDialog";
import api from "api";
import { UserContext } from "contexts/UserContext";
import { useDispatch } from "react-redux";
import { fetchWaitingLists } from "containers/ReservationList/actions";
import { snackMessage } from "containers/SnackBar/actions";

interface UnvalidatedWaitingList
  extends Omit<WaitingList, "waiting_list_status"> {
  waiting_list_status: Exclude<WaitingList["waiting_list_status"], "VALIDATED">;
}

interface WaitingListListItemProps {
  waitingList: UnvalidatedWaitingList;
}

export function WaitingListListItem({ waitingList }: WaitingListListItemProps) {
  const classes = useReservationListItemStyles();
  const intl = useIntl();

  const { userProfile } = useContext(UserContext);

  const customerId = userProfile.customerId;

  const dispatch = useDispatch();

  const requestedDate = capitalize(
    i18nVerboseDateFormatWithYear(
      dayjs(waitingList.requested_datetime).tz().format(FORMAT_DATE),
    ),
  );

  const minTime = useMemo(
    () => dayjs(waitingList.min_time).format("LT"),
    [waitingList.min_time],
  );
  const maxTime = useMemo(
    () => dayjs(waitingList.max_time).format("LT"),
    [waitingList.max_time],
  );

  const [cancelWaitingListDialogOpen, setCancelWaitingListDialogOpen] =
    useState(false);

  const handleCancelWaitingList = useCallback(() => {
    return api
      .cancelWaitingList({ customerId, searchRequestId: waitingList.id })
      .then(() => {
        setCancelWaitingListDialogOpen(false);
        dispatch(fetchWaitingLists(customerId));
        dispatch(
          snackMessage(
            "success",
            intl.formatMessage({ id: "FLOW_HELPER_WAITING_LIST_CANCELLED" }),
          ),
        );
      });
  }, [customerId, dispatch, intl, waitingList.id]);

  return (
    <article className={cn(classes.reservationContainer, "bg-zinc-100")}>
      <div className="flex items-center justify-between">
        <div className={classes.reservationHeader}>
          <Typography className={classes.reservationDate}>
            {requestedDate}
          </Typography>

          <InfosBadges standardSeats={waitingList.passengers_number} />

          <Typography
            variant="body2"
            className="font-bold text-muted-foreground"
          >
            {waitingList.time_restriction_type === "DEPARTURE" ? (
              <FormattedMessage
                id="LABEL_WAITING_LIST_DEPARTURE_BETWEEN"
                values={{
                  departureTimeMin: minTime,
                  departureTimeMax: maxTime,
                }}
              />
            ) : (
              <FormattedMessage
                id="LABEL_WAITING_LIST_ARRIVAL_BETWEEN"
                values={{ arrivalTimeMin: minTime, arrivalTimeMax: maxTime }}
              />
            )}
          </Typography>
        </div>
        <UnvalidatedWaitingListStatus
          status={waitingList.waiting_list_status}
        />
      </div>

      <div className={classes.timedRideContainer}>
        <TimedRide
          dropoffNodeName={waitingList.dropoff_address}
          pickupNodeName={waitingList.pickup_address}
        />

        <div className={classes.actionsContainer}>
          {waitingList.waiting_list_status === "PENDING" && (
            <>
              <Button
                variant="outline"
                onClick={() => setCancelWaitingListDialogOpen(true)}
              >
                <FormattedMessage id="ACTION_CANCEL_WAITING_LIST_REQUEST" />
              </Button>
              <CancelWaitingListDialog
                open={cancelWaitingListDialogOpen}
                onClose={() => setCancelWaitingListDialogOpen(false)}
                onSubmit={handleCancelWaitingList}
                requestedDate={requestedDate}
                waitingList={waitingList}
              />
            </>
          )}
        </div>
      </div>
    </article>
  );
}
