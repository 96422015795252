import React from "react";

import { Typography } from "@material-ui/core";

export function A11yDeclarationBlock1(props) {
  const { textContents, websiteURL } = props;

  return (
    <>
      <Typography component="h2">{textContents["block1-h2"]}</Typography>

      <Typography component="p">{textContents["block1-p1"]}</Typography>

      <Typography component="p">
        {textContents["block1-p2"].replace("{websiteURL}", websiteURL)}
      </Typography>

      <Typography component="h3">{textContents["block1-h3"]}</Typography>

      <Typography component="p">{textContents["block1-p3"]}</Typography>

      <Typography component="p">{textContents["block1-p4"]}</Typography>

      <Typography component="p">{textContents["block1-p5"]}</Typography>

      <ul>
        <Typography component="li">{textContents["block1-li1"]}</Typography>

        <Typography component="li">{textContents["block1-li2"]}</Typography>

        <Typography component="li">{textContents["block1-li3"]}</Typography>

        <Typography component="li">{textContents["block1-li4"]}</Typography>

        <Typography component="li">{textContents["block1-li5"]}</Typography>
      </ul>
    </>
  );
}
