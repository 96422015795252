import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Star(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#717587">
            <path d="M16.957 20c-.216 0-.429-.052-.62-.15L12.5 17.873 8.663 19.85c-.447.215-.98.17-1.383-.117-.404-.287-.613-.768-.544-1.252l.732-4.188-3.104-2.968c-.336-.352-.45-.856-.297-1.315.154-.458.55-.799 1.034-.887l4.29-.608 1.918-3.813C11.543 4.27 12.001 4 12.5 4c.499 0 .957.27 1.19.702l1.919 3.813 4.29.608c.484.088.88.429 1.034.887.153.459.04.963-.297 1.315l-3.104 2.968.732 4.188c.058.376-.052.759-.302 1.05-.25.29-.617.462-1.005.469z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Star;
