import api from "api";

export const RIDE_UPDATED = "RIDE_UPDATED";
export const CLEAR_RIDE = "CLEAR_RIDE";

const rideUpdated = (ride) => ({
  type: RIDE_UPDATED,
  ride,
});

export const clearRide = () => ({
  type: CLEAR_RIDE,
});

export const updateReservation =
  (rideId, selectedTerritoryKey) => (dispatch) => {
    api
      .getCurrentReservationInfos(
        { reservation_id: rideId },
        { territory: selectedTerritoryKey },
      )
      .then((json) => {
        dispatch(rideUpdated(json));
      })
      .catch((error) => {
        console.warn(error);
        dispatch(clearRide());
      });
  };
