// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-cssmodule-sideWrapper-OIKut{display:flex;justify-content:space-between;flex-direction:column;width:100%}@media (max-width: 888px){.main-cssmodule-sideWrapper-OIKut{display:none}}.main-cssmodule-content-uDW8C{display:flex;flex-direction:row}@media (max-width: 888px){.main-cssmodule-content-uDW8C{flex-direction:column}}.main-cssmodule-sideBar-HRVfR{flex:0 1 30rem;margin:1%}@media (max-width: 888px){.main-cssmodule-sideBar-HRVfR{flex:0 0 auto}}.main-cssmodule-results-jIub7{margin:1%;flex:1 1 60rem;transition:all 0.3s}@media (max-width: 888px){.main-cssmodule-results-jIub7{flex:0 0 auto}}.main-cssmodule-searchResultConfirm-RiOkw{margin:1%;flex:0 1 30rem}@media (max-width: 888px){.main-cssmodule-searchResultConfirm-RiOkw{flex:0 0 auto}}
`, "",{"version":3,"sources":["webpack://./src/views/SearchResult/main.cssmodule.scss"],"names":[],"mappings":"AAAA,kCACE,YAAa,CACb,6BAA8B,CAC9B,qBAAsB,CACtB,UAAW,CAMX,0BAVF,kCAWI,YAAa,CAEhB,CAED,8BACE,YAAa,CACb,kBAAmB,CACnB,0BAHF,8BAII,qBAAsB,CAEzB,CAED,8BACE,cAAe,CACf,SAAU,CACV,0BAHF,8BAII,aAAc,CAEjB,CAED,8BACE,SAAU,CACV,cAAe,CAIf,mBAAoB,CAHpB,0BAHF,8BAII,aAAc,CAGjB,CAED,0CACE,SAAU,CACV,cAAe,CACf,0BAHF,0CAII,aAAc,CAEjB","sourcesContent":[".sideWrapper {\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n  width: 100%;\n\n  /*@media (max-width: 1024px) {\n        width: 35%;\n    }*/\n\n  @media (max-width: 888px) {\n    display: none;\n  }\n}\n\n.content {\n  display: flex;\n  flex-direction: row;\n  @media (max-width: 888px) {\n    flex-direction: column;\n  }\n}\n\n.sideBar {\n  flex: 0 1 30rem;\n  margin: 1%;\n  @media (max-width: 888px) {\n    flex: 0 0 auto;\n  }\n}\n\n.results {\n  margin: 1%;\n  flex: 1 1 60rem;\n  @media (max-width: 888px) {\n    flex: 0 0 auto;\n  }\n  transition: all 0.3s;\n}\n\n.searchResultConfirm {\n  margin: 1%;\n  flex: 0 1 30rem;\n  @media (max-width: 888px) {\n    flex: 0 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sideWrapper": `main-cssmodule-sideWrapper-OIKut`,
	"content": `main-cssmodule-content-uDW8C`,
	"sideBar": `main-cssmodule-sideBar-HRVfR`,
	"results": `main-cssmodule-results-jIub7`,
	"searchResultConfirm": `main-cssmodule-searchResultConfirm-RiOkw`
};
export default ___CSS_LOADER_EXPORT___;
