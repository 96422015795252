import React, { useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Paper,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import dayjs from "dayjs";

import { Badge } from "atoms/Badge/Badge";
import { WrappedFormattedMessage } from "atoms/WrappedFormattedMessage/WrappedFormattedMessage";
import {
  TimedRideStep,
  TimedRideStepType,
} from "molecules/TimedRideStep/TimedRideStep";

import ExpandMore from "components/Icons/ExpandMore";
import ExpandLess from "components/Icons/ExpandLess";
import { Info } from "components/Icons/Info";
import { BusIcon } from "icons/BusIcon";
import { WalkerIcon } from "icons/WalkerIcon";
import { FORMAT_HOUR } from "utils/constants";

import { useRideDetailsStyles } from "./rideDetailsStyles";

export function RideDetails(props) {
  const { booking, reservationInfo } = props;

  const classes = useRideDetailsStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (!booking) {
    return null;
  }

  const departureAddress = reservationInfo.departure_position.address;
  const departureWalkDuration = booking.pickup_walking_time;

  const pickupTime = dayjs(booking.proposed_pickup_time)
    .tz()
    .format(FORMAT_HOUR);
  const estimatedPickupTime = dayjs(booking.pickup_time)
    .tz()
    .format(FORMAT_HOUR);
  const referencePickupTime =
    booking.reference_pickup_time &&
    dayjs(booking.reference_pickup_time).tz().format(FORMAT_HOUR);
  const pickupNodeName = booking.pickup_node.name;
  const pickupNodeAddress = booking.pickup_node.position.address;

  const busDuration = `${dayjs
    .duration({
      minutes: dayjs(booking.dropoff_time).diff(
        dayjs(booking.pickup_time),
        "minute",
      ),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min `;

  const arrivalAddress = reservationInfo.destination_position.address;
  const arrivalWalkDuration = booking.dropoff_walking_time;

  const dropoffTime = dayjs(booking.proposed_dropoff_time)
    .tz()
    .format(FORMAT_HOUR);
  const estimatedDropoffTime = dayjs(booking.dropoff_time)
    .tz()
    .format(FORMAT_HOUR);
  const dropoffNodeName = booking.dropoff_node.name;
  const dropoffNodeAddress = booking.dropoff_node.position.address;

  return (
    <Paper className={classes.rideDetailsContainer}>
      <Card className={classes.card}>
        <div
          className={classes.cardHeaderContainer}
          tabIndex={0}
          aria-expanded={expanded}
          onClick={handleExpandClick}
        >
          <CardHeader
            className={classes.cardHeader}
            id={"header-personal-items"}
            title={
              <div className={classes.cardHeaderLabel}>
                <Info fill="#0869AF" />
                <FormattedMessage id="ride_details.tooltip.understand_timestamps" />
              </div>
            }
          />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </div>

        <Collapse in={expanded}>
          <CardContent
            className={classes.cardContent}
            aria-labelledby={"header-personal-items"}
            aria-expanded={expanded}
          >
            <WrappedFormattedMessage
              formattedMessageProps={{ id: "research.label.min_departure" }}
              spanProps={{ className: classes.cardContentTitle }}
            />
            <WrappedFormattedMessage
              formattedMessageProps={{
                id: "ride_details.tooltip.min_departure_description",
              }}
              spanProps={{ className: classes.cardContentDescription }}
            />
            <WrappedFormattedMessage
              formattedMessageProps={{ id: "research.label.max_arrival" }}
              spanProps={{ className: classes.cardContentTitle }}
            />
            <WrappedFormattedMessage
              formattedMessageProps={{
                id: "ride_details.tooltip.max_arrival_description",
              }}
              spanProps={{ className: classes.cardContentDescription }}
            />
            <WrappedFormattedMessage
              formattedMessageProps={{
                id: "ride_details.tooltip.estimated_departure_arrival",
              }}
              spanProps={{ className: classes.cardContentTitle }}
            />
            <WrappedFormattedMessage
              formattedMessageProps={{
                id: "ride_details.tooltip.estimated_pu_do_description",
              }}
              spanProps={{ className: classes.cardContentDescription }}
            />
            <Alert severity="success" className={classes.alertSuccess}>
              <WrappedFormattedMessage
                formattedMessageProps={{
                  id: "label.confirmed_departure",
                }}
                spanProps={{ className: classes.alertTitle }}
              />
              <FormattedMessage id="ride_details.tooltip.reference_pu_time_description" />
            </Alert>
          </CardContent>
        </Collapse>
      </Card>

      <ol className={classes.rideStepList}>
        {departureWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.pickupColors}`}
              />
            }
            stepAddress={departureAddress}
            timeOrDuration={`${departureWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.pickupColors}`}
            />
          }
          stepAddress={pickupNodeAddress}
          stepName={pickupNodeName}
          timeOrDuration={pickupTime}
          estimatedTime={estimatedPickupTime}
          referencePickupTime={referencePickupTime}
          type={TimedRideStepType.Pickup}
        />

        <TimedRideStep
          badgeElement={<Badge label={busDuration} />}
          type={TimedRideStepType.Badge}
        />

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.dropoffColors}`}
            />
          }
          stepAddress={dropoffNodeAddress}
          stepName={dropoffNodeName}
          timeOrDuration={dropoffTime}
          estimatedTime={estimatedDropoffTime}
          type={TimedRideStepType.Dropoff}
        />

        {arrivalWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.dropoffColors}`}
              />
            }
            stepAddress={arrivalAddress}
            timeOrDuration={`${arrivalWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}
      </ol>
    </Paper>
  );
}
