import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Bus(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 40 40"
        focusable="false"
        role="none"
        aria-hidden="true"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#151515">
            <g>
              <path
                d="M27.43 2.925c4.196 0 7.62 3.3 7.818 7.447l.009.379v15.652c0 1.578-.47 3.087-1.329 4.364l-.124.171-.002 4.003c0 1.027-.725 1.884-1.69 2.088l-.211.034-.233.012h-2.972c-1.1 0-2.006-.832-2.122-1.902l-.013-.232-.001-.714H13.438v.714c0 1.027-.724 1.884-1.69 2.088l-.211.034-.233.012H8.332c-1.1 0-2.006-.832-2.122-1.902l-.012-.232-.002-4.001-.123-.171c-.688-1.022-1.126-2.193-1.274-3.428l-.042-.466-.014-.472V10.751c0-4.195 3.3-7.62 7.447-7.817l.38-.01h14.86zm0 1.423H12.57c-3.42 0-6.212 2.679-6.395 6.052l-.009.351v15.652c0 1.28.376 2.502 1.066 3.54l.242.339.146.191v4.468c0 .344.244.63.569.697l.143.014h2.972c.344 0 .631-.244.697-.568l.015-.143v-2.136h15.968v2.136c0 .344.244.63.568.697l.144.014h2.972c.344 0 .63-.244.697-.568l.014-.144.003-4.469.145-.19c.759-.996 1.21-2.189 1.293-3.454l.014-.424V10.751c0-3.419-2.679-6.211-6.052-6.394l-.351-.01zM12 24.268c1.594 0 2.893 1.271 2.893 2.847 0 1.575-1.299 2.845-2.893 2.845-1.594 0-2.893-1.27-2.893-2.845 0-1.576 1.299-2.846 2.893-2.846zm16 0c1.594 0 2.893 1.271 2.893 2.847 0 1.575-1.299 2.845-2.893 2.845-1.594 0-2.893-1.27-2.893-2.845 0-1.576 1.299-2.846 2.893-2.846zm-16 1.424c-.816 0-1.47.64-1.47 1.423 0 .782.654 1.423 1.47 1.423s1.47-.641 1.47-1.423c0-.783-.654-1.423-1.47-1.423zm16 0c-.816 0-1.47.64-1.47 1.423 0 .782.654 1.423 1.47 1.423s1.47-.641 1.47-1.423c0-.783-.654-1.423-1.47-1.423zm4.348-15.652V20H7.652v-9.96h24.696zm-1.424 1.422H9.074v7.114h21.85v-7.114z"
                transform="translate(-597.000000, -343.000000) translate(597.000000, 343.000000)"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Bus;
