import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { FormattedMessage } from "react-intl-phraseapp";

function OptOutView() {
  return (
    <div
      className="column"
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper style={{ padding: "2rem" }}>
        <Typography>
          <FormattedMessage id="terms.unsubscribe" />
        </Typography>
      </Paper>
    </div>
  );
}

export default OptOutView;
