import { makeStyles } from "@material-ui/core";

export const useCreditManagerStyles = makeStyles(() => ({
  buyCreditHeading: {
    fontWeight: 500,
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    padding: "16px",
  },
  creditIcon: {
    margin: "0 0 8px 0",
    fontSize: "36px",
  },
  creditManagerContainer: {
    display: "grid",
    gridGap: "16px",

    width: "100%",
    margin: "1rem 0 0 0",
  },
  sliderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    width: "100%",
    padding: "1rem",
  },
  sliderExtremum: {
    padding: "0 1rem",
  },
}));
