import React from "react";

import { SvgIcon } from "@material-ui/core";

export function BusIcon(props) {
  const { className } = props;

  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g>
          <circle cx="15" cy="15" r="15"></circle>

          <g transform="translate(4.000000, 4.000000)" fill="#FFFFFF">
            <path d="M14.5217391,3 C16.4427296,3 18,4.55727044 18,6.47826087 L18,14.1304348 C18,14.9151798 17.7401212,15.6392269 17.3017312,16.2212086 L17.3,18.3043478 C17.3,18.6885459 16.9885459,19 16.6043478,19 L15.1956522,19 C14.8114541,19 14.5,18.6885459 14.5,18.3043478 L14.5,17.608 L7.5,17.608 L7.5,18.3043478 C7.5,18.6885459 7.18854591,19 6.80434783,19 L5.39565217,19 C5.01145409,19 4.7,18.6885459 4.7,18.3043478 L4.69979169,16.2232288 C4.26048068,15.6409029 4,14.9160881 4,14.1304348 L4,6.47826087 C4,4.55727044 5.55727044,3 7.47826087,3 L14.5217391,3 Z M7.15,13.4347826 C6.57010101,13.4347826 6.1,13.9019637 6.1,14.4782609 C6.1,15.054558 6.57010101,15.5217391 7.15,15.5217391 C7.72989899,15.5217391 8.2,15.054558 8.2,14.4782609 C8.2,13.9019637 7.72989899,13.4347826 7.15,13.4347826 Z M14.85,13.4347826 C14.270101,13.4347826 13.8,13.9019637 13.8,14.4782609 C13.8,15.054558 14.270101,15.5217391 14.85,15.5217391 C15.429899,15.5217391 15.9,15.054558 15.9,14.4782609 C15.9,13.9019637 15.429899,13.4347826 14.85,13.4347826 Z M16.6,6.47826087 L5.4,6.47826087 L5.4,10.6521739 L16.6,10.6521739 L16.6,6.47826087 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
