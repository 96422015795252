import * as React from "react";
import { FixedLinesSection } from "organisms/FixedLinesResultElement/FixedLinesTypes";
import { FixedLinesLine } from "atoms/FixedLinesLine/FixedLinesLine";

type FixedLinesTitleSectionProps = {
  section: FixedLinesSection;
};

export function FixedLinesTitleSection(props: FixedLinesTitleSectionProps) {
  const { section } = props;

  return <FixedLinesLine line={section.line} mode={section.mode} />;
}
