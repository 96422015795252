import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useA11yDeclarationStyles = makeStyles((theme: CustomTheme) => ({
  contentBlock: {
    width: "100%",
    margin: "0.75rem 0",
    padding: "2rem",

    lineHeight: 1.3,

    "& h2, h3, h4, h5": {
      margin: "0 0 1.25rem 0",

      fontWeight: 500,
    },

    "& h2, h3": {
      fontSize: "1.5rem",
    },

    "& h4": {
      fontSize: "1.35rem",
    },

    "& h5": {
      fontSize: "1.3rem",
      fontStyle: "italic",
    },

    "& p": {
      margin: "0 0 1rem 0",
    },

    "& ul": {
      paddingInlineStart: "1.5rem",
    },

    "& li": {
      margin: "0 0 0.5rem 0",
    },

    "& address": {
      width: "fit-content",
      margin: "2rem auto 0.5rem auto",
      fontStyle: "normal",

      "& p": {
        margin: "0 0 0.125rem 0",
      },
    },

    [theme.breakpoints.up("sm")]: {
      padding: "2rem 2.5rem",
    },
  },
  contentWrapper: {
    padding: "1rem 0.5rem",

    [theme.breakpoints.up("sm")]: {
      padding: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      padding: "2rem",
    },
  },
  decorativeBackground: {
    background: `linear-gradient(${theme.palette.primary.light} 10rem, #f2f2f2 10rem)`,
  },
  link: {
    color: "#0869af",
    textDecoration: "underline",
  },
}));
