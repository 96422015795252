import React from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, IconButton, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import CancelRide from "containers/CancelRide/CancelRide";
import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import RateRide from "containers/RateRide/RateRide";
import { TimedRide } from "organisms/TimedRide/TimedRide";

import Delete from "components/Icons/Delete";

import { i18nVerboseDateFormatWithYear } from "utils/i18nDate";
import { capitalize } from "utils/strings";
import { FORMAT_DATE, FORMAT_HOUR, INITIAL } from "utils/constants";

import { useReservationListItemStyles } from "./reservationListItemStyles";
import { PaymentMethodAPIEnum } from "types/payment";
import { cn } from "utils/classes";

type ResNode = {
  district: string;
  name: string;
  id: number;
  position: {
    address: string;
    latitude: string;
    longitude: string;
    roadside_index: number;
  };
};
type ResPayment = {
  amount: number;
  initial_amount: number;
  invoice: string;
  used_credit_card: string;
  mode: string;
  status: string;
};

type PersonalItems = {
  count: number;
  personalItems: any[];
};

type Reservation = {
  pickup_time: string;
  passengers_number: number;
  extras_access: any; // TODO Remove this any by typed object
  extras_bike: any; // TODO Remove this any by typed object
  payment: ResPayment;
  pickup_node: ResNode;
  status: string;
  dropoff_time: string;
  dropoff_node: ResNode;
  passenger_status: string;
  personalItems: PersonalItems;
  id: string;
  proposed_dropoff_time: string;
  proposed_pickup_time: string;
};

type ReservationListItemProps = {
  isDeletable: (res: Reservation) => boolean;
  reservation: Reservation;
};

export function ReservationListItem(props: ReservationListItemProps) {
  const { isDeletable, reservation } = props;

  const classes = useReservationListItemStyles();

  const reservationDate = capitalize(
    i18nVerboseDateFormatWithYear(
      dayjs(reservation.pickup_time).tz().format(FORMAT_DATE),
    ),
  );

  const standardSeats = reservation?.passengers_number || 0;
  const wheelchairSeats =
    reservation?.extras_access?.requested_access_seats || 0;
  const bikeSeats: number = reservation?.extras_bike?.requested_bike_seats || 0;

  const price = reservation?.payment.amount || 0;

  const hasBeenCanceledByDriver = reservation.status === "CANCELED_BY_DRIVER";

  const pickupTime = dayjs(reservation.proposed_pickup_time)
    .tz()
    .format(FORMAT_HOUR);
  const pickupNodeName =
    reservation.pickup_node?.name || reservation.pickup_node?.position.address;

  const dropoffTime = dayjs(reservation.proposed_dropoff_time)
    .tz()
    .format(FORMAT_HOUR);

  const dropoffNodeName =
    reservation.dropoff_node?.name ||
    reservation.dropoff_node?.position.address;

  const canSeeMore = [INITIAL, PaymentMethodAPIEnum.ONBOARD].includes(
    reservation.passenger_status,
  );

  const canRateRide = ["DROPOFF"].includes(reservation.passenger_status);
  const personalItems = reservation.personalItems?.count || 0;

  return (
    <article
      className={cn(
        classes.reservationContainer,
        "bg-white shadow-[0_0_4px_rgba(0,0,0,0.2)]",
      )}
    >
      <div className={classes.reservationHeader}>
        <Typography className={classes.reservationDate}>
          {reservationDate}
        </Typography>

        <InfosBadges
          bikeSeats={bikeSeats}
          hasBeenCanceledByDriver={hasBeenCanceledByDriver}
          price={price}
          standardSeats={standardSeats}
          wheelchairSeats={wheelchairSeats}
          personalItems={personalItems}
        />
      </div>

      <div className={classes.timedRideContainer}>
        <TimedRide
          dropoffNodeName={dropoffNodeName}
          dropoffTime={dropoffTime}
          pickupNodeName={pickupNodeName}
          pickupTime={pickupTime}
        />

        <div className={classes.actionsContainer}>
          {canSeeMore && (
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to={`/reservation/${reservation.id}`}
            >
              <FormattedMessage id="ride.see_more" />
            </Button>
          )}

          {canRateRide && <RateRide reservation={reservation} />}

          {isDeletable && isDeletable(reservation) && (
            <CancelRide
              reservation={reservation}
              renderButton={(props) => (
                <IconButton className={classes.deleteButton} {...props}>
                  <Delete className={classes.deleteIcon} />
                </IconButton>
              )}
            />
          )}
        </div>
      </div>
    </article>
  );
}
