import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import Autocomplete from "containers/Autocomplete/Autocomplete";
import PaperBox from "components/PaperBox/PaperBox";

import styles from "./form.cssmodule.scss";

export default function ImpersonateForm(props) {
  const intl = useIntl();

  const {
    value,
    onChange,
    onUserSelected,
    onSubmit,
    onCreateNewUser,
    selectedUser,
    isRequesting,
  } = props;

  return (
    <PaperBox title={<FormattedMessage id="impersonate.title2" />}>
      <div>
        <MuiButton
          aria-label="create new user"
          style={{ float: "left" }}
          color="inherit"
          onClick={onCreateNewUser}
          variant="outlined"
          data-testid="create-new-user-btn"
        >
          <FormattedMessage id="impersonate.new" />
        </MuiButton>
      </div>

      <form className={styles.form} onSubmit={onSubmit}>
        <Typography className={styles.searchLabel} variant="caption">
          {intl.formatMessage({ id: "impersonate.floattingHint" })}
        </Typography>

        <Autocomplete
          id="impersonate-autocomplete"
          context="impersonate"
          inputProps={{
            label: intl.formatMessage({ id: "impersonate.floattingHint" }),
            text: value,
          }}
          onChange={onChange}
          onSelect={(p) => {
            // FIXME: the selected user is undefined sometimes
            // https://sentry.io/organizations/padam-mobility/issues/2809581524/?project=1877452
            if (p) {
              onUserSelected(p);
            }
          }}
        />

        <div className="right">
          <MuiButton
            variant="contained"
            aria-label="start session"
            type="submit"
            color="primary"
            disabled={!selectedUser || isRequesting}
            data-testid="start-session-btn"
          >
            {isRequesting ? (
              <CircularProgress />
            ) : (
              <FormattedMessage id="impersonate.start" />
            )}
          </MuiButton>
        </div>
      </form>
    </PaperBox>
  );
}
