import React from "react";
import { useIntl } from "react-intl";

import { IconButton, Paper, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { InfosBadges } from "molecules/InfosBadges/InfosBadges";

import Cross from "components/Icons/Cross/Cross";
import { FixedLinesTimedRide } from "components/organisms/FixedLinesTimeRide/FixedLinesTimedRide";

import { useFixedLinesRideSummaryStyles } from "./fixedLinesRideSummaryStyles";

import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";
import { FIXED_LINES_MODE_WALK } from "../FixedLinesResultElement/FixedLinesTypes";
import { FixedLinesTripTitle } from "components/molecules/FixedLinesTripTitle/FixedLinesTripTitle";

export default function FixedLinesRideSummary({
  booking,
  onDelete,
  personalItemsCount,
}) {
  const classes = useFixedLinesRideSummaryStyles();
  const intl = useIntl();

  if (!booking) {
    return null;
  }

  const bookingDay = capitalize(
    i18nVerboseDateFormat(booking.departure_datetime),
  );

  const pickupTime = dayjs(booking.departure_datetime).tz().format("HH:mm");
  const pickupNodeName = booking.sections[0].departure_stop.name;
  const dropoffTime = dayjs(booking.arrival_datetime).tz().format("HH:mm");
  const dropoffNodeName =
    booking.sections[booking.sections.length - 1].arrival_stop.name;

  const tripDuration = `${dayjs
    .duration({
      minutes: dayjs(booking.arrival_datetime).diff(
        dayjs(booking.departure_datetime),
        "minute",
      ),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min `;

  const totalWalkDuration = booking.sections
    .filter((section) => section.mode == FIXED_LINES_MODE_WALK)
    .reduce((acc, element) => acc + element.duration, 0);

  const walkDurationValue = dayjs.duration({
    minutes: totalWalkDuration / 60,
  });
  const walkDuration = `${walkDurationValue
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  return (
    <Paper className={classes.rideSummaryContainer} tabIndex={-1}>
      <Typography className={classes.summaryTitle} component="h2">
        {bookingDay}
      </Typography>

      <FixedLinesTripTitle trip={booking} />

      <div className={classes.summaryTimedRide}>
        <FixedLinesTimedRide
          dropoffNodeName={dropoffNodeName}
          dropoffTime={dropoffTime}
          pickupNodeName={pickupNodeName}
          pickupTime={pickupTime}
        />
      </div>

      <div className={classes.summaryInfosBadges}>
        <InfosBadges
          duration={tripDuration}
          walkDuration={walkDuration}
          personalItems={personalItemsCount}
        />
      </div>

      {onDelete && (
        <div className={classes.deleteButton}>
          <IconButton
            aria-label={intl.formatMessage({ id: "aria.cancel_selected_trip" })}
            onClick={() => {
              onDelete(booking);
            }}
          >
            <Cross />
          </IconButton>
        </div>
      )}
    </Paper>
  );
}
