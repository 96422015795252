import * as React from "react";
import { useState, useEffect, useCallback } from "react";

import { Fab, Slide } from "@material-ui/core";

import ExpandLess from "components/Icons/ExpandLess";

import { useScrollButtonToTopStyles } from "./scrollToTopButtonStyles";

const DEFAULT_SCROLL_BUTTON_TIMEOUT = 150;
const SCROLL_OFFSET_BUTTON_APPEAR = 100; // just after nav bar leave viewport

export function ScrollToTopButton() {
  const [isButtonShown, setIsButtonShown] = useState(false);
  const classes = useScrollButtonToTopStyles();

  const handleClickButton = () => {
    window.scrollTo({
      top: -Number.MAX_SAFE_INTEGER,
      behavior: "smooth",
    });
  };

  const updateButtonVisibility = useCallback(() => {
    const offset = document.documentElement.scrollTop;

    if (!offset) return;

    setIsButtonShown(offset > SCROLL_OFFSET_BUTTON_APPEAR);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", updateButtonVisibility);

    return () => window.removeEventListener("scroll", updateButtonVisibility);
  }, [updateButtonVisibility]);

  return (
    <Slide
      direction="up"
      timeout={DEFAULT_SCROLL_BUTTON_TIMEOUT}
      in={isButtonShown}
    >
      <div className={classes.floatinButtonContainer}>
        <Fab
          onClick={handleClickButton}
          color={"primary"}
          className={classes.scrollButton}
        >
          <ExpandLess />
        </Fab>
      </div>
    </Slide>
  );
}
