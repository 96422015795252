import React from "react";
import { useContext } from "react";
import { FormInputGenerator } from "molecules/FormInputGenerator/FormInputGenerator";
import { CustomFieldsType } from "components/organisms/AccountInfos/types/CustomFieldsType";
import { ProductContext } from "contexts/ProductContext";
import { CustomField } from "api/types/common";
import { CustomFieldType } from "utils/constants";

type SignUpFormCustomFieldsProps = {
  customFields: CustomFieldsType | null | undefined;
  onChange: (name: string, value: any) => void;
};

export const SignUpFormCustomFields = (props: SignUpFormCustomFieldsProps) => {
  const { onChange } = props;

  // Get data to generate input fields
  const { productParameters } = useContext(ProductContext);
  const signupCustomFields = productParameters?.signup;

  if (!signupCustomFields || signupCustomFields.length === 0) {
    return null;
  }

  function render(field: CustomField) {
    const value: string | number | boolean | null = null;
    let extraProperties = {};

    if (field.type === "select") {
      extraProperties = {
        optionsSelect: field?.options,
      };
    }

    return (
      <div className="field">
        <FormInputGenerator
          identifier={field.key}
          type={CustomFieldType[field.type]}
          displayName={field.display_name}
          translatedDisplayName={false}
          prefixLabelId="forms."
          required={field.is_required}
          value={value}
          prefixId="profile-"
          prefixTestId="account-infos-"
          onChange={(name: string, value: any) =>
            onChange(`customfield|${name}`, value)
          }
          {...extraProperties}
        />
      </div>
    );
  }

  /**
   * This function is used to display the custom fields in two columns
   * to keep the same display format for other signup fields
   * @param array
   * @param size
   */
  const getCustomFieldInputsTuple = (): JSX.Element[][] => {
    const CustomFieldInputsTuple: JSX.Element[][] = [];
    let index = 0;
    const totalRows =
      signupCustomFields.length > 2
        ? Math.ceil(signupCustomFields.length / 2)
        : 1;

    for (let row = 0; row < totalRows; row++) {
      if (!CustomFieldInputsTuple[row]) {
        CustomFieldInputsTuple[row] = [];
      }
      // Add two fields per row
      for (let column = 0; column < 2; column++) {
        if (signupCustomFields[index]) {
          CustomFieldInputsTuple[row].push(render(signupCustomFields[index]));
        }
        index++;
      }
    }

    // Add a blank field if the last row has only one field
    if (
      CustomFieldInputsTuple[CustomFieldInputsTuple.length - 1].length === 1
    ) {
      CustomFieldInputsTuple[CustomFieldInputsTuple.length - 1].push(
        <div className="field" />,
      );
    }

    return CustomFieldInputsTuple;
  };

  return (
    <>
      {getCustomFieldInputsTuple()?.map((inputs: any, index: number) => {
        return (
          <div
            key={`${index}-tuple`}
            className="row"
            style={{ width: "100%", justifyContent: "space-around" }}
          >
            {inputs}
          </div>
        );
      })}
    </>
  );
};
