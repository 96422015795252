// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation-cssmodule-wrapper-ihZgC{justify-content:center;padding:1rem;transition:all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)}.confirmation-cssmodule-container-HRvjE{overflow:auto}
`, "",{"version":3,"sources":["webpack://./src/components/BookingValidation/confirmation.cssmodule.scss"],"names":[],"mappings":"AAAA,sCACE,sBAAuB,CACvB,YAAa,CACb,oDAAqD,CACtD,wCAGC,aAAc","sourcesContent":[".wrapper {\n  justify-content: center;\n  padding: 1rem;\n  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);\n}\n\n.container {\n  overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `confirmation-cssmodule-wrapper-ihZgC`,
	"container": `confirmation-cssmodule-container-HRvjE`
};
export default ___CSS_LOADER_EXPORT___;
