import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  Button as MuiButton,
  CircularProgress,
  useTheme,
} from "@material-ui/core";

import { useElements, useStripe } from "@stripe/react-stripe-js";

import { Price } from "atoms/Price/Price";
import { Card } from "./forms/Card";
import { Iban } from "./forms/Iban";
import { PaymentMethodEnum, StripeFormProps } from "types/payment";

/** ****** description :
 * there are several components (we need to wrap the main one with stripe stuff to provide props)
 * for now, we don't use Redux as we don't really need it.
 * so asynchronoucity is managed with promises
 */

export function StripeForm(props: StripeFormProps) {
  const { method, totalPrice, isRequesting, pay, isFormOpen, stripeError } =
    props;

  const theme = useTheme();

  const stripe = useStripe();
  const elements = useElements();

  // used to display error of the Stripe payment intent process (card...)
  const [formError, setFormError] = useState(null);
  const [shouldSavePaymentMethod, setShouldSavePaymentMethod] = useState(false);
  const [extraOptions, setExtraOptions] = useState({});

  const stripeStyle = {
    base: {
      fontSize: "16px",
      color: "#424770",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: theme.palette.error.main,
    },
  };

  const handleChange = ({ error }) => {
    if (error) {
      /* I don't know why there are quotes "" around the string, so let's remove them anyway */
      const temp = error.message;
      temp.replace(/["]+/g, "");
      setFormError(temp);
    }
  };

  const payWrapper = () => {
    setFormError(null);
    pay(stripe, elements, totalPrice, shouldSavePaymentMethod, extraOptions);
  };

  const errorMessage = formError || stripeError;

  return (
    <div dir="ltr" className="flex flex-col justify-center">
      {method === PaymentMethodEnum.STRIPE_CARD && isFormOpen && (
        <Card
          stripeStyle={stripeStyle}
          handleChange={handleChange}
          errorMessage={errorMessage}
          shouldSavePaymentMethod={shouldSavePaymentMethod}
          setShouldSavePaymentMethod={setShouldSavePaymentMethod}
        />
      )}
      {method === PaymentMethodEnum.STRIPE_SEPA && isFormOpen && (
        <Iban
          stripeStyle={stripeStyle}
          handleChange={handleChange}
          errorMessage={errorMessage}
          shouldSavePaymentMethod={shouldSavePaymentMethod}
          setShouldSavePaymentMethod={setShouldSavePaymentMethod}
          setExtraOptions={setExtraOptions}
        />
      )}

      <div className="my-3 mr-3 flex justify-end md:mr-0">
        <MuiButton
          className="w-1/4"
          color="primary"
          variant="contained"
          onClick={payWrapper}
          disabled={isRequesting}
          data-testid="pay-with-credit-card-btn"
        >
          {isRequesting ? (
            <CircularProgress />
          ) : (
            <FormattedMessage
              id="payment.general"
              defaultMessage="Pay {price}"
              values={{ price: <Price value={totalPrice} /> }}
            />
          )}
        </MuiButton>
      </div>
    </div>
  );
}
