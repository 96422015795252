import { TEXT_CONTENTS_CA } from "./translations/a11yDeclaration.ca";
import { TEXT_CONTENTS_DE } from "./translations/a11yDeclaration.de";
import { TEXT_CONTENTS_EN } from "./translations/a11yDeclaration.en";
import { TEXT_CONTENTS_ES } from "./translations/a11yDeclaration.es";
import { TEXT_CONTENTS_FR } from "./translations/a11yDeclaration.fr";
import { TEXT_CONTENTS_IT } from "./translations/a11yDeclaration.it";
import { TEXT_CONTENTS_NL } from "./translations/a11yDeclaration.nl";
import { TEXT_CONTENTS_SV } from "./translations/a11yDeclaration.sv";
import { TEXT_CONTENTS_DA } from "./translations/a11yDeclaration.da";

const TRANSLATIONS = {
  default: TEXT_CONTENTS_EN,
  ca: TEXT_CONTENTS_CA,
  de: TEXT_CONTENTS_DE,
  en: TEXT_CONTENTS_EN,
  es: TEXT_CONTENTS_ES,
  fr: TEXT_CONTENTS_FR,
  "fr-ca": TEXT_CONTENTS_FR,
  it: TEXT_CONTENTS_IT,
  nl: TEXT_CONTENTS_NL,
  sv: TEXT_CONTENTS_SV,
  da: TEXT_CONTENTS_DA,
};

export function getTextContents(lang) {
  const textContents = {};

  for (let key in TRANSLATIONS.default) {
    textContents[key] = TRANSLATIONS[lang][key] || TRANSLATIONS.default[key];
  }

  return textContents;
}
