import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Parameters(props) {
  return (
    <SvgIcon {...props}>
      <svg>
        <g fill="none" fillRule="evenodd">
          <g fill="#717587" fillRule="nonzero">
            <path d="M12.417 22h.416c.365 0 .625-.258.677-.568l.313-1.603c.885-.206 1.719-.516 2.448-1.033l1.354.93c.26.207.677.155.885-.103l1.146-1.137c.26-.259.26-.62.104-.879l-.937-1.343c.469-.776.833-1.603.99-2.43l1.614-.31c.313-.051.573-.361.573-.671v-1.654c0-.362-.26-.62-.573-.672l-1.614-.31c-.209-.878-.521-1.705-.99-2.429l.937-1.344c.209-.258.157-.671-.104-.878l-1.093-1.189c-.209-.258-.625-.258-.886-.103l-1.354.93c-.781-.465-1.615-.827-2.448-1.033l-.313-1.603c-.052-.31-.364-.568-.677-.568H11.22c-.365 0-.625.258-.677.568l-.313 1.654c-.885.207-1.719.517-2.448 1.034l-1.354-.93c-.26-.207-.677-.155-.885.103L4.396 5.566c-.26.207-.26.62-.104.878l.937 1.344c-.469.775-.833 1.55-1.042 2.43l-1.614.31c-.313.05-.573.36-.573.671v1.654c0 .361.26.62.573.672l1.615.31c.208.878.52 1.705 1.041 2.429l-.937 1.343c-.209.259-.157.672.104.879l1.146 1.137c.26.258.625.258.885.103l1.354-.93c.782.465 1.563.827 2.448 1.033l.313 1.603c.052.31.364.568.677.568h1.198zm-.365-5.116c-2.708 0-4.896-2.17-4.896-4.91 0-2.687 2.188-4.858 4.896-4.858 2.708 0 4.896 2.17 4.896 4.858 0 2.74-2.188 4.91-4.896 4.91z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Parameters;
