import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useRideDetailsStyles = makeStyles((theme: CustomTheme) => ({
  busIcon: {
    width: "1.875rem",
    height: "1.875rem",
  },
  infoBox: {
    margin: "2rem 0 0 0",
  },
  rideDetailsContainer: {
    padding: "1rem",
  },
  rideStepList: {
    padding: 0,

    listStyle: "none",

    "& li": {
      margin: "0 0 0 2rem",
      padding: "0 1rem 2rem 2rem",
      borderLeft: "0.125rem dashed #717587",
    },

    "& li:last-child": {
      padding: "0 1rem 0 2rem",
      borderColor: "transparent",
    },
  },
  stepIcon: {
    position: "absolute",
    left: "-1rem",

    background: "white",
  },
  walkerIcon: {
    width: "1.375rem",
    height: "1.375rem",
    padding: "0.125rem",
    border: "0.125rem solid transparent",
    borderRadius: "50%",
  },

  // let this classes here, to override previous classes:
  dropoffColors: {
    borderColor: theme.palette.dropoff.main,

    fill: theme.palette.dropoff.main,

    "& circle": {
      fill: theme.palette.dropoff.main,
    },
  },
  pickupColors: {
    borderColor: theme.palette.pickup.main,

    fill: theme.palette.pickup.main,

    "& circle": {
      fill: theme.palette.pickup.main,
    },
  },
  card: {
    color: "#0869AF",
    backgroundColor: "#D7E7F2",
    padding: "1rem",
    margin: "1rem 0 2rem 0",

    "& .MuiCardContent-root:last-child": {
      padding: 0,
    },
  },
  cardContent: {
    padding: 0,
    margin: "1.5rem 0 0 1rem",
  },
  cardContentTitle: {
    display: "block",
    color: theme.palette.text.primary,
    marginBottom: "0.5rem",
  },
  cardContentDescription: {
    display: "block",
    marginBottom: "1.5rem",
  },
  cardHeader: {
    padding: 0,

    "& legend": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
  },
  cardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    cursor: "pointer",
  },
  cardHeaderLabel: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre-wrap",

    "& > svg": {
      margin: "0 0.5rem",
      width: "1.5rem",
      height: "1.5rem",
    },
  },
  alertSuccess: {
    color: theme.palette.success.main,
  },
  alertTitle: {
    display: "block",
    marginBottom: "0.5rem",
    fontWeight: "bold",
  },
}));
