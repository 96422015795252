import React, { useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Typography } from "@material-ui/core";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import { TerritoryContext } from "contexts/TerritoryContext";
import { StripeForm } from "components/molecules/StripeForm/StripeForm";

import "./stripe.css";
import { StripeFormProps } from "types/payment";

type extraOptions = {
  stripeAccount?: string;
};

interface StripeWrapperElementProps extends StripeFormProps {}

export default function StripeWrapperElement(props: StripeWrapperElementProps) {
  const { selectedTerritory } = useContext(TerritoryContext);

  const stripeProvider = selectedTerritory?.payment?.stripe;
  const token = stripeProvider?.token || "";

  const extraOptions: extraOptions = {};
  if (stripeProvider?.account) {
    extraOptions.stripeAccount = stripeProvider?.account;
  }

  if (!token)
    return (
      <Typography color="error" style={{ margin: "1rem" }}>
        <FormattedMessage id="misc.unexpected_error" />
      </Typography>
    );

  const stripePromise = useMemo(() => loadStripe(token, extraOptions), [token]);

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        {
          /* only time when ...props is tolerated ;) */
          ...props
        }
      />
    </Elements>
  );
}
