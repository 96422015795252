import * as React from "react";

import { FixedLinesArrow } from "components/molecules/FixedLinesArrow/FixedLinesArrow";

import { useFixedLinesTimedRideStyles } from "./fixedLinesTimedRideStyles";

type FixedLinesTimedRideProps = {
  dropoffNodeName: string;
  dropoffTime: string;
  pickupNodeName: string;
  pickupTime: string;
};

export function FixedLinesTimedRide({
  dropoffNodeName,
  dropoffTime,
  pickupTime,
  pickupNodeName,
}: FixedLinesTimedRideProps) {
  const classes = useFixedLinesTimedRideStyles();

  return (
    <>
      <div className={classes.container}>
        <FixedLinesArrow
          pickupTime={pickupTime}
          dropoffTime={dropoffTime}
          pickupName={pickupNodeName}
          dropoffName={dropoffNodeName}
        />
      </div>
    </>
  );
}
