// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Those fonts are specified in the Material-Ui Theme config file */
/* only import the needed weights */

/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
`, "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA,mEAAmE;AACnE,mCAAmC;;AAInC;8EAC8E","sourcesContent":["/* Those fonts are specified in the Material-Ui Theme config file */\n/* only import the needed weights */\n@import url(\"https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap\");\n@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap\");\n\n/* @import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
