import api from "api";
import { NavigateFunction } from "react-router";

export const ON_LOOKUP_PROPOSITION_RECEIVE =
  "@@LookupProposition/ON_LOOKUP_PROPOSITION_RECEIVE";

export const lookupProposition =
  (
    navigate: NavigateFunction,
    propositionId: string | null,
    changeSelectedTerritory?: (territoryIdentifier: string) => void,
  ) =>
  (dispatch) => {
    api
      .retrievePropositionDetails({ proposition_id: propositionId })
      .then(
        (proposition: {
          search_request: string;
          territory_identifier: string;
        }) => {
          api
            .retrieveSearchRequestDetails({
              search_request_id: proposition.search_request,
            })
            .then((search_request) => {
              if (changeSelectedTerritory)
                changeSelectedTerritory(proposition.territory_identifier);
              dispatch({
                type: ON_LOOKUP_PROPOSITION_RECEIVE,
                proposition,
                search_request,
              });
              navigate("/search/result/confirmation");
            });
        },
      );
  };
