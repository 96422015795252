import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useResetPasswordStyles = makeStyles((theme: CustomTheme) => ({
  passwordInput: {
    margin: "1rem 0 0 0",
    width: "100%",
  },
  errorMessage: {
    color: theme.palette.error.main,
    fontSize: theme.typography.caption.fontSize,
  },
  resetPasswordContainer: {
    margin: "16px",
    padding: "2rem 1.5rem",

    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  resetPasswordTitle: {
    margin: "0 1rem 1rem 1rem",

    fontWeight: 500,
    textAlign: "center",
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",

    width: "100%",
    margin: "3rem 0 0 0",
  },
}));
