// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-cssmodule-form-FIl6O{margin:48px 0 0 0}.form-cssmodule-form-FIl6O button[type="submit"]{margin:32px 0 0 0}.form-cssmodule-searchLabel-J4rog{color:#5c5c5c}
`, "",{"version":3,"sources":["webpack://./src/components/ImpersonateForm/form.cssmodule.scss"],"names":[],"mappings":"AAAA,2BACE,iBAAkB,CADpB,iDAII,iBAAkB,CACnB,kCAID,aAAc","sourcesContent":[".form {\n  margin: 48px 0 0 0;\n\n  button[type=\"submit\"] {\n    margin: 32px 0 0 0;\n  }\n}\n\n.searchLabel {\n  color: #5c5c5c;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `form-cssmodule-form-FIl6O`,
	"searchLabel": `form-cssmodule-searchLabel-J4rog`
};
export default ___CSS_LOADER_EXPORT___;
