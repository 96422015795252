import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Visa(props) {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          d="M512 402.281c0 16.716-13.55 30.267-30.265 30.267H30.265C13.55 432.549 0 418.997 0 402.281V109.717c0-16.716 13.55-30.266 30.265-30.266h451.47c16.716 0 30.265 13.551 30.265 30.266v292.564z"
          fill="#fff"
        />
        <path
          d="M113.64 258.035l-12.022-57.671c-2.055-7.953-8.035-10.319-15.507-10.632H30.993l-.491 2.635c42.929 10.407 71.334 35.513 83.138 65.668z"
          fill="#f79f1a"
        />
        <g fill="#059bbf">
          <path d="M241.354 190.892h-35.613l-22.242 130.527h35.554zM135.345 321.288l56.01-130.307h-37.691l-34.843 89.028-3.719-13.442c-6.83-16.171-26.35-39.446-49.266-54.098l31.85 108.863 37.659-.044zM342.931 278.75c.132-14.819-9.383-26.122-29.887-35.458-12.461-6.03-20.056-10.051-19.965-16.17 0-5.406 6.432-11.213 20.368-11.213 11.661-.179 20.057 2.367 26.624 5.003l3.218 1.475 4.826-28.277c-7.059-2.637-18.094-5.451-31.895-5.451-35.157 0-59.904 17.691-60.128 43.064-.224 18.763 17.692 29.216 31.181 35.469 13.847 6.374 18.493 10.453 18.404 16.171-.089 8.743-11.035 12.73-21.264 12.73-14.25 0-21.8-1.965-33.509-6.843l-4.55-2.09-4.998 29.249c8.303 3.629 23.668 6.801 39.618 6.933 37.387 0 61.689-17.466 61.957-44.592zM385.233 301.855c4.065 0 40.382.045 45.566.045 1.072 4.545 4.333 19.565 4.333 19.565h33.011L439.33 191.027h-27.472c-8.533 0-14.874 2.323-18.628 10.809l-52.845 119.629h37.392c-.003 0 6.071-16.079 7.456-19.61zm24.389-63.21c-.176.357 2.95-7.549 4.737-12.463l2.411 11.256s6.792 31.182 8.22 37.704h-29.528c2.949-7.504 14.16-36.497 14.16-36.497zM481.735 79.451H30.265C13.55 79.451 0 93.001 0 109.717v31.412h512v-31.412c0-16.716-13.549-30.266-30.265-30.266z" />
        </g>
        <path
          d="M481.735 432.549H30.265C13.55 432.549 0 418.998 0 402.283v-31.412h512v31.412c0 16.715-13.549 30.266-30.265 30.266z"
          fill="#f79f1a"
        />
        <path
          d="M21.517 402.281V109.717c0-16.716 13.551-30.266 30.267-30.266h-21.52C13.55 79.451 0 93.001 0 109.717v292.565c0 16.716 13.55 30.267 30.265 30.267h21.52c-16.716 0-30.268-13.552-30.268-30.268z"
          opacity=".15"
          fill="#202121"
        />
      </svg>
    </SvgIcon>
  );
}
