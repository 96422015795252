import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Maestro(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <path
              fill="#FFF"
              stroke="#E6E7E8"
              strokeWidth=".5"
              d="M22.906.25c.233 0 .444.094.597.247.152.152.247.361.247.592h0v14.822c0 .232-.093.443-.245.595-.152.151-.363.244-.599.244h0H1.094c-.233 0-.444-.094-.597-.247-.152-.152-.247-.361-.247-.592h0V1.089C.25.857.343.646.495.494.647.343.858.25 1.094.25h0z"
              transform="translate(0 3.5)"
            />
            <g>
              <path
                fill="#7673C0"
                d="M5.237 0.976H9.120000000000001V8.141H5.237z"
                transform="translate(0 3.5) translate(4.847 4.188)"
              />
              <path
                fill="#EB001B"
                d="M5.485 4.557C5.484 3.16 6.109 1.84 7.18.976 5.36-.492 2.747-.28 1.177 1.467c-1.57 1.746-1.57 4.437 0 6.182 1.57 1.746 4.183 1.96 6.003.491-1.072-.863-1.697-2.184-1.695-3.583z"
                transform="translate(0 3.5) translate(4.847 4.188)"
              />
              <path
                fill="#00A1DF"
                fillRule="nonzero"
                d="M13.936 7.555v-.29h.058v-.061h-.147v.06h.058v.29h.03zm.285 0v-.351h-.045l-.052.25-.051-.25h-.046v.35h.032v-.262l.048.228h.033l.049-.228v.265l.032-.002z"
                transform="translate(0 3.5) translate(4.847 4.188)"
              />
              <path
                fill="#00A1DF"
                d="M14.359 4.557c0 1.745-.97 3.336-2.499 4.099-1.528.762-3.346.561-4.682-.517 1.07-.864 1.696-2.184 1.696-3.582 0-1.398-.625-2.719-1.696-3.583C8.514-.104 10.333-.304 11.861.458c1.528.763 2.498 2.355 2.498 4.1z"
                transform="translate(0 3.5) translate(4.847 4.188)"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
