import api from "api";
import { formatterPersonalItem } from "containers/ReservationList/actions";
export const CLEAR_RESERVATION = "CLEAR_RESERVATION";
export const INITIATE_RESERVATION_REQUEST =
  "reservation @@ INITIATE_RESERVATION_REQUEST";
export const RECEIVE_RESERVATION = "reservation @@ RECEIVE_RESERVATION";
export const RESERVATION_REQUEST_ERROR =
  "reservation @@  RESERVATION_REQUEST_ERROR";

// below is used in reservationForm
export const reservationFetched = (reservation) => ({
  type: RECEIVE_RESERVATION,
  reservation,
});

export const clearReservation = () => ({
  type: CLEAR_RESERVATION,
});

// for single reservation view, we also want the "max-dropoff-time" value (with algorithm margin)
// we get it from "get-current-reservation-info" at key "dropoff_time"
export const fetchReservation =
  (id, selectedTerritoryKey) => (dispatch, getState) => {
    dispatch({ type: INITIATE_RESERVATION_REQUEST });
    const state = getState();
    const customerId = state?.user?.user?.customer_id;
    api
      .getReservation({ id }, { territory: selectedTerritoryKey })
      .then((json) => {
        let result = { ...json };
        if (!json.passengers_number) {
          let nb_passengers = 0;
          if (json.requested_seats) nb_passengers += json.requested_seats;

          if (json.requested_access_seats)
            nb_passengers += json.requested_access_seats;

          result.passengers_number = nb_passengers;
        }
        // Get personal items
        const bookingId = id;
        api
          .getBookingPersonalItems({
            customerId,
            bookingId,
          })
          .then((json) => {
            result.personalItems = formatterPersonalItem(json.results);
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // try to complete infos form other api
            api
              .getCurrentReservationInfos(
                { reservation_id: id },
                { territory: selectedTerritoryKey },
              )
              .then((json1) => {
                // Dropoff time in getReservationInfo is not consistant with updated dropoff time
                delete json1.dropoff_time;
                result = {
                  ...result,
                  ...json1,
                };
              })
              .catch((error) => console.log({ error }))
              .finally(() => {
                // the api working or not, we send result to redux
                dispatch(reservationFetched(result));
              });
          });
      })
      .catch(() => {
        dispatch({ type: RESERVATION_REQUEST_ERROR });
      });
  };
