// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signupForm-cssmodule-logoContainer-Z8y_p{width:40%;margin:2% auto;height:10vh;display:flex;justify-content:center;align-items:center}.signupForm-cssmodule-submitForm-HWXj7{margin-top:20px;align-items:center;justify-content:center}@media (max-width: 500px){.signupForm-cssmodule-submitForm-HWXj7{display:block}}@media (min-width: 500px){.signupForm-cssmodule-submitForm-HWXj7{display:flex}}.signupForm-cssmodule-submitForm-HWXj7 li{margin:1rem}.signupForm-cssmodule-territoryLogo-iUu1l{max-width:100%;max-height:100%}.signupForm-cssmodule-terms-wXWh3{display:flex;flex-direction:column;font-weight:500;margin-top:20px;margin-left:40px;justify-content:space-around}.signupForm-cssmodule-fieldsContainer-y8iHI{display:flex;flex-flow:row wrap;position:relative;justify-content:space-around;width:100%}.signupForm-cssmodule-link-eLGUU{padding:1rem;text-align:center}.signupForm-cssmodule-link-eLGUU svg{transform:translateY(3px)}.signupForm-cssmodule-textLink-e65uP{color:#0869af;text-decoration:underline}
`, "",{"version":3,"sources":["webpack://./src/components/SignUpForm/signupForm.cssmodule.scss"],"names":[],"mappings":"AAAA,0CACE,SAAU,CACV,cAAe,CACf,WAAY,CACZ,YAAa,CACb,sBAAuB,CACvB,kBAAmB,CACpB,uCAGC,eAAgB,CAChB,kBAAmB,CACnB,sBAAuB,CAEvB,0BALF,uCAMI,aAAc,CASjB,CAPC,0BARF,uCASI,YAAa,CAMhB,CAfD,0CAaI,WAAY,CACb,0CAID,cAAe,CACf,eAAgB,CACjB,kCAGC,YAAa,CACb,qBAAsB,CACtB,eAAgB,CAChB,eAAgB,CAChB,gBAAiB,CACjB,4BAA6B,CAC9B,4CAGC,YAAa,CACb,kBAAmB,CACnB,iBAAkB,CAClB,4BAA6B,CAC7B,UAAW,CACZ,iCAGC,YAAa,CACb,iBAAkB,CACnB,qCAGC,yBAA0B,CAC3B,qCAGC,aAAc,CACd,yBAA0B","sourcesContent":[".logoContainer {\n  width: 40%;\n  margin: 2% auto;\n  height: 10vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.submitForm {\n  margin-top: 20px;\n  align-items: center;\n  justify-content: center;\n\n  @media (max-width: 500px) {\n    display: block;\n  }\n  @media (min-width: 500px) {\n    display: flex;\n  }\n\n  li {\n    margin: 1rem;\n  }\n}\n\n.territoryLogo {\n  max-width: 100%;\n  max-height: 100%;\n}\n\n.terms {\n  display: flex;\n  flex-direction: column;\n  font-weight: 500;\n  margin-top: 20px;\n  margin-left: 40px;\n  justify-content: space-around;\n}\n\n.fieldsContainer {\n  display: flex;\n  flex-flow: row wrap;\n  position: relative;\n  justify-content: space-around;\n  width: 100%;\n}\n\n.link {\n  padding: 1rem;\n  text-align: center;\n}\n\n.link svg {\n  transform: translateY(3px);\n}\n\n.textLink {\n  color: #0869af; // TODO: use theme.palette.info.main when creating style hook\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logoContainer": `signupForm-cssmodule-logoContainer-Z8y_p`,
	"submitForm": `signupForm-cssmodule-submitForm-HWXj7`,
	"territoryLogo": `signupForm-cssmodule-territoryLogo-iUu1l`,
	"terms": `signupForm-cssmodule-terms-wXWh3`,
	"fieldsContainer": `signupForm-cssmodule-fieldsContainer-y8iHI`,
	"link": `signupForm-cssmodule-link-eLGUU`,
	"textLink": `signupForm-cssmodule-textLink-e65uP`
};
export default ___CSS_LOADER_EXPORT___;
