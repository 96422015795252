import { makeStyles } from "@material-ui/core";
import { CustomTheme } from "src/themes/themeTypes";

export const useSeparatorStyles = makeStyles((theme: CustomTheme) => ({
  separatorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  separator: {
    display: "flex",
    margin: "1rem 0",
    height: "0.125rem",
    background: theme.palette.grey[300],
    width: "30%",
  },
}));
