import React from "react";

import { SvgIcon } from "@material-ui/core";

export function AddCommentIcon() {
  return (
    <SvgIcon viewBox="0 0 22 22">
      <path
        d="M18.5327 15.2618L18.1951 14.893L18.0045 15.0675L18.0366 15.3239L18.5327 15.2618ZM15.3843 17.1921L15.6079 16.7449L15.4092 16.6456L15.2023 16.7265L15.3843 17.1921ZM18.1644 18.5822L17.9408 19.0294L18.1644 18.5822ZM18.8841 18.073L18.388 18.135V18.135L18.8841 18.073ZM20.5 10C20.5 11.8522 19.6456 13.5652 18.1951 14.893L18.8703 15.6306C20.4927 14.1455 21.5 12.177 21.5 10H20.5ZM11 2.5C16.3566 2.5 20.5 5.95621 20.5 10H21.5C21.5 5.20723 16.6891 1.5 11 1.5V2.5ZM1.5 10C1.5 5.95621 5.64341 2.5 11 2.5V1.5C5.31089 1.5 0.5 5.20723 0.5 10H1.5ZM11 17.5C5.64341 17.5 1.5 14.0438 1.5 10H0.5C0.5 14.7928 5.31089 18.5 11 18.5V17.5ZM15.2023 16.7265C13.9373 17.2209 12.5113 17.5 11 17.5V18.5C12.6339 18.5 14.1836 18.1983 15.5663 17.6578L15.2023 16.7265ZM18.388 18.135L15.6079 16.7449L15.1607 17.6394L17.9408 19.0294L18.388 18.135ZM18.388 18.135V18.135L17.9408 19.0294C18.6551 19.3866 19.4793 18.8035 19.3803 18.0109L18.388 18.135ZM18.0366 15.3239L18.388 18.135L19.3803 18.0109L19.0289 15.1998L18.0366 15.3239Z"
        fill="#717587"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5.54544C11.3515 5.54544 11.6364 5.83035 11.6364 6.1818L11.6357 9.36299L14.8182 9.36362C15.1696 9.36362 15.4545 9.64853 15.4545 9.99999C15.4545 10.3514 15.1696 10.6363 14.8182 10.6363L11.6357 10.6357L11.6364 13.8182C11.6364 14.1696 11.3515 14.4545 11 14.4545C10.6485 14.4545 10.3636 14.1696 10.3636 13.8182L10.363 10.6357L7.18182 10.6363C6.83037 10.6363 6.54546 10.3514 6.54546 9.99999C6.54546 9.64853 6.83037 9.36362 7.18182 9.36362L10.363 9.36299L10.3636 6.1818C10.3636 5.83035 10.6485 5.54544 11 5.54544Z"
        fill="#717587"
      />
    </SvgIcon>
  );
}
