import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import { CancelRideDialog } from "components/CancelRideDialog/CancelRideDialog";

import { TerritoryContext } from "contexts/TerritoryContext";

import { getCancellationReasons, deleteReservation } from "./actions.js";

function CancelRideContainer(props) {
  const { selectedTerritory } = useContext(TerritoryContext);
  const [open, setOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(null);
  const [comment, setComment] = useState(undefined);
  const [currentReason, setCurrentReason] = useState(null);

  const {
    cancellationReasons,
    getCancellationReasons,
    onDelete,
    renderButton,
    reservation,
  } = props;

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback((reset) => {
    setOpen(false);

    if (reset) {
      setIsCancelled(false);
    }
  }, []);

  const resetCurrentReason = useCallback(() => {
    if (isCancelled === false) {
      setCurrentReason(null);
    }
  }, [isCancelled]);

  const handleCancelReservation = useCallback((cancel) => {
    setIsCancelled(cancel);
    resetCurrentReason();
  }, []);

  const handleChange = useCallback((e) => {
    setCurrentReason(e.target.value);
  }, []);

  const handleChangeComment = useCallback((comment) => {
    setComment(comment.target.value);
  }, []);

  useEffect(() => {
    getCancellationReasons(selectedTerritory.territory_key);
    resetCurrentReason();
  }, [selectedTerritory]);

  return (
    <CancelRideDialog
      // INTERNAL METHODS
      handleCancelReservation={handleCancelReservation}
      handleChange={handleChange}
      handleChangeComment={handleChangeComment}
      handleClose={handleClose}
      handleOpen={handleOpen}
      // PROPS
      cancellationReasons={cancellationReasons}
      renderButton={renderButton}
      reservation={reservation}
      onDelete={onDelete}
      // STATE
      comment={comment}
      currentReason={currentReason}
      isCancelled={isCancelled}
      open={open}
    />
  );
}

const _mapState = (state, ownProps) => {
  return {
    cancellationReasons: state.rateRide.cancellationReasons,
    reservation: ownProps.reservation || state.reservation.reservation,
  };
};

const _mapDispatch = (dispatch) => ({
  getCancellationReasons: (selectedTerritoryKey) => {
    dispatch(getCancellationReasons(selectedTerritoryKey));
  },
  onDelete: (
    navigate,
    payload,
    recurringServicesEnabled,
    selectedTerritoryKey,
  ) => {
    dispatch(
      deleteReservation(
        navigate,
        payload,
        recurringServicesEnabled,
        selectedTerritoryKey,
      ),
    );
  },
});

export default connect(_mapState, _mapDispatch)(CancelRideContainer);
