import React from "react";

import { SvgIcon } from "@material-ui/core";

export function ArrivalIcon({
  className,
  fill = "#151515",
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <svg viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.7442 2.58824L11.2444 0H0V22H2.49877V12.9412H9.49531L9.99506 15.5294H18.7407V2.58824H11.7442Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
}
