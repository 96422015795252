import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function CreditCard(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 40 40"
      >
        <g fill="#151515">
          <g>
            <path
              d="M39.09 13.455v15.181c0 1.67-1.285 3.039-2.92 3.172l-.26.01H4.09c-1.668 0-3.038-1.285-3.17-2.92l-.01-.262V13.455h38.18zm-1.817 1.818H2.727v13.363c0 .628.424 1.157 1.001 1.315l.178.037.185.012h31.818c.69 0 1.261-.513 1.351-1.179l.013-.185V15.273zm-5.455 6.09c1.757 0 3.182 1.425 3.182 3.182 0 1.758-1.425 3.182-3.182 3.182-.595 0-1.165-.164-1.657-.465l-.163-.11-.158.11c-.41.25-.875.407-1.363.452l-.295.013c-1.757 0-3.182-1.424-3.182-3.182 0-1.757 1.425-3.181 3.182-3.181.596 0 1.166.164 1.659.466l.159.106.16-.106c.41-.251.874-.408 1.363-.453zm0 .91c-.568 0-1.1.208-1.512.579l-.303.274-.305-.273c-.414-.372-.948-.58-1.516-.58-1.255 0-2.273 1.017-2.273 2.272 0 1.256 1.018 2.273 2.273 2.273.568 0 1.102-.208 1.514-.578l.303-.272.304.271c.414.371.948.58 1.515.58 1.255 0 2.273-1.018 2.273-2.274 0-1.255-1.018-2.272-2.273-2.272zm4.091-15.91c1.67 0 3.039 1.286 3.171 2.921l.01.261v3.364H.91V9.545c0-1.669 1.285-3.038 2.92-3.17l.26-.011h31.82zm0 1.819H4.091c-.69 0-1.261.513-1.351 1.178l-.013.185v1.546h34.546V9.545c0-.627-.424-1.156-1.001-1.314l-.178-.037-.185-.012z"
              transform="translate(-597.000000, -123.000000) translate(597.000000, 123.000000)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default CreditCard;
