import { makeStyles } from "@material-ui/core";

export const useBadgeStyles = makeStyles(() => ({
  badgeContainer: {
    display: "flex",
    alignItems: "center",

    width: "fit-content",
    margin: "0.125rem 0.5rem 0.125rem 0",
    padding: "0.125rem 0.75rem",
    borderRadius: "1rem",

    background: "#e0e0e0",
  },
  badgeIcon: {
    display: "flex",
    alignItems: "center",

    margin: "0 0.25rem 0 0",
  },
  badgeLabel: {
    fontSize: "1rem",
  },
}));
