import { makeStyles } from "@material-ui/core";

export const useFixedLinesLineStyles = makeStyles((theme) => ({
  subtitle: {
    fontSize: "0.75rem",
    fontWeight: 500,
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    textAlign: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "3rem",
  },
}));
