import React from "react";
import { useIntl } from "react-intl";

import { useTheme, Dialog, IconButton, Typography } from "@material-ui/core";

import Cross from "components/Icons/Cross/Cross.jsx";

export default function DialogContainer(props) {
  const { title, children, onClose, classes, isClosable, ...otherProps } =
    props;

  const intl = useIntl();
  const theme = useTheme();

  // we want the top title to fusion its border-radiuses with top -and bottom if no content
  return (
    <Dialog
      open
      style={{ width: "100%", margin: "1%" }}
      onClose={isClosable && onClose}
      classes={classes}
      {...otherProps}
    >
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: "1rem",
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: !children ? theme.shape.borderRadius : 0,
          borderBottomRightRadius: !children ? theme.shape.borderRadius : 0,
        }}
      >
        <Typography
          variant="h1"
          align="center"
          style={{ color: theme.palette.textColor }}
        >
          {title}
        </Typography>
      </div>

      {onClose && isClosable && (
        <IconButton
          variant="outlined"
          aria-label={intl.formatMessage({ id: "misc.cancel" })}
          style={{ position: "absolute", right: 0, top: "0.5rem" }}
          onClick={onClose}
        >
          <Cross style={{ width: 20, height: 20 }} />
        </IconButton>
      )}

      {children && (
        <div className="column" style={{ padding: "1rem" }}>
          {children}
        </div>
      )}
    </Dialog>
  );
}
