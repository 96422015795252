import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";

import { getMainProfileKey } from "lib/genericSeatsFunctions";

import { CounterButtons } from "molecules/CounterButtons/CounterButtons";

import { updatePassengerProfile } from "containers/PassengersProfiles/actions";

import { usePassengerProfilesStyles } from "./passengerProfilesStyles";
import {
  PassengerProfileEnum,
  PassengerProfilesType,
  PassengerProfileType,
  Profile,
} from "types/passengerProfile";

type BookedProfilesIdsByKey = {
  [type: string]: {
    ids: string[];
    passengerProfileId: number;
  };
};

type ProfileInterface = {
  id: number;
  ageMin: number;
  ageMax: number;
  count: number;
};

type ProfileByKey = {
  [key: string]: ProfileInterface;
};

type ProfileByKeyUnion = BookedProfilesIdsByKey & ProfileByKey;

type PassengerProfilesProps = {
  mainProfileDefaultCount?: number;
  maxAllPassengersCount: number;
  onChange: (value: number, type: string) => void;
  passengerProfiles: ProfileByKeyUnion;
};

export type StateInterface = {
  passengersProfiles: {
    isLoading: boolean;
    profiles: PassengerProfilesType;
  };
};

export function PassengerProfiles(props: PassengerProfilesProps) {
  const {
    mainProfileDefaultCount,
    maxAllPassengersCount,
    onChange,
    passengerProfiles = {},
  } = props;

  const intl = useIntl();
  const classes = usePassengerProfilesStyles();
  const dispatch = useDispatch();
  const [mainProfileType, setMainProfileType] =
    useState<PassengerProfileType>(null);

  const { isLoading, profiles } = useSelector((state: StateInterface) => ({
    isLoading: state?.passengersProfiles?.isLoading,
    profiles: state?.passengersProfiles?.profiles,
  }));

  useEffect(() => {
    if (isLoading) {
      return;
    }
    // replace the above with the following:
    for (const key in passengerProfiles) {
      if (key in profiles) {
        profiles[key].count =
          passengerProfiles[key].count || passengerProfiles[key].ids.length;
      }
    }
  }, [isLoading, passengerProfiles]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const mainProfileKey = getMainProfileKey(profiles);

    if (mainProfileKey) {
      setMainProfileType(mainProfileKey);

      if (mainProfileDefaultCount) {
        profiles[mainProfileKey].count = mainProfileDefaultCount;
      }
    }
  }, [isLoading, mainProfileDefaultCount]);

  if (isLoading || !profiles) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  const getTotalPassenger = () => {
    return Object.values(profiles).reduce<number>(
      (acc: number, profileToAcc: Profile) => {
        acc += profileToAcc.count;
        return acc;
      },
      0,
    );
  };

  /**
   * Get year range of counter buttons
   * @param type
   */
  const getYearRangesOfCounterButtons = (type: PassengerProfileEnum) => {
    const minAge = profiles[type]?.ageMin || 0;
    const maxAge = profiles[type]?.ageMax || 0;

    if (type === PassengerProfileEnum.PASSENGER) {
      return "";
    }
    if (maxAge >= 120) {
      return intl.formatMessage(
        { id: "passenger_profile.age_over" },
        {
          ageMin: minAge,
        },
      );
    }
    if (minAge >= 0 && maxAge > 0) {
      return intl.formatMessage(
        { id: "passenger_profile.age_range" },
        {
          ageMin: minAge,
          ageMax: maxAge,
        },
      );
    }
  };

  const profileLabels = {
    PASSENGER: intl.formatMessage({ id: "search.nb_standards" }),
    ADULT: intl.formatMessage({ id: "passenger_profile.adult" }),
    CHILD: intl.formatMessage({ id: "passenger_profile.child" }),
    BABY: intl.formatMessage({ id: "passenger_profile.baby" }),
  };

  const getLabelOfCounterButtons = (type: PassengerProfileType) => {
    return type ? `${profileLabels[type] || profileLabels.PASSENGER}` : "";
  };

  const getMaxValueOfCounterButtons = (type: PassengerProfileType) => {
    return type
      ? maxAllPassengersCount + profiles[type].count - getTotalPassenger()
      : 0;
  };

  return (
    <>
      {Object.entries(profiles).map(
        ([type, profile]: [PassengerProfileEnum, Profile]) => (
          <CounterButtons
            key={profile.id}
            label={getLabelOfCounterButtons(type)}
            extraInfoLabel={getYearRangesOfCounterButtons(type)}
            minValue={0}
            maxValue={getMaxValueOfCounterButtons(type)}
            onChange={(value: number): void => {
              profile.count = value;
              dispatch(updatePassengerProfile(type, profile));
              onChange(value, type);
            }}
            defaultValue={profile.count}
            type={type}
          />
        ),
      )}
    </>
  );
}
