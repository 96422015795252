import * as React from "react";
import { useCallback, useContext, useState, ChangeEvent } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";

import { PhoneField } from "components/PhoneField/PhoneField";
import { IContact } from "organisms/EmergencyContacts/EmergencyContacts";

import { UserContext } from "contexts/UserContext";

import api from "api";

import { useEmergencyContactDialogStyles } from "./emergencyContactDialogStyles";

interface IEmergencyContactDialogProps {
  contact?: IContact;
  fetchContacts: () => void;
  onClose: () => void;
  textFieldLabel: string;
}

export function EmergencyContactDialog(props: IEmergencyContactDialogProps) {
  // If contact is undefined, we're in create mode, otherwise we're in edit mode
  const { contact, fetchContacts, onClose, textFieldLabel } = props;

  const [contactName, setContactName] = useState(contact?.name);
  const [contactPhoneNumber, setContactPhoneNumber] = useState(
    contact?.phoneNumber,
  );
  const [phoneErrorMessage, setPhoneErrorMessage] = useState(undefined);

  const { userProfile } = useContext(UserContext);
  const intl = useIntl();
  const classes = useEmergencyContactDialogStyles();

  const customerId = userProfile?.customerId;

  const handleSubmit = useCallback(() => {
    const createOrUpdatePromise = contact
      ? api.updateContact({
          customerId,
          contactId: contact.id,
          name: contactName,
          phone_number: contactPhoneNumber,
        })
      : api.createContact({
          customerId,
          name: contactName,
          phone_number: contactPhoneNumber,
          customer: customerId,
          is_emergency_contact: true,
          is_notify_contact: false,
        });

    createOrUpdatePromise
      .then(() => {
        fetchContacts();
        onClose();
      })
      .catch((error) => {
        const phoneNumberError = error?.infos?.extra?.fields?.phone_number;
        if (phoneNumberError) {
          setPhoneErrorMessage(error?.infos?.extra?.fields?.phone_number);
        } else {
          throw error;
        }
      });
  }, [
    contact,
    contactName,
    contactPhoneNumber,
    customerId,
    fetchContacts,
    onClose,
  ]);

  return (
    <Dialog className={classes.emergencyContactDialogContainer} open>
      <DialogTitle
        className={classes.emergencyContactDialogTitle}
        disableTypography
      >
        <Typography variant="h2">
          <FormattedMessage
            id="contact.emergency-contact"
            defaultMessage="Emergency contact"
          />
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.fieldsContainer}>
        <TextField
          className={classes.contactNameField}
          label={textFieldLabel}
          value={contactName}
          type="text"
          onChange={(e: ChangeEvent<HTMLInputElement>): void =>
            setContactName(e.target.value)
          }
        />

        <Typography className={classes.contactNameFieldInfo}>
          <FormattedMessage
            id="contact.contact-name-info"
            defaultMessage="This name will be visible only by yourself"
          />
        </Typography>

        <PhoneField
          required
          autocomplete="tel"
          label={intl.formatMessage({
            id: "signup.phoneNumber",
          })}
          value={contactPhoneNumber}
          type="tel"
          onChange={(e: string) => setContactPhoneNumber(e)}
        />

        {phoneErrorMessage && (
          <Typography className={classes.phoneErrorMessage}>
            {phoneErrorMessage}
          </Typography>
        )}
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          <FormattedMessage id="misc.cancel" />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          disabled={!contactPhoneNumber}
          onClick={handleSubmit}
        >
          <FormattedMessage id="misc.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
