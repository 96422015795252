import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const ExternalLink = (props) => {
  return (
    <SvgIcon
      {...props}
      aria-label="external link"
      x="0px"
      y="0px"
      viewBox="0 0 72 72"
    >
      <path
        d="M54,37c2.209,0,4,1.791,4,4v8c0,4.962-4.037,9-9,9H23c-4.963,0-9-4.038-9-9V23c0-4.962,4.037-9,9-9h8c2.209,0,4,1.791,4,4
	s-1.791,4-4,4h-8c-0.552,0-1,0.449-1,1v26c0,0.551,0.448,1,1,1h26c0.552,0,1-0.449,1-1v-8C50,38.791,51.791,37,54,37z"
      />
      <path
        id="overlay_15_"
        d="M56,12c2.209,0,4,1.791,4,4v13c0,2.209-1.791,4-4,4s-4-1.791-4-4v-3.343L40.828,36.829
	C40.048,37.609,39.023,38,38,38s-2.048-0.391-2.828-1.171c-1.562-1.562-1.562-4.095,0-5.657L46.343,20H43c-2.209,0-4-1.791-4-4
	s1.791-4,4-4H56z"
      />
    </SvgIcon>
  );
};
