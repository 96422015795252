export const TEXT_CONTENTS_EN = {
  // BLOCK 1:
  "block1-h2": "Accessibility",

  "block1-p1": "This page is not a help page.",
  "block1-p2":
    "It aims to present the accessibility policy of the website “{websiteURL}” and to define the general level of accessibility observed on the website in accordance with the regulations and standards in force.",

  "block1-h3": "What is digital accessibility?",

  "block1-p3":
    "Digital accessibility is a set of rules and good practices that cover functional, graphic, technical and editorial aspects.",
  "block1-p4":
    "Following these rules and good practices ensures that digital media (websites, mobile applications, PDF documents, etc.) are accessible to people with disabilities.",
  "block1-p5": "An accessible website allows, for example, to :",

  "block1-li1":
    "Customise its display via the operating system and/or browser (enlarge or shrink characters, change typography, change colours, stop animations, etc.).",
  "block1-li2":
    "Navigate using assistive technologies such as text-to-speech or Braille.",
  "block1-li3":
    "Navigate without using the mouse, with the keyboard only, switches or via a touch screen.",
  "block1-li4":
    "View video and audio content with subtitles and/or transcripts.",
  "block1-li5": "Etc.",

  // BLOCK 2:
  "block2-h2": "Accessibility statement: {brand}",

  "block2-p1":
    "{brand} is committed to make its websites (internet, intranet and extranet), mobile applications, software packages and digital street furniture accessible in accordance with the article 47 of the French Law n°2005-102 of February, 11 2005.",
  "block2-p2":
    "This accessibility statement applies to the website “{websiteURL}”.",

  "block2-h3": "Compliance status",

  "block2-p3":
    "The website “{websiteURL}“ is partially compliant with WCAG 2.1 (level AA), and {a11yOverallRate} compliant with RGAA version 4.1, due to the non-conformities and deviations listed below.",

  // BLOCK 3:
  "block3-h2": "Test results",

  "block3-p1a":
    "An audit of compliance with the RGAA version 4.1 was carried out in {auditDate} by the company ",
  "block3-p1b":
    ". It was followed by a self-audit in {autoAuditDate} after integrating the corrections reported by Ideance. This self-audit carried out by Padam Mobility reveals that on the sample :",

  "block3-li1":
    "The overall compliance rate is {a11yOverallRate}. This rate is obtained by dividing the number of compliant criteria by the number of applicable criteria.",
  "block3-li2":
    "The average compliance rate is {a11yAverageRate}. This rate is obtained by averaging the compliance rates of each page.",

  "block3-h3": "Non-accessible content",

  "block3-h4-1": "Non-compliances",

  "block3-p2": "List of non-compliant criteria:",

  "block3-criterion-1-1":
    "1.1 Does every image that carries information have a textual alternative?",
  "block3-criterion-1-2":
    "1.2 Is each decorative image correctly ignored by assistive technologies?",
  "block3-criterion-1-3":
    "1.3 For each image that carries information with a textual alternative, is this alternative relevant (excluding special cases)?",
  "block3-criterion-3-2":
    "3.2 In each web page, is the contrast between the colour of the text and the colour of its background sufficiently high (excluding special cases)?",
  "block3-criterion-6-1":
    "6.1 Is every link explicit (excluding special cases)?",
  "block3-criterion-7-1":
    "7.1 Is each script, where necessary, compatible with assistive technologies?",
  "block3-criterion-7-3":
    "7.3 Is each script controllable by the keyboard and by any pointing device (except in special cases)?",
  "block3-criterion-7-5":
    "7.5 In each web page, are status messages correctly rendered by assistive technologies?",
  "block3-criterion-8-2":
    "8.2 For each web page, is the generated source code valid for the specified document type (excluding special cases)?",
  "block3-criterion-8-6":
    "8.6 For each web page with a page title, is the title relevant?",
  "block3-criterion-8-7":
    "8.7 In each web page, is each language change indicated in the source code (excluding special cases)?",
  "block3-criterion-8-9":
    "8.9 In every web page, tags should not be used for presentation purposes only. Is this rule respected?",
  "block3-criterion-9-2":
    "9.2 In each web page, is the structure of the document coherent (excluding special cases)?",
  "block3-criterion-9-3":
    "9.3 Is every list on every web page correctly structured?",
  "block3-criterion-10-7":
    "10.7 In each web page, for each element receiving focus, is the focus capture visible?",
  "block3-criterion-10-8":
    "10.8 For each web page, is hidden content intended to be ignored by assistive technologies?",
  "block3-criterion-10-11":
    "10.11 For each web page, can the contents be presented without using vertical scrolling for a window with a height of 256px or horizontal scrolling for a window with a width of 320px (excluding special cases)?",
  "block3-criterion-10-12":
    "10.12 In each web page, can the text spacing properties be redefined by the user without loss of content or functionality (excluding special cases)?",
  "block3-criterion-11-1": "11.1 Does each form field have a label?",
  "block3-criterion-11-3":
    "11.3 In each form, is each label associated with a form field that has the same function and is repeated several times within a page or set of pages consistent?",
  "block3-criterion-11-5":
    "11.5 In each form, are fields of the same nature grouped together, if necessary?",
  "block3-criterion-11-9":
    "11.9 In each form, is the title of each button relevant (excluding special cases)?",
  "block3-criterion-11-10":
    "11.10 In each form, is the input control used appropriately (excluding special cases)?",
  "block3-criterion-11-13":
    "11.13 Can the purpose of an input field be inferred to facilitate automatic filling of fields with user data?",
  "block3-criterion-12-6":
    "12.6 Can content grouping areas on several web pages (header, main navigation, main content, footer and search engine areas) be reached or avoided?",
  "block3-criterion-12-7":
    "12.7 Is there an avoidance or quick access link to the main content area on each web page (excluding special cases)?",

  "block3-h4-2": "Derogations for disproportionate burden",

  "block3-h5-1": "Main navigation menu",

  "block3-p-3a":
    "The main drop-down navigation menu in the connected part of the website follows in part the ",
  "block3-p-3b": "ARIA design pattern “Menu or Menu bar”",
  "block3-p-4":
    "Which is not optimal because this design pattern is not intended for this type of navigation menu.",
  "block3-p-5":
    "Also, the HTML structuring of this menu has some slight imperfections.",
  "block3-p-6":
    "However, this derogation is possible because this menu is perfectly usable with the keyboard alone and with a screen reader.",

  "block3-h5-2": "Breadcrumb trail",

  "block3-p-7":
    "From a certain screen width (800px and less), for functional and graphical reasons, it has been chosen not to display the breadcrumb trail.",
  "block3-p-8": "This could invalidate criterion 10.11.",
  "block3-p-9":
    "However, this derogation is possible because the breadcrumb trail is a non-essential feature for the proper use of the website.",
  "block3-p-10":
    "Also, and more importantly, there are other alternative ways to find out where you are in the website and then return to previously viewed pages.",

  "block3-h5-3": '"Clock" feature',

  "block3-p-11":
    'The "clock" feature, which allows to fill in the "time" fields on the ride search page for example, is not accessible, particularly with the keyboard alone and with a screen reader.',
  "block3-p-12":
    'However, this derogation is possible because it is possible to fill in these "time" fields by hand, without necessarily using this feature.',
  "block3-p-13": "Which is an accessible alternative.",

  "block3-h4-3": "Content not subject to the accessibility obligation",

  "block3-p-14": "The interactive Google Maps on the booking details pages.",

  // BLOCK 3 UK (Specific to United Kingdom):

  "block3-uk-p-2":
    "We list below some of the items where this website may not be fully compliant.",

  "block3-uk-li-1":
    "The ability for each displayed image to have an alternative text description",

  "block3-uk-li-2":
    "The ability to ensure a sufficient high contrast between text colour and background colour",

  "block3-uk-li-3":
    "The ability to adjust text spacing properties on a user by user basis",

  "block3-uk-li-4": "The automatic filling of all input fields with user data.",

  // BLOCK 4:
  "block4-h2": "Preparation of this accessibility declaration",

  "block4-p-1": "This declaration was issued on the {autoAuditDate}.",

  "block4-h3-1": "Technologies used to create the website",

  "block4-h3-2": "Test environment",

  "block4-p-2":
    "The tests were conducted with the following web browser and screen reader combinations:",

  "block4-h3-3": "Tools for assessing accessibility",

  "block4-li-12": "Firefox development tools",
  "block4-li-13": "Web Developer (Firefox extension)",

  "block4-h3-4":
    "Pages of the website that have been subject to the compliance check",

  "block4-li-14": "Log in",
  "block4-li-15": "Sign up",
  "block4-li-16": "Search for rides",
  "block4-li-17": "Suggested rides",
  "block4-li-18": "Booking",
  "block4-li-19": "Account settings",

  // BLOCK 5:
  "block5-h2": "Feedback and contact",

  "block5-p-1":
    "If you are unable to access any content or service on this website, you may contact us to be directed to an accessible alternative or to obtain the content in another form. Write to us by post at the following address:",
  "block5-p-2": "Write to us by post at the following address:",

  "block5-h3": "Remedies",

  "block5-p-3": "This procedure should be used in the following case.",
  "block5-p-4":
    "You have notified the website operator of a lack of accessibility that prevents you from accessing content or services and have not received a satisfactory response.",

  "block5-li-1":
    "Send a message to the French Human Rights Defender (in French)",
  "block5-li-2":
    "Contact the delegate of the Human Rights Defender in your region (in French)",
  "block5-li-3":
    "Send a letter by post (free of charge, do not put a stamp) to the following address:",

  // BLOCK 5 UK (Specific to United Kingdom):

  "block5-uk-p-2": "Write to us via:",

  "block5-uk-p-3":
    "is responsible for enforcing the accessibility regulations. If you're not happy with how we respond to your complaint, please contact the ",

  "block5-uk-li-1": "The Equality and Human Rights Commission (EHRC) ",

  "block5-uk-li-2": "Equality Advisory and Support Services (EASS).",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Appendix",
};
