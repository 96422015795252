import React from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

import { useTheme, Breadcrumbs, Typography } from "@material-ui/core";

import ChevronRight from "components/Icons/ChevronRight";

import useWindowWidth from "lib/useWindowWidth";
import styles from "./breadcrumb.cssmodule.scss";

export default function Breadcrumb(props) {
  const { data } = props;

  const intl = useIntl();
  const theme = useTheme();
  const width = useWindowWidth();

  const textColor = theme.palette.textColor;

  if (_.isEmpty(data)) return null;

  return (
    <Breadcrumbs
      maxItems={width >= 1000 ? 8 : 2}
      separator={
        <ChevronRight style={{ fill: textColor, height: 30, width: 30 }} />
      }
      aria-label={intl.formatMessage({ id: "breadcrumb.navigation" })}
    >
      {data.map((link, i) => {
        const last = i === data.length - 1;

        return !last ? (
          <Link
            key={`breadcrumb-link-${i}`}
            className="row-only aligned"
            to={link.path}
            tabIndex={0}
          >
            {link.icon}
            <Typography
              className={styles.font}
              style={{ color: textColor, textDecoration: "underline" }}
            >
              {link.title}
            </Typography>
          </Link>
        ) : (
          <div key={`breadcrumb-link-${i}`} className="row-only aligned">
            {link.icon}
            <Typography style={{ color: textColor }} className={styles.font}>
              {link.title}
            </Typography>
          </div>
        );
      })}
    </Breadcrumbs>
  );
}
