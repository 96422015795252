import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

export const Notification = (props) => {
  return (
    <SvgIcon {...props} aria-label="external link" x="0px" y="0px">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 5.02974C6.91216 5.64707 5 7.82452 5 10.4179V14.1267C5 14.4684 4.70058 14.7455 4.33403 14.7455C3.59727 14.7455 3 15.3004 3 15.9818V16.91C3 17.0802 3.14818 17.2182 3.33418 17.2182H18.6658C18.8504 17.2182 19 17.0782 19 16.91V15.9818C19 15.299 18.3999 14.7455 17.666 14.7455C17.2982 14.7455 17 14.4706 17 14.1267V10.4179C17 7.82555 15.0882 5.64728 12.5 5.02976V4.39117C12.5 3.62104 11.8284 3 11 3C10.1733 3 9.5 3.62285 9.5 4.39117V5.02974ZM8.66667 17.8364H13.3333C13.3333 19.0313 12.2887 20 11 20C9.71134 20 8.66667 19.0313 8.66667 17.8364Z"
        fill="#6C6C6C"
      />
    </SvgIcon>
  );
};
