import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function ActionInfo(props) {
  return (
    <SvgIcon {...props}>
      <path
        fill={props.fill || "#000"}
        d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0zm0 1C5.477 1 1 5.477 1 11s4.477 10 10 10 10-4.477 10-10S16.523 1 11 1zm0 8c.552 0 1 .448 1 1v5c0 .552-.448 1-1 1s-1-.448-1-1v-5c0-.552.448-1 1-1zm0-3c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z"
        transform="translate(-1429 -255) translate(1409 70) translate(20 185)"
      />
    </SvgIcon>
  );
}

export default ActionInfo;
