import cookie from "lib/cookie.js";
import { settings } from "config/app";

/**
 * Get impersonate session bool from state
 * or cookie (to recover refreshes)
 *
 * @param  object state
 * @return
 */
export const getImpersonateSession = (state) => {
  const fromState = state.sessionStarted;
  const fromCookie = cookie.get(settings.cookieKeys.impersonateCookie);

  return fromState || !!fromCookie;
};
