import React from "react";

import { SvgIcon } from "@material-ui/core";

// Not used as an icon but has an illustration for 404 page:
export function Error404(props) {
  const { className } = props;

  return (
    <SvgIcon
      className={className}
      width="658"
      height="291"
      viewBox="0 0 658 291"
    >
      <svg>
        <defs>
          <path
            id="emx4xyek6b"
            d="M175 188.426c96.65 0 175-7.835 175-17.5 0-3.832-32.945-167.617-53.841-170.5C264.359-3.96 233.324 26.951 175 26.951c-34.592 0-91.537 18.86-118.687 20.592C7.605 50.65 0 164.721 0 170.926c0 9.665 78.35 17.5 175 17.5z"
          />

          <path
            id="ljqssw21ne"
            d="M113.906 175.487c5.733 0 10.391-1.648 13.975-4.945 3.583-3.296 5.375-8.17 5.375-14.62v-7.31h4.73c12.47 0 18.705-5.088 18.705-15.265 0-9.89-6.235-14.835-18.705-14.835h-4.73V40.897c0-6.163-1.935-10.965-5.805-14.405-3.87-3.44-8.529-5.16-13.975-5.16-3.297 0-6.486.753-9.568 2.258-3.081 1.505-5.626 3.69-7.632 6.557l-61.49 87.72c-3.154 4.444-4.73 9.174-4.73 14.19 0 4.73 1.505 8.672 4.515 11.825 3.01 3.154 7.095 4.73 12.255 4.73H94.77v7.31c0 6.45 1.756 11.324 5.267 14.62 3.512 3.297 8.134 4.945 13.868 4.945zM94.77 118.512h-25.8l25.8-37.195v37.195z"
          />

          <path
            id="z7w4atjreg"
            d="M363.5 173.361c18.777 0 33.11-6.665 43-19.995 9.89-13.33 14.835-32.608 14.835-57.835 0-25.083-4.98-44.29-14.942-57.62-9.962-13.33-24.26-19.995-42.893-19.995-18.777 0-33.11 6.665-43 19.995-9.89 13.33-14.835 32.537-14.835 57.62 0 25.227 4.945 44.505 14.835 57.835 9.89 13.33 24.223 19.995 43 19.995zm0-29.885c-6.88 0-11.825-3.726-14.835-11.18-3.01-7.453-4.515-19.708-4.515-36.765 0-17.056 1.505-29.275 4.515-36.657 3.01-7.382 7.955-11.073 14.835-11.073 6.88 0 11.825 3.727 14.835 11.18 3.01 7.454 4.515 19.637 4.515 36.55 0 17.057-1.505 29.312-4.515 36.765-3.01 7.454-7.955 11.18-14.835 11.18z"
          />

          <path
            id="hlmmzgxm7i"
            d="M516.645 172.716c5.733 0 10.392-1.648 13.975-4.945 3.583-3.296 5.375-8.17 5.375-14.62v-7.31h4.73c12.47 0 18.705-5.088 18.705-15.265 0-9.89-6.235-14.835-18.705-14.835h-4.73V38.126c0-6.163-1.935-10.965-5.805-14.405-3.87-3.44-8.528-5.16-13.975-5.16-3.297 0-6.486.753-9.568 2.258-3.081 1.505-5.625 3.69-7.632 6.557l-61.49 87.72c-3.153 4.444-4.73 9.174-4.73 14.19 0 4.73 1.505 8.672 4.515 11.825 3.01 3.154 7.095 4.73 12.255 4.73h47.945v7.31c0 6.45 1.756 11.324 5.267 14.62 3.512 3.297 8.135 4.945 13.868 4.945zm-19.135-56.975h-25.8l25.8-37.195v37.195z"
          />

          <filter
            id="u6je5sthbd"
            width="103.2%"
            height="102.6%"
            x="-2.4%"
            y="-.6%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dx="-2"
              dy="2"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />

            <feColorMatrix
              in="shadowOffsetOuter1"
              values="0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0.9 0"
            />
          </filter>

          <filter
            id="jwlsu1ehmf"
            width="104.3%"
            height="103.9%"
            x="-3%"
            y="-1%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dx="-2"
              dy="3"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />

            <feColorMatrix
              in="shadowOffsetOuter1"
              values="0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0.9 0"
            />
          </filter>

          <filter
            id="g48wd8xwzh"
            width="103.9%"
            height="103.9%"
            x="-2.8%"
            y="-1%"
            filterUnits="objectBoundingBox"
          >
            <feOffset
              dx="-2"
              dy="3"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            />

            <feColorMatrix
              in="shadowOffsetOuter1"
              values="0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0 0.0806442482 0 0 0 0.9 0"
            />
          </filter>

          <radialGradient
            id="1fgjexavba"
            cx="50%"
            cy="50%"
            r="50%"
            fx="50%"
            fy="50%"
            gradientTransform="matrix(0 1 -1.00224 0 1.001 0)"
          >
            <stop offset="0%" />
            <stop offset="65.134%" />
            <stop offset="100%" stopColor="#4A4A4A" />
          </radialGradient>
        </defs>

        <g fill="none" fillRule="evenodd">
          <g>
            <ellipse
              cx="205"
              cy="224.5"
              fill="url(#1fgjexavba)"
              rx="175"
              ry="17.5"
            />

            <g>
              <g transform="translate(30 53.574)">
                <mask id="2aywkgjxsc" fill="#fff">
                  <use xlinkHref="#emx4xyek6b" />
                </mask>

                <g mask="url(#2aywkgjxsc)">
                  <g>
                    <g
                      fillRule="nonzero"
                      transform="translate(115.74 40.229) rotate(-30 93.373 98.41)"
                    >
                      <use
                        fill="#000"
                        filter="url(#u6je5sthbd)"
                        xlinkHref="#ljqssw21ne"
                      />

                      <use fill="#717587" xlinkHref="#ljqssw21ne" />
                    </g>

                    <path
                      fill="#151515"
                      fillOpacity=".23"
                      d="M39.688 122.585c13.761-6.258 31.119-9.388 52.073-9.388s44.781 4.269 71.482 12.805l25.56 28.378-175.535 15.586 26.42-47.38z"
                      transform="translate(115.74 40.229)"
                    />
                  </g>
                </g>
              </g>

              <g fillRule="nonzero" transform="translate(30 53.574)">
                <use
                  fill="#000"
                  filter="url(#jwlsu1ehmf)"
                  xlinkHref="#z7w4atjreg"
                />

                <use fill="#717587" xlinkHref="#z7w4atjreg" />
              </g>

              <g fillRule="nonzero" transform="translate(30 53.574)">
                <use
                  fill="#000"
                  filter="url(#g48wd8xwzh)"
                  xlinkHref="#hlmmzgxm7i"
                />
                <use fill="#717587" xlinkHref="#hlmmzgxm7i" />
              </g>
            </g>

            <path d="M0 0H658V291H0z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
