import React from "react";

import { Typography } from "@material-ui/core";

export function A11yDeclarationBlock4(props) {
  const { autoAuditDate, textContents } = props;

  return (
    <>
      <Typography component="h2">{textContents["block4-h2"]}</Typography>

      <Typography component="p">
        {textContents["block4-p-1"].replace("{autoAuditDate}", autoAuditDate)}
      </Typography>

      <Typography component="h3">{textContents["block4-h3-1"]}</Typography>

      <ul>
        <Typography component="li">{"HTML5"}</Typography>

        <Typography component="li">{"CSS3"}</Typography>

        <Typography component="li">{"JavaScript"}</Typography>

        <Typography component="li">{"ARIA"}</Typography>

        <Typography component="li">{"SVG"}</Typography>
      </ul>

      <Typography component="h3">{textContents["block4-h3-2"]}</Typography>

      <Typography component="p">{textContents["block4-p-2"]}</Typography>

      <ul>
        <Typography component="li">{"Firefox 93.0 / NVDA 2021.2"}</Typography>

        <Typography component="li">{"Firefox 93.0 / JAWS 2020"}</Typography>

        <Typography component="li">
          {"Safari / VoiceOver (macOS 11.5.2)"}
        </Typography>

        <Typography component="li">
          {"Safari / VoiceOver (iOS 15.0.1)"}
        </Typography>
      </ul>

      <Typography component="h3">{textContents["block4-h3-3"]}</Typography>

      <ul>
        <Typography component="li">{"Colour Contrast Analyzer"}</Typography>

        <Typography component="li">{"Contrast Finder"}</Typography>

        <Typography component="li">{textContents["block4-li-12"]}</Typography>

        <Typography component="li">{textContents["block4-li-13"]}</Typography>
      </ul>

      <Typography component="h3">{textContents["block4-h3-4"]}</Typography>

      <ul>
        <Typography component="li">{textContents["block4-li-14"]}</Typography>

        <Typography component="li">{textContents["block4-li-15"]}</Typography>

        <Typography component="li">{textContents["block4-li-16"]}</Typography>

        <Typography component="li">{textContents["block4-li-17"]}</Typography>

        <Typography component="li">{textContents["block4-li-18"]}</Typography>

        <Typography component="li">{textContents["block4-li-19"]}</Typography>
      </ul>
    </>
  );
}
