import React from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { WaitingList } from "api/types/waitingList";

import { cn } from "utils/classes";

import { Typography } from "@/components/ui/typography";

type UnvalidatedWaitingListStatus = Exclude<
  WaitingList["waiting_list_status"],
  "VALIDATED"
>;

interface UnvalidatedWaitingListStatusProps {
  status: UnvalidatedWaitingListStatus;
}

const baseClasses = "text-sm rounded-md whitespace-nowrap p-2";

export function UnvalidatedWaitingListStatus({
  status,
}: UnvalidatedWaitingListStatusProps) {
  if (status === "PENDING") {
    return (
      <Typography className={cn(baseClasses, "bg-[#ecb35f]")}>
        <FormattedMessage id="LABEL_IN_WAITING_LIST" />
      </Typography>
    );
  }

  return (
    <Typography className={cn(baseClasses, "bg-[#edbecf]")}>
      <FormattedMessage id="LABEL_NO_AVAILABILITY" />
    </Typography>
  );
}
