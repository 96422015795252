import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePaperLinkStyles = makeStyles((theme: CustomTheme) => ({
  link: {
    "&:hover": {
      color: theme.palette.info.main,

      cursor: "pointer",
    },
  },
  linkText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  newTabIcon: {
    color: theme.palette.info.main,
  },
  paperLinkContainer: {
    padding: "1rem 1.5rem",
    borderRadius: "1rem",
  },
}));
