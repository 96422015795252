import React from "react";
import { useIntl } from "react-intl";

import PaymentContainer from "containers/Payment/PaymentContainer";
import BookingValidationResult from "containers/BookingValidation/BookingValidationResult";

export function RidePaymentView() {
  const intl = useIntl();

  return (
    <>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "payment.title" })}
      </h1>

      <PaymentContainer />

      <BookingValidationResult />
    </>
  );
}
