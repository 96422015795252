import React from "react";

import { Button, Link, Paper, Typography } from "@material-ui/core";

import { OpenNewTabIcon } from "icons/OpenNewTabIcon";

import { useContactCardStyles } from "./contactCardStyles";

export function ContactCard(props) {
  const {
    contactMethodName,
    contactToCopy,
    copyBtnLabel,
    formUrl,
    formLinkLabel,
    iconElement,
  } = props;

  const classes = useContactCardStyles();

  return (
    <Paper className={classes.contactCardContainer}>
      <Typography className={classes.contactMethodTitle}>
        <span className={classes.contactMethodIcon}>{iconElement}</span>

        <span>{contactMethodName}</span>
      </Typography>

      {contactToCopy && (
        <>
          <Typography className={classes.contactToCopy}>
            {contactToCopy}
          </Typography>

          <Button
            className={classes.copyBtn}
            onClick={() => navigator.clipboard.writeText(contactToCopy)}
          >
            <Typography className={classes.copyBtnLabel}>
              {copyBtnLabel}
            </Typography>
          </Button>
        </>
      )}

      {formUrl && (
        <Link className={classes.link} href={formUrl} target="_blank">
          <Typography className={classes.linkText}>
            <span>{formLinkLabel}</span>

            <span className={classes.newTabIcon}>
              <OpenNewTabIcon />
            </span>
          </Typography>
        </Link>
      )}
    </Paper>
  );
}
