import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Lock(props) {
  return (
    <SvgIcon {...props}>
      <svg>
        <path
          d="M18.0520013,10.1371117 L17.4773616,10.1373339 L17.4734018,7.40155643 C17.4717713,4.42155677 14.9293345,1.99844594 11.805736,2.00000075 C8.68213753,2.00155705 6.14226297,4.42689011 6.14389349,7.40711199 L6.14785331,10.1428894 L5.94450495,10.1428894 C4.86999639,10.1433339 3.99930205,10.9748894 4.00000042,12.0000007 L4.00163135,20.1448883 C4.00209721,21.1699993 4.87372328,22.0006659 5.94823184,22.0000007 L18.0554952,21.9942214 C19.1300038,21.993777 20.0006981,21.1622215 20.0000004,20.1371105 L19.9983688,11.9922226 C19.9979029,10.9671116 19.1265098,10.1366672 18.0520013,10.1371117 Z M12.5322464,16.3888887 L12.533411,18.6957774 L11.6520019,18.6962218 L11.6508372,16.3893332 C11.1663417,16.2179999 10.8190423,15.777111 10.818809,15.2553333 C10.8183435,14.5851112 11.3878586,14.0413334 12.0903771,14.041111 C12.7928956,14.0406668 13.3628766,14.5840001 13.3631097,15.2542222 C13.3633425,15.7757777 13.0165089,16.217111 12.5322464,16.3888887 Z M15.3896052,10.138445 L8.2356097,10.1420006 L8.23164947,7.4062231 C8.23071816,5.52466776 9.83467782,3.99289016 11.8069007,3.99200088 C13.7791235,3.99111238 15.3847137,5.52133443 15.3856454,7.40288976 L15.3896052,10.138445 Z"
          id="path-1"
        ></path>

        <g
          id="icons/illustration/password"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="Shape" fill="#717587" xlinkHref="#path-1"></use>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Lock;
