import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { DropDown } from "components/organisms/DropDown/DropDown";

import { switchTerritoryDialogStyles } from "./switchTerritoryDialogStyles";

// To obtain a good UX, territories are displayed as:
// - buttons if there are 5 or less territories
// - a dropdown menu otherwise
const MAX_TERRITORIES_TO_DISPLAY_AS_BUTTONS = 5;

export default function SwitchTerritoryDialog(props) {
  const {
    availableTerritories,
    currentTerritoryKey,
    handleCloseDialog,
    selectTerritory,
  } = props;

  const classes = switchTerritoryDialogStyles();

  const currentTerritory = availableTerritories.find(
    (territory) => territory.territory_key === currentTerritoryKey,
  );

  const dropDownTerritoriesValues = availableTerritories.map((item) => ({
    id: item.territory_key,
    display: item.geography.area_name,
  }));

  if (!availableTerritories) {
    return null;
  }

  return (
    <Dialog
      className={classes.dialogContainer}
      open
      onClose={handleCloseDialog}
      data-testid="switch-territory-dialog"
    >
      <div className={classes.dialogTitle}>
        <Typography component="h2">
          {currentTerritoryKey ? (
            <FormattedMessage
              id={"menu.switch_territory"}
              defaultMessage={"Switch a service territory"}
            />
          ) : (
            <FormattedMessage
              id={"menu.choose_territory"}
              defaultMessage={"Choose a territory"}
            />
          )}
        </Typography>
      </div>

      {currentTerritoryKey && (
        <Typography className={classes.currentTerritoryMessage}>
          <FormattedMessage
            id="menu.territory_current"
            defaultMessage="Votre territoire desservi"
          />{" "}
          : <b>{currentTerritory?.geography?.area_name}</b>
        </Typography>
      )}

      <DialogContent className={classes.availableTerritoriesContainer}>
        {availableTerritories.length > MAX_TERRITORIES_TO_DISPLAY_AS_BUTTONS ? (
          <DropDown
            defaultId={currentTerritoryKey}
            label={
              <FormattedMessage id="territories" defaultMessage="Territoires" />
            }
            onChange={selectTerritory}
            values={dropDownTerritoriesValues}
          />
        ) : (
          availableTerritories.map((territory) => {
            return (
              <Button
                variant={`${
                  territory.territory_key === currentTerritoryKey
                    ? "contained"
                    : "outlined"
                }`}
                onClick={() => selectTerritory(territory.territory_key)}
                key={territory.territory_id}
              >
                {territory.geography.area_name}
              </Button>
            );
          })
        )}
      </DialogContent>

      <Alert className={classes.infoMessage} severity="info">
        <Typography variant="body2">
          {<FormattedMessage id="menu.territory_info" />}
        </Typography>
      </Alert>
    </Dialog>
  );
}
