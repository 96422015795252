import React from "react";
import { connect } from "react-redux";
import { formatNode } from "containers/Autocomplete/actions.js";
import { ListItem, Typography } from "@material-ui/core";
import BusStop from "components/Icons/BusStop";

function NodeSuggestions(props) {
  const { nodes, onSelect, startIndex, highlightedIndex } = props;

  // (i+startIndex) is the index in all the autocomplete list
  return _.map(nodes, (p, i) => (
    <ListItem
      button
      name={`node-${i}`}
      key={i}
      alignItems="center"
      onMouseDown={() => onSelect(formatNode(p))}
      selected={i + startIndex === highlightedIndex}
      // ACCESSIBILITY
      role="option"
      id={"suggestion-" + (i + startIndex)}
      aria-selected={i + startIndex === highlightedIndex}
      tabIndex={-1}
    >
      <BusStop
        style={{ marginRight: "0.5em", width: "1rem", height: "1rem" }}
      />
      <div
        className="row-only aligned fullWidth"
        style={{ justifyContent: "space-between" }}
      >
        <Typography variant="body1">{p.name}</Typography>
        {/*<Typography variant="body2">
							{p.district}
						</Typography>*/}
      </div>
    </ListItem>
  ));
}
const mapStateToProps = (state) => ({
  nodes: state.autocomplete.nodes,
  isRequestingNodes: state.autocomplete.isRequestingNodes,
  startIndex: state.autocomplete.geocodes.length,
  highlightedIndex: state.autocomplete.highlightedIndex,
});

export default connect(mapStateToProps)(NodeSuggestions);
