import api from "api";
import { favoriteUpdated } from "store/actions.js";
import { snackMessage } from "containers/SnackBar/actions.js";

export const ON_FIELD_CHANGE = "@@favorite/ON_FIELD_CHANGE";
export const FORM_ERRORS = "@@favorite/FORM_ERRORS";
export const ON_ADDRESS_SELECTED = "@@favorite/ON_ADDRESS_SELECTED";
export const SUBMITTING = "@@favorite/SUBMITTING";

export const submitting = () => ({
  type: SUBMITTING,
});

export const onFieldChange = (field, value) => ({
  type: ON_FIELD_CHANGE,
  field,
  value,
});

export const onAddressSelected = (address, placeId, addressType) => ({
  type: ON_ADDRESS_SELECTED,
  address,
  placeId,
  addressType,
});

export const formErrors = (errors) => ({
  type: FORM_ERRORS,
  errors,
});

export const saveFavorites =
  (pickup, dropoff, selectedTerritoryKey) => (dispatch, getState) => {
    dispatch(submitting());

    const placeIds = getState().favoriteForm.placeIds;

    const payload = {
      home: {
        address: pickup,
        google_place_id: placeIds.pickup,
      },
      office: {
        address: dropoff,
        google_place_id: placeIds.dropoff,
      },
    };

    api
      .setFavorite(payload, { territory: selectedTerritoryKey })
      .then((json) => {
        dispatch(snackMessage("success", json.message));
        dispatch(favoriteUpdated(json.favorite));
      })
      .catch((error) => {
        if (error?.infos?.detail?.message) {
          dispatch(snackMessage("error", error.infos.detail.message));
        }
        dispatch(formErrors(error.infos));
      });
  };
