import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useResultElementStyles = makeStyles((theme: CustomTheme) => ({
  badgesContainer: {
    margin: "0.75rem 0 0 0",
  },
  card: {
    position: "relative",
    cursor: "pointer",
    margin: 0,
    padding: "1rem",
  },
  cardWithDiscount: {
    padding: "2.5rem 1rem 1rem 1rem",
  },
  discountTicketIcon: {
    padding: "0.25rem 0.5rem",
  },
  ticket: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,

    width: "fit-content",
    padding: "0rem 1rem",
    borderRadius: "0 0 1.5rem 0",

    background: theme.palette.primary.main,

    [theme.breakpoints.up("sm")]: {
      right: 0,
      left: "unset",

      borderRadius: "0 0 0 1.5rem",
    },
  },
  ticketDetails: {
    color: theme.palette.textColor,
    fontSize: "1rem",
    textTransform: "none",
  },
}));
