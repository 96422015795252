import React, { useContext } from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { AuthModal, AuthModalsContext } from "contexts/AuthModalsContext";

import { Message } from "atoms/Message";

import { Button } from "components/ui/button";

export function SignInToJoin() {
  const { showAuthModal } = useContext(AuthModalsContext);

  return (
    <>
      <Message variant="info">
        <FormattedMessage id="TEXT_WAITING_LIST_LOG_IN" />
      </Message>
      <Button variant="outline" onClick={() => showAuthModal(AuthModal.SignIn)}>
        <FormattedMessage id="signin.connexion" />
      </Button>
    </>
  );
}
