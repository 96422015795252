import api from "api";

// 100km radius is the current limit we use when building the matrices of a new territory
const RADIUS = 100000; // in meters

export function padamAutocomplete(address, territory) {
  // this function is used for adress autocomplete purposes
  // It does *NOT* retrieve or store lat/lng information
  // for resulting propositions
  // This will be done at the last minute by googleGeoCode
  return new Promise((resolve) => {
    if (!address.trim()) {
      // let's not run useless requests
      // but still reset results database for proper display
      resolve([]);
      return;
    }

    api
      .placePredictions(
        {},
        {
          input: address,
          radius: RADIUS /*in meters*/,
          territory: territory,
        },
      )
      .then((results) =>
        resolve(
          results.predictions.map((p) => ({
            place_id: p.place_id,
            zipcode: p.zipcode,
            address: p.description,
            latitude: p.latitude,
            longitude: p.longitude,
          })),
        ),
      );
  });
}

export function padamGeoCode({ address, territory, placeId }) {
  return new Promise((resolve, reject) => {
    if (!address.trim()) {
      // let's not run useless requests
      // but still reset results database for proper display
      resolve([]);
      return;
    }

    api
      .placeGeocoding(
        {},
        {
          address: placeId,
          place_id: placeId,
          territory: territory,
        },
      )
      .then((results) => {
        if (_.isEmpty(results)) {
          reject();
          return;
        }
        const latitude = results[0].geometry.location.lat;
        const longitude = results[0].geometry.location.lng;
        const zipcode = _.reduce(
          results[0].address_components,
          (zipcode, b) => {
            if (zipcode) {
              return zipcode;
            }
            if (b.types[0] === "postal_code") return b.long_name;
          },
          null,
        );
        resolve({
          latitude,
          longitude,
          zipcode,
          address: results[0].formatted_address,
        });
      });
  });
}
