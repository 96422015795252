import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export function ContactIcon(props) {
  return (
    <SvgIcon className={props.className} viewBox="0 0 32 32">
      <path
        d="M10.1529 9.53223C10.1529 5.87442 13.0691 2.90918 16.6663 2.90918C20.2635 2.90918 23.1797 5.87442 23.1797 9.53223C23.1797 11.2112 22.56 12.7826 21.4952 13.9777L21.3906 14.0899L21.7037 14.1843C25.8567 15.5056 28.8476 19.3986 28.9657 23.9692L28.9693 24.2501V29.1561L27.851 29.1561H16.7012H5.55133L4.36328 29.1561V24.2501C4.36328 19.4611 7.51839 15.3652 11.8951 14.1025L11.941 14.0889L11.8375 13.9775C10.8287 12.8458 10.2194 11.3757 10.1581 9.79642L10.1529 9.53223Z"
        fill="#6C6C6C"
      />
    </SvgIcon>
  );
}
