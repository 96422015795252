import { makeStyles } from "@material-ui/core";

export const usePromoCodeStyles = makeStyles(() => ({
  okButton: {
    width: "160px",
    height: "60px",
  },
  promoCodeContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "0.5rem",

    padding: "1rem",
  },
  successIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "160px",

    "& svg": {
      transform: "scale(2)",
    },
  },
}));
