import { useSearchParams } from "react-router-dom";

import dayjs from "dayjs";

export const TIME_MODE_DEPARTURE = "departure";
export const TIME_MODE_ARRIVAL = "arrival";

type TimeMode = typeof TIME_MODE_DEPARTURE | typeof TIME_MODE_ARRIVAL | null;

type DeepLinksParams = {
  isDeepLink: boolean;
  required: {
    departureAddress: string | null;
    destinationAddress: string | null;
    territory: string | null;
  };
  optional: {
    requestedSeats: number;
    requestedTime: string | null;
    timeMode: TimeMode;
  };
};

const DEPARTURE_ADDRESS_PARAM = "departure-address";
const DESTINATION_ADDRES_PARAM = "destination-address";

const DEPARTURE_TIME_PARAM = "requested-departure-time";
const DESTINATION_TIME_PARAM = "requested-arrival-time";
const TERRITORY_PARAM = "territory";
const SEATS_PARAM = "requested-seats";

export function useDeepLink() {
  const [queryParameters] = useSearchParams();

  const deeplinkDepartureAddress = queryParameters.get(DEPARTURE_ADDRESS_PARAM);
  const deeplinkDestinationAddress = queryParameters.get(
    DESTINATION_ADDRES_PARAM,
  );
  const requestedDepartureTime = queryParameters.get(DEPARTURE_TIME_PARAM);
  const requestedArrivalTime = queryParameters.get(DESTINATION_TIME_PARAM);
  const deeplinkTerritory = queryParameters.get(TERRITORY_PARAM);
  const isDeepLink = Boolean(
    deeplinkDepartureAddress && deeplinkDestinationAddress && deeplinkTerritory,
  );

  const bookingDepartureTime = dayjs(requestedDepartureTime).tz();
  const bookingDestinationTime = dayjs(requestedArrivalTime).tz();
  const now = dayjs.tz();

  let bookingRequestTime = "";
  let timeMode: TimeMode = null;

  if (isDeepLink && bookingDepartureTime.isValid()) {
    bookingRequestTime = bookingDepartureTime.format();
    timeMode = TIME_MODE_DEPARTURE;
  } else if (isDeepLink && bookingDestinationTime.isValid()) {
    bookingRequestTime = bookingDestinationTime.format();

    timeMode = TIME_MODE_ARRIVAL;
  }

  if (bookingRequestTime < now.format()) {
    bookingRequestTime = now.format();
    timeMode = TIME_MODE_DEPARTURE;
  }

  const deepLinksParams = {
    isDeepLink,
    required: {
      departureAddress: deeplinkDepartureAddress,
      destinationAddress: deeplinkDestinationAddress,
      territory: deeplinkTerritory,
    },
    optional: {
      requestedSeats: parseInt(queryParameters.get(SEATS_PARAM) || "1"),
      requestedTime: bookingRequestTime,
      timeMode,
    },
  };

  return deepLinksParams as DeepLinksParams;
}
