import React from "react";
import { injectIntl } from "react-intl";

import dayjs from "dayjs";

import CustomDayPickerInput from "components/CustomDayPickerInput/CustomDayPickerInput.jsx";

import { getLang } from "../../i18n.js";
import { i18nVerboseDateFormat } from "utils/i18nDate";
import { FORMAT_DATE } from "utils/constants";

export class CustomDayPickerInputContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { openDayPicker: false, selectedDays: [] };
    this.clickedInsideContainer = false;
  }

  componentWillUnmount() {
    clearTimeout(this.clickTimeout);
    clearTimeout(this.hideTimeout);
  }

  onFocus = () => {
    this.setState({ openDayPicker: true });
  };

  close = () => {
    this.setState({ openDayPicker: false });
  };

  onBlur = () => {
    this.setState({ openDayPicker: this.clickedInsideContainer });
  };

  handleContainerMouseDown = () => {
    this.clickedInsideContainer = true;

    this.clickTimeout = setTimeout(() => {
      this.clickedInsideContainer = false;
    }, 0);
  };

  /**
   * This could go onto the internal state
   * but we also need that action on the search result page
   */
  onDayClick = (day, disabled) => {
    if (disabled) return;

    this.props.onDayClick(day);
  };

  submitSelectedDays = () => {
    this.hideTimeout = setTimeout(() => {
      this.setState({ openDayPicker: false });
    }, 100);
  };

  formatSelectedDays = () => {
    const days = this.props.selectedDays;

    if (!days.length) return "";

    const last = days.length - 1;

    if (days.length === 1)
      return this.props.intl.formatMessage(
        { id: "search.date" },
        { date: i18nVerboseDateFormat(days[0]) },
      );

    // from ... to ...
    const str = this.props.intl.formatMessage(
      { id: "search.multi_date" },
      {
        startDate: i18nVerboseDateFormat(days[0]),
        endDate: i18nVerboseDateFormat(days[last]),
      },
    );

    return str;
  };

  getDisabledDates = () => {
    const date = new Date(dayjs.tz().format(FORMAT_DATE));
    const addedDisableDays = this.props.addDisabledDays;

    let disabledDates = [
      {
        after: this.props.maxBookingDay || null,
      },
      {
        before: date,
      },
    ];

    if (typeof addedDisableDays === "object")
      disabledDates = [...disabledDates, ...addedDisableDays];

    return disabledDates;
  };

  render() {
    const { openDayPicker } = this.state;
    const { errorText } = this.props;
    const selectedDays = _.map(this.props.selectedDays, (selectedDay) => {
      if (selectedDay !== null) {
        return new Date(dayjs.tz(selectedDay).format(FORMAT_DATE));
      }
    });
    const selectedDaysStr = this.formatSelectedDays();
    const now = new Date(dayjs.tz().format(FORMAT_DATE));

    return (
      <CustomDayPickerInput
        close={this.close}
        maxBookingDay={this.props.maxBookingDay}
        disabled={this.props.disabled}
        disabledDays={this.getDisabledDates()}
        errorText={errorText}
        getLang={getLang()}
        handleContainerMouseDown={this.handleContainerMouseDown}
        intl={this.props.intl}
        label={this.props.label}
        name={this.props.name}
        fromMonth={this.props.fromMonth || now}
        onBlur={this.onBlur}
        onDayClick={this.onDayClick}
        onFocus={this.onFocus}
        open={openDayPicker}
        selectedDays={selectedDays}
        selectedDaysStr={selectedDaysStr}
        submitSelectedDays={this.submitSelectedDays}
      />
    );
  }
}

export default injectIntl(CustomDayPickerInputContainer);
