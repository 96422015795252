import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useAboutStyles = makeStyles((theme: CustomTheme) => ({
  aboutContainer: {
    margin: "32px 16px",
    padding: "0.5rem",
  },
  aboutTab: {
    minWidth: "140px",

    [theme.breakpoints.up("sm")]: {
      minWidth: "240px",
    },
  },
  aboutTabPanel: {
    display: "grid",
    columnGap: "24px",
    rowGap: "1rem",

    padding: "1rem 0 0.5rem 0",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",

      padding: "1.5rem 1rem 1.25rem 1rem",
    },

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  contactTitle: {
    padding: "2rem 1rem 0 1rem",
  },
  noInfos: {
    padding: "2.5rem 1rem 2rem 1rem",
  },
}));
