import React, { useEffect } from "react";
import { useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate, useLocation } from "react-router-dom";

import {
  Button as MuiButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import api from "api";

import { snackMessage } from "containers/SnackBar/actions";

import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";
import { AuthModalsContext } from "contexts/AuthModalsContext";

import store from "store/store.js";
import { logout, jumpToNextPage } from "store/actions";

import { useSignUpVerificationStyles } from "./signUpVerificationFormStyles";

interface SignUpVerificationFormProps {
  phoneCode?: string;
}

export default function SignUpVerificationForm({
  phoneCode,
}: SignUpVerificationFormProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const location = useLocation();

  const classes = useSignUpVerificationStyles();

  const { productParameters } = useContext(ProductContext);
  const { setIsAuthenticated, loadUserProfile, userProfile } =
    useContext(UserContext);
  const { showAuthModal } = useContext(AuthModalsContext);

  const [resendCodeLoading, setResendCodeLoading] = useState(false);
  const [resendCodeError, setResendCodeError] = useState(null);

  const {
    formState: { errors, isSubmitting },
    getValues,
    handleSubmit,
    register,
    setError,
  } = useForm();

  const phoneValidationProcessAtBooking =
    productParameters?.user.phone_validation_process === "AT_BOOKING";

  const onCancel = useCallback(() => {
    if (phoneValidationProcessAtBooking) {
      // Phone validation process at booking: just close the modal
      showAuthModal(null);
      return;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: don't know how to solve Redux typing issue
    // but it doesn't matter because it will disappear eventually
    store.dispatch(logout(navigate, setIsAuthenticated));
    if (SIGNUP_AFTER_SEARCH) {
      showAuthModal(null);
    } else {
      navigate("/login");
    }
    store.dispatch(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: don't know how to solve Redux typing issue
      // but it doesn't matter because it will disappear eventually
      snackMessage(
        "error",
        intl.formatMessage({
          id: "signup.verification.logout_but_required",
        }),
      ),
    );
  }, [phoneValidationProcessAtBooking]);

  const onSubmit = useCallback(
    async ({ phoneNumber, validationCode }) => {
      setResendCodeError(null);
      try {
        await api.validatePhoneNumber({
          validation_code: validationCode,
          phone_number: phoneNumber,
        });
      } catch (error) {
        if (error?.infos?.detail?.code === 302) {
          // Not connected
          if (!SIGNUP_AFTER_SEARCH && !phoneValidationProcessAtBooking) {
            navigate("/login");
          }
        }
        setError(
          [415, 417].includes(error.infos.detail.code)
            ? "phoneNumber"
            : "validationCode",
          {
            message:
              error?.infos?.detail?.message || "Code verification failed",
          },
        );
        return;
      }

      loadUserProfile();
      if (!SIGNUP_AFTER_SEARCH && !phoneValidationProcessAtBooking) {
        jumpToNextPage(navigate, location);
      }
    },
    [phoneValidationProcessAtBooking],
  );

  const onResendCode = useCallback(async () => {
    try {
      setResendCodeError(null);
      setResendCodeLoading(true);
      const res = await api.getPhoneNumberValidationCode({
        phone_number: getValues("phoneNumber"),
        code: phoneCode,
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: don't know how to solve Redux typing issue
      // but it doesn't matter because it will disappear eventually
      store.dispatch(snackMessage("success", res?.message || "Code sent"));
    } catch (error) {
      if ([415, 417].includes(error.infos.detail.code)) {
        setError("phoneNumber", {
          message: error?.infos?.detail?.message || "Unable to send code",
        });
      } else {
        setResendCodeError(
          error?.infos?.detail?.message || "Unable to send code",
        );
      }
    } finally {
      setResendCodeLoading(false);
    }
  }, []);

  useEffect(() => {
    // Send code the first time on new SMS flow
    if (phoneValidationProcessAtBooking) {
      onResendCode();
    }
  }, [phoneValidationProcessAtBooking]);

  return (
    <Paper className={classes.formContainer}>
      <form
        className={classes.signupVerificationForm}
        onSubmit={handleSubmit(onSubmit)}
        data-testid="sign-up-verification-form"
      >
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
              DEFAULT_TERRITORY || PRODUCT
            }_color.png`}
            alt="signinForm default"
          />
        </div>

        {phoneValidationProcessAtBooking && (
          <Typography className="pb-4">
            <FormattedMessage id="MESSAGE_VERIFY_PHONE_BEFORE_BOOKING" />
          </Typography>
        )}

        <TextField
          {...register("phoneNumber", { required: true })}
          required
          type="tel"
          autoComplete="off"
          label={<FormattedMessage id="signup.phoneNumber" />}
          defaultValue={userProfile.phoneNumber}
          helperText={errors.phoneNumber?.message?.toString()}
          error={Boolean(errors.phoneNumber)}
          data-testid="sign-up-verification-form-phone-number-field"
        />

        <MuiButton
          className={classes.resendCodeButton}
          variant="outlined"
          color="secondary"
          aria-label="Resend verification phone code"
          disabled={isSubmitting || resendCodeLoading}
          onClick={onResendCode}
          data-testid="sign-up-verification-form-resend-button"
        >
          <FormattedMessage id="signup.verification.re_send" />
        </MuiButton>

        {resendCodeError && (
          <Typography
            variant="body2"
            color="error"
            className={classes.resendCodeError}
          >
            {resendCodeError}
          </Typography>
        )}

        <TextField
          {...register("validationCode", { required: true })}
          required
          autoComplete="off"
          label={<FormattedMessage id="signup.verification.number" />}
          aria-label="sms verification number field"
          helperText={errors.validationCode?.message?.toString()}
          error={Boolean(errors.validationCode)}
          data-testid="sign-up-verification-form-verification-number-field"
        />

        <div className={classes.actionsContainer}>
          <MuiButton
            className={classes.actionButton}
            aria-label="redirect to register"
            variant="outlined"
            onClick={onCancel}
            role="link"
            data-testid="sign-up-verification-form-cancel-button"
          >
            <FormattedMessage id="misc.cancel" />
          </MuiButton>

          <MuiButton
            className={classes.actionButton}
            aria-label="submit verification code"
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting || resendCodeLoading}
            data-testid="sign-up-verification-form-submit-button"
          >
            <FormattedMessage id="signup.verification.verify" />
          </MuiButton>
        </div>
      </form>
    </Paper>
  );
}
