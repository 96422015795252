import React from "react";

import ReactStars from "react-stars";
import { Controller } from "react-hook-form";

const ControlledReactStars = ({
  control,
  name,
  rules,
  className,
  count,
  char,
  color1,
  color2,
  size,
  edit,
  half,
}) => (
  <Controller
    control={control}
    name={name}
    rules={rules}
    render={({ field: { onChange, value } }) => (
      <ReactStars
        value={value}
        onChange={onChange}
        className={className}
        count={count}
        char={char}
        color1={color1}
        color2={color2}
        size={size}
        edit={edit}
        half={half}
      />
    )}
  />
);

export default ControlledReactStars;
