import { ResourceType } from "api/resources/types/resourceType";

export const search: ResourceType = {
  tripSearch: {
    endpoint: "/trip-search",
    impersonate: true,
    method: "POST",
  },
  new_search: {
    endpoint: "/get-rides-multinode",
    impersonate: true,
    method: "POST",
  },
  addPassengerToSearchRequest: {
    endpoint:
      "/customers/{customerId}/searchrequests/{searchrequestId}/passengers/",
    impersonate: true,
    method: "POST",
    apiVersion: "2.0",
  },
  placePredictions: {
    endpoint: "/geoplaces/predict/",
    impersonate: true,
    method: "GET",
    apiVersion: "2.0",
  },
  placeGeocoding: {
    endpoint: "/geoplaces/geocode/",
    impersonate: true,
    method: "GET",
    apiVersion: "2.0",
  },
  getNodeList: {
    endpoint: "/search-nodes",
    impersonate: true,
    method: "GET",
  },
  fixedLinesSearch: {
    endpoint: "/fixed-lines/search-trips/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
};
