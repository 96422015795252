import { makeStyles } from "@material-ui/core";

export const useCancelRideDialogStyles = makeStyles((theme) => ({
  actionButton: {
    width: "calc(50% - 1rem)",

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 2rem)",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    padding: "2rem",
  },
  successIcon: {
    display: "block",

    margin: "1rem auto",

    transform: "scale(2)",

    [theme.breakpoints.up("sm")]: {
      margin: "1rem auto 3rem auto",
    },
  },
  dialogTitle: {
    padding: "2rem",

    "& h2": {
      textAlign: "center",
      fontSize: "1.35rem",
      fontWeight: 700,
    },

    [theme.breakpoints.up("sm")]: {
      padding: "2rem 4rem",

      "& h2": {
        fontSize: "1.5rem",
      },
    },
  },
  otherReasonContent: {
    margin: "1rem 0 0 0",
    overflowY: "initial",

    "& .MuiFormControl-root": {
      width: "100%",
    },

    "& fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  selectContent: {
    padding: "3rem 2rem 0 2rem",
    overflow: "hidden",
  },
  selectInput: {
    border: "0.125rem solid transparent",
    borderColor: theme.palette.primary.main,
    borderRadius: "2rem",

    "& .MuiInputLabel-formControl": {
      fontWeight: 500,

      transform: "translate(16px, 9px) scale(1)",

      "&[data-shrink='true']": {
        transform: "translate(0, -24px) scale(0.75)",

        [theme.breakpoints.up("sm")]: {
          transform: "translate(0, -32px) scale(0.75)",
        },
      },
    },

    "& label + .MuiInput-formControl": {
      margin: 0,
    },

    "& .MuiSelect-root": {
      padding: "0.5rem 1rem",
    },

    "& .MuiInput-underline::before": {
      content: "none",
    },

    "& .MuiInput-underline::after": {
      content: "none",
    },

    "& .MuiSelect-underline::before": {
      content: "none",
    },

    "& .MuiSelect-underline::after": {
      content: "none",
    },

    "& .MuiSelect-icon": {
      padding: "0 0.75rem",
    },
  },
}));
