import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import * as dayjs from "dayjs";

import Reservation from "containers/Reservation/Reservation.jsx";

import { useReservationViewStyles } from "./reservationViewStyles";

const ReservationView = (props) => {
  const { reservation } = props;

  const params = useParams();
  const intl = useIntl();
  const classes = useReservationViewStyles();

  useEffect(() => {
    if (reservation) {
      document.title = `${intl.formatMessage({ id: "title.booking" })} : ${
        reservation.pickup_node.name
      } (${dayjs(reservation.pickup_time).format("H:mm")}) - ${
        reservation.dropoff_node.name
      } (${dayjs(reservation.dropoff_time).format("H:mm")})`;
    }
  }, [reservation]);

  if (params?.id) {
    return (
      <div className={classes.reservationViewContainer}>
        <h1 className="visuallyHidden">
          {intl.formatMessage({ id: "breadcrumb.myResa" })}
        </h1>

        <Reservation id={params.id} />
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  return {
    reservation: state.reservation.reservation,
  };
};

export default connect(mapStateToProps)(ReservationView);
