import * as React from "react";
import { createContext, useCallback, useMemo, useState } from "react";

import { LOCAL_STORAGE_ITEM_TERRITORY_KEY } from "contexts/TerritoryContext/constants";

import { settings } from "config/app";
import cookie from "lib/cookie.js";

import { useUserProfile } from "./hooks";

import { AuthErrorHandler, UserContextValue } from "./types";
import { getLang } from "../../i18n";

export const UserContext = createContext<UserContextValue>(
  {} as UserContextValue,
);

export const UserContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(
    undefined,
  );
  const [selectedTerritoryKey, setSelectedTerritoryKey] = useState<
    string | undefined
  >(window.localStorage.getItem(LOCAL_STORAGE_ITEM_TERRITORY_KEY) || undefined);

  /* Decorator for authentication error handling.
   * To be used on functions returning an API call promise.
   * If the response is an authentication error (internal error
   * code 102), the user is considered as unauthenticated.
   */
  const authErrorHandler: AuthErrorHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <T extends (...args: any[]) => any>(
      func: T,
    ): ((...args: Parameters<T>) => ReturnType<T>) => {
      return (...args: Parameters<T>): ReturnType<T> => {
        return func(...args).catch((error) => {
          if (error?.infos?.detail?.code === 102) {
            // Unauthenticated error
            setIsAuthenticated(false);
          } else {
            throw error;
          }
        });
      };
    },
    [],
  );

  const {
    loadUserProfile,
    onSavedTerritoryRestored,
    restoreSavedTerritory,
    savedTerritoryKey,
    userTerritories,
    updateHasTermsAccepted,
    updateUserPassword,
    updateUserProfile,
    updateUserProfileByAdmin,
    userProfile,
    userProfileHasNeverBeenLoaded,
    userProfileLoading,
  } = useUserProfile(selectedTerritoryKey);

  const userProfileWillBeLoaded = useMemo((): boolean => {
    // The user profile is expected to be loaded automatically if:
    // - there is no cookie AND signup after search is enabled
    // OR
    // - there is a cookie AND the user profile has never been loaded yet
    const hasAuthOrImpersonateCookie = Boolean(
      cookie.get(settings.cookieKeys.authCookie) ||
        cookie.get(settings.cookieKeys.impersonateCookie),
    );

    return hasAuthOrImpersonateCookie && userProfileHasNeverBeenLoaded;
  }, [userProfileHasNeverBeenLoaded]);

  const [language, setLanguage] = useState(getLang());

  const value = useMemo(
    () => ({
      authErrorHandler,
      isAuthenticated,
      loadUserProfile: authErrorHandler(loadUserProfile),
      onSavedTerritoryRestored,
      restoreSavedTerritory,
      savedTerritoryKey,
      setIsAuthenticated,
      setSelectedTerritoryKey,
      updateHasTermsAccepted,
      updateUserPassword: authErrorHandler(updateUserPassword),
      updateUserProfile: authErrorHandler(updateUserProfile),
      updateUserProfileByAdmin: authErrorHandler(updateUserProfileByAdmin),
      userProfile,
      userProfileLoading,
      userProfileWillBeLoaded,
      userTerritories,
      language,
      setLanguage,
    }),
    [
      authErrorHandler,
      isAuthenticated,
      loadUserProfile,
      onSavedTerritoryRestored,
      restoreSavedTerritory,
      savedTerritoryKey,
      updateHasTermsAccepted,
      updateUserPassword,
      updateUserProfile,
      updateUserProfileByAdmin,
      userProfile,
      userProfileLoading,
      userProfileWillBeLoaded,
      userTerritories,
      language,
      setLanguage,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
