import React from "react";

import { Paper } from "@material-ui/core";

import dayjs from "dayjs";

import { Badge } from "atoms/Badge/Badge";
import {
  TimedRideStep,
  TimedRideStepType,
} from "molecules/TimedRideStep/TimedRideStep";

import { BusIcon } from "icons/BusIcon";
import { WalkerIcon } from "icons/WalkerIcon";

import { FORMAT_HOUR } from "utils/constants";

import { useRideDetailsSimplifiedStyles } from "./rideDetailsSimplifiedStyles";

export function RideDetailsSimplified({ booking, reservationInfo }) {
  const classes = useRideDetailsSimplifiedStyles();

  if (!booking) {
    return null;
  }

  const departureAddress = reservationInfo.departure_position.address;
  const departureWalkDuration = booking.pickup_walking_time;

  const pickupTime = dayjs(booking.pickup_time).tz().format(FORMAT_HOUR);
  const pickupNodeName = booking?.pickup_node.name;
  const pickupNodeAddress = booking?.pickup_node.position.address;

  const busDuration = `${dayjs
    .duration({
      minutes: dayjs(booking.dropoff_time).diff(
        dayjs(booking.pickup_time),
        "minute",
      ),
    })
    .asMinutes()
    .toFixed(0)
    .replace(/\b0\b/, "1")} min`;

  const arrivalAddress = reservationInfo.destination_position.address;
  const arrivalWalkDuration = booking.dropoff_walking_time;

  const dropoffTime = dayjs(booking.dropoff_time).tz().format(FORMAT_HOUR);
  const dropoffNodeName = booking?.dropoff_node.name;
  const dropoffNodeAddress = booking?.dropoff_node.position.address;

  return (
    <Paper className={classes.rideDetailsContainer}>
      <ol className={classes.rideStepList}>
        {departureWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.pickupColors}`}
              />
            }
            stepAddress={departureAddress}
            timeOrDuration={`${departureWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.pickupColors}`}
            />
          }
          stepAddress={pickupNodeAddress}
          stepName={pickupNodeName}
          timeOrDuration={pickupTime}
          type={TimedRideStepType.Pickup}
        />

        <TimedRideStep
          badgeElement={<Badge label={busDuration} />}
          type={TimedRideStepType.Badge}
        />

        <TimedRideStep
          iconElement={
            <BusIcon
              className={`${classes.stepIcon} ${classes.busIcon} ${classes.dropoffColors}`}
            />
          }
          stepAddress={dropoffNodeAddress}
          stepName={dropoffNodeName}
          timeOrDuration={dropoffTime}
          type={TimedRideStepType.Dropoff}
        />

        {arrivalWalkDuration > 0 && (
          <TimedRideStep
            iconElement={
              <WalkerIcon
                className={`${classes.stepIcon} ${classes.walkerIcon} ${classes.dropoffColors}`}
              />
            }
            stepAddress={arrivalAddress}
            timeOrDuration={`${arrivalWalkDuration} min`}
            type={TimedRideStepType.Walk}
          />
        )}
      </ol>
    </Paper>
  );
}
