import * as React from "react";
import { useContext } from "react";
import { FormattedNumber, IntlProvider } from "react-intl";

import { TerritoryContext } from "contexts/TerritoryContext";

import { getLang } from "../../../i18n.js";

type PriceProps = {
  value: number;
};

export function Price(props: PriceProps) {
  const { value } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  const lang = getLang();
  const currency = selectedTerritory?.payment.currency || "";

  return (
    <IntlProvider locale={lang}>
      <FormattedNumber
        style="currency"
        value={value}
        currency={currency}
        minimumFractionDigits={2}
        maximumFractionDigits={2}
      />
    </IntlProvider>
  );
}
