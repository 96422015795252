import { makeStyles } from "@material-ui/core";

export const useIndicationsToDriverInputStyles = makeStyles(() => ({
  indicationsToDriverInput: {
    display: "flex",
    alignItems: "center",

    width: "100%",
    maxWidth: "40rem",
    padding: "0.25rem 1rem",
    border: "1px solid #151515",
    borderRadius: "2rem",

    color: "#151515",
    textAlign: "left",

    background: "#f4f4f4",

    "&:hover": {
      cursor: "pointer",
    },
  },
  indicationsToDriverContainer: {
    maxWidth: "calc(100% - 2rem)",
    width: "fit-content",
    margin: "auto",
  },
  indicationsToDriverContent: {
    margin: "0 0 0 0.5rem",
    overflow: "hidden",

    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  indicationsToDriverLabel: {
    display: "block",

    margin: "0 0 0.25rem 1rem",

    color: "#5c5c5c",
    fontSize: "1rem",
  },

  // Let this class here to override styles:
  addIndicationsButton: {
    width: "auto",

    color: "#6c6c6c",

    background: "white",
  },
}));
