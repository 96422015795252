import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { TextField, List, CircularProgress } from "@material-ui/core";

import { map } from "lodash";

import NodeSuggestions from "components/Autocomplete/NodeSuggestions";
import AddressSuggestions from "components/Autocomplete/AddressSuggestions";
import UserSuggestions from "components/Autocomplete/UserSuggestions";
import GeocodedSuggestion from "components/Autocomplete/GeocodedSuggestion";

import {
  ARROW_UP,
  ARROW_DOWN,
  enter,
  onInputChange,
  RESET_AUTOCOMPLETE,
  contextToData,
} from "containers/Autocomplete/actions";

import { TerritoryContext } from "contexts/TerritoryContext";
import { ProductContext } from "contexts/ProductContext";

const suggestionProviders = {
  geocodes: GeocodedSuggestion,
  nodes: NodeSuggestions,
  addresses: AddressSuggestions,
  users: UserSuggestions,
};

function Autocomplete(props) {
  /********  props from parent
   * text : the text in the field, controlled by parent with:
   * onChange
   * context : the view where is the autocomplete => which data it displays ?
   * onSelect : when clicking on suggestion
   */
  const {
    onChange,
    onInputChange,
    context,
    id,
    onSelect,
    loading,
    highlightedIndex,
    className,
    forceSuggestionsOpen,
    onFieldFocus,
  } = props;
  const { text, helperText, label, placeholder } = _.get(
    props,
    "inputProps",
    {},
  );
  /******* props generated from inside
   */
  const { arrowUp, arrowDown, enter, resetAutocomplete } = props;

  const { selectedTerritory } = useContext(TerritoryContext);
  const { productParameters } = useContext(ProductContext);

  /******** internal state that is not needed above the component
   */
  const [isFocused, setFocus] = useState(forceSuggestionsOpen || false);
  // conditional opening of autocomplete
  const open =
    forceSuggestionsOpen !== undefined
      ? forceSuggestionsOpen
      : Boolean(isFocused && text && text.length >= 3);

  // highlighted index (for keyboard navigation) is managed via redux to answer the case of
  // switching from node to address suggestion

  const handleKey = (e) => {
    e.stopPropagation();
    switch (e.keyCode) {
      // second thought : a "selected" element with arrows is also displayed and entered in form
      // context to know which datatypes are displayed and to guess the highlightedIndex element
      case 38: // arrowUp key
        arrowUp(context);
        enter(context, onSelect);
        break;
      case 40: // arrowDown key
        arrowDown(context);
        enter(context, onSelect);

        break;
      case 13: // enter key
        enter(context, onSelect);
        setFocus(false);
        resetAutocomplete();
        break;
    }
  };

  // below is cleaning the "highlightedIndex" in an edge case
  useEffect(() => {
    if (isFocused && text && text.length < 3) {
      resetAutocomplete();
    }
  }, [text]);

  useEffect(() => {
    if (forceSuggestionsOpen) {
      onInputChange(text, context, selectedTerritory, productParameters);
    }
  }, [isFocused, text]);

  useEffect(() => {
    if (forceSuggestionsOpen === undefined) {
      setFocus(false);
    } else if (forceSuggestionsOpen === true) {
      onInputChange(text, context, selectedTerritory, productParameters);
    }
  }, [forceSuggestionsOpen]);

  const underline = context === "search";

  return (
    <div className="fullWidth" style={{ position: "relative" }}>
      <TextField
        autoComplete="off" // not from the browser
        id={id}
        fullWidth
        value={text}
        onChange={(e) => {
          // update search.formData and textfield
          onChange(e);
          // update autoComplete only when user types a letter
          onInputChange(
            e.target.value,
            context,
            selectedTerritory,
            productParameters,
          );
          setFocus(true);
        }}
        onFocus={() => {
          // loads suggestions also when clicking
          if (text && text.length >= 3) {
            onInputChange(text, context, selectedTerritory, productParameters);
          }
          setFocus(true);
          onFieldFocus && onFieldFocus();
        }}
        onBlur={() => {
          if (!forceSuggestionsOpen) {
            setFocus(false);
            resetAutocomplete();
          }
        }}
        onKeyDown={handleKey}
        helperText={helperText}
        error={Boolean(helperText)}
        title={label}
        InputProps={{ disableUnderline: underline }}
        placeholder={placeholder}
        className={className}
        // ACCESSIBILITY
        inputProps={{
          autoComplete: "off",
          "aria-label": label,
          "aria-autocomplete": open ? "list" : "none",
          "aria-controls": open ? "autocomplete-results" : "",
          "aria-activedescendant": open ? "suggestion-" + highlightedIndex : "",
        }}
      />

      {open && (
        <List
          aria-expanded={open}
          tabIndex={-1}
          disablePadding
          className="suggestionsContainer"
          // ACCESSIBILITY
          id="autocomplete-results"
          role="listbox"
          data-testid="impersonate-search-results"
        >
          {loading ? (
            <div className="row centered fullWidth padded">
              <CircularProgress />
            </div>
          ) : (
            map(contextToData[context], (dataType, i) => {
              const SuggestionProvider = suggestionProviders[dataType];
              return <SuggestionProvider key={i} onSelect={onSelect} />;
            })
          )}
        </List>
      )}
    </div>
  );
}
const mapStateToProps = (state) => ({
  loading: state.autocomplete.loading,
  highlightedIndex: state.autocomplete.highlightedIndex,
});

const mapDispatchToProps = (dispatch) => ({
  arrowUp: (context) => dispatch({ type: ARROW_UP, context }),
  arrowDown: (context) => dispatch({ type: ARROW_DOWN, context }),
  enter: (context, onSelect) => dispatch(enter(context, onSelect)),
  onInputChange: (newText, context, selectedTerritory, productParameters) =>
    dispatch(
      onInputChange(newText, context, selectedTerritory, productParameters),
    ),
  resetAutocomplete: () => dispatch({ type: RESET_AUTOCOMPLETE }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Autocomplete);
