export const SNACK_MESSAGE = "SNACK_MESSAGE";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";

export const snackMessage = (severity, message, debugMessage) => (dispatch) => {
  dispatch({
    type: SNACK_MESSAGE,
    severity,
    message,
    debugMessage,
  });

  if (severity === "error") {
    setTimeout(() => {
      dispatch({
        type: CLOSE_SNACKBAR,
      });
    }, 12000);
  } else {
    setTimeout(() => {
      dispatch({
        type: CLOSE_SNACKBAR,
      });
    }, 8000);
  }
};
