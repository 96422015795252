import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { connect } from "react-redux";

import { Box, Button as MuiButton, Typography } from "@material-ui/core";

import Close from "components/Icons/Close";
import StripeWrapperElement from "organisms/Stripe/StripeWrapperElement";

import SavedPaymentMethodsContainer from "containers/Payment/SavedPaymentMethodsContainer";
import { hideForm, showForm } from "containers/Payment/actions";
import { handleStripePaymentIntent } from "containers/Stripe/actions";
import { payloadHandleStripePaymentIntentParameters } from "./StripeType";
import { PaymentMethodEnum } from "types/payment";

interface StripeContainerProps {
  method: PaymentMethodEnum;
  totalPrice: number;
  isRequesting: boolean;
  isRequestingStripe: boolean;
  isCardFormOpen: boolean;
  isIBANFormOpen: boolean;
  stripeError: string;
  validate: (token: { payment_intent_id: string }) => void;
  handleStripePaymentIntent: (
    payloadHandleStripePaymentIntent: payloadHandleStripePaymentIntentParameters,
  ) => void;
  showForm: (method: string) => void;
  hideForm: (method: string) => void;
}

function StripeContainer(props: StripeContainerProps) {
  const {
    method,
    totalPrice,
    isRequesting,
    isRequestingStripe,
    isCardFormOpen,
    isIBANFormOpen,
    stripeError,
    validate,
    handleStripePaymentIntent,
    showForm,
    hideForm,
  } = props;

  const [isFormOpen, setIsFormOpen] = useState(false);

  const callback = (token) => {
    validate({ payment_intent_id: token });
  };
  const pay = (
    stripe,
    elements,
    totalPrice,
    shouldSavePaymentMethod,
    extraOptions = undefined,
  ) => {
    handleStripePaymentIntent({
      stripe,
      elements,
      totalPrice,
      shouldSavePaymentMethod,
      method,
      callback,
      extraOptions,
    });
  };

  useEffect(() => {
    if (method === PaymentMethodEnum.STRIPE_CARD) {
      setIsFormOpen(isCardFormOpen);
    }
    if (method === PaymentMethodEnum.STRIPE_SEPA) {
      setIsFormOpen(isIBANFormOpen);
    }
  }, [isCardFormOpen, isIBANFormOpen, method]);

  return (
    <div className="flex flex-col justify-center">
      <SavedPaymentMethodsContainer method={method} />

      {!isFormOpen && (
        <div className="w-full justify-end">
          <Box mx={2}>
            <MuiButton
              classes={{ root: "left" }}
              variant="outlined"
              color="inherit"
              onClick={() => {
                showForm(method);
              }}
              data-testid="add-new-card"
            >
              <Typography variant="caption">
                {method === PaymentMethodEnum.STRIPE_CARD && (
                  <FormattedMessage id="payment.new_card" />
                )}
                {method === PaymentMethodEnum.STRIPE_SEPA && (
                  <FormattedMessage id="ACTION_ADD_IBAN" />
                )}
              </Typography>
            </MuiButton>
          </Box>
        </div>
      )}

      <div className="flex w-full justify-center">
        <div dir="rtl" className="w-full md:w-5/6">
          <div className="relative w-full">
            {isFormOpen && (
              <div
                className="absolute start-0 top-0 m-3 flex cursor-pointer justify-center rounded hover:bg-gray-100"
                onClick={() => {
                  hideForm(method);
                }}
              >
                <Close />
              </div>
            )}

            <StripeWrapperElement
              method={method}
              pay={(stripe, elements, totalPrice, shouldSaveCard) =>
                pay(stripe, elements, totalPrice, shouldSaveCard)
              }
              totalPrice={totalPrice}
              isRequesting={
                // the first "isRequesting" comes from Parent, who can be CreditManager or ~BookingPayment
                isRequesting || isRequestingStripe
              }
              isFormOpen={isFormOpen}
              stripeError={stripeError}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    // savedCards: _.get(state, 'payment.savedCards', []),
    isRequestingStripe: state.payment.isRequestingStripe,
    selectedCardIndex: state?.payment?.selectedCardIndex || -1, // default value is for new card
    isCardFormOpen: state?.payment.isCardFormOpen,
    isIBANFormOpen: state?.payment.isIBANFormOpen,
    stripeError: state?.payment.stripeError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  handleStripePaymentIntent: (
    payloadHandleStripePaymentIntent: payloadHandleStripePaymentIntentParameters,
  ) => dispatch(handleStripePaymentIntent(payloadHandleStripePaymentIntent)),
  showForm: (method) => dispatch(showForm(method)),
  hideForm: (method) => dispatch(hideForm(method)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StripeContainer);
