import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import MuiButton from "@material-ui/core/Button";

import { SuccessIcon } from "components/Icons/Success";

import { TerritoryContext } from "contexts/TerritoryContext";

import { useCancelRideDialogStyles } from "./cancelRideDialogStyles";

export function CancelRideDialog(props) {
  const classes = useCancelRideDialogStyles();
  const intl = useIntl();
  const navigate = useNavigate();
  const { selectedTerritory } = useContext(TerritoryContext);

  const {
    cancellationReasons,
    comment,
    currentReason,
    handleCancelReservation,
    handleChange,
    handleChangeComment,
    handleClose,
    handleOpen,
    isCancelled,
    onDelete,
    open,
    renderButton,
    reservation,
  } = props;

  const handleKeyPressed = (e) => {
    if (e.key === "Enter") {
      handleOpen();
    }
  };

  const deleteFunction = () => {
    return onDelete(
      navigate,
      {
        reservation: reservation,
        context: "reservation",
        cancellationReason: currentReason,
        comment: comment,
      },
      selectedTerritory?.recurring_services?.enabled || false,
      selectedTerritory.territory_key,
    );
  };

  return (
    <div>
      {renderButton({
        "aria-label": intl.formatMessage({ id: "misc.cancel_booking" }),
        onClick: handleOpen,
        onKeyPress: (e) => handleKeyPressed(e),
      })}

      <Dialog open={open} onClose={() => handleClose(true)}>
        <DialogTitle className={classes.dialogTitle} disableTypography>
          <Typography component="h2">
            {isCancelled ? (
              <FormattedMessage id={"reservation.deleted"} />
            ) : (
              <FormattedMessage id={"misc.confirm_delete_reservation"} />
            )}
          </Typography>
        </DialogTitle>

        {!isCancelled && (
          <DialogActions className={classes.actionsContainer}>
            <MuiButton
              className={classes.actionButton}
              color="primary"
              variant="outlined"
              aria-label={intl.formatMessage({
                id: "aria.abort-cancel",
                defaultMessage: "No, don't cancel this reservation",
              })}
              onClick={() => handleClose(true)}
            >
              <FormattedMessage id="misc.no" defaultMessage="No" />
            </MuiButton>

            <MuiButton
              className={classes.actionButton}
              color="primary"
              variant="contained"
              aria-label={intl.formatMessage({
                id: "aria.confirm-cancel",
                defaultMessage: "Yes, cancel this reservation",
              })}
              onClick={() => handleCancelReservation(true)}
            >
              <FormattedMessage id="misc.yes" defaultMessage="Yes" />
            </MuiButton>
          </DialogActions>
        )}

        {isCancelled && (
          <>
            <SuccessIcon className={classes.successIcon} />

            <DialogContent className={classes.selectContent}>
              <TextField
                className={classes.selectInput}
                id="why-cancel"
                fullWidth
                autoComplete="off"
                select
                label={
                  <FormattedMessage
                    id={"misc.why_cancel_reservation"}
                    defaultMessage="Why do you cancel?"
                  />
                }
                value={currentReason || ""}
                onChange={handleChange}
                disabled={!isCancelled}
              >
                {Object.values(cancellationReasons).map((reason, index) => (
                  <MenuItem value={reason} key={`reason-${index}`}>
                    {reason}
                  </MenuItem>
                ))}
              </TextField>
            </DialogContent>
          </>
        )}

        {isCancelled &&
          currentReason ===
            cancellationReasons[cancellationReasons.length - 1] && (
            <DialogContent className={classes.otherReasonContent}>
              <TextField
                id="why-cancel-comment"
                variant="outlined"
                multiline
                minRows={2}
                maxRows={2}
                autoFocus
                autoComplete="off"
                label={
                  <FormattedMessage
                    id={"misc.give_reason"}
                    defaultMessage="Do you want to specify?"
                  />
                }
                inputProps={{ maxLength: 500 }}
                onChange={handleChangeComment}
              />
            </DialogContent>
          )}

        {isCancelled && (
          <DialogActions className={classes.actionsContainer}>
            <MuiButton
              className={classes.actionButton}
              color="primary"
              variant="outlined"
              aria-label={intl.formatMessage({
                id: "aria.ignore-cancel-reason",
                defaultMessage: "Ignore the question",
              })}
              onClick={() => {
                deleteFunction();
                handleClose();
              }}
            >
              <FormattedMessage id="misc.ignore" defaultMessage="Ignore" />
            </MuiButton>

            <MuiButton
              className={classes.actionButton}
              color="primary"
              variant="contained"
              aria-label={intl.formatMessage({
                id: "aria.send-cancel-reason",
                defaultMessage: "Send my answer",
              })}
              onClick={() => {
                deleteFunction();
                handleClose();
              }}
            >
              <FormattedMessage id={"misc.send"} defaultMessage="Send" />
            </MuiButton>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
}
