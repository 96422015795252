import React from "react";
import { connect } from "react-redux";
import { formatAddress } from "containers/Autocomplete/actions.js";
import { ListItem, Typography } from "@material-ui/core";

function AddressSuggestions(props) {
  const { addresses, onSelect, startIndex, highlightedIndex } = props;

  return _.map(addresses, (p, i) => (
    <ListItem
      button
      style={{ justifyContent: "space-between" }}
      alignItems="center"
      name={`address-${i}`}
      key={i}
      onMouseDown={() => onSelect(formatAddress(p))}
      selected={i + startIndex === highlightedIndex}
      // ACCESSIBILITY
      role="option"
      id={"suggestion-" + (i + startIndex)}
      aria-selected={i + startIndex === highlightedIndex}
      tabIndex={-1}
    >
      <Typography variant="body1">{p.suggestionParts.mainText}</Typography>
      <Typography variant="body2">{p.suggestionParts.secondaryText}</Typography>
    </ListItem>
  ));
}
const mapStateToProps = (state) => {
  const a = state.autocomplete;
  return {
    addresses: a.addresses,
    isRequestingAddresses: a.isRequestingAddresses,
    startIndex:
      state.autocomplete.geocodes.length + state.autocomplete.nodes.length,
    highlightedIndex: state.autocomplete.highlightedIndex,
  };
};

export default connect(mapStateToProps)(AddressSuggestions);
