import { makeStyles } from "@material-ui/core";

export const useNotificationStyles = makeStyles((theme) => ({
  actionButton: {
    width: "fit-content",
    height: "fit-content",
    margin: "0 0 1.25rem 0",

    [theme.breakpoints.up("md")]: {
      margin: "2.75rem 0 1.25rem 1.25rem",
    },
  },
  banner: {
    width: "100%",
    maxWidth: theme.breakpoints.values.xl,
    margin: "0 auto",
    padding: "0 0 16px 0",

    background: theme.palette.primary.light,
  },
  card: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    position: "relative",
    right: 0,
    left: 0,

    width: "100%",
    margin: "auto",
    borderRadius: "0 0 1.25rem 1.25rem",

    [theme.breakpoints.up("md")]: {
      width: "75%",
    },

    [theme.breakpoints.up("lg")]: {
      width: "60%",
    },
  },
  content: {
    padding: 0,

    "&:last-child": {
      padding: 0,
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",

    padding: "1.25rem 0",

    textAlign: "left",
  },
  contentWithButtonContainer: {
    display: "flex",
    flexDirection: "column",

    width: "calc(100% - 4rem)",

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  icon: {
    width: "2rem",
    height: "2rem",
    margin: "1.25rem",
  },
  shortText: {
    margin: "0.5rem 0 0 0",

    fontSize: "1.15rem",
  },
  shortTitle: {
    fontSize: "1.3rem",
    fontWeight: 500,
  },
}));
