import React, { useContext } from "react";

import { Link, Paper, Typography } from "@material-ui/core";

import { FormattedMessage, useIntl } from "react-intl";
import dayjs from "dayjs";

import { Separator } from "atoms/Separator/Separator";
import { TimedRideStep } from "molecules/TimedRideStep/TimedRideStep";
import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import { DotIcon } from "icons/DotIcon";
import { WalkerIcon } from "icons/WalkerIcon";
import { DepartureIcon } from "icons/DepartureIcon";
import { ArrivalIcon } from "icons/ArrivalIcon";
import { OpenNewTabIcon } from "icons/OpenNewTabIcon";

import { FORMAT_HOUR } from "utils/constants";

import { useFixedLinesRideDetailsStyles } from "./fixedLinesRideDetailsStyles";

export function FixedLinesRideDetails({ booking }) {
  const classes = useFixedLinesRideDetailsStyles();
  const { productParameters } = useContext(ProductContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const brand = productParameters?.brand;
  const intl = useIntl();

  if (!booking) {
    return null;
  }

  const sectionsLength = booking.sections.length;

  const WalkingSection = ({ section }) => {
    return (
      <TimedRideStep
        type={section.mode}
        iconElement={<WalkerIcon className={classes.stepIcon} />}
        stepAddress={
          section.mode !== "walking" ? section.departure_stop.name : undefined
        }
        timeOrDuration={`${dayjs
          .duration(section.duration, "seconds")
          .asMinutes()
          .toFixed(0)
          .replace(/\b0\b/, "1")} min ${intl.formatMessage({
          id: "ride.of_walk",
        })}`}
      />
    );
  };

  const FixedLinesSection: React.FC<{ section: any }> = ({ section }) => {
    if (section.mode === "walking") {
      return <WalkingSection section={section} />;
    } else {
      return (
        <>
          <TimedRideStep
            type={section.mode}
            stepAddress={section.departure_stop.name}
            stepName={dayjs(section.departure_datetime)
              .tz()
              .format(FORMAT_HOUR)}
            style={{ borderLeft: `0.125rem solid #${section?.line?.color}` }}
            iconElement={
              <DotIcon
                className={classes.stepIcon}
                fill={`#${section?.line?.color}`}
              />
            }
          />
          <TimedRideStep
            type={section.mode}
            stepAddress={`Direction ${section.arrival_stop.name}`}
            timeOrDuration={`${dayjs
              .duration(section.duration, "seconds")
              .asMinutes()
              .toFixed(0)} min`}
            style={{ borderLeft: `0.125rem solid #${section?.line?.color}` }}
            line={section.line}
          />
          <TimedRideStep
            type={section.mode}
            stepAddress={section.arrival_stop.name}
            stepName={dayjs(section.arrival_datetime).tz().format(FORMAT_HOUR)}
            iconElement={
              <DotIcon
                className={classes.stepIcon}
                fill={`#${section?.line?.color}`}
              />
            }
          />
        </>
      );
    }
  };

  const ExtremityStep: React.FC<{ section: any; isFirst?: boolean }> = ({
    section,
    isFirst = false,
  }) => {
    return (
      <>
        <TimedRideStep
          type={section.mode}
          stepAddress={
            isFirst ? section.departure_stop.name : section.arrival_stop.name
          }
          stepName={
            isFirst
              ? dayjs(section.departure_datetime).tz().format(FORMAT_HOUR)
              : dayjs(section.arrival_datetime).tz().format(FORMAT_HOUR)
          }
          iconElement={
            isFirst ? (
              <DepartureIcon className={classes.stepIcon} fill="black" />
            ) : (
              <ArrivalIcon className={classes.stepIcon} fill="black" />
            )
          }
        />
      </>
    );
  };

  return (
    <Paper className={classes.rideDetailsContainer}>
      <ol className={classes.rideStepList}>
        <ExtremityStep section={booking.sections[0]} isFirst />
        {booking.sections.map((section, index) => (
          <FixedLinesSection section={section} key={index} />
        ))}
        {booking.sections[sectionsLength - 1].mode === "walking" && (
          <ExtremityStep section={booking.sections[sectionsLength - 1]} />
        )}
      </ol>
      <Separator />
      <Typography className={classes.externalLinkDescription}>
        <FormattedMessage id="search.fixed_line.brand" values={{ brand }} />
      </Typography>
      <Typography>
        <Link
          href={
            selectedTerritory?.booking?.fixed_lines?.fixed_lines_service_url
          }
          target="_blank"
        >
          <OpenNewTabIcon className={classes.newTabIcon} />
          <FormattedMessage
            id="search.fixed_line.brand.link"
            defaultMessage="Consulter le site"
          />
        </Link>
      </Typography>
    </Paper>
  );
}
