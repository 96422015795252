import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button as MuiButton, CircularProgress } from "@material-ui/core";

export default function CustomPaymentProvider(props) {
  const { validate, isRequesting } = props;

  return (
    <div className="column" style={{ alignItems: "center", padding: "1rem" }}>
      <MuiButton
        aria-label="validate ride"
        color="primary"
        variant="contained"
        onClick={() => validate()}
        disabled={isRequesting}
      >
        {isRequesting ? (
          <CircularProgress />
        ) : (
          <FormattedMessage id="misc.validate" />
        )}
      </MuiButton>
    </div>
  );
}
