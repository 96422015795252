import * as React from "react";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import {
  FIXED_LINES_MODE_WALK,
  FixedLinesTrip,
} from "organisms/FixedLinesResultElement/FixedLinesTypes";
import { FixedLinesTitleSection } from "atoms/FixedLinesTitleSection/FixedLinesTitleSection";

import { useFixedLinesTripTitleStyles } from "./fixedLinesTripTitleStyles";

type FixedLinesTripProps = {
  trip: FixedLinesTrip;
  hasTitle?: boolean;
};

export function FixedLinesTripTitle({
  trip,
  hasTitle = false,
}: FixedLinesTripProps): JSX.Element {
  const classes = useFixedLinesTripTitleStyles();

  if (trip.sections.length > 0) {
    return (
      <>
        {hasTitle && (
          <Typography className={classes.titleAlternative}>
            <FormattedMessage
              id={"search.label.alternative_route"}
              defaultMessage="Itinéraire alternatif"
            />
          </Typography>
        )}
        {trip.sections
          .filter((section) => section.mode != FIXED_LINES_MODE_WALK)
          .map((section, index, { length }) => (
            <div key={index} className={classes.sectionInline}>
              <FixedLinesTitleSection section={section} />
              {length - 1 !== index && (
                <Typography className={classes.sectionJoint}>+</Typography>
              )}
            </div>
          ))}
      </>
    );
  }
  return <></>;
}
