export const TEXT_CONTENTS_ES = {
  // BLOCK 1:
  "block1-h2": "Accesibilidad",

  "block1-p1": "Esta página web no es una página web de ayuda.",
  "block1-p2":
    'Su objetivo es presentar la política de accesibilidad de la página web "{websiteURL}" y definir el nivel general de accesibilidad que se observa en la página web de acuerdo con los reglamentos y normas vigentes.',

  "block1-h3": "¿Qué es la accesibilidad digital?",

  "block1-p3":
    "La accesibilidad digital es un conjunto de normas y buenas prácticas que abarcan aspectos funcionales, gráficos, técnicos y editoriales.",
  "block1-p4":
    "Seguir estas normas y buenas prácticas garantiza que los medios digitales (páginas web, aplicaciones móviles, documentos PDF, etc.) sean accesibles para las personas con discapacidad.",
  "block1-p5": "Una página web accesible permite, por ejemplo:",

  "block1-li1":
    "Personalizar su visualización a través del sistema operativo y/o del navegador (ampliar o reducir los caracteres, cambiar la tipografía, cambiar los colores, detener las animaciones, etc.).",
  "block1-li2":
    "Navegar utilizando tecnologías de asistencia como la conversión de texto a voz o el braille.",
  "block1-li3":
    "Navegar sin usar el ratón, sólo con el teclado, con interruptores o a través de una pantalla táctil.",
  "block1-li4":
    "Ver contenidos de vídeo y audio con subtítulos y/o transcripciones.",
  "block1-li5": "Etc.",

  // BLOCK 2:
  "block2-h2": "Declaración de accesibilidad",

  "block2-p1":
    "{brand} se compromete a hacer accesibles sus páginas web (internet, intranet y extranet), sus aplicaciones móviles, sus paquetes de software y su mobiliario urbano digital, en conformidad con el artículo 47 de la ley francesa n°2005-102 de 11 de febrero de 2005.",
  "block2-p2":
    'Esta declaración de accesibilidad se aplica al sitio "{websiteURL}".',

  "block2-h3": "Estado de cumplimiento",

  "block2-p3":
    'La página web "{websiteURL}" es parcialmente conforme a la WCAG 2.1 (nivel AA), y un {a11yOverallRate} conforme a la versión 4.1 del RGAA, debido a las no conformidades y desviaciones que se indican a continuación.',

  // BLOCK 3:
  "block3-h2": "Resultados de las pruebas",

  "block3-p1a":
    "En {auditDate} se realizó una auditoría de cumplimiento de la versión 4.1 del RGAA por parte de la empresa ",
  "block3-p1b":
    ". A continuación se realizó una autoauditoría en {autoAuditDate} tras integrar las correcciones comunicadas por Ideance. Esta autoauditoría realizada por Padam Mobility revela que en la muestra :",

  "block3-li1":
    "La tasa de cumplimiento global es del {a11yOverallRate}. Este índice se obtiene dividiendo el número de criterios conformes entre el número de criterios aplicables.",
  "block3-li2":
    "La tasa media de cumplimiento es del {a11yAverageRate}. Este índice se obtiene promediando los índices de cumplimiento de cada página.",

  "block3-h3": "Contenidos no accesibles",

  "block3-h4-1": "Incumplimientos",

  "block3-p2": "Lista de criterios no conformes:",

  "block3-criterion-1-1":
    "1.1 ¿Todas las imágenes que contienen información tienen una alternativa textual?",
  "block3-criterion-1-2": "",
  "block3-criterion-1-3": "",
  "block3-criterion-3-2":
    "3.2 En cada página web, ¿el contraste entre el color del texto y el color de su fondo es suficientemente alto (excluyendo los casos especiales)?",
  "block3-criterion-6-1": "",
  "block3-criterion-7-1":
    "7.1 ¿Son los guiones, en caso necesario, compatibles con las tecnologías de asistencia?",
  "block3-criterion-7-3":
    "7.3 ¿Se puede controlar cada guión con el teclado y con cualquier dispositivo señalador (excluyendo los casos especiales)?",
  "block3-criterion-7-5":
    "7.5 En cada página web, ¿los mensajes de estado son correctamente representados por las tecnologías de asistencia?",
  "block3-criterion-8-2":
    "8.2 Para cada página web, ¿es el código fuente generado válido para el tipo de documento especificado (excluyendo los casos especiales)?",
  "block3-criterion-8-6":
    "8.6 Para cada página web con un título, ¿es relevante el título?",
  "block3-criterion-8-7":
    "8.7 En cada página web, ¿se indica cada cambio de idioma en el código fuente (excluyendo los casos especiales)?",
  "block3-criterion-8-9":
    "8.9 En todas las páginas web, las etiquetas no deben utilizarse únicamente con fines de presentación. ¿Se respeta esta norma?",
  "block3-criterion-9-2": "",
  "block3-criterion-9-3":
    "9.3 ¿Están todas las listas de cada página web correctamente estructuradas?",
  "block3-criterion-10-7":
    "10.7 En cada página web, para cada elemento que recibe el foco, ¿es visible la captura del foco?",
  "block3-criterion-10-8":
    "10.8 Para cada página web, ¿se pretende que el contenido oculto sea ignorado por las tecnologías de asistencia?",
  "block3-criterion-10-11":
    "10.11 Para cada página web, ¿puede presentarse el contenido sin utilizar el desplazamiento vertical para una ventana con una altura de 256px o el desplazamiento horizontal para una ventana con una anchura de 320px (excluyendo los casos especiales)?",
  "block3-criterion-10-12":
    "10.12 En cada página web, ¿puede el usuario redefinir las propiedades de espaciado del texto sin perder el contenido o la funcionalidad (excluyendo los casos especiales)?",
  "block3-criterion-11-1": "",
  "block3-criterion-11-3": "",
  "block3-criterion-11-5": "",
  "block3-criterion-11-9":
    "11.9 En cada formulario, ¿es relevante el título de cada botón (excluyendo los casos especiales)?",
  "block3-criterion-11-10":
    "11.10 En cada formulario, ¿se utiliza adecuadamente el control de entrada (excluyendo los casos especiales)?",
  "block3-criterion-11-13":
    "11.13 ¿Se puede deducir la finalidad de un campo de entrada para facilitar el llenado automático de los campos con los datos del usuario?",
  "block3-criterion-12-6": "",
  "block3-criterion-12-7":
    "12.7 ¿Existe un enlace de evasión o de acceso rápido al área de contenido principal en cada página web (excluyendo los casos especiales)?",

  "block3-h4-2": "Excepciones por carga desproporcionada",

  "block3-h5-1": "Menú principal de navegación",

  "block3-p-3a":
    "El menú principal de navegación desplegable en la parte conectada a la página web sigue en parte el ",
  "block3-p-3b": 'patrón de diseño ARIA"Menú o barra de menús"',
  "block3-p-4":
    "Esto no es óptimo porque este patrón de diseño no está pensado para este tipo de menú de navegación.",
  "block3-p-5":
    "Además, la estructuración HTML de este menú tiene algunas pequeñas imperfecciones.",
  "block3-p-6":
    "Sin embargo, esta excepción es posible porque este menú es perfectamente utilizable sólo con el teclado y con un lector de pantalla.",

  "block3-h5-2": "Hilo conductor",

  "block3-p-7":
    "A partir de un determinado ancho de pantalla (800px y menos), por razones funcionales y gráficas, se ha optado por no mostrar el hilo conductor.",
  "block3-p-8": "Esto podría invalidar el criterio 10.11.",
  "block3-p-9":
    "Sin embargo, esta excepción es posible porque el rastro de migas de pan es una característica no esencial para el uso adecuado de la página web.",
  "block3-p-10":
    "Además, y lo que es más importante, hay otras formas alternativas de saber en qué parte de la página web se encuentra y de volver a las páginas web vistas anteriormente.",

  "block3-h5-3": 'Funcionalidad "Reloj”',

  "block3-p-11":
    'La funcionalidad "reloj", que permite rellenar los campos "hora" de la página web de búsqueda de trayectos, por ejemplo, no es accesible, sobre todo con el teclado solo y con un lector de pantalla.',
  "block3-p-12":
    'Sin embargo, esta excepción es posible porque es posible introducir estos campos "Hora" a mano, sin utilizar necesariamente esta funcionalidad.',
  "block3-p-13": "Esta es una alternativa accesible.",

  "block3-h4-3": "Contenidos no sujetos a la obligación de accesibilidad",

  "block3-p-14":
    "Los mapas interactivos de Google en las páginas web de detalles de las reservas.",

  // BLOCK 4:
  "block4-h2": "Preparación de esta declaración de accesibilidad",

  "block4-p-1": "Esta declaración fue emitida el {autoAuditDate}.",

  "block4-h3-1": "Tecnologías utilizadas para crear la página web",

  "block4-h3-2": "Entorno de prueba",

  "block4-p-2":
    "Las pruebas se realizaron con las siguientes combinaciones de navegador web y lector de pantalla:",

  "block4-h3-3": "Herramientas para evaluar la accesibilidad",

  "block4-li-12": "Herramientas de desarrollo de Firefox",
  "block4-li-13": "Web Developer (extensión de Firefox)",

  "block4-h3-4":
    "Páginas de la página web que han sido objeto de la comprobación de conformidad",

  "block4-li-14": "Conexión",
  "block4-li-15": "Registro",
  "block4-li-16": "Búsqueda de trayectos",
  "block4-li-17": "Trayectos sugeridos",
  "block4-li-18": "Reserva",
  "block4-li-19": "Configuración de la cuenta",

  // BLOCK 5:
  "block5-h2": "Comentarios y contacto",

  "block5-p-1":
    "Si no puede acceder a cualquier contenido o servicio de esta página web, puede ponerse en contacto con nosotros a través de uno de los medios indicados a continuación para que le dirijamos a una alternativa accesible o para obtener el contenido de otra forma.",
  "block5-p-2":
    "Si no puede acceder a algún contenido o servicio de esta página web, puede ponerse en contacto con nosotros para que le dirijamos a una alternativa accesible o para obtener el contenido de otra forma. Escríbanos por correo a la siguiente dirección:",

  "block5-h3": "Remedios",

  "block5-p-3": "Este procedimiento debe utilizarse en el siguiente caso.",
  "block5-p-4":
    "Ha notificado al operador de la página web una falta de accesibilidad que le impide acceder a los contenidos o servicios y no ha recibido una respuesta satisfactoria.",

  "block5-li-1":
    "Escribe un mensaje al Defensor francés de los Derechos Humanos",
  "block5-li-2":
    "Ponte en contacto con el delegado del Defensor francés de los Derechos Humanos en tu región",
  "block5-li-3":
    "Envíe un correo (gratuito, no ponga sello) a la siguiente dirección:",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Apéndice",
};
