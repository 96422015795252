import { ResourceType } from "api/resources/types/resourceType";

export const customer: ResourceType = {
  getCustomerProfile: {
    endpoint: "/get-customer-profile",
    method: "GET",
    impersonate: true,
  },
  updateUserPassword: {
    endpoint: "/update-user-password",
    method: "POST",
    impersonate: true,
  },
  updateUserProfile: {
    endpoint: "/update-user-profile",
    method: "POST",
    impersonate: true,
    requiredParameters: {
      email: "string",
      phone_number: "string",
      first_name: "string",
      last_name: "string",
      has_terms_accepted: "boolean",
    },
  },
  updateUserProfileByAdmin: {
    endpoint: "/customers/{id}/update-user-profile",
    method: "PUT",
    impersonate: false,
    adminzone: true,
  },
  deleteCustomerAccount: {
    endpoint: "/delete-customer-account",
    method: "POST",
    impersonate: true,
  },
  getCustomerPropositionPrice: {
    endpoint: "/get-customer-proposition-price",
    method: "POST",
    impersonate: true,
  },
  getCustomerCancelledReservations: {
    endpoint: "/get-customer-cancelled-bookings",
    method: "GET",
    impersonate: true,
  },
  getCustomerLastBookings: {
    endpoint: "/get-customer-last-bookings",
    method: "GET",
    impersonate: true,
  },
  getCustomerList: {
    endpoint: "/customers/",
    method: "GET",
    apiVersion: "2.0",
  },
  getCustomerNextReservations: {
    endpoint: "/get-customer-next-bookings",
    method: "GET",
    impersonate: true,
  },
  getCustomerRecurringReservations: {
    endpoint: "/get-customer-recurring-bookings",
    method: "GET",
    impersonate: true,
  },
  getCustomerPastReservations: {
    endpoint: "/get-customer-past-bookings",
    method: "GET",
    impersonate: true,
  },
  patchIndicationsToDriver: {
    // WARNING: customerId below must be userProfile.customerId, not a customerId relative to a booking:
    endpoint: "/customers/{customerId}/bookings/{bookingId}/",
    method: "PATCH",
    impersonate: true,
    apiVersion: "2.0",
  },
  getPassenger: {
    endpoint: "/customers/{customerId}/bookings/{bookingId}/passengers/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  addPassengerToBooking: {
    endpoint: "/customers/{customerId}/bookings/{bookingId}/passengers/",
    method: "POST",
    impersonate: true,
    apiVersion: "2.0",
  },
  removePassengerFromBooking: {
    endpoint:
      "/customers/{customerId}/bookings/{bookingId}/passengers/{profileId}/",
    method: "DELETE",
    impersonate: true,
    apiVersion: "2.0",
  },
  getBookingPersonalItems: {
    endpoint:
      "/customers/{customerId}/bookings/{bookingId}/personalequipments/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  addPersonalItem: {
    endpoint:
      "/customers/{customerId}/searchrequests/{searchrequestId}/personalequipments/",
    method: "POST",
    impersonate: true,
    apiVersion: "2.0",
  },
  getNotificationPreferences: {
    endpoint: "/customers/{customerId}/notification-preferences/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  addNotificationPreferences: {
    endpoint: "/customers/{customerId}/notification-preferences/",
    method: "POST",
    impersonate: true,
    apiVersion: "2.0",
  },
  updateNotificationPreferences: {
    endpoint:
      "/customers/{customerId}/notification-preferences/{notificationPreferenceId}/",
    method: "PATCH",
    impersonate: true,
    apiVersion: "2.0",
  },
};
