import React from "react";
import { AccordionDetails } from "@material-ui/core";
import { DiscountCard as DiscountCardWrapped } from "molecules/DiscountCard/DiscountCard";

import "./discountCard.cssmodule.scss";
import { DiscountProfile } from "types/payment";

type DiscountCardProps = {
  availableDiscountProfiles: DiscountProfile[];
};

export function DiscountCard(props: DiscountCardProps) {
  const { availableDiscountProfiles } = props;

  return (
    <AccordionDetails>
      <ul className="discountList">
        {availableDiscountProfiles.map((discountProfile) => (
          <li className="discountListItem" key={discountProfile.id}>
            <DiscountCardWrapped discountProfile={discountProfile} />
          </li>
        ))}
      </ul>
    </AccordionDetails>
  );
}
