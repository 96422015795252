import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button as MuiButton,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import dayjs from "dayjs";

import { TimePicker } from "components/TimePicker/TimePicker.jsx";
import PaperBox from "components/PaperBox/PaperBox";

import { FORMAT_HOUR } from "utils/constants";

import { useReservationFormStyles } from "./reservationFormStyles";

export default function ReservationForm(props) {
  const classes = useReservationFormStyles();

  const { onFieldChange, onSubmit, reservation } = props;

  if (!reservation) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <PaperBox
      title={<FormattedMessage id="reservationEdition.sidebar.title" />}
    >
      <form className={classes.formContainer} onSubmit={onSubmit}>
        <Select
          className={classes.selectContainer}
          value={reservation.time_restriction_type.toLowerCase()}
          disabled
        >
          <MenuItem value={"departure"}>
            <FormattedMessage id="search.departure_time" />
          </MenuItem>

          <MenuItem value={"arrival"}>
            <FormattedMessage id="search.arrival_time" />
          </MenuItem>
        </Select>

        <TimePicker
          baseDatetime={reservation.pickup_time}
          defaultValue={dayjs(reservation.pickup_time).tz().format(FORMAT_HOUR)}
          onFieldChange={(newDatetime) =>
            onFieldChange("time", newDatetime.format())
          }
        />

        <TextField
          className={classes.positionAddress}
          label={<FormattedMessage id="search.pickup" />}
          defaultValue={reservation.departure_position.address}
          disabled={true}
        />

        <TextField
          className={classes.positionAddress}
          label={<FormattedMessage id="search.dropoff" />}
          defaultValue={reservation.destination_position.address}
          disabled={true}
        />

        <TextField
          className={classes.positionAddress}
          label={<FormattedMessage id="search.passengersNumber" />}
          defaultValue={reservation.requested_seats}
          disabled={true}
        />

        <MuiButton
          className={classes.submitButton}
          type="submit"
          color="inherit"
          variant="outlined"
        >
          <FormattedMessage id="search.submit" />
        </MuiButton>
      </form>
    </PaperBox>
  );
}
