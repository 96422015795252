import React from "react";
import { useIntl } from "react-intl";

import MuiButton from "@material-ui/core/Button";

import styles from "./form.cssmodule.scss";

export default function FavoriteLoader(props) {
  const { loadFavorite } = props;
  const intl = useIntl();

  return (
    <div className={styles.favoriteLoadersWrapper}>
      <MuiButton
        size="small"
        onClick={() => loadFavorite("home_to_workplace")}
        color="inherit"
        variant="outlined"
        component="div"
        aria-label={intl.formatMessage({ id: "help.fav1To2" })}
        style={{ padding: "0.2rem 2rem" }}
        data-testid="favorite-1-to-2-btn"
      >
        {intl.formatMessage({
          id: "favorite.trip_1_to_2",
        })}
      </MuiButton>

      <MuiButton
        size="small"
        onClick={() => loadFavorite("workplace_to_home")}
        color="inherit"
        variant="outlined"
        aria-label={intl.formatMessage({ id: "help.fav2To1" })}
        style={{ padding: "0.2rem 2rem" }}
      >
        {intl.formatMessage({
          id: "favorite.trip_2_to_1",
        })}
      </MuiButton>
    </div>
  );
}
