import React from "react";

import { Link, Typography } from "@material-ui/core";

import { useA11yDeclarationStyles } from "../a11yDeclarationStyles";

export function A11yDeclarationBlock3UK({
  a11yOverallRate,
  a11yAverageRate,
  auditDate,
  autoAuditDate,
  textContents,
}) {
  const classes = useA11yDeclarationStyles();

  return (
    <>
      <Typography component="h2">{textContents["block3-h2"]}</Typography>

      <Typography component="p">
        {textContents["block3-p1a"]
          .replace("{auditDate}", auditDate)
          .replace("{autoAuditDate}", autoAuditDate)}
        <Link
          className={classes.link}
          href="https://ideance.net/fr/"
          target="_blank"
        >
          {"Ideance"}
        </Link>
        {textContents["block3-p1b"].replace("{autoAuditDate}", autoAuditDate)}
      </Typography>

      <ul>
        <Typography component="li">
          {textContents["block3-li1"].replace(
            "{a11yOverallRate}",
            a11yOverallRate,
          )}
        </Typography>

        <Typography component="li">
          {textContents["block3-li2"].replace(
            "{a11yAverageRate}",
            a11yAverageRate,
          )}
        </Typography>
      </ul>

      <Typography component="h3">{textContents["block3-h3"]}</Typography>

      <Typography component="h4">{textContents["block3-h4-1"]}</Typography>

      <ul>
        <Typography component="li">{textContents["block3-uk-li-1"]}</Typography>

        <Typography component="li">{textContents["block3-uk-li-2"]}</Typography>

        <Typography component="li">{textContents["block3-uk-li-3"]}</Typography>

        <Typography component="li">{textContents["block3-uk-li-4"]}</Typography>
      </ul>
    </>
  );
}
