import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function UserCard(props) {
  return (
    <SvgIcon {...props}>
      <svg>
        <g fill="none" fillRule="evenodd">
          <g fill="#717587">
            <path d="M19.845 5c.632 0 1.133.606 1.133 1.371v11.258H21c0 .765-.501 1.371-1.133 1.371H4.133C3.501 19 3 18.394 3 17.629V6.371C3 5.606 3.501 5 4.133 5h15.712zM8.69 12.528l-.354.004-.439.016c-.271.015-.49.038-.6.07-.768.239-1.297.955-1.297 1.757v.717l.008.08c.035.182.19.313.384.313h4.59l.081-.008c.182-.036.312-.19.312-.385v-.717l-.003-.149c-.047-.742-.557-1.4-1.276-1.608-.114-.032-.337-.055-.61-.07l-.442-.016c-.232-.005-.476-.005-.708 0zm8.214 1.233h-4.078l-.095.009c-.245.045-.434.262-.434.52 0 .29.239.53.529.53h4.078l.095-.01c.246-.045.434-.262.434-.52 0-.29-.221-.529-.529-.529zm0-2.065h-4.078l-.095.009c-.245.045-.434.262-.434.52 0 .29.239.53.529.53h4.078l.095-.01c.246-.044.434-.262.434-.52 0-.29-.221-.529-.529-.529zM8.696 9c-.848 0-1.536.688-1.536 1.536 0 .848.688 1.536 1.536 1.536.848 0 1.536-.688 1.536-1.536C10.232 9.688 9.544 9 8.696 9zm8.208.648h-4.078l-.095.01c-.245.044-.434.262-.434.52 0 .29.239.528.529.528h4.078l.095-.008c.246-.045.434-.263.434-.52 0-.29-.221-.53-.529-.53z" />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default UserCard;
