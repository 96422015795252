import React from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import ReservationForm from "containers/ReservationForm/ReservationForm.jsx";
import SearchResultConfirm from "containers/SearchResultConfirm/SearchResultConfirm.jsx";
import SearchResultErrors from "containers/SearchResultErrors/SearchResultErrors.jsx";
import SearchResultGrid from "containers/SearchResultGrid/SearchResultGrid.jsx";

import { useEditionViewStyles } from "./editionViewStyles";

function EditionView(props) {
  const classes = useEditionViewStyles();
  const intl = useIntl();
  const params = useParams();

  const { reservation } = props;

  if (_.isEmpty(reservation))
    return (
      <div className={classes.contentWrapper}>
        <h1 className="visuallyHidden">
          {intl.formatMessage({ id: "edition.title" })}
        </h1>
        <CircularProgress />
      </div>
    );

  return (
    <div className={classes.contentWrapper}>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "edition.title" })}
      </h1>

      <div className={classes.contentColumn}>
        <ReservationForm reservationId={params.id} />
      </div>

      <div className={classes.contentColumn}>
        <SearchResultGrid edition />
      </div>

      <div className={classes.contentColumn}>
        <SearchResultErrors edition />

        <SearchResultConfirm edition />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  reservation: state.reservation.reservation,
});

export default connect(mapStateToProps)(EditionView);
