import React, { useState, useContext, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import dayjs from "dayjs";
import { pickBy } from "lodash";

import { settings } from "config/app";

import SearchForm from "components/SearchForm/SearchForm.jsx";

import { TerritoryContext } from "contexts/TerritoryContext";
import { ProductContext } from "contexts/ProductContext";

import { useDeepLink } from "hooks/useDeepLink";

import {
  actualizeDeparture,
  changePassengersCount,
  changePersonalItems,
  changeIndicationsToDriver,
  loadFavorite,
  onAddressSelected,
  onAddressSwap,
  onDayClick,
  onFieldChange,
  submitSearchForm,
} from "./actions.js";

export function SearchFormContainer() {
  const dispatch = useDispatch();

  const {
    recurrence,
    selectedDays,
    time,
    nodes,
    isRequestingNodes,
    isCallCenter,
  } = useSelector((state) => ({
    recurrence: state.search.searchForm.recurrence,
    selectedDays: state.search.searchForm.selectedDays,
    time: state.search.searchForm.time,
    nodes: state.search.nodes,
    isRequestingNodes: state.search.isRequestingNodes,
    isCallCenter:
      state.impersonateForm.sessionStarted &&
      !state.impersonateForm.impersonateByReferent,
  }));
  const formData = useSelector((state) => state.search.searchForm);
  const profiles = useSelector((state) => state.passengersProfiles.profiles);
  const errors = useSelector((state) => state.search.formErrors);

  const { selectedTerritory } = useContext(TerritoryContext);
  const { productParameters } = useContext(ProductContext);

  const navigate = useNavigate();
  const deepLinkParameters = useDeepLink();

  const isMapEnabled = useMemo(() => {
    return (
      window.localStorage.getItem(settings.localStorageKeys.isSearchMapOpen) ===
        null ||
      window.localStorage.getItem(settings.localStorageKeys.isSearchMapOpen) ===
        "true"
    );
  }, []);

  const [isMapOpen, setIsMapOpen] = useState(isMapEnabled);

  const toggleMap = () => {
    window.localStorage.setItem(
      settings.localStorageKeys.isSearchMapOpen,
      !isMapOpen,
    );
    setIsMapOpen(!isMapOpen);
  };

  const customFieldInfos = useMemo(
    () =>
      pickBy(
        selectedTerritory?.extras?.custom_fields?.search_request || {},
        (cf) => cf.type === "select" || cf.type === "str",
      ),
    [selectedTerritory],
  );

  // Don't let time or selectedDays in null state:
  useEffect(() => {
    if (!time && !deepLinkParameters.isDeepLink) {
      dispatch(actualizeDeparture());
    }
  }, [time, selectedDays]);

  useEffect(() => {
    selectedDays.forEach((selectedDay) => {
      if (dayjs(selectedDay).isBefore(dayjs(new Date()), "day")) {
        dispatch(onDayClick(selectedDay, selectedTerritory));
      }
    });
  }, []);

  // For recurrence, dont let start > end, considering that they are days:
  useEffect(() => {
    const newRecurrence = { ...recurrence };

    let changed = false;

    // Set start_datetime at today if (null or strictly before today):
    if (
      !recurrence.start_datetime ||
      (dayjs(recurrence.start_datetime).isBefore(dayjs()) &&
        !dayjs(recurrence.start_datetime).isSame(dayjs(), "day"))
    ) {
      changed = true;

      newRecurrence.start_datetime = dayjs().format();
    }

    // Set end_datetime at start + 1 week if null or (before or same day as start_datetime)
    if (
      !recurrence.end_datetime ||
      dayjs(recurrence.end_datetime).isSame(
        dayjs(recurrence.start_datetime),
        "day",
      ) ||
      dayjs(recurrence.end_datetime).isBefore(dayjs(recurrence.start_datetime))
    ) {
      changed = true;

      newRecurrence.end_datetime = recurrence.start_datetime
        ? dayjs(recurrence.start_datetime).add(1, "week").format()
        : dayjs().add(1, "week").format();
    }

    if (changed) {
      dispatch(onFieldChange("recurrence", newRecurrence));
    }
  }, [recurrence.start_datetime, recurrence.end_datetime]);

  const onSubmit = async () => {
    if (formData.asap) {
      await dispatch(actualizeDeparture());

      // Doesn't actualize formData here despise the "await", so do it manually:
      const now = dayjs.tz().format("YYYY-MM-DDTHH:mm:ssZ");

      dispatch(
        submitSearchForm(
          navigate,
          {
            ...formData,
            recurringOfferId: 0,
            selectedDays: now,
            time: now,
            timeRestrictionType: "departure",
            passengers: profiles,
          },
          selectedTerritory,
          productParameters,
        ),
      );
    } else {
      dispatch(
        submitSearchForm(
          navigate,
          { ...formData, passengers: profiles },
          selectedTerritory,
          productParameters,
        ),
      );
    }
  };

  const swapActive = () => {
    const { departure, destination } = formData;

    return Boolean(departure.display.length && destination.display.length);
  };

  return (
    <SearchForm
      isMapOpen={isMapOpen}
      toggleMap={toggleMap}
      actualizeDeparture={() => dispatch(actualizeDeparture())}
      customFieldInfos={customFieldInfos}
      errors={errors}
      formData={formData}
      profiles={profiles}
      loadFavorite={(favoriteType) => {
        dispatch(loadFavorite(favoriteType));
      }}
      onAddressSelected={(
        suggestion,
        nodeId,
        type,
        latitude,
        longitude,
        address,
        placeId,
      ) => {
        dispatch(
          onAddressSelected(
            suggestion,
            nodeId,
            type,
            latitude,
            longitude,
            address,
            placeId,
          ),
        );
      }}
      onDayClick={(day) => dispatch(onDayClick(day, selectedTerritory))}
      onFieldChange={(field, value) => {
        dispatch(onFieldChange(field, value));
      }}
      onPassengersChange={(type, value) =>
        dispatch(changePassengersCount(type, value))
      }
      onPersonalItemsChange={(value) => dispatch(changePersonalItems(value))}
      onIndicationsToDriverChange={(indications) =>
        dispatch(changeIndicationsToDriver(indications))
      }
      onSubmit={onSubmit}
      swapActive={swapActive}
      swapAddresses={() => {
        dispatch(onAddressSwap());
      }}
      territory={selectedTerritory}
      nodes={nodes}
      isRequestingNodes={isRequestingNodes}
      isAdmin={isCallCenter}
    />
  );
}
