import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, CircularProgress, TextField } from "@material-ui/core";

import { SuccessIcon } from "components/Icons/Success";

import { TerritoryContext } from "contexts/TerritoryContext";

import { checkPromoCode, resetError } from "containers/Payment/actions";

import { usePromoCodeStyles } from "organisms/PromoCode/promoCodeStyles";

type stateInterface = {
  payment: {
    isRequestingPromoCode: boolean;
    isPromoCodeValid: boolean;
    promoCode: string;
    errorMessage: string;
  };
};

export function PromoCode() {
  const dispatch = useDispatch();

  useEffect(() => {
    if (errorMessage) {
      dispatch(resetError());
    }
  }, []);

  const [disabledTextField, setDisabledTextField] = useState(false);
  const [valid, setValid] = useState(false);

  const { isRequestingPromoCode, isPromoCodeValid, promoCode, errorMessage } =
    useSelector((state: stateInterface) => ({
      isRequestingPromoCode: state?.payment?.isRequestingPromoCode,
      isPromoCodeValid: state?.payment?.isPromoCodeValid,
      promoCode: state?.payment?.promoCode,
      errorMessage: state?.payment?.errorMessage,
    }));
  const [promoCodeTmp, setPromoCodeTmp] = useState(promoCode);
  const [getErrorMessage, setErrorMessage] = useState(errorMessage);

  useEffect(() => {
    if (promoCodeTmp !== promoCode) {
      dispatch(resetError());
    }
    if (errorMessage && promoCodeTmp !== "") {
      setErrorMessage(errorMessage);
    } else {
      setErrorMessage("");
    }
  }, [errorMessage, promoCodeTmp]);

  useEffect(() => {
    if (!isPromoCodeValid) {
      setDisabledTextField(false);
      setValid(false);
      return;
    }
    const valid = Object.values(isPromoCodeValid).find(
      (value) => value === true,
    );
    setValid(valid);
    if (valid) {
      setDisabledTextField(true);
    } else {
      setDisabledTextField(false);
    }
  }, [isPromoCodeValid, promoCode]);

  const { selectedTerritory } = useContext(TerritoryContext);

  const classes = usePromoCodeStyles();

  return (
    <div className={classes.promoCodeContainer}>
      <TextField
        autoComplete="off"
        id="promo-code"
        variant="outlined"
        error={!!getErrorMessage}
        helperText={getErrorMessage}
        color={isPromoCodeValid ? undefined : "primary"}
        label={<FormattedMessage id="payment.promoCode" />}
        value={(promoCodeTmp || "").toUpperCase()}
        onChange={(e) => setPromoCodeTmp(e.target.value.toUpperCase())}
        disabled={disabledTextField}
        name="promo code"
      />

      {!valid ? (
        <Button
          className={classes.okButton}
          variant="outlined"
          onClick={() => {
            if (!promoCodeTmp) {
              return;
            }
            dispatch(
              checkPromoCode({
                promoCode: promoCodeTmp,
                selectedTerritoryKey: selectedTerritory?.territory_key,
              }),
            );
          }}
        >
          {isRequestingPromoCode ? (
            <CircularProgress />
          ) : (
            <FormattedMessage id="payment.promocode_apply" />
          )}
        </Button>
      ) : (
        <div className={classes.successIconContainer}>
          <SuccessIcon />
        </div>
      )}
    </div>
  );
}
