import { createIntl } from "react-intl";
import { initializePhraseAppEditor } from "react-intl-phraseapp";

import { settings } from "config/app";

// for old browser compatibility
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-pluralrules/locale-data/fr"; // Add locale data for fr
import "@formatjs/intl-pluralrules/locale-data/en"; // Add locale data for en
import "@formatjs/intl-pluralrules/locale-data/it"; // Add locale data for it
import "@formatjs/intl-pluralrules/locale-data/es"; // Add locale data for es
import "@formatjs/intl-pluralrules/locale-data/de"; // Add locale data for de
import "@formatjs/intl-pluralrules/locale-data/sv"; // Add locale data for sv
import "@formatjs/intl-pluralrules/locale-data/da"; // Add locale data for da

// Line below bugs in Safari
// import '@formatjs/intl-relativetimeformat/polyfill'
import "@formatjs/intl-relativetimeformat/locale-data/fr"; // Add locale data for fr
import "@formatjs/intl-relativetimeformat/locale-data/en"; // Add locale data for en
import "@formatjs/intl-relativetimeformat/locale-data/it"; // Add locale data for it
import "@formatjs/intl-relativetimeformat/locale-data/es"; // Add locale data for es
import "@formatjs/intl-relativetimeformat/locale-data/de"; // Add locale data for de
import "@formatjs/intl-relativetimeformat/locale-data/sv"; // Add locale data for sv
import "@formatjs/intl-relativetimeformat/locale-data/da"; // Add locale data for da

import caTranslations from "./translations/ca.json";
import deTranslations from "./translations/de.json";
import enTranslations from "./translations/en.json";
import esTranslations from "./translations/es.json";
import frTranslations from "./translations/fr.json";
import frCaTranslations from "./translations/fr-CA.json";
import itTranslations from "./translations/it.json";
import nlTranslations from "./translations/nl.json";
import svTranslations from "./translations/sv.json";
import daTranslations from "./translations/da.json";

export const translations = {
  ca: caTranslations,
  de: deTranslations,
  en: enTranslations,
  es: esTranslations,
  fr: frTranslations,
  "fr-ca": frCaTranslations,
  it: itTranslations,
  nl: nlTranslations,
  sv: svTranslations,
  da: daTranslations,
};

/** our cached version of the IntlProvider we pass through redux */
let _intl = null;

/**
 * This function will be injected into each thunk-actions through
 * the getIntl prop. It allows to use the IntlProvider API inside redux actions
 */

export const getLang = () => {
  const currentLanguage = window.localStorage.getItem(
    settings.localStorageKeys.language,
  );
  const browser_says =
    (navigator.languages ? navigator.languages[0] : false) ||
    navigator.language ||
    navigator.browserLanguage ||
    "en";

  if (currentLanguage === "fr-ca" || browser_says.toLowerCase() === "fr-ca") {
    return "fr-ca";
  }

  const lang = currentLanguage || browser_says.split("-")[0].toLowerCase();

  return translations[lang] ? lang : "en";
};

export const getMessages = (language) => {
  const lang = language || getLang();

  if (lang === "fr-ca") {
    // French Canadian fallbacks to French, which fallbacks to English
    return { ...translations.en, ...translations.fr, ...translations[lang] };
  }

  if (lang === "ca") {
    // Catalan fallbacks to Spanish, which fallbacks to English
    return { ...translations.en, ...translations.es, ...translations[lang] };
  }

  if (translations[lang]) {
    // Any other language fallbacks to English
    return { ...translations.en, ...translations[lang] };
  }

  // Default language: English
  return translations.en;
};

export const getIntl = () => {
  if (!_intl) {
    _intl = createIntl({
      locale: getLang(),
      messages: getMessages(),
    });
  }

  return _intl;
};

const phraseAppEditorConfig = {
  projectId: "7eb2b91af8f981d6f72ac07efa0d989f",
  phraseEnabled:
    typeof PHRASE_IN_CONTEXT_EDITOR === "boolean"
      ? PHRASE_IN_CONTEXT_EDITOR
      : false,
  prefix: "[[__",
  suffix: "__]]",
  fullReparse: true,
};

initializePhraseAppEditor(phraseAppEditorConfig);
