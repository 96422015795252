import React, { useState } from "react";

import {
  Box,
  Button as MuiButton,
  CircularProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import Delete from "components/Icons/Delete";
import { FormattedMessage } from "react-intl-phraseapp";
import { StripeIbanType } from "types/payment";

const useStyles = makeStyles({
  root: {
    padding: "0.5rem 0 2rem 0",
  },
  paper: {
    padding: "1rem",
  },
});

type SavedIbanProps = {
  iban: StripeIbanType;
  deleteIban: (id: number) => void;
};

export default function SavedIban(props: SavedIbanProps) {
  const { iban, deleteIban } = props;

  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="flex w-full flex-row">
      <div className="flex w-8/12 flex-col md:flex-row">
        <Typography className="flex w-full justify-center md:w-8/12">{`${iban.data.country}**${iban.data.bank_code}${iban.data.branch_code}********${iban.data.last4}`}</Typography>
        <Typography className="flex w-full justify-center md:w-4/12">
          <Box mx={2}>{iban.name}</Box>
        </Typography>
      </div>
      <div className="flex w-4/12 justify-center">
        <MuiButton
          aria-label="delete saved iban"
          onClick={handleClickOpen}
          data-testid="delete-saved-iban"
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <Delete style={{ color: "#D0021B" }} />
          )}
        </MuiButton>
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={classes}
      >
        <DialogTitle id="alert-dialog-title">
          <FormattedMessage id={"ACTION_DELETE_IBAN"} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" />
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={handleClose} variant="outlined" color="inherit">
            <FormattedMessage id={"cancel"} />
          </Button>
          <Button
            onClick={() => {
              setLoading(true);
              deleteIban(iban.id);
            }}
            autoFocus
            variant="contained"
            color="primary"
            data-testid="confirm-card-deletion"
          >
            <FormattedMessage id={"delete"} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
