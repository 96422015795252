import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { CircularProgress, IconButton, Typography } from "@material-ui/core";

import { SelectedDiscountListItem } from "./SelectedDiscountListItem";

import { useSelectedDiscountListStyles } from "./selectedDiscountListStyles";
import { useDispatch, useSelector } from "react-redux";
import {
  loadDiscountedPrices,
  unsetPromoCode,
} from "containers/Payment/actions";
import { FormattedMessage } from "react-intl-phraseapp";
import { Price } from "atoms/Price/Price";
import { DeleteCross } from "components/Icons/DeleteCross";
import { TerritoryContext } from "contexts/TerritoryContext";
import {
  PAYMENT_PAGE,
  PERCENTAGE,
  SUBTRACTION,
} from "containers/Payment/PaymentDiscounts/PaymentDiscountsConstants";
import { useLocation } from "react-router-dom";

export function SelectedDiscountList(props) {
  const { totalPassengers } = props;
  const intl = useIntl();
  const dispatch = useDispatch();

  const { selectedTerritory } = useContext(TerritoryContext);
  const isMultiDateEnabled = selectedTerritory?.booking?.multi_date?.enabled;

  const [standardTicketsQuantity, setStandardTicketsQuantity] =
    useState(totalPassengers);
  const [selectedFlatFares, setSelectedFlatFares] = useState([]);
  const [selectedSchemes, setSelectedSchemes] = useState([]);
  const [isCodeValid, setIsCodeValid] = useState(false);
  const [totalCodePrice, setTotalCodePrice] = useState(0);
  const [standardTicketPrice, setStandardTicketPrice] = useState(0);

  const location = useLocation();
  const isNotRemovable = location.pathname !== PAYMENT_PAGE;

  const classes = useSelectedDiscountListStyles();

  const { discountedPrices, discountedPricesLoading } = useSelector(
    (state) => ({
      discountedPrices: state?.payment?.discountedPrices,
      discountedPricesLoading: state?.payment?.isDiscountLoading,
    }),
  );
  const promoCode = useSelector((state) => state?.payment?.promoCode);
  const isPromoCodeValid = useSelector(
    (state) => state?.payment?.isPromoCodeValid,
  );
  const discountedPricesState = useSelector(
    (state) => state?.payment?.discountedPrices,
  );
  const discountedPricesMultiDate = useSelector(
    (state) => state?.payment?.discountedPricesMultiDate,
  );
  const selectedProposals = useSelector(
    (state) => state?.search?.selectedProposals,
  );
  const responses = useSelector((state) => state?.search?.responses);

  const [nbBookings, setNbBookings] = useState(0);

  useEffect(() => {
    let nbBookings = 1;
    if (isMultiDateEnabled && discountedPricesMultiDate) {
      nbBookings = Object.keys(selectedProposals).length;
    }
    setNbBookings(nbBookings);
  }, [selectedProposals, discountedPricesMultiDate, isMultiDateEnabled]);

  useEffect(() => {
    if (!isPromoCodeValid) {
      return;
    }
    const valid = Object.values(isPromoCodeValid).find(
      (value) => value === true,
    );
    setIsCodeValid(valid);
  }, [isPromoCodeValid]);

  useEffect(() => {
    const totalPriceCodeDiscounted =
      discountedPricesState?.promo_code_discount_price || 0;
    const discountedMultiDateArray = Object.values(
      discountedPricesMultiDate || {},
    );

    const multiDateTotalPriceCode = discountedMultiDateArray?.reduce(
      (acc, value) => {
        return acc + value?.promo_code_discount_price;
      },
      0,
    );

    setTotalCodePrice(
      totalPriceCodeDiscounted > 0
        ? totalPriceCodeDiscounted
        : multiDateTotalPriceCode,
    );
  }, [
    isPromoCodeValid,
    discountedPricesState,
    discountedPricesMultiDate,
    promoCode,
  ]);

  useEffect(() => {
    const discountedPricesMultiDateValues = Object.values(
      discountedPricesMultiDate || {},
    );

    if (discountedPricesMultiDateValues.length > 0) {
      setStandardTicketPrice(
        discountedPricesMultiDateValues[0].initial_unit_price ||
          discountedPricesMultiDateValues[0].initial_price ||
          0,
      );
    } else {
      setStandardTicketPrice(
        discountedPrices?.initial_unit_price ||
          discountedPrices?.initial_price ||
          0,
      );
    }
  }, [discountedPrices, discountedPricesMultiDate]);

  useEffect(() => {
    const discountedPricesMultiDateValues = Object.values(
      discountedPricesMultiDate || {},
    );

    const tmpDiscountedPrices =
      discountedPricesMultiDateValues.length > 0
        ? discountedPricesMultiDateValues[0]
        : discountedPrices;
    if (!discountedPricesLoading && tmpDiscountedPrices?.price_breakdown) {
      setSelectedFlatFares(
        tmpDiscountedPrices.price_breakdown.filter(
          (discount) =>
            discount.discount_profile.discount_type === SUBTRACTION &&
            Boolean(discount.passengers_number),
        ),
      );
      setSelectedSchemes(
        tmpDiscountedPrices.price_breakdown.filter(
          (discount) =>
            discount.discount_profile.discount_type === PERCENTAGE &&
            Boolean(discount.passengers_number),
        ),
      );
    }
  }, [discountedPrices, discountedPricesMultiDate, discountedPricesLoading]);

  useEffect(() => {
    const selectedFlatFaresQuantity = selectedFlatFares.reduce(
      (acc, discount) => acc + discount.passengers_number,
      0,
    );

    setStandardTicketsQuantity(
      nbBookings * (totalPassengers - selectedFlatFaresQuantity),
    );
  }, [selectedFlatFares, selectedSchemes, nbBookings, totalPassengers]);

  const handleRemoveDiscount = (discountProfileId) => {
    const newSelectedDiscounts = discountedPrices?.price_breakdown
      .filter(
        (discountedPrice) =>
          discountedPrice.discount_profile.id !== discountProfileId,
      )
      .map((discountedPrice) => ({
        discountId: discountedPrice.discount_profile.id,
        quantity: discountedPrice.passengers_number,
      }));

    dispatch(
      loadDiscountedPrices({
        selectedProposals: selectedProposals,
        responses: responses,
        promoCode: promoCode,
        isPromoCodeValid: isPromoCodeValid,
        newSelectedDiscounts: newSelectedDiscounts,
      }),
    );
  };

  if (discountedPricesLoading) {
    return (
      <div className={classes.loaderContainer}>
        <CircularProgress />
      </div>
    );
  }

  const onClickResetPromoCode = () => {
    dispatch(unsetPromoCode());
    dispatch(
      loadDiscountedPrices({
        selectedProposals: selectedProposals,
        responses: responses,
      }),
    );
  };

  const renderSelectedDiscountListItem = ({
    discount,
    price,
    passengersNumber = discount.passengers_number,
    isDiscountScheme = false,
  }) => {
    let isNotRemovable = discount.isNotRemovable;
    if (discount?.discount_profile?.is_public === false) {
      isNotRemovable = true;
    }

    return (
      <SelectedDiscountListItem
        discountName={discount.discount_profile.name}
        discountProfileId={discount.discount_profile.id}
        discountValue={`-${discount.discount_value?.toFixed(2)}${
          discount.discount_profile.discount_type === PERCENTAGE ? "%" : ""
        }`}
        handleRemoveDiscount={handleRemoveDiscount}
        isDiscountScheme={isDiscountScheme}
        quantity={passengersNumber}
        price={price}
        isNotRemovable={isNotRemovable}
        key={discount.discount_profile.id}
      />
    );
  };

  return (
    <ul className={classes.discountList}>
      {standardTicketsQuantity > 0 ? (
        <SelectedDiscountListItem
          discountName={intl.formatMessage({
            id: "ticketing.standard_ticket",
            defaultMessage: "Standard ticket",
          })}
          isDiscountScheme={false}
          quantity={standardTicketsQuantity}
          price={standardTicketPrice * standardTicketsQuantity}
        />
      ) : null}
      {selectedSchemes.length > 0
        ? selectedSchemes
            .filter((discount) => Boolean(discount.passengers_number))
            .map((discount) =>
              renderSelectedDiscountListItem({
                discount,
                passengersNumber: discount.passengers_number * nbBookings,
                isDiscountScheme: true,
                price:
                  discount.discount_profile.discount_type === PERCENTAGE
                    ? -discount.total_discount * nbBookings
                    : discount.discounted_price * nbBookings,
              }),
            )
        : null}
      {selectedFlatFares.length > 0 ? (
        <div className={selectedSchemes.length > 0 ? classes.flatFares : ""}>
          {selectedFlatFares
            .filter((discount) => Boolean(discount.passengers_number))
            .map((discount) =>
              renderSelectedDiscountListItem({
                discount,
                price:
                  discount.discount_profile.discount_type === PERCENTAGE
                    ? -discount.total_discount
                    : discount.discounted_price,
              }),
            )}
        </div>
      ) : null}
      {promoCode && isCodeValid ? (
        <div className={classes.promoCode}>
          <Typography className={classes.promoCodeName}>
            <FormattedMessage id="payment.code" /> {promoCode}
          </Typography>

          <Typography className={classes.promoCodeValue}>
            <Price value={-1 * totalCodePrice} />
          </Typography>

          {!isNotRemovable ? (
            <Typography
              component="div"
              className={classes.removeButtonContainer}
            >
              <IconButton
                title={intl.formatMessage({
                  id: "ticketing.remove_reduction_or_subscription",
                  defaultMessage: "Remove",
                })}
                size="small"
                onClick={onClickResetPromoCode}
              >
                <DeleteCross />
              </IconButton>
            </Typography>
          ) : null}
        </div>
      ) : null}
    </ul>
  );
}
