import { makeStyles } from "@material-ui/core";

export const useReservationStyles = makeStyles(() => ({
  detailsColumn: {
    "& > div": {
      padding: "0 0 16px 0",
    },
  },
  rideMapContainer: {
    minHeight: "60vh",
    maxHeight: "100vh",
  },
  grid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",
  },
}));
