import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@material-ui/core";

import { Badge } from "atoms/Badge/Badge";
import { SuccessIcon } from "components/Icons/Success";
import Warning from "components/Icons/Warning";

import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";

import { useBookingValidationResultStyles } from "./bookingValidationResultStyles";

type BookingValidationResultProps = {
  backToSearch: () => void;
  bookReturnTrip: () => void;
  errors: any;
  isRequesting: boolean;
  redirectToRideId: () => void;
  redirectToRides: () => void;
  responses: any;
  isReturnTrip: boolean;
};

export default function BookingValidationResult({
  backToSearch,
  bookReturnTrip,
  errors,
  isRequesting,
  redirectToRideId,
  redirectToRides,
  responses,
  isReturnTrip,
}: BookingValidationResultProps) {
  const classes = useBookingValidationResultStyles();

  let dateOfFirstFailedBooking, errorMessageForAllFailedBookings;

  // Display only one error message, if necessary, for a better UX:
  if (Object.keys(errors).length > 0) {
    dateOfFirstFailedBooking = Object.keys(errors)[0];
    errorMessageForAllFailedBookings =
      errors[dateOfFirstFailedBooking]?.infos?.detail?.message || "";
  }

  return (
    <Dialog className={classes.dialogContainer} open>
      {isRequesting && <CircularProgress className={classes.loader} />}

      <DialogContent>
        {!isRequesting && Object.keys(responses).length > 0 && (
          <>
            <SuccessIcon className={classes.statusIcon} />

            <Typography className={classes.statusMessage}>
              <FormattedMessage
                id="booking.confirm"
                values={{ dayCount: Object.keys(responses).length }}
              />
            </Typography>

            <div className={classes.badgesContainer}>
              {Object.keys(responses).map((dateStr, i) => {
                const date = new Date(dateStr.split("T")[0]);
                return (
                  <Badge
                    styleVariant={{ background: "#caf6e2" }}
                    label={capitalize(i18nVerboseDateFormat(date))}
                    key={`reservation-${i}`}
                  />
                );
              })}
            </div>
          </>
        )}

        {!isRequesting && Object.keys(errors).length > 0 && (
          <>
            <Warning className={classes.statusIcon} />

            <Typography className={classes.statusMessage}>
              <FormattedMessage
                id="booking.error"
                values={{ dayCount: Object.keys(errors).length }}
              />
            </Typography>

            <div className={classes.badgesContainer}>
              {Object.keys(errors).map((dateStr, i) => {
                const date = new Date(dateStr.split("T")[0]);
                return (
                  <Badge
                    styleVariant={{ background: "#fae5e8" }}
                    label={capitalize(i18nVerboseDateFormat(date))}
                    key={`reservation-error-${i}`}
                  />
                );
              })}
            </div>

            <Typography className={classes.errorMessage}>
              {errorMessageForAllFailedBookings}
            </Typography>
          </>
        )}
      </DialogContent>

      {!isRequesting && (
        <DialogActions className={classes.actionsContainer}>
          {Object.keys(responses).length > 0 && (
            <div
              className={`${classes.actionsRow} ${classes.layoutMultipleBtns}`}
            >
              {!isReturnTrip ? (
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="inherit"
                  role="link"
                  onClick={() => bookReturnTrip()}
                  data-testid="book-return-trip-btn"
                >
                  <FormattedMessage id="misc.book_return_trip" />
                </Button>
              ) : (
                <Button
                  className={classes.actionButton}
                  variant="outlined"
                  color="inherit"
                  role="link"
                  onClick={() => backToSearch()}
                  data-testid="back-to-search-btn"
                >
                  <FormattedMessage id="misc.back_to_search" />
                </Button>
              )}
              <Button
                className={classes.actionButton}
                variant="contained"
                color="primary"
                role="link"
                onClick={() =>
                  Object.keys(responses).length > 1
                    ? redirectToRides()
                    : redirectToRideId()
                }
                data-testid="see-my-ride"
              >
                {Object.keys(responses).length > 1 ? (
                  <FormattedMessage id="misc.see_my_rides" />
                ) : (
                  <FormattedMessage id="misc.see_my_ride" />
                )}
              </Button>
            </div>
          )}
          {(Object.keys(responses).length === 0 || !isReturnTrip) && (
            <div className={`${classes.actionsRow} ${classes.layoutSoloBtn}`}>
              <Button
                className={`${classes.actionButton} ${classes.tertiaryActionButton}`}
                variant={
                  Object.keys(responses).length === 0 ? "outlined" : "text"
                }
                color="inherit"
                role="link"
                onClick={() => backToSearch()}
                data-testid="back-to-search-btn"
              >
                <FormattedMessage id="misc.back_to_search" />
              </Button>
            </div>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}
