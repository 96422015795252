import React, { useContext, useMemo } from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { useSelector } from "react-redux";

import dayjs from "dayjs";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

interface StateInterface {
  search: {
    searchForm: {
      time: string;
      timeRestrictionType: "departure" | "arrival";
    };
  };
}

export function TimeWindowLabel() {
  const { language } = useContext(UserContext);
  const { selectedTerritory } = useContext(TerritoryContext);
  const { time, departure } = useSelector((state: StateInterface) => ({
    time: state.search.searchForm.time,
    departure: state.search.searchForm.timeRestrictionType === "departure",
  }));

  const [timeMin, timeMax] = useMemo(() => {
    const delayBefore =
      (departure
        ? selectedTerritory?.waiting_list?.delay_before_requested_departure
        : selectedTerritory?.waiting_list?.delay_before_requested_arrival) || 0;
    const delayAfter =
      (departure
        ? selectedTerritory?.waiting_list?.delay_after_requested_departure
        : selectedTerritory?.waiting_list?.delay_after_requested_arrival) || 0;

    const timeMin = dayjs(time)
      .subtract(delayBefore, "minute")
      .locale(language)
      .format("LT");
    const timeMax = dayjs(time)
      .add(delayAfter, "minute")
      .locale(language)
      .format("LT");

    return [timeMin, timeMax];
  }, [departure, language, selectedTerritory, time]);

  return departure ? (
    <FormattedMessage
      id="LABEL_WAITING_LIST_DEPARTURE_BETWEEN"
      values={{
        departureTimeMin: <strong>{timeMin}</strong>,
        departureTimeMax: <strong>{timeMax}</strong>,
      }}
    />
  ) : (
    <FormattedMessage
      id="LABEL_WAITING_LIST_ARRIVAL_BETWEEN"
      values={{
        arrivalTimeMin: <strong>{timeMin}</strong>,
        arrivalTimeMax: <strong>{timeMax}</strong>,
      }}
    />
  );
}
