import React from "react";
import { useIntl } from "react-intl";

import { Paper, Tooltip, Typography } from "@material-ui/core";

import ActionInfo from "components/Icons/ActionInfo";
import { DiscountTicketIcon } from "components/Icons/DiscountTicket";

import { useDiscountCardStyles, useTooltipStyles } from "./discountCardStyles";

export function DiscountCard(props) {
  const { discountProfile } = props;

  const classes = useDiscountCardStyles();
  const tooltipClasses = useTooltipStyles();

  const intl = useIntl();

  return (
    <Paper className={classes.discountContainer}>
      <DiscountTicketIcon className={classes.discountTicketIcon} fill="black" />

      <Typography className={classes.discountName} variant="body2">
        {discountProfile.name}
      </Typography>

      <Typography
        className={classes.discountExternalIdentifier}
        variant="body2"
      >
        {discountProfile.external_identifier}
      </Typography>

      <Tooltip
        classes={tooltipClasses}
        placement="bottom"
        arrow
        PopperProps={{ id: "ticketingInformation" }}
        title={
          <Typography variant="body2">
            {intl.formatMessage(
              { id: `ticketing.card_update_information` },
              {
                cardName: discountProfile.name,
              },
            )}
          </Typography>
        }
        tabIndex="0"
        aria-label="information"
        role="tooltip"
        aria-describedby="ticketingInformation"
      >
        <div className={classes.iconContainer}>
          <ActionInfo fill="#0869af" />
        </div>
      </Tooltip>
    </Paper>
  );
}
