import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";

import ReservationForm from "components/ReservationForm/ReservationForm.jsx";

import { fetchReservation } from "containers/Reservation/actions.js";
import { resetSearch } from "containers/SearchForm/actions.js";

import { TerritoryContext } from "contexts/TerritoryContext";

import { onFieldChange, changeBookingSearch } from "./actions.js";

/**
 * ReservationForm container (reservation edition page)
 */
function ReservationFormContainer(props) {
  /**
   * Resume of searchForm, but we can only change time . The truth source is still search.responses, so
   * we clean it in case there is still some.
   *
   */
  const {
    reservationId,
    time,
    reservation,
    onFieldChange,
    fetchReservation,
    resetSearch,
    changeBookingSearch,
  } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  useEffect(() => {
    resetSearch();
    if (!reservation || reservation.id !== parseInt(reservationId, 10))
      fetchReservation(reservationId, selectedTerritory.territory_key);
    else if (time && reservationId) {
      // automatically loads result when arriving on edition view
      changeBookingSearch(
        {
          time,
          reservationId,
        },
        selectedTerritory.territory_key,
      );
    }
  }, [reservation, reservationId]);

  const submit = (e) => {
    e.preventDefault();
    resetSearch();
    changeBookingSearch(
      {
        time,
        reservationId,
      },
      selectedTerritory.territory_key,
    );
  };

  return (
    <ReservationForm
      reservation={reservation}
      time={time}
      onFieldChange={onFieldChange}
      onSubmit={submit}
    />
  );
}

const mapDispatch = (dispatch) => ({
  fetchReservation: (id, selectedTerritoryKey) => {
    dispatch(fetchReservation(id, selectedTerritoryKey));
  },
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  changeBookingSearch: (payload, selectedTerritoryKey) => {
    dispatch(changeBookingSearch(payload, selectedTerritoryKey));
  },
  resetSearch: () => {
    dispatch(resetSearch());
  },
});

const mapState = (state) => ({
  reservation: state.reservation.reservation,
  time: state.reservationForm.time,
  selectedDays: state.search.selectedDays,
});

export default connect(mapState, mapDispatch)(ReservationFormContainer);
