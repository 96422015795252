import React, { Fragment } from "react";
import { Divider, Grid, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl-phraseapp";

import { INotificationsError } from "components/organisms/NotificationPreferences/NotificationPreferencesContainer";
import { LabeledSwitchContainer } from "atoms/LabeledSwitch/LabeledSwitchContainer";

import styles from "./notificationRow.cssmodule.scss";

const AT_LEAST_ONE_CHANNEL_MUST_BE_ACTIVATED =
  "AT_LEAST_ONE_CHANNEL_MUST_BE_ACTIVATED";

type NotificationRowProps = {
  rowType: string;
  channels: { [key: string]: boolean };
  onChange: (channelChangedId, value, setChecked) => void;
  error?: INotificationsError;
};

export const NotificationRow = ({
  rowType,
  channels,
  onChange,
  error,
}: NotificationRowProps) => (
  <>
    <Grid item xs={12} sm={6}>
      <Typography component="h3" className={styles.title}>
        <FormattedMessage id={`LABEL_${rowType}`} />
      </Typography>
      <Typography component="h4" className={styles.subtitle}>
        <FormattedMessage id={`TEXT_${rowType}`} />
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} container spacing={2}>
      {Object.keys(channels).map((channel, index) => (
        <Fragment key={`${rowType}-${channel.toLowerCase()}`}>
          <LabeledSwitchContainer
            switchId={channel}
            isChecked={channels[channel]}
            onChange={(channelChangedId, value, setChecked) =>
              onChange(channelChangedId, value, setChecked)
            }
          />
          {index < Object.keys(channels).length - 1 && (
            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
          )}
        </Fragment>
      ))}
      {error &&
        error.extra &&
        error.extra.reason === AT_LEAST_ONE_CHANNEL_MUST_BE_ACTIVATED && (
          <Grid item xs={12} sm={12}>
            <Typography color="error">
              <FormattedMessage
                id={"FLOW_HELPER_ERROR_NOTIFICATION_GROUP_DISABLED"}
              />
            </Typography>
          </Grid>
        )}
    </Grid>
  </>
);
