import React from "react";
import { TextField } from "@material-ui/core";

type TextFieldGeneratorProps = {
  id: string;
  fieldKey: string;
  label: string | JSX.Element;
  name: string;
  type: string;
  value: string;
  error?: boolean;
  helperText?: Array<string>;
  disabled?: boolean;
  required: boolean | undefined;
  handleChange: (name: string, event: any) => void;
  setvalue: (value: string) => void;
  dataTestId: string;
  extraProperties: object;
};

export const TextFieldGenerator = (props: TextFieldGeneratorProps) => {
  const {
    id,
    fieldKey,
    label,
    name,
    type,
    value,
    error = false,
    helperText,
    disabled = false,
    required,
    handleChange,
    setvalue,
    dataTestId,
    extraProperties,
  } = props;

  return (
    <TextField
      id={id}
      fullWidth={true}
      label={label}
      name={name}
      type={type}
      value={value}
      error={error}
      helperText={helperText}
      disabled={disabled}
      required={required}
      data-testid={dataTestId}
      onChange={(e) => {
        handleChange(fieldKey, e.target.value);
        setvalue(e.target.value);
      }}
      {...extraProperties}
    />
  );
};
