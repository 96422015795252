import React from "react";

import { SvgIcon } from "@material-ui/core";

type DotIconProps = {
  className?: string;
  fill?: string;
};

export function DotIcon({ className, fill = "#6C6C6C" }: DotIconProps) {
  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11 16C13.7614 16 16 13.7614 16 11C16 8.23858 13.7614 6 11 6C8.23858 6 6 8.23858 6 11C6 13.7614 8.23858 16 11 16Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
}
