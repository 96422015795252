import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Wallet(props) {
  return (
    <SvgIcon {...props}>
      <svg>
        <g fill="none" fillRule="evenodd">
          <g fill="#717587">
            <path
              d="M16.842 3.158c0 .581-.471 1.053-1.053 1.053H1.053C.47 4.21 0 3.739 0 3.158V1.053C0 .47.471 0 1.053 0h14.736c.582 0 1.053.471 1.053 1.053H1.58c-.29 0-.526.235-.526.526 0 .29.235.526.526.526h15.263v1.053z"
              transform="translate(2 5)"
            />
            <path
              d="M18.947 13.684c0 .582-.47 1.053-1.052 1.053H1.053C.47 14.737 0 14.266 0 13.684V3.158c0-.581.471-1.053 1.053-1.053h16.842c.581 0 1.052.472 1.052 1.053v2.105h-5.263c-.581 0-1.052.471-1.052 1.053v3.158c0 .581.47 1.052 1.052 1.052h5.263v3.158z"
              transform="translate(2 5)"
            />
            <path
              d="M18.947 6.316c.582 0 1.053.471 1.053 1.052v1.053c0 .581-.471 1.053-1.053 1.053h-4.21c-.582 0-1.053-.472-1.053-1.053V7.368c0-.58.471-1.052 1.053-1.052h4.21zm-3.684 1.052c-.29 0-.526.236-.526.527 0 .29.235.526.526.526.29 0 .526-.236.526-.526 0-.29-.235-.527-.526-.527z"
              transform="translate(2 5)"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Wallet;
