import { Reservation } from "api/types/common.js";
import {
  CLEAR_RESERVATION,
  INITIATE_RESERVATION_REQUEST,
  RECEIVE_RESERVATION,
  RESERVATION_REQUEST_ERROR,
} from "./actions.js";

type stateType = {
  reservation: Reservation | null;
  isRequesting: boolean;
  error: string | null;
};

type actionType = {
  type: string;
  reservation: Reservation;
};

const initialState: stateType = {
  reservation: null,
  isRequesting: false,
  error: null,
};

export const reservation = (state = initialState, action: actionType) => {
  switch (action.type) {
    case INITIATE_RESERVATION_REQUEST:
      return {
        ...initialState,
        reservation: null,
        isRequesting: true,
      };
    case RECEIVE_RESERVATION:
      return {
        ...initialState,
        reservation: action.reservation,
        isRequesting: false,
      };
    case RESERVATION_REQUEST_ERROR:
      return {
        ...state,
        reservation: null,
        isRequesting: false,
        error: "can't fetch this reservation",
      };
    case CLEAR_RESERVATION:
      return initialState;
    default:
      return state;
  }
};
