import { RIDE_UPDATED, CLEAR_RIDE } from "./actions.js";

const initialState = {
  ride: null,
};

export const rideDetails = (state = initialState, action) => {
  switch (action.type) {
    case RIDE_UPDATED:
      return {
        ...state,
        ride: action.ride,
      };
    case CLEAR_RIDE:
      return {
        ride: null,
      };
    default:
      return state;
  }
};
