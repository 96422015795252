import { makeStyles } from "@material-ui/core";

export const useAuthenticatedLayoutStyles = makeStyles((theme) => ({
  viewWrapper: {
    width: "100%",
    maxWidth: theme.breakpoints.values.xl,
    height: "100%",
    minHeight: "calc(100vh - 8rem)",
    margin: "0 auto",
  },
}));
