import { CANCELLATION_REASONS_FETCHED } from "./actions.js";

const initialState = {
  cancellationReasons: {},
};

export const rateRide = (state = initialState, action) => {
  switch (action.type) {
    case CANCELLATION_REASONS_FETCHED:
      return {
        ...state,
        cancellationReasons: action.reasons,
      };
    default:
      return state;
  }
};
