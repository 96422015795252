import React, { useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import CustomDayPickerInput from "containers/CustomDayPickerInput/CustomDayPickerInput.jsx";

import { useModifyRecurringDialogStyles } from "./modifyRecurringDialogStyles";

export function ModifyRecurringDialog(props) {
  const { recurring, handleClose, changeEndDateForRecurringBooking } = props;

  const [date, setDate] = useState(initialEndDate);

  const classes = useModifyRecurringDialogStyles();

  const initialEndDate = recurring.end_datetime;

  // Disable dates between now and the next recurring booking to be generated
  const limitDate = new Date(recurring.booking.pickup_time);
  const disabledDays = { before: limitDate };

  const changeEndDate = (endDate) => {
    const isoDate = endDate.toISOString();

    changeEndDateForRecurringBooking(recurring, isoDate);
  };

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography component="h2">
          <FormattedMessage id={"recurring.modify.dialogTitle"} />
        </Typography>
      </DialogTitle>

      <DialogContent>
        <CustomDayPickerInput
          label={<FormattedMessage id="search.recurrence.end_date" />}
          name="recurrig-end-date"
          tabIndex={0}
          month={new Date(date)}
          selectedDays={[date]}
          addDisabledDays={disabledDays}
          onDayClick={(day) => setDate(day)}
        />

        <Alert className={classes.infoBox} severity="info">
          <FormattedMessage id="recurring.modify.dialogInfo" />
        </Alert>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          variant="contained"
          autoFocus
          onClick={handleClose}
        >
          <FormattedMessage id={"cancel"} />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => {
            changeEndDate(new Date(date));
            handleClose();
          }}
        >
          <FormattedMessage id={"misc.confirm"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
