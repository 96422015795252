import { makeStyles } from "@material-ui/core";

export const useBookingValidationResultStyles = makeStyles((theme) => ({
  actionButton: {
    width: "100%",
    minWidth: "calc(50% - 0.5rem)",
    margin: "8px 0",

    [theme.breakpoints.up("sm")]: {
      width: "auto",
      minWidth: "calc(50% - 1rem)",
    },
  },
  tertiaryActionButton: {
    textDecoration: "underline",
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",

    padding: "16px 24px 8px 24px",
  },
  actionsRow: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  layoutMultipleBtns: {
    justifyContent: "space-between",
  },
  layoutSoloBtn: {
    justifyContent: "center",
  },
  badgesContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  dateBadge: {
    margin: "8px 8px 0 0",
  },
  dialogContainer: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "600px",
      margin: "16px",
    },

    "& .MuiDialogActions-spacing > :not(:first-child)": {
      margin: 0,
    },

    "& .MuiPaper-root": {
      padding: "16px 0",

      [theme.breakpoints.up("sm")]: {
        padding: "16px 8px",
      },
    },
  },
  errorMessage: {
    margin: "0.5rem 0",

    color: theme.palette.error.main,
  },
  loader: {
    margin: "64px auto",
  },
  statusIcon: {
    display: "block",

    margin: "2rem auto 1.75rem auto",

    transform: "scale(2)",
  },
  statusMessage: {
    margin: "1rem 0 0.5rem 0",
    fontWeight: 500,
  },
}));
