import React from "react";
import { useIntl } from "react-intl";

import ReservationList from "containers/ReservationList/ReservationList.jsx";

export default function ListView() {
  const intl = useIntl();

  return (
    <div className="marged container">
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "reservationList.title" })}
      </h1>

      <ReservationList />
    </div>
  );
}
