import api from "api";
import { snackMessage } from "containers/SnackBar/actions.js";
import { fetchReservations } from "containers/ReservationList/actions.js";

export const rateRide =
  (
    id,
    driverRating,
    rideRating,
    comment,
    recurringServicesEnabled,
    selectedTerritoryKey,
  ) =>
  (dispatch) => {
    api
      .rateRidingStars({
        reservation_id: id,
        driver_rating: driverRating,
        service_rating: rideRating,
        comment,
      })
      .then((response) => {
        dispatch(snackMessage("success", response.message));
        dispatch(
          fetchReservations(recurringServicesEnabled, selectedTerritoryKey),
        );
      })
      .catch((error) => console.log(error));
  };
