import {
  ON_CHANGE,
  ON_USER_SELECTED,
  CLOSE_SESSION,
  SESSION_STARTED,
  OPEN_IMPERSONATE_DIALOG,
  CLOSE_IMPERSONATE_DIALOG,
  REQUEST_IMPERSONATE,
  REQUEST_IMPERSONATE_ERROR,
} from "./actions.js";

const initialState = {
  value: "",
  selectedUser: null,
  sessionStarted: false,
  openDialog: false,
  isRequestingImpersonate: false,
  impersonateByReferent: false,
};

export const impersonateForm = (state = initialState, action) => {
  switch (action.type) {
    case ON_CHANGE:
      return {
        ...state,
        value: action.value,
      };
    case ON_USER_SELECTED:
      return {
        ...state,
        selectedUser: action.user,
      };
    case SESSION_STARTED:
      return {
        ...state,
        value: "",
        sessionStarted: true,
        isRequestingImpersonate: false,
        impersonateByReferent: action.isReferent,
      };
    case CLOSE_SESSION:
      return {
        ...state,
        sessionStarted: false,
        selectedUser: null,
        value: "",
        impersonateByReferent: false,
        isRequestingImpersonate: false,
      };
    case OPEN_IMPERSONATE_DIALOG:
      return {
        ...state,
        openDialog: true,
      };
    case CLOSE_IMPERSONATE_DIALOG:
      return {
        ...state,
        openDialog: false,
      };
    case REQUEST_IMPERSONATE:
      return {
        ...state,
        isRequestingImpersonate: true,
      };
    case REQUEST_IMPERSONATE_ERROR:
      return {
        ...state,
        isRequestingImpersonate: false,
      };
    default:
      return state;
  }
};
