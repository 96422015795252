import React from "react";
import { useIntl } from "react-intl";

import { Button, CircularProgress, Typography } from "@material-ui/core";

import Autocomplete from "containers/Autocomplete/Autocomplete";

import { useFavouritesStyles } from "./favouritesStyles";

export function FavoriteForm(props) {
  const {
    dropoff,
    errors,
    isRequesting,
    onAddressSelected,
    onFieldChange,
    onSubmit,
    pickup,
    territory,
  } = props;

  const intl = useIntl();
  const classes = useFavouritesStyles();

  const favouriteLabel1 =
    territory === "gml"
      ? intl.formatMessage({ id: "favorite.home" })
      : intl.formatMessage({ id: "favorite.favorite1" });

  const favouriteLabel2 =
    territory === "gml"
      ? intl.formatMessage({ id: "favorite.workplace" })
      : intl.formatMessage({ id: "favorite.favorite2" });

  return (
    <form className={classes.favouritesContainer} onSubmit={(e) => onSubmit(e)}>
      <div className={classes.fieldsContainer}>
        <div>
          <Typography
            className={classes.autocompleteLabel}
            variant="caption"
            component="label"
            htmlFor="favorite1-autocomplete"
          >
            {favouriteLabel1}
          </Typography>

          <Autocomplete
            id="favorite1-autocomplete"
            context="favorite"
            inputProps={{
              label: favouriteLabel1,
              helperText: errors.home,
              text: pickup,
            }}
            onChange={(e) => onFieldChange("pickup", e.target.value)}
            onSelect={(p) => onAddressSelected(p.address, p.placeId, "pickup")}
          />
        </div>

        <div>
          <Typography
            className={classes.autocompleteLabel}
            variant="caption"
            component="label"
            htmlFor="favorite2-autocomplete"
          >
            {favouriteLabel2}
          </Typography>

          <Autocomplete
            id="favorite2-autocomplete"
            context="favorite"
            inputProps={{
              label: favouriteLabel2,
              helperText: errors.office,
              text: dropoff,
            }}
            onChange={(e) => onFieldChange("dropoff", e.target.value)}
            onSelect={(p) => onAddressSelected(p.address, p.placeId, "dropoff")}
          />
        </div>
      </div>

      <div className={classes.saveButtonContainer}>
        <Button
          color="primary"
          type="submit"
          disabled={isRequesting}
          variant="contained"
          aria-label={intl.formatMessage({
            id: "aria.save_favorite_adresses",
          })}
        >
          {isRequesting ? (
            <CircularProgress />
          ) : (
            intl.formatMessage({ id: "misc.save" })
          )}
        </Button>
      </div>
    </form>
  );
}
