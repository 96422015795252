import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useCernCaptchaStyles = makeStyles((theme: CustomTheme) => ({
  captchaContainer: {
    maxWidth: "19rem",
    margin: "auto",
  },
  captchaControlButton: {
    minWidth: "auto",
  },
  captchaControls: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "16px",

    width: "100%",
    padding: "16px 0",
  },
  captchaImage: {
    width: "100%",
    minHeight: "48px",
    background: "#f4f4f4",
  },
  captchaTimer: {
    fontSize: "1em",
    color: "dimgray",
  },
  captchaField: {
    display: "block",

    maxWidth: "19rem",
    margin: "auto",

    "& .MuiInput-root": {
      width: "100%",
    },
  },
  cernCaptchaContainer: {
    maxWidth: "30rem",
    margin: "32px 0 16px 0",
    padding: "16px",

    [theme.breakpoints.up("sm")]: {
      margin: "32px 32px 16px 32px",
    },
  },
  cernCaptchaTitle: {
    margin: "0 0 24px 0",

    fontWeight: 500,
    textAlign: "center",
  },
  infoBox: {
    margin: "24px 0 0 0",

    fontSize: "0.9rem",

    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
}));
