const isEditable = (
  booking,
  isDeletable: boolean,
  isChangeBookingSeatsEnabled: boolean,
) => {
  return (
    (booking?.payment?.payment_discount_values || []).length === 0 &&
    isDeletable &&
    isChangeBookingSeatsEnabled
  );
};

export { isEditable };
