import { ResourceType } from "api/resources/types/resourceType";

export const booking: ResourceType = {
  getReservation: {
    endpoint: "/bookings/{id}",
    method: "GET",
    impersonate: true,
    adminzone: true,
  },
  cancelReservation: {
    endpoint: "/cancel-reservation",
    method: "POST",
    impersonate: true,
  },
  changeBookingSearch: {
    endpoint: "/{reservationId}/change-booking-search",
    method: "POST",
    impersonate: true,
  },
  changeBookingSeats: {
    endpoint: "/{reservationId}/change-booking-seats",
    method: "POST",
    impersonate: true,
  },
  changeBookingValidate: {
    endpoint: "/{reservationId}/change-booking-validate",
    method: "POST",
    impersonate: true,
  },
  commentCancelledReservation: {
    endpoint: "/comment-cancelled-reservation",
    method: "POST",
    impersonate: true,
  },
  getCancellationReasons: {
    endpoint: "/get-cancellation-reasons",
    method: "GET",
  },
  getCurrentReservationInfos: {
    endpoint: "/get-current-reservation-infos",
    method: "POST",
    impersonate: true,
  },
  setFavorite: {
    endpoint: "/set-favorite",
    method: "POST",
    impersonate: true,
  },
  validateBooking: {
    endpoint: "/validate-booking",
    method: "POST",
    impersonate: true,
  },
  cancelRecurringBooking: {
    endpoint: "/recurring_booking/{recurring_booking_id}",
    method: "DELETE",
    impersonate: true,
  },
  changeRecurringBookingEndDate: {
    endpoint: "/recurring_booking/{recurring_booking_id}",
    method: "PATCH",
    impersonate: true,
  },
  rateRidingStars: {
    endpoint: "/rate-past-reservation",
    method: "POST",
  },
  retrievePropositionDetails: {
    endpoint: "/propositions/{proposition_id}",
    method: "GET",
    apiVersion: "2.0",
    authenticationRequired: true,
  },
  retrieveSearchRequestDetails: {
    endpoint: "/searchrequests/{search_request_id}",
    method: "GET",
    apiVersion: "2.0",
    authenticationRequired: true,
  },
};
