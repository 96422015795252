import React, { useCallback, useContext, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

import dayjs from "dayjs";

import ReservationTabs from "components/Reservation/ReservationTabs.jsx";

import { UserContext } from "contexts/UserContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import {
  cancelRecurringBooking,
  changeEndDateForRecurringBooking,
  fetchReservations,
  fetchWaitingLists,
} from "./actions.js";

function ReservationListContainer(props) {
  const {
    cancelRecurringBooking,
    changeEndDateForRecurringBooking,
    fetchReservations,
    fetchWaitingLists,
    reservationList,
  } = props;

  const { userProfile } = useContext(UserContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const customerId = userProfile.customerId;

  const recurringServicesEnabled =
    selectedTerritory?.recurring_services?.enabled || false;

  useEffect(() => {
    fetchReservations(
      recurringServicesEnabled,
      selectedTerritory.territory_key,
      customerId,
    );
    fetchWaitingLists(customerId);
  }, [
    fetchReservations,
    fetchWaitingLists,
    recurringServicesEnabled,
    selectedTerritory.territory_key,
    customerId,
  ]);

  const isDeletable = useCallback(
    (reservation) => {
      const now = dayjs(new Date());
      const pickupTime = dayjs(reservation.pickup_time);
      const minutesDiff = pickupTime.diff(now, "minutes");
      const cancellationTreshold =
        selectedTerritory?.booking?.cancellation_threshold;

      return minutesDiff > cancellationTreshold;
    },
    [selectedTerritory?.booking?.cancellation_threshold],
  );

  // Exclude recurring reference bookings from upcoming bookings
  const recurringReferences =
    reservationList?.recurring?.map((a) => a.booking.id) || [];
  const upcome =
    reservationList?.upcoming?.filter(
      (booking) => !recurringReferences.includes(booking.id),
    ) || [];
  const recurringOffers =
    selectedTerritory?.booking?.recurring_bookings?.offers || [];
  const isRecurringDisabled = recurringOffers.length === 0;

  return (
    <ReservationTabs
      cancelled={reservationList.cancelled}
      cancelRecurringBooking={(recurringBooking) =>
        cancelRecurringBooking(
          recurringBooking,
          recurringServicesEnabled,
          selectedTerritory.territory_key,
        )
      }
      changeEndDateForRecurringBooking={(recurring, isoDate) =>
        changeEndDateForRecurringBooking(
          recurring,
          isoDate,
          recurringServicesEnabled,
          selectedTerritory.territory_key,
        )
      }
      isDeletable={isDeletable}
      isRecurringDisabled={isRecurringDisabled}
      past={reservationList.past}
      recurring={!isRecurringDisabled && reservationList.recurring}
      upcoming={upcome}
      waitingLists={reservationList.waitingLists}
    />
  );
}

const _mapState = (state) => {
  return {
    reservationList: state.reservationList,
  };
};

const _mapDispatch = (dispatch) => ({
  fetchReservations: (recurringServicesEnabled, selectedTerritoryKey) => {
    dispatch(fetchReservations(recurringServicesEnabled, selectedTerritoryKey));
  },
  fetchWaitingLists: (customerId) => {
    dispatch(fetchWaitingLists(customerId));
  },
  cancelRecurringBooking: (
    recurring_booking,
    recurringServicesEnabled,
    selectedTerritoryKey,
  ) => {
    dispatch(
      cancelRecurringBooking(
        recurring_booking,
        recurringServicesEnabled,
        selectedTerritoryKey,
      ),
    );
  },
  changeEndDateForRecurringBooking: (
    recurring_booking,
    new_end_date,
    recurringServicesEnabled,
    selectedTerritoryKey,
  ) => {
    dispatch(
      changeEndDateForRecurringBooking(
        recurring_booking,
        new_end_date,
        recurringServicesEnabled,
        selectedTerritoryKey,
      ),
    );
  },
});

export default connect(
  _mapState,
  _mapDispatch,
)(injectIntl(ReservationListContainer));
