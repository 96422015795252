import { ResourceType } from "api/resources/types/resourceType";

export const operator: ResourceType = {
  getOperatorPassengerProfiles: {
    endpoint: "/operators/{territoryId}/passengerprofiles/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  getOperatorPersonalItems: {
    endpoint: "/operators/{territoryId}/personalequipments/",
    impersonate: false,
    method: "GET",
    apiVersion: "2.0",
  },
  getOperatorPersonalItemCategories: {
    endpoint:
      "/operators/{territoryId}/personalequipments/{itemId}/categories/",
    impersonate: false,
    method: "GET",
    apiVersion: "2.0",
  },
};
