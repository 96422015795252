import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import { withTheme, Grid, Link, Paper, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import ImpersonateForm from "containers/ImpersonateForm/ImpersonateForm.jsx";

import { OpenNewTabIcon } from "icons/OpenNewTabIcon";
import Speakerphone from "components/Icons/Speakerphone";

import { ProductContext } from "contexts/ProductContext";

import { isAdmin } from "lib/user/userRoles.js";

import { useImpersonateViewStyles } from "./impersonateViewStyles";

function ImpersonateView(props) {
  const { groups } = props;

  const navigate = useNavigate();
  const classes = useImpersonateViewStyles();

  const { productParameters } = useContext(ProductContext);

  const isSurveyEnabled =
    productParameters?.features?.booking_website
      ?.is_call_center_survey_enabled || false;

  useEffect(() => {
    if (!isAdmin(groups)) {
      navigate("/search");
    }
  }, []);

  return (
    <Grid
      container
      justifyContent="center"
      className={classes.impersonateViewContainer}
    >
      <h1 className="visuallyHidden">
        <FormattedMessage id="impersonate.title" />
      </h1>

      <Grid
        item
        container
        xs={12}
        sm={8}
        spacing={2}
        direction="row"
        justifyContent="space-around"
        alignItems="center"
      >
        <Grid item xs={12} md={4}>
          <Alert className={classes.impersonateHelpText} severity="info">
            <Typography align="center">
              <FormattedMessage id="impersonate.helpText" />
            </Typography>
          </Alert>
        </Grid>

        <Grid item xs={12} md={8}>
          <ImpersonateForm />
        </Grid>

        {isSurveyEnabled && (
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              className={classes.surveyInfoContainer}
              component={Paper}
            >
              <Grid item xs={12} md={2} className={classes.surveyIconContainer}>
                <Speakerphone />
              </Grid>

              <Grid item xs={12} md={10}>
                <Typography variant="h2">
                  <FormattedMessage id="impersonate.survey_title" />
                </Typography>

                <Typography className={classes.surveyInfoMessage}>
                  <FormattedMessage id="impersonate.survey_body" />
                </Typography>

                <Link
                  className={classes.surveyInfoLink}
                  href="https://forms.gle/6JGFpjV49YpuRsEE9"
                  target="_blank"
                  rel="noopener"
                >
                  <FormattedMessage id="impersonate.survey_link" />

                  <OpenNewTabIcon />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = (state) => ({
  groups: state.user.groups,
});

export default connect(mapStateToProps, null)(withTheme(ImpersonateView));
