import api from "api";
import apiAxios from "apiAxios";
import dayjs from "dayjs";
import { snackMessage } from "containers/SnackBar/actions.js";
import { WaitingListStatusEnum } from "types/reservation";

export const RESERVATIONS_FETCHED = "RESERVATIONS_FETCHED";
export const WAITING_LISTS_FETCHED = "WAITING_LISTS_FETCHED";
export const PERSONAL_ITEMS_BOOKING_FETCHED = "PERSONAL_ITEMS_BOOKING_FETCHED";

const reservationsFetched = (resType, reservations) => ({
  type: RESERVATIONS_FETCHED,
  resType,
  reservations,
});

const waitingListsFetched = (waitingLists) => ({
  type: WAITING_LISTS_FETCHED,
  waitingLists,
});

const personalItemsBookingFetched = (bookingId, personalItems) => ({
  type: PERSONAL_ITEMS_BOOKING_FETCHED,
  bookingId,
  personalItems,
});

export const formatterPersonalItem = (personalItems) => {
  let newPersonalItems = { count: 0, personalItems: [] };
  personalItems.forEach((item) => {
    newPersonalItems.count += 1;
    newPersonalItems.personalItems.push(item);
  });
  return newPersonalItems;
};

export const getBookingPersonalItems = (bookingId) => (dispatch, getState) => {
  const state = getState();
  const customerId = state?.user?.user?.customer_id;

  if (bookingId && customerId) {
    api
      .getBookingPersonalItems({
        customerId,
        bookingId,
      })
      .then((json) => {
        const personalItems = formatterPersonalItem(json.results);
        dispatch(personalItemsBookingFetched(bookingId, personalItems));
      });
  }
};

/**
 * Load waiting lists
 * for the current user
 */
export const fetchWaitingLists = (customerId) => (dispatch) => {
  const today = dayjs();
  apiAxios
    .getWaitingLists({
      urlParameters: {
        customerId,
      },
    })
    .then((waitingLists) => {
      dispatch(
        waitingListsFetched(
          waitingLists.results
            .filter(
              // Filter out validated waiting lists (which have become reservations)
              (waitingList) =>
                ![
                  WaitingListStatusEnum.VALIDATED,
                  WaitingListStatusEnum.CANCELLED_BY_CUSTOMER,
                ].includes(waitingList.waiting_list_status),
            )
            .filter(
              // Filter out waiting lists with past requested datetime
              (waitingList) => dayjs(waitingList.requested_datetime) >= today,
            ),
        ),
      );
    });
};

/**
 * Load upcoming/past/cancelled reservations
 * for the current user
 */
export const fetchReservations =
  (recurringServicesEnabled, selectedTerritoryKey) => (dispatch) => {
    api
      .getCustomerNextReservations(undefined, {
        territory: selectedTerritoryKey,
      })
      .then((json) => {
        dispatch(
          reservationsFetched(
            "upcoming",
            _.get(json, "bookings", json.reservations),
          ),
        );
        // To have the same behaviour as on the apps, do not fetch personal items for every booking here.
        // json.bookings.forEach((booking) => {
        //   dispatch(getBookingPersonalItems(booking.id));
        // });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    api
      .getCustomerPastReservations(undefined, {
        territory: selectedTerritoryKey,
      })
      .then((json) => {
        dispatch(
          reservationsFetched(
            "past",
            _.get(json, "bookings", json.reservations),
          ),
        );
        // To have the same behaviour as on the apps, do not fetch personal items for every booking here.
        // json.bookings.forEach((booking) => {
        //   dispatch(getBookingPersonalItems(booking.id));
        // });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    api
      .getCustomerCancelledReservations(undefined, {
        territory: selectedTerritoryKey,
      })
      .then((json) => {
        dispatch(
          reservationsFetched(
            "cancelled",
            _.get(json, "bookings", json.reservations),
          ),
        );
        // To have the same behaviour as on the apps, do not fetch personal items for every booking here.
        // json.bookings.forEach((booking) => {
        //   dispatch(getBookingPersonalItems(booking.id));
        // });
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    // only look if the feature is enabled
    if (recurringServicesEnabled) {
      api
        .getCustomerRecurringReservations()
        .then((json) => {
          dispatch(reservationsFetched("recurring", json.bookings));
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  };

export const cancelRecurringBooking =
  (recurring_booking, recurringServicesEnabled, selectedTerritoryKey) =>
  (dispatch, getState, getIntl) => {
    const intl = getIntl();
    const state = getState();

    let impersonate = "CANCELED_BY_USER";
    // for now, the Referent Impersonate is labelled by what's above
    if (
      state.impersonateForm.sessionStarted &&
      !state.impersonateForm.impersonateByReferent
    )
      impersonate = "CANCELED_BY_CALL_CENTER";

    const recurring_booking_id = recurring_booking.id;

    api
      .cancelRecurringBooking(
        {
          recurring_booking_id,
          cancelled_by: impersonate,
        },
        { territory: selectedTerritoryKey },
      )
      .then(() => {
        dispatch(
          fetchReservations(recurringServicesEnabled, selectedTerritoryKey),
        );
        dispatch(
          snackMessage(
            "success",
            intl.formatMessage({ id: "reservation.deleted" }),
          ),
        );
      })
      //FIXME adapt message
      .catch(() => {
        dispatch(
          snackMessage(
            "error",
            intl.formatMessage({ id: "misc.unexpected_error" }),
            "error on api: cancel-recurring-reservation",
          ),
        );
      });
  };

export const changeEndDateForRecurringBooking =
  (
    recurring_booking,
    end_date,
    recurringServicesEnabled,
    selectedTerritoryKey,
  ) =>
  (dispatch, getState, getIntl) => {
    const intl = getIntl();

    api
      .changeRecurringBookingEndDate(
        { recurring_booking_id: recurring_booking.id },
        {
          end_date: end_date,
          territory: selectedTerritoryKey,
        },
      )
      .then(() => {
        dispatch(
          fetchReservations(recurringServicesEnabled, selectedTerritoryKey),
        );
        dispatch(
          snackMessage(
            "success",
            intl.formatMessage({ id: "reservation.edited" }),
          ),
        );
      })
      .catch(() => {
        dispatch(
          snackMessage(
            "error",
            intl.formatMessage({ id: "recurring.modify.error" }),
            "error on api: change-recurring-end-date out of range",
          ),
        );
      });
  };
