import * as React from "react";
import { VariantProps, cva } from "class-variance-authority";
import { cn } from "utils/classes";
import { Slot } from "@radix-ui/react-slot";

const typographyVariants = cva("text-foreground", {
  variants: {
    variant: {
      h1: "scroll-m-20 text-[1.8rem] font-light tracking-[-0.01562em]",
      h2: "scroll-m-20 text-[1.5rem] font-light tracking-[-0.00833em]",
      h3: "scroll-m-20 text-[1.35rem] font-light",
      h4: "scroll-m-20 text-[1.25rem] font-light tracking-[0.00735em]",
      h5: "scroll-m-20 text-[1.15rem] font-light",
      h6: "scroll-m-20 text-[1.1rem] font-medium tracking-[0.0075em]",
      p: "text-[1.15rem] font-normal",
      blockquote: "mt-6 border-l-2 pl-6 italic",
      ul: "my-2 ml-6 list-disc [&>li]:mt-2 text-[1.15rem]",
      inlineCode:
        "relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm font-semibold",
      lead: "text-xl text-muted-foreground",
      largeText: "text-lg font-semibold",
      smallText: "text-sm font-medium leading-none",
      mutedText: "text-sm text-muted-foreground",
    },
  },
  defaultVariants: {
    variant: "p",
  },
});

type VariantPropType = VariantProps<typeof typographyVariants>;

const variantElementMap: Record<
  NonNullable<VariantPropType["variant"]>,
  string
> = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
  blockquote: "blockquote",
  inlineCode: "code",
  largeText: "div",
  smallText: "small",
  lead: "p",
  mutedText: "p",
  ul: "ul",
};

export interface TypographyProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantProps<typeof typographyVariants> {
  asChild?: boolean;
  as?: string;
}

const Typography = React.forwardRef<HTMLElement, TypographyProps>(
  ({ className, variant, as, asChild, ...props }, ref) => {
    const Comp = asChild
      ? Slot
      : as ?? (variant ? variantElementMap[variant] : undefined) ?? "div";
    return (
      <Comp
        className={cn(typographyVariants({ variant, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);

Typography.displayName = "Typography";

export { Typography, typographyVariants };
