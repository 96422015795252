import * as React from "react";
import { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { EmergencyContactButton } from "organisms/EmergencyContactButton/EmergencyContactButton";

import { UserContext } from "contexts/UserContext";

import api from "api";
import { GetContactsOutput } from "api/types/getContacts";

import { useEmergencyContactsStyles } from "./emergencyContactsStyles";

const MAX_EMERGENCY_CONTACTS = 2;

export interface IContact {
  id: string;
  name: string;
  phoneNumber: string;
}

export function EmergencyContacts() {
  const [contacts, setContacts] = useState<IContact[]>([]);

  const { userProfile } = useContext(UserContext);
  const intl = useIntl();
  const classes = useEmergencyContactsStyles();

  const customerId = userProfile?.customerId;

  const fetchContacts = useCallback(() => {
    api.getContacts({ customerId }).then((contactsData: GetContactsOutput) => {
      const contacts = contactsData.results;
      const emergencyContacts = contacts
        .filter((contact) => contact.is_emergency_contact)
        .map((contact) => ({
          id: contact.id,
          name: contact.name,
          phoneNumber: contact.phone_number,
        }));

      setContacts(emergencyContacts);
    });
  }, [customerId]);

  useEffect(() => fetchContacts(), []);

  const contactDesignation = intl.formatMessage({
    id: "contact.contact-designation",
    defaultMessage: "Contact",
  });

  const handleDeleteContact = useCallback(
    (contactId: string) => {
      api
        .deleteContact({
          customerId,
          contactId,
        })
        .then(() => {
          fetchContacts();
        });
    },
    [customerId, fetchContacts],
  );

  return (
    <div className={classes.emergencyContactsContainer}>
      <Typography className={classes.sectionHeading} component="h3">
        <FormattedMessage
          id={"contact.emergency-contacts"}
          defaultMessage="Emergency contacts"
        />
      </Typography>

      <Alert severity="info">
        <FormattedMessage
          id="contact.emergency-contacts-info"
          defaultMessage="Add the phone number of contacts the driver could join if necessary, before your pick-up."
        />
      </Alert>

      <div className={classes.contactButtonsContainer}>
        {contacts.map((contact, index) => (
          <EmergencyContactButton
            contact={contact}
            fetchContacts={fetchContacts}
            onDelete={handleDeleteContact}
            textFieldLabel={`${contactDesignation} ${index + 1}`}
            key={contact.id}
          />
        ))}

        {contacts.length < MAX_EMERGENCY_CONTACTS && (
          <EmergencyContactButton
            fetchContacts={fetchContacts}
            onDelete={handleDeleteContact}
            textFieldLabel={`${contactDesignation} ${contacts.length + 1}`}
          />
        )}
      </div>
    </div>
  );
}
