import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl-phraseapp";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { Price } from "atoms/Price/Price";
import { SelectedDiscountList } from "containers/Payment/PaymentDiscounts/SelectedDiscountList";

import ExpandLess from "components/Icons/ExpandLess";
import ExpandMore from "components/Icons/ExpandMore";

import { TerritoryContext } from "contexts/TerritoryContext";
import { PaymentMethodAPIEnum } from "types/payment";

import { usePaymentDetailsStyles } from "./paymentDetailsStyles";

const getDiscounts = (reservation, totalPassengers) => {
  // TODO: discount details should be sent in the same format
  // on bookings/:id and get-customer-proposition-price.
  // Otherwise, the discount functionnality could have
  // its own context, at the same level as the parameters and
  // user contexts and the conversion fuction moved there.
  // It could also just be a hook, actually.
  const initialPrice = reservation.payment.initial_amount / totalPassengers;

  return {
    discountedPricesLoading: false,
    discountedPrices: {
      price_breakdown: reservation.payment.payment_discount_values.map(
        (discount) => {
          return {
            discounted_price:
              discount.passengers_number *
              (initialPrice - discount.discount_value?.toFixed(2)),
            discount_profile: {
              id: discount.discount_profile.id,
              discount_type: discount.discount_profile.discount_type,
              name: discount.discount_profile.name,
            },
            isNotRemovable: true,
            passengers_number: discount.passengers_number,
            total_discount:
              (initialPrice *
                discount.discount_value *
                discount.passengers_number) /
              100,
          };
        },
      ),
      unit_initial_price: initialPrice,
    },
  };
};

export function PaymentDetails(props) {
  const { reservation } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  const [isOpen, setIsOpen] = useState(false);

  const classes = usePaymentDetailsStyles();

  const intl = useIntl();

  const standardSeats = reservation?.passengers_number || 0;
  const wheelchairSeats =
    reservation?.extras_access?.requested_access_seats || 0;
  const bikeSeats = reservation?.extras_bike?.requested_bike_seats || 0;

  const totalPassengers = standardSeats + wheelchairSeats + bikeSeats;

  const totalAmount = reservation?.payment?.amount;
  const discounts = getDiscounts(reservation, totalPassengers);
  const onBoardInfoUrl = selectedTerritory?.payment?.onboard?.info_url;

  let paymentMethod, totalWording;
  switch (reservation.payment.mode) {
    case PaymentMethodAPIEnum.CB:
      paymentMethod = <FormattedMessage id="LABEL_PAYMENT_MODE_CREDIT_CARD" />;
      totalWording = <FormattedMessage id="payment.payOffTotal" />;
      break;
    case PaymentMethodAPIEnum.CREDIT:
      paymentMethod = <FormattedMessage id="payment.credits" />;
      totalWording = (
        <FormattedMessage id="payment.payOffTotal" defaultMessage="Total" />
      );
      break;
    case PaymentMethodAPIEnum.PAYPAL:
      paymentMethod = <FormattedMessage id="payment.paypal" />;
      totalWording = (
        <FormattedMessage id="payment.payOffTotal" defaultMessage="Total" />
      );
      break;
    case PaymentMethodAPIEnum.ONBOARD:
      paymentMethod = (
        <FormattedMessage
          id="paymentMethod.onboard"
          defaultMessage="Onboard payment"
        />
      );
      totalWording = <FormattedMessage id="payment.total" />;
      break;
    default:
      paymentMethod = "";
      totalWording = <FormattedMessage id="payment.total" />;
  }

  return (
    <Accordion className={classes.paymentDetailsContainer}>
      <AccordionSummary
        className={classes.paymentDetailsHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Typography>
          <FormattedMessage
            id="payment.paymentDetails"
            defaultMessage="Payment details"
          />
        </Typography>

        <div className={classes.chipAndExpandIcon}>
          {paymentMethod ? (
            <Chip className={classes.paymentMethod} label={paymentMethod} />
          ) : null}

          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </div>
      </AccordionSummary>

      <AccordionDetails className={classes.paymentDetails}>
        <SelectedDiscountList
          discounts={discounts}
          totalPassengers={totalPassengers}
        />

        <Divider />

        <Typography className={classes.totalPriceContainer}>
          <span>{totalWording}</span>

          <span>
            <Price value={totalAmount} />
          </span>
        </Typography>

        {discounts?.discountedPrices?.price_breakdown?.length > 0 ||
        reservation?.payment?.mode === PaymentMethodAPIEnum.ONBOARD ? (
          <Alert className={classes.supportingDocumentInfo} severity="info">
            {reservation?.payment?.mode === PaymentMethodAPIEnum.ONBOARD && (
              <>
                <Typography>
                  <FormattedMessage
                    id="TEXT_INFO_ONBOARD_PAYMENT_VAR"
                    values={{
                      paymentMethods:
                        selectedTerritory?.payment?.onboard?.onboard_payment_modes
                          .map((mode) =>
                            intl.formatMessage({
                              id: `LABEL_PAYMENT_MODE_${mode.displayed_name.replace(" ", "_").toUpperCase()}`,
                            }),
                          )
                          .join(", "),
                    }}
                  />
                </Typography>
                <Typography>
                  <FormattedMessage id="TEXT_INFO_SEE_HELP_CONTACT_PAGE" />
                </Typography>
                {onBoardInfoUrl && (
                  <Link to={onBoardInfoUrl} target="_blank">
                    <Typography className="hover:underline">
                      <FormattedMessage id="payment.onboard.link" />
                    </Typography>
                  </Link>
                )}
              </>
            )}
            {discounts?.discountedPrices?.price_breakdown?.length > 0 && (
              <Typography>
                <FormattedMessage
                  id="ticketing.supporting_document_information"
                  defaultMessage="A supporting document will be required when entering the vehicle"
                />
              </Typography>
            )}
          </Alert>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
}
