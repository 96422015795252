import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useDropDownStyles = makeStyles((theme: CustomTheme) => ({
  selectContent: {
    padding: "20px 4px 20px 4px",
    overflow: "hidden",
  },
  selectInput: {
    border: "0.125rem solid transparent",
    borderColor: theme.palette.primary.main,
    borderRadius: "2rem",

    "& .MuiInputLabel-formControl": {
      fontWeight: 500,

      transform: "translate(16px, 9px) scale(1)",

      "&[data-shrink='true']": {
        transform: "translate(0, -20px) scale(0.75)",
      },
    },

    "& label + .MuiInput-formControl": {
      margin: 0,
    },

    "& .MuiSelect-root": {
      padding: "0.5rem 1rem",
      borderRadius: "2rem",
    },

    "& .MuiInput-underline::before": {
      content: "none",
    },

    "& .MuiInput-underline::after": {
      content: "none",
    },

    "& .MuiSelect-underline::before": {
      content: "none",
    },

    "& .MuiSelect-underline::after": {
      content: "none",
    },

    "& .MuiSelect-icon": {
      padding: "0 0.75rem",
    },
  },
}));
