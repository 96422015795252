import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchLastBookings,
  onAddressSelected,
} from "containers/SearchForm/actions.js";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import FormSectionHeader from "./FormSectionHeader";
import ArrowRight from "components/Icons/ArrowRight";
import BusStop from "components/Icons/BusStop";
import { useIntl } from "react-intl";
import DropOff from "../Icons/DropOff/DropOff";
import PickUp from "../Icons/PickUp/PickUp";
import ExpandMore from "components/Icons/ExpandMore";

import styles from "./form.cssmodule.scss";

function LastBookings(props) {
  const intl = useIntl();

  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [pressedIndex, setPressedIndex] = useState(-1);

  const {
    isRequestingValidateBooking,
    fetchLastBookings,
    onAddressSelected,
    lastBookings,
  } = props;
  // if arriving on /search view, fetch lastBooking for history feature
  useEffect(() => {
    fetchLastBookings();
  }, [isRequestingValidateBooking, fetchLastBookings]);

  if (_.isEmpty(lastBookings)) return null;

  const onKeyDown = (e) => {
    e.stopPropagation();
    switch (e.keyCode) {
      case 38: //up arrow
        setHighlightedIndex(Math.max(0, highlightedIndex - 1));
        return;
      case 40: //down arrow
        setHighlightedIndex(
          Math.min(lastBookings.length - 1, highlightedIndex + 1),
        );
        return;
      case 13: // enter key
        select(lastBookings[highlightedIndex]);
        return;
      default:
        return;
    }
  };
  // business logic that should be on server side
  const isEquivalent = (position, node) =>
    position.address === "NO ADDRESS" ||
    position.address === node.position.address;

  const getDisplay = (position, node, isDeparture) => {
    // case 1 : is equivalent to NODE
    if (isEquivalent(position, node))
      return (
        <div className="row-only">
          {isDeparture && (
            <BusStop
              style={{
                marginRight: "1rem",
                width: "1.5rem",
                height: "1.5rem",
                fill: "#1EA86A",
              }}
              aria-label={intl.formatMessage({
                id: "search.pickup.placeholder",
              })}
              aria-hidden={false} // aria-hidden is active by default on SvgIcon
            />
          )}
          {!isDeparture && (
            <BusStop
              style={{
                marginRight: "1rem",
                width: "1.5rem",
                height: "1.5rem",
                fill: "#FF1D58",
              }}
              aria-label={intl.formatMessage({
                id: "search.dropoff.placeholder",
              })}
              aria-hidden={false} // aria-hidden is active by default on SvgIcon
            />
          )}
          <Typography variant="body2" style={{ width: "100%" }}>
            {node.name}
          </Typography>
        </div>
      );
    return (
      <div className="row-only">
        {isDeparture && (
          <PickUp
            width={24}
            height={24}
            style={{ marginRight: "1rem" }}
            aria-label={intl.formatMessage({ id: "search.pickup.placeholder" })}
          />
        )}

        {!isDeparture && (
          <DropOff
            width={24}
            height={24}
            style={{ marginRight: "1rem" }}
            aria-label={intl.formatMessage({
              id: "search.dropoff.placeholder",
            })}
          />
        )}

        <Typography variant="body2" style={{ width: "100%" }}>
          {position.address}
        </Typography>
      </div>
    );
  };

  const select = (booking) => {
    const arr = [
      {
        field: "departure",
        position: booking.departure_position,
        node: booking.pickup_node,
      },
      {
        field: "destination",
        position: booking.destination_position,
        node: booking.dropoff_node,
      },
    ];
    arr.map((o) => {
      if (isEquivalent(o.position, o.node)) {
        onAddressSelected(
          o.node.name,
          o.node.id,
          o.field,
          o.node.position.latitude,
          o.node.position.longitude,
          o.node.position.address,
        );
      } else {
        onAddressSelected(
          o.position.address,
          null,
          o.field,
          o.position.latitude,
          o.position.longitude,
          o.position.address,
        );
      }
      return;
    });
  };

  return (
    <Accordion className={styles.accordion}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <FormSectionHeader
          title={intl.formatMessage({ id: "section.history" })}
        />
      </AccordionSummary>
      <AccordionDetails>
        <div
          className="fullWidth"
          style={{
            maxHeight: "15rem",
            overflow: "auto",
          }}
        >
          <List
            classes={{ root: styles.listContainer }}
            dense
            onKeyDown={(e) => onKeyDown(e)}
          >
            {lastBookings.map((booking, index) => {
              return (
                <li key={index}>
                  <ListItem
                    classes={{ root: styles.listItem }}
                    id={"history-" + index}
                    button // Makes the element a div, hence the wrapping li
                    selected={index === highlightedIndex}
                    aria-pressed={index === pressedIndex}
                    onClick={() => {
                      select(booking);
                      setHighlightedIndex(null);
                      setPressedIndex(index);
                    }}
                  >
                    <div
                      className="row-only"
                      style={{ alignItems: "flex-start", width: "100%" }}
                    >
                      <div style={{ flexGrow: 1, flexBasis: "5rem" }}>
                        {getDisplay(
                          booking.departure_position,
                          booking.pickup_node,
                          true,
                        )}
                      </div>
                      <ArrowRight style={{ flex: "0 0 2em" }} />
                      <div style={{ flexGrow: 1, flexBasis: "5rem" }}>
                        {getDisplay(
                          booking.destination_position,
                          booking.dropoff_node,
                          false,
                        )}
                      </div>
                    </div>
                  </ListItem>
                </li>
              );
            })}
          </List>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
const mapStateToProps = (state) => ({
  lastBookings: state.search.lastBookings,
  isRequestingValidateBooking: state.bookingValidation.isRequesting,
});
const mapDispatchToProps = (dispatch) => ({
  fetchLastBookings: () => {
    dispatch(fetchLastBookings());
  },
  onAddressSelected: (
    suggestion,
    nodeId,
    type,
    latitude,
    longitude,
    address,
    placeId,
  ) => {
    dispatch(
      onAddressSelected(
        suggestion,
        nodeId,
        type,
        latitude,
        longitude,
        address,
        placeId,
      ),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LastBookings);
