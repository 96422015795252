import React from "react";
import { useIntl } from "react-intl";

import Sidebar from "components/Sidebar/Search.jsx";
import SearchResultGrid from "containers/SearchResultGrid/SearchResultGrid.jsx";
import SearchResultConfirm from "containers/SearchResultConfirm/SearchResultConfirm.jsx";

import styles from "./main.cssmodule.scss";

export default function SearchResultView() {
  const intl = useIntl();

  return (
    <div className="column" style={{ margin: "1%" }}>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "breadcrumb.search" })}
      </h1>
      <div className={styles.content}>
        <div className={styles.sideBar}>
          <Sidebar />
        </div>

        <div className={styles.results}>
          <SearchResultGrid />
        </div>

        <div className={styles.searchResultConfirm}>
          <SearchResultConfirm />
        </div>
      </div>
    </div>
  );
}
