// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.impersonateDialog-cssmodule-dialog__container-mxYxM{min-width:40rem}.impersonateDialog-cssmodule-currentTerritory__container-hqhyR{width:40%;padding:10px;border-left:5px solid black;border-radius:5px;box-shadow:0 1px 3px rgba(0,0,0,0.12),0 1px 2px rgba(0,0,0,0.24)}@media (max-width: 425px){.impersonateDialog-cssmodule-currentTerritory__container-hqhyR{width:100%}}.impersonateDialog-cssmodule-currentTerritory__line-RqFWS{height:1px;margin:10px 0;background:#3a3a3a}.impersonateDialog-cssmodule-currentTerritory__wrapper-s4nHn{display:flex;justify-content:space-between;align-items:center;margin-top:15px}.impersonateDialog-cssmodule-availableTerritories__wrapper-xqWBL{display:flex;justify-content:space-around;flex-wrap:wrap;overflow:auto}@media (max-width: 425px){.impersonateDialog-cssmodule-availableTerritories__wrapper-xqWBL{justify-content:flex-start;overflow:scroll}}
`, "",{"version":3,"sources":["webpack://./src/components/ImpersonateDialog/impersonateDialog.cssmodule.scss"],"names":[],"mappings":"AAAA,qDACE,eAAgB,CACjB,+DAIG,SAAU,CACV,YAAa,CACb,2BAA4B,CAC5B,iBAAkB,CAClB,gEAE+B,CAE/B,0BATF,+DAUI,UAAW,CAEd,CACD,0DACE,UAAW,CACX,aAAc,CACd,kBAAmB,CACpB,6DAEC,YAAa,CACb,6BAA8B,CAC9B,kBAAmB,CACnB,eAAgB,CACjB,iEAID,YAAa,CACb,4BAA6B,CAC7B,cAAe,CACf,aAAc,CAEd,0BANF,iEAOI,0BAA2B,CAC3B,eAAgB,CAEnB","sourcesContent":[".dialog__container {\n  min-width: 40rem;\n}\n\n.currentTerritory {\n  &__container {\n    width: 40%;\n    padding: 10px;\n    border-left: 5px solid black;\n    border-radius: 5px;\n    box-shadow:\n      0 1px 3px rgba(0, 0, 0, 0.12),\n      0 1px 2px rgba(0, 0, 0, 0.24);\n\n    @media (max-width: 425px) {\n      width: 100%;\n    }\n  }\n  &__line {\n    height: 1px;\n    margin: 10px 0;\n    background: #3a3a3a;\n  }\n  &__wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 15px;\n  }\n}\n\n.availableTerritories__wrapper {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n  overflow: auto;\n\n  @media (max-width: 425px) {\n    justify-content: flex-start;\n    overflow: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog__container": `impersonateDialog-cssmodule-dialog__container-mxYxM`,
	"currentTerritory__container": `impersonateDialog-cssmodule-currentTerritory__container-hqhyR`,
	"currentTerritory__line": `impersonateDialog-cssmodule-currentTerritory__line-RqFWS`,
	"currentTerritory__wrapper": `impersonateDialog-cssmodule-currentTerritory__wrapper-s4nHn`,
	"availableTerritories__wrapper": `impersonateDialog-cssmodule-availableTerritories__wrapper-xqWBL`
};
export default ___CSS_LOADER_EXPORT___;
