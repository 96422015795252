import React, { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Grid, Paper, Tabs, Tab, Typography } from "@material-ui/core";

import { ContactCard } from "molecules/ContactCard/ContactCard";
import { PaperLink } from "molecules/PaperLink/PaperLink";

import { EmailIcon } from "icons/EmailIcon";
import { FormIcon } from "icons/FormIcon";
import { PhoneIcon } from "icons/PhoneIcon";

import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

import { useAboutStyles } from "./aboutStyles";

const TabPanel = (props) => {
  const { children, value, index } = props;

  return <>{value === index && <div>{children}</div>}</>;
};

export default function About() {
  const { productParameters } = useContext(ProductContext);
  const { selectedTerritory } = useContext(TerritoryContext);
  const [value, setValue] = useState(0);
  const intl = useIntl();
  const classes = useAboutStyles();

  const brand = productParameters?.brand;
  const contactEmail = productParameters?.contact?.contact_mail;
  const phoneContacts = productParameters?.contact?.phone_contacts;
  const contactForm = productParameters?.contact?.contact_url;

  const equipmentsDocumentationUrl =
    productParameters?.contact?.equipments_documentation_url;

  const serviceInfoDocumentationUrl =
    selectedTerritory?.zone_infos?.service_info_documentation_url;

  const onBoardInfoUrl = selectedTerritory?.payment?.onboard?.info_url;

  // TO DO: replace this 2 declarations by the 2 commented declarations that follow (when the backend will be updated in prod with the new positioning of this documentation parameters):
  const serviceDesignDocumentationUrl =
    selectedTerritory?.zone_infos?.service_design_documentation_url ||
    productParameters?.contact?.service_design_documentation_url ||
    selectedTerritory?.zone_infos?.zone_info_url;

  const schedulesDocumentationUrl =
    selectedTerritory?.zone_infos?.schedules_documentation_url ||
    productParameters?.contact?.schedules_documentation_url ||
    selectedTerritory?.zone_infos?.service_info_url;
  /*
  const serviceDesignDocumentationUrl =
    selectedTerritory?.zone_infos?.service_design_documentation_url;

  const schedulesDocumentationUrl =
    selectedTerritory?.zone_infos?.schedules_documentation_url;
  */
  const ticketingDocumentationUrl =
    productParameters?.contact?.ticketing_documentation_url;

  const handleTabs = (event, tabIndex) => {
    setValue(tabIndex);
  };

  return (
    <Grid
      item
      xs={12}
      md={10}
      className={classes.aboutContainer}
      component={Paper}
    >
      <Tabs indicatorColor="primary" value={value} onChange={handleTabs}>
        <Tab
          className={classes.aboutTab}
          label={intl.formatMessage({
            id: "about.help",
            defaultMessage: "Help",
          })}
        />

        <Tab
          className={classes.aboutTab}
          label={intl.formatMessage({ id: "about.labelTab2" })}
        />
      </Tabs>

      {/* Help TabPanel */}
      <TabPanel value={value} index={0}>
        {equipmentsDocumentationUrl ||
        serviceInfoDocumentationUrl ||
        serviceDesignDocumentationUrl ||
        schedulesDocumentationUrl ||
        ticketingDocumentationUrl ? (
          <div className={classes.aboutTabPanel}>
            {serviceInfoDocumentationUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "help.service_info",
                  defaultMessage: "Service Info",
                })}
                url={serviceInfoDocumentationUrl}
              />
            )}

            {schedulesDocumentationUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "help.schedules",
                  defaultMessage: "Services hours",
                })}
                url={schedulesDocumentationUrl}
              />
            )}

            {serviceDesignDocumentationUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "help.service_design",
                  defaultMessage: "Served zones",
                })}
                url={serviceDesignDocumentationUrl}
              />
            )}

            {ticketingDocumentationUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "help.ticketing",
                  defaultMessage: "Fares & subscriptions",
                })}
                url={ticketingDocumentationUrl}
              />
            )}

            {equipmentsDocumentationUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "help.equipments_policy",
                  defaultMessage: "Equipments policy",
                })}
                url={equipmentsDocumentationUrl}
              />
            )}
            {onBoardInfoUrl && (
              <PaperLink
                linkText={intl.formatMessage({
                  id: "ACTION_INFORMATION_ONBOARD_PAYMENT",
                })}
                url={onBoardInfoUrl}
              />
            )}
          </div>
        ) : (
          <Typography className={classes.noInfos}>
            <FormattedMessage id="about.noInfos" />
          </Typography>
        )}
      </TabPanel>

      {/* Contact TabPanel */}
      <TabPanel value={value} index={1}>
        {contactEmail || contactForm || phoneContacts ? (
          <>
            <Typography className={classes.contactTitle}>
              <FormattedMessage id="about.contactTitle" values={{ brand }} />
            </Typography>

            <div className={classes.aboutTabPanel}>
              {contactEmail && (
                <ContactCard
                  contactMethodName={intl.formatMessage({
                    id: "about.contactEmail",
                  })}
                  contactToCopy={contactEmail}
                  copyBtnLabel={intl.formatMessage({
                    id: "about.copyEmail",
                  })}
                  iconElement={<EmailIcon />}
                />
              )}

              {contactForm && (
                <ContactCard
                  contactMethodName={intl.formatMessage({
                    id: "about.contactForm",
                  })}
                  formUrl={contactForm}
                  formLinkLabel={intl.formatMessage({
                    id: "about.formFill",
                  })}
                  iconElement={<FormIcon />}
                />
              )}

              {phoneContacts &&
                phoneContacts.map((contactPhone, index) => (
                  <ContactCard
                    key={contactPhone.display_name}
                    contactMethodName={`${intl.formatMessage({
                      id: "about.contactPhone",
                    })} ${contactPhone.display_name}`}
                    contactToCopy={contactPhone.phone_number}
                    copyBtnLabel={intl.formatMessage({
                      id: "about.copyPhone",
                    })}
                    iconElement={<PhoneIcon />}
                  />
                ))}
            </div>
          </>
        ) : (
          <Typography className={classes.noInfos}>
            <FormattedMessage id="about.noInfos" />
          </Typography>
        )}
      </TabPanel>
    </Grid>
  );
}
