import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate } from "react-router-dom";

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@material-ui/core";

import api from "api";

import { ProductContext } from "contexts/ProductContext";

import { usePersonalDatatyles } from "./personalDataStyles";

export default function PersonalData({ profileData, onFieldChange }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const navigate = useNavigate();
  const classes = usePersonalDatatyles();

  const { productParameters } = useContext(ProductContext);

  const brand = productParameters?.brand;

  const onCheckChange = (e, value) => {
    onFieldChange("optInMarket", value);
  };

  const handleOpen = () => {
    setIsDialogOpen(true);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleDelete = () => {
    api
      .deleteCustomerAccount()
      .then((json) => {
        console.log(json);
      })
      .catch((error) => {
        console.log(error);
      }),
      navigate("/login");
  };

  return (
    <div className={classes.personalDataContainer}>
      <Typography
        className={classes.sectionHeading}
        variant="h4"
        component="h3"
      >
        <FormattedMessage id={"profile.optInMarket"} />
      </Typography>

      <FormControlLabel
        label={
          <Typography id="accept-personal-data">
            <FormattedMessage id={"terms.commercial"} values={{ brand }} />
          </Typography>
        }
        control={
          <Checkbox
            id="accept-commercial-use"
            color="primary"
            checked={profileData.optInMarket}
            onChange={(e) => onCheckChange("optInMarket", e.target.checked)}
            inputProps={{
              "aria-labelledby": "accept-personal-data",
            }}
          />
        }
      />

      <div className={classes.divider} aria-hidden="true" />

      <div className={classes.deleteSection}>
        <Typography
          className={classes.sectionHeading}
          variant="h4"
          component="h3"
        >
          <FormattedMessage id={"profile.deleteAccountData"} />
        </Typography>

        <Button
          className={classes.deleteButton}
          variant="outlined"
          onClick={handleOpen}
        >
          <FormattedMessage id={"profile.deleteAccount"} />
        </Button>
      </div>

      <Dialog
        open={isDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={handleClose}
      >
        <DialogTitle
          className={classes.deleteDialogHeading}
          id="alert-dialog-title"
        >
          <FormattedMessage id={"profile.deleteAccountDialogTitle"} />
        </DialogTitle>

        <DialogContent>
          <Typography id="alert-dialog-description">
            <FormattedMessage id={"profile.deleteAccountDialog"} />
          </Typography>
        </DialogContent>

        <DialogActions className={classes.actionButtonsContainer}>
          <Button
            className={classes.actionButton}
            variant="contained"
            color="primary"
            onClick={handleClose}
            autoFocus
          >
            <FormattedMessage id={"cancel"} />
          </Button>

          <Button
            className={`${classes.actionButton} ${classes.deleteButton}`}
            variant="outlined"
            onClick={handleDelete}
          >
            <FormattedMessage id={"delete"} />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
