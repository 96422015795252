import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import { Box, CircularProgress, List, Typography } from "@material-ui/core";

import {
  deletePaymentMethod,
  getSavedPaymentMethods,
} from "containers/Stripe/actions";
import { selectPaymentMethod } from "containers/Payment/actions";
import { SavedCardList } from "./SavedPaymentMethodList/SavedCardList";
import { SavedIbanList } from "./SavedPaymentMethodList/SavedIbanList";
import {
  PaymentMethodEnum,
  StripeCardType,
  StripeIbanType,
} from "types/payment";

type SavedPaymentMethodsContainerProps = {
  method: PaymentMethodEnum;
  isRequestingSavedPaymentMethods: boolean;
  getSavedPaymentMethods: () => void;
  savedCards: StripeCardType[];
  savedIBANs: StripeIbanType[];
  selectPaymentMethod: (method: string, index: number) => void;
  deletePaymentMethod: (method: string, id: number) => void;
  selectedCardIndex: number;
  selectedIBANIndex: number;
  canBeSelected?: boolean;
};

function SavedPaymentMethodsContainer(
  props: SavedPaymentMethodsContainerProps,
) {
  const {
    method,
    isRequestingSavedPaymentMethods,
    getSavedPaymentMethods,
    savedCards,
    savedIBANs,
    selectPaymentMethod,
    deletePaymentMethod,
    selectedCardIndex,
    selectedIBANIndex,
    canBeSelected = true,
  } = props;

  useEffect(() => {
    getSavedPaymentMethods();
  }, []);

  if (isRequestingSavedPaymentMethods) {
    return <CircularProgress />;
  }

  if (savedCards.length === 0 && method === PaymentMethodEnum.STRIPE_CARD) {
    return null;
  }

  return (
    <>
      {savedCards.length > 0 && method === PaymentMethodEnum.STRIPE_CARD && (
        <Typography variant="caption">
          <Box mx={3}>
            <FormattedMessage id="payment.saved_cards" />
          </Box>
        </Typography>
      )}
      {savedIBANs.length === 0 && method === PaymentMethodEnum.STRIPE_SEPA && (
        <Typography variant="caption">
          <Box mx={3}>
            <FormattedMessage id="TEXT_IBAN_PAYMENT_INFO" />
          </Box>
        </Typography>
      )}

      <hr aria-hidden="true" className="w-full" />

      <List className="w-full" data-testid="saved-payment-method-list">
        {method === PaymentMethodEnum.STRIPE_CARD && savedCards.length > 0 && (
          <SavedCardList
            savedCards={savedCards}
            selectedCardIndex={selectedCardIndex}
            method={method}
            selectPaymentMethod={selectPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            canBeSelected={canBeSelected}
          />
        )}

        {method === PaymentMethodEnum.STRIPE_SEPA && savedIBANs.length > 0 && (
          <SavedIbanList
            savedIBANs={savedIBANs}
            selectedIBANIndex={selectedIBANIndex}
            method={method}
            selectPaymentMethod={selectPaymentMethod}
            deletePaymentMethod={deletePaymentMethod}
            canBeSelected={canBeSelected}
          />
        )}
      </List>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    savedCards: state?.payment?.savedCards || [],
    savedIBANs: state?.payment?.savedIBANs || [],
    isRequestingSavedPaymentMethods:
      state?.payment?.isRequestingSavedPaymentMethods,
    selectedCardIndex: state?.payment?.selectedCardIndex ?? -1, // default value is for new card
    selectedIBANIndex: state?.payment?.selectedIBANIndex ?? -1, // default value is for new card
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSavedPaymentMethods: () => dispatch(getSavedPaymentMethods()),
  deletePaymentMethod: (method, id) =>
    dispatch(deletePaymentMethod(method, id)),
  selectPaymentMethod: (method, index) =>
    dispatch(selectPaymentMethod(method, index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SavedPaymentMethodsContainer);
