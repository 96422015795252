import { makeStyles } from "@material-ui/core";

export const usePaymentResumeDetailsStyles = makeStyles(() => ({
  paymentResumeDetailsContainer: {
    padding: "1rem",

    "& h2": {
      fontWeight: 500,
    },
  },

  detailsList: {
    margin: "1rem 0",
  },
  removeButtonContainer: {
    width: "2.25rem",

    textAlign: "right",
  },
  totalPrice: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "& h3": {
      fontWeight: 500,
    },
  },
  divider: {
    marginBottom: "16px",
  },
}));
