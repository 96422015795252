// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cardCard {
  width: 30em;
  align-items: center;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.stripeLabel {
  font-weight: 300;
  letter-spacing: 0.025em;
}

/* form {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 3px solid #e6ebf1;
} */

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1rem;
  font-family: "Source Code Pro", monospace;
  box-shadow:
    rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus {
  box-shadow:
    rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  transition: all 150ms ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/Stripe/stripe.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;;;;GAIG;;AAEH;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;EACf,yCAAyC;EACzC;;wCAEsC;EACtC,SAAS;EACT,UAAU;EACV,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE;;wCAEsC;EAEtC,0BAA0B;AAC5B","sourcesContent":[".cardCard {\n  width: 30em;\n  align-items: center;\n}\n\n.Checkout {\n  margin: 0 auto;\n  max-width: 800px;\n  box-sizing: border-box;\n  padding: 0 5px;\n}\n\n.stripeLabel {\n  font-weight: 300;\n  letter-spacing: 0.025em;\n}\n\n/* form {\n  margin-bottom: 40px;\n  padding-bottom: 40px;\n  border-bottom: 3px solid #e6ebf1;\n} */\n\n.StripeElement {\n  display: block;\n  margin: 10px 0 20px 0;\n  max-width: 500px;\n  padding: 10px 14px;\n  font-size: 1rem;\n  font-family: \"Source Code Pro\", monospace;\n  box-shadow:\n    rgba(50, 50, 93, 0.14902) 0px 1px 3px,\n    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;\n  border: 0;\n  outline: 0;\n  border-radius: 4px;\n  background: white;\n}\n\n.StripeElement--focus {\n  box-shadow:\n    rgba(50, 50, 93, 0.109804) 0px 4px 6px,\n    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;\n  -webkit-transition: all 150ms ease;\n  transition: all 150ms ease;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
