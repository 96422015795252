import React from "react";

import { cn } from "utils/classes";

const Frame = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div
      className={cn("rounded-2xl border-[1px] border-primary p-4", className)}
      ref={ref}
      {...props}
    />
  );
});

Frame.displayName = "Frame";

export { Frame };
