import { ResourceType } from "api/resources/types/resourceType";

export const discount: ResourceType = {
  checkPromoCode: {
    endpoint: "/check-promo-code",
    impersonate: true,
    method: "POST",
  },
  getApplicableDiscountProfilesForCustomerProposition: {
    endpoint: "/get-applicable-discount-profiles-for-customer-proposition",
    method: "POST",
    impersonate: true,
  },
};
