import React, { useContext } from "react";
import { connect } from "react-redux";

import RideMap from "components/RideMap/RideMap.jsx";

import { TerritoryContext } from "contexts/TerritoryContext";

/**
 * Container for our RideMap component
 * (using google maps api)
 * goal is to provide appropriate dep, pickup, dropoff and des coords for the markers
 */
function RideMapContainer(props) {
  // context is Single Reservation View OR Search Result Confirmation OR edition view
  const { responses, reservation, selectedProposals, selectedDatetime } = props;

  const { selectedTerritory } = useContext(TerritoryContext);

  // the goal is to define all those below with { lat, lng } format
  let dep, pickup, dropoff, des;
  let isFixedLine = false;

  if (selectedProposals === undefined) return null;
  // CASE 1: data source is fixed lines
  if (selectedProposals[selectedDatetime]?.sections !== undefined) {
    dep = {
      lat: selectedProposals[selectedDatetime].sections[0].departure_stop
        .position.latitude,
      lng: selectedProposals[selectedDatetime].sections[0].departure_stop
        .position.longitude,
    };
    des = {
      lat: selectedProposals[selectedDatetime].sections[
        selectedProposals[selectedDatetime].sections.length - 1
      ].arrival_stop.position.latitude,
      lng: selectedProposals[selectedDatetime].sections[
        selectedProposals[selectedDatetime].sections.length - 1
      ].arrival_stop.position.longitude,
    };
    pickup = {
      lat: selectedProposals[selectedDatetime].sections[0].departure_stop
        .position.latitude,
      lng: selectedProposals[selectedDatetime].sections[0].departure_stop
        .position.longitude,
    };
    dropoff = {
      lat: selectedProposals[selectedDatetime].sections[
        selectedProposals[selectedDatetime].sections.length - 1
      ].arrival_stop.position.latitude,
      lng: selectedProposals[selectedDatetime].sections[
        selectedProposals[selectedDatetime].sections.length - 1
      ].arrival_stop.position.longitude,
    };
    isFixedLine = true;
  } else if (_.isEmpty(reservation)) {
    // CASE 2: data source is responses[...selectedProposals]
    // departure and destination are common to all time propositions of the day
    const reservationInfo = _.get(
      responses,
      selectedDatetime + ".reservation_info",
    );
    dep = {
      lat: _.get(reservationInfo, "departure_position.latitude"),
      lng: _.get(reservationInfo, "departure_position.longitude"),
    };
    des = {
      lat: _.get(reservationInfo, "destination_position.latitude"),
      lng: _.get(reservationInfo, "destination_position.longitude"),
    };

    // pickup and dropoff depends of the time proposition
    const datetimeData = _.find(_.get(reservationInfo, "proposed_datetimes"), {
      id: _.get(selectedProposals, selectedDatetime),
    });
    // get-rides-multinodes case
    if (_.get(datetimeData, "pickup_node.position.latitude")) {
      pickup = {
        lat: _.get(datetimeData, "pickup_node.position.latitude"),
        lng: _.get(datetimeData, "pickup_node.position.longitude"),
      };
      dropoff = {
        lat: _.get(datetimeData, "dropoff_node.position.latitude"),
        lng: _.get(datetimeData, "dropoff_node.position.longitude"),
      };
    } else {
      // change-booking-search case (pickup_node ~= id) => standard nodes
      pickup = {
        lat: _.get(reservationInfo, "pickup_node.position.latitude"),
        lng: _.get(reservationInfo, "pickup_node.position.longitude"),
      };
      dropoff = {
        lat: _.get(reservationInfo, "dropoff_node.position.latitude"),
        lng: _.get(reservationInfo, "dropoff_node.position.longitude"),
      };
    }
  } else {
    // CASE 3: data source is "reservation"
    dep = {
      lat: _.get(reservation, "departure_position.latitude"),
      lng: _.get(reservation, "departure_position.longitude"),
    };
    des = {
      lat: _.get(reservation, "destination_position.latitude"),
      lng: _.get(reservation, "destination_position.longitude"),
    };
    pickup = {
      lat: _.get(reservation, "pickup_node.position.latitude"),
      lng: _.get(reservation, "pickup_node.position.longitude"),
    };
    dropoff = {
      lat: _.get(reservation, "dropoff_node.position.latitude"),
      lng: _.get(reservation, "dropoff_node.position.longitude"),
    };
  }

  // to avoid render error
  if (!reservation && _.isEmpty(selectedProposals)) return null;

  return (
    <RideMap
      isFixedLine={isFixedLine}
      dep={dep}
      des={des}
      pickup={pickup}
      dropoff={dropoff}
      defaultCenter={{
        lat: parseFloat(
          _.get(selectedTerritory, "geography.default_latitude", 0),
        ),
        lng: parseFloat(
          _.get(selectedTerritory, "geography.default_longitude", 0),
        ),
      }}
    />
  );
}

const _mapState = (state) => ({
  responses: state.search.responses,
  selectedProposals: state.search.selectedProposals,
  selectedDatetime: state.search.selectedDatetime,
});

export default connect(_mapState, null)(RideMapContainer);
