import * as React from "react";
import Credit from "components/Icons/Credit";
import CreditPaymentProvider from "components/Payment/CreditPaymentProvider";
import CreditCard from "components/Icons/CreditCard";
import StripeContainer from "containers/Stripe/StripeContainer";
import OnBoardPaymentProvider from "components/Payment/OnBoardPaymentProvider";
import { Paypal } from "components/Icons/Paypal";
import { PaypalPaymentProvider } from "components/Payment/PaypalPaymentProvider";
import { BankIcon } from "icons/BankIcon";
import {
  PaymentMethod,
  PaymentMethodAPIEnum,
  PaymentMethodEnum,
  PaymentMethodProvider,
  PaymentMethodProviderEnum,
} from "types/payment";

type PaymentProvidersAndMethodsInterface = {
  method: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
  component: React.FunctionComponent<any>;
};

type PaymentOptionsInterface = {
  enabled: boolean;
  payment_methods?: string[];
};

export const paymentMode = {
  ZERO: "ZERO",
  MODULE: "MODULE",
};

export const paymentProvidersAndMethods: Record<
  PaymentMethod,
  PaymentProvidersAndMethodsInterface
> = {
  [PaymentMethodEnum.CREDIT]: {
    method: PaymentMethodAPIEnum.CREDIT,
    icon: Credit,
    component: CreditPaymentProvider,
  },
  [PaymentMethodEnum.STRIPE_CARD]: {
    method: PaymentMethodAPIEnum.STRIPE,
    icon: CreditCard,
    component: StripeContainer,
  },
  [PaymentMethodEnum.STRIPE_SEPA]: {
    method: PaymentMethodAPIEnum.STRIPE,
    icon: BankIcon,
    component: StripeContainer,
  },
  [PaymentMethodEnum.ON_BOARD]: {
    method: PaymentMethodAPIEnum.CASH,
    icon: null,
    component: OnBoardPaymentProvider,
  },
  [PaymentMethodEnum.PAYPAL]: {
    method: PaymentMethodAPIEnum.PAYPAL,
    icon: Paypal,
    component: PaypalPaymentProvider,
  },
};

export const paymentPriorityOrder: Array<PaymentMethod> = [
  PaymentMethodEnum.PAYPAL,
  PaymentMethodEnum.STRIPE_CARD,
  PaymentMethodEnum.STRIPE_SEPA,
  PaymentMethodEnum.CREDIT,
  PaymentMethodEnum.ON_BOARD,
];

/**
 * Get payment methods based on the payment options
 * Specific case
 * ----------------
 * In data returns in Stripe method we have the sub methods *STRIPE_CARD* and/or *STRIPE_SEPA*
 * and we need add the sub methods to the payment methods array
 * @param paymentOptions
 */
export const getPaymentMethods = (
  paymentOptions: PaymentOptionsInterface,
): string[] => {
  const paymentMethodsKeys = Object.values(PaymentMethodProviderEnum);

  const paymentMethods = (paymentOptions.payment_methods || []).reduce(
    (paymentMethodsAcc: PaymentMethod[], method: PaymentMethodProvider) => {
      const paymentMethodEnabled = paymentOptions[method].enabled;

      if (!paymentMethodsKeys.includes(method)) return paymentMethodsAcc;

      if (!paymentMethodEnabled) {
        return paymentMethodsAcc;
      }

      if (method === PaymentMethodProviderEnum.STRIPE) {
        if (
          paymentOptions[method].payment_methods.includes(
            PaymentMethodEnum.STRIPE_CARD,
          )
        ) {
          paymentMethodsAcc.push(PaymentMethodEnum.STRIPE_CARD);
        }
        if (
          paymentOptions[method].payment_methods.includes(
            PaymentMethodEnum.STRIPE_SEPA,
          )
        ) {
          paymentMethodsAcc.push(PaymentMethodEnum.STRIPE_SEPA);
        }
      } else {
        // TODO replaced alias
        paymentMethodsAcc.push(method as unknown as PaymentMethod);
      }

      return paymentMethodsAcc;
    },
    [],
  );

  return paymentMethods.sort(
    (m1, m2) =>
      paymentPriorityOrder.indexOf(m1) - paymentPriorityOrder.indexOf(m2),
  );
};
