import * as React from "react";
import { useContext } from "react";

import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { TerritoryContext } from "contexts/TerritoryContext";
import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

type paypalPropsInterface = {
  totalPrice: string;
  validate: (payload) => void;
};

export const PaypalPaymentProvider = (paypalProps: paypalPropsInterface) => {
  const { totalPrice, validate } = paypalProps;

  const { selectedTerritory } = useContext(TerritoryContext);

  const currency = selectedTerritory?.payment.currency || "";
  const clientID = selectedTerritory?.payment?.paypal?.client_id || "";

  const paypalOptions = {
    "client-id": clientID,
    currency: currency,
  };

  return (
    <PayPalScriptProvider options={paypalOptions}>
      <div className="column" style={{ padding: "1rem 1rem 1rem 4rem" }}>
        <Typography variant="body1">
          <FormattedMessage id="text.paypal_payment" />
        </Typography>

        <div
          className="row fullWidth"
          style={{
            alignItems: "flex-end",
            alignSelf: "flex-end",
            justifyContent: "space-between",
          }}
        ></div>
      </div>
      <div style={{ padding: "1rem 4rem" }}>
        <PayPalButtons
          style={{ layout: "horizontal" }}
          createOrder={(data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: totalPrice,
                  },
                },
              ],
            });
          }}
          onApprove={(data, _) => {
            // Capture the funds from the transaction
            const payload = {
              order_id: data?.orderID,
            };

            validate(payload);

            return Promise.resolve();
          }}
        />
      </div>
    </PayPalScriptProvider>
  );
};
