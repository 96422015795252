import api from "apiAxios";

import { settings } from "config/app";
import cookie from "lib/cookie.js";
import { CLOSE_SESSION } from "containers/ImpersonateForm/actions.js";
import { resetSearch } from "containers/SearchForm/actions.js";

export const PROFILE_FETCHED = "@@user/PROFILE_FETCHED";
export const PROFILE_UPDATED = "@@user/PROFILE_UPDATED";
export const FAVORITE_UPDATED = "@@user/FAVORITE_UPDATED";

export const LOGOUT = "LOGOUT";
export const UPDATED_SETTINGS = "UPDATED_SETTINGS";
/**
 * Logout from the app. Delete our auth cookie token
 * and the impersonate one if it exists
 *
 * @return
 */
export const logout = (navigate, setIsAuthenticated) => (dispatch) => {
  api
    .logout({})
    .then(() => {
      cookie.erase(settings.cookieKeys.authCookie);
      cookie.erase("adm");
      cookie.erase(settings.cookieKeys.isLoggedWithOpenid);

      setIsAuthenticated(false);

      // Operator eXperience : we reset search between impersonated users
      dispatch(resetSearch());
      // impersonate data
      cookie.erase(settings.cookieKeys.impersonateCookie);
      dispatch({ type: CLOSE_SESSION });

      window.scrollTo(0, 0);

      navigate(SIGNUP_AFTER_SEARCH ? "/search" : "/login");

      dispatch({
        type: LOGOUT,
      });
    })
    .catch((error) => {
      console.error("Logout error", error);
    });
};

export const favoriteUpdated = (favorite) => ({
  type: FAVORITE_UPDATED,
  favorite,
});

export const profileUpdated = (profile) => ({
  type: PROFILE_UPDATED,
  profile,
});

export const jumpToNextPage = (navigate, location) => {
  navigate(location?.state?.path || "/search", {
    state: null,
  });
};
