import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Link, Typography } from "@material-ui/core";

import { useA11yDeclarationStyles } from "../a11yDeclarationStyles";

export function A11yDeclarationBlock5(props) {
  const classes = useA11yDeclarationStyles();

  const { textContents } = props;

  return (
    <>
      <Typography component="h2">{textContents["block5-h2"]}</Typography>

      <Typography component="p">{textContents["block5-p-1"]}</Typography>

      <Typography component="p">{textContents["block5-p-2"]}</Typography>

      <address>
        <Typography component="p">Padam Mobility</Typography>

        <Typography component="p">11 rue Tronchet</Typography>

        <Typography component="p">
          75&nbsp;008 Paris -{" "}
          <FormattedMessage id="country.france" defaultMessage="France" />
        </Typography>
      </address>

      <Typography component="h3">{textContents["block5-h3"]}</Typography>

      <Typography component="p">{textContents["block5-p-3"]}</Typography>

      <Typography component="p">{textContents["block5-p-4"]}</Typography>

      <ul>
        <Typography component="li">
          <Link
            className={classes.link}
            href="https://formulaire.defenseurdesdroits.fr/code/afficher.php"
            target="_blank"
          >
            {textContents["block5-li-1"]}
          </Link>
        </Typography>

        <Typography component="li">
          <Link
            className={classes.link}
            href="https://www.defenseurdesdroits.fr/saisir/delegues"
            target="_blank"
          >
            {textContents["block5-li-2"]}
          </Link>
        </Typography>

        <Typography component="li">{textContents["block5-li-3"]}</Typography>
      </ul>

      <address>
        <Typography component="p">Défenseur des droits</Typography>

        <Typography component="p">Libre réponse 71&nbsp;120</Typography>

        <Typography component="p">
          75&nbsp;342 Paris CEDEX 07 -{" "}
          <FormattedMessage id="country.france" defaultMessage="France" />
        </Typography>
      </address>
    </>
  );
}
