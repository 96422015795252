import { getLang } from "../i18n";

export const LANG_TO_COUNTRY = {
  ca: "es",
  "ca-es": "es",
  de: "de",
  "de-de": "de",
  "de-ch": "ch",
  en: "gb",
  "en-gb": "gb",
  "en-us": "us",
  es: "es",
  "es-es": "es",
  fr: "fr",
  "fr-ca": "ca",
  "fr-ch": "ch",
  "fr-fr": "fr",
  it: "it",
  "it-ch": "ch",
  sv: "se",
  da: "dk",
};

export const getCountryFromBrowserLanguage = () => {
  const mainLanguage = navigator.language.toLowerCase();

  const country =
    LANG_TO_COUNTRY[mainLanguage] || LANG_TO_COUNTRY[getLang()] || "gb";

  return country;
};

export const formatPhoneNumber = (rawPhoneNumber) => {
  return rawPhoneNumber.startsWith("+") ? rawPhoneNumber : `+${rawPhoneNumber}`;
};
