import { makeStyles } from "@material-ui/core";

export const usePersonalItemsStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    padding: "1rem",

    "@media (max-width: 450px)": {
      width: "calc(100% - 2rem)",
    },

    "& .MuiCardContent-root:last-child": {
      padding: 0,
    },
  },
  cardContent: {
    padding: 0,
  },
  cardHeader: {
    padding: 0,

    "& legend": {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
    },
  },
  cardHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    cursor: "pointer",
  },
  linkText: {
    fontSize: "1rem",
  },
  loaderContainer: {
    textAlign: "center",
  },
  newTabIcon: {
    display: "inline-block",

    color: theme.palette.info.main,

    transform: "translateY(0.25rem)",
  },
}));
