import React from "react";

import { withTheme } from "@material-ui/core/styles";

const Cross = (props) => (
  <svg
    className={`${props.className} clickable`}
    style={{ fill: props.theme.palette.error.main, ...props.style }}
    id="cross"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2297 2321"
    focusable="false"
    role="none"
    aria-hidden="true"
  >
    <path d="M481.088 85.788l666.429 666.826 666.842-666.826c260.794-260.789 660.239 131.632 395.73 396.134l-666.429 666.414 666.43 666.826c260.381 259.964-135.762 656.098-395.731 395.722l-666.842-666.414-666.43 666.414c-260.381 260.376-656.112-135.758-396.143-395.722l666.842-666.826L84.944 481.922c-259.97-260.375 135.762-656.51 396.144-396.134z" />
  </svg>
);

export default withTheme(Cross);
