import * as React from "react";

import { Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

import { FixedLinesLineType } from "components/organisms/FixedLinesResultElement/FixedLinesTypes";
import { FixedLinesLine } from "atoms/FixedLinesLine/FixedLinesLine";
import CircleCheck from "components/Icons/CircleCheck";

import { useTimedRideStepStyles } from "./timedRideStepStyles";

export enum TimedRideStepType {
  Walk = "walk",
  Pickup = "pickup",
  Dropoff = "dropoff",
  Badge = "badge",
  First = "first",
  Last = "last",
}

type TimedRideStepProps = {
  badgeElement?: JSX.Element;
  iconElement?: JSX.Element;
  stepAddress?: string;
  stepName?: string;
  timeOrDuration?: string;
  estimatedTime?: string;
  referencePickupTime?: string;
  type: TimedRideStepType;
  line?: FixedLinesLineType;
  mode?: string;
  style?: React.CSSProperties;
};

const displayDropoffLabel = (type: TimedRideStepType) =>
  type === TimedRideStepType.Dropoff ? (
    <FormattedMessage id="research.label.max_arrival" />
  ) : null;

const displayDropoffEstimatedLabel = (type: TimedRideStepType) =>
  type === TimedRideStepType.Dropoff ? (
    <FormattedMessage id="ride_details.estimated_do_time" />
  ) : null;

export function TimedRideStep({
  badgeElement,
  iconElement,
  stepAddress,
  stepName,
  timeOrDuration,
  estimatedTime,
  referencePickupTime,
  type,
  line,
  mode,
  style,
}: TimedRideStepProps) {
  const classes = useTimedRideStepStyles();

  return (
    <li className={classes.stepContainer} style={style}>
      {iconElement}

      {badgeElement && (
        <span className={classes.durationBadge}>{badgeElement}</span>
      )}

      {line && (
        <span className={classes.durationBadge}>
          <FixedLinesLine line={line} mode={mode} />
        </span>
      )}

      <div className={`${classes.column} ${line && classes.lineDescription}`}>
        {timeOrDuration && (
          <>
            <Typography
              className={
                [TimedRideStepType.Pickup, TimedRideStepType.Dropoff].includes(
                  type,
                )
                  ? classes.timeOrDuration
                  : ""
              }
            >
              <span className={classes.dataContainer}>{timeOrDuration}</span>
              <Typography
                component="span"
                className={classes.dropOffPickUpLabel}
                display="inline"
              >
                {type === TimedRideStepType.Pickup ? (
                  <FormattedMessage id="research.label.min_departure" />
                ) : (
                  displayDropoffLabel(type)
                )}
              </Typography>
            </Typography>
          </>
        )}
        {referencePickupTime && type === TimedRideStepType.Pickup ? (
          <Typography className={classes.referencePickupTime}>
            <b>{referencePickupTime}&nbsp;</b>
            <Typography
              component="span"
              className={`${classes.dropOffPickUpLabel} ${classes.labelConfirmed} ${classes.inlineLine}`}
              display="inline"
            >
              <FormattedMessage id="label.confirmed_departure" />
              <CircleCheck />
            </Typography>
          </Typography>
        ) : null}
        {(type !== TimedRideStepType.Pickup || !referencePickupTime) &&
        estimatedTime ? (
          <Typography className={classes.estimatedTime}>
            ≈ {estimatedTime}{" "}
            <Typography
              component="span"
              className={classes.dropOffPickUpLabel}
              display="inline"
            >
              {type === TimedRideStepType.Pickup ? (
                <FormattedMessage id="ride_details.estimated_pu_time" />
              ) : (
                displayDropoffEstimatedLabel(type)
              )}
            </Typography>
          </Typography>
        ) : null}

        {stepName ? (
          <Typography
            className={`${classes.nodeNameAndAddress} ${classes.nodeName}`}
          >
            {stepName}
          </Typography>
        ) : null}

        {stepAddress && stepAddress !== stepName ? (
          <Typography
            className={classes.nodeNameAndAddress}
            component="address"
          >
            {stepAddress}
          </Typography>
        ) : null}
      </div>
    </li>
  );
}
