import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useImpersonateViewStyles = makeStyles((theme: CustomTheme) => ({
  impersonateHelpText: {
    flexDirection: "column",

    border: "1px solid transparent",
    borderColor: theme.palette.info.main,

    "& .MuiAlert-icon": {
      justifyContent: "center",

      fontSize: "2rem",
    },
  },
  impersonateViewContainer: {
    margin: "32px auto",
    padding: "0.5rem",
  },
  surveyIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "& .MuiSvgIcon-root": {
      margin: "0 0 1.5rem 0",

      fontSize: "4rem",
    },
  },
  surveyInfoContainer: {
    padding: "2rem",
  },
  surveyInfoLink: {
    display: "flex",
    alignItems: "center",

    color: theme.palette.info.main,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: 500,

    "& .MuiSvgIcon-root": {
      transform: "translate(0.5rem, -0.125rem)",
    },
  },
  surveyInfoMessage: {
    margin: "1rem 0",
  },
}));
