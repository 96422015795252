import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Purse(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 50 50">
      <g transform="translate(0 3)">
        <path
          d="m39.419 7.2018c1.9787 1.8753 3.2589 3.841 3.8406 5.8971 0.58167 2.0561 0.58167 4.6156 0 7.6786"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          d="m43.999 5.9961c1.9787 1.8753 3.2589 3.841 3.8406 5.8971 0.58167 2.0561 0.58167 4.6156 0 7.6786"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <path
          transform="translate(22 21.998) rotate(-15) translate(-22 -21.998)"
          d="m40 5.996c-0.0012862-0.32331-0.1588-0.62605-0.42283-0.81265-0.26402-0.1866-0.60198-0.23405-0.90717-0.12735l-16.84 5.94h-9.83c-1.8192 0.0059072-3.4052 1.2386-3.86 3h-1.14c-1.6569 0-3 1.3431-3 3v2c0 1.6569 1.3431 3 3 3h1c0 2.2091 1.7909 4 4 4h0.07l0.86 11.15c0.078637 1.0456 0.95145 1.853 2 1.85h4.07c1.1046 0 2-0.89544 2-2v-11h0.83l16.84 5.94c0.10584 0.038436 0.21741 0.058722 0.33 0.06 0.55228 0 1-0.44772 1-1v-25zm-33 14c-0.55228 0-1-0.44772-1-1v-2c0-0.55228 0.44772-1 1-1h1v4h-1zm3 2v-7c0-1.1046 0.89543-2 2-2h9v11h-9c-1.1046 0-2-0.89543-2-2zm9 6h-2v2h2v2h-2v2h2v3h-4.07l-0.85-11h4.92v2zm19 1.59-15-5.3v-11.58l15-5.3v22.18z"
        />
      </g>
    </SvgIcon>
  );
}

export default Purse;
