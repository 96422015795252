import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useTimedNodeStyles = makeStyles((theme: CustomTheme) => ({
  bullet: {
    display: "inline-block",

    width: "0.5rem",
    minWidth: "0.5rem",
    height: "0.5rem",
    margin: "0.25rem 1rem",
    borderRadius: "50%",

    background: theme.palette.pickup.main,
  },
  busIcon: {
    width: "1.5rem",
    height: "1.5rem",
    margin: "0 0.75rem",

    background: "white",
  },
  dropOffBullet: {
    background: theme.palette.dropoff.main,
  },
  dropoffColors: {
    borderColor: theme.palette.dropoff.main,

    fill: theme.palette.dropoff.main,

    "& circle": {
      fill: theme.palette.dropoff.main,
    },
  },
  pickupColors: {
    borderColor: theme.palette.pickup.main,

    fill: theme.palette.pickup.main,

    "& circle": {
      fill: theme.palette.pickup.main,
    },
  },
  time: {
    fontWeight: 500,
  },
  timedNodeContainer: {
    display: "flex",
    alignItems: "center",

    margin: "0.25rem 0",
    width: "100%",
  },
  nodeNameLabelWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    gap: "2ch",
  },
  nodeName: {
    display: "-webkit-box",

    overflow: "hidden",

    textAlign: "left",
    textTransform: "none",
    textOverflow: "ellipsis",
    lineClamp: 2,
    boxOrient: "vertical",
    flex: 1,
  },
  differentNode: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  nodeLabel: {
    color: theme.palette.text.secondary,
    fontSize: "1rem",
    flex: 0.8,
  },
}));
