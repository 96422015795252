import { ResourceType } from "./types/resourceType";
import { booking } from "./booking";
import { auth } from "./auth";
import { common } from "./common";
import { contact } from "./contact";
import { customer } from "./customer";
import { discount } from "./discount";
import { operator } from "./operator";
import { payment } from "./payment";
import { search } from "./search";
import { waitingList } from "./waitingList";

export const apiResources: ResourceType = {
  ...auth,
  ...booking,
  ...common,
  ...contact,
  ...customer,
  ...discount,
  ...operator,
  ...payment,
  ...search,
  ...waitingList,
};
