import api from "api";
import { debounce } from "lodash";
import { googleAutocomplete } from "lib/autocomplete/googleGeoCode";
import { padamAutocomplete } from "lib/autocomplete/padamGeoCode";

export const INITIATE_GEOCODES_REQUEST =
  "autocomplete @@ INITIATE_GEOCODES_REQUEST";
export const RECEIVE_GEOCODES = "autocomplete @@ RECEIVE_GEOCODES";
export const GEOCODES_REQUEST_ERROR = "autocomplete @@  GEOCODES_REQUEST_ERROR";

export const INITIATE_NODES_REQUEST = "autocomplete @@ INITIATE_NODES_REQUEST";
export const RECEIVE_NODES = "autocomplete @@ RECEIVE_NODES";
export const NODES_REQUEST_ERROR = "autocomplete @@  NODES_REQUEST_ERROR";

export const INITIATE_ADDRESSES_REQUEST =
  "autocomplete @@ INITIATE_ADDRESSES_REQUEST";
export const RECEIVE_ADDRESSES = "autocomplete @@ RECEIVE_ADDRESSES";
export const ADDRESSES_REQUEST_ERROR =
  "autocomplete @@ ADDRESSES_REQUEST_ERROR";

export const INITIATE_USERS_REQUEST = "autocomplete @@ INITIATE_USERS_REQUEST";
export const RECEIVE_USERS = "autocomplete @@ RECEIVE_USERS";
export const USERS_REQUEST_ERROR = "autocomplete @@ USERS_REQUEST_ERROR";

export const ARROW_UP = "autocomplete @@ ARROW_UP";
export const ARROW_DOWN = "autocomplete @@ ARROW_DOWN";

export const RESET_AUTOCOMPLETE = "autocomplete @@ RESET";
export const IS_REQUESTING = "autocomplete @@ IS_REQUESTING";
export const REQUESTS_RECEIVED = "autocomplete @@ REQUESTS_RECEIVED";

export const contextToData = {
  search: ["nodes", "geocodes"],
  favorite: ["geocodes"],
  impersonate: ["users"],
};

export const formatAddress = (p) => ({
  suggestion: p.suggestion,
  placeId: null,
  nodeId: null,
  latitude: p.geoloc.lat,
  longitude: p.geoloc.lng,
  address: `${p.suggestionParts.mainText}, ${p.suggestionParts.secondaryText}`,
});

export const formatNode = (p) => ({
  suggestion: p.name,
  placeId: null,
  nodeId: p.id,
  latitude: p.position.latitude,
  longitude: p.position.longitude,
  address: p.position.address,
});

// see format in lib/googleGeoCode
export const formatGeocode = (p) => ({
  suggestion: p.address,
  placeId: p.place_id,
  nodeId: null,
  latitude: p.latitude,
  longitude: p.longitude,
  address: p.address,
});

const typeToFormat = {
  geocodes: formatGeocode,
  nodes: formatNode,
  users: (a) => a,
};

export const enter = (context, onSelect) => (dispatch, getState) => {
  // DYNAMIC DATA ORDER (enter needs context from Autocomplete)
  // guessing what is highlighted index element in order to select it
  const state = getState();
  const i = state.autocomplete.highlightedIndex;

  if (i < 0) {
    // If index is negative, no item is selected yet -> nothing to do
    return;
  }

  // uess which type of data to select depending on i and data order
  let arrOfLength = _.map(
    contextToData[context],
    (dataType) => _.get(state.autocomplete, dataType, []).length,
  );
  // do cumulated sum for index boundaries
  for (var j = 1; j < arrOfLength.length; j++) {
    arrOfLength[j] = arrOfLength[j] + arrOfLength[j - 1];
  }
  let c = 0;
  while (c < contextToData[context].length && i >= arrOfLength[c]) {
    c++;
  }
  const highlightedType = contextToData[context][c];
  const relativeIndex = c === 0 ? i : i - arrOfLength[c - 1];

  onSelect(
    typeToFormat[highlightedType](
      state.autocomplete[highlightedType][relativeIndex],
    ),
  );
};

// we want max 1 geocode for now (UX). It uses the 2 fonctions above
export const fetchGeocodes =
  (value, cb, selectedTerritory, productParameters) => (dispatch, getState) => {
    //make sure we don't run google geocoding requests for nothing
    if (!value || value.length < 3) {
      return;
    }
    const state = getState();

    const lat = selectedTerritory?.geography?.default_latitude;
    const lng = selectedTerritory?.geography?.default_longitude;

    const cached_value = _.find(state.autocomplete.geocodeCache, {
      key: value,
    });
    if (cached_value) {
      // if value is in cache, put it back at the last place
      // as it now is the most recent item which has been accessed
      dispatch({
        type: RECEIVE_GEOCODES,
        key: value,
        geocodes: cached_value.geocodes,
      });
      cb();
      return;
    }
    if (!selectedTerritory?.territory_key) return;

    debouncedAutocompleteGeocodes(
      dispatch,
      getState,
      value,
      cb,
      lat,
      lng,
      selectedTerritory.territory_key,
      productParameters,
    );

    return;
  };

const debouncedAutocompleteGeocodes = debounce(
  (dispatch, getState, value, cb, lat, lng, territory, productParameters) => {
    dispatch({ type: INITIATE_GEOCODES_REQUEST, value });
    // this lib returns max 1
    const trimmedSearchString = value.trim();
    if (!trimmedSearchString) {
      dispatch({
        type: RECEIVE_GEOCODES,
        key: trimmedSearchString,
        geocodes: [],
      });
    }

    // ENABLE_PADAM_GEOCODING is defined as a global/window constant
    let autocompletePromise;
    if (productParameters?.features?.is_padam_geocoding_enabled) {
      autocompletePromise = padamAutocomplete(trimmedSearchString, territory);
    } else {
      const radius = productParameters?.search_radius;
      autocompletePromise = googleAutocomplete(
        trimmedSearchString,
        lat,
        lng,
        radius,
      );
    }
    autocompletePromise
      .then((geocodes) => {
        // we use the data and functions above to decide if the geocode is not far from territory
        // we use the algolia radius below as distance reference
        if (geocodes) {
          dispatch({
            type: RECEIVE_GEOCODES,
            key: trimmedSearchString,
            geocodes: geocodes,
          });
        } else {
          dispatch({
            type: RECEIVE_GEOCODES,
            key: trimmedSearchString,
            geocodes: [],
          });
        }
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: GEOCODES_REQUEST_ERROR });
      })
      .finally(() => cb());
  },
  1000,
);

const debouncedFetchNodes = debounce(
  (dispatch, value, cb, selectedTerritory) => {
    dispatch({ type: INITIATE_NODES_REQUEST });
    api
      .getNodeList(null, {
        search: value,
        territory: selectedTerritory.territory_key,
      })
      .then((json) => {
        dispatch({ type: RECEIVE_NODES, nodes: json.results });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: NODES_REQUEST_ERROR });
      })
      .finally(() => cb());
    return;
  },
  1000,
);
const fetchNodes = (value, cb, selectedTerritory) => (dispatch) =>
  debouncedFetchNodes(dispatch, value, cb, selectedTerritory);

const debouncedFetchUsers = debounce(
  (dispatch, value, cb, selectedTerritory) => {
    dispatch({ type: INITIATE_USERS_REQUEST });
    // don't use pagination, here 10 is enough
    api
      .getCustomerList(null, {
        limit: 10,
        offset: 0,
        search: encodeURIComponent(value.trim()),
        territory: selectedTerritory.territory_key,
      })
      .then((json) => {
        dispatch({ type: RECEIVE_USERS, users: json.results });
      })
      .catch((error) => {
        console.error(error);
        dispatch({ type: USERS_REQUEST_ERROR });
      })
      .finally(() => cb());
    return;
  },
  500,
);
const fetchUsers = (value, cb, selectedTerritory) => (dispatch) =>
  debouncedFetchUsers(dispatch, value, cb, selectedTerritory);

const typeToFetch = {
  geocodes: fetchGeocodes,
  nodes: fetchNodes,
  users: fetchUsers,
};

export const onInputChange =
  (newText, context, selectedTerritory, productParameters) => (dispatch) => {
    dispatch({ type: IS_REQUESTING });
    const dataToUpdate = contextToData[context];
    // we launch all fetches and want to know when all is done
    Promise.all(
      dataToUpdate.map(
        (dataType) =>
          new Promise((cb) => {
            // debounce to have less api calls
            dispatch(
              typeToFetch[dataType](
                newText,
                cb,
                selectedTerritory,
                productParameters,
              ),
            );
          }),
      ),
    ).finally(() => {
      dispatch({ type: REQUESTS_RECEIVED });
    });
  };
