import * as React from "react";
import { FormattedMessage } from "react-intl";
import { AlertTriangle } from "lucide-react";

import { TimedNode } from "molecules/TimedNode/TimedNode";
import { Alert, AlertTitle } from "components/ui/alert";

type TimeRideProps = {
  dropoffNodeName: string;
  dropoffTime?: string;
  pickupNodeName: string;
  pickupTime?: string;
  withBusIcon?: boolean;
  isLabelTop?: boolean;
  hasDifferentNodes?: { pickup: boolean; dropoff: boolean };
};

export function TimedRide(props: TimeRideProps) {
  const {
    dropoffNodeName,
    dropoffTime,
    pickupNodeName,
    pickupTime,
    withBusIcon,
    isLabelTop = false,
    hasDifferentNodes = { pickup: false, dropoff: false },
  } = props;

  return (
    <div className="fullWidth mr-4">
      {(hasDifferentNodes.pickup || hasDifferentNodes.dropoff) && (
        <Alert variant="warning" className="mb-4">
          <AlertTitle className="flex items-center gap-2">
            <AlertTriangle />
            <FormattedMessage id="TEXT_INFO_MODIFIED_NODE" />
          </AlertTitle>
        </Alert>
      )}
      <TimedNode
        nodeName={pickupNodeName}
        time={pickupTime}
        withBusIcon={withBusIcon}
        isLabelTop={isLabelTop}
        isDifferentNode={hasDifferentNodes.pickup}
      />

      <TimedNode
        isDropoffNode
        nodeName={dropoffNodeName}
        time={dropoffTime}
        withBusIcon={withBusIcon}
        isLabelTop={isLabelTop}
        isDifferentNode={hasDifferentNodes.dropoff}
      />
    </div>
  );
}
