import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { Typography, Paper } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";

import SSOSignInForm from "containers/SSOSignInForm/SSOSignInForm.jsx";
import { isOpenidActivated } from "containers/SSOSignInForm/ssoUserManager.js";

import { ProductContext } from "contexts/ProductContext";

import useWindowWidth from "lib/useWindowWidth";

export default function SSOSignInView() {
  const intl = useIntl();
  const theme = useTheme();
  const navigate = useNavigate();
  const { productParameters } = useContext(ProductContext);

  const disabledBookingSite =
    (typeof DISABLED_LOGIN !== "undefined" && DISABLED_LOGIN) ||
    !(productParameters?.features?.booking_website?.enabled || true);

  const width = useWindowWidth();

  if (disabledBookingSite) {
    return (
      <div className="row-only centered aligned" style={{ height: "100%" }}>
        <h1 className="visuallyHidden">
          {intl.formatMessage({ id: "signin.title" })}
        </h1>

        <Paper className="column centered aligned padded">
          <div className="logoBox" style={{ height: "200px" }}>
            <img
              className="logo"
              src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
                DEFAULT_TERRITORY || PRODUCT
              }_color.png`}
              alt="signin disabled"
            />
          </div>
          <Typography className="padded">
            {intl.formatMessage({ id: "signin.disabled" })}
          </Typography>
        </Paper>
      </div>
    );
  }

  if (!isOpenidActivated) {
    navigate("/login");
  }

  return (
    <div
      className="column"
      style={{
        width: "100%",
        height: "100%",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "signin.title" })}
      </h1>

      <SSOSignInForm />

      {
        //this is only a visual stuff
        width > 800 && (
          <div
            style={{
              width: "40rem",
              height: "15rem",
              position: "absolute",
              top: "55%",
              backgroundColor: theme.palette.primary.light,
            }}
          />
        )
      }
    </div>
  );
}
