import React, { useCallback, useContext } from "react";
import { connect } from "react-redux";

import { FavoriteForm } from "components/FavoriteForm/FavoriteForm";

import { TerritoryContext } from "contexts/TerritoryContext";

import { onFieldChange, onAddressSelected, saveFavorites } from "./actions.js";

function FavoriteFormContainer(props) {
  const { selectedTerritory } = useContext(TerritoryContext);

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      props.saveFavorites(
        props.pickup,
        props.dropoff,
        selectedTerritory.territory_key,
      );
    },
    [props, selectedTerritory],
  );

  return <FavoriteForm {...props} onSubmit={onSubmit} />;
}

const _mapState = (state) => ({
  territory: state.user.territory,
  pickup: state.favoriteForm.pickup,
  dropoff: state.favoriteForm.dropoff,
  errors: state.favoriteForm.errors,
  isRequesting: state.favoriteForm.isRequesting,
});

const _mapDispatch = (dispatch) => ({
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  onAddressSelected: (address, placeId, type) => {
    dispatch(onAddressSelected(address, placeId, type));
  },
  saveFavorites: (pickup, dropoff, selectedTerritoryKey) => {
    dispatch(saveFavorites(pickup, dropoff, selectedTerritoryKey));
  },
});

export default connect(_mapState, _mapDispatch)(FavoriteFormContainer);
