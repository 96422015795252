import React from "react";

function Credits(props) {
  return (
    <svg
      {...props}
      style={{ ...props.style }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 148 60"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#293154">
          <g>
            <path
              d="M30 2.727c15.062 0 27.273 12.21 27.273 27.273 0 15.062-12.21 27.273-27.273 27.273-15.062 0-27.273-12.21-27.273-27.273C2.727 14.938 14.937 2.727 30 2.727zm0 2.728C16.444 5.455 5.455 16.444 5.455 30c0 13.556 10.989 24.545 24.545 24.545 13.556 0 24.545-10.989 24.545-24.545C54.545 16.444 43.556 5.455 30 5.455zm0 2.727c12.05 0 21.818 9.768 21.818 21.818S42.05 51.818 30 51.818 8.182 42.05 8.182 30 17.95 8.182 30 8.182zm0 1.363C18.703 9.545 9.545 18.703 9.545 30c0 11.297 9.158 20.455 20.455 20.455 11.297 0 20.455-9.158 20.455-20.455 0-11.297-9.158-20.455-20.455-20.455zm0 9.546c.64 0 1.228.368 1.528.957l2.462 5.2 5.506.829c.621.12 1.13.584 1.327 1.21.197.625.05 1.312-.381 1.792l-3.984 4.048.94 5.71c.074.514-.067 1.036-.388 1.432-.322.397-.792.63-1.29.64-.277 0-.55-.07-.796-.204L30 38.009l-4.924 2.696c-.575.293-1.258.232-1.776-.16-.518-.39-.786-1.048-.697-1.708l.94-5.71-3.985-4.048c-.432-.48-.578-1.167-.38-1.793.196-.625.705-1.089 1.326-1.21l5.506-.828 2.462-5.2c.3-.589.888-.957 1.528-.957zm0 1.183c-.2 0-.385.108-.49.284l-2.6 5.477c-.084.177-.245.3-.433.33l-5.806.877c-.208.03-.381.181-.444.39-.076.211-.02.449.138.6l4.204 4.264c.135.14.195.34.161.535l-.992 6.005c-.016.21.074.413.238.535.164.123.378.148.564.065l5.189-2.84c.083-.047.177-.07.271-.066v-.024c.093 0 .184.024.265.072l5.19 2.84c.185.083.399.058.563-.065.164-.122.254-.326.238-.535l-.992-6.005c-.034-.195.026-.395.162-.535l4.203-4.263c.16-.152.214-.39.138-.6-.063-.21-.236-.362-.444-.391l-5.8-.853c-.188-.03-.35-.153-.433-.33l-2.6-5.483c-.105-.176-.29-.284-.49-.284z"
              transform="translate(0.000000, 0.000000) translate(44.000000, 0.000000)"
            />
          </g>
          <g>
            <path
              d="M22 2c11.046 0 20 8.954 20 20s-8.954 20-20 20S2 33.046 2 22 10.954 2 22 2zm0 2C12.059 4 4 12.059 4 22s8.059 18 18 18 18-8.059 18-18S31.941 4 22 4zm0 2c8.837 0 16 7.163 16 16s-7.163 16-16 16S6 30.837 6 22 13.163 6 22 6zm0 1C13.716 7 7 13.716 7 22c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 7c.47 0 .9.27 1.12.702l1.806 3.813 4.038.608c.455.088.828.429.973.887.144.459.037.963-.28 1.315l-2.921 2.968.689 4.188c.055.376-.049.759-.285 1.05-.235.29-.58.462-.946.469-.203 0-.403-.052-.583-.15L22 27.873l-3.61 1.977c-.422.215-.923.17-1.303-.117-.38-.287-.577-.768-.512-1.252l.69-4.188-2.922-2.968c-.317-.352-.424-.856-.28-1.315.145-.458.518-.799.973-.887l4.038-.608 1.806-3.813C21.1 14.27 21.53 14 22 14zm0 .868c-.146 0-.282.079-.36.208l-1.906 4.016c-.061.13-.18.22-.317.242l-4.258.643c-.153.022-.28.134-.326.287-.055.154-.015.328.102.44l3.082 3.127c.1.102.143.248.118.391l-.727 4.404c-.012.154.054.303.175.393.12.09.277.108.413.047l3.805-2.082c.061-.035.13-.051.199-.049v-.018c.068 0 .135.018.194.053L26 29.053c.136.06.293.043.413-.047s.187-.24.175-.393l-.728-4.404c-.025-.143.02-.29.119-.392l3.082-3.126c.117-.112.157-.286.102-.44-.046-.154-.173-.265-.326-.287l-4.254-.625c-.137-.022-.256-.112-.317-.242l-1.907-4.02c-.077-.13-.213-.21-.359-.21z"
              transform="translate(0.000000, 0.000000) translate(0.000000, 16.000000)"
            />
          </g>
          <g>
            <path
              d="M22 2c11.046 0 20 8.954 20 20s-8.954 20-20 20S2 33.046 2 22 10.954 2 22 2zm0 2C12.059 4 4 12.059 4 22s8.059 18 18 18 18-8.059 18-18S31.941 4 22 4zm0 2c8.837 0 16 7.163 16 16s-7.163 16-16 16S6 30.837 6 22 13.163 6 22 6zm0 1C13.716 7 7 13.716 7 22c0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15zm0 7c.47 0 .9.27 1.12.702l1.806 3.813 4.038.608c.455.088.828.429.973.887.144.459.037.963-.28 1.315l-2.921 2.968.689 4.188c.055.376-.049.759-.285 1.05-.235.29-.58.462-.946.469-.203 0-.403-.052-.583-.15L22 27.873l-3.61 1.977c-.422.215-.923.17-1.303-.117-.38-.287-.577-.768-.512-1.252l.69-4.188-2.922-2.968c-.317-.352-.424-.856-.28-1.315.145-.458.518-.799.973-.887l4.038-.608 1.806-3.813C21.1 14.27 21.53 14 22 14zm0 .868c-.146 0-.282.079-.36.208l-1.906 4.016c-.061.13-.18.22-.317.242l-4.258.643c-.153.022-.28.134-.326.287-.055.154-.015.328.102.44l3.082 3.127c.1.102.143.248.118.391l-.727 4.404c-.012.154.054.303.175.393.12.09.277.108.413.047l3.805-2.082c.061-.035.13-.051.199-.049v-.018c.068 0 .135.018.194.053L26 29.053c.136.06.293.043.413-.047s.187-.24.175-.393l-.728-4.404c-.025-.143.02-.29.119-.392l3.082-3.126c.117-.112.157-.286.102-.44-.046-.154-.173-.265-.326-.287l-4.254-.625c-.137-.022-.256-.112-.317-.242l-1.907-4.02c-.077-.13-.213-.21-.359-.21z"
              transform="translate(0.000000, 0.000000) translate(104.000000, 16.000000)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Credits;
