import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import ControlledReactStars from "./ControlledReactStars";

export default function RateRideDialog(props) {
  const { onClose, onRate, isRateDriverEnabled, isRateRideEnabled } = props;

  const intl = useIntl();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = useCallback(
    ({ driverRating, rideRating, comment }) => {
      onRate(driverRating, rideRating, comment);
      onClose();
    },
    [onClose, onRate],
  );

  return (
    <Dialog open onClose={onClose} aria-labelledby="rateRideDialogTitle">
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>
          <Typography variant="h2" id="rateRideDialogTitle">
            <FormattedMessage id="reservation.rate.subtitle" />
          </Typography>
        </DialogTitle>
        {
          /* RATE DRIVER if allowed */
          isRateDriverEnabled && (
            <DialogContent>
              <div
                className="row-only aligned"
                style={{ justifyContent: "space-between" }}
              >
                <Typography>
                  {intl.formatMessage({ id: "reservation.rate.driver" }) + " *"}
                </Typography>
                <ControlledReactStars
                  control={control}
                  name="driverRating"
                  rules={{ required: true }}
                  count={5}
                  size={24}
                  color2="#ffd700"
                  half={false}
                />
              </div>
              {
                /* ERROR TREATMENT */
                errors.driverRating && (
                  <Typography variant="body2" color="error">
                    <FormattedMessage id="reservation.rate.error" />
                  </Typography>
                )
              }
            </DialogContent>
          )
        }

        {
          /* RATE RIDE if allowed */
          isRateRideEnabled && (
            <DialogContent>
              <div
                className="row-only aligned"
                style={{ justifyContent: "space-between" }}
              >
                <Typography>
                  {intl.formatMessage({ id: "reservation.rate.ride" }) + " *"}
                </Typography>
                <ControlledReactStars
                  control={control}
                  name="rideRating"
                  rules={{ required: true }}
                  count={5}
                  size={24}
                  color2="#ffd700"
                  half={false}
                />
              </div>
              {
                /* ERROR TREATMENT */
                errors.rideRating && (
                  <Typography variant="body2" color="error">
                    <FormattedMessage id="reservation.rate.error" />
                  </Typography>
                )
              }
            </DialogContent>
          )
        }

        {/* COMMENT input */}
        <DialogContent>
          <TextField
            {...register("comment", { maxLength: 500 })}
            id="rate-comment"
            autoComplete="off"
            label={<FormattedMessage id="reservation.rate.comment" />}
            rows={2}
            multiline
            variant="outlined"
            fullWidth
          />
          {errors.comment && (
            <Typography variant="body2" color="error">
              <FormattedMessage id="reservation.rate.comment.maxLength" />
            </Typography>
          )}
        </DialogContent>

        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button onClick={onClose} variant="outlined" color="primary">
            <FormattedMessage id="misc.cancel" />
          </Button>
          <Button type="submit" variant="contained" color="primary">
            <FormattedMessage id="misc.validate" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
