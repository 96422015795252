import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useSearchResultGridStyles = makeStyles((theme: CustomTheme) => ({
  alternativesButton: {
    display: "flex",
    justifyContent: "flex-end",

    width: "100%",
  },
  errorMessage: {
    width: "100%",

    textAlign: "center",
  },
  loaderContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",

    padding: "1rem",

    textAlign: "center",
  },
  noRideCard: {
    width: "100%",
  },
  resultGridWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",

    width: "100%",

    [theme.breakpoints.up("md")]: {
      gap: "1rem",
    },
  },
}));
