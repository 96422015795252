import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, combineReducers, compose } from "redux";
import * as Sentry from "@sentry/react";

import persistState from "redux-localstorage";

import { getIntl } from "./../i18n.js";
import { autocomplete } from "containers/Autocomplete/reducer.js";
import { signin } from "containers/SignInForm/reducer.js";
import { forgetPassword } from "containers/ForgetPassword/reducer.js";
import { signup } from "containers/SignUpForm/reducer.js";
import { search } from "containers/SearchForm/reducer.js";
import { favoriteForm } from "containers/FavoriteForm/reducer.js";
import { impersonateForm } from "containers/ImpersonateForm/reducer.js";
import { bookingValidation } from "containers/BookingValidation/reducer.js";
import { reservationList } from "containers/ReservationList/reducer.js";
import { reservation } from "containers/Reservation/reducer.ts";
import { reservationForm } from "containers/ReservationForm/reducer.js";
import { rideDetails } from "containers/RideDetails/reducer.js";
import { rateRide } from "containers/CancelRide/reducer.js";
import { credit } from "containers/CreditManager/reducer.js";
import { payment } from "containers/Payment/reducer.js";
import { user } from "./reducers/user.js";
import { snackBar } from "containers/SnackBar/reducer.js";
import { passengersProfiles } from "containers/PassengersProfiles/reducer.ts";

/**
 * App level reducers
 */
const appReducer = combineReducers({
  autocomplete,
  bookingValidation,
  passengersProfiles,
  credit,
  favoriteForm,
  forgetPassword,
  impersonateForm,
  payment,
  rateRide,
  reservation,
  reservationForm,
  reservationList,
  rideDetails,
  search,
  signin,
  signup,
  snackBar,
  user,
});

/**
 * Root reducer of the app. Listens for LOGOUT
 * action type to reset our states.
 */
const rootReducer = (state, action) => appReducer(state, action);

/**
 * Persistent state paths
 * add path if it needs to be persistent in Redux
 */
const persistentPaths = [
  // 'territory',
  // 'search',
  // 'impersonateForm'
];
// to push redux infos in error tracking
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  stateTransformer: (state) => {
    // used to hide sensitive info like passwords
    const territory = _.get(state, "territory", {});

    const filteredUser = {
      id: _.get(state, "user.user.id"),
      groups: _.get(state, "user.groups"),
      customFields: _.get(state, "user.user.custom_fields"),
      territoryKey: _.get(state, "user.user.territory_key"),
    };
    return {
      territory,
      filteredUser,
    };
  },
});

/**
 * Create the store
 */
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;
const store = createStore(
  rootReducer,
  composeEnhancers(
    persistState(persistentPaths),
    applyMiddleware(thunkMiddleware.withExtraArgument(getIntl)),
    sentryReduxEnhancer,
  ),
);

// for Cypress automated tests
if (window.Cypress) window.__store__ = store;

export default store;
