import dayjs from "dayjs";
import api from "api";
import {
  addProposal,
  initiateRequest,
  newRideResponse,
  noRideResponse,
} from "containers/SearchForm/actions.js";
import { snackMessage } from "containers/SnackBar/actions.js";
import { reservationFetched } from "containers/Reservation/actions";
import { getPassengersBookedProfilesIds } from "containers/PassengersProfiles/actions";

export const ON_FIELD_CHANGE = "@@edition/ON_FIELD_CHANGE";
export const BOOKING_SEATS_EDITION_REQUEST_ERROR =
  "@@edition / BOOKING_SEATS_EDITION_REQUEST_ERROR";
export const BOOKING_SEATS_EDITION_REQUEST_SUCCESS =
  "@@edition / BOOKING_SEATS_EDITION_REQUEST_SUCCESS";

export const changeBookingValidate =
  (navigate, bookingId, responses, selectedProposals, selectedTerritoryKey) =>
  (dispatch, getState, getIntl) => {
    const datetime = Object.keys(selectedProposals)[0];
    const intl = getIntl();

    const state = getState();
    let impersonate = "CHANGED_BY_USER";
    // for now, the Referent Impersonate is labelled by what's above
    if (
      state.impersonateForm.sessionStarted &&
      !state.impersonateForm.impersonateByReferent
    )
      impersonate = "CHANGED_BY_CALL_CENTER";
    const payload = {
      reservationId: bookingId,
      reservation_history_id: responses[datetime].reservation_info.id,
      customer_proposition_id: selectedProposals[datetime],
      changed_by: impersonate,
    };
    api
      .changeBookingValidate(payload, { territory: selectedTerritoryKey })
      .then(() => {
        window.scrollTo(0, 0);
        navigate("/reservation");

        dispatch(
          snackMessage(
            "success",
            intl.formatMessage({ id: "reservation.edited" }),
          ),
        );
      })
      .catch(() => {
        dispatch(
          snackMessage(
            "error",
            intl.formatMessage({ id: "misc.unexpected_error" }),
            "error on api: change-booking-validate \n with payload \n" +
              JSON.stringify(payload),
          ),
        );
      });
  };

export const changeBookingSearch =
  (payload, selectedTerritoryKey) => (dispatch) => {
    const datetime = dayjs(payload.time).format();
    dispatch(initiateRequest());
    api
      .changeBookingSearch(
        {
          reservationId: payload.reservationId,
          datetime: datetime,
        },
        { territory: selectedTerritoryKey },
      )
      .then((json) => {
        /**
         * Create an object that matches the shape of the
         * search api responses and inject booking details
         */
        const response = {
          reservation_info: { ...json },
        };

        // if (!proposalsAreAcceptable(response, datetime)) {
        // 	return dispatch(noRideResponse(datetime));
        // }

        dispatch(newRideResponse(response, datetime));
        dispatch(
          addProposal(
            datetime,
            response.reservation_info.proposed_datetimes[0].id,
          ),
        );
      })
      .catch((error) => {
        console.log({ error });
        dispatch(noRideResponse(datetime, error.infos.detail.message));
      });
  };

/**
 * Prepare the payload to send to the API
 * @param {Object}
 * @returns {{toAdd: {}, toRemove: *[]}}
 */
function preparePayloadPassengerProfile({
  NewPassengersProfilesState,
  OldPassengerProfilesState,
}) {
  const prepare = {
    toAdd: {},
    toRemove: [],
  };

  for (const [key, passengerProfile] of Object.entries(
    NewPassengersProfilesState,
  )) {
    if (!(key in OldPassengerProfilesState)) {
      prepare.toAdd[key] = passengerProfile;
      continue;
    }

    if (OldPassengerProfilesState[key].ids.length > passengerProfile.count) {
      prepare.toRemove = prepare.toRemove.concat(
        OldPassengerProfilesState[key].ids.slice(
          null,
          OldPassengerProfilesState[key].ids.length - passengerProfile.count,
        ),
      );
    } else {
      passengerProfile.count -= OldPassengerProfilesState[key]
        ? OldPassengerProfilesState[key].ids.length
        : 0;
      prepare.toAdd[key] = passengerProfile;
    }
  }

  return prepare;
}

export const changeBookingSeats =
  ({
    reservationId,
    passengerNumber,
    onSuccess = () => null,
    selectedTerritoryKey,
    passengersProfiles,
    bookedProfilesIds,
  }) =>
  (dispatch, getState) => {
    let requested_extra_seats = {};
    Object.keys(passengerNumber).forEach((type) => {
      if (type !== "standard") {
        requested_extra_seats["extras_" + type] = {
          ["requested_" + type + "_seats"]: passengerNumber[type],
        };
      }
    });

    const state = getState();
    const bookingId = state?.reservation?.reservation?.id;

    const newPassengersProfiles = {};
    for (const [key, passengerProfile] of Object.entries(passengersProfiles)) {
      newPassengersProfiles[key] = {
        id: passengerProfile.id,
        count: passengerProfile.count,
      };
    }
    const prepare = preparePayloadPassengerProfile({
      NewPassengersProfilesState: newPassengersProfiles,
      OldPassengerProfilesState: bookedProfilesIds,
    });

    const payload = {
      requested_standard_seats: passengerNumber.standard,
      ...requested_extra_seats,
    };

    api
      .changeBookingSeats(
        {
          ...payload,
          reservationId: state?.reservation?.reservation?.id,
        },
        { territory: selectedTerritoryKey },
      )
      .then((json) => {
        const request = [];
        Object.entries(prepare.toAdd).forEach(([_, profile]) => {
          for (let i = 0; i < profile?.count; i++) {
            let payloadPassenger = {
              customerId: state?.user?.user?.customer_id,
              bookingId: bookingId,
              passenger_profile: profile.id,
            };
            request.push(api.addPassengerToBooking(payloadPassenger));
          }
        });
        prepare.toRemove.forEach((profileId) => {
          let payloadPassenger = {
            customerId: state?.user?.user?.customer_id,
            bookingId: bookingId,
            profileId: profileId,
          };
          request.push(api.removePassengerFromBooking(payloadPassenger));
        });
        Promise.all(request).then(() =>
          dispatch(getPassengersBookedProfilesIds()),
        );
        dispatch({ type: BOOKING_SEATS_EDITION_REQUEST_SUCCESS });
        dispatch(snackMessage("success", json?.message));
        let reservation_data = json?.reservation;
        if (reservation_data) {
          reservation_data.personalItems =
            state?.reservation?.reservation?.personalItems;
          dispatch(reservationFetched(reservation_data));
        }
        // execute callback
        onSuccess();
      })
      .catch((error) => {
        dispatch({
          type: BOOKING_SEATS_EDITION_REQUEST_ERROR,
          error: _.get(error, "infos.detail"),
        });
        dispatch(
          snackMessage(
            "error",
            _.get(error, "infos.detail.message"),
            "error on api:\n change-booking-seats \n with payload \n" +
              JSON.stringify({
                ...payload,
                reservationId,
              }),
          ),
        );
      });
  };

/**
 * Action triggered when a user changes a field form (edition)
 *`
 * @param  string field
 * @param  mixed value
 * @return
 */
export const onFieldChange = (field, value) => ({
  type: ON_FIELD_CHANGE,
  field,
  value,
});
