// Opera 8.0+
export const isOpera =
  (Boolean(window.opr) && Boolean(opr.addons)) ||
  Boolean(window.opera) ||
  navigator.userAgent.indexOf(" OPR/") >= 0;

// Firefox 1.0+
export const isFirefox = typeof InstallTrigger !== "undefined";

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === "[object SafariRemoteNotification]";
  })(
    !window.safari ||
      (typeof safari !== "undefined" && safari.pushNotification),
  );

// Internet Explorer 6-11
export const isIE = /* @cc_on!@ */ false || Boolean(document.documentMode);

// Edge 20+
export const isEdge = !isIE && Boolean(window.StyleMedia);

// Chrome 1 - 71
export const isChrome =
  Boolean(window.chrome) &&
  (Boolean(window.chrome.webstore) || Boolean(window.chrome.runtime));

// Blink engine detection
export const isBlink = (isChrome || isOpera) && Boolean(window.CSS);
