import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl-phraseapp";

import {
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import OnBoard from "components/Icons/OnBoard";
import { TerritoryContext } from "contexts/TerritoryContext";

export default function OnBoardPaymentProvider(props) {
  const { isRequesting, validate, paymentOptions } = props;
  const intl = useIntl();
  const { selectedTerritory } = useContext(TerritoryContext);

  const onBoardInfoUrl = selectedTerritory?.payment?.onboard?.info_url;

  return (
    <div className="column" style={{ padding: "1rem 1rem 1rem 4rem" }}>
      <Typography variant="body1">
        <FormattedMessage id="payment.onboard.info" />
      </Typography>

      <Typography variant="body2" style={{ fontWeight: "bold" }}>
        <FormattedMessage
          id="TEXT_INFO_ONBOARD_PAYMENT_VAR"
          values={{
            paymentMethods: paymentOptions.onboard.onboard_payment_modes
              .map((mode) =>
                intl.formatMessage({
                  id: `LABEL_PAYMENT_MODE_${mode.displayed_name.replace(" ", "_").toUpperCase()}`,
                }),
              )
              .join(", "),
          }}
        />
      </Typography>
      {onBoardInfoUrl && (
        <Link to={onBoardInfoUrl} target="_blank">
          <Typography className="hover:underline">
            <FormattedMessage id="payment.onboard.link" />
          </Typography>
        </Link>
      )}

      <div
        className="row fullWidth"
        style={{
          alignItems: "flex-end",
          alignSelf: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <OnBoard style={{ width: "6em", height: "6em" }} />

        <MuiButton
          color="primary"
          variant="contained"
          onClick={() => validate()}
          disabled={isRequesting}
          data-testid="pay-onboard-btn"
        >
          {isRequesting ? (
            <CircularProgress />
          ) : (
            <FormattedMessage id="misc.confirm" />
          )}
        </MuiButton>
      </div>
    </div>
  );
}
