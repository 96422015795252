import React from "react";

import { SvgIcon } from "@material-ui/core";

export function WheelchairIcon() {
  return (
    <SvgIcon style={{ margin: "0 0 0 0.25rem" }} viewBox="0 0 120 110">
      <g fill="#000" fillRule="nonzero">
        <path d="M35.993 41.002v6.481h2.418c5.83 0 11.662.059 17.49-.026 2.575-.037 4.316.974 5.587 3.199 4.367 7.646 8.824 15.241 13.203 22.881.504.881.892 1.905 1.027 2.903.293 2.151-.682 3.777-2.575 4.792-1.652.887-3.937.486-5.375-.957-.626-.631-1.147-1.39-1.598-2.161-3.175-5.45-6.359-10.901-9.443-16.403-.674-1.2-1.428-1.613-2.783-1.604-8.05.061-16.101.041-24.153.025-6.238-.013-9.236-2.686-9.676-8.916-.559-7.89-.992-15.789-1.367-23.69-.224-4.748 3.004-8.465 7.346-8.739 4.718-.298 8.301 2.896 8.742 7.823.196 2.2.339 4.404.524 6.854 4.593 0 9.082-.009 13.571.008.896.003 1.816-.007 2.684.179 2.014.431 3.233 2.018 3.088 3.84-.152 1.888-1.506 3.346-3.557 3.43-3.257.132-6.521.067-9.783.079-1.787.008-3.571.002-5.37.002z" />
        <path d="M55.825 69.466c1.423 2.445 2.698 4.504 3.813 6.648.252.48.128 1.341-.131 1.879-6.344 13.181-21.58 20.213-35.739 16.535C9.306 90.773-.5 77.5.273 62.719.809 52.486 5.408 44.491 13.65 38.514c.317-.229.68-.396 1.248-.722.143 2.37.376 4.502.339 6.63-.011.687-.606 1.485-1.143 2.023-13.169 13.201-8.053 35.276 9.58 41.259 12.982 4.407 27.34-2.944 31.4-16.074.185-.583.403-1.159.751-2.164zM26.083.5c4.665-.039 8.355 3.584 8.38 8.225.022 4.591-3.732 8.378-8.3 8.372-4.447-.005-8.229-3.731-8.311-8.186C17.767 4.321 21.47.538 26.083.5z" />
      </g>
    </SvgIcon>
  );
}
