function getRGBComponents(hexString) {
  const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
    hexString,
  );
  return {
    red: parseInt(components[1], 16),
    green: parseInt(components[2], 16),
    blue: parseInt(components[3], 16),
  };
}

function lum(component) {
  const value = component / 255;
  return value <= 0.03928
    ? value / 12.92
    : Math.pow((value + 0.055) / 1.055, 2.4);
}

function luminance(RGBColor) {
  const rLum = 0.2126 * lum(RGBColor.red);
  const gLum = 0.7152 * lum(RGBColor.green);
  const bLum = 0.0722 * lum(RGBColor.blue);
  return rLum + gLum + bLum;
}

function contrastRatio(colorHex1, colorHex2) {
  const rgb1 = getRGBComponents(colorHex1);
  const rgb2 = getRGBComponents(colorHex2);
  const lum1 = luminance(rgb1) + 0.05;
  const lum2 = luminance(rgb2) + 0.05;
  const ratio = lum1 / lum2;
  return ratio < 1.0 ? 1.0 / ratio : ratio;
}

const rgbToHex = (r, g, b) => {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
};

export function getAccessibleTextColor(colorHex) {
  if (colorHex.startsWith("rgb")) {
    const rgb = colorHex
      .substring(colorHex.indexOf("("), colorHex.lastIndexOf(")"))
      .split(",");
    colorHex = rgbToHex(parseInt(rgb[0]), parseInt(rgb[1]), parseInt(rgb[2]));
  }
  const white = "#FFFFFF";
  const black = "#000000";
  const whiteRatio = contrastRatio(colorHex, white);
  const blackRatio = contrastRatio(colorHex, black);
  return blackRatio > whiteRatio ? black : white;
}

/**
 * Returns the opposite hexa color code for a given color.
 * @param {string} hexaColorCode
 * @returns {string}
 */
export function invertColor(hexaColorCode) {
  hexaColorCode = hexaColorCode.replace("#", "");

  let invertedRed = (
    255 - parseInt(hexaColorCode.substring(0, 2), 16)
  ).toString(16);
  let invertedGreen = (
    255 - parseInt(hexaColorCode.substring(2, 4), 16)
  ).toString(16);
  let invertedBlue = (
    255 - parseInt(hexaColorCode.substring(4, 6), 16)
  ).toString(16);

  invertedRed = invertedRed.padStart(2, "0");
  invertedGreen = invertedGreen.padStart(2, "0");
  invertedBlue = invertedBlue.padStart(2, "0");

  return `#${invertedRed}${invertedGreen}${invertedBlue}`;
}
