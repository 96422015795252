import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { lookupImpersonate } from "containers/ImpersonateForm/actions.js";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";

import store from "store/store.js";

export function LookupImpersonate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { loadUserProfile } = useContext(UserContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const phone = searchParams.get("phone");
  const next = searchParams.get("next") && searchParams.get("next").trim();

  useEffect(() => {
    store.dispatch(
      lookupImpersonate(
        navigate,
        loadUserProfile,
        phone,
        next,
        selectedTerritory.territory_key,
      ),
    );
  }, []);

  return <div>Recherche en cours ...</div>;
}
