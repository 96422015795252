import React, { useState } from "react";
import { useIntl } from "react-intl";

import { Typography } from "@material-ui/core";

import { IndicationsToDriverDialog } from "components/organisms/IndicationsToDriverDialog/IndicationsToDriverDialog";

import { AddCommentIcon } from "icons/AddCommentIcon";
import { CommentedIcon } from "icons/CommentedIcon";

import { useIndicationsToDriverInputStyles } from "./indicationsToDriverInputStyles";

export function IndicationsToDriverInput(props) {
  const {
    bookingId,
    isSearchRequest = false,
    onIndicationsToDriverChange,
    previousIndications,
  } = props;

  const [indicationsToDriver, setIndicationsToDriver] =
    useState(previousIndications);
  const [indicationsToDriverDialogOpen, setIndicationsToDriverDialogOpen] =
    useState(false);

  const intl = useIntl();
  const classes = useIndicationsToDriverInputStyles();

  return (
    <div className={classes.indicationsToDriverContainer}>
      <button
        className={`${classes.indicationsToDriverInput}${
          indicationsToDriver ? "" : ` ${classes.addIndicationsButton}`
        }`}
        onClick={() => setIndicationsToDriverDialogOpen(true)}
      >
        {indicationsToDriver ? <CommentedIcon /> : <AddCommentIcon />}

        <Typography className={classes.indicationsToDriverContent}>
          {indicationsToDriver ||
            intl.formatMessage({ id: "comment.dialog_title" })}
        </Typography>
      </button>

      {indicationsToDriverDialogOpen && (
        <IndicationsToDriverDialog
          bookingId={bookingId}
          indicationsToDriver={indicationsToDriver}
          isSearchRequest={isSearchRequest}
          onIndicationsToDriverChange={onIndicationsToDriverChange}
          setIndicationsToDriver={setIndicationsToDriver}
          setIndicationsToDriverDialogOpen={setIndicationsToDriverDialogOpen}
        />
      )}
    </div>
  );
}
