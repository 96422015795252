import { makeStyles } from "@material-ui/core";

export const useSelectedDiscountListStyles = makeStyles(() => ({
  promoCode: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#6C6C6C",
    margin: "1.5rem 0 0 0",
  },
  promoCodeName: {
    width: "calc(100% - 7.25rem)",

    lineHeight: 1.15,
  },
  promoCodeValue: {
    width: "5rem",
    padding: "0 0 0 0.5rem",

    textAlign: "right",
  },
  discountList: {
    listStyleType: "none",
    padding: 0,
  },
  discountListItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    margin: "0.25rem 0",
  },
  discountName: {
    width: "calc(100% - 7.25rem)",

    lineHeight: 1.15,
  },
  discountValue: {
    width: "5rem",
    padding: "0 0 0 0.5rem",

    textAlign: "right",
  },
  flatFares: {
    margin: "1.5rem 0 0 0",
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",

    padding: "2rem 0 1.5rem 0",
  },
  removeButtonContainer: {
    width: "2.25rem",

    textAlign: "right",
  },
  schemeColor: {
    color: "#6C6C6C",
  },
}));
