import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePaymentDiscountsStyles = makeStyles((theme: CustomTheme) => ({
  changeDiscounts: {
    display: "flex",
    alignItems: "center",

    margin: "1rem 0 0 0",
  },
  discountTicketIcon: {
    margin: "0 0.5rem 0 0",
  },
  link: {
    border: 0,

    color: theme.palette.info.main,
    textDecoration: "underline",

    background: "white",

    cursor: "pointer",

    "&:hover": {
      textDecoration: "none",
    },
  },
}));
