import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useSearchViewStyles = makeStyles((theme: CustomTheme) => ({
  searchViewContainer: {
    display: "flex",
    justifyContent: "center",

    background: `linear-gradient(${theme.palette.primary.light} 10rem, #f2f2f2 10rem)`,
  },
}));
