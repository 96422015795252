import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePaymentDetailsStyles = makeStyles((theme: CustomTheme) => ({
  chipAndExpandIcon: {
    display: "flex",
    alignItems: "center",

    margin: "0 0 0 0.5rem",
  },
  onBoardPaymentInfoLink: {
    display: "inline-block",

    margin: "1.5rem 0 0 0",

    color: theme.palette.info.main,
    textDecoration: "underline",

    "& svg": {
      transform: "translateY(3px)",
    },
  },
  paymentDetailsContainer: {
    borderRadius: "20px",

    "&.MuiAccordion-root::before": {
      display: "none",
    },
  },
  paymentDetails: {
    flexDirection: "column",

    "& li div:last-child": {
      display: "none",
    },
  },
  paymentDetailsHeader: {
    "&.Mui-expanded": {
      minHeight: "48px",
    },

    "& .Mui-expanded": {
      margin: "12px 0 0 0",
    },

    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  paymentMethod: {
    margin: "0 0.5rem 0 0",
  },
  removeButtonContainer: {
    display: "none",
  },
  supportingDocumentInfo: {
    margin: "1.5rem 0 0 0",
  },
  totalPriceContainer: {
    display: "flex",
    justifyContent: "space-between",

    padding: "1rem 0 0 0",
  },
}));
