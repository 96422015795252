import {
  ON_FIELD_CHANGE,
  SIGNUP_REQUEST_ERROR,
  REQUESTING,
  RESET_STATE,
} from "./actions.js";

const initialState = {
  lastname: "",
  firstname: "",
  phoneNumber: "",
  email: "",
  password: "",
  passwordRepeat: "",
  hasTermsAccepted: false,
  optInMarket: false,
  requesting: false,
  error: {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    passwordRepeat: "",
    passport_id: "",
  },
};

export const signup = (state = initialState, action) => {
  switch (action.type) {
    case ON_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case SIGNUP_REQUEST_ERROR:
      return {
        ...state,
        requesting: false,
        error: {
          ...state.error,
          ...action.error.infos,
        },
      };
    case REQUESTING:
      return {
        ...state,
        requesting: true,
        error: initialState.error,
      };
    case "SIGNUP_REQUEST_SUCCESS":
      return {
        ...initialState,
        phoneNumber: state.phoneNumber, // still needs to be verified in SignUpVerificationForm
      };
    case RESET_STATE:
      return initialState;
    default:
      return state;
  }
};
