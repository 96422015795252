import * as React from "react";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Paper } from "@material-ui/core";

import api from "api";

import { useStopNotificationsViewStyles } from "./stopNotificationsViewStyles";

export function StopNotificationsView() {
  const [error, setError] = useState(true);
  const classes = useStopNotificationsViewStyles();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const customerId = params.get("customer-id");
    const contactId = params.get("contact-id");

    if (customerId && contactId) {
      api
        .stopNotifications({
          customerId,
          contactId,
        })
        .then(() => {
          setError(false);
        })
        .catch((json) => {
          if (json.infos.includes("already unsubscribed")) {
            setError(false);
          }
        });
    }
  }, [setError]);

  return (
    <>
      <h1 className="visuallyHidden">
        <FormattedMessage
          id="title.stop-notifications"
          defaultMessage="Stop notifications"
        />
      </h1>
      <Paper className={classes.message}>
        <h2>
          {error ? (
            <FormattedMessage
              id="stop-notifications-error-h2"
              defaultMessage="An error occured"
            />
          ) : (
            <FormattedMessage
              id="stop-notifications-h2"
              defaultMessage="You have stopped the notifications"
            />
          )}
        </h2>
        <p>
          {error ? (
            <FormattedMessage
              id="stop-notifications-error-message"
              defaultMessage="We have not been able to interrupt this notifications. Please click again the email link."
            />
          ) : (
            <FormattedMessage
              id="stop-notifications-message"
              defaultMessage="You will not receive any more notification mail about this user's activity."
            />
          )}
        </p>
      </Paper>
      ;
    </>
  );
}
