import api from "api";
import { snackMessage } from "containers/SnackBar/actions.js";
import { fetchReservations } from "containers/ReservationList/actions.js";

export const CANCELLATION_REASONS_FETCHED = "CANCELLATION_REASONS_FETCHED";

const cancellationReasonsFetched = (reasons) => ({
  type: CANCELLATION_REASONS_FETCHED,
  reasons,
});

export const getCancellationReasons = (selectedTerritoryKey) => (dispatch) => {
  api
    .getCancellationReasons(undefined, { territory: selectedTerritoryKey })
    .then((json) => {
      dispatch(cancellationReasonsFetched(json.cancellation_reasons));
    });
};

export const deleteReservation =
  (navigate, payload, recurringServicesEnabled, selectedTerritoryKey) =>
  (dispatch, getState, getIntl) => {
    const intl = getIntl();
    const state = getState();

    let impersonate = "CANCELED_BY_USER";
    // for now, the Referent Impersonate is labelled by what's above
    if (
      state.impersonateForm.sessionStarted &&
      !state.impersonateForm.impersonateByReferent
    )
      impersonate = "CANCELED_BY_CALL_CENTER";

    api
      .cancelReservation(
        {
          reservation_id: payload.reservation.id,
          cancelled_by: impersonate,
        },
        { territory: selectedTerritoryKey },
      )
      .then(() => {
        if (payload.cancellationReason) {
          api.commentCancelledReservation({
            reservation_id: payload.reservation.id,
            cancellation_reason: payload.cancellationReason,
            comment: payload.comment,
          });
        }
        dispatch(
          fetchReservations(recurringServicesEnabled, selectedTerritoryKey),
        );
        dispatch(
          snackMessage(
            "success",
            intl.formatMessage({ id: "reservation.deleted" }),
          ),
        );
        if (payload.context === "reservation") navigate("/reservation");
      })
      .catch((error) => {
        dispatch(
          snackMessage(
            "error",
            error.infos?.detail?.message ||
              intl.formatMessage({ id: "misc.unexpected_error" }),
            "error on api: cancel-reservation\n with payload " +
              JSON.stringify({
                reservation_id: payload.reservation.id,
                cancelled_by: impersonate,
              }),
          ),
        );
      });
  };
