import Authorization from "./authorization.js";
import { CONTENT_TYPES, HTTP_METHOD } from "./constants";
import { AxiosInstance, AxiosRequestConfig, Method } from "axios";
import * as apiType from "./types/api";

type requestArgsType = {
  axiosInstance: AxiosInstance;
  method: Method;
  url: string;
  baseUrl?: string;
  apiVersion?: string;
  impersonate?: boolean;
  payload?: apiType.PayloadType;
  urlParameters?: apiType.UrlParametersType;
  queryParameters?: apiType.FiltersType;
  authenticationRequired?: boolean;
};

type parseUrlType = {
  url: string;
  error: string | null;
};

/**
 * Http wrapper used to request the API.
 * uses fetch under the hood
 */
export const http = {
  getKeyInUrl: function (url: string) {
    const key = url.match(/\/\d+/g);
    if (key) {
      return key[0].replace("/", "");
    }
    return null;
  },
  /**
   * URL parsing. Matching & replacing placeholders
   *
   * @param  {string} url
   * @param  {object} data
   * @return
   */
  parseUrl: function (url: string, data): parseUrlType {
    const placeholders = url.match(/({[^}]+})/g);
    const result: parseUrlType = {
      url: url,
      error: null,
    };

    if (!placeholders) return result;

    for (const match of placeholders) {
      const key = match.replace("{", "").replace("}", "").trim();

      if (!data.hasOwnProperty(key)) {
        result.error = `http.parseURL - key '${key}' is required`;
        return result;
      }

      if (data[key]) {
        result.url = result.url.replace(match, data[key]);

        delete data[key];
      }
    }

    return result;
  },

  /**
   * Parse queries object and create query string
   *
   * @param {string} url
   * @param {apiType.FiltersType} queries
   */
  parseQueries: function (url: string, queryParameters: apiType.FiltersType) {
    if (Object.keys(queryParameters).length === 0) {
      return url;
    }

    const queriesUrl: string[] = [];
    for (const [key, values] of Object.entries(queryParameters)) {
      queriesUrl.push(`${key}=${values}`);
    }

    return `${url}?${queriesUrl.join("&")}`;
  },

  /**
   * @param {requestArgsType} parameters request arguments
   * @return
   */
  request: function ({
    axiosInstance,
    method,
    url,
    baseUrl,
    apiVersion,
    impersonate,
    payload,
    urlParameters,
    queryParameters,
    authenticationRequired = true,
  }: requestArgsType) {
    const axiosRequestConfig: AxiosRequestConfig = {
      url: url,
      method: method,
      headers: {},
      data: payload,
    };

    if (baseUrl) {
      axiosRequestConfig.baseURL = baseUrl;
    }

    // @ts-ignore
    axiosRequestConfig?.headers?.Accept = apiVersion
      ? `${CONTENT_TYPES.JSON}; version=${apiVersion}`
      : CONTENT_TYPES.JSON;

    if (authenticationRequired) {
      // @ts-ignore
      axiosRequestConfig?.headers?.Authorization =
        Authorization.getHeaders(impersonate);
    }

    axiosRequestConfig.url = url;
    const result = this.parseUrl(axiosRequestConfig.url, {
      ...urlParameters,
    });

    if (result.error) {
      return Promise.reject(result.error);
    }

    axiosRequestConfig.url = result.url;

    if (queryParameters && Object.keys(queryParameters).length > 0) {
      axiosRequestConfig.url =
        this.parseQueries(axiosRequestConfig.url, queryParameters) || undefined;
    }

    if (
      ![HTTP_METHOD.POST, HTTP_METHOD.PUT, HTTP_METHOD.PATCH].includes(method)
    ) {
      axiosRequestConfig.data = {};
    }

    return axiosInstance.request(axiosRequestConfig);
  },
};
