import React from "react";
import { useIntl } from "react-intl";

import ProfileForm from "containers/ProfileForm/ProfileForm.jsx";

export default function ProfileView() {
  const intl = useIntl();

  return (
    <div>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "profile.title" })}
      </h1>
      <ProfileForm />
    </div>
  );
}
