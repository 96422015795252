import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button as MuiButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";

import Credits from "../Icons/Credits";

import { getCreditBalance } from "containers/CreditManager/actions";

function CreditPaymentProvider(props) {
  const {
    balance,
    getCreditBalance,
    isRequesting,
    isRequestingBalance,
    validate,
    totalPrice,
  } = props;

  useEffect(() => {
    getCreditBalance();
  }, []);

  return (
    <div className="column" style={{ padding: "1rem" }}>
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <Credits style={{ width: "10rem", height: "6rem" }} />

        {isRequestingBalance ? (
          <CircularProgress />
        ) : (
          <div className="column">
            <Typography variant="h2">
              <FormattedMessage id="credit.balance" values={{ balance }} />
            </Typography>

            {balance < totalPrice ? (
              <Typography variant="body1">
                <FormattedMessage id="credit.notEnough" />
              </Typography>
            ) : (
              <Typography variant="h3">
                <FormattedMessage
                  id="credit.balanceAfter"
                  values={{
                    balance: (balance - totalPrice).toFixed(2),
                  }}
                />
              </Typography>
            )}
          </div>
        )}
      </div>

      <MuiButton
        color="primary"
        variant="contained"
        onClick={() => validate()}
        disabled={isRequesting || balance < totalPrice}
        style={{ alignSelf: "flex-end" }}
        data-testid="pay-with-credits-btn"
      >
        {isRequesting ? (
          <CircularProgress />
        ) : (
          <FormattedMessage
            id="payment.withCredits"
            values={{ price: totalPrice }}
          />
        )}
      </MuiButton>
    </div>
  );
}

const mapStateToProps = (state) => ({
  balance: state.credit.balance,
  isRequestingBalance: state.credit.isRequestingBalance,
});

const mapDispatchToProps = (dispatch) => ({
  getCreditBalance: () => dispatch(getCreditBalance()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreditPaymentProvider);
