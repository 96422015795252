import React, { useState, useEffect, useContext } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate } from "react-router-dom";

import {
  withTheme,
  Button as MuiButton,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";

import ActionInfo from "components/Icons/ActionInfo";
import Close from "components/Icons/Close";
import Credit from "components/Icons/Credit";
import Warning from "components/Icons/Warning";

import { TerritoryContext } from "contexts/TerritoryContext";

import api from "apiAxios";

import { useNotificationStyles } from "./notificationStyles";

function Notification() {
  const classes = useNotificationStyles();

  const navigate = useNavigate();

  const { selectedTerritory } = useContext(TerritoryContext);

  const [notif, setNotif] = useState(null);
  const [isRendered, setIsRendered] = useState(true);
  const [isOpen, setIsOpen] = useState(false); // only for TEMPLATE_FULL_PAGE

  useEffect(() => {
    if (!selectedTerritory) {
      return;
    }

    api
      .getCurrentInAppNotifications({
        queryParameters: {
          territory: selectedTerritory.territory_key,
        },
      })
      .then((json) => {
        const newNotif = _.get(json, "results.0");
        setNotif(newNotif);
      })
      .catch((error) => {
        console.log({ error });
      });
  }, [selectedTerritory]);

  if (!notif || !isRendered) return null;

  // this is used to find the equivalent web path of internal mobile app link:
  const mobileToWebLinks = {
    account: "profile",
    home: "search",
  };

  // keys are [notif].action_type:
  const actions = {
    IN_APP_LINK: () => navigate(mobileToWebLinks[notif.action_in_app_link]),
    TEMPLATE_FULL_PAGE: () => setIsOpen(true),
    URL: () => null,
  };

  const notifColors = {
    ALERT: "#fae0e0",
    INFORMATION: "#daeaf3",
    PROMOTION: "#f4f4f4",
  };

  const iconColors = {
    ALERT: "#d0021b",
    INFORMATION: "#0869AF",
    PROMOTION: "#151515",
  };

  const NotifIcons = {
    ALERT: Warning,
    INFORMATION: ActionInfo,
    PROMOTION: Credit,
  };

  const Icon = NotifIcons[notif.notification_type];

  return (
    <div className={classes.banner}>
      <Card
        className={classes.card}
        style={{ background: notifColors[notif.notification_type] }}
        square
        aria-label="notification banner"
      >
        <Icon
          className={classes.icon}
          style={{
            color: iconColors[notif.notification_type],
          }}
        />

        <div className={classes.contentWithButtonContainer}>
          <div className={classes.contentContainer}>
            <CardContent className={classes.content}>
              <Typography className={classes.shortTitle}>
                {notif.short_title}
              </Typography>
            </CardContent>

            <CardContent className={classes.content}>
              <Typography className={classes.shortText}>
                {notif.short_text}
              </Typography>
            </CardContent>
          </div>

          {notif.action_type !== "NO_ACTION" && (
            <MuiButton
              className={classes.actionButton}
              variant="outlined"
              color="inherit"
              href={notif.action_type === "URL" ? notif.action_url : null}
              target="_blank"
              role="link"
              aria-label="notification action"
              onClick={() => actions[notif.action_type]()}
            >
              {notif.action_type === "IN_APP_LINK" && (
                <FormattedMessage id="misc.redirect" />
              )}
              {notif.action_type === "TEMPLATE_FULL_PAGE" && (
                <FormattedMessage id="ride.see_more" />
              )}
              {notif.action_type === "URL" && (
                <FormattedMessage id="misc.redirect" />
              )}
            </MuiButton>
          )}
        </div>

        <IconButton
          className={classes.icon}
          aria-label="close notification banner"
          onClick={() => setIsRendered(false)}
        >
          <Close style={{ color: "#404148", width: "2rem", height: "2rem" }} />
        </IconButton>

        {/* long text dialog */}

        <Dialog
          open={isOpen}
          aria-label="notification popup"
          onClose={() => setIsOpen(false)}
        >
          <IconButton
            style={{ position: "absolute", right: 0, top: 0 }}
            aria-label="close notification popup"
            onClick={() => setIsOpen(false)}
          >
            <Close
              style={{ color: "#404148", width: "1.5rem", height: "1.5rem" }}
            />
          </IconButton>

          <DialogTitle
            style={{
              textAlign: "center",
              backgroundColor: notifColors[notif.notification_type],
            }}
          >
            {notif.long_title}
          </DialogTitle>

          <DialogContent>
            <Typography variant="body1">{notif.long_text}</Typography>
          </DialogContent>

          {!_.isEmpty(notif.assets) && (
            <DialogContent className="row-only" style={{ flexWrap: "wrap" }}>
              {notif.assets.map((src, index) => (
                <img src={src} width="300" key={`notif-asset-${index}`} />
              ))}
            </DialogContent>
          )}
        </Dialog>
      </Card>
    </div>
  );
}

export default withTheme(Notification);
