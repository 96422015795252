import React from "react";
import { withTheme } from "@material-ui/core/styles";

const DropOff = ({ className, width, height, theme, style }) => (
  <svg
    className={className}
    style={{ fill: theme.palette.error.main, ...style }}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    focusable="false"
    role="none"
    aria-hidden="true"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="#FF1D58">
        <g>
          <g transform="translate(-322 -203) translate(310 170) translate(12 33)">
            <circle cx="13" cy="13" r="6.5" />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default withTheme(DropOff);
