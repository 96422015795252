import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { IntlProvider } from "react-intl";
import dayjs from "dayjs";

import { UserContext } from "contexts/UserContext";
import { getMessages } from "../i18n";

type I18nProviderProps = {
  children?: React.ReactNode;
};

export const I18nProvider: React.FC<I18nProviderProps> = ({ children }) => {
  const { language } = useContext(UserContext);

  const [messages, setMessages] = React.useState(getMessages(language));

  useEffect(() => {
    dayjs.locale(language);
    setMessages(getMessages(language));
  }, [language]);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }} />
      <IntlProvider locale={language} messages={messages}>
        {children}
      </IntlProvider>
    </>
  );
};
