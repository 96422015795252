import * as React from "react";

import { Link, Paper, Typography } from "@material-ui/core";

import { OpenNewTabIcon } from "icons/OpenNewTabIcon";

import { usePaperLinkStyles } from "./paperLinkStyles";

type PaperLinkProps = {
  linkText: string;
  url: string;
};

export function PaperLink(props: PaperLinkProps) {
  const { linkText, url } = props;

  const classes = usePaperLinkStyles();

  return (
    <Paper className={classes.paperLinkContainer}>
      <Link className={classes.link} href={url} target="_blank">
        <Typography className={classes.linkText}>
          <span>{linkText}</span>

          <span className={classes.newTabIcon}>
            <OpenNewTabIcon />
          </span>
        </Typography>
      </Link>
    </Paper>
  );
}
