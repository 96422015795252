import React from "react";

import { SvgIcon } from "@material-ui/core";

export function PhoneIcon() {
  return (
    <SvgIcon>
      <path d="M14.205 15.823l.714-.71c.361-.36.645-.642.827-.821.902-.895 2.108-.895 3.013 0 .84.834 1.692 1.685 2.558 2.557.915.922.91 2.129-.01 3.055-.181.184-.48.402-.884.663-.09.058-.185.118-.284.179-.267.164-.551.331-.835.493-.171.097-.304.17-.37.206-1.04.6-2.73.73-3.813.31-.109-.032-.303-.098-.572-.2-.44-.168-.931-.377-1.458-.63-1.504-.722-3.007-1.655-4.411-2.823-.564-.47-1.102-.969-1.61-1.499-.409-.428-.798-.874-1.167-1.336-1.216-1.52-2.171-3.148-2.895-4.776-.254-.57-.461-1.102-.625-1.58-.1-.29-.163-.5-.181-.573-.408-1.123-.177-2.814.503-3.808.043-.066.117-.179.215-.323.164-.24.333-.482.502-.707.073-.098.144-.192.213-.28.178-.225.34-.411.484-.554.904-.889 2.108-.888 3.014.002.37.362 1.964 1.958 2.541 2.54.934.942.936 2.133.003 3.07-.14.14-.443.445-.863.864l-.651.652-.242.243c.059.15.157.383.292.673.26.562.557 1.123.886 1.643.359.568.733 1.051 1.115 1.424.353.344.774.677 1.249.996.554.371 1.15.704 1.747.992.31.15.558.258.727.326l.268-.268zm6.163 1.6c-.855-.86-1.694-1.7-2.523-2.52-.488-.485-1.027-.485-1.513-.002-.179.177-.46.455-.814.808l-.707.704-.32.319c-.255.254-.68.335-.998.201-.175-.067-.472-.195-.852-.378-.631-.304-1.263-.657-1.855-1.054-.519-.348-.983-.715-1.38-1.103-.443-.431-.861-.972-1.256-1.596-.352-.557-.667-1.15-.941-1.743-.165-.357-.28-.636-.339-.79-.132-.329-.043-.747.205-.995l.293-.293.643-.643.852-.854c.518-.52.517-1.043-.004-1.57-.567-.571-2.141-2.146-2.502-2.5-.491-.482-1.032-.482-1.52-.002-.107.105-.24.259-.391.45-.063.08-.127.164-.194.254-.156.21-.316.436-.47.662-.091.135-.16.24-.206.31-.493.721-.674 2.042-.373 2.877.025.093.081.278.172.541.152.445.346.941.584 1.476.682 1.533 1.581 3.066 2.724 4.495.346.433.71.85 1.093 1.25.474.496.977.962 1.504 1.401 1.318 1.097 2.732 1.974 4.145 2.653.494.237.952.432 1.361.588.243.092.413.15.534.188.814.313 2.155.21 2.923-.232.07-.039.197-.108.36-.2.27-.155.54-.314.792-.469.093-.057.181-.112.264-.166.34-.219.588-.4.699-.512.508-.513.51-1.051.01-1.555z" />
    </SvgIcon>
  );
}
