import React, { useContext, useEffect, useState } from "react";

import { MuiThemeProvider, Theme } from "@material-ui/core/styles";

import { RegionalTerritory } from "api/types/common";

import { ProductContext } from "contexts/ProductContext";

import { FALLBACK_COLORS } from "styles/clientsThemes";

import { getContrastColor, getMutedContrastColor } from "utils/contrast";

import { createTheme } from "../../themes/theme";

import { useThemeLayoutStyles } from "./themeLayoutStyles";

function hexToRGB(hex?: string) {
  if (!hex) return hex;
  let r = "0",
    g = "0",
    b = "0";

  // 3 digits
  if (hex.length == 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];

    // 6 digits
  } else if (hex.length == 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }

  return +r + "," + +g + "," + +b;
}

type ThemeColors = RegionalTerritory["bookingzone_theme"]["colors"] & {
  textColor?: string;
};

function setCssVarsForTailwind(themeColors: ThemeColors) {
  const root = document.querySelector<HTMLElement>(":root");

  if (!root) {
    throw new Error("Could not find :root element");
  }

  const primaryForeground = themeColors?.PRIMARY
    ? getContrastColor(themeColors.PRIMARY)
    : undefined;
  const primaryMutedForeground = primaryForeground
    ? getMutedContrastColor(primaryForeground)
    : undefined;

  const cssVars = {
    "--primary": themeColors?.PRIMARY
      ? hexToRGB(themeColors.PRIMARY)
      : undefined,
    "--primary-foreground": hexToRGB(primaryForeground),
    "--primary-muted-foreground": hexToRGB(primaryMutedForeground),
    "--pickup-color": themeColors?.PICKUP,
    "--dropoff-color": themeColors?.DROPOFF,
  };

  Object.entries(cssVars).forEach(([k, v]) => {
    if (v) {
      root.style.setProperty(k, v);
    } else {
      root.style.removeProperty(k);
    }
  });
}

export function ThemeLayout({ children }) {
  const [theme, setTheme] = useState<Theme | undefined>(undefined);

  const classes = useThemeLayoutStyles();

  const { productParameters } = useContext(ProductContext);

  const brand = productParameters?.brand;
  const colors = productParameters?.bookingzone_theme.colors;

  useEffect(() => {
    if (!brand) {
      return;
    }
    let themeColors: ThemeColors;

    if (colors && Object.keys(colors).length > 0) {
      themeColors = colors;
      // Alternative text color if computed accessible color is not satisfactory (like for Flex'hop):
      if (FALLBACK_COLORS[brand]?.TO_OVERRIDE_GET_ACCESSIBLE_TEXT_COLOR) {
        themeColors.textColor =
          FALLBACK_COLORS[brand].TO_OVERRIDE_GET_ACCESSIBLE_TEXT_COLOR;
      }
    } else if (FALLBACK_COLORS[brand]) {
      themeColors = FALLBACK_COLORS[brand];
    } else {
      themeColors = FALLBACK_COLORS.Padam;
    }

    setCssVarsForTailwind(themeColors); // For Tailwind CSS
    setTheme(createTheme(themeColors)); // For MUI
  }, [brand, colors]);

  return (
    <div
      className={`${classes.layoutContainer} ${
        theme ? classes.visibleContainer : classes.hiddenContainer
      }`}
    >
      {theme && <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>}
    </div>
  );
}
