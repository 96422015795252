export const FALLBACK_COLORS = {
  CarPostal: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Celering: {
    PRIMARY: "#446190",
    PICKUP: "#446190",
    DROPOFF: "#e01021",
  },
  "CLAM'Express": {
    PRIMARY: "#01707c",
    PICKUP: "#00acc8",
    DROPOFF: "#e6466e",
  },
  Fileo: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "Flex'hop": {
    PRIMARY: "#c7353a",
    PICKUP: "#c7353a",
    DROPOFF: "#e82394",
    TO_OVERRIDE_GET_ACCESSIBLE_TEXT_COLOR: "white",
  },
  Flexigo: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Free2Move: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Haguenau: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "Icilà d'Envibus": {
    PRIMARY: "#E20079",
    PICKUP: "#E20079",
    DROPOFF: "#22780F",
  },
  "ilévia réservation": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Its: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "La Navette": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "La Saire TaD": {
    PRIMARY: "#00b373",
    PICKUP: "#00b373",
    DROPOFF: "#f7a30a",
  },
  "Lausanne bus": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  LTA: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Mobibus: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "Mouvéo-TAD": {
    PRIMARY: "#009540",
    PICKUP: "#009540",
    DROPOFF: "#e82394",
  },
  "Night Bus": {
    PRIMARY: "#c8102e",
    PICKUP: "#002d72",
    DROPOFF: "#e82394",
  },
  Padam: {
    PRIMARY: "#01092b",
    PICKUP: "#69e781",
    DROPOFF: "#e20c44",
  },
  "Padam Shuttle": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "Plus de Pep's": {
    PRIMARY: "#e5007d",
    PICKUP: "#e5007d",
    DROPOFF: "#bcc400",
  },
  Résalib: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  RESAGO: {
    PRIMARY: "#5092c9",
    PICKUP: "#5092c9",
    DROPOFF: "#e82394",
  },
  résaGO: {
    PRIMARY: "#F1B202",
    PICKUP: "#F1B202",
    DROPOFF: "#CB0375",
  },
  "Résa'Tao": {
    PRIMARY: "#26adbc",
    PICKUP: "#26adbc",
    DROPOFF: "#e82394",
  },
  "Résa'Tao TPMR": {
    PRIMARY: "#26adbc",
    PICKUP: "#26adbc",
    DROPOFF: "#e82394",
  },
  SAFIR: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "SAM-E on demand": {
    PRIMARY: "#0070B8",
    PICKUP: "#0070B8",
    DROPOFF: "#e82394",
  },
  TAD: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "TCL à la demande": {
    PRIMARY: "#ed7442",
    PICKUP: "#e00c19",
    DROPOFF: "#42b1f7",
  },
  "The Hague": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  Traverse: {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
  "Ville de Paris": {
    PRIMARY: "#6588ea",
    PICKUP: "#495da2",
    DROPOFF: "#f25555",
  },
};
