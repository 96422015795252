import { settings } from "config/app";
import { http } from "./http";
import { apiResources } from "./resources";

import { getLang } from "../i18n";
import { UNAUTHORIZED } from "./constants";

const TERRITORY_QUERIES = {
  app_version: "bookingzone:1.0.0",
  user_group: "customer",
};

const api = {
  UNAUTHORIZED: UNAUTHORIZED,
};

for (const key in apiResources) {
  const resource = apiResources[key];

  let url = resource.apiVersion
    ? settings.api.baseURL.newApi
    : settings.api.baseURL.api;

  if (resource.adminzone) {
    url = settings.api.baseURL.adminzone;
  }

  url += resource.endpoint;

  api[key] = (payload, queries) => {
    const territoryQueries = { ...TERRITORY_QUERIES };

    // Don't include the territory param if it's null
    const { territory, ...filteredQueries } = queries || {};
    if (territory) {
      filteredQueries.territory = territory;
    }

    territoryQueries.language = getLang();

    return http.request({
      url: url,
      apiVersion: resource.apiVersion,
      method: resource.method,
      impersonate: resource.impersonate,
      payload,
      queries: { ...territoryQueries, ...filteredQueries },
      authenticationRequired: resource.authenticationRequired,
    });
  };
}

export default api;
