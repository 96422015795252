import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useRideSchedulesStyles = makeStyles((theme: CustomTheme) => ({
  flexLine: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    gap: "1rem 1.5rem",

    maxWidth: "calc(100% - 2rem)",
  },
  inlineLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    whiteSpace: "pre-wrap",

    "& > svg": {
      margin: "0 0.5rem",
    },
  },

  indicationsToDriverContainer: {
    margin: "1.5rem 0 0 0",
  },
  rideSchedulesContainer: {
    display: "grid",
    gridGap: "1.5rem",

    padding: "1rem",
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      padding: "1.5rem 2rem",
    },
  },
  rideSchedulesHeader: {
    fontWeight: 500,
  },
  confirmedTime: {
    color: theme.palette.success.main,
    gap: "0.5rem",
    "& > svg": {
      fill: theme.palette.success.main,
      height: "2.5rem",
      width: "2.5rem",
    },
  },
  unconfirmedTime: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
    fontStyle: "italic",
  },
}));
