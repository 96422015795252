import * as React from "react";
import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate, useLocation } from "react-router-dom";

import { useTheme } from "@material-ui/core/styles";

import SignUpVerificationForm from "organisms/SignUpVerificationForm/SignUpVerificationForm";

import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";
import { jumpToNextPage } from "store/actions";

import useWindowWidth from "lib/useWindowWidth";

export function SignUpVerificationView() {
  const theme = useTheme();
  const width = useWindowWidth();
  const navigate = useNavigate();
  const location = useLocation();
  const { productParameters } = useContext(ProductContext);
  const { userProfile } = useContext(UserContext);

  const phoneValidationProcessAtBooking =
    productParameters?.user.phone_validation_process === "AT_BOOKING";

  useEffect(() => {
    // If in signup after search mode or when the phone validation process is performed
    // at booking, the phone number verification form is shown on a dialog.
    // If the phone number already is validated, there is no need to be on this page.
    if (
      SIGNUP_AFTER_SEARCH ||
      phoneValidationProcessAtBooking ||
      userProfile.isPhoneValidated
    ) {
      jumpToNextPage(navigate, location);
    }
  }, [userProfile, phoneValidationProcessAtBooking]);

  return (
    <div
      className="column"
      style={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 className="visuallyHidden">
        <FormattedMessage id="signupVerification.title" />
      </h1>
      <SignUpVerificationForm />

      {
        //this is only a visual stuff
        width > 800 && (
          <div
            style={{
              width: "40rem",
              height: "15rem",
              position: "absolute",
              top: "55%",
              backgroundColor: theme.palette.primary.light,
            }}
          />
        )
      }
    </div>
  );
}
