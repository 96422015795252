import React, { useContext } from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { cn } from "utils/classes";

import { Typography } from "components/ui/typography";
import { UserContext } from "contexts/UserContext";
import { SignInToJoin } from "./SignInToJoin";
import { JoinForm } from "./JoinForm";

interface JoinWaitingListProps {
  className: string;
  searchRequestId: string;
}

export function JoinWaitingList({
  className,
  searchRequestId,
}: JoinWaitingListProps) {
  const { isAuthenticated } = useContext(UserContext);

  return (
    <div className={cn("flex flex-col items-center gap-6", className)}>
      <Typography variant="h3" className="text-3xl font-bold">
        <FormattedMessage id="PAGE_TITLE_WAITING_LIST" />
      </Typography>
      <Typography variant="ul" className="text-md text-muted-foreground">
        <li>
          <FormattedMessage id="TEXT_INFO_WAITING_LIST_VALIDATED" />
        </li>
        <li>
          <FormattedMessage id="TEXT_INFO_WAITING_LIST_UNVALIDATED" />
        </li>
      </Typography>
      {isAuthenticated ? (
        <JoinForm searchRequestId={searchRequestId} />
      ) : (
        <SignInToJoin />
      )}
    </div>
  );
}
