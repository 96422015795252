import api from "api";
import { settings } from "config/app";
import cookie from "lib/cookie.js";
import { snackMessage } from "containers/SnackBar/actions.js";
import { jumpToNextPage } from "store/actions";

export const ON_FIELD_CHANGE = "@@login/ON_FIELD_CHANGE";
export const LOGIN_REQUEST_ERROR = "LOGIN_REQUEST_ERROR";
export const REQUESTING = "@@login/REQUESTING";
export const RESET_LOGIN = "@@login/RESET_FORM";

export const onFieldChange = (field, value) => ({
  type: ON_FIELD_CHANGE,
  field,
  value,
});

export const loginRequestError = (error) => ({
  type: LOGIN_REQUEST_ERROR,
  error,
});

const requesting = () => ({
  type: REQUESTING,
});

export const loginRequest =
  (
    navigate,
    setIsAuthenticated,
    loadUserProfile,
    username,
    password,
    location,
  ) =>
  (dispatch, getState, getIntl) => {
    dispatch(requesting());
    api
      .login({ [LOGIN_TYPE]: username, password: password })
      .then((json) => {
        // in case of token conflict (impersonate, etc)

        cookie.erase(settings.cookieKeys.impersonateCookie);
        cookie.erase(settings.cookieKeys.authCookie);
        cookie.erase(settings.cookieKeys.isLoggedWithOpenid);
        cookie.erase("adm");

        cookie.set(settings.cookieKeys.authCookie, json.token, 2);

        setIsAuthenticated(true);

        loadUserProfile()
          .then((userProfile) => {
            dispatch(
              snackMessage(
                "success",
                getIntl().formatMessage(
                  {
                    id: "signin.success",
                  },
                  { name: userProfile.firstName },
                ),
              ),
            );
            dispatch({ type: RESET_LOGIN });
            if (!SIGNUP_AFTER_SEARCH) {
              jumpToNextPage(navigate, location);
            }
          })
          .catch((error) => {
            // inconsistent user or restricted territory
            dispatch(
              snackMessage(
                "error",
                _.get(error, "infos.detail.message", "unable to login"),
                error,
              ),
            );
            navigate("/login");
            dispatch(loginRequestError(error));
          });
      })
      .catch((error) => dispatch(loginRequestError(error)));
  };
