import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useDriverDetailsStyles = makeStyles((theme: CustomTheme) => ({
  driverDetail: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",

    margin: "0.5rem 0",

    fontSize: "1rem",
  },
  driverDetailContent: {
    padding: "0 0 0 2rem",
  },
  driverDetailType: {
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
  driverDetailsContainer: {
    height: "100%",
    padding: "1rem 2rem",
  },
  driverDetailsBlock: {
    margin: "0 0 1.5rem 0",
  },
  driverIndicationsBlock: {
    margin: "0 0 1rem 0",
  },
}));
