// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.discountCard-cssmodule-discountList-PAcbV{width:100%;padding:0;list-style:none}.discountCard-cssmodule-discountListItem-WRZK5{margin:0 0 0.5rem 0}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/ProfilePayment/DiscountCard/discountCard.cssmodule.scss"],"names":[],"mappings":"AAAA,2CACE,UAAW,CACX,SAAU,CACV,eAAgB,CACjB,+CAGC,mBAAoB","sourcesContent":[".discountList {\n  width: 100%;\n  padding: 0;\n  list-style: none;\n}\n\n.discountListItem {\n  margin: 0 0 0.5rem 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"discountList": `discountCard-cssmodule-discountList-PAcbV`,
	"discountListItem": `discountCard-cssmodule-discountListItem-WRZK5`
};
export default ___CSS_LOADER_EXPORT___;
