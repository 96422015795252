export const UNAUTHORIZED = "unauthorized" as const;

export const API_VERSION = {
  NEW: "2.0",
  OLD: "1.7",
} as const;

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
} as const;

export const API_ERROR_CODES = {
  USER_SUSPENDED: 179,
} as const;

export const HTTP_METHOD = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  PATCH: "PATCH",
};

export const TIMEOUT = 12000;

export const CONTENT_TYPE = "content-type";

export const CONTENT_TYPES = {
  JSON: "application/json",
};

export const APP_VERSION = "bookingzone:1.0.0";

export const USER_GROUP = "customer";

export const SIGNIN_PATH = "/signin";
