import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useContactCardStyles = makeStyles((theme: CustomTheme) => ({
  contactCardContainer: {
    padding: "1rem 1.5rem",
    borderRadius: "1rem",
  },
  contactMethodIcon: {
    display: "flex",
    alignItems: "center",

    margin: "0 0.75rem 0 0",
  },
  contactMethodTitle: {
    display: "flex",
    alignItems: "center",

    margin: "0 0 1.5rem 0",

    fontSize: "1.4rem",
    fontWeight: 500,
  },
  contactToCopy: {
    margin: "0 0 1rem 0",

    textAlign: "center",
  },
  copyBtn: {
    display: "block",

    margin: "auto",
    padding: "0.125rem 1rem",
    border: "1px solid black",

    fontWeight: 400,

    "&:hover": {
      cursor: "pointer",
    },
  },
  copyBtnLabel: {
    fontSize: "1rem",
  },
  link: {
    margin: "1rem 0 0 0",

    "&:hover": {
      color: theme.palette.info.main,

      cursor: "pointer",
    },
  },
  linkText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  newTabIcon: {
    color: theme.palette.info.main,
  },
}));
