import React, { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";

import {
  Button as MuiButton,
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
} from "@material-ui/core";

import dayjs from "dayjs";

import DateRange from "components/Icons/DateRange";

import { localSelector } from "./local";

import styles from "./input.cssmodule.scss";

// see example custom navbar for accessibility
// https://react-day-picker.js.org/examples/elements-navbar

/**
 * react-day-picker/DayPickerInput doesn't meet business requirements (e.g nbOfMonth)
 * so this is our custom day picker input component
 */
const dateToMonthLabel = (datetime) => dayjs(datetime).tz().format("MMMM YYYY");

export default function CustomDayPickerInput(props) {
  const intl = useIntl();
  // we use ref to manage focus after panel is closed
  const calendarRef = useRef(null);

  const {
    maxBookingDay,
    onFocus,
    onBlur,
    submitSelectedDays,
    close,
    handleContainerMouseDown,
    name,
    disabled,
    label,
    selectedDaysStr,
    errorText,
    getLang,
    onDayClick,
    selectedDays,
    open,
    disabledDays,
    fromMonth,
  } = props;

  const [currentMonth, setCurrentMonth] = useState(dayjs.tz());
  const audioLabel =
    intl.formatMessage(
      { id: "help.calendar" },
      {
        number: selectedDays.length,
      },
    ) +
    " " +
    selectedDaysStr;

  const toMonth = maxBookingDay
    ? new Date(dayjs(maxBookingDay).tz())
    : new Date(dayjs.tz().add(1, "y")); // here is for recurring booking, default is 1 year max for now

  const handleKeyPressed = (e, event) => {
    if (e.key === "Enter") {
      switch (event) {
        case "focus":
          onFocus();
          break;
        case "submit":
          submitSelectedDays();
          break;
        default:
          break;
      }
    }
  };
  // accessibility labels
  const previousMonth = intl.formatMessage(
    { id: "help.calendarMonth" },
    {
      month1: dateToMonthLabel(currentMonth),
      month2: dateToMonthLabel(dayjs(currentMonth).tz().add(-1, "month")),
    },
  );
  const nextMonth = intl.formatMessage(
    { id: "help.calendarMonth" },
    {
      month1: dateToMonthLabel(currentMonth),
      month2: dateToMonthLabel(dayjs(currentMonth).tz().add(1, "month")),
    },
  );

  return (
    <ClickAwayListener onClickAway={close}>
      <div
        ref={calendarRef}
        className={styles.dayPickerWrapper}
        onMouseDown={handleContainerMouseDown}
        onKeyPress={(e) => handleKeyPressed(e, "focus")}
        tabIndex={disabled ? -1 : 0}
        //ACCESSIBILITY
        role="combobox"
        aria-expanded={open}
      >
        <TextField
          required
          id="calendar-widget"
          type="datetime-local"
          autoComplete="off"
          variant="outlined"
          multiline
          name={name} // for tests targeting
          disabled={disabled}
          label={label}
          fullWidth
          onFocus={onFocus}
          onBlur={onBlur}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={disabled}
                  tabIndex={-1}
                  aria-hidden
                  onClick={onFocus}
                >
                  <DateRange color="grey" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={selectedDaysStr}
          helperText={errorText}
          error={!!(errorText && errorText.length > 0)}
          // ACCESSIBILITY
          inputProps={{
            tabIndex: -1, // to be able to access calendar properly
            autoComplete: "off",
            id: "calendar-field",
            "aria-controls": open && "calendar",
          }}
        />
        {open && (
          <Paper
            classes={{ root: styles.overlay }}
            className="MuiEngagementPaper--01"
            // ACCESSIBILITY
            id="calendar"
            aria-label={intl.formatMessage({ id: "form.error.select_date" })}
          >
            <DayPicker
              onDayClick={(day) => onDayClick(dayjs(day).tz().format(), false)}
              mode="single"
              labels={{ previousMonth, nextMonth }}
              selected={selectedDays}
              disabled={disabledDays}
              fromMonth={fromMonth}
              fixedWeeks
              onMonthChange={setCurrentMonth}
              toMonth={toMonth}
              locale={localSelector(getLang)}
            />

            <div
              className={styles.submit}
              onKeyPress={(e) => handleKeyPressed(e, "submit")}
            >
              <MuiButton
                onClick={() => {
                  close();
                  submitSelectedDays();
                  calendarRef.current.focus();
                }}
                aria-label={
                  intl.formatMessage({ id: "misc.validate" }) + audioLabel
                }
              >
                OK
              </MuiButton>
            </div>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
}
