import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { i18nVerboseDateFormat } from "utils/i18nDate";

import { useCancelRecurringDialogStyles } from "./cancelRecurringDialogStyles";

export function CancelRecurringDialog(props) {
  const { recurring, handleClose, cancelRecurringBooking } = props;

  const classes = useCancelRecurringDialogStyles();

  const limitDate = new Date(recurring.booking.pickup_time);

  return (
    <Dialog open onClose={handleClose}>
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <Typography component="h2">
          <FormattedMessage id="recurring.cancel.dialogTitle" />
        </Typography>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="recurring.cancel.dialogText"
            values={{
              offreRecurrence: recurring.offers[0].name,
              startDateRecurrence: i18nVerboseDateFormat(limitDate),
            }}
          />
        </DialogContentText>

        <Alert className={classes.infoBox} severity="info">
          <FormattedMessage id="recurring.cancel.dialogInfo" />
        </Alert>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          color="default"
          variant="contained"
          autoFocus
          onClick={() => handleClose()}
        >
          <FormattedMessage id={"cancel"} />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => {
            cancelRecurringBooking(recurring);
            handleClose();
          }}
        >
          <FormattedMessage id={"delete"} />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
