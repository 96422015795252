export type FixedLinesLineType = {
  code: string;
  color: string;
  text_color: string;
  destination: string;
};

export type FixedLinesCoordinates = {
  latitude: number;
  longitude: number;
};

export type FixedLinesStop = {
  name: string;
  position: FixedLinesCoordinates;
};

export type FixedLinesSection = {
  departure_stop: FixedLinesStop;
  arrival_stop: FixedLinesStop;
  departure_datetime: string;
  arrival_datetime: string;
  mode: string;
  line: FixedLinesLineType;
};

export type FixedLinesTrip = {
  departure_datetime: string;
  arrival_datetime: string;
  duration: number;
  sections: Array<FixedLinesSection>;
};

export const FIXED_LINES_MODE_WALK = "walking";
export const FIXED_LINES_MODE_BUS = "bus";
export const FIXED_LINES_MODE_METRO = "metro";
export const FIXED_LINES_MODE_TRAMWAY = "tramway";
export const FIXED_LINES_MODE_TRAIN = "train";
