import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import dayjs from "dayjs";
import { isEmpty, get } from "lodash";

import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Typography,
  Link,
} from "@material-ui/core";

import PaperBox from "components/PaperBox/PaperBox";
import SearchResultRideContainer from "containers/SearchResultRide/SearchResultRide.jsx";
import { TerritoryContext } from "contexts/TerritoryContext";

import { i18nVerboseDateFormat } from "utils/i18nDate";
import { capitalize } from "utils/strings";

import { useSearchResultGridStyles } from "./searchResultGridStyles";
import { JoinWaitingList } from "components/organisms/WaitingList/JoinWaitingList/JoinWaitingList";

const ERROR_CODE_BUSES_COMMITTED = 705;

// If component has prop "edition", we are in /reservation/{id}/edit:
function SearchResultGridContainer(props) {
  const {
    edition,
    isRequesting,
    isRequestingFixedLines,
    requestErrors,
    responses,
    selectedDays,
  } = props;
  const results = { ...responses, ...requestErrors };
  for (const [key, value] of Object.entries(responses)) {
    if ("fixed_lines_results" in value)
      results[key].fixed_lines_results = value.fixed_lines_results;
  }

  const [openDays, setOpenDays] = useState([]);
  const { selectedTerritory } = useContext(TerritoryContext);
  const classes = useSearchResultGridStyles();

  const buslineInfoUrl = selectedTerritory?.zone_infos?.busline_info_url;
  const waitingListEnabled = selectedTerritory?.waiting_list?.enabled || false;
  const waitingListInsertionDayLimit =
    selectedTerritory?.waiting_list?.insertion_day_limit || 0;

  // the next appropriate rideContainer should open
  // triggered when finished requesting OR when all rideContainers are closed:
  useEffect(() => {
    if (!isRequesting && isEmpty(openDays)) {
      const sortedDays = Object.keys(responses).sort((a, b) =>
        dayjs(a).diff(dayjs(b)),
      );

      const availableSortedDays = sortedDays.filter(
        (d) => !selectedDays.includes(d),
      );

      if (availableSortedDays.length > 0) {
        setOpenDays([availableSortedDays[0]]);
      }
    }
  }, [isRequesting, responses, selectedDays]);

  // openDays describe the state of opened ride containers, indexed by datetime:
  const toggle = (open, datetime) => {
    // opening: add the datetime
    if (open && !openDays.includes(datetime))
      setOpenDays([...openDays, datetime]);
    // closing: remove the datetime
    else if (!open && openDays.includes(datetime)) {
      const temp = [...openDays];

      temp.splice(temp.indexOf(datetime), 1);
      setOpenDays(temp);
    }
  };
  return (
    <PaperBox title={<FormattedMessage id="search.grid.title" />}>
      <div className={classes.resultGridWrapper}>
        {(isRequesting || isRequestingFixedLines) && (
          <div className={classes.loaderContainer}>
            <CircularProgress />

            <Typography>
              <FormattedMessage id="misc.loading" />
            </Typography>
          </div>
        )}

        {!isRequesting &&
          !isRequestingFixedLines &&
          Object.keys(results).length === 0 && (
            <Typography className={classes.errorMessage} variant="body1">
              <FormattedMessage id="search.no_result2" />
            </Typography>
          )}

        {!isRequesting &&
          !isRequestingFixedLines &&
          Object.keys(results)
            .sort((a, b) => dayjs(a).diff(dayjs(b)))
            .map((datetime) => {
              const item = results[datetime];
              const dayDiff = dayjs(datetime).diff(
                dayjs().startOf("day"),
                "day",
              );

              // case 1: item is an available ride
              if (
                get(item, "reservation_info", []).length === 0 &&
                get(item, "fixed_lines_results", []).length > 0 &&
                !isRequesting &&
                !isRequestingFixedLines
              ) {
                return (
                  <SearchResultRideContainer
                    edition={edition}
                    datetime={datetime}
                    ride={item}
                    isOpen={openDays.includes(datetime)}
                    toggle={(open) => toggle(open, datetime)}
                    key={`ride-${datetime}`}
                    onlyFixedLines
                  />
                );
              }
              if (
                (get(item, "reservation_info") ||
                  get(item, "fixed_lines_results", []).length > 0) &&
                !isRequesting &&
                !isRequestingFixedLines
              ) {
                return (
                  <SearchResultRideContainer
                    edition={edition}
                    datetime={datetime}
                    ride={item}
                    isOpen={openDays.includes(datetime)}
                    toggle={(open) => toggle(open, datetime)}
                    key={`ride-${datetime}`}
                  />
                );
              }

              // case 2: no ride this day (data is from requestErrors)
              return (
                <Card key={datetime} className={classes.noRideCard}>
                  <CardHeader
                    title={capitalize(i18nVerboseDateFormat(datetime))}
                    titleTypographyProps={{
                      variant: "h5",
                      align: "center",
                    }}
                  />

                  <CardContent className="flex flex-col gap-8 divide-y-2">
                    <Typography variant="body1" align="center">
                      {item.message}
                    </Typography>

                    {
                      // specific case of unbookable lines : "see more" button
                      item.code === 745 && buslineInfoUrl && (
                        <div className={classes.alternativesButton}>
                          <Link
                            style={{ paddingTop: "1rem" }}
                            underline="always"
                            href={buslineInfoUrl}
                            target={"_blank"}
                            aria-label="more information on unbookable lines - link"
                          >
                            <FormattedMessage id={"ride.alternatives"} />
                          </Link>
                        </div>
                      )
                    }
                    {item.code === ERROR_CODE_BUSES_COMMITTED &&
                      waitingListEnabled &&
                      dayDiff > waitingListInsertionDayLimit && (
                        <JoinWaitingList
                          className="p-8"
                          searchRequestId={item.searchRequestId}
                        />
                      )}
                  </CardContent>
                </Card>
              );
            })}
      </div>
    </PaperBox>
  );
}

const mapState = function (state) {
  return {
    requestErrors: state.search.requestErrors,
    responses: state.search.responses,
    isRequesting: state.search.isRequesting,
    isRequestingFixedLines: state.search.isRequestingFixedLines,
    // used for auto opening remaining SearchResultRideContainers.
    // Only a comparison on an array can trigger the hook
    selectedDays: Object.keys(state.search.selectedProposals),
  };
};

export default connect(mapState, null)(SearchResultGridContainer);
