import React from "react";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { formatGeocode } from "containers/Autocomplete/actions.js";
import { ListItem, Typography } from "@material-ui/core";
import Place from "components/Icons/Place";

function GeocodedSuggestion(props) {
  const { geocodes, onSelect, highlightedIndex } = props;

  if (!isEmpty(geocodes))
    return geocodes.map((geocode) => (
      <ListItem
        button
        key={geocode.place_id}
        name={"geocoded address"}
        alignItems="center"
        onMouseDown={() => {
          onSelect(formatGeocode(geocode));
        }}
        selected={0 === highlightedIndex}
        // ACCESSIBILITY
        role="option"
        id={"suggestion-0"}
        aria-selected={0 === highlightedIndex}
        tabIndex={-1}
      >
        <Place
          style={{ marginRight: "0.5em", width: "1rem", height: "1rem" }}
        />
        <div
          className="row-only aligned fullWidth"
          style={{ justifyContent: "space-between" }}
        >
          <Typography variant="body1">{geocode.address}</Typography>
        </div>
      </ListItem>
    ));

  return null;
}
const mapStateToProps = (state) => ({
  geocodes: state.autocomplete.geocodes,
  highlightedIndex: state.autocomplete.highlightedIndex,
});

export default connect(mapStateToProps)(GeocodedSuggestion);
