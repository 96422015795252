import * as React from "react";
import { CSSProperties } from "react";

import { Typography } from "@material-ui/core";

import { useBadgeStyles } from "./badgeStyles";

type BadgeProps = {
  iconAriaLabel?: string;
  iconElement?: JSX.Element;
  label?: JSX.Element | string | number;
  styleVariant?: CSSProperties;
};

export function Badge(props: BadgeProps) {
  const { iconAriaLabel, iconElement, label, styleVariant } = props;
  const classes = useBadgeStyles();

  return (
    <span className={classes.badgeContainer} style={styleVariant}>
      {iconElement && <span className={classes.badgeIcon}>{iconElement}</span>}
      {iconAriaLabel && <span className="visuallyHidden">{iconAriaLabel}</span>}
      <Typography className={classes.badgeLabel} component="span">
        {label}
      </Typography>
    </span>
  );
}
