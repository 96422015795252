import { makeStyles } from "@material-ui/core";

export const useSnackBarStyles = makeStyles(() => ({
  closeIcon: {
    width: "0.75rem",
    height: "0.75rem",

    fill: "white !important",
  },
  closeIconButton: {
    padding: "1.125rem 0.75rem",
  },
  severityIcon: {
    width: "2rem",
    height: "2rem",
    padding: "0 1rem 0 0",

    fill: "white !important",
  },
  snackBarContent: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    alignItems: "start",
  },
  snackBarMessage: {
    display: "flex",
  },
}));
