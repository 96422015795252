import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useCounterButtonsStyles = makeStyles((theme: CustomTheme) => ({
  buttonsContainer: {
    display: "flex",
    minWidth: "7rem",
  },
  counterButton: {
    width: "2rem",
    minWidth: "auto",
    height: "2rem",
    padding: "0.5rem",

    color: theme.palette.textColor,
    fontWeight: 400,

    background: theme.palette.primary.main,

    "&:hover": {
      color: theme.palette.textColor,

      background: theme.palette.primary.main,
    },

    "&.Mui-disabled": {
      color: theme.palette.textColor,

      // background: "rgba(0, 0, 0, 0.12)",
      background: theme.palette.primary.main,
      opacity: 0.5,
    },
  },
  counterContainer: {
    display: "flex",
    alignItems: "center",

    width: "100%",
    margin: "1rem 0",
  },
  counterLabel: {
    margin: "0 0 0 1rem",
  },
  extraInfoLabel: {
    fontSize: ".8rem",
    color: "#5C5C5C",
  },
  counterValue: {
    width: "2rem",
    margin: "0 0.5rem",

    textAlign: "center",
  },
}));
