import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withTheme } from "@material-ui/core/styles";

function CircleCheck(props) {
  return (
    <SvgIcon {...props}>
      <svg
        width="33"
        height="33"
        viewBox="0 0 50 50"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 0C25.6127 0 33 7.3873 33 16.5C33 25.6127 25.6127 33 16.5 33C7.3873 33 0 25.6127 0 16.5C0 7.3873 7.3873 0 16.5 0ZM16.5 1.5C8.21573 1.5 1.5 8.21573 1.5 16.5C1.5 24.7843 8.21573 31.5 16.5 31.5C24.7843 31.5 31.5 24.7843 31.5 16.5C31.5 8.21573 24.7843 1.5 16.5 1.5ZM26.361 9.93964C26.9015 10.4805 26.9429 11.3314 26.4852 11.9197L26.3604 12.061L15.3542 23.061C14.8135 23.6013 13.9631 23.6429 13.3747 23.1857L13.2335 23.061L7.73964 17.5702C7.15369 16.9846 7.15342 16.0349 7.73904 15.4489C8.27962 14.908 9.13045 14.8662 9.71902 15.3236L9.86036 15.4483L14.2935 19.878L24.2396 9.93904C24.8256 9.35342 25.7753 9.35369 26.361 9.93964Z"
        />
      </svg>
    </SvgIcon>
  );
}

export default withTheme(CircleCheck);
