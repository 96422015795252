import React, { useContext, useEffect, useState } from "react";

import api from "api";

import { NotificationPreferences } from "./NotificationPreferences";
import { UserContext } from "contexts/UserContext";

export interface INotificationPreference {
  id: number;
  notification_group_key: string;
  channels: { [key: string]: boolean };
}

export interface INotificationsError {
  notificationGroupKey: string;
  message: string;
  extra: { reason: string };
}

export function NotificationPreferencesContainer() {
  const { userProfile } = useContext(UserContext);
  const [notificationPreferences, setNotificationPreferences] = useState<
    INotificationPreference[] | undefined
  >(undefined);
  const [notificationsError, setNotificationsError] = useState<
    INotificationsError[]
  >([]);

  useEffect(() => {
    if (!notificationPreferences) {
      getNotificationPreferences();
    }
  }, []);

  const getNotificationPreferences = async () => {
    await api
      .getNotificationPreferences({ customerId: userProfile?.customerId })
      .then((response) => {
        setNotificationPreferences(response);
      });
  };

  const addNotificationPreference = async (notificationGroupKey, channels) => {
    await api
      .addNotificationPreferences({
        customerId: userProfile?.customerId,
        notification_group_key: notificationGroupKey,
        channels,
      })
      .then((response) => {
        if (notificationPreferences) {
          const currentNotificationPreferences = notificationPreferences.filter(
            (notificationPreference) =>
              notificationPreference.notification_group_key !==
              notificationGroupKey,
          );
          setNotificationPreferences(
            [...currentNotificationPreferences, response].sort((a, b) => {
              if (a.notification_group_key > b.notification_group_key) return 1;
              if (a.notification_group_key < b.notification_group_key)
                return -1;
              return 0;
            }),
          );
        }
        if (
          notificationsError.find(
            (error) => error.notificationGroupKey === notificationGroupKey,
          )
        ) {
          setNotificationsError(
            notificationsError.filter(
              (error) => error.notificationGroupKey !== notificationGroupKey,
            ),
          );
        }
      })
      .catch((error) => {
        setNotificationsError([
          ...notificationsError,
          {
            notificationGroupKey: notificationGroupKey,
            message: error.infos.message,
            extra: error.infos.extra,
          },
        ]);
      });
  };

  const updateNotificationPreference = async (
    notificationPreferencesId,
    notificationGroupKey,
    channels,
  ) => {
    await api
      .updateNotificationPreferences({
        customerId: userProfile?.customerId,
        notificationPreferenceId: notificationPreferencesId,
        notification_group_key: notificationGroupKey,
        channels,
      })
      .then((response) => {
        if (notificationPreferences) {
          const currentNotificationPreferences = notificationPreferences.filter(
            (notificationPreference) =>
              notificationPreference.notification_group_key !==
              notificationGroupKey,
          );
          setNotificationPreferences(
            [...currentNotificationPreferences, response].sort((a, b) => {
              if (a.notification_group_key > b.notification_group_key) return 1;
              if (a.notification_group_key < b.notification_group_key)
                return -1;
              return 0;
            }),
          );
        }
        if (
          notificationsError.find(
            (error) => error.notificationGroupKey === notificationGroupKey,
          )
        ) {
          setNotificationsError(
            notificationsError.filter(
              (error) => error.notificationGroupKey !== notificationGroupKey,
            ),
          );
        }
      })
      .catch((error) => {
        setNotificationsError([
          ...notificationsError,
          {
            notificationGroupKey: notificationGroupKey,
            message: error.infos.message,
            extra: error.infos.extra,
          },
        ]);
      });
  };

  const handleOnChange = (
    notificationGroupKey: string,
    channels: { [key: string]: boolean },
  ) => {
    const notificationPreference = notificationPreferences?.find(
      (notificationPreference) =>
        notificationPreference.notification_group_key === notificationGroupKey,
    );
    if (notificationPreference?.id) {
      updateNotificationPreference(
        notificationPreference.id,
        notificationGroupKey,
        channels,
      );
    } else {
      addNotificationPreference(notificationGroupKey, channels);
    }
  };

  return (
    <NotificationPreferences
      notificationPreferences={notificationPreferences}
      onChange={handleOnChange}
      errors={notificationsError}
    />
  );
}
