/**
 * Utility class to manage cookies
 */
const cookie = {
  /**
   * Set cookie
   *
   * @param string cname
   * @param string cvalue
   * @param number exdays
   */
  set: function (cname, cvalue, exdays) {
    const d = new Date();

    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);

    const expires = exdays === 0 ? "expires=0" : `expires=${d.toUTCString()}`;
    const path = "path=/";

    document.cookie = `${cname}=${cvalue}; ${expires}; ${path}`;
  },

  /**
   * Get cookie by name
   *
   * @param  string cname
   * @return string
   */
  get: function (cname) {
    const name = `${cname}=`;
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];

      while (c.charAt(0) === " ") c = c.substring(1);

      if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
    }

    return "";
  },

  erase: function (cname) {
    this.set(cname, "", -1);
  },
};

export default cookie;
