import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePhoneFieldStyles = makeStyles((theme: CustomTheme) => ({
  countryList: {
    ...theme.typography.body1,

    "&.country-list": {
      margin: 0,
    },
  },
  field: {
    margin: "10px 0",
  },
  phoneField: {
    "& .MuiFormControl-root": {
      margin: 0,
    },

    "& .MuiInputLabel-formControl": {
      transform: "translate(40px, 24px) scale(1)",

      "&[data-shrink='true']": {
        transform: "translate(0, 1.5px) scale(0.75)",
      },
    },

    "& .selected-flag": {
      height: "fit-content",
      margin: "34px 0 0 0",
      padding: 0,
    },
  },
}));
