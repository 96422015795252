import React, { useContext } from "react";
import dayjs from "dayjs";

import { ProductContext } from "contexts/ProductContext";

type DayJsProviderProps = {
  children?: React.ReactNode;
};

export const DayJsProvider: React.FC<DayJsProviderProps> = ({ children }) => {
  const { productParameters } = useContext(ProductContext);

  dayjs.tz.setDefault(productParameters?.user?.timezone || dayjs.tz.guess());

  return <>{children}</>;
};
