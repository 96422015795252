import React, { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { useLocation } from "react-router-dom";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Typography,
} from "@material-ui/core";

import { AccountInfos } from "organisms/AccountInfos/AccountInfos";
import { EmergencyContacts } from "organisms/EmergencyContacts/EmergencyContacts";
import { Credit as ProfilePaymentCredits } from "molecules/ProfilePayment/Credit/Credit";
import { DiscountCard as ProfilePaymentDiscountCard } from "molecules/ProfilePayment/DiscountCard/DiscountCard";
import { NotificationPreferencesContainer } from "../NotificationPreferences/NotificationPreferencesContainer";
import Favourites from "containers/FavoriteForm/FavoriteForm";
import PersonalData from "organisms/PersonalData/PersonalData";
import { Password } from "organisms/Password/Password";

import { ContactIcon } from "icons/ContactIcon";
import Credit from "components/Icons/Credit";
import ExpandMore from "components/Icons/ExpandMore";
import Lock from "components/Icons/Lock";
import Parameters from "components/Icons/Parameters";
import Star from "components/Icons/Star";
import UserCard from "components/Icons/UserCard";
import Wallet from "components/Icons/Wallet";
import { Notification } from "components/Icons/Notification";

import { ProductContext } from "contexts/ProductContext";

import { useProfileFormStyles } from "./profileFormStyles";
import { TerritoryContext } from "contexts/TerritoryContext";
import { connect } from "react-redux";
import { UserPasswordDataForUpdate } from "contexts/UserContext/types";
import { DiscountProfile } from "types/payment";
import { AccountInfosFormErrors, ProfileData } from "types/accountInfos";

const FAVOURITES_HASH = "#favoriteForm";
const CREDIT_MANAGER_HASH = "#wallet";

enum Panel {
  AccountInfos,
  Password,
  Contacts,
  CreditManager,
  Favourites,
  NotificationPreferences,
  PersonalData,
}

type ProfileFormProps = {
  availableDiscountProfiles: DiscountProfile[];
  balance: number;
  formErrors: AccountInfosFormErrors;
  passwordData: UserPasswordDataForUpdate;
  profileData: ProfileData;
  email: string;
  onFieldChange: (e: any) => void;
  ongoingImpersonation: boolean;
  onSubmit: (e: any) => void;
};

function ProfileForm(props: ProfileFormProps) {
  const {
    availableDiscountProfiles,
    balance,
    formErrors,
    passwordData,
    profileData,
    onFieldChange,
    ongoingImpersonation,
    onSubmit,
  } = props;

  const { selectedTerritory } = useContext(TerritoryContext);
  const { productParameters } = useContext(ProductContext);
  const [expandedPanel, setExpandedPanel] = useState<Panel | null>(null);
  const classes = useProfileFormStyles();

  const location = useLocation();

  const emergencyContactsEnabled =
    productParameters?.features?.call_customer?.enabled || false;

  const favoriteEnabled =
    productParameters?.features?.favorites?.enabled || false;

  const isCreditEnabled = selectedTerritory?.payment?.credit?.enabled;
  const hasPrivateDiscountProfiles = availableDiscountProfiles?.some(
    (discountProfile) => !discountProfile.is_public,
  );

  const isPaymentEnabled =
    isCreditEnabled || hasPrivateDiscountProfiles || false;

  const handleChange = (panel: Panel) => (_, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : null);
  };

  useEffect(() => {
    if (location.hash === FAVOURITES_HASH) {
      setExpandedPanel(Panel.Favourites);
    } else if (location.hash === CREDIT_MANAGER_HASH) {
      setExpandedPanel(Panel.CreditManager);
    }
  }, [location]);

  return (
    <>
      <div className={classes.userBanner}>
        <Typography className={classes.userFullName}>
          {profileData.firstName} {profileData.lastName}
        </Typography>

        <Typography className={classes.userEmail}>
          {profileData.email || props.email}
        </Typography>
      </div>

      <div className={classes.accordionsContainer}>
        <Accordion
          className={classes.accordion}
          expanded={expandedPanel === Panel.AccountInfos}
          onChange={handleChange(Panel.AccountInfos)}
          data-testid="profile-form-accordion-container"
        >
          <AccordionSummary
            className={classes.accordionHeader}
            id={`${Panel.AccountInfos}bh-header"`}
            expandIcon={<ExpandMore />}
            aria-controls="panel1bh-content"
          >
            <UserCard className={classes.accordionHeaderIcon} />

            <Typography className={classes.title} component="h2" variant="h3">
              <FormattedMessage id={"profile.informations"} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <AccountInfos
              formErrors={formErrors}
              profileData={profileData}
              onFieldChange={onFieldChange}
              ongoingImpersonation={ongoingImpersonation}
              onSubmit={onSubmit}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expandedPanel === Panel.Password}
          onChange={handleChange(Panel.Password)}
        >
          <AccordionSummary
            className={classes.accordionHeader}
            id={`${Panel.Password}bh-header`}
            expandIcon={<ExpandMore />}
            aria-controls={`${Panel.Password}bh-content`}
          >
            <Lock className={classes.accordionHeaderIcon} />

            <Typography className={classes.title} component="h2" variant="h3">
              <FormattedMessage id={"password"} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Password
              formErrors={formErrors}
              passwordData={passwordData}
              onFieldChange={onFieldChange}
              ongoingImpersonation={ongoingImpersonation}
              onSubmit={onSubmit}
            />
          </AccordionDetails>
        </Accordion>

        {emergencyContactsEnabled && (
          <Accordion
            className={classes.accordion}
            expanded={expandedPanel === Panel.Contacts}
            onChange={handleChange(Panel.Contacts)}
          >
            <AccordionSummary
              className={classes.accordionHeader}
              id={`${Panel.Contacts}bh-header`}
              expandIcon={<ExpandMore />}
              aria-controls={`${Panel.Contacts}bh-content`}
            >
              <ContactIcon className={classes.accordionHeaderIcon} />

              <Typography className={classes.title} component="h2" variant="h3">
                <FormattedMessage
                  id={"profile.associated-contacts"}
                  defaultMessage="Associated contacts"
                />
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <EmergencyContacts />
            </AccordionDetails>
          </Accordion>
        )}

        {isPaymentEnabled && (
          <Accordion
            className={classes.accordion}
            expanded={expandedPanel === Panel.CreditManager}
            onChange={handleChange(Panel.CreditManager)}
          >
            <AccordionSummary
              className={classes.accordionHeader}
              id={`${Panel.CreditManager}bh-header`}
              expandIcon={<ExpandMore />}
              aria-controls={`${Panel.CreditManager}bh-content`}
            >
              <Wallet className={classes.accordionHeaderIcon} />

              <Typography
                className={classes.title}
                component="h2"
                variant="h3"
                data-testid="payment-heading"
              >
                <FormattedMessage id={"payment.type"} />
              </Typography>

              {isCreditEnabled && (
                <Chip
                  className={classes.creditBadge}
                  label={
                    <FormattedMessage
                      id="credit.balanceChip"
                      values={{ balance }}
                    />
                  }
                  icon={<Credit />}
                />
              )}
            </AccordionSummary>

            {hasPrivateDiscountProfiles && (
              <ProfilePaymentDiscountCard
                availableDiscountProfiles={availableDiscountProfiles}
              />
            )}
            {isCreditEnabled && <ProfilePaymentCredits />}
          </Accordion>
        )}

        {favoriteEnabled && (
          <Accordion
            className={classes.accordion}
            expanded={expandedPanel === Panel.Favourites}
            onChange={handleChange(Panel.Favourites)}
          >
            <AccordionSummary
              className={classes.accordionHeader}
              id={`${Panel.Favourites}bh-header`}
              expandIcon={<ExpandMore />}
              aria-controls={`${Panel.Favourites}bh-content`}
            >
              <Star className={classes.accordionHeaderIcon} />

              <Typography className={classes.title} component="h2" variant="h3">
                <FormattedMessage id={"menu.favorite"} />
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Favourites />
            </AccordionDetails>
          </Accordion>
        )}

        <Accordion
          className={classes.accordion}
          expanded={expandedPanel === Panel.NotificationPreferences}
          onChange={handleChange(Panel.NotificationPreferences)}
        >
          <AccordionSummary
            className={classes.accordionHeader}
            id={`${Panel.NotificationPreferences}bh-header`}
            expandIcon={<ExpandMore />}
            aria-controls={`${Panel.NotificationPreferences}bh-content`}
          >
            <Notification className={classes.accordionHeaderIcon} />

            <Typography className={classes.title} component="h2" variant="h3">
              <FormattedMessage id={"menu.edit_notifications"} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails className={classes.accordionFlexColumn}>
            <Typography
              className={classes.subtitle}
              component="h3"
              variant="h4"
            >
              <FormattedMessage
                id={"LABEL_NOTIFICATIONS_CHANNEL_DESCRIPTION"}
              />
            </Typography>
            <NotificationPreferencesContainer />
          </AccordionDetails>
        </Accordion>

        <Accordion
          className={classes.accordion}
          expanded={expandedPanel === Panel.PersonalData}
          onChange={handleChange(Panel.PersonalData)}
        >
          <AccordionSummary
            className={classes.accordionHeader}
            id={`${Panel.PersonalData}bh-header`}
            expandIcon={<ExpandMore />}
            aria-controls={`${Panel.PersonalData}bh-content`}
          >
            <Parameters className={classes.accordionHeaderIcon} />

            <Typography className={classes.title} component="h2" variant="h3">
              <FormattedMessage id={"profile.dataManagement"} />
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <PersonalData
              profileData={profileData}
              onFieldChange={onFieldChange}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const credit = state.credit;

  return {
    availableDiscountProfiles: state.user.availableDiscountProfiles,
    balance: credit.balance,
  };
};

export default connect(mapStateToProps)(ProfileForm);
