import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ForgetPasswordEmailForm from "components/ForgetPasswordEmailForm/ForgetPasswordEmailForm.jsx";
import { onFieldChange, emailRequest } from "./actions.js";

/**
 * Sign in container
 */
export class ForgetPasswordEmailFormContainer extends React.Component {
  submit = (e) => {
    e.preventDefault();

    this.props.emailRequest(this.props.email);
  };

  fieldChange = (name, e) => {
    this.props.onFieldChange(name, e.target.value);
  };

  render() {
    return (
      <ForgetPasswordEmailForm
        intl={this.props.intl}
        email={this.props.email}
        onFieldChange={this.fieldChange}
        isSent={this.props.isSent}
        onSubmit={this.submit}
        error={this.props.error}
        message={this.props.message}
      />
    );
  }
}

const _mapState = (state) => ({
  email: state.forgetPassword.email,
  error: state.forgetPassword.error,
  isSent: state.forgetPassword.isSent,
  message: state.forgetPassword.message,
});

const _mapDispatch = (dispatch) => ({
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  emailRequest: (email) => {
    dispatch(emailRequest(email));
  },
});

export default connect(
  _mapState,
  _mapDispatch,
)(injectIntl(ForgetPasswordEmailFormContainer));
