import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";

type CheckboxGeneratorProps = {
  id: string;
  label: string | JSX.Element;
  name: string;
  value: string;
  disabled?: boolean;
  required: boolean | undefined;
  handleChange: (
    event: React.ChangeEvent<{ value: unknown; checked: unknown }>,
  ) => void;
  SetValue: (value: boolean) => void;
  dataTestId: string;
};

export const CheckboxGenerator = (props: CheckboxGeneratorProps) => {
  const {
    id,
    label,
    name,
    value,
    disabled = false,
    required,
    handleChange,
    SetValue,
    dataTestId,
  } = props;
  const extraOptions = {};

  if (value) {
    extraOptions["checked"] = value;
  }
  if (value === undefined || value === null) {
    SetValue(false);
  }

  return (
    <>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            id={id}
            name={name}
            required={required}
            disabled={disabled}
            {...extraOptions}
            data-testid={dataTestId}
            onChange={handleChange}
          />
        }
      />
    </>
  );
};
