import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useEditionViewStyles = makeStyles((theme: CustomTheme) => ({
  contentColumn: {
    margin: "0.5rem",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",

    width: "fit-content",
    margin: "0 auto",
    padding: "1rem 0.5rem",

    [theme.breakpoints.up("sm")]: {
      padding: "1rem",
    },

    [theme.breakpoints.up("md")]: {
      flexDirection: "row",

      padding: "2rem",
    },
  },
}));
