import React from "react";
import { connect } from "react-redux";

import SnackBar from "components/SnackBar/SnackBar.jsx";

import { CLOSE_SNACKBAR } from "./actions";

function SnackBarContainer(props) {
  const { close, debugMessage, message, open, severity } = props;

  // Debug message for CS:
  if (debugMessage) {
    console.debug("--- SnackBar debug message ---");
    console.debug(debugMessage);
  }

  return (
    <SnackBar
      open={open}
      message={message}
      severity={severity}
      close={close}
      debugMessage={debugMessage}
    />
  );
}

const _mapState = (state) => ({
  open: state.snackBar.open,
  message: state.snackBar.message,
  severity: state.snackBar.severity,
  debugMessage: state.snackBar.debugMessage,
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch({ type: CLOSE_SNACKBAR }),
});

export default connect(_mapState, mapDispatchToProps)(SnackBarContainer);
