import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useFooterStyles = makeStyles((theme: CustomTheme) => ({
  footer: {
    color: theme.palette.textColor,
    backgroundColor: theme.palette.primary.main,
  },
  navList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",

    maxWidth: theme.breakpoints.values.xl,
    margin: "auto",
    padding: "1rem 2rem",

    "& li": {
      margin: "0.5rem 1.5rem 0.75rem 0",

      listStyleType: "none",
    },

    "& a": {
      color: theme.palette.textColor,
    },

    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",

      "& li": {
        margin: "0 1.5rem 0 0",

        listStyleType: "disc",
      },

      "& li:first-child": {
        listStyleType: "none",
      },
    },
  },
  link: {
    color: theme.palette.textColor,
    cursor: "pointer",

    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
