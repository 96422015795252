import React, { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router";

import dayjs from "dayjs";

import {
  removeFixedLineProposal,
  removeProposal,
} from "containers/SearchForm/actions";
import { TerritoryContext } from "contexts/TerritoryContext";
import { Reservation } from "api/types/common";

import RideSummary from "components/RideSummary/RideSummary";
import FixedLinesRideSummary from "components/organisms/FixedLinesRideSummary/FixedLinesRideSummary";

type ChosenRideSummaryProps = {
  edition?: boolean;
  reservation?: Reservation;
  responses: any;
  selectedProposals: any;
  personalItemsCount: number;
  removeProposal: (trip: string) => void;
  removeFixedLineProposal: (trip: string) => void;
};

function ChosenRideSummary({
  edition,
  reservation,
  responses,
  selectedProposals,
  personalItemsCount,
  removeProposal,
  removeFixedLineProposal,
}: ChosenRideSummaryProps) {
  const { selectedTerritory } = useContext(TerritoryContext);
  const navigate = useNavigate();

  const recurringOffers =
    selectedTerritory?.booking?.recurring_bookings?.offers;

  const onDelete = (id) => {
    for (const datetime in selectedProposals) {
      if (selectedProposals[datetime] === id) {
        const nbSelectedProposals = Object.keys(selectedProposals).length;
        removeProposal(datetime);
        if (nbSelectedProposals === 1) {
          navigate("/search/result");
        }
      }
    }
  };

  const onDeleteFixedLine = (trip) => {
    for (const datetime in selectedProposals) {
      if (
        selectedProposals[datetime].departure_datetime ===
        trip.departure_datetime
      ) {
        removeFixedLineProposal(datetime);
      }
    }
  };

  return (
    <>
      {Object.keys(selectedProposals)
        .sort((a, b) => dayjs(a).diff(dayjs(b)))
        .map((datetime, i) => {
          if (typeof selectedProposals[datetime] !== "object") {
            const id = selectedProposals[datetime];
            const ride = responses[datetime];
            const booking = ride.reservation_info.proposed_datetimes.find(
              (b) => b.id === id,
            );
            return (
              <RideSummary
                recurringOffers={recurringOffers}
                personalItemsCount={
                  !edition
                    ? personalItemsCount
                    : reservation?.personalItems?.count
                }
                ride={ride}
                booking={
                  !edition
                    ? booking
                    : {
                        ...booking,
                        departure_position: reservation?.departure_position,
                        destination_position: reservation?.destination_position,
                        pickup_node: reservation?.pickup_node,
                        dropoff_node: reservation?.dropoff_node,
                      }
                }
                onDelete={onDelete}
                key={`proposal-${i}`}
              />
            );
          } else {
            const booking = selectedProposals[datetime];
            return (
              <FixedLinesRideSummary
                personalItemsCount={
                  !edition
                    ? personalItemsCount
                    : reservation?.personalItems?.count
                }
                booking={booking}
                onDelete={onDeleteFixedLine}
                key={`proposal-${i}`}
              />
            );
          }
        })}
    </>
  );
}

function mapStateToProps(state) {
  const { selectedProposals } = state.search;

  return {
    selectedProposals: { ...selectedProposals },
    responses: state.search.responses,
    personalItemsCount: state?.search?.searchForm?.personalItems?.count,
    // in edit mode
    reservation: state.reservation.reservation,
  };
}

const mapDispatch = (dispatch) => ({
  removeProposal: (id) => {
    dispatch(removeProposal(id));
  },
  removeFixedLineProposal: (trip) => {
    dispatch(removeFixedLineProposal(trip));
  },
});

export default connect(mapStateToProps, mapDispatch)(ChosenRideSummary);
