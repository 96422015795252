import React from "react";
import { ListItem, Radio } from "@material-ui/core";
import SavedIban from "components/Payment/SavedIban";
import { StripeIbanType } from "types/payment";
import { GREY_COLOR_HEX } from "utils/constants";

type SavedIbanListProps = {
  savedIBANs: StripeIbanType[];
  selectedIBANIndex: number;
  method: string;
  selectPaymentMethod: (method: string, index: number) => void;
  deletePaymentMethod: (method: string, id: number) => void;
  canBeSelected: boolean;
};

export function SavedIbanList(props: SavedIbanListProps) {
  const {
    savedIBANs = [],
    selectedIBANIndex,
    method,
    selectPaymentMethod,
    deletePaymentMethod,
    canBeSelected = true,
  } = props;

  return (
    <>
      {savedIBANs.map((iban, i) => (
        <ListItem
          key={`iban-${iban.id}`}
          id={`iban-${iban.id}`}
          className="flex-column flex w-full justify-between p-2 md:flex-row md:items-center md:justify-center md:space-x-0 md:space-y-2"
          button
          selected={canBeSelected ? selectedIBANIndex === i : undefined}
          onClick={() => {
            if (!canBeSelected) return;
            selectPaymentMethod(method, i);
          }}
        >
          {canBeSelected && (
            <Radio
              classes={{ root: "left" }}
              style={{ color: GREY_COLOR_HEX }}
              checked={selectedIBANIndex === i}
              inputProps={{ "aria-labelledby": `credit-iban-${iban.id}` }}
            />
          )}
          <SavedIban
            iban={iban}
            data-testid="delete-saved-iban"
            deleteIban={(id: number) => deletePaymentMethod(method, id)}
          />
        </ListItem>
      ))}
    </>
  );
}
