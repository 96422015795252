import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";

import Visibility from "components/Icons/Visibility";
import VisibilityOff from "components/Icons/VisibilityOff";

import { useResetPasswordStyles } from "./resetPasswordStyles";

export default function ResetPasswordForm(props) {
  const {
    error,
    isSent,
    onFieldChange,
    onSubmit,
    password,
    passwordConfirmation,
  } = props;

  const [showPasswords, setShowPasswords] = useState(false);

  const intl = useIntl();
  const classes = useResetPasswordStyles();

  const formTitle =
    typeof DISABLED_LOGIN === "undefined" || !DISABLED_LOGIN
      ? intl.formatMessage({
          id: "forgetPassword.resetPassword",
        })
      : "Reset password";
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        className={classes.resetPasswordContainer}
        component={Paper}
      >
        <Typography
          className={classes.resetPasswordTitle}
          component="h2"
          variant="h2"
        >
          {formTitle}
        </Typography>

        <form onSubmit={onSubmit}>
          <TextField
            className={classes.passwordInput}
            id="reset-new-password-1"
            required
            autoComplete="new-password"
            label={
              typeof DISABLED_LOGIN === "undefined" || !DISABLED_LOGIN
                ? intl.formatMessage({
                    id: "forgetPassword.newPassword",
                  })
                : "New password"
            }
            value={password || ""}
            type={showPasswords ? "text" : "password"}
            onChange={(e) => onFieldChange("password", e)}
            helperText={error?.password ? error?.password[0] : ""}
            error={Boolean(error?.password)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswords(!showPasswords)}
                    id="visibility reset password 1"
                    aria-label={intl.formatMessage({
                      id: "aria.show_all_passwords",
                    })}
                    aria-pressed={showPasswords}
                  >
                    {showPasswords ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            data-testid="reset-password-1"
          />

          <TextField
            className={classes.passwordInput}
            id="reset-new-password-2"
            required
            autoComplete="new-password"
            label={
              typeof DISABLED_LOGIN === "undefined" || !DISABLED_LOGIN
                ? intl.formatMessage({
                    id: "profile.repeat_new_password",
                  })
                : "Confirm new password"
            }
            value={passwordConfirmation || ""}
            type={showPasswords ? "text" : "password"}
            onChange={(e) => onFieldChange("passwordConfirmation", e)}
            helperText={
              error?.password_confirmation ? error.password_confirmation[0] : ""
            }
            error={Boolean(error?.password_confirmation)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPasswords(!showPasswords)}
                    id="visibility reset password 2"
                    aria-label={intl.formatMessage({
                      id: "aria.show_all_passwords",
                    })}
                    aria-pressed={showPasswords}
                  >
                    {showPasswords ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            data-testid="reset-password-2"
          />

          {error && (
            <Typography className={classes.errorMessage}>
              {error?.detail?.message || ""}
            </Typography>
          )}

          <div className={classes.submitButtonContainer}>
            <Button
              variant="contained"
              type="submit"
              aria-label={intl.formatMessage({
                id: "forgetPassword.resetPassword",
              })}
              color="primary"
              disabled={isSent}
              data-testid="reset-password-submit-btn"
            >
              {typeof DISABLED_LOGIN === "undefined" || !DISABLED_LOGIN
                ? intl.formatMessage({
                    id: "misc.send",
                    defaultMessage: "Send",
                  })
                : "Submit"}
            </Button>
          </div>
        </form>
      </Grid>
    </Grid>
  );
}
