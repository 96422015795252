import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Link, Typography } from "@material-ui/core";

import ActionInfo from "components/Icons/ActionInfo";
import { OpenNewTabIcon } from "icons/OpenNewTabIcon";

import { useSalesConditionsStyles } from "./salesConditionsStyles";

export function SalesConditions(props) {
  const classes = useSalesConditionsStyles();

  const { brand, gtcsURL } = props;

  return (
    <Typography className={classes.salesConditionsContainer} component="p">
      <ActionInfo className={classes.infoIcon} />

      <FormattedMessage
        id="payment.secure_payment"
        values={{ brand }}
        defaultMessage="{brand} use a safe payment solution."
      />
      {gtcsURL ? (
        <Link className={classes.gtcsLink} href={gtcsURL} target="_blank">
          <FormattedMessage id="payment.see_gtcs" defaultMessage="See GTCS." />
          <OpenNewTabIcon />
        </Link>
      ) : null}
    </Typography>
  );
}
