import React from "react";

import { Typography } from "@material-ui/core";

import { Price } from "atoms/Price/Price";

import { useSelectedDiscountListStyles } from "./selectedDiscountListStyles";

export function SelectedDiscountListItem({
  discountName,
  isDiscountScheme,
  quantity,
  price,
  discountValue,
}) {
  const classes = useSelectedDiscountListStyles();

  return (
    <li
      className={`${classes.discountListItem} ${
        isDiscountScheme ? classes.schemeColor : ""
      }`}
    >
      <Typography component="div" className={classes.discountName}>
        {discountName}
        &nbsp;
        {isDiscountScheme ? discountValue : <>×&nbsp;{quantity}</>}
      </Typography>

      <Typography component="div" className={classes.discountValue}>
        <Price value={price} />
      </Typography>

      <Typography
        component="div"
        className={classes.removeButtonContainer}
      ></Typography>
    </li>
  );
}
