import React from "react";
import { connect } from "react-redux";
import { ListItem, Typography } from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";
import AccountCircle from "components/Icons/AccountCircle";

function UserSuggestions(props) {
  const { users, onSelect, theme, highlightedIndex } = props;

  return _.map(users, (p, i) => (
    <ListItem
      button
      name={`user-${i}`}
      key={i}
      onMouseDown={() => onSelect(p)}
      className="predictionUser"
      selected={i === highlightedIndex}
      // ACCESSIBILITY
      role="option"
      id={"suggestion-" + i}
      aria-selected={i === highlightedIndex}
      tabIndex={-1}
    >
      <AccountCircle
        style={{ color: theme.palette.primary.dark, paddingRight: "1rem" }}
      />
      <div className="column ">
        <Typography variant="body1">
          {`${p.first_name} ${p.last_name}`}
        </Typography>
        {p.phone_number && (
          <Typography variant="body2">
            {decodeURIComponent(p.phone_number)}
          </Typography>
        )}
      </div>
    </ListItem>
  ));
}
const mapStateToProps = (state) => ({
  users: state.autocomplete.users,
  isRequestingUsers: state.autocomplete.isRequestingUsers,
  highlightedIndex: state.autocomplete.highlightedIndex,
});
export default withTheme(connect(mapStateToProps)(UserSuggestions));
