export const TEXT_CONTENTS_SV = {
  // BLOCK 1:
  "block1-h2": "",

  "block1-p1": "",
  "block1-p2": "",

  "block1-h3": "",

  "block1-p3": "",
  "block1-p4": "",
  "block1-p5": "",

  "block1-li1": "",
  "block1-li2": "",
  "block1-li3": "",
  "block1-li4": "",
  "block1-li5": "",

  // BLOCK 2:
  "block2-h2": "",

  "block2-p1": "",
  "block2-p2": "",

  "block2-h3": "",

  "block2-p3": "",

  // BLOCK 3:
  "block3-h2": "",

  "block3-p1a": "",
  "block3-p1b": "",

  "block3-li1": "",
  "block3-li2": "",

  "block3-h3": "",

  "block3-h4-1": "",

  "block3-p2": "",

  "block3-criterion-1-1": "",
  "block3-criterion-1-2": "",
  "block3-criterion-1-3": "",
  "block3-criterion-3-2": "",
  "block3-criterion-6-1": "",
  "block3-criterion-7-1": "",
  "block3-criterion-7-3": "",
  "block3-criterion-7-5": "",
  "block3-criterion-8-2": "",
  "block3-criterion-8-6": "",
  "block3-criterion-8-7": "",
  "block3-criterion-8-9": "",
  "block3-criterion-9-2": "",
  "block3-criterion-9-3": "",
  "block3-criterion-10-7": "",
  "block3-criterion-10-8": "",
  "block3-criterion-10-11": "",
  "block3-criterion-10-12": "",
  "block3-criterion-11-1": "",
  "block3-criterion-11-3": "",
  "block3-criterion-11-5": "",
  "block3-criterion-11-9": "",
  "block3-criterion-11-10": "",
  "block3-criterion-11-13": "",
  "block3-criterion-12-6": "",
  "block3-criterion-12-7": "",

  "block3-h4-2": "",

  "block3-h5-1": "",

  "block3-p-3a": "",
  "block3-p-3b": "",
  "block3-p-4": "",
  "block3-p-5": "",
  "block3-p-6": "",

  "block3-h5-2": "",

  "block3-p-7": "",
  "block3-p-8": "",
  "block3-p-9": "",
  "block3-p-10": "",

  "block3-h5-3": "",

  "block3-p-11": "",
  "block3-p-12": "",
  "block3-p-13": "",

  "block3-h4-3": "",

  "block3-p-14": "",

  // BLOCK 4:
  "block4-h2": "",

  "block4-p-1": "",

  "block4-h3-1": "",

  "block4-h3-2": "",

  "block4-p-2": "",

  "block4-h3-3": "",

  "block4-li-12": "",
  "block4-li-13": "",

  "block4-h3-4": "",

  "block4-li-14": "",
  "block4-li-15": "",
  "block4-li-16": "",
  "block4-li-17": "",
  "block4-li-18": "",
  "block4-li-19": "",

  // BLOCK 5:
  "block5-h2": "",

  "block5-p-1": "",
  "block5-p-2": "",

  "block5-h3": "",

  "block5-p-3": "",
  "block5-p-4": "",

  "block5-li-1": "",
  "block5-li-2": "",
  "block5-li-3": "",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Appendix",
};
