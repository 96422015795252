import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from "react-intl-phraseapp";

import { CircularProgress, Divider, Typography } from "@material-ui/core";

import { PaymentDiscounts } from "containers/Payment/PaymentDiscounts/PaymentDiscounts";
import { Price } from "atoms/Price/Price";

import { loadDiscountedPrices } from "containers/Payment/actions";

import { usePaymentResumeDetailsStyles } from "./paymentResumeDetailsStyles";
import { TerritoryContext } from "contexts/TerritoryContext";

type applicableDiscountInterface = {
  discount: number;
  discount_type: string;
  discount_value: number;
};

type stateInterface = {
  payment: {
    totalPrice: number;
    promoCode: string;
    discountedPrices: {
      price: number;
      promo_code_discount_price: number;
    };
    discountedPricesMultiDate: {
      [key: string]: {
        price: number;
        promo_code_discount_price: number;
      };
    };
    isDiscountPriceLoading: boolean;
    isPromoCodeValid: boolean;
    applicableDiscounts: [applicableDiscountInterface];
    selectedDiscounts: [];
  };
  search: {
    selectedProposals: [];
    responses: [];
  };
};

export function PaymentResumeDetails() {
  const dispatch = useDispatch();
  const classes = usePaymentResumeDetailsStyles();

  const { selectedTerritory } = useContext(TerritoryContext);
  const isMultiDateEnabled = selectedTerritory?.booking?.multi_date?.enabled;

  const totalPrice = useSelector(
    (state: stateInterface) => state?.payment?.totalPrice,
  );
  const promoCode = useSelector(
    (state: stateInterface) => state?.payment?.promoCode,
  );
  const discountedPricesState = useSelector(
    (state: stateInterface) => state?.payment?.discountedPrices,
  );
  const discountedPricesMultiDate = useSelector(
    (state: stateInterface) => state?.payment?.discountedPricesMultiDate,
  );
  const discountedPricesLoading = useSelector(
    (state: stateInterface) => state?.payment?.isDiscountPriceLoading,
  );
  const isPromoCodeValid = useSelector(
    (state: stateInterface) => state?.payment?.isPromoCodeValid,
  );
  const selectedProposals = useSelector(
    (state: stateInterface) => state?.search?.selectedProposals,
  );
  const responses = useSelector(
    (state: stateInterface) => state?.search?.responses,
  );
  const selectedDiscounts = useSelector(
    (state: stateInterface) => state?.payment?.selectedDiscounts,
  );

  const [finalTotalPrice, setFinalTotalPrice] = useState(0);

  const selectedProposalDateTime = Object.keys(selectedProposals)[0];
  const ride = responses[selectedProposalDateTime];
  const totalPassengers = ride?.reservation_info?.requested_seats || 0;
  const [nbBookings, setNbBookings] = useState(0);

  useEffect(() => {
    let nbBookings = 1;
    if (isMultiDateEnabled && discountedPricesMultiDate) {
      nbBookings = Object.keys(selectedProposals).length;
    }
    setNbBookings(nbBookings);
  }, [selectedProposals, discountedPricesMultiDate, isMultiDateEnabled]);

  useEffect(() => {
    let total = totalPrice || 0;

    const discountedPrices = discountedPricesState?.price || 0;
    const discountedMultiDateArray = Object.values(
      discountedPricesMultiDate || {},
    );
    const multiDateDiscountedPrices = discountedMultiDateArray?.reduce(
      (acc, value) => {
        return acc + value.price;
      },
      0,
    );

    if (discountedPrices > 0) {
      total = discountedPrices;
    }

    if (multiDateDiscountedPrices > 0) {
      total = multiDateDiscountedPrices;
    }

    setFinalTotalPrice(total);
  }, [
    isPromoCodeValid,
    totalPrice,
    discountedPricesState?.price,
    discountedPricesMultiDate,
    selectedDiscounts,
    nbBookings,
  ]);

  useEffect(() => {
    if (promoCode !== "") {
      dispatch(
        loadDiscountedPrices({
          selectedProposals: selectedProposals,
          responses: responses,
          promoCode: promoCode,
          isPromoCodeValid: isPromoCodeValid,
        }),
      );
    }
  }, [promoCode, isPromoCodeValid]);

  return (
    <div className={classes.paymentResumeDetailsContainer}>
      <Typography component="h2" variant="h3">
        <FormattedMessage id="payment.paymentDetails" />
      </Typography>

      <div className={classes.detailsList}>
        <PaymentDiscounts totalPassengers={totalPassengers} />
      </div>

      <Divider role="presentation" className={classes.divider} />

      <>
        {discountedPricesLoading ? (
          <CircularProgress />
        ) : (
          <div className={classes.totalPrice}>
            <Typography variant="h3">
              <FormattedMessage id="payment.payOffTotal" />
            </Typography>

            <Typography variant="h3">
              <Price value={finalTotalPrice} />
            </Typography>
          </div>
        )}
      </>
    </div>
  );
}
