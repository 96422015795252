import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Link, Typography } from "@material-ui/core";

import { useA11yDeclarationStyles } from "../a11yDeclarationStyles";

// Appendix created with text moved from block 3 in October 2022
// UK clients asked it in relation with the new WCAG 2.2
export function A11yDeclarationBlock6(props) {
  const classes = useA11yDeclarationStyles();

  const { textContents } = props;

  return (
    <>
      <Typography component="h3">{textContents["block6-h3"]}</Typography>

      <Typography component="h4">{textContents["block3-h4-2"]}</Typography>

      <Typography component="h5">{textContents["block3-h5-1"]}</Typography>

      <Typography component="p">
        {textContents["block3-p-3a"]}
        <Link
          className={classes.link}
          href="https://www.w3.org/TR/wai-aria-practices-1.1/#menu"
          target="_blank"
        >
          {textContents["block3-p-3b"]}
        </Link>
        .
      </Typography>

      <Typography component="p">{textContents["block3-p-4"]}</Typography>

      <Typography component="p">{textContents["block3-p-5"]}</Typography>

      <Typography component="p">{textContents["block3-p-6"]}</Typography>

      <Typography component="h5">{textContents["block3-h5-2"]}</Typography>

      <Typography component="p">{textContents["block3-p-7"]}</Typography>

      <Typography component="p">{textContents["block3-p-8"]}</Typography>

      <Typography component="p">{textContents["block3-p-9"]}</Typography>

      <Typography component="p">{textContents["block3-p-10"]}</Typography>

      <Typography component="h5">{textContents["block3-h5-3"]}</Typography>

      <Typography component="p">{textContents["block3-p-11"]}</Typography>

      <Typography component="p">{textContents["block3-p-12"]}</Typography>

      <Typography component="p">{textContents["block3-p-13"]}</Typography>
    </>
  );
}
