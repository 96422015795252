import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useDiscountsDialogStyles = makeStyles((theme: CustomTheme) => ({
  actionButton: {
    width: "calc(50% - 1rem)",

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 2rem)",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    padding: "1rem",

    [theme.breakpoints.up("sm")]: {
      padding: "2rem",
    },
  },
  alertMessage: {
    margin: "1rem",
    borderRadius: "1rem",

    [theme.breakpoints.up("sm")]: {
      margin: "2rem",
    },
  },
  dialogContainer: {
    padding: "1rem",
    overflowY: "scroll",

    "& .MuiDialog-paper": {
      margin: 0,
    },

    [theme.breakpoints.up("sm")]: {
      padding: "2rem",

      "& .MuiDialog-paper": {
        margin: "2rem",
      },
    },
  },
  dialogContent: {
    padding: "1rem 1rem 1.5rem 1rem",
    overflowY: "visible",

    [theme.breakpoints.up("sm")]: {
      padding: "1rem 2rem 1.5rem 2rem",
    },
  },
  dialogTitle: {
    padding: "0 1rem",

    wordWrap: "break-word",

    [theme.breakpoints.up("sm")]: {
      padding: "0 1.75rem",
    },
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",

    padding: "0 0 1.5rem 0",
  },
}));
