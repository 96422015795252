import { Stripe } from "@stripe/stripe-js";

export enum PaymentMethodAPIEnum {
  CB = "CB",
  CREDIT = "CREDIT",
  ONBOARD = "ONBOARD",
  PAYPAL = "PAYPAL",
  STRIPE = "STRIPE",
  CASH = "CASH",
}

export enum PaymentMethodEnum {
  CREDIT = "credit",
  ON_BOARD = "onboard",
  PAYPAL = "paypal",
  STRIPE_CARD = "card",
  STRIPE_SEPA = "sepa_debit",
}

export enum PaymentMethodProviderEnum {
  CREDIT = "credit",
  ON_BOARD = "onboard",
  PAYPAL = "paypal",
  STRIPE = "stripe",
}

export type PaymentMethodProvider =
  | PaymentMethodProviderEnum.CREDIT
  | PaymentMethodProviderEnum.ON_BOARD
  | PaymentMethodProviderEnum.PAYPAL
  | PaymentMethodProviderEnum.STRIPE;

export type PaymentMethod =
  | PaymentMethodEnum.CREDIT
  | PaymentMethodEnum.ON_BOARD
  | PaymentMethodEnum.PAYPAL
  | PaymentMethodEnum.STRIPE_CARD
  | PaymentMethodEnum.STRIPE_SEPA;

export type StripeIbanType = {
  id: number;
  name?: string;
  data: {
    last4: string;
    country: string;
    bank_code: string;
    branch_code: string;
  };
};

export type StripeCardType = {
  id: number;
  name?: string;
  data: {
    brand: string;
    last4: string;
    exp_year: number;
    exp_month: number;
  };
};

export type DiscountProfile = {
  id: number;
  is_public: boolean;
  external_identifier: string;
  name: string;
};

export type StripeFormProps = {
  method: string;
  totalPrice: number;
  isRequesting: boolean;
  pay: (
    stripe: Stripe | null,
    elements: any,
    totalPrice: number,
    shouldSavePaymentMethod: boolean,
    extraOptions: {
      name?: string;
    },
  ) => void;
  isFormOpen: boolean;
  stripeError: string;
};
