import React from "react";
import { useIntl } from "react-intl";

import { Button, Typography } from "@material-ui/core";

import { DEPARTURE, DESTINATION } from "components/SearchForm/SearchForm";

import { useTooltipSelectionStyles } from "./tooltipSelectionStyles";
import { BusIcon } from "icons/BusIcon";

export function TooltipSelection({ isRequesting, onAddressSelected, name }) {
  const intl = useIntl();
  const styles = useTooltipSelectionStyles();

  return (
    <div className={styles.root}>
      <Typography className={styles.title}>
        <BusIcon className={styles.busIcon} />
        {name}
      </Typography>
      <Button
        variant="contained"
        color="inherit"
        disabled={isRequesting}
        onClick={() => onAddressSelected(DEPARTURE)}
        className={`${styles.button} ${styles.buttonFrom}`}
      >
        <Typography variant="body1">
          {intl.formatMessage({ id: "search.go_from" })}
        </Typography>
      </Button>
      <Button
        variant="contained"
        color="inherit"
        disabled={isRequesting}
        onClick={() => {
          onAddressSelected(DESTINATION);
        }}
        className={`${styles.button} ${styles.buttonTo}`}
      >
        <Typography variant="body1">
          {intl.formatMessage({ id: "search.go_to" })}
        </Typography>
      </Button>
    </div>
  );
}
