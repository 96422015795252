export const updateCustomFields = (profile, payload) => {
  const customfieldStartingWith = "customfield|";
  const customFields = {};

  Object.keys(profile).forEach((key) => {
    if (key.startsWith(customfieldStartingWith)) {
      customFields[key.substring(customfieldStartingWith.length)] =
        profile[key];
      if (profile?.custom_fields) delete profile.custom_fields[key];
    }
  });

  (payload || profile).custom_fields = {
    ...profile.custom_fields,
    ...customFields,
  };
};
