import React, { useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";

import { CounterButtons } from "molecules/CounterButtons/CounterButtons";

import { changeBookingSeats } from "containers/ReservationForm/actions";

import { TerritoryContext } from "contexts/TerritoryContext";

import { extrasToGenericSeats } from "lib/genericSeatsFunctions";

import { useChangeSeatsDialogStyles } from "./changeSeatsDialogStyles";
import { PassengerProfiles } from "organisms/PassengerProfiles/PassengerProfiles";
import { get } from "lodash";

export function ChangeSeatsDialog(props) {
  const { onClose, reservation } = props;
  const intl = useIntl();

  // Get territory context
  const { selectedTerritory } = useContext(TerritoryContext);
  const dispatch = useDispatch();

  // Passenger profiles
  const passengersProfiles = useSelector(
    (state) => state?.passengersProfiles?.profiles,
  );
  const bookedProfilesIds = useSelector(
    (state) => state?.passengersProfiles?.bookedProfilesIds,
  );

  // Passenger on Database
  const classes = useChangeSeatsDialogStyles();

  const maxSeats = selectedTerritory?.booking?.max_seats_in_bus || 0;

  const extras = selectedTerritory?.extras || {};
  const extrasTypes = extrasToGenericSeats(extras);
  const maxExtras = extrasTypes.reduce((maxExtras, type) => {
    maxExtras[type] = get(extras, type + ".max_" + type + "_seats_in_bus", 0);
    return maxExtras;
  }, {});

  const reservationExtras = extrasTypes.reduce((reservationExtras, type) => {
    reservationExtras[type] = get(
      reservation,
      "extras_" + type + ".requested_" + type + "_seats",
      0,
    );
    return reservationExtras;
  }, {});

  const [seatError, setSeatError] = useState("");

  // Passenger number
  const [passengerNumber, setPassengerNumber] = useState({});
  useEffect(() => {
    const defaultData = {
      standard: reservation?.passengers_number || 0,
      ...reservationExtras,
    };
    setPassengerNumber(defaultData);
  }, [reservation?.passengers_number]);

  const [totalPassenger, setTotalPassenger] = useState(0);
  useEffect(() => {
    const total = Object.keys(passengerNumber).reduce(
      (acc, key) => acc + passengerNumber[key],
      0,
    );
    if (total === totalPassenger) return;
    setTotalPassenger(total);
  }, [passengerNumber]);
  // End of passenger number

  return (
    <Dialog
      className={classes.dialogContainer}
      open={true}
      onClose={onClose}
      id="dialog change seats"
      aria-labelledby="change-seats-dialog-title"
    >
      <DialogTitle
        className={classes.dialogTitle}
        id="change-seats-dialog-title"
        disableTypography
      >
        <Typography component="h2">
          {intl.formatMessage({ id: "misc.editPassengers" })}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <div>
          <PassengerProfiles
            maxAllPassengersCount={maxSeats}
            onChange={(value) => {
              setPassengerNumber({
                ...passengerNumber,
                standard: value,
              });
            }}
            passengerProfiles={bookedProfilesIds}
            handleErrors={(err) => {
              setSeatError(err);
            }}
          />

          {extrasTypes.map((type) => (
            <CounterButtons
              key={type}
              decButtonAriaLabel={intl.formatMessage(
                { id: "help.passengersSubstract" },
                {
                  number: passengerNumber[type] || 0,
                  type: intl.formatMessage({ id: "search.nb_standards" }),
                },
              )}
              defaultValue={passengerNumber[type]}
              incButtonAriaLabel={intl.formatMessage(
                { id: "help.passengersAdd" },
                {
                  number: passengerNumber[type] || 0,
                  type: intl.formatMessage({ id: "search.nb_standards" }),
                },
              )}
              label={extras[type]?.display || extras[type]?.name}
              minValue={0}
              maxValue={
                extras[type]?.requires_standard_seat
                  ? Math.min(
                      maxExtras[type],
                      maxSeats - totalPassenger + passengerNumber[type],
                    )
                  : maxExtras[type]
              }
              onChange={(value) => {
                setPassengerNumber({
                  ...passengerNumber,
                  [type]: value,
                });
              }}
              type={type}
            />
          ))}
        </div>

        {totalPassenger === 0 ? (
          <Typography color="error" variant="caption">
            {intl.formatMessage({ id: "search.error.nbPassengers" })}
          </Typography>
        ) : null}

        {totalPassenger === maxSeats ? (
          <Typography color="error" variant="caption">
            {intl.formatMessage({ id: "search.error.maxPassengers" })}
          </Typography>
        ) : null}
      </DialogContent>

      {seatError && (
        <Typography color="error" variant="caption">
          {seatError}
        </Typography>
      )}

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          <FormattedMessage id="misc.cancel" />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => {
            if (seatError) {
              return;
            }
            dispatch(
              changeBookingSeats({
                reservationId: reservation?.id,
                passengerNumber: passengerNumber,
                onSuccess: () => onClose(),
                selectedTerritoryKey: selectedTerritory.territory_key,
                passengersProfiles: passengersProfiles,
                bookedProfilesIds: bookedProfilesIds,
              }),
            );
          }}
        >
          <FormattedMessage id="misc.edit" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
