import {
  ON_FIELD_CHANGE,
  LOGIN_REQUEST_ERROR,
  REQUESTING,
  RESET_LOGIN,
} from "./actions.js";

const initialState = {
  username: "",
  password: "",
  requesting: false,
  error: false,
};

export const signin = (state = initialState, action) => {
  switch (action.type) {
    case ON_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case LOGIN_REQUEST_ERROR:
      return {
        // here we want to clean the state even with bad credentials
        ...initialState,
        requesting: false,
        error: action.error.infos,
      };
    case RESET_LOGIN:
      return initialState;
    case REQUESTING:
      return {
        ...state,
        requesting: true,
        error: false,
      };
    default:
      return state;
  }
};
