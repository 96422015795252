import * as React from "react";

import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@material-ui/lab";
import { useFixedLinesArrowStyles } from "./fixedLinesArrowStyles";

type FixedLinesArrowProps = {
  pickupTime: string;
  pickupName: string;
  dropoffTime: string;
  dropoffName: string;
};

export function FixedLinesArrow({
  pickupTime,
  dropoffTime,
  pickupName,
  dropoffName,
}: FixedLinesArrowProps) {
  const classes = useFixedLinesArrowStyles();

  return (
    <Timeline align="left" style={{ flex: 1 }}>
      <TimelineItem className={classes.arrow}>
        <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
        <TimelineSeparator>
          <TimelineDot className={classes.arrowDot} />
          <TimelineConnector className={classes.arrowBody} />
        </TimelineSeparator>
        <TimelineContent className={classes.arrowContent}>
          <span className={classes.time}>{pickupTime}</span>
          <span className={classes.nodeName}>{pickupName}</span>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem className={classes.arrow}>
        <TimelineOppositeContent style={{ flex: 0, padding: 0 }} />
        <TimelineSeparator>
          <TimelineDot className={classes.arrowHead} />
        </TimelineSeparator>
        <TimelineContent
          className={`${classes.arrowContent} ${classes.arrowLabelLast}`}
        >
          <span className={classes.time}>{dropoffTime}</span>
          <span className={classes.nodeName}>{dropoffName}</span>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}
