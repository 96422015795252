import { makeStyles } from "@material-ui/core";

export const useFixedLinesResultElementStyles = makeStyles(() => ({
  badgesContainer: {
    margin: "0.75rem 0 0 0",
  },
  card: {
    position: "relative",
    cursor: "pointer",
    border: "1px solid blue",
    margin: 0,
    padding: "1rem",
  },
}));
