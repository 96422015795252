import React, { ChangeEvent } from "react";

import { Grid, Switch, Typography } from "@material-ui/core";
import { FormattedMessage } from "react-intl-phraseapp";

type LabeledSwitchProps = {
  switchId: string;
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const LabeledSwitch = ({
  switchId,
  checked,
  onChange,
}: LabeledSwitchProps) => (
  <>
    <Grid item xs={12} sm={6}>
      <Typography component="p">
        <FormattedMessage id={`LABEL_${switchId}`} />
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6}>
      <Switch checked={checked} onChange={onChange} />
    </Grid>
  </>
);
