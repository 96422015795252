import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { connect } from "react-redux";

import { Button, Typography } from "@material-ui/core";

import RateRideDialog from "components/RateRideDialog/RateRideDialog";

import { rateRide } from "containers/RateRide/actions.js";

import { ProductContext } from "contexts/ProductContext";
import { TerritoryContext } from "contexts/TerritoryContext";

function RateRideContainer(props) {
  const { reservation, rateRide } = props;

  // if no feedback <=> not rated
  const feedback = reservation.user_feedback;

  const { productParameters } = useContext(ProductContext);
  const { selectedTerritory } = useContext(TerritoryContext);

  const [openDialog, setOpenDialog] = useState(false);

  const isRateDriverEnabled = productParameters?.feedback?.rate_driver?.enabled;
  const isRateRideEnabled = productParameters?.feedback?.rate_ride?.enabled;

  // build smth like ★★★★☆ in ascii string
  const ratingDisplay = (n) => {
    let display = "";
    for (var i = 0; i < 5; i++) {
      if (i < n) display += "★";
      else display += "☆";
    }
    return display;
  };
  return (
    <div>
      {
        /* !feedback <=> not rated */
        !feedback ? (
          <Button
            onClick={() => setOpenDialog(true)}
            variant="outlined"
            color="inherit"
          >
            <FormattedMessage id="reservation.rate.button" />
          </Button>
        ) : (
          <div className="column spaced">
            {
              // have to make this condition because it can be 0
              feedback.driver_rating !== null && (
                <div className="row-only aligned">
                  <div style={{ width: "7rem", textAlign: "center" }}>
                    <Typography>
                      <FormattedMessage id="ride.driver_name" />
                    </Typography>
                  </div>
                  <div style={{ width: "5rem", color: "#FFC300" }}>
                    {ratingDisplay(feedback.driver_rating)}
                  </div>
                </div>
              )
            }
            {feedback.service_rating !== null && (
              <div className="row-only aligned">
                <div style={{ width: "7rem", textAlign: "center" }}>
                  <Typography>
                    <FormattedMessage id="reservation.ride" />
                  </Typography>
                </div>
                <div style={{ width: "5rem", color: "#FFC300" }}>
                  {ratingDisplay(feedback.service_rating)}
                </div>
              </div>
            )}
          </div>
        )
      }
      {
        /* modal */
        openDialog && (
          <RateRideDialog
            reservation={reservation}
            isRateDriverEnabled={isRateDriverEnabled}
            isRateRideEnabled={isRateRideEnabled}
            onClose={() => setOpenDialog(false)}
            onRate={(r1, r2, c) =>
              rateRide(
                reservation.id,
                r1,
                r2,
                c,
                selectedTerritory?.recurring_services?.enabled || false,
                selectedTerritory.territory_key,
              )
            }
          />
        )
      }
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  rateRide: (id, r1, r2, c, recurringServicesEnabled, selectedTerritoryKey) => {
    dispatch(
      rateRide(id, r1, r2, c, recurringServicesEnabled, selectedTerritoryKey),
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(RateRideContainer);
