import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useFixedLinesArrowStyles = makeStyles((theme: CustomTheme) => ({
  arrow: {
    minHeight: 0,
  },
  arrowDot: {
    marginBottom: 0,
    marginTop: "0.5rem",
    boxShadow: "none",
  },
  arrowHead: {
    display: "block",
    borderRadius: 0,
    width: "0px",
    height: "0px",
    border: "1.2rem solid transparent",
    borderBottom: "none",
    borderLeft: "0.4rem solid transparent",
    borderRight: "0.4rem solid transparent",
    borderTopColor: theme.palette.grey[400],
    boxShadow: "none",
    backgroundColor: "transparent",
    padding: 0,
    marginTop: 0,
  },
  arrowBody: {
    height: "1rem",
  },
  arrowContent: {
    display: "inline-flex",
    gap: "1rem",
    fontSize: "1.125rem",
  },
  arrowLabelLast: {
    paddingTop: 0,
  },
  time: {
    fontWeight: 500,
  },
  nodeName: {
    display: "-webkit-box",

    overflow: "hidden",

    textAlign: "left",
    textTransform: "none",
    textOverflow: "ellipsis",
    lineClamp: 2,
    boxOrient: "vertical",
  },
}));
