import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export function TrashIcon(props) {
  return (
    <SvgIcon className={props.className}>
      <path
        d="M18.273 4.217c.401 0 .727.292.727.653 0 .36-.326.652-.727.652h-.01c-.08 0-.148.057-.151.13l-.524 12.678c-.038.926-.901 1.67-1.933 1.67h-9.31c-1.033 0-1.895-.745-1.933-1.67L3.888 5.653c-.003-.073-.068-.131-.15-.131h-.01C3.325 5.522 3 5.23 3 4.87c0-.36.326-.653.727-.653h3.546c.053 0 .194-.29.421-.868C7.938 2.585 8.803 2 9.688 2h2.624c.885 0 1.75.585 1.994 1.349.227.579.367.868.421.868h3.546zM10.418 7.87v8.87c0 .287.26.52.582.52.321 0 .582-.233.582-.52V7.87c0-.289-.26-.522-.582-.522-.321 0-.582.233-.582.522zm-3.2.015l.291 8.87c.01.287.278.514.599.506.321-.009.574-.25.564-.537l-.29-8.87c-.01-.288-.278-.514-.6-.506-.32.009-.573.249-.564.537zm6.4-.03l-.29 8.869c-.01.288.243.528.564.537.321.008.59-.219.599-.507l.29-8.87c.01-.287-.243-.527-.564-.536-.321-.008-.59.218-.599.506zm-4.54-3.638h3.844c.083 0 .13-.055.108-.123l-.123-.387c-.065-.204-.359-.403-.595-.403H9.688c-.236 0-.53.2-.595.403l-.123.387c-.022.069.026.123.108.123z"
        transform="translate(-1149 -685) translate(466 636) translate(683 49)"
      />
    </SvgIcon>
  );
}
