import React from "react";

import { SvgIcon } from "@material-ui/core";

export function EmailIcon() {
  return (
    <SvgIcon>
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <g>
            <path
              stroke="#151515"
              strokeWidth=".805"
              d="M1.5 0C.678 0 0 .677 0 1.499v11.49c0 .822.678 1.499 1.5 1.499h19c.822 0 1.5-.677 1.5-1.499V1.5C22 .677 21.322 0 20.5 0h-19z"
              transform="translate(1 5)"
            />
            <path
              fill="#000"
              d="M10.869 12.341c.384 0 .764-.044 1.143-.133.378-.09.678-.207.901-.352l-.208-.84c-.241.113-.528.203-.86.27-.333.067-.65.1-.954.1-1.15 0-2.015-.351-2.592-1.055-.577-.703-.837-1.699-.778-2.986.04-.847.219-1.6.537-2.256.318-.656.752-1.163 1.304-1.519.552-.356 1.18-.535 1.885-.535 1.129 0 1.981.341 2.557 1.023.575.682.832 1.658.77 2.927-.022.633-.147 1.144-.373 1.535-.227.391-.508.587-.844.587-.22 0-.38-.077-.48-.23-.1-.152-.132-.372-.095-.66l.279-3.152-.345-.235c-.37-.24-.815-.36-1.337-.36-.424 0-.819.126-1.184.376-.365.251-.668.61-.907 1.078-.24.467-.392 1-.458 1.6-.073.735.037 1.326.33 1.774.292.447.704.67 1.238.67.588 0 1.065-.254 1.43-.763.117.25.29.444.518.578.228.135.5.202.814.202.712 0 1.28-.274 1.701-.821.422-.547.653-1.286.693-2.217.04-.964-.104-1.81-.433-2.537-.328-.727-.823-1.286-1.485-1.677-.66-.391-1.45-.587-2.367-.587-.902 0-1.712.218-2.43.652-.718.435-1.285 1.05-1.702 1.847-.416.796-.647 1.696-.69 2.7-.044 1.047.101 1.948.435 2.703.335.754.841 1.325 1.521 1.713.68.387 1.502.58 2.466.58zm-.311-3.398c-.283 0-.488-.119-.615-.356-.126-.238-.17-.586-.128-1.044.07-.652.224-1.148.46-1.488.237-.34.57-.51.998-.51.153 0 .302.022.447.067l-.235 2.462c-.108.281-.244.496-.407.645-.164.15-.338.224-.52.224z"
              transform="translate(1 5)"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}
