import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import dayjs from "dayjs";

import { useTimePickerStyles } from "./timePickerStyles";

export const TimePicker = (props) => {
  const intl = useIntl();
  const classes = useTimePickerStyles();

  const {
    baseDatetime,
    defaultValue,
    disabled,
    error,
    helperText,
    onFieldChange,
  } = props;

  return (
    <TextField
      className={classes.timePicker}
      id="time"
      type="time"
      label={intl.formatMessage({ id: "search.time" })}
      defaultValue={defaultValue}
      disabled={disabled}
      error={error}
      helperText={helperText}
      required
      InputLabelProps={{
        className: classes.timePickerLabel,
      }}
      onChange={(e) => {
        const [hours, minutes] = e.target.value
          ? e.target.value.split(":")
          : defaultValue.split(":");
        const newDatetime = dayjs(baseDatetime)
          .tz()
          .hour(hours)
          .minute(minutes);

        if (newDatetime && newDatetime.isValid()) {
          onFieldChange(newDatetime);
        }
      }}
    />
  );
};
