import { snackMessage } from "containers/SnackBar/actions.js";
import api from "api";

export const ON_FIELD_CHANGE = "@@forgetPassword/ON_FIELD_CHANGE";
export const REQUEST_EMAIL = "@@forgetPassword/REQUEST_EMAIL";
export const REQUEST_RESET_PASSWORD = "@@forgetPassword/REQUEST_RESET_PASSWORD";
export const REQUEST_ERROR = "@@forgetPassword/REQUEST_ERROR";

export const onFieldChange = (field, value) => ({
  type: ON_FIELD_CHANGE,
  field,
  value,
});

export const requestEmail = (message) => ({
  type: REQUEST_EMAIL,
  message,
});

export const requestResetPassword = () => ({
  type: REQUEST_RESET_PASSWORD,
});

export const requestError = (error) => ({
  type: REQUEST_ERROR,
  error,
});

export const resetPasswordRequest =
  (navigate, password, passwordConfirmation, token) =>
  (dispatch, getState, getIntl) => {
    api
      .resetUserPassword({
        password: password,
        password_confirmation: passwordConfirmation,
        token: token,
      })
      .then(() => {
        dispatch(requestResetPassword());
        dispatch(
          snackMessage(
            "success",
            getIntl().formatMessage({
              id: "forgetPassword.newPasswordConfirmed",
            }),
          ),
        );

        navigate("/login");
      })
      .catch((error) => {
        dispatch(requestError(error));
      });
  };

export const emailRequest = (email) => (dispatch) => {
  api
    .resetUserPasswordEmail({ email: email })
    .then((json) => {
      dispatch(requestEmail(json.message));
    })
    .catch((error) => {
      dispatch(requestError(error));
    });
};
