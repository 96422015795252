import React from "react";
import styles from "./incompatibleBrowser.cssmodule.scss";
import { injectIntl } from "react-intl";
import { Link } from "react-router-dom";

export class IncompatibleBrowserView extends React.Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <h1 className={styles.text}>
          {"Ce site n'est pas disponible sur votre navigateur actuel"}
        </h1>
        <Link className={styles.forgotButton} to={"/forget-password-email"}>
          {this.props.intl.formatMessage({ id: "signin.forgetPassword" })}
        </Link>
      </div>
    );
  }
}

export default injectIntl(IncompatibleBrowserView);
