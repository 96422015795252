import React from "react";

import { SvgIcon } from "@material-ui/core";

export function PassengerIcon() {
  return (
    <SvgIcon>
      <path
        d="M9 1.038c-2.43 0-4.398 1.878-4.398 4.193l.003.167c.042 1 .453 1.93 1.134 2.647l.07.07-.03.01C2.822 8.923.691 11.516.691 14.547v3.106h16.616v-3.106l-.003-.178c-.08-2.893-2.1-5.357-4.903-6.194l-.212-.06.07-.07a4.077 4.077 0 0 0 1.138-2.815c0-2.315-1.969-4.193-4.398-4.193zm0 .932c1.89 0 3.42 1.46 3.42 3.261 0 1.132-.608 2.163-1.593 2.758l-1.16.7 1.363.156c3.01.344 5.3 2.788 5.3 5.703v3.106H1.67v-3.106c0-2.915 2.29-5.36 5.3-5.704l1.364-.156-1.161-.7c-.985-.594-1.594-1.625-1.594-2.757 0-1.8 1.532-3.26 3.421-3.26z"
        fill="#151515"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}
