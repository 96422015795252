import React, { useEffect, useState } from "react";

import { Button, Typography } from "@material-ui/core";

import { useCounterButtonsStyles } from "./counterButtonsStyles";

export function CounterButtons(props) {
  const {
    decButtonAriaLabel,
    defaultValue = 0,
    incButtonAriaLabel,
    label,
    extraInfoLabel = "",
    maxValue,
    minValue = 0,
    onChange,
    type,
  } = props;

  const [firstRender, setFirstRender] = useState(true);
  const [value, setValue] = useState(defaultValue);

  const classes = useCounterButtonsStyles();

  const decButtonDisabled = typeof minValue === "number" && value <= minValue;
  const incButtonDisabled = typeof maxValue === "number" && value >= maxValue;

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);

      return;
    }

    onChange(value);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={classes.counterContainer}>
      <div className={classes.buttonsContainer}>
        <Button
          className={classes.counterButton}
          disabled={decButtonDisabled}
          tabIndex={decButtonDisabled ? -1 : 0}
          aria-label={decButtonAriaLabel}
          onClick={() => setValue((previousValue) => previousValue - 1)}
          data-testid={`decrement-${type}`}
        >
          -
        </Button>

        <Typography
          className={classes.counterValue}
          data-testid={`count-${type}`}
        >
          {value}
        </Typography>

        <Button
          className={classes.counterButton}
          disabled={incButtonDisabled}
          tabIndex={incButtonDisabled ? -1 : 0}
          aria-label={incButtonAriaLabel}
          onClick={() => setValue((previousValue) => previousValue + 1)}
          data-testid={`increment-${type}`}
        >
          +
        </Button>
      </div>

      <Typography className={classes.counterLabel}>
        {label} <span className={classes.extraInfoLabel}>{extraInfoLabel}</span>
      </Typography>
    </div>
  );
}
