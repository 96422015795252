import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import { lookupProposition } from "containers/LookupProposition/actions";

export function LookupProposition() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const proposition_id = searchParams.get("proposition_id");

  useEffect(() => {
    dispatch(lookupProposition(navigate, proposition_id));
  }, []);

  return <div>Recherche en cours ...</div>;
}
