import {
  RESERVATIONS_FETCHED,
  WAITING_LISTS_FETCHED,
  PERSONAL_ITEMS_BOOKING_FETCHED,
} from "./actions.js";

const initialState = {
  waitingLists: null,
  upcoming: null,
  past: null,
  cancelled: null,
};

export const reservationList = (state = initialState, action) => {
  switch (action.type) {
    case RESERVATIONS_FETCHED:
      return {
        ...state,
        [action.resType]: action.reservations,
      };
    case WAITING_LISTS_FETCHED:
      return {
        ...state,
        waitingLists: action.waitingLists,
      };
    case PERSONAL_ITEMS_BOOKING_FETCHED:
      Object.keys(state).forEach((key) => {
        state[key].forEach((booking) => {
          if (booking.id == action.bookingId) {
            booking.personalItems = action.personalItems;
          }
        });
      });
      return state;
    default:
      return state;
  }
};
