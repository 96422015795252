import { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";

import dayjs from "dayjs";
import api from "api";

import { TerritoryContext } from "contexts/TerritoryContext";

import { StateInterface } from "components/organisms/PassengerProfiles/PassengerProfiles";

import {
  TIME_MODE_ARRIVAL,
  TIME_MODE_DEPARTURE,
  useDeepLink,
} from "hooks/useDeepLink";
import { FORMAT_HOUR } from "utils/constants";

const DEPARTURE = "departure";
const DESTINATION = "destination";
const ARRIVAL = "arrival";
const TIME = "time";
const STANDARD = "standard";
const ASAP = "asap";
const TIME_RESTRICTION_TYPE = "timeRestrictionType";

type Node = {
  id: number;
  name: string;
  index: number;
  district: string;
  is_bus_stop: boolean;
  is_bookable: boolean;
  status: string;
  position: {
    address: string;
    google_place_id: number | null;
    latitude: number;
    longitude: number;
    roadside_index: number;
  };
};

export const useSearchFormDeeplink = (
  maxSeats: number,
  actualizeDeparture: () => void,
  onDayClick: (d: dayjs.Dayjs) => void,
  onFieldChange: (s: string, value: any) => void,
  onPassengersChange: (s: string, n: number) => void,
  onAddressSelected: (
    name: string,
    id: number,
    field: string,
    longitude: number,
    latitude: number,
    address: string,
    place_id: number | null,
  ) => void,
  updateTotalPassenger: (value: number) => void,
) => {
  const { selectedTerritory, territories, changeSelectedTerritory } =
    useContext(TerritoryContext);
  const deeplinkParameters = useDeepLink();
  const formData = useSelector(
    (state: { search: { searchForm: { time: string } } }) =>
      state.search.searchForm,
  );

  const intl = useIntl();

  const [isDeeplinkDepartureValidate, setIsDeeplinkDepartureValidate] =
    useState(false);
  const [isDeeplinkDestinationValidate, setIsDeeplinkDestinationValidate] =
    useState(false);

  const [addressFormHelpers, setAddressFormHelpers] = useState({
    isDepartureDeeplinkHelper: "",
    isDestinationDeeplinkHelper: "",
  });

  const { isPassengerProfileLoading } = useSelector(
    (state: StateInterface) => ({
      isPassengerProfileLoading: state?.passengersProfiles?.isLoading,
    }),
  );

  // If number of seat requested is not in range, then it put 1 by default
  let deeplinkRequestedSeats = 0;
  if (deeplinkParameters.isDeepLink) {
    deeplinkRequestedSeats =
      !Number.isNaN(deeplinkParameters.optional.requestedSeats) &&
      deeplinkParameters.optional.requestedSeats <= maxSeats &&
      deeplinkParameters.optional.requestedSeats >= 0
        ? deeplinkParameters.optional.requestedSeats
        : 1;
  }

  const setPickersValues = useCallback((mode: string) => {
    actualizeDeparture();

    const requestedDayTime = dayjs(
      deeplinkParameters.optional.requestedTime,
    ).tz();
    onDayClick(dayjs().tz());
    onDayClick(requestedDayTime);

    onFieldChange(TIME, requestedDayTime);
    onFieldChange(ASAP, false);
    onFieldChange(TIME_RESTRICTION_TYPE, mode);
  }, []);

  const tryValidateAddress = useCallback(
    (mode: string, targetName: string, nodeList: Node[]) => {
      const targetNode = nodeList.find((gcode) => gcode.name === targetName);

      if (targetNode) {
        onAddressSelected(
          targetNode.name,
          targetNode.id,
          mode,
          targetNode.position.latitude,
          targetNode.position.longitude,
          targetNode.position.address,
          targetNode.position.google_place_id,
        );

        return true;
      }

      return false;
    },
    [],
  );

  const fetchNode = useCallback((value, territory) => {
    return api.getNodeList(null, {
      search: value,
      territory: territory,
    });
  }, []);

  // Pickers & redux store values setter
  useEffect(() => {
    if (!deeplinkParameters.isDeepLink) return;

    onFieldChange(DEPARTURE, {
      display: deeplinkParameters.required.departureAddress || "",
    });
    onFieldChange(DESTINATION, {
      display: deeplinkParameters.required.destinationAddress || "",
    });
    onPassengersChange(STANDARD, deeplinkRequestedSeats);

    if (deeplinkParameters.optional.timeMode === TIME_MODE_DEPARTURE) {
      setPickersValues(DEPARTURE);
    } else if (deeplinkParameters.optional.timeMode === TIME_MODE_ARRIVAL) {
      setPickersValues(ARRIVAL);
    } else {
      onFieldChange(ASAP, true);
    }
  }, [selectedTerritory]);

  // Auto select node if it match with fetched one
  useEffect(() => {
    if (!selectedTerritory || !deeplinkParameters.isDeepLink) return;

    if (!isDeeplinkDepartureValidate) {
      fetchNode(
        deeplinkParameters.required.departureAddress,
        selectedTerritory.territory_key,
      ).then((nodes) => {
        if (
          nodes &&
          tryValidateAddress(
            DEPARTURE,
            deeplinkParameters.required.departureAddress!,
            nodes.results,
          )
        ) {
          setIsDeeplinkDepartureValidate(true);
        } else {
          setAddressFormHelpers((old) => ({
            ...old,
            isDepartureDeeplinkHelper: intl.formatMessage({
              id: "search.error.stops_placeholder",
            }),
          }));
        }
      });
    }

    if (!isDeeplinkDestinationValidate) {
      fetchNode(
        deeplinkParameters.required.destinationAddress,
        selectedTerritory.territory_key,
      ).then((nodes) => {
        if (
          nodes &&
          tryValidateAddress(
            DESTINATION,
            deeplinkParameters.required.destinationAddress!,
            nodes.results,
          )
        ) {
          setIsDeeplinkDestinationValidate(true);
        } else {
          setAddressFormHelpers((old) => ({
            ...old,
            isDestinationDeeplinkHelper: intl.formatMessage({
              id: "search.error.stops_placeholder",
            }),
          }));
        }
      });
    }
  }, [selectedTerritory]);

  useEffect(() => {
    if (!deeplinkParameters.isDeepLink) return;

    if (isDeeplinkDestinationValidate) {
      setAddressFormHelpers((old) => ({
        ...old,
        isDestinationDeeplinkHelper: "",
      }));
    }

    if (isDeeplinkDepartureValidate) {
      setAddressFormHelpers((old) => ({
        ...old,
        isDepartureDeeplinkHelper: "",
      }));
    }
  }, [isDeeplinkDestinationValidate, isDeeplinkDepartureValidate]);

  // Only set the territory on mount
  useEffect(() => {
    if (!deeplinkParameters.isDeepLink) return;

    const existingTerritory = territories.find(
      (territory) =>
        territory.territory_key === deeplinkParameters.required.territory,
    );

    if (!existingTerritory) {
      console.warn(
        `Deeplink: Fail to found '${deeplinkParameters.required.territory}' as territory. Cached territory is used.`,
      );
    } else if (
      selectedTerritory?.territory_key !== existingTerritory.territory_key
    ) {
      if (deeplinkParameters.required.territory)
        changeSelectedTerritory(deeplinkParameters.required.territory);
    }
  }, []);

  useEffect(() => {
    if (!isPassengerProfileLoading && deeplinkParameters.isDeepLink) {
      onPassengersChange(STANDARD, deeplinkParameters.optional.requestedSeats);
      updateTotalPassenger(deeplinkRequestedSeats);
    }
  }, [isPassengerProfileLoading]);

  const timePickerDefaultValue = deeplinkParameters.isDeepLink
    ? dayjs(deeplinkParameters.optional.requestedTime).tz().format(FORMAT_HOUR)
    : dayjs(formData.time).format(FORMAT_HOUR) ||
      dayjs().tz().format(FORMAT_HOUR);

  return {
    deeplinkParameters,
    addressFormHelpers,
    timePickerDefaultValue,
    deeplinkRequestedSeats,
    setIsDeeplinkDepartureValidate,
    setIsDeeplinkDestinationValidate,
  };
};
