import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useIntl } from "react-intl";

import { settings } from "config/app";
import cookie from "lib/cookie.js";
import store from "store/store.js";

import { snackMessage } from "containers/SnackBar/actions";

import ProfileForm from "organisms/ProfileForm/ProfileForm";
import { UserContext } from "contexts/UserContext";
import { updateCustomFields } from "utils/updateCustomField";

const defaultPasswordData = {
  currentPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export function ProfileFormContainer(props) {
  const intl = useIntl();

  const {
    userProfile,
    updateUserProfile,
    updateUserProfileByAdmin,
    updateUserPassword,
  } = useContext(UserContext);

  const [profileData, setProfileData] = useState(userProfile);
  const [passwordData, setPasswordData] = useState(defaultPasswordData);
  const [formErrors, setFormErrors] = useState({});
  const [firstRender, setFirstRender] = useState(true);

  const ongoingImpersonation = props.ongoingImpersonation;

  const handleFieldChange = useCallback((field, value) => {
    if (field in defaultPasswordData) {
      setPasswordData((oldPasswordData) => ({
        ...oldPasswordData,
        [field]: value,
      }));
    } else {
      setProfileData((oldProfileData) => ({
        ...oldProfileData,
        [field]: value,
      }));
    }
  }, []);

  const updateProfile = useCallback(() => {
    updateCustomFields(profileData);
    return updateUserProfile(profileData);
  }, [profileData, updateUserProfile]);

  const updatePassword = useCallback(() => {
    updateUserPassword(passwordData)
      .then((json) => {
        if (json.token) {
          cookie.set(settings.cookieKeys.authCookie, json.token, 2);
        }
        store.dispatch(snackMessage("success", json.message));
        setFormErrors({});
      })
      .catch((errors) => {
        setFormErrors(errors.infos);
        if (errors?.infos?.detail?.message) {
          store.dispatch(snackMessage("error", errors.infos.detail.message));
        }
      });
  }, [passwordData, updateUserPassword]);

  const updateProfileAndPasswordByAdmin = useCallback(() => {
    // Update user profile by admin; add password field if password changed and valid
    const extraPasswordField =
      passwordData.newPassword.length > 0 &&
      passwordData.newPassword === passwordData.newPasswordConfirm
        ? { password: passwordData.newPassword }
        : {};

    updateCustomFields(profileData);
    return updateUserProfileByAdmin({
      ...profileData,
      ...extraPasswordField,
    });
  }, [passwordData, profileData, updateUserProfileByAdmin]);

  const handleSubmit = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
      }

      let passwordChange = false;
      let updateUserProfilePromise;

      if (ongoingImpersonation) {
        updateUserProfilePromise = updateProfileAndPasswordByAdmin();
      } else {
        updateUserProfilePromise = updateProfile();
        if (
          passwordData.currentPassword ||
          passwordData.newPassword ||
          passwordData.newPasswordConfirm
        ) {
          passwordChange = true;
          updatePassword(passwordData);
          setPasswordData(defaultPasswordData);
        }
      }

      updateUserProfilePromise
        .then(() => {
          if (!passwordChange) {
            store.dispatch(
              snackMessage(
                "success",
                intl.formatMessage({ id: "profile.updated" }),
              ),
            );
            setFormErrors({});
          }
        })
        .catch((errors) => {
          const fixedErrors = { ...errors };
          if (
            fixedErrors.phone_number &&
            !(fixedErrors.phone_number instanceof Array)
          )
            fixedErrors.phone_number = [fixedErrors.phone_number.message];

          if (fixedErrors.password) {
            fixedErrors.new_password = fixedErrors.password;
            fixedErrors.new_password_confirmation = fixedErrors.password;
          }

          if (fixedErrors?.detail?.message) {
            fixedErrors.custom_field = fixedErrors.detail.message;
          }

          setFormErrors(fixedErrors);

          return errors;
        });
    },
    [
      ongoingImpersonation,
      updateProfileAndPasswordByAdmin,
      updateProfile,
      passwordData,
      updatePassword,
      intl,
    ],
  );

  useEffect(() => {
    // Auto-submit if optInMarket field has changed (no submit button)
    if (firstRender) {
      // We don't auto-submit at first useEffect execution obviously
      setFirstRender(false);
    } else {
      handleSubmit();
    }
  }, [profileData.optInMarket]);

  return (
    <ProfileForm
      profileData={profileData}
      passwordData={passwordData}
      onFieldChange={handleFieldChange}
      onSubmit={handleSubmit}
      formErrors={formErrors}
      ongoingImpersonation={ongoingImpersonation}
    />
  );
}

const _mapState = (state) => {
  return {
    ongoingImpersonation: state.impersonateForm.sessionStarted,
  };
};

export default connect(_mapState)(ProfileFormContainer);
