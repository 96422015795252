import {
  ON_FIELD_CHANGE,
  FORM_ERRORS,
  SUBMITTING,
  ON_ADDRESS_SELECTED,
} from "./actions.js";

import { CLOSE_SESSION } from "containers/ImpersonateForm/actions.js";

import { PROFILE_FETCHED, FAVORITE_UPDATED } from "store/actions.js";

const initialState = {
  pickup: "",
  dropoff: "",
  errors: {
    home: "",
    office: "",
  },
  isRequesting: false,
  placeIds: { pickup: "", dropoff: "" },
};

export const favoriteForm = (state = initialState, action) => {
  switch (action.type) {
    case ON_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case PROFILE_FETCHED:
      return {
        ...state,
        pickup: action.profile.favorite?.home?.address || "",
        dropoff: action.profile.favorite?.office?.address || "",
        placeIds: {
          pickup: action.profile.favorite?.home?.google_place_id || "",
          dropoff: action.profile.favorite?.office?.google_place_id || "",
        },
      };
    case ON_ADDRESS_SELECTED:
      return {
        ...state,
        [action.addressType]: action.address,
        placeIds: {
          ...state.placeIds,
          [action.addressType]: action.placeId,
        },
      };
    case FORM_ERRORS:
      return {
        ...state,
        errors: action.errors,
        isRequesting: false,
      };
    case SUBMITTING:
      return {
        ...state,
        isRequesting: true,
        errors: false,
      };
    case FAVORITE_UPDATED:
      return {
        ...state,
        isRequesting: false,
      };
    case CLOSE_SESSION:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
