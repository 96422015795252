import React from "react";

import { SvgIcon } from "@material-ui/core";

export function ClockIcon() {
  return (
    <SvgIcon viewBox="0 0 26 26">
      <g fill="none" fillRule="evenodd">
        <g fill="#717587">
          <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1.25c-4.832 0-8.75 3.918-8.75 8.75s3.918 8.75 8.75 8.75 8.75-3.918 8.75-8.75S16.832 3.25 12 3.25zm0 2.083c.46 0 .833.373.833.834v5.832L17 12c.46 0 .833.373.833.833 0 .46-.373.834-.833.834h-5c-.46 0-.833-.373-.833-.834V6.167c0-.46.373-.834.833-.834z" />
        </g>
      </g>
    </SvgIcon>
  );
}
