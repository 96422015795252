import { makeStyles } from "@material-ui/core";

export const useEmergencyContactsStyles = makeStyles((theme) => ({
  contactButtonsContainer: {
    display: "grid",
    gridGap: "16px 48px",

    margin: "1.5rem 0 0 0",

    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  emergencyContactsContainer: {
    width: "100%",
  },
  sectionHeading: {
    margin: "8px 0 16px 0",

    fontWeight: 500,
  },
}));
