import { UserManager } from "oidc-client-ts";
import { settings } from "../../config/app/index";

/**
 * an object to manage the openid signin/signout
 */
export const userManager = new UserManager(settings.openIdConfig);

export const isOpenidActivated = Boolean(
  typeof OPENID_SERVER_URL !== "undefined" &&
    typeof OPENID_CLIENT_ID !== "undefined" &&
    OPENID_SERVER_URL !== "" &&
    OPENID_CLIENT_ID !== "",
);
