import React from "react";
import { useIntl } from "react-intl";

import SvgIcon from "@material-ui/core/SvgIcon";
import { withTheme } from "@material-ui/core/styles";

function Warning(props) {
  const intl = useIntl();
  const { theme } = props;

  return (
    <SvgIcon
      {...props}
      style={{ fill: theme.palette.warning.dark, ...props.style }}
      aria-label={intl.formatMessage({ id: "misc.warning" })}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 28 28"
      >
        <g fill="none" fillRule="evenodd">
          <g fill="#d0021b">
            <g>
              <g>
                <path
                  d="M15.117 2.165c.392.214.715.537.929.93l10.432 19.12c.617 1.132.2 2.55-.93 3.167-.344.187-.728.285-1.118.285H3.538c-1.288 0-2.333-1.045-2.333-2.334 0-.391.098-.776.286-1.12l10.46-19.12c.618-1.131 2.036-1.547 3.166-.928zm-2.071 1.371l-.072.116-10.46 19.121c-.093.172-.142.365-.142.56 0 .599.45 1.092 1.03 1.16l.136.007H24.43c.195 0 .387-.049.558-.143.526-.286.743-.918.524-1.46l-.058-.122L15.022 3.653c-.086-.157-.206-.291-.351-.394l-.114-.07c-.525-.288-1.173-.13-1.511.347zM14 17.5c.644 0 1.167.522 1.167 1.167 0 .644-.523 1.166-1.167 1.166s-1.167-.522-1.167-1.166c0-.645.523-1.167 1.167-1.167zm0-9.333c.598 0 1.091.356 1.159.816l.008.108v6.318c0 .51-.523.924-1.167.924-.598 0-1.091-.356-1.159-.816l-.008-.108V9.091c0-.51.523-.924 1.167-.924z"
                  transform="translate(-35.000000, -339.000000) translate(19.000000, 325.000000) translate(16.000000, 14.000000)"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default withTheme(Warning);
