export const TEXT_CONTENTS_IT = {
  // BLOCK 1:
  "block1-h2": "Accessibilità",

  "block1-p1": "Questa pagina non è una pagina di aiuto.",
  "block1-p2":
    'Ha lo scopo di presentare la politica di accessibilità del sito "{websiteURL}" e di definire il livello generale di accessibilità osservato sul sito secondo i regolamenti e le norme in vigore.',

  "block1-h3": "Cos'è l'accessibilità digitale?",

  "block1-p3":
    "L'accessibilità digitale è un insieme di regole e buone pratiche che coprono aspetti funzionali, grafici, tecnici ed editoriali.",
  "block1-p4":
    "Seguire queste regole e buone pratiche assicura che i media digitali (siti web, applicazioni mobili, documenti PDF, ecc.) siano accessibili alle persone con disabilità.",
  "block1-p5": "Un sito accessibile permette, per esempio, di:",

  "block1-li1":
    "Personalizzare la sua visualizzazione attraverso il sistema operativo e/o il browser (ingrandire o rimpicciolire i caratteri, cambiare la tipografia, cambiare i colori, fermare le animazioni, ecc.).",
  "block1-li2":
    "Navigare usando tecnologie assistive come il text-to-speech o il Braille.",
  "block1-li3":
    "Naviga senza usare il mouse, solo con la tastiera, interruttori o tramite un touch screen.",
  "block1-li4":
    "Visualizza contenuti video e audio con sottotitoli e/o trascrizioni.",
  "block1-li5": "Ecc.",

  // BLOCK 2:
  "block2-h2": "Dichiarazione di accessibilità",

  "block2-p1":
    "{brand} si impegna a rendere accessibili i propri siti web (internet, intranet ed extranet), le applicazioni mobili, i pacchetti software e l'arredo urbano digitale in conformità all'articolo 47 della legge n°2005-102 dell'11 febbraio 2005.",
  "block2-p2":
    'Questa dichiarazione di accessibilità si applica al sito "{websiteURL}".',

  "block2-h3": "Stato di conformità",

  "block2-p3":
    'Il sito "{websiteURL}" è parzialmente conforme a WCAG 2.1 (livello AA), e al {a11yOverallRate} conforme a RGAA versione 4.1, a causa delle non conformità e deviazioni elencate qui sotto.',

  // BLOCK 3:
  "block3-h2": "Risultati dei test",

  "block3-p1a":
    "C'è stato un audit di conformità alla versione 4.1 della RGAA effettuato nel {auditDate} dalla società ",
  "block3-p1b":
    " e poi un'auto-audit nel {autoAuditDate} dopo aver integrato le correzioni segnalate da Ideance. Questo autocontrollo effettuato da Padam Mobility rivela che sul campione:",

  "block3-li1":
    "Il tasso di conformità globale è del {a11yOverallRate}. Questo tasso si ottiene dividendo il numero di criteri conformi per il numero di criteri applicabili.",
  "block3-li2":
    "Il tasso medio di conformità è dell'{a11yAverageRate}. Questo tasso si ottiene facendo la media dei tassi di conformità di ogni pagina.",

  "block3-h3": "Contenuto non accessibile",

  "block3-h4-1": "Non conformità",

  "block3-p2": "Elenco dei criteri non conformi:",

  "block3-criterion-1-1":
    "1.1 Ogni immagine che porta informazioni ha un'alternativa testuale?",
  "block3-criterion-1-2": "",
  "block3-criterion-1-3": "",
  "block3-criterion-3-2":
    "3.2 In ogni pagina web, il contrasto tra il colore del testo e il colore del suo sfondo è sufficientemente alto (esclusi casi speciali)?",
  "block3-criterion-6-1": "",
  "block3-criterion-7-1":
    "7.1 Ogni script, se necessario, è compatibile con le tecnologie assistive?",
  "block3-criterion-7-3":
    "7.3 Ogni script è controllabile dalla tastiera e da qualsiasi dispositivo di puntamento (tranne in casi speciali)?",
  "block3-criterion-7-5":
    "7.5 In ogni pagina web, i messaggi di stato sono resi correttamente dalle tecnologie assistive?",
  "block3-criterion-8-2":
    "8.2 Per ogni pagina web, il codice sorgente generato è valido per il tipo di documento specificato (esclusi i casi speciali)?",
  "block3-criterion-8-6":
    "8.6 Per ogni pagina web con un titolo di pagina, il titolo è rilevante?",
  "block3-criterion-8-7":
    "8.7 In ogni pagina web, ogni cambiamento di lingua è indicato nel codice sorgente (tranne in casi speciali)?",
  "block3-criterion-8-9":
    "8.9 In ogni pagina web, i tag non dovrebbero essere usati solo per la presentazione. Questa regola è rispettata?",
  "block3-criterion-9-2": "",
  "block3-criterion-9-3":
    "9.3 Ogni lista su ogni pagina web è strutturata correttamente?",
  "block3-criterion-10-7":
    "10.7 In ogni pagina web, per ogni elemento che riceve il focus, la cattura del focus è visibile?",
  "block3-criterion-10-8":
    "10.8 Per ogni pagina web, il contenuto nascosto è destinato ad essere ignorato dalle tecnologie assistive?",
  "block3-criterion-10-11":
    "10.11 Per ogni pagina web, il contenuto può essere presentato senza usare lo scorrimento verticale per una finestra con un'altezza di 256px o lo scorrimento orizzontale per una finestra con una larghezza di 320px (esclusi casi speciali)?",
  "block3-criterion-10-12":
    "10.12 In ogni pagina web, le proprietà di spaziatura del testo possono essere definite dall'utente senza perdita di contenuto o funzionalità (tranne in casi speciali)?",
  "block3-criterion-11-1": "",
  "block3-criterion-11-3": "",
  "block3-criterion-11-5": "",
  "block3-criterion-11-9":
    "11.9 In ogni modulo, il titolo di ogni pulsante è rilevante (esclusi i casi speciali)?",
  "block3-criterion-11-10":
    "11.10 In ogni modulo, il controllo dell'input è usato in modo appropriato (esclusi i casi speciali)?",
  "block3-criterion-11-13":
    "11.13 Si può dedurre lo scopo di un campo di input per facilitare il riempimento automatico dei campi con i dati dell'utente?",
  "block3-criterion-12-6": "",
  "block3-criterion-12-7":
    "12.7 C'è un link di evitamento o di accesso rapido all'area di contenuto principale su ogni pagina web (esclusi i casi speciali)?",

  "block3-h4-2": "Deroghe per oneri sproporzionati",

  "block3-h5-1": "Menu di navigazione principale",

  "block3-p-3a":
    "Il menu principale di navigazione nella parte collegata del sito segue in parte il ",
  "block3-p-3b": 'design pattern ARIA "Menu o barra di menu"',
  "block3-p-4":
    "Questo non è ottimale perché questo modello di design non è destinato a questo tipo di menu di navigazione.",
  "block3-p-5":
    "Inoltre, la strutturazione HTML di questo menu ha qualche piccola imperfezione.",
  "block3-p-6":
    "Tuttavia, questa deroga è possibile perché questo menu è perfettamente utilizzabile con la sola tastiera e con uno screen reader.",

  "block3-h5-2": "Percorso breadcrumb",

  "block3-p-7":
    "A partire da una certa larghezza dello schermo (800px e meno), per ragioni funzionali e grafiche, si è scelto di non visualizzare il percorso del breadcrumb.",
  "block3-p-8": "Questo potrebbe invalidare il criterio 10.11.",
  "block3-p-9":
    "Tuttavia, questa deroga è possibile perché il breadcrumb trail è una caratteristica non essenziale per il corretto utilizzo del sito.",
  "block3-p-10":
    "Inoltre, e più importante, ci sono altri modi alternativi per sapere dove ti trovi nel sito e quindi tornare alle pagine viste in precedenza.",

  "block3-h5-3": 'Funzionalità "Orologio"',

  "block3-p-11":
    'La funzionalità "orologio", che permette di compilare i campi "tempo" nella pagina di ricerca del viaggio, per esempio, non è accessibile, in particolare con la sola tastiera e con uno screen reader.',
  "block3-p-12":
    'Tuttavia, questa deroga è possibile perché è possibile inserire questi campi "Tempo" a mano, senza necessariamente utilizzare questa funzionalità.',
  "block3-p-13": "Questa è un'alternativa accessibile.",

  "block3-h4-3": "Contenuto non soggetto all'obbligo di accessibilità",

  "block3-p-14":
    "Le mappe interattive di Google Maps nelle pagine dei dettagli della prenotazione.",

  // BLOCK 4:
  "block4-h2": "Preparazione di questa dichiarazione di accessibilità",

  "block4-p-1": "Questa dichiarazione è stata rilasciata il {autoAuditDate}.",

  "block4-h3-1": "Tecnologie utilizzate per creare il sito",

  "block4-h3-2": "Ambiente di prova",

  "block4-p-2":
    "I test sono stati condotti con le seguenti combinazioni di browser web e screen reader:",

  "block4-h3-3": "Strumenti per valutare l'accessibilità",

  "block4-li-12": "Strumenti di sviluppo per Firefox",
  "block4-li-13": "Web Developer (estensione Firefox)",

  "block4-h3-4":
    "Pagine del sito che sono state sottoposte al controllo di conformità",

  "block4-li-14": "Connessione",
  "block4-li-15": "Registrazione",
  "block4-li-16": "Ricerca di percorsi",
  "block4-li-17": "Percorsi suggeriti",
  "block4-li-18": "Prenotazione",
  "block4-li-19": "Impostazioni dell'account",

  // BLOCK 5:
  "block5-h2": "Feedback e contatto",

  "block5-p-1":
    "Se non siete in grado di accedere a qualsiasi contenuto o servizio su questo sito, potete contattarci per essere indirizzati a un'alternativa accessibile o per ottenere il contenuto in un'altro modo.",
  "block5-p-2": "Scrivici per posta al seguente indirizzo:",

  "block5-h3": "Rimedi",

  "block5-p-3": "Questa procedura deve essere utilizzata nel seguente caso.",
  "block5-p-4":
    "Hai notificato all'operatore del sito web una mancanza di accessibilità che ti impedisce di accedere ai contenuti o ai servizi e non hai ricevuto una risposta soddisfacente.",

  "block5-li-1": "Scrivi un messaggio al difensore dei diritti umani",
  "block5-li-2":
    "Contatta il delegato del difensore dei diritti umani nella tua regione",
  "block5-li-3":
    "Invia una lettera per posta (gratis, non mettere il francobollo) al seguente indirizzo:",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Appendix",
};
