import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import { IbanElement } from "@stripe/react-stripe-js";

import Interrogation from "components/Icons/Interrogation";
import { StripeElementStyle } from "@stripe/stripe-js";

type IbanProps = {
  stripeStyle: StripeElementStyle;
  handleChange: (e: any) => void;
  errorMessage: string;
  shouldSavePaymentMethod: boolean;
  setShouldSavePaymentMethod: (value: boolean) => void;
  setExtraOptions: (value: object) => void;
};

export function Iban(props: IbanProps) {
  const {
    stripeStyle,
    handleChange,
    errorMessage,
    shouldSavePaymentMethod,
    setShouldSavePaymentMethod,
    setExtraOptions,
  } = props;
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Paper
      className="rounded-lg p-3"
      style={{
        backgroundColor: "lightgrey",
      }}
    >
      <div
        className="row-only"
        style={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div className="stripeLabel" style={{ flexBasis: "30em" }}>
          <FormattedMessage id="PLACEHOLDER_IBAN_NUMBER" />

          <IbanElement
            onChange={(e) => handleChange(e)}
            options={{ style: stripeStyle, supportedCountries: ["SEPA"] }}
          />
        </div>
      </div>

      <div
        className="row-only"
        style={{
          justifyContent: "center",
          alignItems: "flex-end",
          width: "100%",
        }}
      >
        <div className="stripeLabel" style={{ flexBasis: "30em" }}>
          <FormattedMessage id="PLACEHOLDER_IBAN_NAME" />

          <input
            style={{
              width: "100%",
              margin: "0.5rem 0",
              padding: "0.5rem",
              borderRadius: "4px",
              border: `1px solid ${theme.palette.primary.main}`,
            }}
            type="text"
            onChange={(value) => setExtraOptions({ name: value })}
            placeholder={intl.formatMessage({ id: "PLACEHOLDER_IBAN_NAME" })}
          />
        </div>
      </div>

      {errorMessage && errorMessage.length > 0 && (
        <Typography
          style={{ padding: "1rem", color: theme.palette.error.main }}
          variant="caption"
        >
          {errorMessage}
        </Typography>
      )}

      <div
        className="row"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="row-only" style={{ alignItems: "center" }}>
          <Switch
            name="save Iban for future use"
            checked={shouldSavePaymentMethod}
            aria-label={intl.formatMessage({ id: "ACTION_SAVE_IBAN" })}
            inputProps={{ "aria-labelledby": "save-card" }}
            onChange={(e) => setShouldSavePaymentMethod(e.target.checked)}
          />

          <Typography id="save-card" variant="body2">
            <FormattedMessage id="ACTION_SAVE_IBAN" />
          </Typography>

          <Tooltip
            id="save-iban-2"
            title={<FormattedMessage id="TEXT_IBAN_PAYMENT_INFO" />}
            tabIndex={-1}
          >
            <IconButton tabIndex={-1} aria-labelledby="save-iban-2">
              <Interrogation tabIndex={-1} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
}
