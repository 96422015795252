import { makeStyles } from "@material-ui/core";

export const useFixedLinesTripTitleStyles = makeStyles(() => ({
  titleAlternative: {
    marginBottom: "0.5rem",
  },
  section: {
    display: "flex",
  },
  sectionInline: {
    display: "inline-flex",
  },
  sectionJoint: {
    marginRight: "10px",
  },
}));
