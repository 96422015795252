import React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button as MuiButton, Grid, Typography } from "@material-ui/core";

import { Error404 } from "icons/Error404";

import { useNotFoundViewStyles } from "./notFoundViewStyles";

export function NotFoundView() {
  const classes = useNotFoundViewStyles();

  return (
    <Grid container justifyContent="center" className={classes.contentWrapper}>
      <Grid item xs={12} sm={6} className={classes.contentBlock}>
        <div className={classes.textBlock}>
          <Typography component="h1">
            <FormattedMessage id="notFound.title" />
          </Typography>

          <Typography component="p">
            <FormattedMessage id="notFound.not_exist" />
          </Typography>

          <Typography component="p">
            <FormattedMessage id="notFound.not_exist2" />
          </Typography>

          <MuiButton
            className={classes.homeButton}
            color="primary"
            variant="contained"
            href={`${BASE_URL}search`}
            role="link"
          >
            <FormattedMessage id="notFound.back_home" />
          </MuiButton>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        sm={6}
        className={`${classes.contentBlock} ${classes.illustrationBlock}`}
      >
        <Error404 className={classes.notFoundIllustration} />
      </Grid>
    </Grid>
  );
}
