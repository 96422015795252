export function getContrastColor(hexColor: string) {
  // Convert hex to RGB
  let r = parseInt(hexColor.slice(1, 3), 16) / 255;
  let g = parseInt(hexColor.slice(3, 5), 16) / 255;
  let b = parseInt(hexColor.slice(5, 7), 16) / 255;

  // Apply gamma correction
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate relative luminance
  const l = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Choose text color based on luminance
  return l > 0.5 ? "#000000" : "#ffffff";
}

export function getMutedContrastColor(
  color: ReturnType<typeof getContrastColor>,
) {
  if (color === "#000000") {
    return "#333333";
  }
  if (color === "#ffffff") {
    return "#f2f2f2";
  }
}
