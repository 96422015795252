import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useNotFoundViewStyles = makeStyles((theme: CustomTheme) => ({
  contentBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    maxWidth: theme.breakpoints.values.xl / 2,
    height: "calc(100vh - 12rem)",
    maxHeight: "50rem",
    margin: "5rem 0 0 0",
    padding: "5rem 0 0 0",

    "& h1": {
      margin: "0 0 1.25rem 0",
      fontSize: "2rem",
      fontWeight: 500,
    },

    "& p": {
      margin: "0 0 0.5rem 0",
      fontSize: "1.5rem",
      lineHeight: 1.25,
    },

    [theme.breakpoints.up("sm")]: {
      height: "calc(100vh - 5rem)",

      "& p": {
        lineHeight: "normal",
      },
    },
  },
  contentWrapper: {
    padding: "0 2rem",
    background: `linear-gradient(${theme.palette.primary.main} 5rem, #f2f2f2 5rem)`,

    [theme.breakpoints.up("sm")]: {
      padding: "0 4rem",
    },
  },
  homeButton: {
    margin: "2rem 0",

    [theme.breakpoints.up("sm")]: {
      margin: "3rem 0",
    },
  },
  illustrationBlock: {
    height: "fit-content",
    margin: "1rem 0 5rem 0",
    padding: "0",

    [theme.breakpoints.up("sm")]: {
      height: "auto",
      margin: "5rem 0 0 0",
    },
  },
  notFoundIllustration: {
    width: "80%",
    height: "80%",
  },
  textBlock: {
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      textAlign: "left",
    },
  },
}));
