// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signinForm-cssmodule-login__disable-WWN7N{height:20vh;display:flex;align-items:center;text-align:center;line-height:20px}.signinForm-cssmodule-login__disable__register-REkvb{font-weight:600;text-decoration:underline}.signinForm-cssmodule-form-o9HmX{height:100%}.signinForm-cssmodule-error-w18vF{margin-top:20px}.signinForm-cssmodule-loginButton-slFvu,.signinForm-cssmodule-forgotButton-L2ZaQ{padding:0.5rem}.signinForm-cssmodule-loginConfirm-stTmf{width:auto !important}.signinForm-cssmodule-loginConfirm-stTmf:hover,.signinForm-cssmodule-loginButton-slFvu:hover,.signinForm-cssmodule-forgotButton-L2ZaQ:hover,.signinForm-cssmodule-loginConfirm-stTmf:focus,.signinForm-cssmodule-loginButton-slFvu:focus,.signinForm-cssmodule-forgotButton-L2ZaQ:focus{color:black}.signinForm-cssmodule-link-ldcs9{color:#0869af;text-decoration:underline}
`, "",{"version":3,"sources":["webpack://./src/components/SignInForm/signinForm.cssmodule.scss"],"names":[],"mappings":"AACE,2CACE,WAAY,CACZ,YAAa,CACb,kBAAmB,CACnB,iBAAkB,CAClB,gBAAiB,CAEjB,qDACE,eAAgB,CAChB,yBAA0B,CAC3B,iCAKH,WAAY,CACb,kCAGC,eAAgB,CACjB,iFAIC,cAAe,CAChB,yCAGC,qBAAsB,CACvB,wRAOC,WAAY,CACb,iCAGC,aAAc,CACd,yBAA0B","sourcesContent":[".login {\n  &__disable {\n    height: 20vh;\n    display: flex;\n    align-items: center;\n    text-align: center;\n    line-height: 20px;\n\n    &__register {\n      font-weight: 600;\n      text-decoration: underline;\n    }\n  }\n}\n\n.form {\n  height: 100%;\n}\n\n.error {\n  margin-top: 20px;\n}\n\n.loginButton,\n.forgotButton {\n  padding: 0.5rem;\n}\n\n.loginConfirm {\n  width: auto !important;\n}\n.loginConfirm:hover,\n.loginButton:hover,\n.forgotButton:hover,\n.loginConfirm:focus,\n.loginButton:focus,\n.forgotButton:focus {\n  color: black;\n}\n\n.link {\n  color: #0869af; // TODO: use theme.palette.info.main when creating style hook\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login__disable": `signinForm-cssmodule-login__disable-WWN7N`,
	"login__disable__register": `signinForm-cssmodule-login__disable__register-REkvb`,
	"form": `signinForm-cssmodule-form-o9HmX`,
	"error": `signinForm-cssmodule-error-w18vF`,
	"loginButton": `signinForm-cssmodule-loginButton-slFvu`,
	"forgotButton": `signinForm-cssmodule-forgotButton-L2ZaQ`,
	"loginConfirm": `signinForm-cssmodule-loginConfirm-stTmf`,
	"link": `signinForm-cssmodule-link-ldcs9`
};
export default ___CSS_LOADER_EXPORT___;
