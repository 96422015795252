import React, { useCallback, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Checkbox,
  Typography,
  Button as MuiButton,
  Paper,
  FormControlLabel,
  IconButton,
  Link,
} from "@material-ui/core";

import { CernCaptcha } from "components/organisms/CernCaptcha/CernCaptcha";
import { PhoneField } from "components/PhoneField/PhoneField";
import Visibility from "components/Icons/Visibility";
import VisibilityOff from "components/Icons/VisibilityOff";

import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";
import { ProductContext } from "contexts/ProductContext";

import styles from "./signupForm.cssmodule.scss";
import { OpenNewTabIcon } from "icons/OpenNewTabIcon";
import { SignUpFormCustomFields } from "./SignUpFormCustomFields";

export default function SignUpForm(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { showAuthModal } = useContext(AuthModalsContext);
  const { productParameters } = useContext(ProductContext);
  const [showPasswords, setShowPasswords] = useState(false);
  const [captchaAnswer, setCaptchaAnswer] = useState(undefined);

  const optInMessage = productParameters?.user?.opt_in_market_message;
  const gdprMessage = productParameters?.user?.terms_acceptance_message;
  const termsAge = productParameters?.user?.signup_age_restriction || {};

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      props.submit(captchaAnswer);
    },
    [captchaAnswer, props],
  );

  const getGdprMessageToDisplay = () => {
    if (gdprMessage) return gdprMessage;

    if (termsAge.enabled === true)
      return (
        intl.formatMessage({
          id: "terms.bodyAge",
          values: { age: termsAge.value },
        }) + " *"
      );

    return (
      intl.formatMessage({
        id: "terms.body",
        values: { age: termsAge.value },
      }) + " *"
    );
  };

  const gdprMessageToDisplay = getGdprMessageToDisplay();
  const optInMessageToDisplay =
    optInMessage ||
    intl.formatMessage(
      { id: "terms.commercial" },
      { brand: props.regional.brand },
    );

  return (
    <Paper
      className="MuiEngagementPaper--01"
      style={{
        padding: "1rem",
        zIndex: 1,
        overflowY: "auto",
      }}
    >
      <form className="column aligned" onSubmit={handleSubmit}>
        <div className={styles.logoContainer}>
          <img
            className={styles.territoryLogo}
            src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
              DEFAULT_TERRITORY || PRODUCT
            }_color.png`}
            alt={props.regional.brand}
          />
        </div>

        <Typography
          className="padded"
          variant="caption"
          component="p"
          align="center"
        >
          {intl.formatMessage({ id: "help.fieldsRequired" })}
        </Typography>

        <div
          className="row"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div className="field">
            <TextField
              id="signup-firstname"
              required
              autoComplete="given-name"
              label={props.intl.formatMessage({ id: "signup.firstname" })}
              fullWidth={true}
              value={props.firstname}
              onChange={(e) => props.fieldChange("firstname", e)}
              helperText={props.error.first_name}
              error={props.error.first_name.length > 0}
              data-testid="signup-firstname"
            />
          </div>
          <div className="field">
            <TextField
              id="signup-lastname"
              autoComplete="family-name"
              required
              className="field"
              label={props.intl.formatMessage({ id: "signup.lastname" })}
              fullWidth={true}
              value={props.lastname}
              onChange={(e) => props.fieldChange("lastname", e)}
              helperText={props.error.last_name}
              error={props.error.last_name.length > 0}
              data-testid="signup-lastname"
            />
          </div>
        </div>
        <div
          className="row"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div className="field" data-testid="signup-phone">
            <PhoneField
              id="signup-tel"
              required
              autoComplete="tel"
              label={props.intl.formatMessage({
                id: "signup.phoneNumber",
              })}
              fullWidth={true}
              value={props.phoneNumber}
              type="tel"
              onChange={(e) => props.fieldChange("phoneNumber", e)}
              helperText={props.error.phone_number}
              error={props.error.phone_number.length > 0}
            />
          </div>
          <div className="field">
            <TextField
              id="signup-mail"
              required
              autoComplete="email"
              label={props.intl.formatMessage({ id: "placeholder.email" })}
              fullWidth={true}
              type="email"
              value={props.email}
              onChange={(e) => props.fieldChange("email", e)}
              helperText={props.error.email}
              error={props.error.email.length > 0}
              data-testid="signup-email"
            />
          </div>
        </div>
        <div
          className="row"
          style={{ width: "100%", justifyContent: "space-around" }}
        >
          <div className="field">
            <TextField
              id="signup-password-1"
              autoComplete="new-password"
              required
              label={props.intl.formatMessage({
                id: "password.withRequirement",
              })}
              type={showPasswords ? "text" : "password"}
              fullWidth={true}
              value={props.password}
              onChange={(e) => props.fieldChange("password", e)}
              helperText={props.error.password}
              error={props.error.password.length > 0}
              InputProps={{
                endAdornment: (
                  <IconButton
                    id="visibility-password-1"
                    type="button"
                    aria-label={intl.formatMessage({
                      id: "aria.show_password",
                    })}
                    aria-pressed={showPasswords}
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    {showPasswords ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
              data-testid="signup-password-1"
            />
          </div>
          <div className="field">
            <TextField
              id="signup-password-2"
              autoComplete="new-password"
              required
              label={props.intl.formatMessage({
                id: "signup.password_repeat",
              })}
              type={showPasswords ? "text" : "password"}
              fullWidth={true}
              value={props.passwordRepeat}
              onChange={(e) => props.fieldChange("passwordRepeat", e)}
              helperText={props.error.passwordRepeat}
              error={props.error.passwordRepeat.length > 0}
              InputProps={{
                endAdornment: (
                  <IconButton
                    id="visibility-password-2"
                    type="button"
                    aria-label={intl.formatMessage({
                      id: "aria.show_password",
                    })}
                    aria-pressed={showPasswords}
                    onClick={() => setShowPasswords(!showPasswords)}
                  >
                    {showPasswords ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                ),
              }}
              data-testid="signup-password-2"
            />
          </div>
        </div>
        <SignUpFormCustomFields onChange={props.fieldChange} />
        <div className={styles.terms}>
          <FormControlLabel
            style={{ marginBottom: "1rem" }}
            control={
              <Checkbox
                required
                id="accept-terms-checkbox"
                onChange={(e) =>
                  props.onCheckChange("hasTermsAccepted", e.target.checked)
                }
                checked={props.hasTermsAccepted || false}
                color="primary"
                inputProps={{
                  "data-testid": "accept-terms-checkbox",
                }}
              />
            }
            htmlFor="accept-terms-checkbox"
            label={
              <Typography variant="body2">{gdprMessageToDisplay}</Typography>
            }
          />

          <FormControlLabel
            control={
              <Checkbox
                id="accept-commercial-use"
                onChange={(e) =>
                  props.onCheckChange("optInMarket", e.target.checked)
                }
                checked={props.optInMarket || false}
                color="primary"
                style={{ alignSelf: "flex-start" }}
                inputProps={{
                  "data-cy": "accept-commercial-use",
                }}
              />
            }
            htmlFor="accept-commercial-use"
            label={
              <Typography variant="body2">{optInMessageToDisplay}</Typography>
            }
          />
        </div>

        <CernCaptcha
          error={props.error?.captcha || props.error?.error_message} // Remove second operand when the following issue is fixed: https://app.shortcut.com/padammobility/story/92731/signup-endpoint-fix-invalid-captcha-error-shape
          onCaptchaAnswerChange={setCaptchaAnswer}
        />

        <div
          className="row aligned"
          style={{ justifyContent: "space-around", paddingTop: "1rem" }}
        >
          <MuiButton
            variant="outlined"
            color="inherit"
            onClick={() => {
              if (SIGNUP_AFTER_SEARCH) {
                showAuthModal(AuthModal.SignIn);
              } else {
                navigate("/login");
              }
            }}
            role="link"
            data-testid="redirect-to-login"
          >
            {props.intl.formatMessage({ id: "signin.connexion" })}
          </MuiButton>
          <Link
            className={styles.link}
            target={"_blank"}
            href={_.get(props.regional, "contact.cvg_link_url")}
            data-testid="signup-terms-link"
          >
            <Typography variant="body2" className={styles.textLink}>
              <FormattedMessage id={"terms.button_terms_of_use"} />
              <OpenNewTabIcon />
            </Typography>
          </Link>
          <Link
            className={styles.link}
            target={"_blank"}
            href={_.get(props.regional, "contact.gdpr_use_term_url")}
            data-testid="signup-privacy-link"
          >
            <Typography variant="body2" className={styles.textLink}>
              <FormattedMessage id={"terms.button_gdpr_traitement"} />
              <OpenNewTabIcon />
            </Typography>
          </Link>
          <MuiButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={props.requesting || !props.hasTermsAccepted}
            data-testid="signup-submit"
          >
            {props.intl.formatMessage({ id: "link.register" })}
          </MuiButton>
        </div>
      </form>
    </Paper>
  );
}
