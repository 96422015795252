import React, { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { useIntl } from "react-intl";

import dayjs from "dayjs";

import AppBar from "containers/AppBar/AppBar.jsx";
import { AuthModals } from "components/AuthModals/AuthModals";
import { ScrollToTopButton } from "components/molecules/ScrollToTopButton/ScrollToTopButton";
import { Footer } from "organisms/Footer/Footer";
import SnackBar from "containers/SnackBar/SnackBar.jsx";
import { snackMessage } from "containers/SnackBar/actions.js";
import SwitchTerritory from "containers/SwitchTerritory/SwitchTerritory";
import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";
import { ProductContext } from "contexts/ProductContext";
import { UserContext } from "contexts/UserContext";

import { usePathnameForTitle } from "./usePathname";
import { useAuthenticatedLayoutStyles } from "./authenticatedLayoutStyles";

const displayBuildInfos = () => {
  console.log("=====");
  console.log("BUILD INFOS for debug (ADMIN and DEBUG users only):");

  if (process.env.BUILD_DATE) {
    console.log(`-- process.env.BUILD_DATE: ${dayjs(process.env.BUILD_DATE)}`);
  }
  if (process.env.COMMIT_HASH) {
    console.log(`-- process.env.COMMIT_HASH: ${process.env.COMMIT_HASH}`);
  }

  console.log("=====");
};

function AuthenticatedLayout(props) {
  const { isAllowed, ongoingImpersonation, snackMessage } = props;

  const intl = useIntl();
  const classes = useAuthenticatedLayoutStyles();

  const { isAuthenticated, userProfile, userProfileLoading } =
    useContext(UserContext);
  const { productParameters } = useContext(ProductContext);

  const [authModal, showAuthModal] = useState(null);
  const [phoneCode, setPhoneCode] = useState();

  usePathnameForTitle(productParameters?.brand);

  const phoneValidationProcessAtBooking =
    productParameters?.user.phone_validation_process === "AT_BOOKING";

  window.gm_authFailure = () => {
    snackMessage("error", "Your google maps API key is not working");
  };

  useEffect(() => {
    /* This effect triggers automatic auth form opening and closing
     * depending on user state, in signup after search or when
     * the phone validation process is performed at booking.
     * It does the following:
     *   - opens the signup verification form when the user is logged in but not verified ;
     *   - closes any open auth form when the user is logged in and verified.
     */
    if (
      (SIGNUP_AFTER_SEARCH || phoneValidationProcessAtBooking) &&
      isAuthenticated
    ) {
      if (phoneValidationProcessAtBooking) {
        // No verification at this point when phone validation process is performed at booking
        showAuthModal(null);
      } else {
        const isNotVerified =
          !userProfileLoading &&
          !userProfile.isPhoneValidated &&
          !ongoingImpersonation;
        showAuthModal(isNotVerified ? AuthModal.SignUpVerification : null);
      }
    }
  }, [
    isAuthenticated,
    ongoingImpersonation,
    phoneValidationProcessAtBooking,
    userProfile.isPhoneValidated,
    userProfileLoading,
  ]);

  useEffect(() => {
    if (isAllowed) {
      displayBuildInfos();
    }
  }, []);

  return (
    <AuthModalsContext.Provider
      value={{ authModal, showAuthModal, phoneCode, setPhoneCode }}
    >
      <a id="go-to-content" href="#main-content" tabIndex={0}>
        {intl.formatMessage({ id: "help.skipToContent" })}
      </a>

      <div className="column" style={{ width: "100%", height: "100%" }}>
        <AppBar {...props} />

        <main className={classes.viewWrapper} id="main-content" role="main">
          <Outlet />
          {/** below is the modal to change or instanciate territory */}
          <SwitchTerritory />
          <SnackBar />
          <ScrollToTopButton />
        </main>
        <Footer />
      </div>

      <AuthModals />
    </AuthModalsContext.Provider>
  );
}

const mapStateToProps = (state) => {
  const groups = _.get(state, "user.groups", []);
  const impersonateStarted = _.get(
    state,
    "impersonateForm.sessionStarted",
    false,
  );

  return {
    isAllowed: groups.includes("ADMIN") || groups.includes("DEBUG"),
    ongoingImpersonation: impersonateStarted,
  };
};

const mapDispatchToProps = (dispatch) => ({
  snackMessage: (type, msg) => dispatch(snackMessage(type, msg)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthenticatedLayout);
