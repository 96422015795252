import React, { useMemo, useState } from "react";

import { INotificationsError } from "components/organisms/NotificationPreferences/NotificationPreferencesContainer";
import { NotificationRow } from "./NotificationRow";

type NotificationRowContainerProps = {
  rowType: string;
  channels: { [key: string]: boolean };
  onChange: (channels: { [key: string]: boolean }) => void;
  error?: INotificationsError;
};

export function NotificationRowContainer({
  rowType,
  channels,
  onChange,
  error,
}: NotificationRowContainerProps) {
  const countChecked = useMemo(() => {
    return Object.values(channels).reduce(
      (accumulator, current) => accumulator + (current ? 1 : 0),
      0,
    );
  }, [channels]);

  const [nbChecked, setNbChecked] = useState(countChecked);

  const handleOnChange = (channelChangedId, value, setChecked) => {
    if (nbChecked === 1 && channels[channelChangedId] === true && !value) {
      setChecked(!value);
    } else {
      if (value) {
        setNbChecked(nbChecked + 1);
      } else {
        setNbChecked(nbChecked - 1);
      }
    }
    const newChannels = { ...channels, [channelChangedId]: value };
    onChange(newChannels);
  };

  return (
    <NotificationRow
      rowType={rowType}
      channels={channels}
      onChange={handleOnChange}
      error={error}
    />
  );
}
