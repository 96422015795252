import {
  ON_FIELD_CHANGE,
  BOOKING_SEATS_EDITION_REQUEST_ERROR,
  BOOKING_SEATS_EDITION_REQUEST_SUCCESS,
} from "./actions.js";

import { RECEIVE_RESERVATION } from "./../Reservation/actions.js";

const initialState = {
  time: null,
  timeRestrictionType: "departure",
  editionSeatsError: null,
};

export const reservationForm = (state = initialState, action) => {
  switch (action.type) {
    case ON_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case RECEIVE_RESERVATION:
      return {
        ...state,
        time: action.reservation.pickup_time,
        timeRestrictionType:
          action.reservation.time_restriction_type.toLowerCase(),
      };
    case BOOKING_SEATS_EDITION_REQUEST_ERROR:
      return {
        ...state,
        editionSeatsError: action.error,
      };
    case BOOKING_SEATS_EDITION_REQUEST_SUCCESS:
      return {
        ...state,
        editionSeatsError: null,
      };
    default:
      return state;
  }
};
