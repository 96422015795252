import { makeStyles } from "@material-ui/core";

export const useSearchResultErrorStyles = makeStyles(() => ({
  closeIcon: {
    position: "absolute",
    top: "1rem",
    right: "1rem",

    fill: "rgb(208, 2, 27)",
    width: "1.5rem",
    height: "1.5rem",
    margin: "0.25rem",

    cursor: "pointer",

    "&:hover": {
      color: "#3a3a3a !important",
    },
  },
  errorWrapper: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    margin: "0 0 1rem 0",
    padding: "1rem",
    borderRadius: "1.5rem",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",

    lineHeight: "1rem",

    background: "white",
    transition: "all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)",

    "& > span": {
      display: "block",

      margin: "0 0 0.5rem 0",
    },
  },

  requestError: {
    display: "inline-block",

    margin: "0 1rem 0 0",

    fontWeight: 700,
  },
}));
