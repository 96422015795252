import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useNavigate, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { settings } from "config/app";
import cookie from "lib/cookie.js";

import {
  Typography,
  Button as MuiButton,
  CircularProgress,
  Paper,
} from "@material-ui/core";

import { userManager } from "containers/SSOSignInForm/ssoUserManager";
import { ssoLoginRequest } from "containers/OauthCallback/actions";

import { UserContext } from "contexts/UserContext";
import { logout } from "store/actions";
import useWindowWidth from "lib/useWindowWidth";
import { ProductContext } from "contexts/ProductContext";

function OauthCallbackContainer(props) {
  const { intl, error, requesting } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { setIsAuthenticated, loadUserProfile } = useContext(UserContext);
  const isLoggedWithOpenid = cookie.get(settings.cookieKeys.isLoggedWithOpenid);

  const width = useWindowWidth();
  const { productParameters } = useContext(ProductContext);

  useEffect(() => {
    dispatch(
      ssoLoginRequest(navigate, setIsAuthenticated, loadUserProfile, location),
    );
  }, [dispatch]);

  return (
    <Paper
      className="MuiEngagementPaper--01"
      style={{
        padding: width >= 600 ? "2rem" : "1rem 0",
        /*z-index here for the visual effect below the paper*/ zIndex: 1,
        /* TODO: Use MUI breakpoints - https://app.shortcut.com/padammobility/story/37597/use-mui-breakpoints-instead-of-literal-width-values */
        minWidth: width >= 600 ? "25rem" : "auto",
        overflowY: "auto",
      }}
    >
      <div className="column">
        <div className="logoBox">
          <img
            className="logo"
            src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
              DEFAULT_TERRITORY || PRODUCT
            }_color.png`}
            alt={productParameters?.brand}
          />
        </div>
      </div>
      {error ? (
        <>
          <div className="h2">
            <span>{error}</span>
          </div>
          <Typography className="padded">
            {intl.formatMessage({ id: "signin.oauth_callback_error" })}
          </Typography>
          <MuiButton
            style={{ marginTop: "0.5rem" }}
            type="submit"
            color="primary"
            variant="contained"
            data-testid="signin-button"
            onClick={() => {
              if (isLoggedWithOpenid) {
                userManager.signoutRedirect();
                userManager.removeUser();
              }
              dispatch(logout(navigate, setIsAuthenticated));
              navigate("/sso-login");
            }}
          >
            {intl.formatMessage({ id: "signin.go_back_to_login_page" })}
          </MuiButton>
        </>
      ) : (
        <div className="row aligned" style={{ justifyContent: "center" }}>
          {requesting ? (
            <MuiButton
              style={{ marginTop: "0.5rem" }}
              type="submit"
              color="primary"
              disabled={true}
              variant="contained"
              data-testid="signin-button"
            >
              <CircularProgress />
            </MuiButton>
          ) : (
            <MuiButton
              style={{ marginTop: "0.5rem" }}
              type="submit"
              color="primary"
              variant="contained"
              data-testid="signin-button"
              onClick={() => {
                if (isLoggedWithOpenid) {
                  userManager.signoutRedirect();
                  userManager.removeUser();
                }
                dispatch(logout(navigate, setIsAuthenticated));
                navigate("/sso-login");
              }}
            >
              {intl.formatMessage({ id: "menu.logout" })}
            </MuiButton>
          )}
        </div>
      )}
    </Paper>
  );
}

const _mapState = (state) => {
  return {
    error: state.signin.error,
    requesting: state.signin.requesting,
  };
};

export default connect(_mapState)(injectIntl(OauthCallbackContainer));
