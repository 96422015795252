import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Amex(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <g>
            <path
              fill="#016FD0"
              d="M0 1.09C0 .487.48 0 1.094 0h21.812C23.51 0 24 .49 24 1.09v14.82c0 .602-.48 1.09-1.094 1.09H1.094C.49 17 0 16.51 0 15.91V1.09z"
              transform="translate(0 3.5)"
            />
            <g>
              <path
                fill="#FFF"
                d="M4.638 8.761V4.97H8.61l.426.562.44-.562h14.417v3.53s-.377.258-.813.262h-7.983l-.48-.598v.598h-1.575v-1.02s-.215.142-.68.142h-.536v.878H9.442l-.425-.573-.432.573H4.638zM0 2.11L.896 0h1.549l.508 1.182V0h1.925l.303.855L5.474 0h8.644v.43s.454-.43 1.2-.43l2.805.01.5 1.167V0h1.61l.444.67V0h1.626v3.792h-1.626l-.425-.672v.672h-2.367l-.238-.598h-.637l-.234.598h-1.605c-.643 0-1.053-.42-1.053-.42v.42h-2.421l-.48-.598v.598H2.214l-.238-.598h-.634l-.236.598H0V2.111z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                fillRule="nonzero"
                d="M1.213.467L.005 3.307H.79l.223-.569H2.31l.222.569h.803L2.128.467h-.915zm.447.661l.395.994h-.792l.397-.994z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                d="M3.419 3.306L3.419.467 4.536.471 5.186 2.302 5.821.467 6.929.467 6.929 3.306 6.227 3.306 6.227 1.214 5.483 3.306 4.867 3.306 4.121 1.214 4.121 3.306zM7.41 3.306L7.41.467 9.701.467 9.701 1.102 8.119 1.102 8.119 1.588 9.664 1.588 9.664 2.186 8.119 2.186 8.119 2.69 9.701 2.69 9.701 3.306z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                fillRule="nonzero"
                d="M10.108.467v2.84h.702V2.298h.296l.841 1.009h.859l-.924-1.046c.379-.033.77-.362.77-.872 0-.598-.464-.922-.982-.922h-1.562zm.702.636h.803c.192 0 .332.152.332.299 0 .188-.181.298-.322.298h-.813v-.597z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                d="M13.656 3.306L12.939 3.306 12.939.467 13.656.467zM15.356 3.306H15.2c-.749 0-1.204-.596-1.204-1.408 0-.831.45-1.431 1.396-1.431h.776v.673h-.805c-.384 0-.655.302-.655.765 0 .55.31.781.757.781h.185l-.295.62z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                fillRule="nonzero"
                d="M16.884.467l-1.209 2.84h.787l.223-.569h1.296l.221.569h.804L17.8.467h-.915zm.446.661l.395.994h-.79l.395-.994z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                d="M19.088 3.306L19.088.467 19.981.467 21.121 2.251 21.121.467 21.823.467 21.823 3.306 20.959 3.306 19.79 1.476 19.79 3.306zM5.119 8.275L5.119 5.436 7.41 5.436 7.41 6.071 5.828 6.071 5.828 6.557 7.373 6.557 7.373 7.155 5.828 7.155 5.828 7.659 7.41 7.659 7.41 8.275zM16.346 8.275L16.346 5.436 18.637 5.436 18.637 6.071 17.056 6.071 17.056 6.557 18.593 6.557 18.593 7.155 17.056 7.155 17.056 7.659 18.637 7.659 18.637 8.275zM7.499 8.275L8.614 6.873 7.472 5.436 8.357 5.436 9.037 6.324 9.72 5.436 10.57 5.436 9.442 6.856 10.56 8.275 9.676 8.275 9.015 7.401 8.371 8.275z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                fillRule="nonzero"
                d="M10.644 5.436v2.84h.72v-.897h.74c.625 0 1.099-.335 1.099-.987 0-.54-.372-.956-1.008-.956h-1.551zm.72.643h.779c.202 0 .346.125.346.327 0 .189-.144.326-.349.326h-.776V6.08zM13.508 5.436v2.84h.702v-1.01h.296l.841 1.01h.859l-.924-1.047c.379-.032.77-.361.77-.872 0-.597-.464-.921-.982-.921h-1.562zm.702.635h.803c.192 0 .332.152.332.299 0 .189-.181.299-.322.299h-.813V6.07z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
              <path
                fill="#016FD0"
                d="M18.963 8.275V7.66h1.405c.208 0 .298-.114.298-.238 0-.12-.09-.24-.298-.24h-.635c-.552 0-.86-.34-.86-.85 0-.456.282-.895 1.102-.895h1.368l-.296.639h-1.183c-.226 0-.295.12-.295.234 0 .118.086.248.258.248h.666c.615 0 .882.353.882.815 0 .497-.298.903-.916.903h-1.496zM21.54 8.275V7.66h1.405c.208 0 .298-.114.298-.238 0-.12-.09-.24-.298-.24h-.635c-.552 0-.86-.34-.86-.85 0-.456.282-.895 1.102-.895h1.368l-.296.639h-1.183c-.226 0-.295.12-.295.234 0 .118.086.248.259.248h.665c.615 0 .882.353.882.815 0 .497-.297.903-.916.903H21.54z"
                transform="translate(0 3.5) translate(.024 4.096)"
              />
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
