import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Grid, Paper, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { ProductContext } from "contexts/ProductContext";

import { A11yDeclarationBlock1 } from "./parts/A11yDeclarationBlock1";
import { A11yDeclarationBlock2 } from "./parts/A11yDeclarationBlock2";
import { A11yDeclarationBlock3 } from "./parts/A11yDeclarationBlock3";
import { A11yDeclarationBlock3UK } from "./parts/A11yDeclarationBlock3UK";
import { A11yDeclarationBlock4 } from "./parts/A11yDeclarationBlock4";
import { A11yDeclarationBlock5 } from "./parts/A11yDeclarationBlock5";
import { A11yDeclarationBlock5UK } from "./parts/A11yDeclarationBlock5UK";
import { A11yDeclarationBlock6 } from "./parts/A11yDeclarationBlock6";

import { getLang } from "../../i18n";
import { getTextContents } from "./getTextContents";

import { useA11yDeclarationStyles } from "./a11yDeclarationStyles";

export function A11yDeclarationView() {
  const classes = useA11yDeclarationStyles();
  const { productParameters } = useContext(ProductContext);
  const intl = useIntl();

  // To avoid Weblate overload for translators, texts have a custom handling:
  const lang = getLang();
  const textContents = getTextContents(lang);

  // Change the audit or auto-audit ratings below:
  const A11Y_OVERALL_RATE = intl.formatNumber(65 / 100, {
    style: "percent",
    maximumFractionDigits: 1,
  });
  const A11Y_AVERAGE_RATE = intl.formatNumber(84.2 / 100, {
    style: "percent",
    maximumFractionDigits: 1,
  });

  const AUDIT_DATE = "26/10/2021";
  const AUTO_AUDIT_DATE = "27/01/2022";

  const brand = productParameters?.brand;
  const phonenumberDefaultRegion =
    productParameters?.phonenumber_default_region;
  const websiteURL = HOST_URL;
  const internationalizedAuditDate = dayjs(AUDIT_DATE, "DD/MM/YYYY").format(
    "LL",
  );
  const internationalizedAutoAuditDate = dayjs(
    AUTO_AUDIT_DATE,
    "DD/MM/YYYY",
  ).format("LL");

  return (
    <div className={classes.decorativeBackground}>
      <Grid
        container
        direction="column"
        alignItems="center"
        className={classes.contentWrapper}
      >
        <Typography className="visuallyHidden" component="h1">
          <FormattedMessage
            id="a11y-declaration"
            defaultMessage="Accessibility declaration"
          />
        </Typography>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          <A11yDeclarationBlock1
            textContents={textContents}
            websiteURL={websiteURL}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          <A11yDeclarationBlock2
            a11yOverallRate={A11Y_OVERALL_RATE}
            brand={brand}
            textContents={textContents}
            websiteURL={websiteURL}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          {phonenumberDefaultRegion === "GB" ? (
            <A11yDeclarationBlock3UK textContents={textContents} />
          ) : (
            <A11yDeclarationBlock3
              a11yOverallRate={A11Y_OVERALL_RATE}
              a11yAverageRate={A11Y_AVERAGE_RATE}
              auditDate={internationalizedAuditDate}
              autoAuditDate={internationalizedAutoAuditDate}
              textContents={textContents}
            />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          <A11yDeclarationBlock4
            autoAuditDate={internationalizedAutoAuditDate}
            textContents={textContents}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          {phonenumberDefaultRegion === "GB" ? (
            <A11yDeclarationBlock5UK textContents={textContents} />
          ) : (
            <A11yDeclarationBlock5 textContents={textContents} />
          )}
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.contentBlock}
          component={Paper}
        >
          <A11yDeclarationBlock6 textContents={textContents} />
        </Grid>
      </Grid>
    </div>
  );
}
