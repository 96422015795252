import React from "react";

import { Link, Typography } from "@material-ui/core";

import { useA11yDeclarationStyles } from "../a11yDeclarationStyles";

export function A11yDeclarationBlock3(props) {
  const classes = useA11yDeclarationStyles();

  const {
    a11yOverallRate,
    a11yAverageRate,
    auditDate,
    autoAuditDate,
    textContents,
  } = props;

  return (
    <>
      <Typography component="h2">{textContents["block3-h2"]}</Typography>

      <Typography component="p">
        {textContents["block3-p1a"]
          .replace("{auditDate}", auditDate)
          .replace("{autoAuditDate}", autoAuditDate)}
        <Link
          className={classes.link}
          href="https://ideance.net/fr/"
          target="_blank"
        >
          {"Ideance"}
        </Link>
        {textContents["block3-p1b"].replace("{autoAuditDate}", autoAuditDate)}
      </Typography>

      <ul>
        <Typography component="li">
          {textContents["block3-li1"].replace(
            "{a11yOverallRate}",
            a11yOverallRate,
          )}
        </Typography>

        <Typography component="li">
          {textContents["block3-li2"].replace(
            "{a11yAverageRate}",
            a11yAverageRate,
          )}
        </Typography>
      </ul>

      <Typography component="h3">{textContents["block3-h3"]}</Typography>

      <Typography component="h4">{textContents["block3-h4-1"]}</Typography>

      <Typography component="p">{textContents["block3-p2"]}</Typography>

      <ul>
        {/* Commented criteria below have been fixed between audit and auto-audit: */}

        <Typography component="li">
          {textContents["block3-criterion-1-1"]}
        </Typography>

        {/* <Typography component="li">
          {textContents["block3-criterion-1-2"]}
        </Typography> */}

        {/* <Typography component="li">
          {textContents["block3-criterion-1-3"]}
        </Typography> */}

        <Typography component="li">
          {textContents["block3-criterion-3-2"]}
        </Typography>

        {/* <Typography component="li">
          {textContents["block3-criterion-6-1"]}
        </Typography> */}

        <Typography component="li">
          {textContents["block3-criterion-7-1"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-7-3"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-7-5"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-8-2"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-8-6"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-8-7"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-8-9"]}
        </Typography>

        {/* <Typography component="li">
          {textContents["block3-criterion-9-2"]}
        </Typography> */}

        <Typography component="li">
          {textContents["block3-criterion-9-3"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-10-7"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-10-8"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-10-11"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-10-12"]}
        </Typography>

        {/* <Typography component="li">
          {textContents["block3-criterion-11-1"]}
        </Typography> */}

        {/* <Typography component="li">
          {textContents["block3-criterion-11-3"]}
        </Typography> */}

        {/* <Typography component="li">
          {textContents["block3-criterion-11-5"]}
        </Typography> */}

        <Typography component="li">
          {textContents["block3-criterion-11-9"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-11-10"]}
        </Typography>

        <Typography component="li">
          {textContents["block3-criterion-11-13"]}
        </Typography>

        {/* <Typography component="li">
          {textContents["block3-criterion-12-6"]}
        </Typography> */}

        <Typography component="li">
          {textContents["block3-criterion-12-7"]}
        </Typography>
      </ul>

      <Typography component="h4">{textContents["block3-h4-3"]}</Typography>

      <Typography component="p">{textContents["block3-p-14"]}</Typography>
    </>
  );
}
