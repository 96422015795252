import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useIntl } from "react-intl";
import { TerritoryContext } from "contexts/TerritoryContext";
import { Alert } from "@material-ui/lab";
import Close from "components/Icons/Close";
import { useUniversalLinkStyles } from "components/UniversalLinkDialog/universalLinkStyles";
import { ExternalLink } from "components/Icons/ExternalLink";
import ChevronRight from "components/Icons/ChevronRight";

interface UniversalLinkDialogProps {
  titleKey?: string | null;
  alertTextKey?: string | null;
  alertKeyParams?: object;
  handleClose: () => void;
}

/**
 * @module UniversalLinkDialog
 * @desc Get all universal links and display them in a modal. Universal links are links that can be used to open another app from a browser.
 * @module UniversalLinkDialog
 * @param titleKey
 * @param handleClose
 * @constructor
 */
export const UniversalLinkDialog = ({
  titleKey = null,
  alertTextKey = null,
  alertKeyParams = {},
  handleClose,
}: UniversalLinkDialogProps) => {
  const intl = useIntl();
  const { selectedTerritory } = useContext(TerritoryContext);
  const classes = useUniversalLinkStyles();

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      id="universal_link_modal"
      aria-labelledby="universal-link-modal-title"
    >
      <DialogTitle id="universal-link-modal-title" disableTypography>
        <div className={classes.titleContainer}>
          {titleKey ? (
            <Typography variant="h1" component="h2" className={classes.title}>
              {intl.formatMessage({ id: titleKey })}
            </Typography>
          ) : null}
          {handleClose ? (
            <IconButton
              size="small"
              aria-label="close"
              onClick={handleClose}
              className={classes.closeBtn}
            >
              <Close />
            </IconButton>
          ) : null}
        </div>
      </DialogTitle>
      <DialogContent>
        {alertTextKey ? (
          <Alert className={classes.textAlert} severity="info">
            {intl.formatMessage({ id: alertTextKey }, { ...alertKeyParams })}
          </Alert>
        ) : null}
        <div className={classes.containerUniversalLinks}>
          {selectedTerritory?.booking?.universal_links?.map((universalLink) => (
            <a
              href={universalLink.link}
              target="_blank"
              rel="noopener noreferrer"
              key={universalLink.display_name}
            >
              <div className={classes.universalLink}>
                <Typography component="p" className={classes.containerText}>
                  <span className={classes.iconLeft}>
                    <ExternalLink />
                  </span>
                  {universalLink.display_name}
                </Typography>
                <span className={classes.iconRight}>
                  <ChevronRight />
                </span>
              </div>
            </a>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};
