import { createContext } from "react";

export enum AuthModal {
  SignIn,
  SignUp,
  SignUpVerification,
  ForgotPassword,
}

interface AuthModalsContextValue {
  authModal: AuthModal | null;
  showAuthModal: (authModal: AuthModal | null) => void;
  phoneCode?: string;
  setPhoneCode: (phoneCode: string) => void;
}

export const AuthModalsContext = createContext<AuthModalsContextValue>({
  authModal: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showAuthModal: () => {},
  phoneCode: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setPhoneCode: () => {},
});
