import {
  BOOKING_VALIDATION_ERROR,
  BOOKING_VALIDATION_SUCCESS,
  INITIATE_BOOKING_VALIDATION_REQUEST,
  BOOKING_VALIDATION_END_REQUEST,
  CLOSE_VALIDATION_RESULT,
} from "./actions.js";
import { INITIATE_MULTI_DATE_REQUEST } from "containers/SearchForm/actions.js";

const initialState = {
  isRequesting: false,
  daysRequesting: {},
  responses: {},
  requestErrors: {},
  dialogOpen: false,
};

export const bookingValidation = (state = initialState, action) => {
  switch (action.type) {
    case BOOKING_VALIDATION_ERROR:
      return {
        ...state,
        requestErrors: {
          ...state.requestErrors,
          [action.day]: action.error,
        },
        daysRequesting: { ...state.daysRequesting, [action.day]: false },
      };
    case BOOKING_VALIDATION_SUCCESS:
      return {
        ...state,
        daysRequesting: {
          ...state.daysRequesting,
          [action.day]: false,
        },
        responses: {
          ...state.responses,
          [action.day]: action.response,
        },
      };
    case INITIATE_BOOKING_VALIDATION_REQUEST:
      return {
        ...initialState,
        daysRequesting: action.daysRequesting,
        isRequesting: true,
        dialogOpen: true,
      };
    case BOOKING_VALIDATION_END_REQUEST:
      return {
        ...state,
        isRequesting: false,
      };
    case CLOSE_VALIDATION_RESULT:
      return {
        ...state,
        dialogOpen: false,
      };
    case INITIATE_MULTI_DATE_REQUEST:
      // reset all responses/errors from previous searches
      return {
        ...state,
        dialogOpen: false,
        isRequesting: false,
        responses: {},
        requestErrors: {},
      };
    default:
      return state;
  }
};
