import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export function EditIcon(props) {
  return (
    <SvgIcon className={props.className} viewBox="0 0 16 16">
      <path
        d="M3.12576 15.5H0.5V12.8742L9.82975 3.54449L12.4555 6.17025L3.12576 15.5ZM15.3865 2.69323C15.5378 2.84459 15.5378 3.08792 15.3865 3.23928L14.1136 4.51217L11.4878 1.8864L12.7607 0.613517C12.9121 0.462161 13.1554 0.462161 13.3068 0.613517L15.3865 2.69323Z"
        fill="#6C6C6C"
        stroke="#6C6C6C"
      />
    </SvgIcon>
  );
}
