import React from "react";

import { VariantProps, cva } from "class-variance-authority";

import { AlertCircle, CheckCircle, Info } from "lucide-react";

import { cn } from "utils/classes";

import { Typography } from "@/components/ui/typography";

const messageVariants = cva("text-md p-2 rounded-xl flex gap-2", {
  variants: {
    variant: {
      info: "text-blue-800 bg-blue-100",
      error: "text-red-800 bg-red-100",
      success: "text-green-800 bg-green-100",
    },
  },
  defaultVariants: {
    variant: "info",
  },
});

type VariantPropType = VariantProps<typeof messageVariants>;

export interface MessageProps
  extends React.HTMLAttributes<HTMLElement>,
    VariantPropType {}

const Message = React.forwardRef<HTMLElement, MessageProps>(
  ({ className, variant, children, ...props }, ref) => {
    return (
      <Typography
        className={cn(messageVariants({ variant, className }))}
        ref={ref}
        {...props}
      >
        {variant === "info" && <Info />}
        {variant === "error" && <AlertCircle />}
        {variant === "success" && <CheckCircle />}
        {children}
      </Typography>
    );
  },
);

Message.displayName = "Message";

export { Message, messageVariants };
