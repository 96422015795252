import * as locals from "date-fns/locale";

export const localSelector = (lang: string): Locale => {
  if (lang === "fr-ca") {
    return locals.frCA;
  }

  if (Object.keys(locals).includes(lang)) {
    return locals[lang];
  }

  console.warn(
    `localSelector fail to convert current lang to date-fns local with "${lang}"`,
  );

  return locals.enUS;
};
