import React from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";

import SSOSignInForm from "components/SSOSignInForm/SSOSignInForm.jsx";
import { userManager } from "containers/SSOSignInForm/ssoUserManager";

import { CLOSE_SESSION } from "containers/ImpersonateForm/actions.js";

/**
 * Sign in container
 */
function SSOSignInContainer(props) {
  const { closeImpersonate, requesting, intl, error } = props;

  const submit = (e) => {
    e.preventDefault();
    // fix on edge case impersonate
    closeImpersonate();
    userManager.signinRedirect();
  };

  return (
    <SSOSignInForm
      requesting={requesting}
      intl={intl}
      onSubmit={submit}
      error={error}
    />
  );
}

const _mapState = (state) => {
  return {
    error: state.signin.error,
    requesting: state.signin.requesting,
  };
};

const _mapDispatch = (dispatch) => ({
  closeImpersonate: () => {
    dispatch({
      type: CLOSE_SESSION,
    });
  },
});

export default connect(_mapState, _mapDispatch)(injectIntl(SSOSignInContainer));
