import React, { useState } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  makeStyles,
  withStyles,
  CircularProgress,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { ReservationListItem } from "organisms/ReservationListItem/ReservationListItem";
import { WaitingListListItem } from "organisms/ReservationListItem/WaitingListListItem";
import { RecurringListItem } from "organisms/ReservationListItem/RecurringListItem";

import { BookingStatusEnum, ResaGroupEnum } from "types/reservation";

import styles from "./list.cssmodule.scss";

const useStyles = makeStyles({
  tab: {
    minWidth: 270,
  },
});

function ReservationTabs(props) {
  const [index, setIndex] = useState(0);
  const classes = useStyles();

  const {
    cancelled,
    cancelRecurringBooking,
    changeEndDateForRecurringBooking,
    isDeletable,
    past,
    recurring,
    upcoming,
    waitingLists,
  } = props;

  const resaGroups = [
    { id: ResaGroupEnum.upcoming, data: upcoming },
    { id: ResaGroupEnum.past, data: past },
    { id: ResaGroupEnum.cancelled, data: cancelled },
  ];

  // Order recurring booking by validity then by descending ids
  let orderedRecurring = [];

  if (recurring) {
    const valid = recurring.filter(
      (a) => a.booking.status === BookingStatusEnum.VALIDATED,
    );
    valid.sort((a, b) => b.id - a.id);

    const others = recurring.filter(
      (a) => a.booking.status !== BookingStatusEnum.VALIDATED,
    );
    others.sort((a, b) => b.id - a.id);

    orderedRecurring.push(...valid);
    orderedRecurring.push(...others);
  }

  const renderTab = (i) => {
    if (i >= 0 && i < resaGroups.length) {
      const group = resaGroups[i] || {};
      let data = group.data;
      const id = group.id;

      if (id === ResaGroupEnum.upcoming && waitingLists) {
        // Merge upcoming reservations with waiting lists
        data = group.data
          .concat(
            waitingLists.map((waitingList) => ({
              ...waitingList,
              isWaitingList: true,
            })),
          )
          .sort((a, b) => {
            const aDateTime = a.isWaitingList
              ? a.requested_datetime
              : a.pickup_time;
            const bDateTime = b.isWaitingList
              ? b.requested_datetime
              : b.pickup_time;
            return aDateTime.localeCompare(bDateTime);
          });
      }

      if (!data)
        return (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        );
      else if (data.length === 0)
        return (
          <Typography style={{ padding: "1rem" }}>
            {id === ResaGroupEnum.upcoming && (
              <FormattedMessage id="tabs.empty_upcoming" />
            )}
            {id === ResaGroupEnum.past && (
              <FormattedMessage id="tabs.empty_past" />
            )}
            {id === ResaGroupEnum.cancelled && (
              <FormattedMessage id="tabs.empty_cancelled" />
            )}
          </Typography>
        );

      return (
        <>
          {data.map((item) => {
            return item.isWaitingList ? (
              <WaitingListListItem key={`wl-${item.id}`} waitingList={item} />
            ) : (
              <ReservationListItem
                key={`r-${item.id}`}
                reservation={item}
                isDeletable={id === ResaGroupEnum.upcoming && isDeletable}
              />
            );
          })}
        </>
      );
    } else if (i === 3) {
      // recurring
      if (!recurring)
        return (
          <div className={styles.loader}>
            <CircularProgress />
          </div>
        );
      else if (recurring.length === 0)
        return (
          <Typography variant="body1" style={{ margin: "1rem 0 0 0" }}>
            <FormattedMessage id="tabs.empty_recurring" />
          </Typography>
        );

      return (
        <>
          <Alert style={{ margin: "1rem 0 0.5rem 0" }} severity="info">
            <FormattedMessage id="recurring.info" />
          </Alert>

          {orderedRecurring.map((rec, i) => {
            return (
              <RecurringListItem
                key={i}
                recurring={rec}
                isDeletable={isDeletable}
                changeEndDateForRecurringBooking={
                  changeEndDateForRecurringBooking
                }
                cancelRecurringBooking={cancelRecurringBooking}
              />
            );
          })}
        </>
      );
    }
  };

  const getTabLabel = (groupId) => {
    if (groupId === ResaGroupEnum.upcoming) {
      return <FormattedMessage id="tabs.upcoming" />;
    } else if (groupId === ResaGroupEnum.past) {
      return <FormattedMessage id="tabs.past" />;
    } else if (groupId === ResaGroupEnum.cancelled) {
      return <FormattedMessage id="tabs.cancelled" />;
    }

    return "";
  };

  return (
    <Paper style={{ padding: "1rem" }}>
      <Tabs
        value={index}
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        onChange={(e, i) => setIndex(i)}
      >
        {
          // a tab per group
          resaGroups.map((group, i) => (
            <Tab
              key={i}
              className={styles.tabTitle}
              index={i}
              label={getTabLabel(group.id)}
            />
          ))
        }
        {
          // if recurring are activated
          recurring && (
            <Tab
              className={styles.tabTitle}
              index={3}
              label={<FormattedMessage id="recurring.booking" />}
              classes={{ root: classes.tab }}
            />
          )
        }
      </Tabs>

      {renderTab(index)}
    </Paper>
  );
}

export default withStyles(styles)(ReservationTabs);
