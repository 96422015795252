import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "../../../themes/themeTypes";

import { getAccessibleTextColor } from "styles/textColor";

export const useProfileFormStyles = makeStyles((theme: CustomTheme) => ({
  accordion: {
    margin: "16px auto",
    padding: "8px 0",
    borderRadius: theme.shape.borderRadius,

    [theme.breakpoints.up("sm")]: {
      padding: "8px",
    },

    "&::before": {
      display: "none",
    },
  },
  accordionsContainer: {
    padding: "16px 8px 32px 8px",

    [theme.breakpoints.up("sm")]: {
      padding: "16px 20% 32px 20%",
    },
  },
  accordionHeader: {
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
  },
  accordionHeaderIcon: {
    fontSize: theme.typography.h1.fontSize,
  },
  accordionFlexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  creditBadge: {
    margin: "0 0 0 16px",

    fontSize: theme.typography.caption.fontSize,
  },
  title: {
    margin: "0 0 0 0.5rem",
  },
  subtitle: {
    margin: "0 1rem 1rem 1rem",
  },
  userBanner: {
    padding: "1rem 15%",

    color: getAccessibleTextColor(theme.palette.primary.main),

    backgroundColor: theme.palette.primary.light,
  },
  userEmail: {
    fontSize: theme.typography.h3.fontSize,
  },
  userFullName: {
    fontSize: theme.typography.h1.fontSize,
  },
}));
