import api from "apiAxios";
import { snackMessage } from "containers/SnackBar/actions";
import { PassengerProfileEnum } from "organisms/PassengerProfiles/constants";

export const INITIATE_PASSENGERS_PROFILES_VALIDATION_REQUEST =
  "INITIATE_PASSENGERS_PROFILES_VALIDATION_REQUEST";
export const RECEIVE_PASSENGERS_PROFILES = "RECEIVE_PASSENGERS_PROFILES";
export const PASSENGERS_PROFILES_ERROR = "PASSENGERS_PROFILES_ERROR";
export const RECEIVE_BOOKED_PROFILE_IDS = "RECEIVE_BOOKED_PROFILE_IDS";
export const UPDATE_PASSENGERS_PROFILES = "UPDATE_PASSENGERS_PROFILES";
export const PASSENGER_PROFILES_LOADING = "PASSENGER_PROFILES_LOADING";

type BookedProfilesIdsDBSchema = {
  id: string;
  passenger_profile: number;
  booking: number;
  passenger_type: number;
  passenger_type_display: string;
};

type BookedProfilesIds = {
  [type: string]: {
    ids: string[];
    passengerProfileId: number;
  };
};

export const getFormattedPassengersProfile =
  ({ territoryId, deepLinkRequestedSeats = null }) =>
  (dispatch, getState) => {
    // dispatch(setPassengerProfilesLoadingStatus());
    const state = getState();
    const profiles = state?.passengersProfiles?.profiles;

    api
      .getOperatorPassengerProfiles({
        urlParameters: {
          territoryId,
        },
      })
      .then((json) => {
        const rawProfiles = json.results;
        for (const profile of rawProfiles) {
          if (!(profile.passenger_type_display in profiles)) {
            dispatch(setPassengerProfilesLoadingStatus());
            break;
          }
        }

        const parsedProfiles = {};

        const getDefaultCountValue = (
          passenger_type_display: PassengerProfileEnum,
        ) => {
          let count = profiles[passenger_type_display]?.count || 0; // Default count is 0 unless overridden by profile count

          if (
            [
              PassengerProfileEnum.PASSENGER,
              PassengerProfileEnum.ADULT,
            ].includes(passenger_type_display)
          ) {
            count = Math.max(1, count); // Ensure count is at least 1 for PASSENGER or ADULT
            return deepLinkRequestedSeats || count; // Return deepLinkRequestedSeats if available, otherwise return count
          }

          return count; // Return count for other passenger types
        };

        for (const profile of rawProfiles) {
          if (!profile.is_enabled) {
            continue;
          }
          parsedProfiles[profile.passenger_type_display] = {
            id: profile.id,
            ageMin: profile.age_min,
            ageMax: profile.age_max,
            count: getDefaultCountValue(profile.passenger_type_display),
          };
        }

        dispatch(passengersProfilesFetched(parsedProfiles));
      })
      .catch((error) => {
        console.log(`Error while fetching passenger profiles: ${error}`);
      });
  };

function _formatBookedProfilesIds(
  passengersProfile: [BookedProfilesIdsDBSchema],
): BookedProfilesIds {
  const bookedProfilesIds = {};

  for (const passengerProfile of passengersProfile) {
    if (!(passengerProfile.passenger_type_display in bookedProfilesIds)) {
      bookedProfilesIds[passengerProfile.passenger_type_display] = {
        ids: [passengerProfile.id],
        passengerProfileId: passengerProfile.passenger_profile,
      };
    } else {
      bookedProfilesIds[passengerProfile.passenger_type_display].ids.push(
        passengerProfile.id,
      );
    }
  }

  return bookedProfilesIds;
}

/**
 * Get all passengers profiles of booking (db schema) formatted to simple structure
 */
export const getPassengersBookedProfilesIds = () => (dispatch, getState) => {
  const state = getState();
  const customerId = state?.user?.user?.customer_id;
  const bookingId = state?.reservation?.reservation?.id;

  api
    .getPassenger({
      urlParameters: {
        customerId,
        bookingId,
      },
    })
    .then((json) => {
      const bookedProfilesIds = _formatBookedProfilesIds(json.results);
      dispatch(passengersBookedProfilesIdsFetched(bookedProfilesIds));
    })
    .catch((error) => {
      dispatch(
        snackMessage(
          "error",
          error?.infos?.detail?.message,
          "error on api:\n get-passengers-profile \n with payload \n" +
            JSON.stringify({
              bookingId,
            }),
        ),
      );
    });
};

export const passengersProfilesFetched = (passengersProfiles) => ({
  type: RECEIVE_PASSENGERS_PROFILES,
  passengersProfiles,
});

export const passengersBookedProfilesIdsFetched = (bookedProfilesIds) => ({
  type: RECEIVE_BOOKED_PROFILE_IDS,
  bookedProfilesIds,
});

export const updatePassengerProfile = (profileType, profile) => ({
  type: UPDATE_PASSENGERS_PROFILES,
  profileType,
  profile,
});

export const setPassengerProfilesLoadingStatus = () => ({
  type: INITIATE_PASSENGERS_PROFILES_VALIDATION_REQUEST,
});
