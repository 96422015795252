export const TEXT_CONTENTS_FR = {
  // BLOCK 1:
  "block1-h2": "Accessibilité",

  "block1-p1": "Cette page n'est pas une page d'aide.",
  "block1-p2":
    "Elle vise à présenter la politique d'accessibilité du site web « {websiteURL} » et à définir le niveau d'accessibilité général constaté sur le site web conformément à la réglementation et aux référentiels en vigueur.",

  "block1-h3": "Qu’est-ce que l’accessibilité numérique ?",

  "block1-p3":
    "L’accessibilité numérique est un ensemble de règles et de bonnes pratiques qui couvrent notamment les aspects fonctionnels, graphiques, techniques et éditoriaux.",
  "block1-p4":
    "Le suivi de ces règles et bonnes pratiques permet de s’assurer que les supports numériques (sites web, applications mobiles, documents PDF, etc.) sont accessibles aux personnes en situation de handicap.",
  "block1-p5": "Un site web accessible permet par exemple de :",

  "block1-li1":
    "Personnaliser son affichage via le système d’exploitation et/ou le navigateur (agrandissement ou rétrécissement des caractères, changement de la typographie, modification des couleurs, arrêt des animations, etc.).",
  "block1-li2":
    "Naviguer à l’aide de technologies d’assistance comme une synthèse vocale ou une plage braille.",
  "block1-li3":
    "Naviguer sans utiliser la souris, avec le clavier uniquement, des contacteurs ou via un écran tactile.",
  "block1-li4":
    "Consulter les vidéos et les contenus audio à l’aide de sous-titres et/ou de transcriptions.",
  "block1-li5": "Etc.",

  // BLOCK 2:
  "block2-h2": "Déclaration d’accessibilité",

  "block2-p1":
    "{brand} s’engage à rendre accessibles ses sites web (internet, intranet et extranet), ses applications mobiles, ses progiciels et son mobilier urbain numérique conformément à l’article 47 de la loi n°2005-102 du 11 février 2005.",
  "block2-p2":
    "Cette déclaration d’accessibilité s’applique au site web « {websiteURL} ».",

  "block2-h3": "État de conformité",

  "block2-p3":
    "Le site web « {websiteURL} » est en conformité partielle avec WCAG 2.1 (niveau AA), et à {a11yOverallRate} conforme avec le RGAA version 4.1 en raison des non-conformités et des dérogations énumérées ci-après.",

  // BLOCK 3:
  "block3-h2": "Résultats des tests",

  "block3-p1a":
    "Un audit de conformité au RGAA version 4.1 a été réalisé le {auditDate} par la société ",
  "block3-p1b":
    ". Il a été suivi d'un auto-audit le {autoAuditDate} après avoir intégré les corrections remontées par Ideance. Cet auto-audit réalisé par Padam Mobility révèle que sur l’échantillon :",

  "block3-li1":
    "Le taux de conformité global est de {a11yOverallRate}. Ce taux est obtenu en divisant le nombre de critères conformes par le nombre de critères applicables.",
  "block3-li2":
    "Le taux de conformité moyen est de {a11yAverageRate}. Ce taux est obtenu en faisant la moyenne des taux de conformité de chaque page.",

  "block3-h3": "Contenus non-accessibles",

  "block3-h4-1": "Non-conformités",

  "block3-p2": "Liste des critères non conformes :",

  "block3-criterion-1-1":
    "1.1 Chaque image porteuse d’information a-t-elle une alternative textuelle ?",
  "block3-criterion-1-2": "",
  "block3-criterion-1-3": "",
  "block3-criterion-3-2":
    "3.2 Dans chaque page web, le contraste entre la couleur du texte et la couleur de son arrière-plan est-il suffisamment élevé (hors cas particuliers) ?",
  "block3-criterion-6-1": "",
  "block3-criterion-7-1":
    "7.1 Chaque script est-il, si nécessaire, compatible avec les technologies d’assistance ?",
  "block3-criterion-7-3":
    "7.3 Chaque script est-il contrôlable par le clavier et par tout dispositif de pointage (hors cas particuliers) ?",
  "block3-criterion-7-5":
    "7.5 Dans chaque page web, les messages de statut sont-ils correctement restitués par les technologies d’assistance ?",
  "block3-criterion-8-2":
    "8.2 Pour chaque page web, le code source généré est-il valide selon le type de document spécifié (hors cas particuliers) ?",
  "block3-criterion-8-6":
    "8.6 Pour chaque page web ayant un titre de page, ce titre est-il pertinent ?",
  "block3-criterion-8-7":
    "8.7 Dans chaque page web, chaque changement de langue est-il indiqué dans le code source (hors cas particuliers) ?",
  "block3-criterion-8-9":
    "8.9 Dans chaque page web, les balises ne doivent pas être utilisées uniquement à des fins de présentation. Cette règle est-elle respectée ?",
  "block3-criterion-9-2": "",
  "block3-criterion-9-3":
    "9.3 Dans chaque page web, chaque liste est-elle correctement structurée ?",
  "block3-criterion-10-7":
    "10.7 Dans chaque page web, pour chaque élément recevant le focus, la prise de focus est-elle visible ?",
  "block3-criterion-10-8":
    "10.8 Pour chaque page web, les contenus cachés ont-ils vocation à être ignorés par les technologies d’assistance ?",
  "block3-criterion-10-11":
    "10.11 Pour chaque page web, les contenus peuvent-ils être présentés sans avoir recours à un défilement vertical pour une fenêtre ayant une hauteur de 256px ou à un défilement horizontal pour une fenêtre ayant une largeur de 320px (hors cas particuliers) ?",
  "block3-criterion-10-12":
    "10.12 Dans chaque page web, les propriétés d’espacement du texte peuvent-elles être redéfinies par l’utilisateur sans perte de contenu ou de fonctionnalité (hors cas particuliers) ?",
  "block3-criterion-11-1": "",
  "block3-criterion-11-3": "",
  "block3-criterion-11-5": "",
  "block3-criterion-11-9":
    "11.9 Dans chaque formulaire, l’intitulé de chaque bouton est-il pertinent (hors cas particuliers) ?",
  "block3-criterion-11-10":
    "11.10 Dans chaque formulaire, le contrôle de saisie est-il utilisé de manière pertinente (hors cas particuliers) ?",
  "block3-criterion-11-13":
    "11.13 La finalité d’un champ de saisie peut-elle être déduite pour faciliter le remplissage automatique des champs avec les données de l’utilisateur ?",
  "block3-criterion-12-6": "",
  "block3-criterion-12-7":
    "12.7 Dans chaque page web, un lien d’évitement ou d’accès rapide à la zone de contenu principal est-il présent (hors cas particuliers) ?",

  "block3-h4-2": "Dérogations pour charge disproportionnée",

  "block3-h5-1": "Menu de navigation principal",

  "block3-p-3a":
    "Le menu de navigation principal déroulant présent dans la partie connectée du site web suit en partie le ",
  "block3-p-3b": "motif de conception ARIA « Menu or Menu bar »",
  "block3-p-4":
    "Ce qui n'est pas optimal car ce motif de conception n'est pas prévu pour ce type de menu de navigation.",
  "block3-p-5":
    "Aussi, la structuration HTML de ce menu possède quelques légères imperfections.",
  "block3-p-6":
    "Toutefois, cette dérogation est envisageable car ce menu est notamment parfaitement utilisable au clavier seul et avec un lecteur d'écran.",

  "block3-h5-2": "Fil d’Ariane",

  "block3-p-7":
    "À partir d'une certaine largeur d'écran (800px et moins), pour des raisons fonctionnelles et graphique, il a été choisi de ne pas afficher le fil d'Ariane.",
  "block3-p-8": "Ce qui pourrait invalider le critère 10.11.",
  "block3-p-9":
    "Toutefois, cette dérogation est envisageable car le fil d'Ariane est une fonctionnalité non essentielle à la bonne utilisation du site web.",
  "block3-p-10":
    "Aussi et surtout, d'autres moyens alternatifs accessibles permettent de savoir où on se situe dans le site web puis de revenir aux pages précédemment consultées.",

  "block3-h5-3": "Fonctionnalité « horloge »",

  "block3-p-11":
    "La fonctionnalité « horloge » permettant de renseigner les champs « Heure » par exemple présents sur la page de recherche de trajets n'est pas accessible notamment au clavier seul et avec un lecteur d'écran.",
  "block3-p-12":
    "Toutefois, cette dérogation est envisageable car il est possible de saisir ces champs « Heure » à la main, sans obligatoirement passer par cette fonctionnalité.",
  "block3-p-13": "Ce qui constitue une alternative accessible.",

  "block3-h4-3": "Contenus non soumis à l’obligation d’accessibilité",

  "block3-p-14":
    "Les cartes interactives Google Maps présentes dans les pages de détail des réservations.",

  // BLOCK 4:
  "block4-h2": "Établissement de cette déclaration d’accessibilité",

  "block4-p-1": "Cette déclaration a été établie le {autoAuditDate}",

  "block4-h3-1": "Technologies utilisées pour la réalisation du site web",

  "block4-h3-2": "Environnement de test",

  "block4-p-2":
    "Les tests ont été effectués avec les combinaisons de navigateur web et lecteur d’écran suivantes :",

  "block4-h3-3": "Outils pour évaluer l’accessibilité",

  "block4-li-12": "Outils de développement Firefox",
  "block4-li-13": "Web Developer (extension Firefox)",

  "block4-h3-4":
    "Pages du site web ayant fait l’objet de la vérification de conformité",

  "block4-li-14": "Connexion",
  "block4-li-15": "Inscription",
  "block4-li-16": "Recherche de trajets",
  "block4-li-17": "Propositions de trajets",
  "block4-li-18": "Réservation",
  "block4-li-19": "Paramètres du compte",

  // BLOCK 5:
  "block5-h2": "Retour d’information et contact",

  "block5-p-1":
    "Si vous n’arrivez pas à accéder à un contenu ou à un service de ce site web, vous pouvez nous contacter pour être orienté vers une alternative accessible ou obtenir le contenu sous une autre forme.",
  "block5-p-2": "Écrivez-nous par voie postale à l’adresse suivante :",

  "block5-h3": "Voies de recours",

  "block5-p-3": "Cette procédure est à utiliser dans le cas suivant.",
  "block5-p-4":
    "Vous avez signalé au responsable du site web un défaut d’accessibilité qui vous empêche d’accéder à un contenu ou à un des services et vous n’avez pas obtenu de réponse satisfaisante.",

  "block5-li-1": "Écrire un message au Défenseur des droits",
  "block5-li-2":
    "Contacter le délégué du Défenseur des droits dans votre région",
  "block5-li-3":
    "Envoyer un courrier par la poste (gratuit, ne pas mettre de timbre) à l’adresse suivante :",

  // BLOCK 6 (build by moving some Block 3 content):
  "block6-h3": "Appendice",
};
