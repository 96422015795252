import React, { useState } from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, IconButton, Typography } from "@material-ui/core";

import dayjs from "dayjs";

import { Badge } from "atoms/Badge/Badge";
import { CancelRecurringDialog } from "components/Reservation/CancelRecurringDialog";
import { InfosBadges } from "molecules/InfosBadges/InfosBadges";
import { ModifyRecurringDialog } from "components/Reservation/ModifyRecurringDialog";
import { TimedRide } from "organisms/TimedRide/TimedRide";

import Delete from "components/Icons/Delete";

import { FORMAT_DATE, FORMAT_HOUR, INITIAL } from "utils/constants";

import { useReservationListItemStyles } from "organisms/ReservationListItem/reservationListItemStyles";
import { PaymentMethodAPIEnum } from "types/payment";

export function RecurringListItem(props) {
  const {
    cancelRecurringBooking,
    changeEndDateForRecurringBooking,
    isDeletable,
    recurring,
  } = props;

  const [changeEndDateIsOpen, setChangeEndDateIsOpen] = useState(false);
  const [cancelRecurringIsOpen, setCancelRecurringIsOpen] = useState(false);

  const intl = useIntl();
  const classes = useReservationListItemStyles();

  const { booking, offers, end_datetime } = recurring;

  const recurring_enddate = dayjs(end_datetime).format(FORMAT_DATE);

  const offersNames = offers.map((offer) => offer.name);

  const recurringLabel = `${intl.formatMessage({
    id: "help.recurrenceOffer",
  })} ${offersNames.toString()}`;

  const price = booking.payment.amount;
  const standardSeats = booking.requested_seats;

  const pickupTime = dayjs(booking.pickup_time).format(FORMAT_HOUR);
  const pickupNodeName =
    booking.pickup_node?.name || booking.pickup_node?.position.address;

  const dropoffTime = dayjs(booking.dropoff_time).format(FORMAT_HOUR);
  const dropoffNodeName =
    booking.dropoff_node?.name || booking.dropoff_node?.position.address;
  const personalItems = booking?.personalItems?.count || [];

  return (
    <article className={classes.reservationContainer}>
      <div className={classes.reservationHeader}>
        <Typography className={classes.reservationDate}>
          <FormattedMessage
            id="recurring.until"
            values={{ date: recurring_enddate }}
          />
        </Typography>

        {booking.passenger_status === "DROPOFF" && (
          <Badge
            label={intl.formatMessage({ id: "tabs.past" })}
            styleVariant={{ background: "#e6f0f7" }}
          />
        )}

        {booking.passenger_status === "CANCELED" && (
          <Badge
            label={intl.formatMessage({ id: "tabs.cancelled" })}
            styleVariant={{ background: "#fae5e8" }}
          />
        )}
      </div>

      <div className={classes.reservationHeader}>
        <InfosBadges
          price={price > 0 ? price : undefined}
          standardSeats={standardSeats}
          textLabel={recurringLabel}
          personalItems={personalItems}
        />
      </div>

      <div className={classes.timedRideContainer}>
        <TimedRide
          dropoffNodeName={dropoffNodeName}
          dropoffTime={dropoffTime}
          pickupNodeName={pickupNodeName}
          pickupTime={pickupTime}
        />

        {(booking.passenger_status === INITIAL ||
          booking.passenger_status === PaymentMethodAPIEnum.ONBOARD) && (
          <>
            <div className={classes.actionsContainer}>
              <Button
                color="primary"
                variant="contained"
                role="button"
                tabIndex={0}
                aria-label={intl.formatMessage({ id: "recurring.modify" })}
                onClick={() => setChangeEndDateIsOpen(true)}
              >
                <FormattedMessage id="misc.edit" />
              </Button>

              <div className="column ">
                <IconButton
                  className={classes.deleteButton}
                  aria-label={intl.formatMessage({ id: "recurring.cancel" })}
                  onClick={() => setCancelRecurringIsOpen(true)}
                >
                  <Delete className={classes.deleteIcon} />
                </IconButton>
              </div>
            </div>

            {changeEndDateIsOpen && (
              <ModifyRecurringDialog
                changeEndDateForRecurringBooking={
                  changeEndDateForRecurringBooking
                }
                handleClose={() => setChangeEndDateIsOpen(false)}
                recurring={recurring}
              />
            )}

            {cancelRecurringIsOpen && isDeletable && isDeletable(booking) && (
              <CancelRecurringDialog
                cancelRecurringBooking={cancelRecurringBooking}
                handleClose={() => setCancelRecurringIsOpen(false)}
                recurring={recurring}
              />
            )}
          </>
        )}
      </div>
    </article>
  );
}
