import React from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { Clock3 } from "lucide-react";

import dayjs from "dayjs";

import { WaitingList } from "api/types/waitingList";

import { Frame } from "atoms/Frame";
import { Typography } from "components/ui/typography";
import { InfosBadges } from "components/molecules/InfosBadges/InfosBadges";

interface WaitingListTripProps {
  requestedDate: string;
  waitingList: WaitingList;
}

export function WaitingListTrip({
  requestedDate,
  waitingList,
}: WaitingListTripProps) {
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col px-4">
        <Typography className="mb-6 text-muted-foreground">
          <FormattedMessage id="FLOW_HELPER_TEXT_CANCEL_WAITING_LIST" />
        </Typography>
        <div className="flex gap-4">
          <Typography>{requestedDate}</Typography>
          <InfosBadges standardSeats={waitingList.passengers_number} />
        </div>
        <Typography>
          <span className="mr-3 inline-block h-3 w-3 rounded-full bg-green-600" />
          <span className="text-muted-foreground">
            <FormattedMessage id="search.pickup" />
          </span>{" "}
          {waitingList.pickup_address}
        </Typography>
        <Typography>
          <span className="mr-3 inline-block h-3 w-3 rounded-full bg-red-600" />
          <span className="text-muted-foreground">
            <FormattedMessage id="search.dropoff" />
          </span>{" "}
          {waitingList.dropoff_address}
        </Typography>
      </div>
      <Frame>
        <Typography variant="ul" className="ml-0">
          <li className="flex gap-2 text-muted-foreground">
            <Clock3 />
            {waitingList.time_restriction_type === "DEPARTURE" ? (
              <FormattedMessage
                id="LABEL_WAITING_LIST_DEPARTURE_BETWEEN"
                values={{
                  departureTimeMin: (
                    <span className="font-bold">
                      {dayjs(waitingList.min_time).format("LT")}
                    </span>
                  ),
                  departureTimeMax: (
                    <span className="font-bold">
                      {dayjs(waitingList.max_time).format("LT")}
                    </span>
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                id="LABEL_WAITING_LIST_ARRIVAL_BETWEEN"
                values={{
                  arrivalTimeMin: (
                    <span className="font-bold">
                      {dayjs(waitingList.min_time).format("LT")}
                    </span>
                  ),
                  arrivalTimeMax: (
                    <span className="font-bold">
                      {dayjs(waitingList.max_time).format("LT")}
                    </span>
                  ),
                }}
              />
            )}
          </li>
        </Typography>
      </Frame>
    </div>
  );
}
