import * as React from "react";
import { FormattedMessage } from "react-intl";

type FormattedMessageClassnameProps = {
  spanProps?: React.ComponentPropsWithoutRef<"span">;
  formattedMessageProps: React.ComponentProps<typeof FormattedMessage>;
};

export function WrappedFormattedMessage({
  spanProps,
  formattedMessageProps,
}: FormattedMessageClassnameProps) {
  return (
    <span {...spanProps}>
      <FormattedMessage {...formattedMessageProps} />
    </span>
  );
}
