import React, { useContext, useEffect } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import SignInForm from "components/SignInForm/SignInForm.jsx";

import { CLOSE_SESSION } from "containers/ImpersonateForm/actions.js";

import { UserContext } from "contexts/UserContext";

import { onFieldChange, loginRequest, RESET_LOGIN } from "./actions.js";

/**
 * Sign in container
 */
function SignInContainer(props) {
  const {
    closeImpersonate,
    loginRequest,
    onFieldChange,
    username,
    password,
    requesting,
    intl,
    error,
    cleanForm,
  } = props;

  const { setIsAuthenticated, loadUserProfile } = useContext(UserContext);

  const navigate = useNavigate();
  const location = useLocation();

  // ~component did mount : we clean form
  useEffect(() => {
    cleanForm();
  }, []);

  const submit = (e) => {
    e.preventDefault();
    // fix on edge case impersonate
    closeImpersonate();
    loginRequest(
      navigate,
      setIsAuthenticated,
      loadUserProfile,
      username,
      password,
      location,
    );
  };

  const fieldChange = (name, e) => {
    onFieldChange(name, e.target.value);
  };

  return (
    <SignInForm
      requesting={requesting}
      intl={intl}
      username={username} // email !..
      password={password}
      onFieldChange={fieldChange}
      onSubmit={submit}
      error={error}
      closeImpersonate={closeImpersonate}
    />
  );
}

const _mapState = (state) => {
  return {
    username: state.signin.username,
    password: state.signin.password,
    error: state.signin.error,
    requesting: state.signin.requesting,
  };
};

const _mapDispatch = (dispatch) => ({
  closeImpersonate: () => {
    dispatch({
      type: CLOSE_SESSION,
    });
  },
  onFieldChange: (field, value) => {
    dispatch(onFieldChange(field, value));
  },
  loginRequest: (
    navigate,
    setIsAuthenticated,
    loadUserProfile,
    username,
    password,
    location,
  ) => {
    dispatch(
      loginRequest(
        navigate,
        setIsAuthenticated,
        loadUserProfile,
        username,
        password,
        location,
      ),
    );
  },
  cleanForm: () => {
    dispatch({ type: RESET_LOGIN });
  },
});

export default connect(_mapState, _mapDispatch)(injectIntl(SignInContainer));
