import React from "react";

import { SvgIcon } from "@material-ui/core";

export function FormIcon() {
  return (
    <SvgIcon>
      <path
        fill="#151515"
        fillRule="nonzero"
        d="M17.01 0c.895 0 1.62.741 1.62 1.655v20.69c0 .914-.725 1.655-1.62 1.655H1.62C.725 24 0 23.259 0 22.345V1.655C0 .741.725 0 1.62 0h15.39zm0 .828H1.62c-.427 0-.777.337-.808.765l-.002.062v20.69c0 .436.33.794.75.825l.06.002h15.39c.427 0 .777-.337.808-.765l.002-.062V1.655c0-.436-.33-.794-.75-.825l-.06-.002z"
      />
      <path
        fill="#151515"
        fillRule="nonzero"
        d="M15.795 3.24c.671 0 1.215.544 1.215 1.215v16.2c0 .672-.544 1.216-1.215 1.216H2.835c-.671 0-1.215-.544-1.215-1.215v-16.2c0-.672.544-1.216 1.215-1.216l.83-.002c-.013.066-.02.135-.02.205 0 .069.007.137.02.202h-.83c-.427 0-.777.33-.808.75l-.002.06v16.2c0 .428.33.777.75.808l.06.003h12.96c.427 0 .777-.33.808-.75l.002-.06v-16.2c0-.428-.33-.778-.75-.809l-.06-.002h-1.64c.013-.065.02-.133.02-.202 0-.07-.007-.139-.02-.205l1.64.002z"
      />
      <path
        fill="#151515"
        fillRule="nonzero"
        d="M8.91 1.215c.647 0 1.176.506 1.213 1.144l.002.071h3.038c.559 0 1.012.453 1.012 1.013 0 .559-.453 1.012-1.012 1.012H4.658c-.56 0-1.013-.453-1.013-1.012 0-.56.453-1.013 1.013-1.013h3.037c0-.671.544-1.215 1.215-1.215zm0 .405c-.427 0-.777.33-.808.75l-.002.06v.405H4.658c-.336 0-.608.272-.608.608 0 .315.241.575.55.604l.058.003h8.505c.335 0 .607-.272.607-.607 0-.316-.24-.576-.549-.605l-.058-.003H9.732l-.013-.453c-.025-.426-.38-.762-.809-.762z"
      />
      <rect width="6.48" height="1" x="3.645" y="6.885" fill="#000" rx=".5" />
      <rect width="6.48" height="1" x="3.645" y="8.505" fill="#000" rx=".5" />
      <rect width="6.48" height="1" x="3.645" y="10.125" fill="#000" rx=".5" />
    </SvgIcon>
  );
}
