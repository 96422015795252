import {
  PROFILE_FETCHED,
  PROFILE_UPDATED,
  FAVORITE_UPDATED,
  LOGOUT,
} from "store/actions.js";

const initialState = {
  user: {},
  favorite: null,
  groups: [],
  availableDiscountProfiles: [],
};

export const user = (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_FETCHED:
      return {
        ...state,
        user: action.profile.user,
        favorite: action.profile.favorite,
        groups: action.profile.groups.map((i) => i.toUpperCase()),
        availableDiscountProfiles: action.profile.available_discount_profiles,
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        user: action.profile,
      };
    case FAVORITE_UPDATED:
      return {
        ...state,
        favorite: action.favorite,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
