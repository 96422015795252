import React from "react";

import { Link, Typography } from "@material-ui/core";

import { useA11yDeclarationStyles } from "../a11yDeclarationStyles";

export function A11yDeclarationBlock5UK(props) {
  const classes = useA11yDeclarationStyles();

  const { textContents } = props;

  return (
    <>
      <Typography component="h2">{textContents["block5-h2"]}</Typography>

      <Typography component="p">{textContents["block5-p-1"]}</Typography>

      <Typography component="p">{textContents["block5-uk-p-2"]}</Typography>

      <address>
        <Typography component="p">
          <a href="mailto:accessibility@padam.io">accessibility@padam.io</a>
        </Typography>
      </address>

      <Typography component="h3">{textContents["block5-h3"]}</Typography>

      <Typography>
        <Link
          className={classes.link}
          href="https://www.equalityhumanrights.com/en"
          target="_blank"
        >
          {textContents["block5-uk-li-1"]}
        </Link>

        {textContents["block5-uk-p-3"]}

        <Link
          className={classes.link}
          href="https://www.equalityadvisoryservice.com/"
          target="_blank"
        >
          {textContents["block5-uk-li-2"]}
        </Link>
      </Typography>
    </>
  );
}
