import { makeStyles } from "@material-ui/core";

export const useEmergencyContactButtonStyles = makeStyles((theme) => ({
  addContactText: {
    margin: "0 0 0 0.5rem",
    overflow: "hidden",

    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  contactName: {
    margin: "0 0 0.25rem 0.75rem",

    fontWeight: 500,
  },
  contactPlaceholder: {
    color: "#6c6c6c",
    fontWeight: 400,
  },
  deleteButton: {
    margin: "0 0 0 1rem",
    padding: 0,

    opacity: 0.75,

    transition: "all 300ms",

    "&:hover": {
      opacity: 1,
    },
  },
  deleteIcon: {
    width: "1.125rem",
    height: "1.125rem",
    padding: "0.625rem",
    borderRadius: "50%",

    color: theme.palette.error.main,

    backgroundColor: "#fae0e0",
  },
  editIcon: {
    transform: "scale(0.7)",
  },
  emergencyContactButton: {
    display: "flex",
    alignItems: "center",

    width: "100%",
    padding: "0.25rem 1rem",
    border: "1px solid #151515",
    borderRadius: "2rem",

    color: "#151515",

    background: "#f4f4f4",

    "&:hover": {
      cursor: "pointer",
    },
  },
  emergencyContactButtonContainer: {
    display: "flex",
    flexDirection: "column",
  },
  plusIcon: {
    color: "#6c6c6c",

    fontSize: "1.5rem",
  },

  // Let this class here to override styles:
  addContactButton: {
    width: "auto",

    color: "#6c6c6c",

    background: "white",
  },
}));
