import React from "react";

import { withTheme } from "@material-ui/core/styles";

import SvgIcon from "@material-ui/core/SvgIcon";

function BusStop(props) {
  const { theme } = props;

  return (
    <SvgIcon
      {...props}
      style={{ fill: theme.palette.primary.main, ...props.style }}
      focusable="false"
      role="none"
      aria-hidden="true"
    >
      <path
        d="M17.799 3.667c.801 0 1.451.656 1.451 1.466 0 .543-.292 1.017-.725 1.27v11.93h-1.452l-.001-11.93c-.433-.254-.725-.727-.725-1.27 0-.81.65-1.466 1.452-1.466zm-7.602 0c1.794 0 3.247 1.453 3.247 3.247v6.897c0 .696-.218 1.34-.59 1.869.006.036.01.075.01.114v1.89c0 .359-.29.65-.65.65h-1.023c-.359 0-.65-.291-.65-.65l.001-.644c-.113.012-.228.018-.345.018H5.08c-.116 0-.231-.006-.345-.018l.001.644c0 .359-.29.65-.65.65H3.064c-.358 0-.65-.291-.65-.65v-1.89c0-.04.004-.078.011-.115-.372-.528-.59-1.172-.59-1.868V6.914c0-1.794 1.453-3.247 3.246-3.247h5.117zm-5.751 9.565c-.481 0-.871.428-.871.956 0 .529.39.957.87.957.482 0 .872-.428.872-.957 0-.528-.39-.956-.871-.956zm6.386 0c-.481 0-.87.428-.87.956 0 .529.389.957.87.957.48 0 .87-.428.87-.957 0-.528-.39-.956-.87-.956zm1.451-6.377H2.994v3.826h9.29V6.855z"
        transform="translate(-320 -482) translate(310 170) translate(0 261) translate(10 51)"
      />
    </SvgIcon>
  );
}

export default withTheme(BusStop);
