import React, { useContext } from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Typography,
  Link,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import MuiButton from "@material-ui/core/Button";
import { withTheme } from "@material-ui/core/styles";

import { ProductContext } from "contexts/ProductContext";

import styles from "./termsDialog.cssmodule.scss";

function TermsDialog({ open, hasTermsAccepted, onClose, onChange, onSubmit }) {
  const { productParameters } = useContext(ProductContext);

  return (
    <Dialog
      className={styles.dialog__body}
      open={open}
      onClose={onClose}
      style={{ textAlign: "center" }}
    >
      <DialogTitle disableTypography>
        <Typography variant="h2" component="h1">
          <FormattedMessage id={"terms.title"} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <div className={styles.terms}>
          <Typography variant="body2" style={{ textAlign: "start" }}>
            <FormattedMessage id={"terms.confirm"} />
          </Typography>
          <div className="row">
            <FormControlLabel
              style={{ textAlign: "start", alignItems: "flex-start" }}
              control={
                <Checkbox
                  style={{ paddingTop: "0px" }}
                  id="accept-commercial-use"
                  onChange={(e) => onChange(e.target.checked)}
                  name="commercial use of personal data"
                  checked={hasTermsAccepted}
                  color="primary"
                  data-testid="has-terms-accepted-checkbox"
                />
              }
              label={
                <Typography variant="body2">
                  <FormattedMessage
                    id={"terms.dataConfirm"}
                    values={{ brand: productParameters?.brand }}
                  />
                </Typography>
              }
            />
          </div>
          <div className={styles.terms__links}>
            <Link
              underline="always"
              className={styles.terms__link}
              target={"_blank"}
              href={productParameters?.contact?.cvg_link_url}
            >
              <FormattedMessage id={"terms.button_terms_of_use"} />
            </Link>
            <Link
              underline="always"
              className={styles.terms__link}
              target={"_blank"}
              href={productParameters?.contact?.gdpr_use_term_url}
            >
              <FormattedMessage id={"terms.button_gdpr_traitement"} />
            </Link>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <MuiButton
          aria-label="accept and validate gdpr terms"
          onClick={onSubmit}
          color="primary"
          variant="contained"
        >
          <FormattedMessage id={"misc.confirm"} />
        </MuiButton>
      </DialogActions>
    </Dialog>
  );
}
export default withTheme(TermsDialog);
