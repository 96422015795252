import { makeStyles } from "@material-ui/core";

export const useTimePickerStyles = makeStyles(() => ({
  timePicker: {
    position: "relative",

    width: "8rem",
    padding: "0.75rem",
    border: "1px solid lightgrey",
    borderRadius: "1.5rem",

    cursor: "text",

    "&:hover": {
      borderColor: "black",
    },

    "& .MuiInput-input": {
      cursor: "text",
    },

    "& .MuiInput-underline::before, .MuiInput-underline:hover:not(.Mui-focused)::before":
      {
        borderBottomColor: "transparent",
      },
  },
  timePickerLabel: {
    top: "-0.75rem",
    left: "0.5rem",

    padding: "0.0.5rem",

    background: "white",
    transform: "translate(0, 1.5px) scale(0.75)",

    "& + .MuiInput-formControl": {
      margin: 0,
    },
  },
}));
