import * as React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl-phraseapp";

import { Button, TextField, Typography } from "@material-ui/core";
import { useAccountInfosStyles } from "./accountInfosStyles";
import { AccountInfosCustomFields } from "./AccountInfosCustomFields";
import { AccountInfosFormErrors, ProfileData } from "types/accountInfos";

type AccountInfosProps = {
  formErrors: AccountInfosFormErrors;
  onFieldChange: (field: string, value: string) => void;
  ongoingImpersonation: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  profileData: ProfileData;
};

export function AccountInfos(props: AccountInfosProps) {
  const {
    formErrors,
    onFieldChange,
    ongoingImpersonation,
    onSubmit,
    profileData,
  } = props;

  const intl = useIntl();
  const classes = useAccountInfosStyles();

  const isFieldEmpty = (field: string) => profileData[field] === "";

  const isSubmitButtonDisabled =
    isFieldEmpty("email") ||
    isFieldEmpty("phoneNumber") ||
    isFieldEmpty("firstName") ||
    isFieldEmpty("lastName");

  return (
    <form
      className={classes.accountInfoContainer}
      onSubmit={onSubmit}
      data-testid="account-infos-accordion-content"
    >
      <Typography variant="caption" component="p" align="center">
        {intl.formatMessage({ id: "help.fieldsRequired" })}
      </Typography>

      <div className={classes.fieldsContainer}>
        <TextField
          id="profile-last-name"
          label={<FormattedMessage id="signup.lastname" />}
          name="lastName"
          value={profileData.lastName}
          helperText={formErrors?.last_name}
          autoComplete="family-name"
          required
          data-testid="account-infos-lastname"
          error={Boolean(
            formErrors?.last_name && formErrors?.last_name.length > 0,
          )}
          onChange={(e) => onFieldChange("lastName", e.target.value)}
        />

        <TextField
          id="profile-first-name"
          label={<FormattedMessage id="signup.firstname" />}
          name="firstName"
          value={profileData.firstName}
          helperText={formErrors?.first_name}
          autoComplete="given-name"
          required
          data-testid="account-infos-firstname"
          error={Boolean(
            formErrors?.first_name && formErrors?.first_name.length > 0,
          )}
          onChange={(e) => onFieldChange("firstName", e.target.value)}
        />

        <TextField
          id="profile-email"
          label={<FormattedMessage id="placeholder.email" />}
          name="email"
          type="email"
          value={profileData.email}
          helperText={formErrors?.email}
          autoComplete="email"
          required
          onChange={(e) => onFieldChange("email", e.target.value)}
          data-testid="account-infos-email"
          error={Boolean(formErrors?.email && formErrors?.email.length > 0)}
        />

        <TextField
          id="profile-tel"
          label={<FormattedMessage id="signup.phoneNumber" />}
          name="phoneNumber"
          type="tel"
          value={profileData.phoneNumber}
          helperText={formErrors?.phone_number}
          autoComplete="tel"
          required
          data-testid="account-infos-phone"
          error={Boolean(
            formErrors?.phone_number && formErrors?.phone_number.length > 0,
          )}
          onChange={(e) => onFieldChange("phoneNumber", e.target.value)}
        />

        <AccountInfosCustomFields
          customFields={profileData?.custom_fields}
          onChange={onFieldChange}
          formErrors={formErrors}
        />
      </div>
      {formErrors.custom_field && (
        <div id="error-custom-fields" className="w-full text-destructive">
          {formErrors?.custom_field}
        </div>
      )}
      <div className={classes.saveButtonContainer}>
        <Button
          variant="contained"
          type="submit"
          color="primary"
          data-testid="account-infos-submit"
          disabled={isSubmitButtonDisabled}
          aria-label={intl.formatMessage({
            id: "aria.save_personal_infos",
          })}
        >
          <FormattedMessage id="misc.save" />
        </Button>
      </div>
    </form>
  );
}
