import {
  ON_FIELD_CHANGE,
  REQUEST_EMAIL,
  REQUEST_ERROR,
  REQUEST_RESET_PASSWORD,
} from "./actions.js";

const initialState = {
  email: "",
  password: "",
  passwordConfirmation: "",
  isSent: false,
  error: false,
  message: "",
};

export const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case ON_FIELD_CHANGE:
      return {
        ...state,
        [action.field]: action.value,
      };
    case REQUEST_EMAIL:
      return {
        ...state,
        isSent: true,
        error: false,
        message: action.message,
      };
    case REQUEST_RESET_PASSWORD:
      return {
        ...state,
        password: initialState.password,
        passwordConfirmation: initialState.passwordConfirmation,
        isSent: true,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        email: "",
        isSent: false,
        error: action.error.infos,
      };
    default:
      return initialState;
  }
};
