import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withTheme } from "@material-ui/core/styles";

function Place(props) {
  const { theme } = props;
  return (
    <SvgIcon
      {...props}
      style={{ fill: theme.palette.primary.main, ...props.style }}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
    </SvgIcon>
  );
}

export default withTheme(Place);
