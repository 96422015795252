import React from "react";

import { SvgIcon } from "@material-ui/core";

export function DepartureIcon({
  className,
  fill = "#151515",
}: {
  className?: string;
  fill?: string;
}) {
  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <svg viewBox="0 0 15 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.2239 7.19265C14.2239 8.31554 13.9695 9.37833 13.5158 10.3253C13.4987 10.4097 13.47 10.4941 13.4288 10.5767L8.00628 21.4454C7.63804 22.1834 6.58489 22.1834 6.21665 21.4454L0.794098 10.5767C0.752324 10.4929 0.72348 10.4074 0.706455 10.3217C0.25379 9.37567 0 8.31413 0 7.19265C0 3.22026 3.18413 0 7.11196 0C11.0398 0 14.2239 3.22026 14.2239 7.19265ZM7.11196 10.6679C9.07587 10.6679 10.6679 9.07587 10.6679 7.11196C10.6679 5.14805 9.07587 3.55598 7.11196 3.55598C5.14804 3.55598 3.55597 5.14805 3.55597 7.11196C3.55597 9.07587 5.14804 10.6679 7.11196 10.6679Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
}
