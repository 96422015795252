import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  TextField,
  Button as MuiButton,
  CircularProgress,
  Typography,
  Paper,
  IconButton,
  Link,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

import Visibility from "components/Icons/Visibility";
import VisibilityOff from "components/Icons/VisibilityOff";

import useWindowWidth from "lib/useWindowWidth";

import { AuthModalsContext, AuthModal } from "contexts/AuthModalsContext";
import { ProductContext } from "contexts/ProductContext";

import styles from "./signinForm.cssmodule.scss";
import {
  isOpenidActivated,
  userManager,
} from "containers/SSOSignInForm/ssoUserManager";

function SignInForm(props) {
  const {
    onSubmit,
    intl,
    username,
    onFieldChange,
    error,
    password,
    requesting,
    closeImpersonate,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [isSSODomain, setIsSSODomain] = useState(false);

  const { showAuthModal } = useContext(AuthModalsContext);
  const { productParameters } = useContext(ProductContext);

  const width = useWindowWidth();

  const navigate = useNavigate();

  const canRegister = Boolean(
    productParameters?.features?.booking_website?.customer_can_register,
  );

  return (
    <Paper
      className="MuiEngagementPaper--01"
      style={{
        padding: width >= 600 ? "2rem" : "1rem 0",
        /*z-index here for the visual effect below the paper*/ zIndex: 1,
        /* TODO: Use MUI breakpoints - https://app.shortcut.com/padammobility/story/37597/use-mui-breakpoints-instead-of-literal-width-values */
        minWidth: width >= 600 ? "25rem" : "auto",
        overflowY: "auto",
      }}
    >
      <form onSubmit={onSubmit} className="column" data-testid="sign-in-form">
        <div className="logoBox">
          <img
            className="logo"
            src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
              DEFAULT_TERRITORY || PRODUCT
            }_color.png`}
            alt={productParameters?.brand}
          />
        </div>

        <Typography
          className="padded"
          variant="caption"
          component="p"
          align="center"
        >
          {intl.formatMessage({ id: "help.fieldsRequired" })}
        </Typography>

        <div className="padded">
          <TextField
            required
            id="signin-email"
            label={intl.formatMessage({ id: "placeholder.email" })}
            value={username}
            type="email"
            onChange={(e) => {
              onFieldChange("username", e);
              if (isOpenidActivated && OPENID_DOMAINS !== "") {
                let domains = OPENID_DOMAINS.split(",");
                domains.forEach((domain) => {
                  if (e.target.value.match(domain)) {
                    setIsSSODomain(true);
                  }
                });
              }
            }}
            fullWidth={true}
            helperText={error && error.email}
            error={Boolean(error)}
            autoFocus
            autoComplete="email"
          />
        </div>
        {!isSSODomain ? (
          <>
            <div className="padded">
              <TextField
                required
                id="signin-password"
                label={intl.formatMessage({
                  id: "password",
                })}
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => onFieldChange("password", e)}
                fullWidth={true}
                helperText={error && error.password}
                error={Boolean(error /*&& error.password*/)}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      id="visibility"
                      type="button"
                      aria-label={intl.formatMessage({
                        id: "aria.show_password",
                      })}
                      aria-pressed={showPassword}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  ),
                }}
              />
            </div>

            {error && (
              <Typography
                variant="caption"
                color="error"
                align="center"
                data-testid="signin-error"
                aria-role="alert"
              >
                {error.detail?.message}
              </Typography>
            )}
            <div
              style={{ float: "left", padding: "1rem", width: "fit-content" }}
            >
              <Link
                tabIndex={0}
                style={{ cursor: "pointer", textDecoration: "none" }}
                onClick={() => {
                  if (SIGNUP_AFTER_SEARCH) {
                    showAuthModal(AuthModal.ForgotPassword);
                  } else {
                    navigate("/forget-password-email");
                  }
                }}
                data-testid="forget-password-link"
              >
                <Typography variant="body2" className={styles.link}>
                  {intl.formatMessage({ id: "signin.forgetPassword" })}
                </Typography>
              </Link>
            </div>

            <div
              className="row aligned"
              style={{
                justifyContent: canRegister ? "space-between" : "flex-end",
              }}
            >
              {canRegister && (
                <MuiButton
                  style={{ marginTop: "0.5rem" }}
                  onClick={() => {
                    if (SIGNUP_AFTER_SEARCH) {
                      showAuthModal(AuthModal.SignUp);
                    } else {
                      navigate("/register");
                    }
                  }}
                  color="inherit"
                  variant="outlined"
                  role="link"
                  data-testid="signup-button"
                >
                  {intl.formatMessage({ id: "link.register" })}
                </MuiButton>
              )}

              <MuiButton
                style={{ marginTop: "0.5rem" }}
                type="submit"
                color="primary"
                disabled={requesting}
                variant="contained"
                data-testid="signin-button"
              >
                {requesting ? (
                  <CircularProgress />
                ) : (
                  intl.formatMessage({ id: "signin.connexion" })
                )}
              </MuiButton>
            </div>
          </>
        ) : (
          <MuiButton
            style={{ marginTop: "0.5rem" }}
            type="submit"
            color="primary"
            disabled={requesting}
            variant="contained"
            data-testid="signin-button"
            onClick={(e) => {
              e.preventDefault();
              // fix on edge case impersonate
              closeImpersonate();
              userManager.signinRedirect({
                extraQueryParams: { username: username },
              });
            }}
          >
            {requesting ? (
              <CircularProgress />
            ) : (
              intl.formatMessage({ id: "signin.connexion" })
            )}
          </MuiButton>
        )}
      </form>
    </Paper>
  );
}

export default withTheme(SignInForm);
