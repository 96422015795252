export const settings = {
  daysMaxSearch: 15,
  api: {
    baseURL: {
      // can bugs if linter auto-formats with $ backtick
      api: HOST_URL + "/api/v1.7",
      newApi: HOST_URL + "/api",
      adminzone: HOST_URL + "/adminzone",
    },
  },
  openIdConfig: {
    authority: "",
    client_id: "",
    redirect_uri: HOST_URL + "/bookings/oauth/callback",
    post_logout_redirect_uri: HOST_URL + "/bookings/sso-login",
    scope: "openid email roles",
  },
  cookieKeys: {
    authCookie: "authz_pdm",
    impersonateCookie: "pdm_impsnt",
    isLoggedWithOpenid: "isLoggedWithOpenid",
  },
  localStorageKeys: {
    language: "languageSelected",
    isSearchMapOpen: "pdm_search_map",
  },
};

if (
  typeof OPENID_SERVER_URL !== "undefined" &&
  typeof OPENID_CLIENT_ID !== "undefined"
) {
  settings.openIdConfig = {
    authority: OPENID_SERVER_URL,
    client_id: OPENID_CLIENT_ID,
    redirect_uri: HOST_URL + "/bookings/oauth/callback",
    post_logout_redirect_uri: HOST_URL + "/bookings/sso-login",
    scope: "openid email roles",
  };
}

export default settings;
