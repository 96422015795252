import React from "react";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { injectIntl, useIntl } from "react-intl";

import ForgetPasswordEmailForm from "containers/ForgetPassword/ForgetPasswordEmailForm.jsx";
import ResetPasswordForm from "containers/ForgetPassword/ResetPasswordForm.jsx";

export function ForgetPasswordView() {
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const token = searchParams.get("token");

  return (
    <>
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "forgetPassword.title" })}
      </h1>

      {token ? (
        <ResetPasswordForm token={token} />
      ) : (
        <ForgetPasswordEmailForm />
      )}
    </>
  );
}

const _mapState = (state) => ({
  isSent: state.forgetPassword.isSent,
});

export default connect(_mapState)(injectIntl(ForgetPasswordView));
