import React from "react";

import { SvgIcon } from "@material-ui/core";

type MapIconProps = {
  className?: string;
  fill?: string;
};

export function MapIcon({ className, fill = "#000" }: MapIconProps) {
  return (
    <SvgIcon className={className} viewBox="0 0 30 30">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.6255 12.1937L16.6017 20.0431L21.1979 21.9958C21.2338 22.0111 21.2729 21.9823 21.269 21.9435L20.0994 10.2467C20.0976 10.2294 20.0873 10.2141 20.0718 10.2061L18.2898 9.28641L16.6255 12.1937Z"
          fill={fill}
        />
        <path
          d="M13.5573 9.14932L15.5987 12.7153L15.6379 20.1308L10.6223 21.6438V10.2782L13.5573 9.14932Z"
          fill={fill}
        />
        <path
          d="M0.0753488 21.7549L3.88375 19.7429L4.5325 8.69847L0.971135 10.2068C0.953545 10.2143 0.941545 10.2309 0.939983 10.2499L0.000181983 21.7053C-0.00313341 21.7457 0.0394968 21.7738 0.0753488 21.7549Z"
          fill={fill}
        />
        <path
          d="M9.59523 21.6151L4.96456 19.9599L5.41119 8.41579L9.59523 10.2505V21.6151Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0142 7.4973C18.0845 7.4973 19.7628 5.81897 19.7628 3.74865C19.7628 1.67833 18.0845 0 16.0142 0C13.9438 0 12.2655 1.67833 12.2655 3.74865C12.2655 5.81897 13.9438 7.4973 16.0142 7.4973ZM16.0142 5.46099C16.9599 5.46099 17.7265 4.69434 17.7265 3.74864C17.7265 2.80294 16.9599 2.0363 16.0142 2.0363C15.0684 2.0363 14.3018 2.80294 14.3018 3.74864C14.3018 4.69434 15.0684 5.46099 16.0142 5.46099Z"
          fill={fill}
        />
        <path
          d="M16.0588 11.214C16.0391 11.2486 15.9893 11.2486 15.9695 11.214L12.8115 5.67662C12.792 5.64239 12.8167 5.59983 12.8561 5.59983L19.1722 5.59983C19.2116 5.59983 19.2363 5.64239 19.2168 5.67662L16.0588 11.214Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
}
