import * as React from "react";
import { useState } from "react";
import { useIntl } from "react-intl";

import { IconButton, Typography } from "@material-ui/core";

import { DeleteEmergencyContactDialog } from "organisms/DeleteEmergencyContactDialog/DeleteEmergencyContactDialog";
import { EmergencyContactDialog } from "components/organisms/EmergencyContactDialog/EmergencyContactDialog";
import { EditIcon } from "icons/EditIcon";
import { TrashIcon } from "icons/TrashIcon";

import { IContact } from "../EmergencyContacts/EmergencyContacts";

import { useEmergencyContactButtonStyles } from "./emergencyContactButtonStyles";

interface IEmergencyContactButtonProps {
  contact?: IContact;
  fetchContacts: () => void;
  onDelete: (contactId: string) => void;
  textFieldLabel: string;
}

export function EmergencyContactButton(props: IEmergencyContactButtonProps) {
  const { contact, fetchContacts, onDelete, textFieldLabel } = props;

  const [emergencyContactDialogOpen, setEmergencyContactDialogOpen] =
    useState(false);
  const [
    deleteEmergencyContactDialogOpen,
    setDeleteEmergencyContactDialogOpen,
  ] = useState(false);

  const intl = useIntl();
  const classes = useEmergencyContactButtonStyles();

  return (
    <div className={classes.emergencyContactButtonContainer}>
      <Typography
        className={`${classes.contactName}${
          contact ? "" : ` ${classes.contactPlaceholder}`
        }`}
      >
        {contact?.name || textFieldLabel}
      </Typography>

      <div className={classes.buttonsContainer}>
        <button
          className={`${classes.emergencyContactButton}${
            contact ? "" : ` ${classes.addContactButton}`
          }`}
          onClick={(): void => setEmergencyContactDialogOpen(true)}
        >
          {contact ? (
            <EditIcon className={classes.editIcon} />
          ) : (
            <span className={classes.plusIcon}>+</span>
          )}

          <Typography className={classes.addContactText}>
            {contact
              ? contact.phoneNumber
              : intl.formatMessage({
                  id: "contact.add-contact",
                  defaultMessage: "Add a contact",
                })}
          </Typography>
        </button>

        {contact && (
          <IconButton
            className={classes.deleteButton}
            onClick={(): void => setDeleteEmergencyContactDialogOpen(true)}
          >
            <TrashIcon className={classes.deleteIcon} />
          </IconButton>
        )}
      </div>

      {emergencyContactDialogOpen && (
        <EmergencyContactDialog
          contact={contact}
          fetchContacts={fetchContacts}
          textFieldLabel={textFieldLabel}
          onClose={() => setEmergencyContactDialogOpen(false)}
        />
      )}

      {contact && deleteEmergencyContactDialogOpen && (
        <DeleteEmergencyContactDialog
          onDelete={() => onDelete(contact.id)}
          onClose={() => setDeleteEmergencyContactDialogOpen(false)}
        />
      )}
    </div>
  );
}
