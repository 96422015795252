import { ResourceType } from "api/resources/types/resourceType";

export const contact: ResourceType = {
  createContact: {
    endpoint: "/customers/{customerId}/contacts/",
    method: "POST",
    impersonate: true,
    apiVersion: "2.0",
  },
  getContacts: {
    endpoint: "/customers/{customerId}/contacts/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  updateContact: {
    endpoint: "/customers/{customerId}/contacts/{contactId}/",
    method: "PATCH",
    impersonate: true,
    apiVersion: "2.0",
  },
  deleteContact: {
    endpoint: "/customers/{customerId}/contacts/{contactId}/",
    method: "DELETE",
    impersonate: true,
    apiVersion: "2.0",
  },
  stopNotifications: {
    endpoint:
      "/customers/{customerId}/contacts/{contactId}/stop-notifications/",
    method: "POST",
    apiVersion: "2.0",
  },
};
