import { makeStyles } from "@material-ui/core";

export const useFixedLinesRideSummaryStyles = makeStyles(() => ({
  deleteButton: {
    position: "absolute",
    top: "0.5rem",
    right: "0.5rem",

    transform: "scale(0.65)",

    "& button": {
      background: "#fae5e8",
      opacity: 0.75,

      transition: "all 300ms",

      "&:hover": {
        background: "#fae5e8",
        opacity: 1,
      },
    },
  },
  rideSummaryContainer: {
    position: "relative",

    padding: "1rem",
    border: "1px solid lightgrey",

    "&:not(:first-child)": {
      margin: "8px 0 0 0",
    },
  },
  summaryInfosBadges: {
    margin: "0.75rem 0 0 0",
  },
  summaryTitle: {
    margin: "0 0 0.25rem 0",

    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "left",
  },
  summarySubTitle: {
    margin: "0 0 1.25rem 0",

    fontSize: "1.1rem",
    textAlign: "center",
  },
  summaryTimedRide: {
    margin: "1rem 0 0 0",
  },
}));
