// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageSelector-cssmodule-languageSelector-xmNf1{height:100%}@media screen and (min-width: 960px){.languageSelector-cssmodule-languageSelector-xmNf1:focus-within{border-width:1px;border-style:solid}}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/LanguageSelector/languageSelector.cssmodule.scss"],"names":[],"mappings":"AAAA,mDACE,WAAY,CAEZ,qCAHF,gEAKM,gBAAiB,CACjB,kBAAmB,CACpB","sourcesContent":[".languageSelector {\n  height: 100%;\n\n  @media screen and (min-width: 960px) {\n    &:focus-within {\n      border-width: 1px;\n      border-style: solid;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"languageSelector": `languageSelector-cssmodule-languageSelector-xmNf1`
};
export default ___CSS_LOADER_EXPORT___;
