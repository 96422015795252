import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function Default(props) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(0 3.5)">
            <rect width="24" height="17" fill="#97989A" rx="1" />
            <path
              fill="#FFF"
              d="M12.667 8.16v-3.4h7c.92 0 1.666.761 1.666 1.7s-.746 1.7-1.666 1.7h-7zM12.667 12.24V8.5H19.6c.966.056 1.733.872 1.733 1.87 0 1.033-.82 1.87-1.833 1.87l.1-.004v.004h-6.933zM12.333 8.913c0 2.025-1.641 3.667-3.666 3.667H6.333c-2.025 0-3.666-1.642-3.666-3.667v-.826c0-2.025 1.641-3.667 3.666-3.667h2.334c2.025 0 3.666 1.642 3.666 3.667v.073H7.2v.34h5.133v.413z"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
