import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { withTheme } from "@material-ui/core/styles";

function Check(props) {
  const { theme } = props;
  return (
    <SvgIcon
      {...props}
      style={{ fill: theme.palette.primary.main, ...props.style }}
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </SvgIcon>
  );
}

export default withTheme(Check);
