import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";

import {
  Button,
  Card,
  CircularProgress,
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";

import dayjs from "dayjs";
import { get } from "lodash";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";

import Autocomplete from "containers/Autocomplete/Autocomplete";
import { Badge } from "atoms/Badge/Badge";
import { CounterButtons } from "molecules/CounterButtons/CounterButtons";
import CustomDayPickerInput from "containers/CustomDayPickerInput/CustomDayPickerInput";
import FavoriteLoader from "./FavoriteLoader";
import { IndicationsToDriverInput } from "organisms/IndicationsToDriverInput/IndicationsToDriverInput";
import LastBookings from "./LastBookings";
import { PassengersIcon } from "icons/PassengersIcon";
import { PassengerProfiles } from "organisms/PassengerProfiles/PassengerProfiles";

import DropOff from "components/Icons/DropOff/DropOff";
import ExpandMore from "components/Icons/ExpandMore";

import PickUp from "components/Icons/PickUp/PickUp";
import Swap from "components/Icons/Swap";

import { TerritoryContext } from "contexts/TerritoryContext";
import { UserContext } from "contexts/UserContext";
import { ProductContext } from "contexts/ProductContext";
import {
  FORMAT_DATE,
  FORMAT_DATE_TIME,
  STATUS_AVAILABLE,
} from "utils/constants";

import { extrasToGenericSeats } from "lib/genericSeatsFunctions";

import { useSearchFormDeeplink } from "./useSearchFormDeeplink";
import { getFormattedPassengersProfile } from "containers/PassengersProfiles/actions";
import { fetchAllNodes, getPersonalItems } from "containers/SearchForm/actions";
import PersonalItems from "../organisms/PersonalItems/PersonalItems";
import { TimePicker } from "components/TimePicker/TimePicker";
import { GenerateDeeplinkBtn } from "./debug/GenerateDeeplinkBtn";

import { TooltipSelection } from "components/molecules/TooltipSelection/TooltipSelection";
import BusStopMarker from "components/Icons/BusStopMarker";
import { MapIcon } from "icons/MapIcon";

import styles from "./form.cssmodule.scss";

const useBusClasses = makeStyles({
  root: {
    width: "3.5rem",
    height: "3.5rem",
    // below is the custom "anchor point" of the resized svg
    position: "absolute",
    left: "-1.7rem",
    top: "-3rem",
    cursor: "pointer",
  },
});

export const DEPARTURE = "departure";
export const DESTINATION = "destination";
const TIME = "time";
const ASAP = "asap";
const TIME_RESTRICTION_TYPE = "timeRestrictionType";

export default function SearchForm({
  isMapOpen,
  toggleMap,
  actualizeDeparture,
  customFieldInfos,
  errors,
  formData,
  loadFavorite,
  onAddressSelected,
  onDayClick,
  onFieldChange,
  onPassengersChange,
  onPersonalItemsChange,
  onIndicationsToDriverChange,
  onSubmit,
  requesting,
  swapActive,
  swapAddresses,
  territory,
  nodes,
  isRequestingNodes,
  profiles,
  isAdmin,
}) {
  const {
    asap,
    customFields,
    departure,
    destination,
    passengers,
    recurrence,
    personalItems,
    selectedDays,
    time,
    timeRestrictionType,
  } = formData;

  const { isAuthenticated } = useContext(UserContext);
  const { selectedTerritory } = useContext(TerritoryContext);
  const { productParameters } = useContext(ProductContext);

  const intl = useIntl();

  const busClasse = useBusClasses();

  const mapRef = useRef();

  const favoriteEnabled =
    productParameters?.features?.favorites?.enabled || false;
  const isMultiDateEnabled = territory?.booking?.multi_date?.enabled;
  const shouldEnableRecurringBookings =
    isAdmin ||
    !territory?.booking?.recurring_bookings?.restricted_to_call_centers;
  const recurringOffers = shouldEnableRecurringBookings
    ? territory?.booking?.recurring_bookings?.offers || []
    : [];

  const maxSeats = territory?.booking?.max_seats_in_bus || 0;

  const deeplinkLogic = useSearchFormDeeplink(
    maxSeats,
    actualizeDeparture,
    onDayClick,
    onFieldChange,
    onPassengersChange,
    onAddressSelected,
    (value) => {
      setPassengerNumber((old) => ({
        ...old,
        standard: value,
      }));
    },
  );

  const {
    deeplinkParameters,
    addressFormHelpers,
    timePickerDefaultValue,
    deeplinkRequestedSeats,
    setIsDeeplinkDepartureValidate,
    setIsDeeplinkDestinationValidate,
  } = deeplinkLogic;

  const extras = territory?.extras || {};
  const extrasTypes = extrasToGenericSeats(extras);
  const maxExtras = extrasTypes.reduce((maxExtras, type) => {
    maxExtras[type] = _.get(extras, type + ".max_" + type + "_seats_in_bus", 0);

    return maxExtras;
  }, {});

  const maxBookingDay = territory?.booking?.max_booking_day;
  const lastBookingDate = maxBookingDay
    ? dayjs(new Date()).add(maxBookingDay, "day").toDate()
    : undefined;

  const currentOffer = recurringOffers.find(
    (offer) => offer.id === formData?.recurrence.id,
  );

  const currentOfferEndDate = currentOffer?.end_date
    ? dayjs(currentOffer.end_date).toDate()
    : undefined;
  setIsDeeplinkDepartureValidate;

  const firsEnabledDateOnSecondDatePicker = dayjs(
    recurrence.start_datetime,
  ).add(1, "day");

  const defaultPassengers = useMemo(() => {
    return {
      ...Object.keys(profiles).reduce((acc, key) => {
        acc[key] = profiles[key].count;
        return acc;
      }, {}),
    };
  }, [profiles]);
  extrasTypes.map((type) => {
    defaultPassengers[type] = passengers[type] || 0;
  });

  const onSelect = (p, type) => {
    setIsDeeplinkDestinationValidate(true);
    onAddressSelected(
      p.suggestion,
      p.nodeId,
      type,
      p.latitude,
      p.longitude,
      p.address,
      p.placeId,
    );
    if (p.latitude && p.longitude) {
      mapRef.current.panTo({
        lat: p.latitude,
        lng: p.longitude,
      });
    }
  };

  const theme = useTheme();

  const getDefaultCenter = () => ({
    lat:
      departure?.latitude && destination?.latitude
        ? (departure.latitude + destination.latitude) / 2
        : selectedTerritory?.geography.default_latitude,
    lng:
      departure?.longitude && destination?.longitude
        ? (departure.longitude + destination.longitude) / 2
        : selectedTerritory?.geography.default_longitude,
  });

  const radiusCircle = new window.google.maps.Circle({
    center: {
      ...getDefaultCenter(),
    },
    radius: territory?.geography?.default_radius,
  });

  const [passengerNumber, setPassengerNumber] = useState(defaultPassengers);
  const [totalPassenger, setTotalPassenger] = useState(1);
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);
  const [center, setCenter] = useState(getDefaultCenter());

  useEffect(() => {
    setPassengerNumber(defaultPassengers);
  }, [defaultPassengers]);

  useEffect(() => {
    let total = 0;
    Object.keys(passengerNumber).forEach((key) => {
      total += passengerNumber[key];
    });
    setTotalPassenger(total);
  }, [passengerNumber]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (territory) {
      dispatch(
        getFormattedPassengersProfile({
          territoryId: territory?.territory_id,
          deepLinkRequestedSeats: deeplinkRequestedSeats,
        }),
      );
      dispatch(fetchAllNodes(territory));
      setCenter(getDefaultCenter());
    }
  }, [territory, deeplinkRequestedSeats, dispatch]);

  useEffect(() => {
    if (territory && Object.keys(personalItems.personalItems).length === 0) {
      dispatch(getPersonalItems(territory));
    }
  }, [territory, dispatch]);

  const indicationsToDriver = formData.indicationsToDriver;

  const isIndicationsToDriverEnabled =
    selectedTerritory?.booking?.comment?.enabled;

  const currentValueExtras = extrasTypes.reduce((acc, type) => {
    acc += passengerNumber[type] || 0;
    return acc;
  }, 0);

  const points =
    nodes
      ?.map((node) => {
        return {
          type: "Feature",
          properties: {
            cluster: false,
            nodeId: node.id,
            name: node.name,
            address: node.position.address,
          },
          geometry: {
            type: "Point",
            coordinates: [
              parseFloat(node.position.longitude),
              parseFloat(node.position.latitude),
            ],
          },
        };
      })
      .filter((n) => n) || [];

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20, minPoints: 4 },
  });

  const Marker = ({ children }) => children;

  return (
    <div
      className={`${styles.grid} ${!isMapOpen && styles.solo} ${
        !isMapOpen && styles.scaleIn
      }`}
    >
      <div className={isMapOpen ? styles.form : styles.fullForm}>
        <Paper className="MuiEngagementPaper--01">
          <form
            className={"padded"}
            style={{ justifyContent: "space-around" }}
            onSubmit={(e) => e.preventDefault()}
          >
            <div
              className="column padded"
              style={{ flex: "1 1 45%", marginRight: "0.5rem" }}
            >
              <div className="column" style={{ justifyContent: "flex-start" }}>
                <Button
                  startIcon={<MapIcon />}
                  variant="outlined"
                  color="inherit"
                  disabled={requesting}
                  onClick={toggleMap}
                  data-testid="searchform-toggle-map-btn"
                  className={styles.toggleMapBtn}
                >
                  <Typography variant="body1">
                    {intl.formatMessage({
                      id: isMapOpen
                        ? "search.toggle_map_off"
                        : "search.toggle_map",
                    })}
                  </Typography>
                </Button>
                <div className={styles.autoCompletes}>
                  <div style={{ width: "100%" }}>
                    <Typography
                      className={styles.searchLabels}
                      variant="caption"
                    >
                      {intl.formatMessage({ id: "search.pickup.placeholder" })}
                    </Typography>

                    <div
                      className={`row-only rounded-xl border-2 border-gray-300 hover:border-gray-800 ${
                        Boolean(
                          addressFormHelpers.isDepartureDeeplinkHelper ||
                            errors.departure,
                        ) && styles.autoCompleteHelper
                      }`}
                      style={{
                        border:
                          addressFormHelpers.isDepartureDeeplinkHelper &&
                          "red 1px solid",
                      }}
                    >
                      <PickUp
                        className="mt-1 h-10 rounded-l-md p-0.5"
                        aria-hidden
                      />

                      <Autocomplete
                        className={`${styles.displaySearch} ${styles.autoComplete}`}
                        id="pickup-autocomplete"
                        context="search"
                        inputProps={{
                          label: intl.formatMessage({
                            id: "search.pickup.placeholder",
                          }),
                          text: departure?.display || "",
                          placeholder: intl.formatMessage({
                            id: "help.address",
                          }),
                          helperText:
                            addressFormHelpers.isDepartureDeeplinkHelper ||
                            errors.departure,
                        }}
                        onChange={(e) => {
                          onFieldChange(DEPARTURE, {
                            display: e.target.value,
                          });
                        }}
                        onSelect={(p) => {
                          onSelect(p, DEPARTURE);
                        }}
                      />
                    </div>

                    <Typography
                      className={styles.searchLabels}
                      variant="caption"
                    >
                      {intl.formatMessage({ id: "search.dropoff.placeholder" })}
                    </Typography>

                    <div
                      className="row-only rounded-xl border-2 border-gray-300 hover:border-gray-800"
                      style={{
                        border:
                          addressFormHelpers.isDestinationDeeplinkHelper !==
                            "" && "red 1px solid",
                      }}
                    >
                      <DropOff
                        className="mt-1 h-10 rounded-l-md p-0.5"
                        aria-hidden
                      />

                      <Autocomplete
                        className={`${styles.displaySearch} ${styles.autoComplete}`}
                        id="dropoff-autocomplete"
                        context="search"
                        inputProps={{
                          label: intl.formatMessage({
                            id: "search.dropoff.placeholder",
                          }),
                          text: destination?.display || "",
                          placeholder: intl.formatMessage({
                            id: "help.address",
                          }),
                          helperText:
                            addressFormHelpers.isDestinationDeeplinkHelper ||
                            errors.destination,
                        }}
                        onChange={(e) => {
                          onFieldChange(DESTINATION, {
                            display: e.target.value,
                          });
                        }}
                        onSelect={(p) => {
                          onSelect(p, DESTINATION);
                        }}
                      />
                    </div>
                  </div>

                  <div className={styles.swapIcon}>
                    {swapActive && (
                      <IconButton
                        title={intl.formatMessage({ id: "help.swap" })}
                        aria-label={intl.formatMessage({ id: "help.swap" })}
                        onClick={swapAddresses}
                        data-testid="swap-button"
                      >
                        <Swap aria-hidden />
                      </IconButton>
                    )}
                  </div>
                </div>

                {isAuthenticated && (
                  <>
                    {favoriteEnabled && (
                      <FavoriteLoader loadFavorite={loadFavorite} />
                    )}

                    <LastBookings />
                  </>
                )}
              </div>
            </div>

            <div className="column aligned padded" style={{ flex: "1 1 45%" }}>
              <div
                className="column aligned fullWidth"
                style={{ flexGrow: 1, justifyContent: "flex-start" }}
              >
                <Card className={`${styles.card}`}>
                  <div className="column aligned fullWidth">
                    <FormControl
                      className={styles.btnsFieldset}
                      component="fieldset"
                    >
                      <FormLabel
                        style={{ padding: "0.75rem 0.4rem", width: "100%" }}
                        component="legend"
                      >
                        <Typography
                          style={{ width: "100%", color: "#5C5C5C" }}
                          variant="caption"
                          component="h2"
                        >
                          {intl.formatMessage({ id: "section.time" })}
                        </Typography>
                      </FormLabel>
                      <RadioGroup
                        className={`fullWidth ${styles.radioBtnsGroup}`}
                        row
                        value={asap ? ASAP : timeRestrictionType}
                        role="none"
                        onChange={(e) => {
                          if (e.target.value === ASAP) {
                            actualizeDeparture();
                          } else {
                            onFieldChange(ASAP, false);
                            onFieldChange(
                              TIME_RESTRICTION_TYPE,
                              e.target.value,
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          label={intl.formatMessage({
                            id: "search.departureNow",
                          })}
                          htmlFor="asap"
                          value="asap"
                          control={
                            <Radio
                              style={{ color: "#404148" }}
                              className={styles.root}
                              id="asap"
                            />
                          }
                        />

                        {!!maxBookingDay && (
                          <>
                            <FormControlLabel
                              label={intl.formatMessage({
                                id: "search.departure_time",
                              })}
                              htmlFor="departure"
                              value="departure"
                              control={
                                <Radio
                                  style={{ color: "#404148" }}
                                  className={styles.root}
                                  id="departure"
                                />
                              }
                              data-testid="departure-input"
                            />
                            <FormControlLabel
                              label={intl.formatMessage({
                                id: "search.arrival_time",
                              })}
                              htmlFor="arrival"
                              value="arrival"
                              control={
                                <Radio
                                  style={{ color: "#404148" }}
                                  className={styles.root}
                                  id="arrival"
                                />
                              }
                            />
                          </>
                        )}
                      </RadioGroup>
                    </FormControl>

                    <Collapse in={!asap} style={{ width: "100%" }}>
                      <div className={styles.selectTime}>
                        <TimePicker
                          defaultValue={timePickerDefaultValue}
                          disabled={asap}
                          error={Boolean(errors.time)}
                          helperText={errors && errors.time}
                          onFieldChange={(newTime) =>
                            onFieldChange(
                              TIME,
                              newTime.format(FORMAT_DATE_TIME),
                            )
                          }
                          tabIndex={0}
                          selectedDays={(!asap ? selectedDays : [time]) || []}
                          onDayClick={(day, r) =>
                            onDayClick(dayjs(day).format(FORMAT_DATE), r)
                          }
                          errorText={errors.selectedDays}
                          maxBookingDay={lastBookingDate}
                          name="date"
                          label={
                            isMultiDateEnabled
                              ? intl.formatMessage({ id: "search.the" })
                              : intl.formatMessage({ id: "search.monoDate" })
                          }
                        />

                        {(!recurringOffers || recurringOffers.length === 0) && (
                          <CustomDayPickerInput
                            disabled={asap}
                            tabIndex={0}
                            selectedDays={(!asap ? selectedDays : [time]) || []}
                            onDayClick={(day, r) =>
                              onDayClick(dayjs(day).format(), r)
                            }
                            errorText={errors.selectedDays}
                            maxBookingDay={lastBookingDate}
                            name="date"
                            label={
                              isMultiDateEnabled
                                ? intl.formatMessage({ id: "search.the" })
                                : intl.formatMessage({ id: "search.monoDate" })
                            }
                          />
                        )}

                        {recurringOffers &&
                          recurringOffers.length > 0 &&
                          recurrence.id === 0 && (
                            <CustomDayPickerInput
                              disabled={asap}
                              tabIndex={0}
                              selectedDays={
                                (!asap ? selectedDays : [time]) || []
                              }
                              onDayClick={(day, r) =>
                                onDayClick(dayjs(day).format(FORMAT_DATE), r)
                              }
                              errorText={errors.selectedDays}
                              maxBookingDay={lastBookingDate}
                              name="date"
                              label={
                                isMultiDateEnabled
                                  ? intl.formatMessage({ id: "search.the" })
                                  : intl.formatMessage({
                                      id: "search.monoDate",
                                    })
                              }
                            />
                          )}

                        {recurringOffers &&
                          recurringOffers.length > 0 &&
                          recurrence.id !== 0 && (
                            <div className={`column ${styles.recurringOffers}`}>
                              <CustomDayPickerInput
                                name="startDate"
                                maxBookingDay={currentOfferEndDate}
                                selectedDays={
                                  recurrence.start_datetime
                                    ? [recurrence.start_datetime]
                                    : []
                                }
                                onDayClick={(day) =>
                                  onFieldChange("recurrence", {
                                    ...recurrence,
                                    start_datetime:
                                      dayjs(day).format(FORMAT_DATE),
                                  })
                                }
                                errorText={errors.selectedDays}
                                label={intl.formatMessage({
                                  id: "search.recurrence.start_date",
                                })}
                              />

                              <CustomDayPickerInput
                                name="endDate"
                                disabled={asap}
                                selectedDays={
                                  recurrence.end_datetime
                                    ? [recurrence.end_datetime]
                                    : []
                                }
                                maxBookingDay={currentOfferEndDate}
                                addDisabledDays={[
                                  {
                                    before:
                                      firsEnabledDateOnSecondDatePicker.toDate(),
                                  },
                                ]}
                                fromMonth={firsEnabledDateOnSecondDatePicker.toDate()}
                                onDayClick={(day) =>
                                  onFieldChange("recurrence", {
                                    ...recurrence,
                                    end_datetime:
                                      dayjs(day).format(FORMAT_DATE),
                                  })
                                }
                                errorText={errors.selectedDays}
                                label={intl.formatMessage({
                                  id: "search.recurrence.end_date",
                                })}
                              />
                            </div>
                          )}
                      </div>

                      {recurringOffers && recurringOffers.length > 0 && (
                        <div className={styles.recurring}>
                          <label
                            htmlFor={"recurrence-offer-select"}
                            style={{
                              fontWeight: "600",
                              fontSize: "14px",
                            }}
                          >
                            {intl.formatMessage({ id: "help.recurrenceOffer" })}
                          </label>
                          <TextField
                            required
                            id="recurrence-offer-select"
                            select
                            name="recurrenceOffer"
                            className={`column ${styles.selectTargetMode}`}
                            value={recurrence.id}
                            style={asap ? { color: "#ccc" } : null}
                            disabled={asap}
                            onChange={(e) => {
                              onFieldChange("recurrence", {
                                ...recurrence,
                                id: e.target.value,
                              });
                            }}
                            InputProps={{
                              className: styles.input,
                              disableUnderline: true,
                            }}
                            SelectProps={{
                              IconComponent: (props) => (
                                <ExpandMore {...props} />
                              ),
                            }}
                          >
                            <MenuItem value={0}>
                              {intl.formatMessage({
                                id: "search.recurrence.doNotRepeat",
                              })}
                            </MenuItem>

                            {recurringOffers.map((offer, index) => (
                              <MenuItem key={index} value={offer.id}>
                                {offer.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      )}
                    </Collapse>
                  </div>
                </Card>

                <Card className={`${styles.card}`}>
                  <div className="column aligned fullWidth">
                    <FormLabel
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "1rem",
                        width: "100%",
                        padding: "0 0.4rem",
                        boxSizing: "border-box",
                      }}
                      component="legend"
                    >
                      <Typography
                        style={{ color: "#5C5C5C" }}
                        variant="caption"
                      >
                        {intl.formatMessage({ id: "search.passengersNumber" })}
                      </Typography>

                      <Badge
                        iconElement={<PassengersIcon />}
                        label={totalPassenger}
                      />
                    </FormLabel>

                    <div className="fullWidth">
                      <PassengerProfiles
                        maxAllPassengersCount={maxSeats - currentValueExtras}
                        mainProfileDefaultCount={
                          deeplinkParameters.isDeepLink &&
                          deeplinkRequestedSeats
                        }
                        onChange={(value, key) => {
                          onPassengersChange(key, value);
                          setPassengerNumber({
                            ...passengerNumber,
                            [key]: value,
                          });
                        }}
                      />

                      {extrasTypes.map((type) => {
                        return (
                          <CounterButtons
                            key={type + "_counter"}
                            decButtonAriaLabel={intl.formatMessage(
                              { id: "help.passengersSubstract" },
                              {
                                number: passengers[type] || 0,
                                type: intl.formatMessage({
                                  id: "search.nb_standards",
                                }),
                              },
                            )}
                            defaultValue={passengerNumber[type]}
                            incButtonAriaLabel={intl.formatMessage(
                              { id: "help.passengersAdd" },
                              {
                                number: passengers[type],
                                type: intl.formatMessage({
                                  id: "search.nb_standards",
                                }),
                              },
                            )}
                            label={extras[type]?.display || extras[type]?.name}
                            minValue={0}
                            maxValue={
                              extras[type]?.requires_standard_seat
                                ? Math.min(
                                    maxExtras[type],
                                    maxSeats -
                                      totalPassenger +
                                      passengers[type],
                                  )
                                : maxExtras[type]
                            }
                            onChange={(value) => {
                              onPassengersChange(type, value);
                              setPassengerNumber({
                                ...passengerNumber,
                                [type]: value,
                              });
                            }}
                            type={type}
                          />
                        );
                      })}
                    </div>
                  </div>

                  {totalPassenger === 0 && (
                    <Typography color="error" variant="caption">
                      {intl.formatMessage({ id: "search.error.nbPassengers" })}
                    </Typography>
                  )}

                  {totalPassenger === maxSeats && (
                    <Typography color="error" variant="caption">
                      {intl.formatMessage({ id: "search.error.maxPassengers" })}
                    </Typography>
                  )}

                  {customFields && (
                    <div className="column spaced" style={{ flexWrap: "wrap" }}>
                      {_.map(
                        _.keys(customFieldInfos).sort(),
                        (cf_key, name) => {
                          const cf = customFieldInfos[cf_key];

                          return (
                            <div
                              className="ron-only column"
                              style={{ padding: "1rem 0 0 0" }}
                              key={"cf-" + name}
                            >
                              <TextField
                                id={cf.display_name}
                                required={Boolean(cf.is_required)}
                                label={cf.display_name}
                                style={{ width: "90%" }}
                                select={cf.type === "select"}
                                value={
                                  _.get(formData, "customFields." + cf_key) ||
                                  ""
                                }
                                onChange={(e) =>
                                  onFieldChange("customFields", {
                                    ...customFields,
                                    [cf_key]: e.target.value,
                                  })
                                }
                                error={Boolean(
                                  get(errors, "customFields." + cf_key),
                                )}
                                helperText={get(
                                  errors,
                                  "customFields." + cf_key,
                                )}
                                variant="outlined"
                                SelectProps={{
                                  IconComponent: () => (
                                    <ExpandMore
                                      style={{
                                        position: "absolute",
                                        right: 10,
                                      }}
                                    />
                                  ),
                                }}
                              >
                                {_.map(_.get(cf, "options", []), (m, index) => (
                                  <MenuItem key={index} value={m.key}>
                                    {m.display}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </div>
                          );
                        },
                      )}
                    </div>
                  )}
                </Card>

                <PersonalItems
                  onPersonalItemsChange={onPersonalItemsChange}
                  personalItems={personalItems}
                />

                {isIndicationsToDriverEnabled && (
                  <div
                    style={{
                      width: "100%",
                      margin: "1.5rem 0 0 0",
                      transform: "translateX(-1rem)",
                    }}
                  >
                    <IndicationsToDriverInput
                      bookingId={null}
                      isSearchRequest={true}
                      onIndicationsToDriverChange={onIndicationsToDriverChange}
                      previousIndications={indicationsToDriver}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        </Paper>

        <div
          className="row"
          style={{
            paddingTop: "1rem",
            flexDirection: "row-reverse",
            flexWrap: "wrap",
            gap: "1rem",
          }}
        >
          <GenerateDeeplinkBtn
            territoryKey={selectedTerritory?.territory_key}
            departure={departure?.display}
            destination={destination?.display}
            totalPassenger={totalPassenger}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={requesting}
            onClick={onSubmit}
            style={{ minWidth: "15rem" }}
            data-testid="searchform-submit-btn"
          >
            <Typography variant="body1">
              {intl.formatMessage({ id: "search.submit" })}
            </Typography>
          </Button>
        </div>
      </div>
      {isMapOpen && (
        <div className={styles.map}>
          {territory && !isRequestingNodes ? (
            <GoogleMapReact
              yesIWantToUseGoogleMapApiInternals
              bootstrapURLKeys={{
                key: GOOGLE_MAPS_API_KEY,
                libraries: ["places"],
              }}
              center={center}
              defaultZoom={13}
              onGoogleApiLoaded={({ map }) => {
                if (radiusCircle.getBounds())
                  (mapRef.current = map).fitBounds(radiusCircle.getBounds());
              }}
              onChange={({ zoom, bounds }) => {
                setZoom(zoom);
                setBounds([
                  bounds.nw.lng,
                  bounds.se.lat,
                  bounds.se.lng,
                  bounds.nw.lat,
                ]);
              }}
              title="map"
            >
              {clusters.map((node) => {
                const {
                  cluster: isCluster,
                  point_count: pointCount,
                  name,
                  address,
                  nodeId,
                } = node.properties;
                const [longitude, latitude] = node.geometry.coordinates;
                const nodeColor = () => {
                  if (destination?.display === name)
                    return theme.palette.dropoff.main;
                  else if (departure?.display === name)
                    return theme.palette.pickup.main;
                  return "black";
                };
                if (isCluster === false) {
                  return (
                    <BusStopMarker
                      titleAccess={name}
                      tooltip={
                        <TooltipSelection
                          isRequesting={requesting}
                          onAddressSelected={(type) => {
                            onAddressSelected(
                              name,
                              nodeId,
                              type,
                              latitude,
                              longitude,
                              address,
                              null,
                            );
                          }}
                          name={name}
                        />
                      }
                      key={`marker-${node.properties.nodeId}`}
                      classes={busClasse}
                      lng={longitude}
                      lat={latitude}
                      color={nodeColor()}
                      ariaLabel={intl.formatMessage({
                        id: "search.pickup.placeholder",
                      })}
                    />
                  );
                }
                return (
                  <Marker
                    key={`cluster-${node.id}`}
                    lat={latitude}
                    lng={longitude}
                  >
                    <div
                      className={styles.clusterMarker}
                      style={{
                        width: `${30 + (pointCount / points?.length) * 20}px`,
                        height: `${30 + (pointCount / points?.length) * 20}px`,
                      }}
                      onClick={() => {
                        const expansionZoom = Math.min(
                          supercluster.getClusterExpansionZoom(node.id),
                          20,
                        );
                        mapRef.current.setZoom(expansionZoom);
                        mapRef.current.panTo({ lat: latitude, lng: longitude });
                      }}
                    >
                      {pointCount}
                    </div>
                  </Marker>
                );
              })}
            </GoogleMapReact>
          ) : (
            <div className={styles.loading}>
              <CircularProgress />
              <Typography variant="body">
                {intl.formatMessage({ id: "search.map.loading" })}
              </Typography>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
