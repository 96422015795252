import cookie from "lib/cookie.js";
import { settings } from "config/app";

/**
 * Generate authorization headers
 */
export default class Authorization {
  /**
   * Compute authorization headers. The impersonate
   * parameter allows us to send requests on behalf of
   * other users. See api resource definition @api/resources
   *
   * @param  boolean impersonate
   * @return
   */
  static getHeaders(impersonate) {
    const headers = { Authorization: "" };
    const impersonateToken = cookie.get(settings.cookieKeys.impersonateCookie);
    const authToken = cookie.get(settings.cookieKeys.authCookie);
    const isLoggedWithOpenid = cookie.get(
      settings.cookieKeys.isLoggedWithOpenid,
    );

    if (impersonateToken && impersonate) {
      return (headers.Authorization = `Token ${impersonateToken}`);
    }
    // Bearer for SSO login
    if (isLoggedWithOpenid && authToken)
      return (headers.Authorization = `Bearer ${authToken}`);

    if (authToken) return (headers.Authorization = `Token ${authToken}`);
  }
}
