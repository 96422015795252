import {
  CONFIRM_PROMOCODE,
  HIDE_CARD_FORM,
  RECEIVE_SAVED_CARDS,
  REQUEST_PAYPAL_PAYMENT_ERROR,
  REQUEST_PROMOCODE,
  REQUEST_PROMOCODE_ERROR,
  REQUEST_STRIPE_PAYMENT,
  REQUEST_STRIPE_PAYMENT_ERROR,
  RECEIVE_STRIPE_TOKEN,
  RESET_PROMO_CODE,
  RESET_TICKETING,
  SELECT_CARD,
  SET_APPLICABLE_DISCOUNTS,
  SET_APPLICABLE_DISCOUNTS_LOADING,
  SET_DISCOUNT_PRICE_LOADING,
  SET_DISCOUNTED_PRICE,
  SET_DISCOUNTED_PRICE_MULTI_DATE,
  SET_PAYPAL_PAYMENT,
  SET_SELECTED_DISCOUNTS,
  SET_SELECTED_TRIP_PROPOSAL_ID,
  SET_TOTAL_PRICE,
  SHOW_CARD_FORM,
  SHOW_IBAN_FORM,
  HIDE_IBAN_FORM,
  SELECT_IBAN,
  RECEIVE_SAVED_IBANS,
  REQUEST_SAVED_PAYMENT_METHODS,
  REQUEST_SAVED_PAYMENT_METHODS_ERROR,
} from "./actions.js";

const initialState = () => ({
  totalPrice: 0,
  isRequestingPromoCode: false,
  // null state is used as "undefined state" rather than "false"
  promoCode: "",
  savedCards: [],
  isRequestingSavedPaymentMethods: false,
  paypalPaymentCaptured: false,
  selectedCardIndex: -1, // is index in savedCards OR -1 if new card
  isRequestingStripe: false,
  stripeError: null,
  paypalError: null,
  showCardForm: false,
  hideCardForm: true,
  savedIBANs: [],
  isRequestingSavedIBANs: false,
  selectedIBANIndex: -1, // is index in savedCards OR -1 if new card
  showIBANForm: false,
  hideIBANForm: true,
  errorMessage: "",
  isPromoCodeValid: null,
  selectedDiscounts: [],
  isDiscountPriceLoading: false,
  discountedPrices: null,
  discountedPricesMultiDate: null,
  isApplicableDiscountsLoading: false,
  applicableDiscounts: null,
  selectedTripProposalId: null,
});

export const payment = (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_PROMOCODE:
      return {
        ...state,
        isRequestingPromoCode: true,
        promoCode: action.promoCode,
        errorMessage: "",
      };
    case REQUEST_PROMOCODE_ERROR:
      return {
        ...state,
        isRequestingPromoCode: false,
        errorMessage: action.errorMessage,
      };
    case CONFIRM_PROMOCODE:
      return {
        ...state,
        isRequestingPromoCode: false,
        isPromoCodeValid: action.isPromoCodeValid,
        errorMessage: "",
      };
    case SET_PAYPAL_PAYMENT:
      return {
        ...state,
        paypalPaymentCaptured: action.paypalPaymentCaptured,
        paypalError: null,
      };
    case REQUEST_PAYPAL_PAYMENT_ERROR:
      return {
        ...state,
        paypalPaymentCaptured: false,
        paypalError: action.error,
      };
    case REQUEST_SAVED_PAYMENT_METHODS:
      return {
        ...state,
        isRequestingSavedPaymentMethods: true,
      };
    case REQUEST_SAVED_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        isRequestingSavedPaymentMethods: false,
        // savedCards: []
      };
    case SET_SELECTED_DISCOUNTS:
      return {
        ...state,
        selectedDiscounts: action.selectedDiscounts,
      };
    case SET_DISCOUNT_PRICE_LOADING:
      return {
        ...state,
        isDiscountPriceLoading: action.isDiscountPriceLoading,
      };
    case SET_DISCOUNTED_PRICE:
      return {
        ...state,
        discountedPrices: action.discountedPrices,
      };
    case SET_DISCOUNTED_PRICE_MULTI_DATE:
      return {
        ...state,
        discountedPricesMultiDate: action.discountedPricesMultiDate,
      };
    case RESET_PROMO_CODE:
      return {
        ...state,
        isDiscountPriceLoading: false,
        discountedPrices: null,
        discountedPricesMultiDate: null,
        promoCode: "",
        isPromoCodeValid: null,
      };
    case SET_APPLICABLE_DISCOUNTS_LOADING:
      return {
        ...state,
        isApplicableDiscountsLoading: action.isApplicableDiscountsLoading,
      };
    case SET_APPLICABLE_DISCOUNTS:
      return {
        ...state,
        applicableDiscounts: action.applicableDiscounts,
      };
    case SET_SELECTED_TRIP_PROPOSAL_ID:
      return {
        ...state,
        selectedTripProposalId: action.selectedTripProposalId,
      };
    case RESET_TICKETING:
      return {
        ...state,
        selectedDiscounts: [],
        // selectedTripProposalId: null,
        // applicableDiscounts: null,
        // isApplicableDiscountsLoading: false,
      };
    case RECEIVE_SAVED_CARDS:
      return {
        ...state,
        isRequestingSavedPaymentMethods: false,
        savedCards: action.savedCards,
        // if no savedCards, open form and "select" it
        isCardFormOpen: _.isEmpty(action.savedCards),
        // auto-selects first element if there is one
        selectedCardIndex: _.isEmpty(action.savedCards) ? -1 : 0,
      };
    case RECEIVE_SAVED_IBANS:
      return {
        ...state,
        isRequestingSavedPaymentMethods: false,
        savedIBANs: action.savedIBANs,
        // if no savedCards, open form and "select" it
        isIBANFormOpen: _.isEmpty(action.savedIBANs),
        // auto-selects first element if there is one
        selectedIBANIndex: _.isEmpty(action.savedIBANs) ? -1 : 0,
      };
    case SHOW_CARD_FORM:
      return {
        ...state,
        isCardFormOpen: true,
        selectedCardIndex: -1,
      };
    case HIDE_CARD_FORM:
      return {
        ...state,
        isCardFormOpen: false,
      };
    case SHOW_IBAN_FORM:
      return {
        ...state,
        isIBANFormOpen: true,
        selectedIBANIndex: -1,
      };
    case HIDE_IBAN_FORM:
      return {
        ...state,
        isIBANFormOpen: false,
      };
    case SELECT_CARD:
      return {
        ...state,
        selectedCardIndex: action.index,
      };
    case SELECT_IBAN:
      return {
        ...state,
        selectedIBANIndex: action.index,
      };
    case SET_TOTAL_PRICE:
      // going to PaymentView, used as initialization
      return {
        ...state,
        totalPrice: action.newPrice,
        stripeError: null,
      };
    case REQUEST_STRIPE_PAYMENT:
      return {
        ...state,
        isRequestingStripe: true,
      };
    case REQUEST_STRIPE_PAYMENT_ERROR:
      return {
        ...state,
        isRequestingStripe: false,
        stripeError: action.error,
      };
    case RECEIVE_STRIPE_TOKEN:
      return {
        ...state,
        isRequestingStripe: false,
        stripeError: null,
      };
    default:
      return state;
  }
};
