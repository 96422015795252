import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useRideDetailsSimplifiedStyles = makeStyles(
  (theme: CustomTheme) => ({
    busIcon: {
      width: "1.875rem",
      height: "1.875rem",
    },
    rideDetailsContainer: {
      padding: "1rem",
    },
    rideStepList: {
      padding: 0,

      listStyle: "none",

      "& li": {
        margin: "0 0 0 2rem",
        padding: "0 1rem 2rem 2rem",
        borderLeft: "0.125rem dashed #717587",
      },

      "& li:last-child": {
        padding: "0 1rem 0 2rem",
        borderColor: "transparent",
      },
    },
    stepIcon: {
      position: "absolute",
      left: "-1rem",

      background: "white",
    },
    walkerIcon: {
      width: "1.375rem",
      height: "1.375rem",
      padding: "0.125rem",
      border: "0.125rem solid transparent",
      borderRadius: "50%",
    },

    // let this classes here, to override previous classes:
    dropoffColors: {
      borderColor: theme.palette.dropoff.main,

      fill: theme.palette.dropoff.main,

      "& circle": {
        fill: theme.palette.dropoff.main,
      },
    },
    pickupColors: {
      borderColor: theme.palette.pickup.main,

      fill: theme.palette.pickup.main,

      "& circle": {
        fill: theme.palette.pickup.main,
      },
    },
  }),
);
