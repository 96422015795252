import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  IconButton,
  Paper,
  Switch,
  Tooltip,
  Typography,
  useTheme,
} from "@material-ui/core";

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import Interrogation from "components/Icons/Interrogation";
import { StripeElementStyle } from "@stripe/stripe-js";

type CardProps = {
  stripeStyle: StripeElementStyle;
  handleChange: (e: any) => void;
  errorMessage: string;
  shouldSavePaymentMethod: boolean;
  setShouldSavePaymentMethod: (value: boolean) => void;
};

export function Card(props: CardProps) {
  const {
    stripeStyle,
    handleChange,
    errorMessage,
    shouldSavePaymentMethod,
    setShouldSavePaymentMethod,
  } = props;
  const theme = useTheme();
  const intl = useIntl();

  return (
    <Paper
      className="rounded-lg p-3 "
      style={{
        backgroundColor: "lightgrey",
      }}
    >
      <div className="flex flex-row px-3">
        <div className="stripeLabel w-full">
          <FormattedMessage id="payment.cardNumber" />

          <CardNumberElement
            className="w-full"
            onChange={(e) => handleChange(e)}
            options={{ style: stripeStyle }}
          />
        </div>
      </div>

      <div className="flex flex-row">
        <div className="stripeLabel w-6/12 px-3">
          <FormattedMessage id="payment.expiration_date" />

          <CardExpiryElement
            className="w-8/12 md:w-6/12"
            onChange={(e) => handleChange(e)}
            options={{ style: stripeStyle }}
          />
        </div>

        <div className="stripeLabel w-6/12 px-3">
          <FormattedMessage id="payment.cvc" />

          <CardCvcElement
            className="w-6/12 md:w-4/12"
            onChange={(e) => handleChange(e)}
            options={{ style: stripeStyle }}
          />
        </div>
      </div>

      {errorMessage && errorMessage.length > 0 && (
        <Typography
          style={{ padding: "1rem", color: theme.palette.error.main }}
          variant="caption"
        >
          {errorMessage}
        </Typography>
      )}

      <div
        className="row"
        style={{
          width: "100%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="row-only" style={{ alignItems: "center" }}>
          <Switch
            name="save card for future use"
            checked={shouldSavePaymentMethod}
            aria-label={intl.formatMessage({ id: "payment.saveCard" })}
            inputProps={{ "aria-labelledby": "save-card" }}
            onChange={(e) => setShouldSavePaymentMethod(e.target.checked)}
          />

          <Typography id="save-card" variant="body2">
            <FormattedMessage id="payment.saveCard" />
          </Typography>

          <Tooltip
            id="save-card-2"
            title={<FormattedMessage id="payment.saveCard2" />}
            tabIndex={-1}
          >
            <IconButton tabIndex={-1} aria-labelledby="save-card-2">
              <Interrogation tabIndex={-1} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Paper>
  );
}
