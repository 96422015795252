import React from "react";

import { SvgIcon } from "@material-ui/core";

type OpenNewTabIconProps = {
  className?: string;
};

export function OpenNewTabIcon({ className, ...props }: OpenNewTabIconProps) {
  return (
    <SvgIcon className={className} {...props}>
      <path
        d="M10.908 8.182v1.363H8.182v12.273h12.273v-2.727h1.363v2.727c0 .753-.61 1.364-1.363 1.364H8.182c-.753 0-1.364-.61-1.364-1.364V9.545c0-.753.61-1.363 1.364-1.363h2.726zm10.228 0l.096.007.022.003c.03.005.06.012.09.022l.005.002.023.008c.09.033.174.085.246.158l.035.037.014.017-.045-.05.054.063.034.046c.012.018.023.037.032.056l.02.04.02.055.016.05.01.045c.007.043.01.08.01.116v5.461c0 .377-.305.682-.682.682-.334 0-.613-.241-.67-.56l-.011-.122v-3.81l-7.515 7.516c-.266.266-.698.266-.964 0-.237-.237-.263-.604-.079-.87l.08-.094 7.512-7.515h-3.807c-.335 0-.613-.24-.671-.559L15 8.864c0-.335.241-.613.56-.671l.122-.011h5.454z"
        transform="translate(-583 -351) translate(583 351)"
      />
    </SvgIcon>
  );
}
