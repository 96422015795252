import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";

import TermsDialog from "components/Terms/TermsDialog";

import { UserContext } from "contexts/UserContext";

function TermsDialogContainer({ impersonate }) {
  const { userProfile, updateHasTermsAccepted } = useContext(UserContext);

  const [hasTermsAccepted, setHasTermsAccepted] = useState(
    userProfile.hasTermsAccepted,
  );
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleChange = useCallback((value) => {
    setHasTermsAccepted(value);
  }, []);

  const handleSubmit = useCallback(() => {
    updateHasTermsAccepted(hasTermsAccepted);
    setOpen(false);
  }, [hasTermsAccepted, updateHasTermsAccepted]);

  useEffect(() => {
    if (!userProfile.hasTermsAccepted && !impersonate.sessionStarted) {
      setOpen(true);
    }
    if (process.env.NODE_ENV === "development") {
      setOpen(false);
    }
  }, []);

  if (userProfile.hasTermsAccepted || impersonate.sessionStarted) {
    return null;
  }

  return (
    <TermsDialog
      open={open}
      hasTermsAccepted={hasTermsAccepted}
      onClose={handleClose}
      onChange={handleChange}
      onSubmit={handleSubmit}
    />
  );
}

const _mapState = (state) => ({
  impersonate: state.impersonateForm,
});

export default connect(_mapState)(TermsDialogContainer);
