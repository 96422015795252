import React, { useCallback, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { RideSchedules } from "components/RideSchedules/RideSchedules.jsx";

import { TerritoryContext } from "contexts/TerritoryContext";
import {
  updateReservation,
  clearRide,
} from "containers/RideDetails/actions.js";
import { isEditable } from "utils/reservation";

function RideSchedulesContainer(props) {
  const {
    canChangeSeats,
    clearRide,
    isDeletable,
    isNotPast,
    isRecurring,
    hasUniversalLink,
    reservation,
    ride,
    setIsChangeSeatsDialogOpen,
    setIsUniversalLinkDialogOpen,
    updateReservation,
  } = props;

  const [intervalId, setIntervalId] = useState(null);
  const { selectedTerritory } = useContext(TerritoryContext);
  const navigate = useNavigate();

  const handleEdit = useCallback(() => {
    if (reservation) navigate(`/reservation/${reservation.id}/edit`);
  }, [navigate, reservation]);

  useEffect(() => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    if (!reservation) {
      return;
    }

    if (!ride) {
      clearRide();
      return;
    }

    const newIntervalId = setInterval(
      updateReservation,
      15000,
      reservation.id,
      selectedTerritory.territory_key,
    );
    setIntervalId(newIntervalId);

    return () => {
      clearInterval(newIntervalId);
      setIntervalId(null);
    };
  }, [reservation, ride, selectedTerritory.territory_key]);

  const editable = isEditable(
    reservation,
    isDeletable,
    selectedTerritory?.booking?.change_booking_seats?.enabled,
  );

  return (
    <RideSchedules
      canChangeSeats={canChangeSeats}
      edit={handleEdit}
      isDeletable={isDeletable}
      isEditable={editable}
      isNotPast={isNotPast}
      isRecurring={isRecurring}
      hasUniversalLink={hasUniversalLink}
      reservation={reservation}
      ride={ride}
      setIsChangeSeatsDialogOpen={setIsChangeSeatsDialogOpen}
      setIsUniversalLinkDialogOpen={setIsUniversalLinkDialogOpen}
    />
  );
}

const _mapState = (state) => ({
  ride: state.rideDetails.ride,
});

const _mapDispatch = (dispatch) => ({
  clearRide: () => {
    dispatch(clearRide());
  },
  updateReservation: (id, selectedTerritoryKey) => {
    dispatch(updateReservation(id, selectedTerritoryKey));
  },
});

export default connect(_mapState, _mapDispatch)(RideSchedulesContainer);
