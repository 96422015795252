import React from "react";
import { FormattedMessage } from "react-intl";
import { SelectGenerator } from "./inputs/SelectGenerator";
import { CheckboxGenerator } from "./inputs/CheckboxGenerator";
import { TextFieldGenerator } from "./inputs/TextFieldGenerator";

type FormInputGeneratorProps = {
  identifier: string;
  type: string;
  displayName: string;
  onChange: (name: string, value: any) => void;
  translatedDisplayName?: boolean;
  prefixLabelId?: string;
  prefixId?: string;
  prefixTestId?: string;
  error?: boolean;
  helperText?: Array<string>;
  value?: any;
  disabled?: boolean;
  required?: boolean;
  condition?: boolean;
  optionsSelect?: { key: string; display: string; order: number }[];
};

enum FieldType {
  TEXT = "text",
  DATE = "date",
  PASSWORD = "password",
  EMAIL = "email",
  NUMBER = "number",
  RADIO = "radio",
  SELECT = "select",
  CHECKBOX = "checkbox",
}

const formatString = (identifier: string) => {
  // Replace all non alphanumeric characters with empty string
  identifier = identifier.replace(/[^a-zA-Z0-9]/g, "");
  // Convert to lowercase
  identifier = identifier.toLowerCase();
  // replace accented characters with their non-accented counterparts
  identifier = identifier.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  // Replace all spaces with tired case
  identifier = identifier.replace(/\s+/g, "-");
  return identifier;
};

export const FormInputGenerator = (props: FormInputGeneratorProps) => {
  const {
    identifier,
    type,
    displayName,
    translatedDisplayName = true,
    prefixLabelId,
    prefixId = "",
    prefixTestId = "",
    error = false,
    helperText,
    value,
    disabled = false,
    required,
    onChange,
  } = props;

  const [editableValue, setEditableValue] = React.useState(value);

  const formattedIdentifier = formatString(identifier);
  let extraProperties = {};

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown; checked: unknown }>,
  ) => {
    onChange(identifier, event.target?.checked || event.target?.value);
    setEditableValue(event.target?.checked || event.target?.value);
  };

  const getId = () => {
    if (prefixId) {
      return `${prefixId}${formattedIdentifier}`;
    }
    return formattedIdentifier;
  };

  const getIdTest = () => {
    if (prefixTestId) {
      return `${prefixTestId}${formattedIdentifier}`;
    }
    return formattedIdentifier;
  };

  const getLabel = () => {
    if (translatedDisplayName) {
      return (
        <FormattedMessage id={`${prefixLabelId}${formatString(displayName)}`} />
      );
    }
    return displayName;
  };

  if (type === FieldType.SELECT) {
    return (
      <SelectGenerator
        id={getId()}
        label={getLabel()}
        labelID={`${getId()}-label`}
        value={editableValue}
        disabled={disabled}
        required={required}
        options={props.optionsSelect}
        handleChange={handleChange}
        dataTestId={getIdTest()}
      />
    );
  }

  if (type === FieldType.CHECKBOX) {
    return (
      <CheckboxGenerator
        id={getId()}
        label={getLabel()}
        name={formattedIdentifier}
        value={editableValue}
        disabled={disabled}
        required={required}
        handleChange={handleChange}
        SetValue={setEditableValue}
        dataTestId={getIdTest()}
      />
    );
  }

  if (type === FieldType.DATE) {
    extraProperties = {
      InputLabelProps: {
        shrink: true,
      },
    };
  }

  return (
    <TextFieldGenerator
      id={getId()}
      fieldKey={identifier}
      label={getLabel()}
      name={formattedIdentifier}
      type={FieldType[type?.toUpperCase()]}
      value={editableValue}
      error={error}
      helperText={helperText}
      disabled={disabled}
      required={required}
      handleChange={onChange}
      setvalue={setEditableValue}
      dataTestId={getIdTest()}
      extraProperties={extraProperties}
    />
  );
};
