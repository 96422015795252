import * as React from "react";
import { FormattedMessage } from "react-intl-phraseapp";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

import { useDeleteEmergencyContactDialogStyles } from "./deleteEmergencyContactDialogStyles";

interface IDeleteEmergencyContactDialogProps {
  onDelete: () => void;
  onClose: () => void;
}

export function DeleteEmergencyContactDialog(
  props: IDeleteEmergencyContactDialogProps,
) {
  const { onDelete, onClose } = props;

  const classes = useDeleteEmergencyContactDialogStyles();

  return (
    <Dialog className={classes.emergencyContactDialogContainer} open>
      <DialogTitle
        className={classes.emergencyContactDialogTitle}
        disableTypography
      >
        <Typography variant="h2">
          <FormattedMessage
            id="contact.delete-contact-title"
            defaultMessage="Delete this contact?"
          />
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.emergencyContactDialogContent}>
        <Typography>
          <FormattedMessage
            id="contact.delete-emergency-contact-info"
            defaultMessage="This contact will be no more reachable by the driver."
          />
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actionsContainer}>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="outlined"
          onClick={onClose}
        >
          <FormattedMessage id="misc.cancel" />
        </Button>

        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={(): void => {
            onDelete();
            onClose();
          }}
        >
          <FormattedMessage id="delete" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
