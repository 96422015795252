import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const useTooltipSelectionStyles = makeStyles((theme: CustomTheme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    pointerEvents: "auto",
  },
  title: {
    margin: "1rem",
    textAlign: "center",
  },
  button: {
    minWidth: "15rem",
    marginBottom: "1rem",
  },
  buttonFrom: {
    backgroundColor: "#188755",
  },
  buttonTo: {
    backgroundColor: "#E8174F",
  },
  busIcon: {
    verticalAlign: "-0.3rem",
  },
}));
