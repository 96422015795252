import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

function Credit(props) {
  return (
    <SvgIcon {...props}>
      <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 1c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 .5c-4.142 0-7.5 3.358-7.5 7.5 0 4.142 3.358 7.5 7.5 7.5 4.142 0 7.5-3.358 7.5-7.5 0-4.142-3.358-7.5-7.5-7.5zM12 8c.235 0 .45.135.56.35l.903 1.908 2.019.304c.228.044.414.214.486.443.073.23.019.481-.14.657l-1.46 1.484.344 2.094c.028.189-.024.38-.142.525-.118.146-.29.231-.473.235-.101 0-.201-.026-.292-.075L12 14.937l-1.805.988c-.211.108-.462.085-.652-.058-.19-.144-.288-.385-.255-.627l.344-2.094-1.46-1.484c-.159-.176-.213-.428-.14-.657.072-.23.258-.4.486-.443l2.019-.304.903-1.907c.11-.216.325-.351.56-.351zm0 .434c-.073 0-.141.04-.18.104l-.953 2.008c-.03.065-.09.11-.159.121l-2.129.322c-.076.01-.14.066-.162.143-.028.077-.008.164.05.22l1.542 1.563c.05.051.071.125.059.196l-.364 2.202c-.006.077.027.151.087.197.06.045.139.054.207.023l1.903-1.041c.03-.017.064-.026.099-.024v-.01c.034 0 .068.01.097.027L14 15.527c.068.03.146.021.207-.024.06-.045.093-.12.087-.197l-.364-2.201c-.012-.072.01-.145.06-.196l1.54-1.564c.059-.055.079-.142.051-.22-.023-.076-.086-.132-.162-.143l-2.127-.313c-.069-.01-.128-.056-.159-.12l-.953-2.01c-.039-.066-.107-.105-.18-.105z" />
    </SvgIcon>
  );
}

export default Credit;
