import React from "react";

import { useTheme, Paper, Typography } from "@material-ui/core";

export default function PaperContainer(props) {
  const { ariaLabel, children, role, tabIndex, title } = props;

  const theme = useTheme();

  // we want the top title to fusion its border-radiuses with top -and bottom if no content
  return (
    <Paper
      role={role}
      aria-label={ariaLabel}
      tabIndex={tabIndex}
      className="MuiEngagementPaper--01"
    >
      <div
        style={{
          backgroundColor: theme.palette.primary.main,
          padding: "1rem",
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: !children ? theme.shape.borderRadius : 0,
          borderBottomRightRadius: !children ? theme.shape.borderRadius : 0,
        }}
      >
        <Typography
          variant="h1"
          component="h2"
          align="center"
          style={{ color: theme.palette.textColor }}
        >
          {title}
        </Typography>
      </div>

      {children && <div className="column padded">{children}</div>}
    </Paper>
  );
}
