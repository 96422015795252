import React, { useContext } from "react";
import { FormattedMessage } from "react-intl-phraseapp";
import { Link as RouterLink } from "react-router-dom";

import { Link } from "@material-ui/core";

import { ProductContext } from "contexts/ProductContext";

import { useFooterStyles } from "./footerStyles";

export function Footer() {
  const classes = useFooterStyles();

  const { productParameters } = useContext(ProductContext);

  const cvg = productParameters?.contact?.cvg_link_url;
  const gdpr = productParameters?.contact?.private_policy_url;
  const privatePolicy = productParameters?.contact?.terms_url;

  return (
    <footer className={classes.footer} role="contentinfo">
      <ul className={classes.navList}>
        <li>
          <Link
            to="/accessibility#"
            component={RouterLink}
            onClick={() => window.scrollTo(0, 0)}
          >
            <FormattedMessage
              id="a11y-declaration-link"
              defaultMessage="Accessibility: partial compliance"
            />
          </Link>
        </li>

        <li>
          <Link href={cvg} target="_blank">
            <FormattedMessage id="footer.cvg" />
          </Link>
        </li>

        <li>
          <Link href={privatePolicy} target="_blank">
            <FormattedMessage id="footer.privatePolicy" />
          </Link>
        </li>

        {privatePolicy ? (
          <li>
            <Link href={gdpr} target="_blank">
              <FormattedMessage id="footer.gdpr" />
            </Link>
          </li>
        ) : null}
      </ul>
    </footer>
  );
}
