import { makeStyles } from "@material-ui/core";

export const useThemeLayoutStyles = makeStyles(() => ({
  hiddenContainer: {
    visibility: "hidden",
    opacity: 0,
  },
  layoutContainer: {
    width: "100%",
    minHeight: "100vh",

    transition: "opacity 300ms",
  },
  visibleContainer: {
    visibility: "visible",
    opacity: 1,
  },
}));
