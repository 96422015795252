import React from "react";
import { useContext } from "react";
import { TerritoryContext } from "contexts/TerritoryContext";
import { FormInputGenerator } from "molecules/FormInputGenerator/FormInputGenerator";
import { useSelector } from "react-redux";
import { CustomFieldsType } from "components/organisms/AccountInfos/types/CustomFieldsType";
import { CustomField } from "api/types/common";
import { CustomFieldType } from "utils/constants";

type AccountInfosCustomFieldsProps = {
  customFields: CustomFieldsType | null | undefined;
  onChange: (name: string, value: any) => void;
  formErrors: any;
};

export const AccountInfosCustomFields = (
  props: AccountInfosCustomFieldsProps,
) => {
  const { customFields, onChange, formErrors } = props;

  // Get data to generate input fields
  const { selectedTerritory } = useContext(TerritoryContext);

  // Get if user is impersonate
  const impersonateSessionStarted = useSelector(
    (state: any) => state.impersonateForm.sessionStarted,
  );

  if (!customFields) {
    return null;
  }

  const accountInfosFields = selectedTerritory?.extras?.custom_field_list?.user;

  function render(field: CustomField) {
    let value: string | number | boolean | null = null;
    let extraProperties = {};
    if (
      customFields &&
      Object.prototype.hasOwnProperty.call(customFields, field.key)
    ) {
      value = customFields[field.key];
    }
    let readable: boolean = field.is_readable;
    let writable: boolean = field.is_writable;

    if (impersonateSessionStarted) {
      if (field.is_used_in_impersonate) {
        readable = writable = true;
      } else {
        readable = readable || field.is_readable_by_admin;
        writable = writable || field.is_writable_by_admin;
      }
    }
    if (!readable) return null;

    const disabled: boolean = readable && !writable;

    if (field.type === CustomFieldType.select) {
      extraProperties = {
        optionsSelect: field?.options,
      };
    }

    return (
      <FormInputGenerator
        identifier={field.key}
        type={CustomFieldType[field.type]}
        displayName={field.display_name}
        translatedDisplayName={false}
        prefixLabelId="forms."
        required={field.is_required}
        value={value}
        disabled={disabled}
        prefixId="profile-"
        prefixTestId="account-infos-"
        onChange={(name: string, value: any) =>
          onChange(`customfield|${name}`, value)
        }
        error={Boolean(
          formErrors[field.key] && formErrors[field.key].length > 0,
        )}
        helperText={formErrors[field.key]}
        {...extraProperties}
      />
    );
  }

  return (
    <>
      {accountInfosFields?.map((field: any) => {
        return render(field);
      })}
    </>
  );
};
