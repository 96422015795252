import React from "react";
import { withTheme } from "@material-ui/core/styles";

function DateRange() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      focusable="false"
      role="none"
      aria-hidden="true"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#717587">
          <path d="M7.455 1l-.001 1.826h9.091V1h1.819l-.001 1.826h.91c1.506 0 2.727 1.226 2.727 2.74V19.26C22 20.774 20.779 22 19.273 22H4.727C3.221 22 2 20.774 2 19.26V5.566c0-1.513 1.221-2.739 2.727-2.739h.909V1h1.819zm13.181 7.228H3.363v11.033c0 .712.541 1.297 1.233 1.363l.131.006h14.546c.709 0 1.291-.543 1.357-1.237l.006-.132V8.228zM12 11.043v5.479H6.545v-5.479H12zm7.273-6.847H4.727c-.709 0-1.291.543-1.357 1.237l-.006.132-.001 1.293h17.273V5.565c0-.712-.54-1.297-1.232-1.363l-.131-.006z" />
        </g>
      </g>
    </svg>
  );
}

export default withTheme(DateRange);
