import { makeStyles } from "@material-ui/core";

import { CustomTheme } from "src/themes/themeTypes";

export const usePasswordStyles = makeStyles((theme: CustomTheme) => ({
  passwordContainer: {
    width: "100%",
  },
  currentPasswordContainer: {
    display: "grid",
    gridGap: "16px 32px",

    margin: "1.5rem 0 2rem 0",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },

  newPasswordContainer: {
    display: "grid",
    gridGap: "16px 32px",

    margin: "2rem 0",

    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
  },
  infoBox: {
    [theme.breakpoints.up("sm")]: {
      gridColumn: "1 / span 2",
    },
  },
  saveButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));
