import React, { useContext } from "react";

import {
  Button as MuiButton,
  CircularProgress,
  Typography,
  Paper,
} from "@material-ui/core";
import { withTheme } from "@material-ui/core/styles";

import useWindowWidth from "lib/useWindowWidth";

import { ProductContext } from "contexts/ProductContext";

function SSOSignInForm(props) {
  const { onSubmit, intl, error, requesting } = props;

  const { productParameters } = useContext(ProductContext);

  const width = useWindowWidth();

  return (
    <Paper
      className="MuiEngagementPaper--01"
      style={{
        padding: width >= 600 ? "2rem" : "1rem 0",
        /*z-index here for the visual effect below the paper*/ zIndex: 1,
        /* TODO: Use MUI breakpoints - https://app.shortcut.com/padammobility/story/37597/use-mui-breakpoints-instead-of-literal-width-values */
        minWidth: width >= 600 ? "25rem" : "auto",
        overflowY: "auto",
      }}
    >
      <form onSubmit={onSubmit} className="column" data-testid="sign-in-form">
        <div className="logoBox">
          <img
            className="logo"
            src={`${ASSETS_URL}${DEFAULT_TERRITORY || PRODUCT}/logos/${
              DEFAULT_TERRITORY || PRODUCT
            }_color.png`}
            alt={productParameters?.brand}
          />
        </div>

        {error && (
          <Typography
            variant="caption"
            color="error"
            align="center"
            data-testid="signin-error"
            aria-role="alert"
          >
            {error.detail?.message}
          </Typography>
        )}

        <div
          className="row aligned"
          style={{
            justifyContent: "center",
          }}
        >
          <MuiButton
            style={{ marginTop: "0.5rem" }}
            type="submit"
            color="primary"
            disabled={requesting}
            variant="contained"
            data-testid="signin-button"
          >
            {requesting ? (
              <CircularProgress />
            ) : (
              intl.formatMessage({ id: "signin.connexion" })
            )}
          </MuiButton>
        </div>
      </form>
    </Paper>
  );
}

export default withTheme(SSOSignInForm);
