import React from "react";

function Swap() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      focusable="false"
      role="none"
      aria-hidden="true"
    >
      <g fill="none" fillRule="evenodd">
        <g fill="#151515">
          <g>
            <g>
              <path
                d="M19.471 9.09c.515 0 .94.372 1.007.854l.01.134-.001 13.367 2.274-2.235c.36-.352.923-.387 1.323-.103l.114.095c.363.349.4.897.106 1.285l-.098.111-4.012 3.942c-.032.031-.061.056-.092.08l.092-.08c-.037.036-.075.069-.116.098-.022.016-.045.032-.069.046-.015.01-.031.019-.048.027l-.06.03-.035.015c-.023.01-.047.018-.071.026l-.054.016-.06.014-.049.009c-.022.004-.045.006-.068.008l-.06.004h-.056l-.053-.002.076.002c-.05 0-.099-.003-.147-.01l-.042-.007c-.026-.005-.052-.01-.077-.017l-.034-.01c-.026-.007-.051-.016-.076-.026-.013-.004-.025-.01-.038-.015l-.06-.03c-.018-.008-.034-.017-.05-.026-.017-.01-.035-.021-.052-.033l-.032-.022-.01-.007c-.035-.027-.068-.055-.1-.086l-.004-.004-4.012-3.942c-.395-.387-.391-1.013.008-1.396.362-.348.927-.377 1.323-.088l.114.096 2.273 2.234V10.078c0-.545.455-.987 1.016-.987zM7.9 1.379l-.094.082c.036-.036.075-.068.115-.098l.075-.05.029-.016c.024-.013.049-.026.074-.037l.037-.016c.023-.01.046-.018.07-.025l.053-.016.06-.014.049-.009c.022-.004.045-.006.068-.008l.06-.004h.056l.053.002-.076-.002c.05 0 .099.003.147.01l.046.008c.02.003.04.008.061.013l.063.018.047.016.05.02.075.037c.008.003.015.008.023.012.03.017.058.035.085.054l.01.008c.04.03.075.058.108.09l.007.007 4.012 3.942c.395.387.391 1.013-.008 1.396-.362.348-.927.377-1.323.088l-.114-.096-2.274-2.234v13.366c0 .545-.454.987-1.015.987-.515 0-.94-.371-1.007-.853l-.01-.134V4.555L5.239 6.79c-.36.352-.923.387-1.323.103l-.114-.095c-.363-.349-.4-.897-.106-1.285l.098-.111L7.806 1.46c.032-.032.062-.058.094-.082z"
                transform="translate(-762 -245) translate(310 170) rotate(-180 240 51.5)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Swap;
