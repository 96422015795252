import { makeStyles } from "@material-ui/core";

export const useChangeSeatsDialogStyles = makeStyles((theme) => ({
  actionButton: {
    width: "calc(50% - 1rem)",
    margin: "0.5rem 0",

    [theme.breakpoints.up("sm")]: {
      width: "calc(50% - 2rem)",
    },
  },
  actionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",

    padding: "1rem 1.5rem",
  },
  dialogContainer: {
    color: "red",

    "& .MuiDialog-paper": {
      margin: "32px 8px",
    },
  },
  dialogTitle: {
    padding: "2rem 2rem 1rem 2rem",

    "& h2": {
      textAlign: "center",
      fontSize: "1.35rem",
      fontWeight: 700,

      [theme.breakpoints.up("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
}));
