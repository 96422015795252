import * as React from "react";

import { SvgIcon } from "@material-ui/core";

export function SuitcaseIcon(props) {
  return (
    <SvgIcon className={props.className}>
      <g transform="translate(0 2) scale(1.2 1.2)">
        <path
          d="M0.968085 3.94924C0.891705 3.94924 0.829787 4.00863 0.829787 4.08189V12.5717C0.829787 12.645 0.891705 12.7043 0.968085 12.7043H12.0319C12.1083 12.7043 12.1702 12.645 12.1702 12.5717V4.08189C12.1702 4.00863 12.1083 3.94924 12.0319 3.94924H0.968085ZM0 4.08189C0 3.56906 0.433426 3.15332 0.968085 3.15332H12.0319C12.5666 3.15332 13 3.56906 13 4.08189V12.5717C13 13.0845 12.5666 13.5003 12.0319 13.5003H0.968085C0.433426 13.5003 0 13.0845 0 12.5717V4.08189Z"
          fill="#151515"
          fillRule="evenodd"
          clipRule="evenodd"
        />
        <path
          d="M6.49994 1.29592C5.8764 1.29592 5.2784 1.53351 4.83749 1.95642C4.39658 2.37934 4.14888 2.95293 4.14888 3.55102C4.14888 3.77081 3.96312 3.94898 3.73399 3.94898C3.50485 3.94898 3.31909 3.77081 3.31909 3.55102C3.31909 2.74184 3.65422 1.9658 4.25074 1.39362C4.84727 0.821446 5.65633 0.5 6.49994 0.5C7.34356 0.5 8.15262 0.821446 8.74914 1.39362C9.34567 1.9658 9.68079 2.74184 9.68079 3.55102C9.68079 3.77081 9.49504 3.94898 9.2659 3.94898C9.03676 3.94898 8.85101 3.77081 8.85101 3.55102C8.85101 2.95293 8.60331 2.37934 8.1624 1.95642C7.72149 1.53351 7.12348 1.29592 6.49994 1.29592Z"
          fill="#151515"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </g>
    </SvgIcon>
  );
}
