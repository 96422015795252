import React from "react";

import { SvgIcon } from "@material-ui/core";

export function BankIcon() {
  return (
    <SvgIcon
      style={{ width: "32px", height: "32px" }}
      width="32px"
      height="32px"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.1283 1.86459C11.3525 1.73363 11.63 1.73361 11.8543 1.86454L19.9087 6.56614C20.1896 6.73011 20.3256 7.06201 20.2407 7.37597C20.1558 7.68992 19.8709 7.90796 19.5457 7.90796H3.4393C3.11408 7.90796 2.82924 7.68994 2.74429 7.37601C2.65935 7.06208 2.7954 6.73018 3.07625 6.56619L11.1283 1.86459ZM11.4914 3.32008L6.10027 6.46796H16.884L11.4914 3.32008Z"
        fill="black"
      />
      <path
        d="M5.77957 8.59424C6.17722 8.59424 6.49957 8.91659 6.49957 9.31424V17.7982C6.49957 18.1959 6.17722 18.5182 5.77957 18.5182C5.38193 18.5182 5.05957 18.1959 5.05957 17.7982V9.31424C5.05957 8.91659 5.38193 8.59424 5.77957 8.59424Z"
        fill="black"
      />
      <path
        d="M9.58816 8.59424C9.98581 8.59424 10.3082 8.91659 10.3082 9.31424V17.7982C10.3082 18.1959 9.98581 18.5182 9.58816 18.5182C9.19052 18.5182 8.86816 18.1959 8.86816 17.7982V9.31424C8.86816 8.91659 9.19052 8.59424 9.58816 8.59424Z"
        fill="black"
      />
      <path
        d="M13.3968 8.59424C13.7944 8.59424 14.1168 8.91659 14.1168 9.31424V17.7982C14.1168 18.1959 13.7944 18.5182 13.3968 18.5182C12.9991 18.5182 12.6768 18.1959 12.6768 17.7982V9.31424C12.6768 8.91659 12.9991 8.59424 13.3968 8.59424Z"
        fill="black"
      />
      <path
        d="M17.2058 8.59424C17.6035 8.59424 17.9258 8.91659 17.9258 9.31424V11.611C17.9258 12.0087 17.6035 12.331 17.2058 12.331C16.8082 12.331 16.4858 12.0087 16.4858 11.611V9.31424C16.4858 8.91659 16.8082 8.59424 17.2058 8.59424Z"
        fill="black"
      />
      <path
        d="M2.71924 19.9248C2.71924 19.5272 3.04159 19.2048 3.43924 19.2048H19.5456C19.9433 19.2048 20.2656 19.5272 20.2656 19.9248C20.2656 20.3225 19.9433 20.6448 19.5456 20.6448H3.43924C3.04159 20.6448 2.71924 20.3225 2.71924 19.9248Z"
        fill="black"
      />
      <path
        d="M20.6112 15.4681L18.8904 13.7473C18.7296 13.5793 18.4632 13.5721 18.2952 13.7329C18.1272 13.8937 18.12 14.1601 18.2808 14.3281C18.2856 14.3329 18.2904 14.3377 18.2952 14.3425L19.3128 15.3577H15.9312C15.6984 15.3577 15.5112 15.5473 15.5112 15.7777C15.5112 16.0081 15.7008 16.1977 15.9312 16.1977H19.3032L18.2952 17.2057C18.1344 17.3737 18.1416 17.6401 18.3096 17.8009C18.4728 17.9545 18.7272 17.9545 18.8904 17.8009L20.6112 16.0801C20.6904 16.0009 20.736 15.8929 20.7336 15.7825C20.7336 15.7801 20.7336 15.7753 20.7336 15.7729C20.7336 15.7705 20.7336 15.7657 20.7336 15.7633C20.7336 15.6529 20.688 15.5473 20.6112 15.4681Z"
        fill="black"
      />
      <path
        d="M18.976 13.6632C18.7698 13.4489 18.4282 13.4393 18.2121 13.6461C17.9968 13.8523 17.9869 14.1946 18.194 14.411L19.0225 15.2376H15.9311C15.6316 15.2376 15.3911 15.4814 15.3911 15.7776C15.3911 16.0743 15.6344 16.3176 15.9311 16.3176H19.0134L18.2103 17.1208L18.2084 17.1226C18.0013 17.339 18.0112 17.6814 18.2265 17.8875L18.2273 17.8882C18.4367 18.0853 18.7632 18.0853 18.9726 17.8882L20.696 16.1649C20.7966 16.0642 20.8563 15.9257 20.8535 15.7812V15.782L20.7335 15.7824L20.8535 15.7798C20.8535 15.7803 20.8535 15.7807 20.8535 15.7812M18.8036 13.8302C18.6882 13.7096 18.4977 13.705 18.3781 13.8195C18.2577 13.9347 18.2529 14.1245 18.3666 14.2442L18.3799 14.2575L19.6029 15.4776H15.9311C15.765 15.4776 15.6311 15.613 15.6311 15.7776C15.6311 15.9417 15.767 16.0776 15.9311 16.0776H19.5928L18.381 17.2895C18.2675 17.409 18.2721 17.5986 18.3921 17.7138C18.5087 17.8232 18.6899 17.8234 18.8068 17.7146L20.5263 15.9952C20.5837 15.9378 20.6152 15.8608 20.6135 15.785L20.6135 15.7824L20.6135 15.7635C20.6135 15.6865 20.5817 15.6104 20.5256 15.5522L18.8036 13.8302Z"
        fill="black"
      />
    </SvgIcon>
  );
}
