import React from "react";

import { FormattedMessage } from "react-intl-phraseapp";

import { DialogActions, DialogContent, DialogTitle } from "@material-ui/core";

import { WaitingList } from "api/types/waitingList";

import { Modal } from "components/Modal/Modal";
import { Button } from "components/ui/button";

import { WaitingListTrip } from "./WaitingListTrip";
import { Typography } from "components/ui/typography";

interface CancelWaitingListDialog {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  requestedDate: string;
  waitingList: WaitingList;
}

export function CancelWaitingListDialog({
  open,
  onClose,
  onSubmit,
  requestedDate,
  waitingList,
}: CancelWaitingListDialog) {
  return (
    <Modal isShowing={open} hide={onClose}>
      <div className="flex flex-col gap-4 p-4 pt-12">
        <Typography variant="h2" className="text-center text-2xl font-bold">
          <FormattedMessage id="FLOW_HELPER_TITLE_CANCEL_WAITING_LIST" />
        </Typography>
        <DialogContent>
          <WaitingListTrip
            requestedDate={requestedDate}
            waitingList={waitingList}
          />
        </DialogContent>
        <DialogActions className="flex gap-4">
          <Button variant="outline" onClick={onClose}>
            <FormattedMessage id="misc.no" />
          </Button>
          <Button onClick={onSubmit}>
            <FormattedMessage id="ACTION_CONFIRM_CANCEL_WAITING_LIST" />
          </Button>
        </DialogActions>
      </div>
    </Modal>
  );
}
