import React from "react";
import RideDetails from "containers/RideDetails/RideDetails.jsx";
import BookingValidation from "containers/BookingValidation/BookingValidation.jsx";
import BookingValidationResult from "containers/BookingValidation/BookingValidationResult";
import RideMap from "containers/RideMap/RideMap.jsx";
import { Paper } from "@material-ui/core";
import { useIntl } from "react-intl";

export default function EditionConfirmView() {
  const intl = useIntl();
  return (
    <div className="marged row container">
      <h1 className="visuallyHidden">
        {intl.formatMessage({ id: "editionConfirm.title" })}
      </h1>

      <div className="marged">
        <RideDetails edition />
      </div>

      <div className="column marged" style={{ width: "100%" }}>
        <Paper className="mapContainerInRow">
          <RideMap />
        </Paper>
        <BookingValidation edition />
      </div>

      {/* below is a modal */}
      <BookingValidationResult />
    </div>
  );
}
