import { ResourceType } from "api/resources/types/resourceType";

export const waitingList: ResourceType = {
  getWaitingLists: {
    endpoint: "/customers/{customerId}/waitinglist/",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  joinWaitingList: {
    endpoint: "/customers/{customerId}/waitinglist/",
    method: "POST",
    impersonate: true,
    apiVersion: "2.0",
  },
  getWaitingList: {
    endpoint: "/customers/{customerId}/waitinglist/{searchRequestId}",
    method: "GET",
    impersonate: true,
    apiVersion: "2.0",
  },
  cancelWaitingList: {
    endpoint: "/customers/{customerId}/waitinglist/{searchRequestId}",
    method: "DELETE",
    impersonate: true,
    apiVersion: "2.0",
  },
};
