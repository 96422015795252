import React, { useContext } from "react";

import SwitchTerritoryDialog from "components/SwitchTerritory/SwitchTerritoryDialog.jsx";

import { TerritoryContext } from "contexts/TerritoryContext";

export default function SwitchTerritoryContainer() {
  const {
    changeSelectedTerritory,
    selectedTerritory,
    setSwitchTerritoryDialogOpen,
    switchTerritoryDialogOpen,
    territories,
  } = useContext(TerritoryContext);

  const handleCloseSwitchTerritoryDialog = () => {
    if (selectedTerritory) {
      setSwitchTerritoryDialogOpen(false);
    }
  };

  const selectTerritory = (newTerritory) => {
    changeSelectedTerritory(newTerritory);
    setSwitchTerritoryDialogOpen(false);
  };

  if (!switchTerritoryDialogOpen) {
    return null;
  }

  return (
    <SwitchTerritoryDialog
      availableTerritories={territories}
      currentTerritoryKey={selectedTerritory?.territory_key}
      handleCloseDialog={handleCloseSwitchTerritoryDialog}
      selectTerritory={selectTerritory}
    />
  );
}
