import {
  INITIATE_CREDIT_MATRIX_REQUEST,
  RECEIVE_CREDIT_MATRIX,
  CREDIT_MATRIX_REQUEST_ERROR,
  INITIATE_CREDIT_BALANCE_REQUEST,
  RECEIVE_CREDIT_BALANCE,
  CREDIT_BALANCE_REQUEST_ERROR,
  INITIATE_CREDIT_PURCHASE_REQUEST,
  CREDIT_PURCHASE_REQUEST_SUCCESS,
  CREDIT_PURCHASE_REQUEST_ERROR,
} from "./actions.js";

const initialState = {
  matrix: [],
  isRequestingMatrix: false,
  balance: 0,
  isRequestingBalance: false,
  isRequestingPurchase: false,
  error: null,
};

export const credit = (state = initialState, action) => {
  switch (action.type) {
    case INITIATE_CREDIT_MATRIX_REQUEST:
      return {
        ...state,
        isRequestingMatrix: true,
      };
    case RECEIVE_CREDIT_MATRIX:
      return {
        ...state,
        matrix: action.matrix,
        isRequestingMatrix: false,
      };
    case CREDIT_MATRIX_REQUEST_ERROR:
      return {
        ...state,
        isRequestingMatrix: false,
      };
    case INITIATE_CREDIT_BALANCE_REQUEST:
      return {
        ...state,
        isRequestingBalance: true,
      };
    case RECEIVE_CREDIT_BALANCE:
      return {
        ...state,
        balance: action.balance,
        isRequestingBalance: false,
      };
    case CREDIT_BALANCE_REQUEST_ERROR:
      return {
        ...state,
        isRequestingBalance: false,
      };
    case INITIATE_CREDIT_PURCHASE_REQUEST:
      return {
        ...state,
        isRequestingPurchase: true,
      };
    case CREDIT_PURCHASE_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestingPurchase: false,
      };
    case CREDIT_PURCHASE_REQUEST_ERROR:
      return {
        ...state,
        error: action.error,
        isRequestingPurchase: false,
      };
    default:
      return state;
  }
};
