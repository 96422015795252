import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import SnackBar from "containers/SnackBar/SnackBar.jsx";

import { ProductContext } from "contexts/ProductContext";

import { usePathnameForTitle } from "./usePathname";
import { useUnauthenticatedLayoutStyles } from "./unauthenticatedLayoutStyles";

export function UnauthenticatedLayout() {
  const { productParameters } = useContext(ProductContext);
  const classes = useUnauthenticatedLayoutStyles();

  usePathnameForTitle(productParameters?.brand);

  return (
    <main className={classes.viewBackground}>
      <div className={classes.viewWapper}>
        <Outlet />

        <SnackBar />
      </div>
    </main>
  );
}
