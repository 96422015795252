// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificationRow-cssmodule-title-NKY9T{font-size:1.2rem !important;font-weight:500}.notificationRow-cssmodule-subtitle-Dut5j{font-size:1rem !important;color:gray}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/NotificationRow/notificationRow.cssmodule.scss"],"names":[],"mappings":"AAAA,uCACE,2BAA4B,CAC5B,eAAgB,CACjB,0CAGC,yBAA0B,CAC1B,UAAW","sourcesContent":[".title {\n  font-size: 1.2rem !important;\n  font-weight: 500;\n}\n\n.subtitle {\n  font-size: 1rem !important;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `notificationRow-cssmodule-title-NKY9T`,
	"subtitle": `notificationRow-cssmodule-subtitle-Dut5j`
};
export default ___CSS_LOADER_EXPORT___;
