export const FORMAT_HOUR = "HH:mm";
export const FORMAT_DATE = "YYYY/MM/DD";
export const FORMAT_DATE_TIME = "YYYY/MM/DDTHH:mm:ss";
export const INITIAL = "INITIAL";
export const STATUS_AVAILABLE = "AVAILABLE";

export enum CustomFieldType {
  str = "text",
  int = "number",
  float = "number",
  select = "select",
  boolean = "checkbox",
  date = "date",
}

export const GREY_COLOR_HEX = "#404148";
