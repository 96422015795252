import * as React from "react";
import { SvgIcon } from "@material-ui/core";

export const Paypal = (props) => {
  return (
    <SvgIcon {...props}>
      <defs>
        <style>{`.cls-1{fill:none;stroke:#404040;stroke-linecap:round;stroke-linejoin:round;stroke-width:2.5px;}`}</style>
      </defs>
      <svg
        id="Calque_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 45.51 55.59"
      >
        <path
          className="cls-1"
          d="m34.24,16.89c0,.41-.04.83-.11,1.25-.87,5.55-5.64,9.65-11.25,9.65h-5.58c-.57,0-1.06.41-1.14.98l-1.75,11.1-1.1,6.96c-.08.51.27,1,.78,1.08.05,0,.1.01.15.01h6.06c.57,0,1.06-.41,1.14-.98l1.6-10.12c.09-.56.57-.98,1.14-.98h3.57c5.61,0,10.38-4.1,11.25-9.65.62-3.94-1.37-7.53-4.76-9.3h0Z"
        />
        <path
          className="cls-1"
          d="m34.24,16.89c.1-5.05-4.07-9.23-9.81-9.23h-12.11c-.57,0-1.06.42-1.14.98l-4.75,30.13c-.08.51.27,1,.78,1.08.05,0,.1.01.15.01h7.05l1.75-11.1"
        />
      </svg>
    </SvgIcon>
  );
};
