export enum BookingStatusEnum {
  PENDING = "VALIDATED",
}

export enum WaitingListStatusEnum {
  PENDING = "PENDING",
  CANCELLED_BY_CUSTOMER = "CANCELLED_BY_CUSTOMER",
  CANCELLED_BY_OPERATOR = "CANCELLED_BY_OPERATOR",
  CANCELLED_BY_SYSTEM = "CANCELLED_BY_SYSTEM",
  VALIDATED = "VALIDATED",
}

export type WaitingListStatus =
  | WaitingListStatusEnum.PENDING
  | WaitingListStatusEnum.CANCELLED_BY_CUSTOMER
  | WaitingListStatusEnum.CANCELLED_BY_OPERATOR
  | WaitingListStatusEnum.CANCELLED_BY_SYSTEM
  | WaitingListStatusEnum.VALIDATED;

export enum ResaGroupEnum {
  upcoming = "upcoming",
  past = "past",
  cancelled = "cancelled",
}
