import api from "api";
import cookie from "lib/cookie.js";
import { settings } from "config/app";
import { snackMessage } from "containers/SnackBar/actions.js";
import { jumpToNextPage } from "store/actions.js";

export const ON_FIELD_CHANGE = "@@signup/ON_FIELD_CHANGE";
export const SIGNUP_REQUEST_ERROR = "SIGNUP_REQUEST_ERROR";
export const REQUESTING = "@@signup/REQUESTING";
export const RESET_STATE = "@@signup/RESET_STATE";

export const onFieldChange = (field, value) => ({
  type: ON_FIELD_CHANGE,
  field,
  value,
});

export const signupRequestError = (error) => ({
  type: SIGNUP_REQUEST_ERROR,
  error,
});

const requesting = () => ({
  type: REQUESTING,
});

const passwordCheck = (password, repeat, dispatch, intl) => {
  if (password && repeat && password !== repeat) {
    dispatch(
      signupRequestError({
        infos: {
          password: intl.formatMessage({ id: "signup.password_doesnt_match" }),
          passwordRepeat: intl.formatMessage({
            id: "signup.password_doesnt_match",
          }),
        },
      }),
    );

    return false;
  } else if (password && !repeat) {
    dispatch(
      signupRequestError({
        infos: {
          passwordRepeat: intl.formatMessage({ id: "misc.not_blank" }),
        },
      }),
    );

    return false;
  }

  return true;
};

export const signupRequest =
  (
    navigate,
    loadUserProfile,
    setIsAuthenticated,
    phoneValidationProcessAtBooking,
    payload,
  ) =>
  (dispatch, getState, getIntl) => {
    const intl = getIntl();
    const passwordValid = passwordCheck(
      payload.password,
      payload.password_repeat,
      dispatch,
      intl,
    );

    if (!passwordValid) return;

    dispatch(requesting());

    api
      .signup(payload)
      .then(async (json) => {
        dispatch({ type: "SIGNUP_REQUEST_SUCCESS" });
        // in case of token conflict (impersonate, etc)
        // cookie.erase(settings.impersonateCookie)
        // cookie.erase(settings.authCookie)
        // cookie.erase('adm')

        cookie.set(settings.cookieKeys.authCookie, json.token, 2);

        setIsAuthenticated(true);

        loadUserProfile().then((userProfile) => {
          if (!phoneValidationProcessAtBooking) {
            const isSignupFinalized =
              userProfile.isPhoneValidated || SIGNUP_AFTER_SEARCH;

            // use and reset redirection link after signup is finalized
            if (isSignupFinalized) {
              jumpToNextPage(navigate, location);
            } else navigate("/register/verification");
          }
          dispatch(
            snackMessage(
              "success",
              getIntl().formatMessage(
                {
                  id: "signin.success",
                },
                { name: userProfile.firstName },
              ),
            ),
          );
        });
      })

      .catch((error) => {
        /**
         * If the phone number isn't valid the API doesn't return
         * the same response structure as if the phone number was empty
         */
        if (
          error.infos.phone_number &&
          !(error.infos.phone_number instanceof Array)
        )
          error.infos.phone_number = [error.infos.phone_number.message];

        dispatch(signupRequestError(error));
      });
  };
